import Icon from "@Atom/Icon";
import LoadingAnimation from "@Atom/LoadingAnimation";
import ModalWrapper from "@Atom/ModalWrapper";
import { getFLBLogs } from "@Services/manager/bm";
import Images from "@Theme/Images";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import { rupiahFormat } from "@Helpers/formatRupiah";

export default function FLBHistoryModal({
  selectedFLB,
  onClose = () => {},
  isEditScheme = false,
}) {
  const [onFirstLoad, setOnFirstLoad] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const maxLength = 150;

  // console.log(selectedFLB, 'tes')

  const fetchLogs = useCallback(
    async (isMore = false) => {
      try {
        if (isMore) {
          setIsLoadingMore(true);
          // setPage(+page + 1)
        }
        const { response } = await getFLBLogs(
          selectedFLB?.schemeID,
          isMore ? (+page || 1) + 1 : page,
          5,
          "Pending"
        );
        if (isMore) {
          setPage(page + 1);
        }
        setTotalPage(response?.totalPage);
        setLogs(isMore ? [...logs, ...response?.logs] : response?.logs);
        setIsLoadingMore(false);
        setOnFirstLoad(false);
      } catch (err) {
        setIsLoadingMore(false);
        setOnFirstLoad(false);
        console.log(err, "err on fetching");
      }
    },
    [logs, page, selectedFLB?.schemeID]
  );

  useEffect(() => {
    if (onFirstLoad) fetchLogs();
  }, [fetchLogs, onFirstLoad]);

  const remark = useCallback((el) => {
    switch (el?.table) {
      case "ExpectedPosition":
        if (el?.remark?.includes("Ditambahkan")) {
          return (
            <span className={Styles.remark}>
              Posisi <span>{el?.updateValue?.toUpperCase() || "-"}</span>{" "}
              Ditambahkan
            </span>
          );
        } else if (el?.remark?.includes("Dihapus")) {
          return (
            <span className={Styles.remark}>
              Posisi{" "}
              <span>
                {el?.remark
                  ?.split("Posisi ")[1]
                  ?.split(" Dihapus")[0]
                  ?.toUpperCase() || "-"}
              </span>{" "}
              Dihapus
            </span>
          );
        } else if (el?.remark?.includes("Perubahan")) {
          return (
            <span className={Styles.remark}>
              {el?.remark?.split(" Posisi ")[0]} Posisi{" "}
              <span>
                {el?.remark
                  ?.split(" Posisi ")[1]
                  ?.split(" untuk ")[0]
                  ?.toUpperCase() || "-"}
              </span>{" "}
              untuk{" "}
              <span>
                {el?.remark?.split(" Posisi ")[1]?.split(" untuk ")[1] || "-"}
              </span>{" "}
            </span>
          );
        } else {
          return <span className={Styles.remark}>{el?.remark}</span>;
        }

      case "ExpectedQualification":
        if (el?.remark?.includes("Ditambahkan")) {
          return (
            <span className={Styles.remark}>
              Kualifikasi Posisi{" "}
              <span>
                {el?.remark
                  ?.split(" Posisi ")[1]
                  ?.split(" Ditambahkan")[0]
                  ?.toUpperCase() || "-"}
              </span>{" "}
              Ditambahkan
            </span>
          );
        } else if (el?.remark?.includes("Dihapus")) {
          return (
            <span className={Styles.remark}>
              Kualifikasi Posisi{" "}
              <span>
                {el?.remark
                  ?.split(" Posisi ")[1]
                  ?.split(" Dihapus")[0]
                  ?.toUpperCase() || "-"}
              </span>{" "}
              Dihapus
            </span>
          );
        } else if (el?.remark?.includes("Perubahan Kualifikasi Posisi")) {
          return (
            <span className={Styles.remark}>
              Perubahan Kualifikasi Posisi{" "}
              <span>
                {el?.remark?.split(" Posisi ")[1]?.toUpperCase() || "-"}
              </span>{" "}
            </span>
          );
        } else {
          return <span className={Styles.remark}>{el?.remark}</span>;
        }
      case "ExpectedAllowance":
        if (el?.remark?.includes("Ditambahkan")) {
          return (
            <span className={Styles.remark}>
              Allowance{" "}
              <span>
                {el?.remark?.split("Allowance ")[1]?.split(" Posisi")[0] || "-"}
              </span>{" "}
              Posisi{" "}
              <span>
                {el?.remark
                  ?.split(" Posisi ")[1]
                  ?.split(" untuk ")[0]
                  ?.toUpperCase() || "-"}
              </span>{" "}
              untuk{" "}
              <span>
                {el?.remark
                  ?.split(" Posisi ")[1]
                  ?.split(" untuk ")[1]
                  ?.split(" Ditambahkan")[0] || "-"}
              </span>{" "}
              Ditambahkan
            </span>
          );
        } else if (el?.remark?.includes("Penghapusan Allowance")) {
          return (
            <span className={Styles.remark}>
              Penghapusan Allowance{" "}
              <span>
                {el?.remark?.split(" Allowance ")[1]?.split(" Posisi ")[0] ||
                  "-"}
              </span>{" "}
              Posisi{" "}
              <span>
                {el?.remark
                  ?.split(" Posisi ")[1]
                  ?.split(" untuk ")[0]
                  ?.toUpperCase() || "-"}
              </span>{" "}
              untuk <span>{el?.remark?.split(" untuk ")[1] || "-"}</span>{" "}
            </span>
          );
        } else if (el?.remark?.includes("Perubahan Allowance")) {
          return (
            <span className={Styles.remark}>
              Perubahan Allowance{" "}
              <span>
                {el?.remark?.split(" Allowance ")[1]?.split(" Posisi ")[0] ||
                  "-"}
              </span>{" "}
              Posisi{" "}
              <span>
                {el?.remark
                  ?.split(" Posisi ")[1]
                  ?.split(" untuk ")[0]
                  ?.toUpperCase() || "-"}
              </span>{" "}
              untuk <span>{el?.remark?.split(" untuk ")[1] || "-"}</span>{" "}
            </span>
          );
        } else {
          return <span className={Styles.remark}>{el?.remark}</span>;
        }

      case "ExpectedEquipment":
        if (el?.remark?.includes("Perubahan Personal Work Equipment")) {
          return (
            <span className={Styles.remark}>
              Perubahan Personal Work Equipment{" "}
              <span>
                {el?.remark?.split(" Equipment ")[1]?.split(" Posisi ")[0] ||
                  "-"}
              </span>{" "}
              Posisi{" "}
              <span>
                {el?.remark
                  ?.split(" Posisi ")[1]
                  ?.split(" untuk ")[0]
                  ?.toUpperCase() || "-"}
              </span>{" "}
              untuk <span>{el?.remark?.split(" untuk kota ")[1] || "-"}</span>{" "}
            </span>
          );
        } else if (
          el?.remark?.includes("Penghapusan Personal Work Equipment")
        ) {
          return (
            <span className={Styles.remark}>
              Penghapusan Personal Work Equipment{" "}
              <span>
                {el?.remark?.split(" Equipment ")[1]?.split(" Posisi ")[0] ||
                  "-"}
              </span>{" "}
              Posisi{" "}
              <span>
                {el?.remark
                  ?.split(" Posisi ")[1]
                  ?.split(" untuk ")[0]
                  ?.toUpperCase() || "-"}
              </span>{" "}
              untuk <span>{el?.remark?.split(" untuk ")[1] || "-"}</span>{" "}
            </span>
          );
        } else if (el?.remark?.includes("Penambahan Personal Work Equipment")) {
          return (
            <span className={Styles.remark}>
              Personal Work Equipment{" "}
              <span>
                {el?.remark?.split(" Equipment ")[1]?.split(" Posisi ")[0] ||
                  "-"}
              </span>{" "}
              Posisi{" "}
              <span>
                {el?.remark
                  ?.split(" Posisi ")[1]
                  ?.split(" untuk ")[0]
                  ?.toUpperCase() || "-"}
              </span>{" "}
              untuk{" "}
              <span>
                {el?.remark?.split(" untuk ")[1]?.split(" Ditambahkan")[0] ||
                  "-"}
              </span>{" "}
              Ditambahkan
            </span>
          );
        } else {
          return <span className={Styles.remark}>{el?.remark}</span>;
        }

      default:
        return <span className={Styles.remark}>{el?.remark}</span>;
    }
  }, []);

  const remarkdesc = useCallback(
    (each) => {
      switch (each?.table) {
        case "ClientContractLog":
          if (each?.remark?.includes("Penambahan")) {
            return (
              <div className={Styles.descBox}>
                <span>
                  Dokumen Kontrak{" "}
                  {each?.updateValue ? each?.updateValue?.split("::")[0] : "-"}{" "}
                  dengan nomor{" "}
                  <span>
                    {each?.updateValue
                      ? each?.updateValue?.split("::")[1]
                      : "-"}{" "}
                  </span>
                  ditambahkan
                </span>

                <span>
                  *Catatan:{" "}
                  {each?.updateValue?.split("::")[2]
                    ? each?.updateValue?.split("::")[2]?.length > maxLength
                      ? each?.updateValue
                          ?.split("::")[2]
                          ?.substring(0, maxLength) + "..."
                      : each?.updateValue?.split("::")[2]
                    : "-"}
                </span>
              </div>
            );
          } else if (each?.remark?.includes("Expired")) {
            return (
              <div className={Styles.descBox}>
                <span>
                  Masa berlaku dokumen{" "}
                  {each?.updateValue ? each?.updateValue?.split("::")[0] : "-"}{" "}
                  dengan{" "}
                  <span>
                    {each?.updateValue
                      ? each?.updateValue?.split("::")[1]
                      : "-"}{" "}
                  </span>
                  telah habis.
                </span>
              </div>
            );
          } else {
            return (
              <div className={Styles.desc}>
                <span>
                  <span> Before </span>
                  <span>
                    :{" "}
                    {each?.remark?.includes("Perubahan Catatan Dokumen")
                      ? each?.prevValue
                        ? each?.prevValue?.length > maxLength
                          ? each?.prevValue?.substring(0, maxLength) + "..."
                          : each?.prevValue
                        : "-"
                      : each?.prevValue || "-"}
                  </span>
                </span>
                <span>
                  <span>After</span>
                  <span>
                    :{" "}
                    {each?.remark?.includes("Perubahan Catatan Dokumen")
                      ? each?.updateValue
                        ? each?.updateValue?.length > maxLength
                          ? each?.updateValue?.substring(0, maxLength) + "..."
                          : each?.updateValue
                        : "-"
                      : each?.updateValue || "-"}
                  </span>
                </span>
              </div>
            );
          }
        // break;
        case "ExpectedPosition":
          if (each?.remark?.includes("Ditambahkan")) {
            return (
              <div className={Styles.descBox}>
                <span>Posisi {each?.updateValue || "-"} telah ditambahkan</span>
              </div>
            );
          } else if (each?.remark?.includes("Dihapus")) {
            return (
              <div className={Styles.descBox}>
                <span>Posisi {each?.prevValue || "-"} telah dihapus</span>
              </div>
            );
          } else if (each?.remark?.includes("Perubahan Salary Posisi")) {
            return (
              <div className={Styles.desc}>
                <span>
                  <span> Before </span>
                  <span>
                    :{" "}
                    {each?.prevValue
                      ? rupiahFormat(each?.prevValue?.split("::")[0])
                      : "-"}{" "}
                    • {each?.prevValue ? each?.prevValue?.split("::")[1] : "-"}
                  </span>
                </span>
                <span>
                  <span>After</span>
                  <span>
                    {" "}
                    :{" "}
                    {each?.updateValue
                      ? rupiahFormat(each?.updateValue?.split("::")[0])
                      : "-"}{" "}
                    •{" "}
                    {each?.updateValue
                      ? each?.updateValue?.split("::")[1]
                      : "-"}
                  </span>
                </span>
              </div>
            );
          } else {
            return (
              <div className={Styles.desc}>
                <span>
                  <span> Before </span>
                  <span>: {each?.prevValue || "-"}</span>
                </span>
                <span>
                  <span>After</span>
                  <span>: {each?.updateValue || "-"}</span>
                </span>
              </div>
            );
          }
        case "ExpectedQualification":
          if (each?.remark?.includes("Ditambahkan")) {
            return (
              <div className={Styles.descBox}>
                <span>
                  Kualifikasi{" "}
                  <span>"{each?.updateValue?.replace("::", ":") || "-"}" </span>{" "}
                  ditambahkan
                </span>
              </div>
            );
          } else if (each?.remark?.includes("Dihapus")) {
            return (
              <div className={Styles.descBox}>
                <span>
                  Kualifikasi{" "}
                  <span>"{each?.prevValue?.replace("::", ":") || "-"}"</span>{" "}
                  dihapus
                </span>
              </div>
            );
          } else {
            return (
              <div className={Styles.desc}>
                <span>
                  <span> Before </span>
                  <span>: {each?.prevValue || "-"}</span>
                </span>
                <span>
                  <span>After</span>
                  <span>: {each?.updateValue || "-"}</span>
                </span>
              </div>
            );
          }
        case "ExpectedEquipment":
          if (each?.remark?.includes("Penambahan")) {
            return (
              <div className={Styles.descBox}>
                <span>
                  Personal Work Equipment{" "}
                  {each?.updateValue?.split("::")[0] || "-"} telah ditambahkan
                  dengan nominal{" "}
                  {each?.updateValue
                    ? rupiahFormat(each?.updateValue?.split("::")[1])
                    : "-"}
                </span>
              </div>
            );
          } else if (each?.remark?.includes("Penghapusan")) {
            return (
              <div className={Styles.descBox}>
                <span>Salah satu Personal Work Equipment telah dihapus</span>
              </div>
            );
          } else {
            return (
              <div className={Styles.desc}>
                <span>
                  <span> Before </span>
                  <span>
                    : {each?.prevValue ? rupiahFormat(each?.prevValue) : "-"}
                  </span>
                </span>
                <span>
                  <span>After</span>
                  <span>
                    :{" "}
                    {each?.updateValue ? rupiahFormat(each?.updateValue) : "-"}
                  </span>
                </span>
              </div>
            );
          }
        case "ExpectedAllowance":
          if (each?.remark?.includes("Ditambahkan")) {
            return (
              <div className={Styles.descBox}>
                <span>
                  Allowance {each?.updateValue?.split("::")[0] || "-"} telah
                  ditambahkan dengan nominal{" "}
                  {each?.updateValue
                    ? rupiahFormat(each?.updateValue?.split("::")[1])
                    : "-"}
                </span>
              </div>
            );
          } else if (each?.remark?.includes("Penghapusan")) {
            return (
              <div className={Styles.descBox}>
                <span>
                  Allowance {each?.prevValue || "-"} telah dihilangkan
                </span>
              </div>
            );
          } else {
            return (
              <div className={Styles.desc}>
                <span>
                  <span> Before </span>
                  <span>
                    :{" "}
                    {each?.prevValue
                      ? !isNaN(each?.prevValue)
                        ? rupiahFormat(each?.prevValue)
                        : each?.prevValue?.includes("::")
                        ? rupiahFormat(each?.prevValue?.split("::")[0])
                        : "-"
                      : "-"}{" "}
                    {!isNaN(each?.prevValue) ? null : "•"}{" "}
                    {!isNaN(each?.prevValue)
                      ? null
                      : each?.prevValue?.includes("::")
                      ? each?.prevValue?.split("::")[1]
                      : "-"}
                  </span>
                </span>
                <span>
                  <span>After</span>
                  <span>
                    :{" "}
                    {each?.updateValue
                      ? !isNaN(each?.updateValue)
                        ? rupiahFormat(each?.updateValue)
                        : each?.updateValue?.includes("::")
                        ? rupiahFormat(each?.updateValue?.split("::")[0])
                        : "-"
                      : "-"}{" "}
                    {!isNaN(each?.updateValue) ? null : "•"}{" "}
                    {!isNaN(each?.updateValue)
                      ? null
                      : each?.updateValue?.includes("::")
                      ? each?.updateValue?.split("::")[1]
                      : "-"}
                  </span>
                </span>
              </div>
            );
          }

        default:
          return (
            <div className={Styles.desc}>
              <span>
                <span> Before </span>
                <span>
                  :{" "}
                  {each?.prevValue
                    ? each?.remark === "Perubahan Management Fee"
                      ? each?.prevValue?.includes("%")
                        ? each?.prevValue
                        : rupiahFormat(each?.prevValue)
                      : each?.prevValue
                    : "-"}
                </span>
              </span>
              <span>
                <span>After</span>
                <span>
                  :{" "}
                  {each?.updateValue
                    ? each?.remark === "Perubahan Management Fee"
                      ? each?.updateValue?.includes("%")
                        ? each?.updateValue
                        : rupiahFormat(each?.updateValue)
                      : each?.updateValue
                    : "-"}
                </span>
              </span>
            </div>
          );
        // break;
      }
    },
    [maxLength, rupiahFormat]
  );

  return (
    <ModalWrapper show={selectedFLB} handleClose={onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <img src={Images.LOG_ICON} alt="" />
          <div className={Styles.middle}>
            <div className={Styles.mTop}>
              <h3>Riwayat Perubahan</h3>
              <button onClick={onClose}>
                <Icon icon={"cross"} size={24} />
              </button>
            </div>
            <div className={Styles.mBottom}>
              <p>
                Document Number : <span>{selectedFLB?.docNumber}</span>
              </p>
              {!isEditScheme && (
                <div
                  className={`${Styles.status} ${
                    selectedFLB?.isExpired
                      ? Styles.expired
                      : selectedFLB?.isVerified
                      ? Styles.verified
                      : Styles.waiting
                  }`}
                >
                  {selectedFLB?.isExpired
                    ? "Expired"
                    : selectedFLB?.isVerified
                    ? "Active"
                    : "Waiting"}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={Styles.content}>
          {onFirstLoad && (
            <div className={Styles.firstLoadLoading}>
              <LoadingAnimation />
            </div>
          )}
          {!onFirstLoad && !logs?.length && (
            <div className={Styles.empty}>
              <img src={Images.LOG_ICON} alt="" />
              <span>Log Kosong</span>
              <span>Belum ada log yang tercatat</span>
            </div>
          )}
          {!!logs?.length && (
            <div className={Styles.logs}>
              {logs?.map((each, i) => (
                <div key={i} className={Styles.each}>
                  <div className={Styles.overflowHider} />
                  <div className={Styles.bullet} />
                  <div className={Styles.main}>
                    <div>
                      {/* <span>{`${each?.prevValue && each?.prevValue !== 'null' ? 'Perubahan' : 'Penambahan'} ${each?.field?.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}`}</span> */}
                      {each?.remark ? (
                        remark(each)
                      ) : (
                        <span className={Styles.remark}>-</span>
                      )}
                      {!isEditScheme && (
                        <p>
                          {moment(new Date(each?.updatedAt))?.format(
                            "ll, HH:mm"
                          )}
                        </p>
                      )}
                    </div>
                    <div>{remarkdesc(each)}</div>
                    <div>
                      <span>Updated by</span>
                      <span>•</span>
                      <span>{each?.modifiedByName || "-"}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {totalPage > page && !isLoadingMore && (
            <div className={Styles.seeMoreWrapper}>
              <button onClick={() => fetchLogs(true)}>
                <span>Click to See more</span>
                <Icon icon={"arrow-down"} size={20} />
              </button>
            </div>
          )}

          {isLoadingMore && (
            <div className={Styles.isLoadingMore}>
              <span>Loading...</span>
            </div>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
}
