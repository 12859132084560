/* eslint-disable eqeqeq */
// import { font } from "@Assets/Fonts/calibri-normal";
import fontBoldItalic from "@Assets/Fonts/calibri-bold-italic.ttf";
import fontBold from "@Assets/Fonts/calibri-bold.ttf";
import fontItalic from "@Assets/Fonts/calibri-italic.ttf";
import font from "@Assets/Fonts/calibri-regular.ttf";
import stylesJs from "@Assets/pdfStyle";
import jsonTest from "@Assets/test.json";
import { fileBaseUrl } from "@Config/api";
import Images from "@Theme/Images";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import ExifReader from "exifreader";
import ReactDOMServer from "react-dom/server";
import Html from "react-pdf-html";

const breakCode = "ECB0C54A257D";

// eslint-disable-next-line no-unused-vars
const htmlTest = jsonTest.response;

Font.register({
  family: "Calibri",
  fonts: [
    { src: font }, // font-style: normal, font-weight: normal
    { src: fontBold, fontWeight: "bold" },
    { src: fontBoldItalic, fontWeight: "bold", fontStyle: "italic" },
    { src: fontItalic, fontStyle: "italic" },
  ],
});

const styleshtml = {
  li: {
    fontFamily: "Calibri",
    paddingRight: 30,
    // margin: 8,
  },
  ol: {
    fontSize: "14px",
  },
  p: {
    margin: 0,
    fontFamily: "Calibri",
    whiteSpace: "pre-wrap",
  },
  span: {
    whiteSpace: "pre-wrap",
  },
  strong: {
    fontFamily: "Calibri",
    fontWeight: "bold",
  },
  a: {
    color: "black",
    fontWeight: "normal",
    textDecoration: "none",
    "&>:after": {
      content: "&nbsp",
    },
  },
};

const styles = stylesJs;

const stylesIn = StyleSheet.create({
  body: {
    paddingLeft: 50,
    paddingRight: 50,
  },
  header: {
    marginBottom: 50,
    marginTop: 20,
    display: "flex",
    paddingRight: 50,
    // alignContent: "left",
    alignItems: "flex-end",
  },
  footerPage: {
    padding: 10,
    paddingBottom: 12,
    backgroundColor: "#5470b3",
    color: "white",
    fontSize: 12,
    display: "grid",
    placeItems: "center",
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingBottom: 10,
    marginTop: 80,
    paddingLeft: 30,
    paddingRight: 30,
  },
  textSign: {
    fontSize: 12,
    color: "#9e9e9e",
  },
  textNeutral60: {
    fontSize: 10,
    color: "#9e9e9e",
    marginBlock: "4px",
  },
  textDanger: {
    fontSize: 11,
    color: "#c26060",
  },
  footer: { position: "absolute", bottom: 0, width: "100%" },
  signatureContainer: {
    display: "flex",
    paddingHorizontal: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 50,
  },
  signatureText: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Calibri",
  },
  signatureTextName: {
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: "Calibri",
    textDecoration: "underline",
  },
  signature: {
    width: "auto",
    height: 50,
    marginTop: 20,
    marginBottom: 20,
  },
  initials: {
    width: 50,
    height: 50,
    marginBottom: 10,
  },
});

const PdfFromString = ({ pdfContent, data }) => {
  const parsedContent = new DOMParser().parseFromString(
    pdfContent,
    "application/xml"
  );

  const renderChildNodes = (parent) => {
    const children = Array.from(parent.childNodes);

    return children.map((child, index) => {
      if (child.nodeType === 1) {
        const tagName = child.tagName.toLowerCase();
        const attributes = Array.from(child.attributes).reduce((acc, attr) => {
          acc[attr.name] = attr.value;
          return acc;
        }, {});

        switch (tagName) {
          case "document":
            return (
              <Document
                {...attributes}
                key={index}
                style={styles[attributes.style]}
                // wrap={attributes.wrap === "true"}
              >
                {renderChildNodes(child)}
              </Document>
            );
          case "page":
            return (
              <Page
                {...attributes}
                key={index}
                style={styles[attributes.style]}
                fixed={attributes.fixed === "true"}
                // wrap={attributes.wrap === "true"}
              >
                {renderChildNodes(child)}
              </Page>
            );
          case "view":
            // console.log(attributes.wrap === "true")
            return (
              <View
                {...attributes}
                key={index}
                style={styles[attributes.style]}
                fixed={attributes.fixed === "true"}
                wrap={attributes.wrap === "true" ? false : "false"}
              >
                {renderChildNodes(child)}
              </View>
            );
          case "text":
            if (attributes.className === "pagination") {
              return (
                <Text
                  {...attributes}
                  key={index}
                  style={styles[attributes.style]}
                  fixed={attributes.fixed === "true"}
                  render={({ pageNumber, totalPages }) =>
                    "Page " + pageNumber + " / " + totalPages
                  }
                  // wrap={attributes.wrap === "true"}
                >
                  {child.textContent}
                </Text>
              );
            } else {
              return (
                <Text
                  {...attributes}
                  key={index}
                  style={styles[attributes.style]}
                  fixed={attributes.fixed === "true"}
                  // wrap={attributes.wrap === "true"}
                >
                  {child.textContent}
                </Text>
              );
            }
          case "image":
            return (
              <Image
                {...attributes}
                key={index}
                style={styles[attributes.style]}
                src={
                  Images[attributes.src] ||
                  data[attributes.src] ||
                  attributes.src
                }
                fixed={attributes.fixed === "true"}
                // wrap={attributes.wrap === "true"}
              />
            );
          case "br":
            return <br />;
          default:
            return null;
        }
      }
      return null;
    });
  };

  return <>{renderChildNodes(parsedContent)}</>;
};

const bmSign = [
  "17023684555510-201221259 JENNIFER WINARSO.png-png",
  "1718163866127-203160600 SYUKRON.png-png",
  "17023684555532-201160655 LISZA ZULTALISZA.png-png",
  "17023684555533-204110137 VERONICA CHANDRA DEWI.png-png",
  "17023684555534-202110199 OCTAVIA YOSEPHINE PASARIBU.png-png",
  "17023684555545-203130355 YOVITA RIZKHA AQUANIETA.png-png",
  "17023684555546-201100038 ASRI IBRAHIM.png-png",
  "1718163826778-201160650 FITRIA.png-png",
  "17175924720860-206120311 ADHITYA EDDY PUTRA.png-png",
  "1718163797618-205110185 JANI MULIAWATI.png-png",
  "1718163847208-210180945 MUKTI.png-png",
  "170236845555411-201211195 FELISA ELFRIDHA GUNAWAN.png-png",
  "17023707230710-201221265 AGNES BS.png-png",
];

const hcSign = "17023768046330-SAWITRI (1).png-png";

export default async function convertHtmlToPdf(
  defaultHtml,
  type = "contract",
  data = null
) {
  const html = defaultHtml.replace(/<\/a>/g, " </a>");
  const pageBreaked = html
    .split(breakCode)
    .join("0667A5FDF1C7")
    .split("0667A5FDF1C7");

  const documents = (html) => {
    return (
      <Page
        size="A4"
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          paddingBottom: 200,
        }}
      >
        <View style={stylesIn.header} fixed>
          <Image
            src={Images.PERMATA_LOGO_FULL}
            style={{ width: "20%", height: "auto" }}
          />
        </View>
        <View style={stylesIn.body}>
          <Html stylesheet={styleshtml}>
            {ReactDOMServer.renderToStaticMarkup(
              <html lang="en">
                <div
                  dangerouslySetInnerHTML={{ __html: html }}
                  className="htmlRenderWrapper"
                ></div>
              </html>
            )}
          </Html>
        </View>
        <View style={stylesIn.signatureContainer} wrap={false}>
          <View style={stylesIn.signatureWrapper}>
            <Text style={stylesIn.signatureText}>Perusahaan</Text>
            <View>
              {data && data?.signature !== "" ? (
                <View style={stylesIn.signature} />
              ) : (
                <View style={stylesIn.signature} />
              )}
              <Text style={stylesIn.signatureTextName}>Sawitri</Text>
              <Text style={stylesIn.signatureText}>Human Capital Manager</Text>
            </View>
          </View>
          <View style={stylesIn.signatureWrapper}>
            <Text style={stylesIn.signatureText}>Karyawan</Text>
            {data && data?.signature !== "" ? (
              <View>
                <Image style={stylesIn.signature} src={data.signature} />
              </View>
            ) : (
              <View style={stylesIn.signature} />
            )}
            <View>
              {data && data?.name !== "" ? (
                <Text style={stylesIn.signatureTextName}>{data.name}</Text>
              ) : (
                <Text style={stylesIn.signatureTextName}>
                  {"{CANDIDATE_NAME}"}
                </Text>
              )}
              <Text style={stylesIn.signatureText}>
                {"{CANDIDATE_POSITION}"}
              </Text>
            </View>
          </View>
        </View>
        <View fixed />
        <View style={stylesIn.footer} fixed>
          <View style={stylesIn.footerContainer}>
            <View>
              {data && data?.initials !== "" && (
                <View>
                  <Image style={stylesIn.initials} src={data.initials} />
                </View>
              )}
              <View>
                <Text style={stylesIn.textSign}>Paraf karyawan</Text>
              </View>

              {/* {data && data?.signature && (
                <View
                  render={({ pageNumber, totalPages }) =>
                    pageNumber + pageBreaked.length <= totalPages && (
                      <View>
                        <Image
                          style={{
                            width: 50,
                            height: 50,
                            marginBottom: 10,
                          }}
                          src={data?.signature}
                        />
                      </View>
                    )
                  }
                />
              )} */}
            </View>
            <View>
              <Text style={stylesIn.textDanger}>
                PT. Permata Indo Sejahtera
              </Text>
              <Text style={stylesIn.textNeutral60}>
                Jl. Raya Kebayoran Lama No. 226
              </Text>
              <Text style={stylesIn.textNeutral60}>Jakarta Selatan 12220</Text>
              <Text style={stylesIn.textNeutral60}>
                T. 6221 726 5362 [hunting]
              </Text>
              <Text style={stylesIn.textNeutral60}>F. 6221 727 86574</Text>
              <Text style={stylesIn.textNeutral60}>
                www.permataindonesia.com
              </Text>
            </View>
          </View>
          <View style={stylesIn.footerPage}>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    );
  };

  const additionals = (html) => {
    return (
      <Page size="A4">
        <View style={stylesIn.body}>
          <Html stylesheet={styleshtml}>
            {ReactDOMServer.renderToStaticMarkup(
              <html lang="en">
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
              </html>
            )}
          </Html>
        </View>
        <View fixed style={{ marginTop: 200 }} />
        <View style={{ position: "absolute", bottom: 0, width: "100%" }} fixed>
          <View style={stylesIn.footerContainer}>
            <View></View>
            <View>
              <Text style={stylesIn.textDanger}>
                PT. Permata Indo Sejahtera
              </Text>
              <Text style={stylesIn.textNeutral60}>
                Jl. Raya Kebayoran Lama No. 226
              </Text>
              <Text style={stylesIn.textNeutral60}>Jakarta Selatan 12220</Text>
              <Text style={stylesIn.textNeutral60}>
                T. 6221 726 5362 [hunting]
              </Text>
              <Text style={stylesIn.textNeutral60}>F. 6221 727 86574</Text>
              <Text style={stylesIn.textNeutral60}>
                www.permataindonesia.com
              </Text>
            </View>
          </View>
          <View style={stylesIn.footerPage}>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    );
  };

  const picSign = () => {
    if (data?.stage === "DONE" || data?.stage === "IOS-SUCCESS") {
      if (data?.contractType === "PKWT") {
        return fileBaseUrl + hcSign;
      } else {
        return (
          fileBaseUrl +
          bmSign.find((el) => el.includes(data?.businessManagerID))
        );
      }
    } else {
      return "no sign";
    }
  };

  const HandleConvertString = ({ html, index }) => {
    if (html.includes("<Page")) {
      return (
        <PdfFromString
          pdfContent={html
            .replace(/style={styles\.(\w+)}/g, 'style="$1"')
            .replace(
              "<Text>Paraf Karyawan</Text>",
              '<View><Image src="initials" style="paraf"/><Text>Paraf Karyawan</Text></View>'
            )}
          data={{
            ...data,
            second_party_sign: data?.signature,
            first_party_sign: picSign(),
          }}
        />
      );
    } else {
      if (index === 0) {
        return documents(html);
      } else {
        return additionals(html);
      }
    }
  };

  const imageObj = {
    url:
      data?.image && !data.image.includes("null")
        ? typeof data?.image === "string"
          ? data.image.includes("https") || data.image.includes("http")
            ? data.image
            : fileBaseUrl + data.image
          : URL.createObjectURL(data?.image)
        : "",
  };

  const imageidentityCardImageObj = {
    url:
      data?.identityCardImage && !data.identityCardImage.includes("null")
        ? typeof data?.identityCardImage === "string"
          ? data.identityCardImage.includes("https") ||
            data.identityCardImage.includes("http")
            ? data.identityCardImage
            : fileBaseUrl + data.identityCardImage
          : URL.createObjectURL(data?.identityCardImage)
        : "",
  };

  if (imageObj?.url) {
    const res = await fetch(imageObj.url);
    const blob = await res.blob();
    const arrBuff = await blob.arrayBuffer();
    const tags = ExifReader.load(arrBuff);
    imageObj.exif = tags;
  }

  if (imageidentityCardImageObj?.url) {
    const res = await fetch(imageidentityCardImageObj.url);
    const blob = await res.blob();
    const arrBuff = await blob.arrayBuffer();
    const tags = ExifReader.load(arrBuff);
    imageidentityCardImageObj.exif = tags;
  }

  // const imgStyles = StyleSheet.create({
  //   imgRotOne: {
  //     width: "100%",
  //     height: "150px",
  //     objectFit: "cover",
  //   },
  //   imgRotSix: {
  //     transform: "rotate(90deg)",
  //     width: "150px",
  //     height: "100%",

  //     objectFit: "cover",
  //   },
  //   imgRotThree: {
  //     transform: "rotate(180deg)",
  //     width: "150px",
  //     height: "100%",

  //     objectFit: "cover",
  //   },
  //   imgRotEight: {
  //     transform: "rotate(270deg)",
  //     width: "150px",
  //     height: "100%",
  //     objectFit: "cover",
  //   },
  // });

  const imgStyles = StyleSheet.create({
    imgRotOne: {
      width: "100%",
      maxHeight: "300px",
      objectFit: "cover",
      // objectFit: "cover",
      // marginTop: '-15px',
    },
    imgRotSix: {
      transform: "rotate(90deg)",
      width: "100%",
      maxHeight: "300px",
      objectFit: "cover",
      // marginLeft: '-33px',
      // marginTop: '18px',
    },
    imgRotThree: {
      transform: "rotate(180deg)",
      width: "100%",
      maxHeight: "300px",
      objectFit: "cover",
      // marginTop: '-15px',
    },
    imgRotEight: {
      transform: "rotate(270deg)",
      width: "100%",
      maxHeight: "300px",
      objectFit: "cover",
      // marginLeft: '-33px',
      // marginTop: '18px',
    },
  });

  let style;
  const rotVal = imageObj.exif?.Orientation?.value;
  if (rotVal == 1 || rotVal == undefined) {
    style = imgStyles.imgRotOne;
  } else if (rotVal == 3) {
    style = imgStyles.imgRotThree;
  } else if (rotVal == 6) {
    style = imgStyles.imgRotSix;
  } else if (rotVal == 8) {
    style = imgStyles.imgRotEight;
  }

  let styleIdentityCardImage;
  const rotValIdentityCardImage =
    imageidentityCardImageObj.exif?.Orientation?.value;
  if (rotValIdentityCardImage == 1 || rotValIdentityCardImage == undefined) {
    styleIdentityCardImage = imgStyles.imgRotOne;
  } else if (rotValIdentityCardImage == 3) {
    styleIdentityCardImage = imgStyles.imgRotThree;
  } else if (rotValIdentityCardImage == 6) {
    styleIdentityCardImage = imgStyles.imgRotSix;
  } else if (rotValIdentityCardImage == 8) {
    styleIdentityCardImage = imgStyles.imgRotEight;
  }

  const signPage = () => {
    return (
      <Page
        size="A4"
        style={
          imageidentityCardImageObj?.url
            ? styles.pageSign
            : styles.pageSignOther
        }
      >
        <View style={styles.header} fixed={true}>
          <Text style={styles.space}></Text>
          <Image style={styles.mainLogo} src={Images.contract_logo} />
        </View>

        {imageidentityCardImageObj?.url ? (
          <View style={styles.signContainer}>
            <View style={styles.signTitle}>
              <Text>Detail Penandatangan Kontrak Kerja</Text>
            </View>

            <View style={styles.photoProfileBox}>
              <View style={styles.photoBox}>
                <View style={styles.photoTitle}>
                  <Text>Foto Selfie</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    width: "100%",
                  }}
                >
                  <Image
                    src={imageObj?.url ? imageObj.url : "no image"}
                    style={imageObj?.url ? style : styles.image}
                  />
                </View>

                <View
                  style={{
                    paddingTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                  }}
                >
                  <View style={styles.photoTitle}>
                    <Text>Lokasi Foto Selfie</Text>
                  </View>
                  <View style={styles.addressBox}>
                    <View style={styles.address}>
                      <Text style={styles.addressTitle}>Alamat</Text>
                      <Text style={styles.addressText}>
                        {data?.imageAddress || "-"}
                      </Text>
                    </View>

                    <View style={styles.longLatBox}>
                      <View style={styles.longLat}>
                        <Text style={styles.titleLonglat}>Latitude</Text>
                        <Text style={styles.longLatText}>
                          {data?.imageLatitude || 0}
                        </Text>
                      </View>
                      <View style={styles.longLat}>
                        <Text style={styles.titleLonglat}>Longtitude</Text>
                        <Text style={styles.longLatText}>
                          {data?.imageLongitude || 0}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.photoBox}>
                <View style={styles.photoTitle}>
                  <Text>Foto KTP</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    width: "100%",
                  }}
                >
                  <Image
                    src={
                      imageidentityCardImageObj?.url
                        ? imageidentityCardImageObj.url
                        : "no image"
                    }
                    style={
                      imageidentityCardImageObj?.url
                        ? styleIdentityCardImage
                        : styles.image
                    }
                  />
                </View>
                <View
                  style={{
                    paddingTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                  }}
                >
                  <View style={styles.photoTitle}>
                    <Text>Lokasi Foto KTP</Text>
                  </View>

                  <View style={styles.addressBox}>
                    <View style={styles.address}>
                      <Text style={styles.addressTitle}>Alamat</Text>
                      <Text style={styles.addressText}>
                        {data?.identityCardAddress || "-"}
                      </Text>
                    </View>

                    <View style={styles.longLatBox}>
                      <View style={styles.longLat}>
                        <Text style={styles.titleLonglat}>Latitude</Text>
                        <Text style={styles.longLatText}>
                          {data?.identityCardLatitude || 0}
                        </Text>
                      </View>
                      <View style={styles.longLat}>
                        <Text style={styles.titleLonglat}>Longtitude</Text>
                        <Text style={styles.longLatText}>
                          {data?.identityCardLongitude || 0}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            {data?.address && data?.latitude !== 0 && data?.longitude && (
              <View style={styles.endingSection}>
                <Text style={styles.signTitleOther}>
                  Penandatangan kontrak ini dilakukan oleh {data.name || "-"}{" "}
                  dengan detail lokasi:
                </Text>

                <View style={styles.addressBox}>
                  <View style={styles.address}>
                    <Text style={styles.addressTitle}>Alamat</Text>
                    <Text style={styles.addressText}>
                      {data?.address || "-"}
                    </Text>
                  </View>

                  <View style={styles.longLatBox}>
                    <View style={styles.longLat}>
                      <Text style={styles.titleLonglat}>Latitude</Text>
                      <Text style={styles.longLatText}>
                        {data?.latitude || 0}
                      </Text>
                    </View>
                    <View style={styles.longLat}>
                      <Text style={styles.titleLonglat}>Longtitude</Text>
                      <Text style={styles.longLatText}>
                        {data?.longitude || 0}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            )}
          </View>
        ) : (
          <View style={styles.signContainer}>
            <View style={styles.signTitle}>
              <Text>Penandatanganan Kontrak Ini Dilakukan Oleh</Text>
            </View>
            <View style={styles.imageWrapper}>
              <View>
                <Image
                  src={imageObj?.url ? imageObj.url : "no image"}
                  style={imageObj?.url ? style : styles.image}
                />
              </View>
              <View styles={styles.imageTextWrapper}>
                <View style={styles.candidateName}>
                  <Text>{data.name}</Text>
                </View>
                <View style={styles.candidatePosition}>
                  <Text>{data.position}</Text>
                </View>
              </View>
            </View>
            <View>
              <View style={styles.subTitleSign}>
                <Text>Lokasi Tanda Tangan</Text>
              </View>
              <View style={styles.locationContainer}>
                <View style={styles.locationWrapper1}>
                  <View style={styles.locationLabel}>
                    <Text>Alamat</Text>
                  </View>
                  <View style={styles.locationValue}>
                    <Text>-</Text>
                  </View>
                </View>
                <View style={styles.locationWrapper2}>
                  <View style={styles.location2}>
                    <View style={styles.locationLabel}>
                      <Text>Latitude</Text>
                    </View>
                    <View style={styles.locationValue}>
                      <Text>{data.latitude}</Text>
                    </View>
                  </View>
                  <View style={styles.location2}>
                    <View style={styles.locationLabel}>
                      <Text>Longitude</Text>
                    </View>
                    <View style={styles.locationValue}>
                      <Text>{data.longitude}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        )}
      </Page>
    );
  };

  const documentPdfScript = (
    <Document>
      {pageBreaked.map((el, idx) => (
        <HandleConvertString html={el} index={idx} key={idx} />
      ))}
      {Boolean(data?.signature) && signPage()}
    </Document>
  );

  const documentAdditional = (
    <Document>
      <Page
        size="A4"
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          paddingBottom: 200,
        }}
      >
        <View style={{ marginBottom: 80 }} fixed></View>
        <View style={stylesIn.body}>
          <Html stylesheet={styleshtml}>
            {ReactDOMServer.renderToStaticMarkup(
              <html lang="en">
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
              </html>
            )}
          </Html>
        </View>
      </Page>
    </Document>
  );

  if (type === "ADDITIONAL") {
    const blob = await pdf(documentAdditional).toBlob();
    return URL.createObjectURL(blob);
  } else {
    const blob = await pdf(documentPdfScript).toBlob();
    return URL.createObjectURL(blob);
  }
}
