import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  LineController,
  PointElement,
} from "chart.js";
import Styles from "./style.module.scss";

import ChartDataLabels from "chartjs-plugin-datalabels";

import Icon from "@Atom/Icon";
import { useMemo, useRef, useState } from "react";
import { Bar, getElementsAtEvent } from "react-chartjs-2";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  LineElement,
  LineController,
  PointElement,
  Legend
);

export default function WorkSchemeSection({
  data,
  windowSize,
  orderData,
  // cities,
}) {
  const chartRef = useRef();

  const [isAll, setIsAll] = useState(true);

  const [selectedBar, setSelectedBar] = useState(null);
  const activeIndex = useMemo(() => {
    return (
      data?.findIndex((object) => {
        return object === selectedBar;
      }) || 0
    );
  }, [selectedBar, data]);

  const chartWidth = useMemo(() => {
    let width;

    if (windowSize[0] <= 480) {
      if (data?.length < 2) {
        width = "100%";
      } else {
        width = `calc(100% + ${(data?.length - 1) * 100}px)`;
      }
    } else if (windowSize[0] > 480 && windowSize[0] <= 768) {
      if (data?.length < 4) {
        width = "100%";
      } else {
        width = `calc(100% + ${(data?.length - 3) * 100}px)`;
      }
    } else if (windowSize[0] > 768) {
      if (data?.length < 10) {
        width = "100%";
      } else {
        width = `calc(100% + ${(data?.length - 9) * 100}px)`;
      }
    } else {
      width = "100%";
    }

    return String(width);
  }, [data, windowSize]);

  const options = {
    elements: {
      point: {
        pointStyle: { border: "1px solid #000000" },
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        data: ["Target", "Pipeline", "Hired"],
        align: "end",
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded'
        }
      },
    },
    responsive: true,
    onHover: (event, chartElement) => {
      if (chartElement.length === 1) {
        event.native.target.style.cursor = "pointer";
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: 12,
            family: 'Poppins'
          },
        },
        grid: {
          display: false,
          drawOnChartArea: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback: function (val, index) {
            return index % 2 === 0 && +val <= 100
              ? this.getLabelForValue(val) + "%"
              : "";
          },
          color: "#6F6F70",
          font: {
            size: 12,
            family: 'Poppins'
          },
          step: "10%",
        },
        border: { dash: [4, 4] },
        beginAtZero: true,
        // grace: '5%',
        grid: {
          drawBorder: false,
        },
      },
    },
    maintainAspectRatio: false,
  };

  const labels = useMemo(() => {
    return data?.map((each, i) => each?.position);
  }, [data]);

  const dataToMap = {
    labels,
    datasets: [
      {
        type: "line",
        label: "Target",
        data: data.map((each, i) => (each?.contractedAmount / each.requestAmount) * 100),
        borderColor: "#77B5FF",
        backgroundColor: "#fff",
        tension: 0.1,
        pointStyle: "circle",
        pointRadius: 6,
        // pointBorderColor: "0px 4px 9px rgba(0, 0, 0, 0.16)",
        pointBorderColor: "#c2c2c2",
        datalabels: {
          color: "#000000",
          align: 320,
          anchor: "end",
          formatter: function (value, context) {
            const position = context.chart.data.labels[context.dataIndex];

            const selected = data.find((el) => {
              return el.position === position;
            });
            return selected.requestAmount;
          },
        },
      },
      {
        type: "bar",
        label: "Hired",
        data: data.map((each, i) => each?.contractedPercentage),
        backgroundColor: "#1571DE",
        borderRadius: 8,
        // barPercentage: 0.2,
        stack: "combined",
        maxBarThickness: 40,
        datalabels: {
          color: (chart) => {
            if (isAll) {
              return "#fff";
            } else {
              return chart.dataIndex === activeIndex ? "#fff" : "#9e9e9e";
            }
          },
          align: "start",
          anchor: "end",
          formatter: function (value, context) {
            const position = context.chart.data.labels[context.dataIndex];

            const selected = data.find((el) => {
              return el.position === position;
            });
            return selected.contractedAmount;
          },
        },
      },
      {
        type: "bar",
        label: "Pipeline",
        data: data.map((each, i) => each?.remainingPercentage),
        backgroundColor: "#FD9434",
        borderRadius: 8,
        stack: "combined",
        // barPercentage: 0.4,
        maxBarThickness: 40,
        datalabels: {
          color: (chart) => {
            if (isAll) {
              return "#fff";
            } else {
              return chart.dataIndex === activeIndex ? "#fff" : "#9e9e9e";
            }
          },
          align: "start",
          anchor: "end",
          formatter: function (value, context) {
            const position = context.chart.data.labels[context.dataIndex];

            const selected = data.find((el) => {
              return el.position === position;
            });
            return selected.pipeline;
          },
        },
      },
    ],
  };

  // eslint-disable-next-line
  const handleClickBar = (event) => {
    setIsAll(false);
    const activeIndexOnClick = getElementsAtEvent(chartRef.current, event)[1]
      ?.index;

    setSelectedBar(data[activeIndexOnClick] || selectedBar);
  };

  const handleViewAll = () => {
    setSelectedBar(null);
    setIsAll(true);
  };

  const sumArray = (arr) => {
    let sum = 0;

    for (let i = 0; i < arr.length; i += 1) {
      sum += arr[i];
    }

    return sum;
  };

  const summary = useMemo(() => {
    if (isAll) {
      return {
        invited: sumArray(
          data?.map((each, i) => {
            return each?.invited;
          })
        ),
        interviewed: sumArray(
          data?.map((each, i) => {
            return each?.interviewed;
          })
        ),
        onClient: sumArray(
          data?.map((each, i) => {
            return each?.onClient;
          })
        ),
        contracted: sumArray(
          data?.map((each, i) => {
            return each?.contractedAmount;
          })
        ),
      };
    } else {
      return {
        invited: selectedBar?.invited,
        interviewed: selectedBar?.interviewed,
        onClient: selectedBar?.onClient,
        contracted: selectedBar?.contractedAmount,
      };
    }
  }, [isAll, data, selectedBar]);

  const schemes = useMemo(() => {
    const arr = [];
    const sortedData = orderData?.sort((a, b) => {
      return new Date(b?.createdAt) - new Date(a?.createdAt);
    });

    for (let i = 0; i < sortedData.length; i++) {
      const data = sortedData[i];
      const cities = [];
      const jobs = [];

      for (let j = 0; j < data?.manPowers?.length; j++) {
        const manPower = data?.manPowers[j];
        if (!cities?.includes(manPower?.City?.name)) {
          cities?.push(manPower?.City?.name);
        }
        if (!jobs?.includes(manPower?.name)) {
          jobs?.push(manPower?.name);
        }
      }

      const each = {
        id: data?.id,
        type:
          data?.contractType?.toLowerCase() === "jasa pemborongan"
            ? "PKM"
            : "PJTK",
        locations: cities,
        jobs: jobs,
        date: moment(new Date(data?.createdAt)).format("LL"),
      };

      arr.push(each);
    }
    return arr;
  }, [orderData]);

  // const getLocationNames = (locationArr) => {
  //   const arr = [];
  //   for (let i = 0; i < locationArr.length; i++) {
  //     const locationCode = locationArr[i];
  //     const city = cities?.find((c) => c.code === locationCode)?.name;
  //     const cityName = city[0]?.toUpperCase() + city?.slice(1)?.toLowerCase();
  //     arr.push(cityName);
  //   }
  //   return arr;
  // };

  const navigate = useNavigate();

  const query = useQuery();
  const name = query.get("n");
  const code = query.get("c");

  // const handleNavigateToSchemes = () => {
  //   navigate(`/clients/orders?n=${name}&c=${code}`);
  // };

  return (
    <div className={Styles.container}>
      {/* <div className={Styles.header}>
        <h3>Orders</h3>
        <button onClick={handleNavigateToSchemes}>See All</button>
      </div> */}
      <div className={Styles.schemeSection}>
        {schemes?.slice(0, 2)?.map((each, i) => {
          return (
            <div
              key={i}
              className={Styles.schemeCard}
              onClick={() =>
                navigate(
                  `/clients/orders/details/${each?.id}?n=${name}&c=${code}`
                )
              }
            >
              <div className={Styles.left}>
                <Icon icon="work-scheme" className={Styles.Icon} size={22} />
              </div>
              <div className={Styles.middle}>
                <h5>
                  {each?.jobs?.slice(0, 3)?.join(" • ")}
                  {each?.jobs?.length > 3 && ` +${each?.jobs?.length - 3} more`}
                </h5>
                <span>{each?.type}</span>
                <div className={Styles.location}>
                  <Icon icon="location" size={18} />
                  <span>{(each?.locations)?.join(", ")}</span>
                </div>
                <span>{each?.date}</span>
              </div>
              <div className={Styles.right}>
                <Icon
                  icon="information-solid"
                  className={Styles.Icon}
                  size={22}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className={Styles.chartSection}>
        <div className={Styles.topSubSection}>
          <div className={Styles.subSectionHeader}>
            <h4>Man Power Summary</h4>
          </div>
          <div className={Styles.chartDoubleWrapper}>
            <div
              className={`${Styles.chartWrapper} ${data?.length > 3 && Styles.chartWrapperExtra
                }`}
              style={{ width: chartWidth }}
            >
              <Bar
                ref={chartRef}
                options={options}
                data={dataToMap}
              // onClick={handleClickBar}
              />
            </div>
          </div>
        </div>
        {/* <hr /> */}
        <div style={{ display: 'none' }} className={Styles.BottomSubSection}>
          <div className={Styles.subSectionHeader}>
            <h4>{isAll ? "All" : selectedBar?.position}</h4>
            <button disabled={isAll} onClick={handleViewAll}>
              View All Summary
            </button>
          </div>
          <div className={Styles.subSectionCard}>
            <div className={Styles.stackedSummary}>
              <div className={Styles.leftStack}>
                <Icon icon="invited" size={18} />
                <h5>Interview Invited</h5>
              </div>
              <div className={Styles.rightStack}>
                <p>{summary?.invited}</p>
              </div>
            </div>
            <div className={Styles.stackedSummary}>
              <div className={Styles.leftStack}>
                <Icon icon="interviewed" size={18} />
                <h5>Interviewed</h5>
              </div>
              <div className={Styles.rightStack}>
                <p>{summary?.interviewed}</p>
              </div>
            </div>
            <div className={Styles.stackedSummary}>
              <div className={Styles.leftStack}>
                <Icon icon="noteboard-outlined" size={18} />
                <h5>Assigned to Client</h5>
              </div>
              <div className={Styles.rightStack}>
                <p>{summary?.onClient}</p>
              </div>
            </div>
            <div className={Styles.stackedSummary}>
              <div className={Styles.leftStack}>
                <Icon icon="briefcase-outlined" size={18} />
                <h5>Contracted</h5>
              </div>
              <div className={Styles.rightStack}>
                <p>{summary?.contracted}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
