/* eslint-disable no-unused-vars */
import UseOutsideClick from "@Hooks/useOutsideClick";
import useQuery from "@Hooks/useQuery";
import Pagination from "@Molecule/Pagination";
import CandidateDetailModal from "@Molecule/_recruitment/CandidateDetailModal";
import CandidateInformationModal from "@Molecule/_recruitment/CandidateInformationModal";
import { getCandidates } from "@Services/officer/recruitment";
import {
  Avatar,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import debounce from "lodash.debounce";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import ModalWrapper from "@Atom/ModalWrapper";
import MoveCandidateModal from "@Molecule/_modal/MoveCandidateModal";
import { fileBaseUrl } from "@Config/api";
import Images from "@Theme/Images";
import TableEmptyHandler from "@Atom/TableEmptyHandler";

const CustomInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    width: "100%",
    border: "none",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#0A0A0A",

    "&:focus": {
      border: "none",
      // color: '#1571DE',
      boxShadow: "none",
    },
  },
});

const TABLE_HEAD = [
  { id: "name", label: "Nama Kandidat", align: "left" },
  { id: "position", label: "Posisi", align: "left" },
  { id: "portal", label: "Sumber", align: "left" },
  // { id: "location", label: "Lokasi", align: "left" },
  { id: "interviewDate", label: "Waktu Interview", align: "left" },
  { id: "interviewLink", label: "Undangan Interview", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "phoneNumber", label: "Nomor Handphone", align: "left" },
  { id: "domicile", label: "Domisili", align: "left" },
  { id: "address", label: "Alamat", align: "left" },
  { id: "education", label: "Pendidikan", align: "left" },
  { id: "invitationNotes", label: "Catatan Undangan", align: "left" },
  { id: "rejectionNote", label: "Alasan Tidak Lolos", align: "left" },
  // { id: "lastExperience", label: "Pengalaman Terakhir", align: "left" },
  { id: "actions", label: "Actions", align: "center" },
];

export default function RejectedList({
  searchTerm,
  positionFilter,
  locationFilter,
  checkedArr,
  setCheckedArr,
  trigger,
  setTrigger,
  positionIDFilter,
}) {
  const [value, setValue] = useState(5);
  const [data, setData] = useState([]);

  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const [dataToMove, setDataToMove] = useState(null);

  const allIds = useMemo(() => {
    const arr = [];

    for (let i = 0; i < data?.length; i++) {
      arr.push(data[i]?.id);
    }

    return arr;
  }, [data]);

  const isCheckedAll = useMemo(() => {
    return allIds.every((el) => checkedArr.includes(el));
  }, [allIds, checkedArr]);

  const isItemChecked = useCallback(
    (id) => {
      return checkedArr.includes(id);
    },
    [checkedArr]
  );

  const handleCheck = (id) => {
    const index = checkedArr.indexOf(id);
    if (index === -1) {
      setCheckedArr([...checkedArr, id]);
    } else {
      const newArr = [...checkedArr];
      newArr.splice(index, 1);
      setCheckedArr(newArr);
    }
  };

  const handleCheckAll = useCallback(() => {
    const arr = [];
    for (let i = 0; i < checkedArr.length; i++) {
      const index = data.indexOf(checkedArr[i]);
      if (index === -1) {
        arr.push(checkedArr[i]);
      }
    }
    if (!isCheckedAll) {
      for (let i = 0; i < data?.length; i++) {
        const index = checkedArr.indexOf(data[i]?.id);
        if (index === -1) {
          arr.push(data[i]?.id);
        }
      }
    } else {
      for (let i = 0; i < data?.length; i++) {
        const index = arr.indexOf(data[i]?.id);
        arr.splice(index, 1);
      }
    }
    setCheckedArr(arr);
  }, [isCheckedAll, data, checkedArr, setCheckedArr]);

  const locationQuery = useMemo(() => {
    return locationFilter?.map((value) => `&location=${value}`).join("");
  }, [locationFilter]);
  const positionQuery = useMemo(() => {
    return positionIDFilter?.map((value) => `&positionID=${value}`).join("");
  }, [positionIDFilter]);

  const query = useQuery();
  const code = query.get("c");

  const [name, setName] = useState(searchTerm);

  const onChange = useCallback(() => {
    setName(searchTerm);
  }, [searchTerm]);

  const debounceChange = useMemo(() => debounce(onChange, 500), [onChange]);

  useEffect(() => {
    debounceChange();
  }, [debounceChange]);

  useEffect(() => {
    const queryParams = `limit=${value}&page=${selectedPage}${locationQuery}${positionQuery}&status=RO-DISQUALIFIED&search=${name}`;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { response } = await getCandidates(code, queryParams);
        setData(
          response?.data?.map((obj) => {
            const imgUrl = obj?.CandidateDocuments?.find(
              (obj) => obj?.type?.toLowerCase() === "self picture"
            )?.url;
            return {
              ...obj,
              profilePicture: imgUrl
                ? fileBaseUrl + imgUrl
                : Images.AVA_DEFAULT,
            };
          })
        );
        setIsLoading(false);
        setTotalPage(response?.totalPage);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [locationQuery, code, positionQuery, name, value, selectedPage, trigger]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const viewType = useMemo(() => {
    if (windowSize[0] > 768) {
      return "desktop";
    } else if (windowSize[0] <= 768) {
      return "mobile";
    }
  }, [windowSize]);

  const [selectedCandidateDetail, setSelectedCandidateDetail] = useState(null);

  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const [showCandidateInfo, setShowCandidateInfo] = useState(false);
  const [showCandidateInfoMobile, setShowCandidateInfoMobile] = useState(false);

  const [defaultExpandId, setDefaultExpandId] = useState(1);

  const handleViewInfo = (data) => {
    setDefaultExpandId(2);

    if (viewType === "desktop") {
      setSelectedCandidate(data);

      setShowCandidateInfo(true);
    } else {
      setSelectedCandidate(data);

      setShowCandidateInfoMobile(true);
    }
  };
  const handleCloseInfo = () => {
    setDefaultExpandId(1);
    if (showCandidateInfo) {
      setShowCandidateInfo(false);
    } else {
      setShowCandidateInfoMobile(false);
    }
    setSelectedCandidate(null);
  };

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (selectedCandidateDetail !== null) {
      if (boxOutsideClick === false) {
        setSelectedCandidateDetail(null);
      }
    }
    if (showCandidateInfoMobile) {
      if (boxOutsideClick === false) {
        setShowCandidateInfoMobile(false);
      }
    }
  }, [boxOutsideClick, selectedCandidateDetail, showCandidateInfoMobile]);

  const handleOpenInterviewInvitation = (link) => {
    window.open(link);
  };

  return (
    <div className={Styles.tableWrapper}>
      <TableContainer
        className={`${Styles.tableContainer} ${
          data?.length ? Styles.grow : ""
        }`}
      >
        <Table className={Styles.table}>
          <TableHead className={Styles.tableHead}>
            <TableRow className={Styles.tableHeadRow}>
              {TABLE_HEAD?.map((eachH, iH) => (
                <TableCell
                  key={"headCell" + iH}
                  align={eachH?.align}
                  padding="none"
                  className={`${Styles.tableHeadCell} ${
                    eachH?.id === "actions" ? Styles.actions : ""
                  } ${iH !== 0 ? Styles.hideOnMobile : ""}`}
                >
                  <div>
                    {/* {eachH?.id === "name" && (
                      <Checkbox
                        onClick={handleCheckAll}
                        sx={{ margin: 0, padding: 0 }}
                        checked={isCheckedAll}
                        icon={<Icon icon="unchecked-all-checkbox" size={23} />}
                        checkedIcon={
                          <Icon icon="checked-all-checkbox" size={23} />
                        }
                      />
                    )} */}
                    <span>{eachH?.label}</span>
                    {/* {eachH?.id !== 'actions'
                      ?
                      <span>{eachH?.label}</span>

                      :
                      <Select
                        style={{ width: "50px" }}
                        input={<CustomInput />}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length
                            ? Array.isArray(value)
                              ? value.join(", ")
                              : value
                            : "Status"
                        }
                        IconComponent={(props) => (
                          <Icon {...props} icon={"arrow-down"} size={18} />
                        )}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              borderRadius: "8px",
                              "& .MuiMenuItem-root": {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#404040",
                              },
                            },
                          },
                        }}
                        value={status}
                        onChange={handleSelectStatus}
                      >
                        <MenuItem value={""}>
                          <em>Semua</em>
                        </MenuItem>
                        <MenuItem value={"Undang"}>Undang</MenuItem>
                        <MenuItem value={"Menunggu"}>Menunggu</MenuItem>
                        <MenuItem value={"Ready"}>Ready</MenuItem>
                      </Select>
                    } */}
                  </div>
                </TableCell>
              ))}
              <TableCell className={`${Styles.showOnMobile}`}>
                <div />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={Styles.tableBody}>
            {data?.map((eachD, iD) => {
              const isChecked = isItemChecked(eachD?.id);
              return (
                <TableRow key={`tableRow` + iD} className={Styles.tableRow}>
                  <TableCell
                    className={`${Styles.stickyLeft} ${Styles.tableCell}`}
                  >
                    <div>
                      {/* <Checkbox
                        onClick={() => handleCheck(eachD?.id)}
                        checked={isChecked || false}
                        sx={{ margin: 0, padding: 0 }}
                        icon={<Icon icon="unchecked-checkbox" size={23} />}
                        checkedIcon={<Icon icon="checked-checkbox" size={23} />}
                      /> */}
                      <button
                        className={Styles.stackedProfile}
                        onClick={() => handleViewInfo(eachD)}
                      >
                        {eachD?.profilePicture ? (
                          <Avatar
                            alt={eachD?.name}
                            src={eachD?.profilePicture}
                            sx={{ width: 36, height: 36 }}
                          />
                        ) : (
                          <Avatar sx={{ width: 36, height: 36 }}>
                            {eachD?.name[0]}
                          </Avatar>
                        )}
                        <span>{eachD?.name?.toUpperCase()}</span>
                      </button>
                    </div>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.ExpectedPosition?.name?.toUpperCase()}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.portal?.toUpperCase()}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.CandidateInvitations?.date
                        ? moment(
                            new Date(eachD?.CandidateInvitations?.date)
                          ).format("Do MMMM YYYY")
                        : "-"}
                      {`, `}
                      {eachD?.CandidateInvitations?.time || "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    {eachD?.interviewLink ? (
                      <div
                        className={`${Styles.linkInterview} ${
                          eachD?.status.toLowerCase().includes("hold") &&
                          Styles.inactive
                        }`}
                        onClick={() => {
                          if (!eachD?.status.toLowerCase().includes("hold")) {
                            handleOpenInterviewInvitation(eachD?.interviewLink);
                          }
                        }}
                      >
                        Lihat Detail
                      </div>
                    ) : (
                      <div className={Styles.nullLink}>Belum Tersedia</div>
                    )}
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.email ? eachD?.email : "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.phoneNumber ? eachD?.phoneNumber : "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.domicile ? eachD?.domicile : "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.address ? eachD?.address?.toUpperCase() : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.lastEducation ? eachD?.lastEducation : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.CandidateInvitations?.notes
                        ? eachD?.CandidateInvitations?.notes?.toUpperCase()
                        : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.rejectionNote?.toUpperCase() || "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile} ${Styles.stickyRight} ${Styles.actions}`}
                    align="center"
                  >
                    <button onClick={() => setDataToMove(eachD)}>
                      Alihkan
                    </button>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.showOnMobile}`}
                  >
                    <button onClick={() => setSelectedCandidateDetail(eachD)}>
                      Detail
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!data?.length && (
        <div className={Styles.emptyWrapper}>
          <TableEmptyHandler
            title={isLoading ? "Tunggu sebentar" : "Data Kosong"}
            desc={
              isLoading
                ? "Data sedang dimuat"
                : "Tidak ada data atau data belum ada"
            }
          />
        </div>
      )}
      <div className={Styles.paginationContainer}>
        <Pagination
          total={data?.length}
          totalPage={totalPage}
          value={value}
          setValue={setValue}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>
      <ModalWrapper show={dataToMove} handleClose={() => setDataToMove(null)}>
        {dataToMove && (
          <MoveCandidateModal
            candidate={dataToMove}
            onClose={() => setDataToMove(null)}
            setTrigger={setTrigger}
          />
        )}
      </ModalWrapper>
      <div
        className={`${Styles.modalWrapper} ${
          selectedCandidateDetail ? Styles.showModal : Styles.hideModal
        }`}
      >
        <CandidateDetailModal
          data={selectedCandidateDetail}
          setData={setSelectedCandidateDetail}
          inviteOption={false}
          moveOption
        />
      </div>
      {showCandidateInfo && selectedCandidate && (
        <CandidateInformationModal
          defaultExpandId={defaultExpandId || 2}
          data={selectedCandidate}
          handleClose={handleCloseInfo}
          inviteOption={false}
          type={"desktop"}
        />
      )}
      <div
        className={`${Styles.modalWrapper} ${
          showCandidateInfoMobile ? Styles.showModal : Styles.hideModal
        }`}
      >
        {/* {modal candidate information error} */}

        {/* <CandidateInformationModal
          defaultExpandId={defaultExpandId}
          data={selectedCandidate}
          handleClose={handleCloseInfo}
          inviteOption={false}
          type={"mobile"}
        /> */}
      </div>
      <div
        ref={boxRef}
        className={`${Styles.overlay} ${
          selectedCandidateDetail || showCandidateInfoMobile
            ? Styles.visible
            : ""
        }`}
      />
    </div>
  );
}
