const { default: Icon } = require("@Atom/Icon");
const { default: styled } = require("@emotion/styled");
const { InputAdornment, TextField } = require("@mui/material");

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    color: "#0a0a0a",
    fontSize: "14px",
    fontWeight: 400,
    background: "#fff",
    borderRadius: 8,
    height: "38px",
    fontFamily: "Poppins",
    "& fieldset": {
      borderRadius: 8,
      border: "1px solid #c2c2c2",
    },
    "&:hover fieldset": {
      border: "1px solid #c2c2c2",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #1571DE",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    background: "#EDEDED",
  },
});

const OutlinedSearchBar = ({
  placeholder,
  value,
  setValue,
  disabled = false,
  onClickIcon = () => {},
  icon = "search",
  inputRef,
  isBS,
  ...props
}) => {
  return (
    <MuiTextField
      inputRef={inputRef}
      fullWidth
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      onChange={(e) => setValue(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <div
              style={{
                display: "grid",
                placeItems: "center",
                cursor: "pointer",
              }}
              onClick={onClickIcon}
            >
              <Icon icon={icon} size={20} />
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default OutlinedSearchBar;
