import ModalWrapper from '@Atom/ModalWrapper'
import Styles from './style.module.scss'
import { useEffect, useMemo, useState } from 'react'
import InputField from '@Molecule/InputField'
import Images from '@Theme/Images'
import Icon from '@Atom/Icon'
import CustomAutocompleteField from '@Molecule/CustomAutocompleteField'
import AutocompleteV2 from '@Atom/AutocompleteV2'

export default function NewAddPositionModal({
  show = false,
  setShow = () => { },
  onAdd = () => { },
  existingPositionNames = [],
  editMode = false,
  defaultPositionName = '',
  editQtyMode = false,
  defaultQty,
  generalDedicatedRO = null,
  delegationsForm = [],
  alertMessage = ''
}) {
  const [step, setStep] = useState(1)
  const [positionName, setPositionName] = useState(defaultPositionName || defaultQty || '')
  const [dedicatedROId, setDedicatedROId] = useState(generalDedicatedRO?.id || '')
  const [dedicatedROName, setDedicaatedROName] = useState(generalDedicatedRO?.name || '')
  const [qualifications, setQualifications] = useState([])

  const roList = useMemo(() => {
    return delegationsForm
      ?.map((obj) => obj?.deployments[3]?.delegations)
      ?.flat()
      ?.filter((u) => u?.isPicked)
      ?.filter(
        (value, index, self) =>
          index === self?.findIndex((t) => t?.id === value?.id)
      )
  }, [delegationsForm])

  useEffect(() => {
    setStep(1)
  }, [show])


  return (
    <ModalWrapper show={show} handleClose={() => setShow(false)}>
      <div className={`${Styles.container} ${Styles[!editQtyMode ? 'wide' : 'small']}`}>
        <div className={Styles.header}>
          <img src={Images.ADD_POSITION_LOGO} alt='' />
          <h3>{editQtyMode ? 'Edit Jumlah Pemenuhan' : `${editMode ? 'Edit' : 'Tambah'} Posisi`}</h3>
        </div>
        {
          editQtyMode
            ?
            <div className={Styles.content}>
              <InputField
                title={editQtyMode ? 'Jumlah' : 'Position'}
                value={positionName}
                placeholder={editQtyMode ? 'Minimal 1' : 'Enter position'}
                onChange={(e) => setPositionName(e?.target?.value?.toUpperCase())}
                type={editQtyMode ? 'number' : ''}
                onKeyDown={editQtyMode ? (evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault() : () => { }}
              />
              <div className={Styles.actions}>
                <button
                  onClick={() => setShow(false)}
                >Cancel</button>
                <button
                  disabled={!positionName || existingPositionNames?.find(obj => obj?.toUpperCase() === positionName?.toUpperCase())}
                  onClick={() => {
                    onAdd(positionName)
                    setShow(false)
                  }}
                >Save</button>
              </div>

            </div>
            :
            <div className={Styles.newContent}>
              <div className={Styles.ncHeader}>
                <button disabled={step === 1}>
                  <Icon icon={'arrow-left-back'} size={20} />
                  <span>Kembali</span>
                </button>
                <span>Step {step}/2</span>
              </div>
              <div className={Styles.ncForm}>
                {
                  step === 1
                    ?
                    <div>
                      <InputField
                        title={'Nama Posisi'}
                        value={positionName}
                        placeholder={'Masukkan nama posisi'}
                        onChange={(e) => setPositionName(e?.target?.value?.toUpperCase())}
                      />
                      <AutocompleteV2
                        value={dedicatedROName}
                        placeholder="Select"
                        onClickOption={(option) => {
                          if (dedicatedROId !== option?.id) {
                            setDedicaatedROName(option?.name);
                            // setPositionData('dedicatedROId', option?.id)
                            setDedicatedROId(option?.id);
                          }
                        }}
                        options={roList}
                        // isError={
                        //   (alertMessage?.includes(positionName) &&
                        //     alertMessage?.includes("Dedicated")) ||
                        //   !roList?.length
                        // }
                        forceValue
                      />
                    </div>
                    :
                    <div>
                      step 2
                    </div>
                }
              </div>
              <div className={Styles.actions}>
                <button
                  onClick={() => setShow(false)}
                >Cancel</button>
                <button
                  disabled={!positionName || existingPositionNames?.find(obj => obj?.toUpperCase() === positionName?.toUpperCase())}
                  onClick={() => {
                    onAdd({ positionName, dedicatedROId, dedicatedROName, qualifications })
                    setShow(false)
                  }}
                >Simpan</button>
              </div>
            </div>

        }

      </div>
    </ModalWrapper>
  )
}