import AutocompleteV2 from "@Atom/AutocompleteV2";
import Icon from "@Atom/Icon";
import InputSearchJob from "@Atom/InputSearchJob";
import NonLinearSlider from "@Atom/RangePriceSlider";
import UseOutsideClick from "@Hooks/useOutsideClick";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./styles.module.scss";

const TipeKontrak = [
  { id: 1, name: "PKWT", code: "pkwt" },
  { id: 2, name: "PKM", code: "pkm" },
];

const experiences = [
  {
    id: 1,
    name: "Fresh Graduate",
    code: "Fresh Graduate",
  },
  {
    id: 2,
    name: "> 1 tahun",
    code: "> 1 tahun",
  },
  {
    id: 3,
    name: "> 3 tahun",
    code: "> 3 tahun",
  },
  {
    id: 4,
    name: "> 4 tahun",
    code: "> 4 tahun",
  },
];

export default function ModalFilterJobSeeker({
  handleDone = () => {},
  handleClose,
  options,
  selectedItems,
  selectedItemsExperiences,
  minValueTerm,
  maxValueTerm,
  valueSearchTermTitle,
  valueSearchTermPlace,
}) {
  const boxRef = useRef();
  // const query = useQuery();

  const { pathname } = useLocation();

  const boxOutsideClick = UseOutsideClick(boxRef);

  const [valueTitle, setValueTitle] = useState("");
  const [valuePlacement, setValuePlacement] = useState("");

  const [valueTypeContract, setValueTypeContract] = useState([]);
  const [valueExperiences, setValueExperiences] = useState([]);

  const [minValue, setMinValue] = useState(100000);
  const [maxValue, setMaxValue] = useState(25000000);

  const [isLoading, setIsloading] = useState(false);

  const setActiveButtonType = (idButton) => {
    // Cek apakah idButton sudah ada dalam array valueTypeContract
    const isButtonSelected = valueTypeContract?.includes(idButton);

    if (isButtonSelected) {
      // Jika sudah dipilih, hapus dari array
      setValueTypeContract(
        valueTypeContract?.filter((buttonId) => buttonId !== idButton)
      );
    } else {
      // Jika belum dipilih, tambahkan ke array
      setValueTypeContract([...valueTypeContract, idButton]);
    }
  };

  useEffect(() => {
    if (
      selectedItems ||
      selectedItemsExperiences ||
      minValueTerm ||
      maxValueTerm ||
      valueSearchTermPlace ||
      valueSearchTermTitle
    ) {
      setValueTypeContract(selectedItems || []);
      setValueExperiences(selectedItemsExperiences || []);
      setMinValue(minValueTerm || "");
      setMaxValue(maxValueTerm || "");
      setValueTitle(valueSearchTermTitle || "");
      setValuePlacement(valueSearchTermPlace || "");
    }
  }, [
    maxValueTerm,
    minValueTerm,
    selectedItems,
    selectedItemsExperiences,
    valueSearchTermPlace,
    valueSearchTermTitle,
  ]);

  // console.log(valueTypeContract);

  const setActiveButtonExperiences = (idButton) => {
    // Cek apakah idButton sudah ada dalam array valueExperiences
    const isButtonSelected = valueExperiences?.includes(idButton);

    if (isButtonSelected) {
      // Jika sudah dipilih, hapus dari array
      setValueExperiences(
        valueExperiences?.filter((buttonId) => buttonId !== idButton)
      );
    } else {
      // Jika belum dipilih, tambahkan ke array
      setValueExperiences([...valueExperiences, idButton]);
    }
  };

  // console.log(valueTypeContract, valueExperiences);

  useEffect(() => {
    if (options === null) {
      setIsloading(true);
    } else {
      setIsloading(false);
    }
  }, [options]);

  useEffect(() => {
    if (boxOutsideClick) {
      handleDone();
    }
  }, [boxOutsideClick, handleDone]);

  function formatNumber(num) {
    if (num >= 1e12) {
      return (num / 1e12).toFixed(2).replace(/\.?0+$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(2).replace(/\.?0+$/, "") + "M";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(2).replace(/\.?0+$/, "") + "jt";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(2).replace(/\.?0+$/, "") + "rb";
    } else {
      return num.toString();
    }
  }

  let minPrice = formatNumber(minValue);
  let maxPrice = formatNumber(maxValue);

  const templatePrice = `${minPrice} - ${maxPrice}`;

  const navigate = useNavigate();

  function handleSearch() {
    navigate(`/job-seeker`, {
      state: {
        valueTitle,
        valuePlacement,
        valueTypeContract,
        valueExperiences,
        minValue,
        maxValue,
      },
      // replace: true,
    });
    handleDone();
  }

  const handleReset = () => {
    if (pathname === "/") {
      setValueTypeContract([]);
      setValueExperiences([]);
      setMinValue(100000);
      setMaxValue(25000000);
      setValueTitle("");
      setValuePlacement("");
    } else {
      navigate(`/job-seeker`, {
        state: {
          valueTitle: "",
          valuePlacement: "",
          valueTypeContract: [],
          valueExperiences: [],
          minValue: 100000,
          maxValue: 25000000,
        },
        // replace: true,
      });
      handleDone();
    }
  };

  return (
    <div className={Styles.filterModalBody}>
      <div ref={boxRef} className={Styles.containerFilter}>
        <div className={Styles.topBox}>
          <span className={Styles.filtText}>Filter</span>
          <Icon
            icon={"cross"}
            size={24}
            className={Styles.iconX}
            onClick={handleClose}
          />
        </div>
        <div className={Styles.contentFilter}>
          <div className={Styles.inputBox}>
            <div className={Styles.labelBox}>
              <Icon icon={"briefcase-outlined"} size={20} color={"#404040"} />
              <span className={Styles.label}>Posisi</span>
            </div>
            <div className={Styles.input}>
              <InputSearchJob
                setValue={setValueTitle}
                value={valueTitle}
                type={"filter-add"}
              />
            </div>
          </div>
          <div className={Styles.inputBox}>
            <div className={Styles.labelBox}>
              <Icon icon={"send-outline"} size={20} color={"#404040"} />
              <span className={Styles.label}>Lokasi</span>
            </div>
            <div className={Styles.input}>
              <AutocompleteV2
                // type={"job-seeker-placement"}
                placeholder={"Masukkan lokasi"}
                options={options?.cities || []}
                value={valuePlacement}
                setValue={setValuePlacement}
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className={Styles.inputBox}>
            <div className={Styles.labelBox}>
              <Icon icon={"file"} size={20} color={"#404040"} />
              <span className={Styles.label}>Tipe Kontrak</span>
            </div>
            <div className={Styles.input}>
              <div className={Styles.boxSelect}>
                {TipeKontrak?.map((el) => {
                  return (
                    <div
                      className={`${Styles.selected} ${
                        el?.id === 2 && Styles.selectedPkm
                      } ${
                        valueTypeContract?.includes(el?.name) &&
                        Styles.selectActive
                      }`}
                      key={el?.id}
                      onClick={() => setActiveButtonType(el?.name)}
                    >
                      {el?.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={Styles.inputBox}>
            <div className={Styles.labelBox}>
              <Icon icon={"noteboard-outlined"} size={20} color={"#404040"} />
              <span className={Styles.label}>Level Pengalaman</span>
            </div>
            <div className={Styles.input}>
              <div className={`${Styles.boxSelectExperinces}`}>
                {experiences?.map((el) => {
                  return (
                    <div
                      className={`${Styles.selected} ${
                        el?.id === 4 && Styles.selectedPkm
                      } ${
                        valueExperiences?.includes(el?.name) &&
                        Styles.selectActive
                      } ${
                        el?.id === 2 || el?.id === 3
                          ? Styles.selectedMiddle
                          : ""
                      }`}
                      key={el?.id}
                      onClick={() => setActiveButtonExperiences(el?.name)}
                    >
                      {el?.name}
                    </div>
                  );
                })}
              </div>
              <div className={Styles.boxSelectResponsive}>
                {experiences?.map((el) => {
                  return (
                    <div
                      className={`${Styles.selected} ${
                        el?.id === 1 || el?.id === 3 ? Styles.selectedPkm : ""
                      } ${
                        valueExperiences?.includes(el?.name) &&
                        Styles.selectActive
                      } ${
                        el?.id === 2 || el?.id === 4
                          ? Styles.selectedMiddle
                          : ""
                      }`}
                      key={el?.id}
                      onClick={() => setActiveButtonExperiences(el?.name)}
                    >
                      {el?.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={Styles.inputBox}>
            <div className={Styles.labelBox}>
              <Icon icon={"money"} size={20} color={"#404040"} />
              <span className={Styles.label}>Range Gaji</span>
            </div>
            <div className={Styles.input}>
              <div className={Styles.rangePriceBox}>
                <div className={Styles.priceTemplate}>Rp{templatePrice}</div>
                <div className={Styles.sliderBox}>
                  <NonLinearSlider
                    setMax={setMaxValue}
                    setMin={setMinValue}
                    min={minValue}
                    max={maxValue}
                  />
                </div>
                {/* <div className={Styles.rangeDefault}>
                  <span className={Styles.spanRP}>Rp100.000</span>
                  <span className={Styles.spanRP}>Rp25.000.000</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className={Styles.buttonBox}>
          <div className={Styles.buttonLeft} onClick={handleReset}>
            Reset
          </div>
          <div className={Styles.buttonRight} onClick={handleSearch}>
            <span className={Styles.searchText}>Cari Pekerjaan</span>
            <Icon icon={"search"} size={20} color={"#FFFFFF"} />
          </div>
        </div>
      </div>
    </div>
  );
}
