import Button from "@Atom/Button";
import Styles from "./inputPhoneNumberVerification.module.scss";
import Icon from "@Atom/Icon";

const InputPhoneNumberVerification = ({
  placeholder,
  id,
  value,
  onChange,
  disabled,
  isVerified,
  onClick,
  isLoading,
  isError = false,
  errorText = "",
  ...props
}) => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        {/* <div className={Styles.phoneCodeWrapper}>+62</div> */}
        <div
          className={`${Styles.phoneContent} ${
            disabled ? Styles.phoneContentDisabled : ""
          } ${isError ? Styles.error : ""}`}
        >
          <div className={Styles.phoneCodeWrapper}>+62</div>

          <input
            className={Styles.input}
            type="number"
            onKeyDown={(event) => {
              if (
                event?.key === "." ||
                event?.key === "-" ||
                event?.key === "e" ||
                (!value?.length && event?.key === "0")
              ) {
                event?.preventDefault();
              }
            }}
            onWheel={(e) => e.target.blur()}
            id={id || "fname"}
            name="fname"
            value={value || ""}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
          />

          {value && (
            <div className={Styles.buttonVerification}>
              {isVerified ? (
                <div className={Styles.verifiedWrap}>
                  <span>Terverifikasi</span>
                  <Icon icon="check-circle" size="20px" color="#27BB83" />
                </div>
              ) : (
                <Button
                  title={isLoading ? "Memverifikasi" : "Verifikasi"}
                  onClick={onClick}
                  disabled={disabled}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {isError && !disabled && (
        <span className={Styles.errorText}>{errorText}</span>
      )}
    </div>
  );
};

export default InputPhoneNumberVerification;
