import { Autocomplete, TextField } from "@mui/material";
import Styles from "./style.module.scss";
import styled from "@emotion/styled";
import Icon from "@Atom/Icon";
import { InputTitle } from "@Molecule/InputField";



export default function AutocompleteField({
  title,
  placeholder,
  searchOptions,
  value,
  setValue,
  iconColor,
  readOnly = false,
  disabled = false,
  required,
  type,
  isError = false,
  errorText = '',
  popupIconSize = '24px',
  popupIcon = "arrow-down",
  preventIconRotate = false,
  optionsAtTop=false
}) {
  if (type === "job-posting") {
    return (
      <div className={Styles.autocompleteFieldWrapper}>
        <Autocomplete
          disablePortal
          freeSolo
          forcePopupIcon
          // autoSelect
          selectOnFocus
          clearOnBlur
          getOptionDisabled={(option) =>
            option && option.toLowerCase() === "no option"
          }
          filterOptions={(options, state) => {
            let newOptions = [];
            options?.forEach((element) => {
              if (
                element
                  ?.replace(",", "")
                  ?.toLowerCase()
                  ?.includes(state?.inputValue?.toLowerCase())
              )
                newOptions.push(element);
            });
            if (newOptions.length) {
              return newOptions;
            } else {
              return ["no option"];
            }
          }}
          id="combo-box-demo"
          isOptionEqualToValue={(option, value) => option === value}
          value={value || ""}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          options={searchOptions}
          popupIcon={
            <Icon
              icon="arrow-down"
              size="24px"
              color={
                iconColor
                  ? !disabled
                    ? iconColor
                    : "#b8b8b8"
                  : !disabled
                    ? "#0a0a0a"
                    : "#b8b8b8"
              }
            />
          }
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <MuiTextField {...params} sx={{ fontFamily: 'Poppins' }} placeholder={placeholder || "Select"} />
          )}
          disabled={disabled}
        />
      </div>
    );
  }

  const MuiTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      fontFamily: "Poppins",
      color: "#0a0a0a",
      fontSize: "14px",
      fontWeight: 400,
      background: "#ffffff",
      borderRadius: 8,
      padding: "8px 12px",
      "& .MuiAutocomplete-popupIndicator": { transform: preventIconRotate ? "none" : 'rotate' },
      "& .MuiAutocomplete-input": {
        padding: 0,
      },
      "& fieldset": {
        borderRadius: 8,
        border: `1px solid ${isError ? '#F5610D' : '#E0E0E0'} `,
      },
      "&:hover fieldset": {
        border: `1px solid ${isError ? '#F5610D' : '#E0E0E0'} `,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${isError ? '#F5610D' : '#1571DE'} `,
        padding: "8px 12px",
      },
    },

    "& .MuiOutlinedInput-root.Mui-disabled": {
      background: "#EDEDED",
      "& fieldset": {
        borderRadius: 8,
        border: "1px solid #E0E0E0",
      },
    },

    "& .MuiInputBase-input::placeholder": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "20px",
      color: "#757575!important",
      opacity: 1,
    },
    "& .MuiInputBase-input": {
      height: "29.5px",
      padding: 0,
    },
  });

  return (
    <div className={Styles.autocompleteFieldWrapper}>
      {/* <div>
        {title}
        {required && <span className={Styles.redStar}>*</span>}
      </div> */}
      <InputTitle
        title={title}
        required={required}
      />
      <Autocomplete
        disablePortal
        freeSolo
        forcePopupIcon
        // autoSelect
        selectOnFocus
        clearOnBlur
        getOptionDisabled={(option) =>
          option && option.toLowerCase() === "no option"
        }
        filterOptions={(options, state) => {
          let newOptions = [];
          options?.forEach((element) => {
            if (
              element
                ?.replace(",", "")
                ?.toLowerCase()
                ?.includes(state?.inputValue?.toLowerCase())
            )
              newOptions.push(element);
          });
          if (newOptions.length) {
            return newOptions;
          } else {
            return ["no option"];
          }
        }}
        id="combo-box-demo"
        isOptionEqualToValue={(option, value) => option === value}
        value={value || ""}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        options={searchOptions}
        popupIcon={
          <Icon
            icon={popupIcon}
            size={popupIconSize}
            color={
              iconColor
                ? !disabled
                  ? iconColor
                  : "#b8b8b8"
                : !disabled
                  ? "#0a0a0a"
                  : "#b8b8b8"
            }
          />
        }
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <MuiTextField {...params} placeholder={placeholder || "Select"} />
        )}
        disabled={disabled}
        readOnly={readOnly}
        disableClearable
      />
      {
        isError && !disabled && <span className={Styles.errorText}>{errorText}</span>
      }

    </div>
  );
}
