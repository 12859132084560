/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import { fileBaseUrl } from "@Config/api";
import useQuery from "@Hooks/useQuery";
import Pagination from "@Molecule/Pagination";
import DoneModal from "@Molecule/_modal/DoneModal";
import ReinvitationConfirmationModal from "@Molecule/_modal/ReinvitationConfirmationModal";
import {
  getCandidates,
  resendOffering,
  sendOffering,
} from "@Services/officer/recruitment";
import Images from "@Theme/Images";
import styled from "@emotion/styled";
import {
  Avatar,
  InputBase,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import debounce from "lodash.debounce";
import Button from "./button";
import Styles from "./style.module.scss";
const { useEffect, useState, useMemo, useCallback } = require("react");

const CustomInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    width: "100%",
    border: "none",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#0A0A0A",

    "&:focus": {
      border: "none",
      // color: '#1571DE',
      boxShadow: "none",
    },
  },
});

const OutlinedCustomInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    width: "100%",
    border: "1px solid #1571DE",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1571DE",
    // textAlign: 'center',
    paddingLeft: "16px",
    paddingBlock: "8px",

    "&:focus": {
      // border: 'none',
      // color: '#1571DE',
      boxShadow: "none",
    },
  },
});

const TABLE_HEAD = [
  { id: "name", label: "Nama Kandidat", align: "left" },
  { id: "position", label: "Posisi", align: "left" },
  { id: "portal", label: "Sumber", align: "left" },
  // { id: "interviewDate", label: "Waktu Interview", align: "left" },
  // { id: "location", label: "Lokasi", align: "left" },
  { id: "offeringLink", label: "Link Offering", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "phoneNumber", label: "Nomor Handphone", align: "left" },
  { id: "domicile", label: "Domisili", align: "left" },
  { id: "address", label: "Alamat", align: "left" },
  { id: "education", label: "Pendidikan", align: "left" },
  // { id: "lastExperience", label: "Pengalaman Terakhir", align: "left" },
  { id: "cancellationNote", label: "Alasan Belum Bisa Join", align: "left" },
  // { id: "updatedAt", label: "Tgl Kirim Offering", align: "left" },
  { id: "status", label: "Status", align: "left" },
];
export default function SendTable({
  searchTerm,
  data: source,
  checkedArr,
  setCheckedArr,
  locationFilter,
  positionFilter,
  setSelectedCandidateDetail,
  setTrigger,
  trigger,
  handleViewInfo,
  setOfferingMode,
  positionIDFilter,
}) {
  const [value, setValue] = useState(5);
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [resendModal, setResendModal] = useState(false);
  const [resentData, setResentData] = useState({
    id: null,
    e: null,
  });

  const resendInvitation = (e, id) => {
    setResendModal(true);
    setResentData((prev) => ({
      ...prev,
      id: id,
      e: e,
    }));
  };

  const allIds = useMemo(() => {
    const arr = [];

    for (let i = 0; i < data?.length; i++) {
      arr.push(data[i]?.id);
    }

    return arr;
  }, [data]);

  // const totalPage = useMemo(() => {
  //   return Math.ceil(filteredData?.length / value)
  // }, [value, filteredData])

  const isCheckedAll = useMemo(() => {
    return allIds.every((el) => checkedArr.includes(el));
  }, [allIds, checkedArr]);

  const isItemChecked = useCallback(
    (id) => {
      return checkedArr.includes(id);
    },
    [checkedArr]
  );

  const handleCheck = (id) => {
    const index = checkedArr.indexOf(id);
    if (index === -1) {
      setCheckedArr([...checkedArr, id]);
    } else {
      const newArr = [...checkedArr];
      newArr.splice(index, 1);
      setCheckedArr(newArr);
    }
  };

  const handleCheckAll = useCallback(() => {
    const arr = [];
    for (let i = 0; i < checkedArr.length; i++) {
      const index = data.indexOf(checkedArr[i]);
      if (index === -1) {
        arr.push(checkedArr[i]);
      }
    }
    if (!isCheckedAll) {
      for (let i = 0; i < data?.length; i++) {
        const index = checkedArr.indexOf(data[i]?.id);
        if (index === -1) {
          arr.push(data[i]?.id);
        }
      }
    } else {
      for (let i = 0; i < data?.length; i++) {
        const index = arr.indexOf(data[i]?.id);
        arr.splice(index, 1);
      }
    }
    setCheckedArr(arr);
  }, [isCheckedAll, data, checkedArr, setCheckedArr]);

  useEffect(() => {
    setSelectedPage(1);
  }, [searchTerm]);

  useEffect(() => {
    // console.log(data, "DATA");
  }, [data]);

  const [status, setStatus] = useState("");

  const handleSelectStatus = (e) => {
    setStatus(e.target.value);
  };

  const [isDone, setIsDone] = useState(false);

  const handleSend = async (e, data) => {
    setOfferingMode(data);

    // try {
    //   e.preventDefault();
    //   await sendOffering(data?.id, sessionStorage.getItem("id"));
    //   setTrigger(Math.random());
    //   setIsDone(true);
    //   setResendModal(false);
    // } catch (err) {
    //   console.log(err, "err");
    // }
  };

  const handleResend = async (e, data) => {
    // setOfferingMode(data)
    try {
      e.preventDefault();
      await resendOffering(data?.id);
      setTrigger(Math.random());
      setIsDone(true);
      setResendModal(false);
    } catch (err) {
      console.log(err, "err");
    }
  };

  const handleDone = (e) => {
    setIsDone(false);
  };

  const query = useQuery();
  const code = query.get("c");

  const [name, setName] = useState(searchTerm);

  const onChange = useCallback(() => {
    setName(searchTerm);
  }, [searchTerm]);

  const debounceChange = useMemo(() => debounce(onChange, 500), [onChange]);

  useEffect(() => {
    debounceChange();
  }, [debounceChange]);

  const locationQuery = useMemo(() => {
    return locationFilter?.map((value) => `&location=${value}`).join("");
  }, [locationFilter]);
  const positionQuery = useMemo(() => {
    return positionIDFilter?.map((value) => `&positionID=${value}`).join("");
  }, [positionIDFilter]);

  const fixedStatus = useMemo(() => {
    if (status === "") {
      // return '&status=null&status=invited&status=accepted'
      return "";
    } else if (status === "Kirim") {
      return "&status=null";
    } else if (status === "Dikirim") {
      return "&status=invited";
    }
  }, [status]);

  useEffect(() => {
    const queryParams = `limit=${value}&page=${selectedPage}${locationQuery}${positionQuery}${fixedStatus}&status=OFFER-INVITE&search=${name}`;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { response } = await getCandidates(code, queryParams);
        setData(
          response?.data?.map((obj) => {
            const imgUrl = obj?.CandidateDocuments?.find(
              (obj) => obj?.type?.toLowerCase() === "self picture"
            )?.url;
            return {
              ...obj,
              profilePicture: imgUrl
                ? fileBaseUrl + imgUrl
                : Images.AVA_DEFAULT,
            };
          })
        );
        setIsLoading(false);
        setTotalPage(response?.totalPage);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [
    locationQuery,
    code,
    positionQuery,
    name,
    value,
    selectedPage,
    trigger,
    fixedStatus,
  ]);

  const handleOpenInterviewInvitation = (link) => {
    window.open(link);
  };

  return (
    <div className={Styles.tableWrapper}>
      <TableContainer
        className={`${Styles.tableContainer} ${
          data?.length ? Styles.grow : ""
        }`}
      >
        <Table className={Styles.table}>
          <TableHead className={Styles.tableHead}>
            <TableRow className={Styles.tableHeadRow}>
              {TABLE_HEAD?.map((eachH, iH) => (
                <TableCell
                  key={"headcCell" + iH}
                  align={eachH?.align}
                  padding="none"
                  className={`${Styles.tableHeadCell} ${
                    eachH?.id === "status" ? Styles.status : ""
                  } ${iH !== 0 ? Styles.hideOnMobile : ""}`}
                >
                  <div>
                    {eachH?.id !== "status" ? (
                      <>
                        {/* {eachH?.id === "name" && (
                          <Checkbox
                            onClick={handleCheckAll}
                            sx={{ margin: 0, padding: 0 }}
                            checked={isCheckedAll}
                            icon={
                              <Icon icon="unchecked-all-checkbox" size={23} />
                            }
                            checkedIcon={
                              <Icon icon="checked-all-checkbox" size={23} />
                            }
                          />
                        )} */}
                        <span>{eachH?.label}</span>
                      </>
                    ) : (
                      <Select
                        style={{ width: "50px" }}
                        input={<CustomInput />}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length
                            ? Array.isArray(value)
                              ? value.join(", ")
                              : value
                            : "Status"
                        }
                        IconComponent={(props) => (
                          <Icon {...props} icon={"arrow-down"} size={18} />
                        )}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              borderRadius: "8px",
                              "& .MuiMenuItem-root": {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: 400,
                                color: "#404040",
                              },
                            },
                          },
                        }}
                        value={status}
                        onChange={handleSelectStatus}
                      >
                        <MenuItem value={""}>
                          <em>Semua</em>
                        </MenuItem>
                        <MenuItem value={"Kirim"}>Kirim</MenuItem>
                        <MenuItem value={"Dikirim"}>Dikirim</MenuItem>\{" "}
                      </Select>
                    )}
                  </div>
                </TableCell>
              ))}
              <TableCell className={` ${Styles.showOnMobile}`}>
                <Select
                  style={{ width: "100%" }}
                  input={<OutlinedCustomInput />}
                  displayEmpty={true}
                  renderValue={(value) =>
                    value?.length
                      ? Array.isArray(value)
                        ? value.join(", ")
                        : value
                      : "Semua"
                  }
                  IconComponent={(props) => (
                    <Icon
                      {...props}
                      icon={"arrow-down"}
                      size={18}
                      color="#1571DE"
                    />
                  )}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        borderRadius: "8px",
                        "& .MuiMenuItem-root": {
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontWeight: 400,
                          color: "#404040",
                          // borderBottom: '1px solid #c2c2c2',
                          paddingInline: "12px",
                          paddingBlock: "0px",
                        },
                      },
                    },
                  }}
                  value={status}
                  onChange={handleSelectStatus}
                >
                  <MenuItem value={""}>
                    <em>Semua</em>
                  </MenuItem>
                  <MenuItem value={"Kirim"}>Kirim</MenuItem>
                  <MenuItem value={"Dikirim"}>Dikirim</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={Styles.tableBody}>
            {data?.map((eachD, iD) => {
              const isChecked = isItemChecked(eachD?.id);
              return (
                <TableRow key={`tableRow` + iD} className={Styles.tableRow}>
                  <TableCell
                    className={`${Styles.stickyLeft} ${Styles.tableCell}`}
                  >
                    <div>
                      {/* <Checkbox
                        onClick={() => handleCheck(eachD?.id)}
                        checked={isChecked || false}
                        sx={{ margin: 0, padding: 0 }}
                        icon={<Icon icon="unchecked-checkbox" size={23} />}
                        checkedIcon={<Icon icon="checked-checkbox" size={23} />}
                      /> */}
                      <button
                        className={Styles.stackedProfile}
                        onClick={() => handleViewInfo(eachD)}
                      >
                        {eachD?.profilePicture ? (
                          <Avatar
                            alt={eachD?.name}
                            src={eachD?.profilePicture}
                            sx={{ width: 36, height: 36 }}
                          />
                        ) : (
                          <Avatar sx={{ width: 36, height: 36 }}>
                            {eachD?.name[0]}
                          </Avatar>
                        )}
                        <span>{eachD?.name?.toUpperCase()}</span>
                      </button>
                    </div>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.ExpectedPosition?.name?.toUpperCase()}</span>
                  </TableCell>
                  {/* <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.ExpectedPosition?.City?.name}</span>
                  </TableCell> */}
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <div>
                      <span>{eachD?.portal?.toUpperCase()}</span>
                      {eachD?.portal === "365 P" && (
                        <Icon
                          icon="chat"
                          size={20}
                          className={Styles.chatIcon}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    {eachD?.offeringLink ? (
                      <div
                        className={`${Styles.linkInterview} ${
                          eachD?.status.toLowerCase().includes("hold") &&
                          Styles.inactive
                        }`}
                        onClick={() => {
                          if (!eachD?.status.toLowerCase().includes("hold")) {
                            handleOpenInterviewInvitation(eachD?.offeringLink);
                          }
                        }}
                      >
                        Lihat Detail
                      </div>
                    ) : (
                      <div className={Styles.nullLink}>Belum Tersedia</div>
                    )}
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.email ? eachD?.email : "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.phoneNumber ? eachD?.phoneNumber : "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.domicile ? eachD?.domicile?.toUpperCase() : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.address ? eachD?.address?.toUpperCase() : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.lastEducation
                        ? eachD?.lastEducation?.toUpperCase()
                        : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.cancellationNote
                        ? eachD?.cancellationNote?.toUpperCase()
                        : "-"}
                    </span>
                  </TableCell>
                  {/* <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.lastPosition ? eachD?.lastPosition : "-"}
                    </span>
                  </TableCell> */}

                  {/* <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    {eachD?.tag === "Invited" ? (
                      <div>
                        <span>
                          {eachD?.updatedAt && eachD?.recruiterID
                            ? moment(eachD?.updatedAt).format("ll")
                            : "-"}
                        </span>
                        <span className={Styles.time}>
                          {eachD?.updatedAt && eachD?.recruiterID
                            ? moment(eachD?.updatedAt).format("LT")
                            : ""}
                        </span>
                      </div>
                    ) : (
                      <span>-</span>
                    )}
                  </TableCell> */}
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile} ${Styles.stickyRight} ${Styles.status}`}
                    align="center"
                  >
                    {eachD?.status?.toLowerCase()?.includes("hold") ? (
                      <div className={Styles.holdText}>Hold</div>
                    ) : (
                      <Button
                        recruiterId={eachD?.recruiterID}
                        tag={eachD?.tag}
                        onClick={(e) =>
                          eachD?.tag && eachD?.recruiterID
                            ? resendInvitation(e, eachD?.id)
                            : handleSend(e, eachD)
                        }
                        updatedAt={eachD?.updatedAt}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.showOnMobile}`}
                  >
                    <button onClick={() => setSelectedCandidateDetail(eachD)}>
                      Detail
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!data?.length && (
        <div className={Styles.emptyWrapper}>
          <TableEmptyHandler
            title={isLoading ? "Tunggu sebentar" : "Data Kosong"}
            desc={
              isLoading
                ? "Data sedang dimuat"
                : "Tidak ada data atau data belum ada"
            }
          />
        </div>
      )}
      <div className={Styles.paginationContainer}>
        <Pagination
          total={data?.length}
          totalPage={totalPage}
          value={value}
          setValue={setValue}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>
      {isDone && (
        <DoneModal
          handleDone={handleDone}
          title="Terkirim!"
          description={"Offering telah dikirimkan"}
        />
      )}
      {resendModal && (
        <ModalWrapper
          show={resendModal}
          forceCenter
          type="fullscreen"
          handleClose={() => setResendModal(false)}
        >
          <ReinvitationConfirmationModal
            action={(e) => handleResend(e, resentData)}
            handleClose={() => setResendModal(false)}
          />
        </ModalWrapper>
      )}
    </div>
  );
}
