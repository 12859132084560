const fonts = {
  courier: "Courier",
  courierBold: "Courier-Bold",
  courierOblique: "Courier-Oblique",
  helvetica: "Helvetica",
  helveticaBold: "Helvetica-Bold",
  helveticaOblique: "Helvetica-Oblique",
  times: "Times-Roman",
  timesBold: "Times-Bold",
  timesItalic: "Times-Italic",
  arial: "Arial",
};

const styles = {
  page: {
    paddingHorizontal: 75,
    paddingTop: 100,
    paddingBottom: 100,
    fontSize: 11,
    textAlign: "justify",
    fontFamily: fonts.helvetica,
    // positionRelative: 1,
  },
  paraf: {
    width: 40,
    height: 40,
    marginBottom: 10,
    marginLeft: 10,
  },
  pageDocument: {
    paddingHorizontal: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 11,
    textAlign: "justify",
    fontFamily: fonts.helvetica,
  },
  pageDoubleColumn: {
    paddingLeft: 75,
    paddingRight: 50,
    paddingTop: 100,
    paddingBottom: 100,
    fontSize: 10,
    textAlign: "justify",
    lineHeight: "1.6",
    fontFamily: fonts.helvetica,
    // positionRelative: 1,
  },
  header: {
    position: "absolute",
    top: 15,
    left: 0,
    right: 15,
    paddingHorizontal: 50,
    // color: "grey",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  mainLogo: {
    opacity: 0.7,
    width: "25%",
    height: "auto",
  },
  addressImage: {
    opacity: 0.7,
    width: "20%",
    height: "auto",
  },
  partiesSignImage: {
    width: "auto",
    height: 60,
    maxWidth: "30vw",
  },
  partiesSignImageAddition: {
    width: "auto",
    height: 60,
    maxWidth: "30%",
  },
  title: {
    fontFamily: fonts.helveticaBold,
    textAlign: "center",
    marginTop: 0,
  },
  subTitle: {
    textAlign: "center",
    marginBottom: 15,
    marginTop: 3,
  },
  paragraf: {
    marginTop: 8,
  },
  chapter: {
    fontFamily: fonts.helveticaBold,
    textAlign: "center",
    marginBottom: 10,
    marginTop: 25,
  },
  tableHead: {
    margin: 3,
    flexDirection: "row",
  },
  tableInnerHead: {
    margin: 2,
    paddingLeft: 20,
    flexDirection: "row",
  },
  tableInnerHeadSalary: {
    margin: 2,
    paddingRight: 80,
    paddingLeft: 20,
    flexDirection: "row",
  },
  columnParty1: {
    width: "4%",
  },
  columnParty2: {
    width: "30%",
  },
  columnParty3: {
    width: "3%",
    textAlign: "center",
  },
  columnParty4: {
    width: "63%",
  },
  columnTableHead: {
    flexDirection: "row",
  },
  columnTableHead2: {
    flexDirection: "row",
    width: "100%",
  },
  columnTableHead3: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  columnTable1: {
    width: "100%",
    textAlign: "center",
    borderWidth: "1px",
    fontWeight: "900",
    padding: "3px",
  },
  columnTable2: {
    width: "50%",
    textAlign: "left",
    borderWidth: "1px",
    padding: "3px",
  },
  columnTable201: {
    width: "50%",
    textAlign: "left",
    borderWidth: "0px",
    paddingLeft: "8px",
  },
  columnTable3: {
    width: "14%",
    textAlign: "right",
    borderWidth: "1px",
    padding: "3px",
  },
  columnTable3Bold: {
    width: "14%",
    textAlign: "right",
    borderWidth: "1px",
    fontWeight: "900",
    padding: "3px",
  },
  columnTable301: {
    width: "19%",
    textAlign: "right",
    borderWidth: "1px",
    padding: "3px",
  },
  columnTable301Bold: {
    width: "19%",
    textAlign: "right",
    borderWidth: "1px",
    fontWeight: "900",
    padding: "3px",
  },
  columnTable4: {
    width: "16%",
    textAlign: "center",
    borderWidth: "1px",
    padding: "1px",
  },
  columnTable401: {
    width: "8%",
    textAlign: "center",
    borderWidth: "1px",
    padding: "1px",
  },
  columnTable4Bold1: {
    width: "16%",
    textAlign: "center",
    // borderWidth: "1px 1px 0px 1px",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    fontWeight: "900",
    padding: "1px",
  },
  columnTable4Bold101: {
    width: "8%",
    textAlign: "center",
    // borderWidth: "1px 1px 0px 1px",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    fontWeight: "900",
    padding: "1px",
  },
  columnTable4Bold2: {
    width: "16%",
    textAlign: "center",
    // borderWidth: "0px 1px 1px 1px",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    fontWeight: "900",
    padding: "3px",
  },
  columnTable4Bold201: {
    width: "8%",
    textAlign: "center",
    // borderWidth: "0px 1px 1px 1px",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    fontWeight: "900",
    padding: "3px",
  },
  columnTable4Bold3: {
    width: "16%",
    textAlign: "center",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    fontWeight: "900",
    padding: "3px",
  },
  columnTable5: {
    width: "84%",
    textAlign: "center",
    borderWidth: "1px",
    fontWeight: "900",
    padding: "3px",
  },
  columnTable6: {
    width: "33%",
    textAlign: "center",
    borderWidth: "1px",
    padding: "1px",
  },
  columnTable6Bold1: {
    width: "33%",
    textAlign: "center",
    // borderWidth: "1px 1px 0px 1px",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    fontWeight: "900",
    padding: "1px",
  },
  columnTable6Bold2: {
    width: "33%",
    textAlign: "center",
    // borderWidth: "0px 1px 1px 1px",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    fontWeight: "900",
    padding: "3px",
  },
  columnTable6Bold3: {
    width: "33%",
    textAlign: "center",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    fontWeight: "900",
    padding: "3px",
  },
  columnTable6Bold1Desc: {
    width: "33%",
    textAlign: "left",
    // borderWidth: "1px 1px 0px 1px",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    fontWeight: "900",
    padding: "1px",
  },
  columnTable6Bold2Desc: {
    width: "33%",
    textAlign: "left",
    // borderWidth: "0px 1px 1px 1px",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    fontWeight: "900",
    padding: "3px",
  },
  columnTable6Bold3Desc: {
    width: "33%",
    textAlign: "left",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    fontWeight: "900",
    padding: "3px",
  },
  columnTable7Title: {
    width: "100%",
    textAlign: "center",
    borderWidth: "1px",
    padding: "1px",
  },
  columnTable7: {
    width: "50%",
    textAlign: "center",
    borderWidth: "1px",
    padding: "1px",
  },
  columnTable7Bold1: {
    width: "50%",
    textAlign: "center",
    // borderWidth: "1px 1px 0px 1px",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    fontWeight: "900",
    padding: "1px",
  },
  columnTable7Bold2: {
    width: "50%",
    textAlign: "center",
    // borderWidth: "0px 1px 1px 1px",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    fontWeight: "900",
    padding: "3px",
  },
  columnTable7Bold3: {
    width: "50%",
    textAlign: "center",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    fontWeight: "900",
    padding: "3px",
  },
  columnTableRTL1: {
    width: "18%",
    textAlign: "center",
    borderWidth: "1px 1px 1px 1px",
    padding: "1px",
  },
  columnTableRTL1_1: {
    width: "18%",
    textAlign: "center",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    padding: "1px",
  },
  columnTableRTL1_2: {
    width: "18%",
    textAlign: "center",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    padding: "1px",
  },
  columnTableRTL2: {
    width: "23%",
    textAlign: "center",
    borderWidth: "1px 1px 1px 1px",
    padding: "1px",
  },
  columnTableRTL2_1: {
    width: "23%",
    textAlign: "center",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    padding: "1px",
  },
  columnTableRTL2_2: {
    width: "23%",
    textAlign: "center",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    padding: "1px",
  },
  columnTableRTL3: {
    width: "35%",
    textAlign: "center",
    borderWidth: "1px 1px 1px 1px",
    padding: "1px",
  },
  columnTableRTL3_1: {
    width: "35%",
    textAlign: "center",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    padding: "1px",
  },
  columnTableRTL3_2: {
    width: "35%",
    textAlign: "center",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    padding: "1px",
  },
  columnTableRTL4: {
    width: "10%",
    textAlign: "center",
    borderWidth: "1px 0px 1px 1px",
    padding: "1px",
  },
  columnTableRTL5: {
    width: "25%",
    textAlign: "center",
    borderWidth: "1px 0px 1px 1px",
    padding: "1px",
  },
  columnChapter1: {
    width: "4%",
  },
  columnChapter2: {
    width: "96%",
  },
  columnChapter3: {
    width: "96%",
    paddingLeft: 20,
  },
  columnChapter4: {
    width: "5%",
  },
  columnChapter5: {
    width: "95%",
    textAlign: "justify",
    textJustify: "inter-word",
  },
  addLineParaf: { borderLeft: "3px solid coral" },
  columnParaf: {
    position: "absolute",
    right: -30,
    top: -25,
    paddingLeft: 5,
    // order: 3,
    fontSize: 9,
    color: "grey",
    // verticalAlign: "top",
    borderLeft: "2px solid coral",
  },
  textMaterai: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 9,
    color: "grey",
  },
  textMateraiContract: {
    marginTop: 30,
    marginBottom: 21,
    fontSize: 9,
    color: "grey",
  },
  columnSalary1: {
    width: "60%",
  },
  columnSalary2: {
    width: "70%",
    textAlign: "right",
  },
  columnSubStatement1: {
    width: "4%",
  },
  columnSubStatement2: {
    width: "92%",
  },
  columnSub1: {
    width: "10%",
  },
  columnSub2: {
    width: "86%",
  },
  partiesSign: {
    flexDirection: "row",
    marginTop: 40,
    justifyContent: "space-between",
    fontFamily: fonts.helveticaBold,
  },
  partiesSignName: {
    marginTop: 10,
    fontFamily: fonts.helveticaBold,
    textDecoration: "underline",
  },
  partiesSignPosition1: {
    // textOverflow: "ellipsis",
    linHeight: "1.5em",
    // width: "25%",
  },
  partiesSignPosition2: {
    // textOverflow: "ellipsis",
    linHeight: "1.5em",
    width: "25%",
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 35,
    alignItems: "flex-end",
    left: 0,
    right: 0,
    paddingHorizontal: 25,
    color: "grey",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  footerImage: {
    opacity: 0.7,
    width: "auto",
    height: "auto",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    bottom: 10,
    left: 30,
    right: 0,
    textAlign: "left",
    color: "white",
    fontFamily: fonts.helveticaBold,
  },
  pageNumberBlack: {
    position: "absolute",
    fontSize: 8,
    bottom: 10,
    left: 30,
    right: 0,
    textAlign: "left",
    color: "black",
    fontFamily: fonts.helveticaBold,
  },
  space: {
    width: "100%",
    marginTop: 7,
    marginBottom: 7,
  },
  lineBreak: {
    width: "100%",
    marginTop: "7",
    marginBottom: 7,
  },

  signTitle: {
    fontFamily: fonts.helveticaBold,
    fontSize: 12,
    fontWeight: "700",
    textAlign: "center",
  },
  photoProfileBox: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    // justifyContent: "space-between",
    paddingTop: 20,
    width: "100%",
  },
  photoBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  photoTitle: {
    textAlign: "left",
    fontFamily: fonts.helveticaBold,
    fontSize: 8,
    color: "black",
    width: "100%",
  },

  addressBox: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: 10,
    backgroundColor: "whitesmoke",
    // borderRadius: 4,
    width: "100%",
  },
  address: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    borderBottomWidth: 1,
    borderColor: "gainsboro",
    paddingBottom: 16,
  },
  addressTitle: {
    fontSize: 8,
    color: "gray",
    fontWeight: 600,
  },
  addressText: {
    fontSize: 8,
    color: "black",
    fontWeight: 500,
  },
  longLatBox: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    gap: 16,
    justifyContent: "space-between",
  },

  longLat: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    width: "100%",
  },
  titleLonglat: {
    fontSize: 8,
    color: "gray",
    fontWeight: 600,
  },

  longLatText: {
    fontSize: 8,
    color: "black",
    fontWeight: 500,
  },

  endingSection: {
    paddingTop: 16,
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },

  signTitleOther: {
    fontFamily: fonts.helveticaBold,
    fontSize: 12,
    fontWeight: "700",
  },
  candidateName: {
    fontFamily: fonts.helveticaBold,
    fontSize: 10,
    textAlign: "center",
    color: "white",
    marginTop: 8,
    marginBottom: 8,
  },
  candidatePosition: {
    fontFamily: fonts.helvetica,
    fontSize: 9,
    textAlign: "center",
    color: "white",
    marginBottom: 8,
  },

  image: {
    width: "100%",

    maxHeight: 300,

    objectFit: "cover",
  },
  imageWrapper: {
    marginTop: 16,
    marginBottom: 16,
    backgroundColor: "black",
    display: "flex",
    maxHeight: 300,
    overflow: "hidden",
  },
  imageTextWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  pageSign: {
    paddingHorizontal: 24,
    paddingTop: 100,
    paddingBottom: 100,
    fontSize: 11,
    textAlign: "justify",
    fontFamily: fonts.helvetica,
  },

  pageSignOther: {
    paddingHorizontal: 125,
    paddingTop: 100,
    paddingBottom: 100,
    fontSize: 11,
    textAlign: "justify",
    fontFamily: fonts.helvetica,
  },
  subTitleSign: {
    fontFamily: fonts.helveticaBold,
    fontSize: 9,
    marginBottom: 8,
  },
  locationContainer: {
    backgroundColor: "#FAFAFA",
    padding: 10,
  },
  locationWrapper1: {
    marginBottom: 12,
    paddingBottom: 12,
    borderBottom: "1px solid #EDEDED",
  },
  locationLabel: {
    color: "#9E9E9E",
    fontFamily: fonts.helvetica,
    fontSize: 8,
    fontWeight: 400,
    marginBottom: 2,
  },
  locationValue: {
    fontFamily: fonts.helvetica,
    fontSize: 8,
    fontWeight: 400,
  },
  locationWrapper2: {
    display: "flex",
    flexDirection: "row",
  },
  location2: {
    width: "50%",
  },
  tableSpace: {
    marginTop: 7,
    flexDirection: "row",
  },
  table4ColumnNumber: {
    width: "8%",
    textAlign: "center",
    borderWidth: "1px 1px 1px 1px",
    padding: "3px",
  },
  table4ColumnNumberRowspan3Top: {
    width: "8%",
    textAlign: "center",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    padding: "3px",
  },
  table4ColumnNumberRowspan3Middle: {
    width: "8%",
    textAlign: "center",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    padding: "3px",
  },
  table4ColumnNumberRowspan3Bottom: {
    width: "8%",
    textAlign: "center",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    padding: "3px",
  },
  table4ColumnHeader: {
    width: "30%",
    textAlign: "center",
    borderWidth: "1px 1px 1px 1px",
    padding: "3px",
    fontWeight: "bold",
  },
  table4Column: {
    width: "30%",
    textAlign: "left",
    borderWidth: "1px 1px 1px 1px",
    padding: "3px",
  },
  table4ColumnRowspan2Top: {
    width: "30%",
    textAlign: "left",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    padding: "3px",
  },
  table4ColumnRowspan2Bottom: {
    width: "30%",
    textAlign: "left",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    padding: "3px",
  },
  table4ColumnRowspan3Top: {
    width: "30%",
    textAlign: "left",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    padding: "3px",
  },
  table4ColumnRowspan3Middle: {
    width: "30%",
    textAlign: "left",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "0px",
    padding: "3px",
  },
  table4ColumnRowspan3Bottom: {
    width: "30%",
    textAlign: "left",
    borderTopWidth: "0px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    padding: "3px",
  },
};

export default styles;
