import Images from "@Theme/Images";
import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "@Hooks/useQuery";
import StaticProgressBar from "@Atom/StaticProgressBar";
import DashboardSyncComponent from "@Molecule/DashboardSyncComponent";
import CustomTable from "@Molecule/CustomTable";
import moment from "moment";
import { useState } from "react";
import ModalWrapper from "@Atom/ModalWrapper";
import QualificationModal from "./QualificationModal";
import ClientDashboardLogModal from "@Molecule/_modal/ClientDashboardLogModal";

export default function FulfillmentDetailRBM({ data }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const query = useQuery();
  const [showQualification, setShowQualification] = useState(null);
  const [positionLog, setPositionLog] = useState(null);

  const columns = [
    {
      name: "city",
      label: "Kota",
      renderData: (row) => (
        <div className={Styles.cityRow}>{row?.city || "-"}</div>
      ),
    },
    {
      name: "updateAt",
      label: "Tanggal Update",
      renderData: (row) => (
        <div className={Styles.dateRow}>
          {row?.updateAt
            ? moment(new Date(row?.updateAt)).format("DD MMMM YYYY")
            : "-"}
        </div>
      ),
    },

    {
      name: "total",
      label: "Jumlah Permintaan & Terpenuhi",
      renderData: (row) => (
        <div className={Styles.progressBar}>
          <StaticProgressBar
            fulfilledAmount={row?.fulfillment || 0}
            middleFulfilledText={`${Math.floor(
              ((row?.fulfillment || 0) / (row?.quota || 0)) * 100
            )}%`}
            candidatesAmount={row?.fulfillment || 0 + row?.vendor || 0}
            candidatesText={row?.vendor || 0}
            requestAmount={row?.quota || 0}
            vacantAmount={row?.vacant || 0}
            isV3
            borderRadius="0px"
            height={"32px"}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div
          className={Styles.routerBack}
          onClick={() => {
            if (query?.get("fr") === "dashboard") {
              navigate(`/`);
            } else {
              navigate(`/daftar-bm/${id}?u=${query?.get("u") || ""}`);
            }
          }}
        >
          <div className={Styles.icon}>
            <Icon icon={"arrow-left"} size={16} color={"#FFFFFF"} />
          </div>
          <span className={Styles.back}>Kembali</span>
        </div>

        <div className={Styles.fulfillmentTitleBox}>
          <div className={Styles.fulfillmentTitle}>
            Dashboard Pemenuhan TKO - {data?.client?.name || "-"}
          </div>

          <button
            className={Styles.addButton}
            disabled
          //  onClick=
          >
            <span>Download Report</span>
            <Icon icon={"download"} size={20} />
          </button>

          <div
            className={Styles.icon}
            onClick={() => {
              setPositionLog(data);
            }}
          >
            <Icon icon={"clock-back"} size={24} color={"#1571DE"} />
          </div>
        </div>
      </div>

      <div className={Styles.content}>
        <div className={Styles.progressBarFulfillment}>
          <div className={Styles.top}>
            <span>Pemenuhan Kuota</span>
            <div className={Styles.descQuota}>
              <div>
                <div className={Styles.blueDark}></div>
                <span>Jumlah Terpenuhi</span>
              </div>
              <div>
                <div className={Styles.blueSky}></div>
                <span>Vendor Lain</span>
              </div>
              <div>
                <div className={Styles.grey}></div>
                <span>Vacant</span>
              </div>
            </div>
          </div>

          <div className={Styles.progressBar}>
            <StaticProgressBar
              fulfilledAmount={data?.totalFullfillment || 0}
              middleFulfilledText={`${Math.floor(
                ((data?.totalFullfillment || 0) / (data?.totalQuota || 0)) * 100
              )}%`}
              // fulfilledText={`${dataTemp?.fulfillment || 0} (${Math.floor(+dataTemp?.fulfillment / +dataTemp?.totalQuota * 100)}%)`}
              candidatesAmount={
                data?.totalFullfillment || 0 + data?.totalVendor || 0
              }
              candidatesText={data?.totalVendor || 0}
              requestAmount={data?.totalQuota || 0}
              vacantAmount={data?.totalVacant || 0}
              isV3
              borderRadius="0px"
              height={"32px"}
            />
          </div>
        </div>

        <div className={Styles.dashboard}>
          <DashboardSyncComponent
            syncData={data}
            // isLoading={isLoadingSync}
            isFulfillment={true}
          />
        </div>

        <div className={Styles.positionBox}>
          {data?.positions?.length > 0 ? (
            data?.positions?.map((position, idx) => {
              return (
                <div className={Styles.position} key={idx}>
                  <div className={Styles.top}>
                    <span>{position?.position_name || "-"}</span>
                    <div
                      onClick={() => {
                        setShowQualification(position);
                      }}
                    >
                      <span>Lihat Kualifikasi</span>
                      <Icon icon={"eye-visible"} size={16} color={"#1571DE"} />
                    </div>
                  </div>

                  <div className={Styles.totalBox}>
                    <div>
                      <span>Total Kuota</span>
                      <span>{position?.quota || 0}</span>
                      <span>Tenaga Kerja</span>
                    </div>
                    <div>
                      <span className={`${Styles.vacant}`}>Vacant</span>
                      <span>{position?.totalVacant || 0}</span>
                      <span>Permintaan</span>
                    </div>
                    <div>
                      <span>
                        Total Pemenuhan <span>Permata Indonesia</span>{" "}
                        {/* <img src={Images.PERMATA_LOGO} alt="" /> */}
                      </span>
                      <span>{position?.fullfillment || 0}</span>
                      <span>Tenaga Kerja</span>
                    </div>
                    <div className={`${Styles.noBoreder}`}>
                      <span>Persentase Terhadap Pemenuhan</span>
                      <span>{`${Math.floor(
                        ((position?.fullfillment || 0) /
                          (position?.quota || 0)) *
                          100
                      )}%`}</span>
                      <span>Terpenuhi</span>
                    </div>
                  </div>

                  <div className={Styles.tableBox}>
                    <CustomTable
                      data={
                        position?.city?.map((el, idx) => {
                          return {
                            id: idx,
                            city: el?.city,
                            updateAt: el?.lastUpdated,
                            fulfillment: el?.fulfillment,
                            quota: el?.quota,
                            vendor: el?.totalVendor,
                            vacant: el?.totalVacant,
                          };
                        }) || []
                      }
                      columns={columns}
                      // isLoading={isLoading}
                      //   isLoadingSkeleton={isLoading}
                      noPagination
                      isFulfillment
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className={Styles.noFoundBox}>
              <div className={Styles.images}>
                <img src={Images.RAFIKI_2} alt="" />
              </div>
              <span>Tidak ada Data Posisi</span>
              <span>Belum ada data posisi ditambahkan pada klien ini</span>
            </div>
          )}
        </div>
      </div>
      <ModalWrapper show={showQualification} handleClose={() => {}}>
        <QualificationModal
          data={showQualification}
          handleClose={() => setShowQualification(null)}
        />
      </ModalWrapper>

      <ClientDashboardLogModal
        isFulfillment={true}
        show={positionLog}
        onClose={() => setPositionLog(null)}
        dataPositions={
          data?.positions?.map((el, idx) => {
            return {
              id: idx,
              positionName: el?.position_name || "-",
            };
          }) || []
        }
      />
    </div>
  );
}
