import Styles from "./style.module.scss";
import IMAGES from "@Theme/Images";
export default function UnderMaintenacePage() {
  return (
    <div className={Styles.container}>
      <img src={IMAGES.COMING_SOON} alt="coming soon" />
      <h3>Coming Soon!</h3>
      <p>
        This feature is coming soon and we are currently in development for
        better experience for you
      </p>
    </div>
  );
}
