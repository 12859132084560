import { API } from "@Config/api";

export function addClientOrder(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/order/client`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function clientOrderSync() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/order-sync/client`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClientOrdersClient(clientCode, page, limit) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client/orders/${clientCode}?page=${page}&limit=${limit}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchAllClient(){
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client/all`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data?.response);
    } catch (error) {
      reject(error);
    }
  })
}

export function fetchOrderByClientCode(clientCode){
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/order/by-client-id/${clientCode}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  })
}

