import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

let interval = undefined;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export default function ProgressBar({
  progress=0,
  handleProgress=()=> {},
  running,
  handleRunning=()=> {},
  index,
  speed=50
}) {
  const [localProgress, setLocalProgress] = useState(progress);
  // eslint-disable-next-line
  // const [localRunning, setLocalRunning] = useState(running);

  useEffect(() => {
    if (running) {
      interval = setInterval(() => {
        setLocalProgress((prev) => prev + 1);
      }, speed);
    } else {
      clearInterval(interval);
    }
  }, [progress, index, running, speed]);

  useEffect(() => {
    if (localProgress === 100) {
      // setLocalRunning(false);
      clearInterval(interval);
      handleRunning(false, index);
      handleProgress(100, index);
    }
  }, [progress, handleRunning, handleProgress, index, localProgress]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress
        variant="determinate"
        value={Number(localProgress)}
      />
    </Box>
  );
}
