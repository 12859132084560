/* eslint-disable no-unused-vars */
import LoadingAnimation from "@Atom/LoadingAnimation";
import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import BusinessSupportDashboardLayout from "@Organism/BusinessSupport/Dashboard";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import {
  getCandidates,
  getLatestCandidates,
} from "@Services/support/candidate";
import { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export async function loader(route) {
  const url = new URL(route?.request?.url);
  // const limit = url.searchParams.get("l") || 10;
  // const page = url.searchParams.get("p") || 1;
  // const status = url.searchParams.get("s") || "Verifikasi Pertama";
  // const stage = url.searchParams.get("st") || "";
  // const search = url.searchParams.get("n") || "";
  // const limit2 = url.searchParams.get("l2") || 10;
  // const page2 = url.searchParams.get("p2") || 1;
  const search2 = url.searchParams.get("n2") || "";

  const packageCandidateListPromise = getCandidates(
    10,
    1,
    "Verifikasi Pertama",
    "",
    [],
    "",
    "",
    "",
    ""
  );
  const packageLatesCandidatesPromise = getLatestCandidates(search2);
  return defer({
    packageCandidateList: packageCandidateListPromise,
    packageLatestCandidates: packageLatesCandidatesPromise,
  });
}

export default function BusinessSupportDashboard() {
  const { packageCandidateList, packageLatestCandidates } = useLoaderData();

  const query = useQuery();
  const defaultLimit = query.get("l") || 10;
  const defaultPage = query.get("p") || 1;
  const defaultStatus = query.get("s") || "Verifikasi Pertama";
  const defaultStage = query.get("st") || "";
  // const defaultSearch = query.get("n") || "";
  const defaultLimit2 = query.get("l2") || 10;
  const defaultPage2 = query.get("p2") || 1;
  const defaultSearch2 = query.get("n2") || "";

  const [limit, setLimit] = useState(defaultLimit);
  const [page, setPage] = useState(defaultPage);
  const [status, setStatus] = useState(defaultStatus);
  const [stage, setStage] = useState(defaultStage);
  // const [search, setSearch] = useState(defaultSearch);
  const [limit2, setLimit2] = useState(defaultLimit2);
  const [page2, setPage2] = useState(defaultPage2);
  const [search2, setSearch2] = useState(defaultSearch2);

  const navigate = useNavigate();

  // const debouncedSearch = useDebounce(search, 300);
  const debouncedSearch2 = useDebounce(search2, 300);

  useEffect(() => {
    navigate(
      `?l=${limit}&p=${page}&s=${status}&l2=${limit2}&p2=${page2}&n2=${debouncedSearch2}`
    );
  }, [debouncedSearch2, limit, limit2, navigate, page, page2, status]);

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Dashboard</title>
      </Helmet>
      <div className={Styles.contentWrapper}>
        <Suspense
          fallback={
            <div className={Styles.loadingWrapper}>
              <LoadingAnimation />
            </div>
          }
        >
          <Await
            resolve={packageCandidateList}
            errorElement={
              <div style={{ height: "calc(100vh - 220px)" }}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageCandidateList) => (
              <Suspense
                fallback={
                  <div className={Styles.loadingWrapper}>
                    <LoadingAnimation />
                  </div>
                }
              >
                <Await resolve={packageLatestCandidates}>
                  {(packageLatestCandidates) => (
                    <BusinessSupportDashboardLayout
                      candidatesData={packageCandidateList?.response}
                      latestCandidatesData={packageLatestCandidates?.response}
                      limit={limit}
                      setLimit={setLimit}
                      page={page}
                      setPage={setPage}
                      status={status}
                      setStatus={setStatus}
                      stage={stage}
                      setStage={setStage}
                      // search={search}
                      // setSearch={setSearch}
                      limit2={limit2}
                      setLimit2={setLimit2}
                      page2={page2}
                      setPage2={setPage2}
                      search2={search2}
                      setSearch2={setSearch2}
                    />
                  )}
                </Await>
              </Suspense>
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}
