import moment from "moment";
import Styles from "./style.module.scss";
import { useMemo } from "react";

export default function DemandCard({
  data,
  index,
  general,
  qualificationList,
}) {
  const qualificationsData = useMemo(() => {
    return data?.qualifications || [];
  }, [data]);
  let IDR = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  const qualifications = useMemo(() => {
    const arr = [];
    for (let i = 0; i < qualificationsData.length; i++) {
      const qualification = qualificationsData[i];
      const found = qualificationList?.find(
        (el) => el?.value === qualification?.name
      );
      const each = {
        name: qualification?.name,
        value: qualification?.value,
        denom: found?.denom,
      };
      arr.push(each);
    }
    return arr;
  }, [qualificationList, qualificationsData]);

  return (
    <div className={Styles.container}>
      <div className={Styles.top}>
        <div className={Styles.stacked}>
          <span>Man power</span>
          <span>{data?.name}</span>
        </div>
        <div className={Styles.stacked}>
          <span>Salary</span>
          <span>{IDR?.format(data?.salary || 0)}</span>
        </div>
        <div className={Styles.stacked}>
          <span>Contract type</span>
          <span>{general?.contractType}</span>
        </div>
        <div className={Styles.stacked}>
          <span>Expected date</span>
          <span>
            {moment(new Date(general?.expectedFulfilmentDate))?.format("LL")}
          </span>
        </div>
      </div>
      <div className={Styles.bottom}>
        <div className={Styles.card}>
          <h5>Allowance</h5>
          {data?.allowances?.length > 0 &&
            data?.allowances?.map((eachA, iA) => (
              <div key={iA} className={Styles.stacked}>
                <span>{eachA?.name}</span>
                <span>{IDR?.format(eachA?.value)}</span>
              </div>
            ))}
          {data?.allowances?.length === 0 && (
            <div className={Styles.stacked}>
              <span>No allowance</span>
              <span></span>
            </div>
          )}
        </div>
        <div className={Styles.card}>
          <h5>Personal Work Equipment</h5>
          {data?.personalEquipments?.length > 0 &&
            data?.personalEquipments?.map((eachA, iA) => (
              <div key={iA} className={Styles.stacked}>
                <span>{eachA?.name}</span>
                <span>{IDR?.format(eachA?.price)}</span>
              </div>
            ))}
          {data?.personalEquipments?.length === 0 && (
            <div className={Styles.stacked}>
              <span>No equipment</span>
              <span></span>
            </div>
          )}
        </div>
      </div>
      <div className={Styles.qualification}>
        <h5>Job Qualification</h5>
        <div className={Styles.card}>
          <div className={Styles.stackedHead}>
            <span>Title</span>
            <span>Qualification</span>
          </div>
          <div className={Styles.body}>
            {qualifications?.length > 0 &&
              qualifications?.map((eachA, iA) => (
                <div key={iA} className={Styles.stacked}>
                  <span>{eachA?.name}</span>
                  <span>
                    {eachA?.value} {eachA?.denom}
                  </span>
                </div>
              ))}
            {qualifications?.length === 0 && (
              <div className={Styles.NoQualification}>
                <span>No qualification</span>
                <span></span>
              </div>
            )}
          </div>
        </div>
      </div>
      {index !== 0 && (
        <span className={Styles.Note}>*Posisi dengan ketentuan berbeda</span>
      )}
    </div>
  );
}
