import Icon from '@Atom/Icon';
import CandidateResumeLayout from '@Molecule/_recruitment/CandidateResumeLayout';
import { useEffect, useState } from 'react';
import Styles from './style.module.scss';

const PDFJS = require("pdfjs-dist/webpack");

export default function PreviewCVModal({
  url,
  onClose,
  candidateName,
  isV2 = false,
  data,
}) {
  const [images, setImages] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadCV = () => {
    setIsDownloading(true);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${"CV_" + candidateName}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .then(() => setIsDownloading(false));
  };

  useEffect(() => {
    if (url && !isV2) {
      const loadDefaultImages = async () => {
        const imagesArr = [];
        if (url === undefined) {
          imagesArr.push(null);
        } else {
          const uri = url;
          var pdf = await PDFJS.getDocument({ url: uri }).promise;
          const canvas = document.createElement("canvas");
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport: viewport })
              .promise;
            imagesArr.push(canvas.toDataURL());
          }
          canvas.remove();
        }

        setImages(imagesArr);
      };
      loadDefaultImages();
    } else {
      setImages([]);
    }
  }, [isV2, url]);

  if (isV2) {
    return (
      <div className={Styles.v2Container}>
        <div className={Styles.header}>
          <h3>CV</h3>
          <button onClick={onClose}>
            <Icon icon={"cross"} size={24} />
          </button>
        </div>
        <div className={Styles.content}>
          <CandidateResumeLayout data={data} noContact noDocument />
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Preview CV</h3>
        <button
          onClick={handleDownloadCV}
          disabled={images?.length < 1 || isDownloading}
        >
          <Icon icon="file-download" size={20} />
          <span className={Styles.hideOnMobile}>
            {isDownloading ? "Downloading CV..." : "Download CV"}
          </span>
        </button>
        <button onClick={onClose}>
          <Icon icon="cross" size={36} />
        </button>
      </div>
      <div className={Styles.content}>
        {images?.length ? (
          images?.map((eachI, iI) => <img key={iI} alt="cv" src={eachI} />)
        ) : (
          <div>{url ? "loading file..." : "no cv uploaded"}</div>
        )}
      </div>
    </div>
  );
}
