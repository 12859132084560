import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";

const GeneralInformationDetail = ({ type, value, value2, ...props }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.iconWrapper}>
        <Icon
          icon={
            type === "email"
              ? "email-outline"
              : type === "phone"
              ? "phone-outline"
              : "calendar"
          }
          size={20}
          className={Styles.icon}
        />
      </div>
      <div className={Styles.informationWrapper}>
        <div className={Styles.informationTitleText}>
          {type === "email" ? "Email" : type === "phone" ? "Phone" : "Start"}
        </div>
        <div className={Styles.informationtext}>
          {type === "phone" && <span className={Styles.span}>+62</span>}
          <span className={Styles.spanBlack}>{value}</span>
        </div>
      </div>
      {type === "duration" && (
        <>
          <div className={Styles.durationBorder}></div>
          <div className={Styles.informationWrapper}>
            <div className={Styles.informationTitleText}>End</div>
            <div className={Styles.informationtext}>
              <span className={Styles.spanBlack}>{value2}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GeneralInformationDetail;
