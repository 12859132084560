import MPOLayout from '@Organism/Client/Order/ManPowerOutsource'
import MPOStepper from '@Organism/Client/Order/ManPowerOutsource/Stepper'
import AsyncErrorBoundary from '@Organism/Utils/AsyncErrorBoundary'
import LoadingSkeleton from '@Pages/Auth/Manager/Client/AddNewOrder/Skeleton'
import { clientOrderSync } from '@Services/client/order'
import { Suspense, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Await, defer, useLoaderData } from 'react-router-dom'
import Styles from './style.module.scss'

export async function loader() {
  const packageOrderSyncPromise = clientOrderSync();

  return defer({
    packageOrderSync: packageOrderSyncPromise,
  });
}

export default function ManPowerOutsource() {
  const { packageOrderSync } = useLoaderData();

  const mpoStep = sessionStorage.getItem("mpoStep");

  const [currentStep, setCurrentStep] = useState(mpoStep ? +mpoStep : 0);

  const defaultSteps = JSON.parse(sessionStorage.getItem("steps"))


  const [stepsOptions, setStepsOptions] = useState([
    {
      id: 1,
      label: 'Contract Type',
      isDone: defaultSteps ? defaultSteps[0]?.isDone : false,
    },
    {
      id: 2,
      label: 'Work Scheme',
      isDone: defaultSteps ? defaultSteps[1]?.isDone : false,
    },
    {
      id: 3,
      label: 'Man Power',
      isDone: defaultSteps ? defaultSteps[2]?.isDone : false,
    },
    {
      id: 4,
      label: 'Payment Method & Estimation',
      isDone: defaultSteps ? defaultSteps[3]?.isDone : false,
    },
    {
      id: 5,
      label: 'Summary',
      isDone: defaultSteps ? defaultSteps[4]?.isDone : false,
    },
  ]);

  const [maxStep, setMaxStep] = useState(0);

  const handleClickStep = (step) => {
    if (maxStep >= step) {
      setCurrentStep(step);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("mpoStep", currentStep);
  }, [currentStep]);

  const topRef = useRef()

  const [height, setHeight] = useState();

  const getTopSize = () => {
    const newHeight = topRef?.current?.clientHeight;
    setHeight(newHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", getTopSize);
  }, []);

  useEffect(()=> {
    getTopSize()
  }, [])


  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Order</title>
      </Helmet>

      <div ref={topRef} className={Styles.controllerWrapper}>
        <MPOStepper
          currentStep={currentStep}
          onClickStep={handleClickStep}
          options={stepsOptions}
        />
      </div>

      <div
       className={Styles.contentWrapper}
       style={{height: `calc(100vh - 70px - ${height}px`}}
       >
        <Suspense fallback={
          <LoadingSkeleton />
        }>
          <Await resolve={packageOrderSync} errorElement={
            <div className={Styles.errorWrapper}>
              <AsyncErrorBoundary />
            </div>
          }>
            {(packageOrderSync) => (
              <MPOLayout
                data={packageOrderSync?.response}
                steps={stepsOptions}
                setSteps={setStepsOptions}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                maxStep={maxStep}
                setMaxStep={setMaxStep}
              />
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}
