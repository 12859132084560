import Styles from "./Styles.module.scss";

import Images from "@Theme/Images";
import { Link } from "react-router-dom";

export default function HomeFooter() {
  // const navigate = useNavigate();

  return (
    <div className={Styles.footer}>
      <div className={Styles.footerTop}>
        <div className={Styles.footerLeft}>
          <div className={Styles.imagesBox}>
            <img src={Images.KERJA365_WHITE} alt="" />
          </div>
          <div className={Styles.informationLeftBox}>
            <span className={Styles.fastServices}>Get Our Fast Service</span>
            <div className={Styles.detailInformation}>
              <span className={Styles.text}>021 726 5364 (hunting)</span>
              <span className={Styles.text}>info@permataindonesia.com</span>
              <span className={Styles.textAddress}>
                Jl. Kyai Maja No.7a, RT.4/RW.1, Grogol Sel., Kec. Kby. Baru,
                Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12130
              </span>
            </div>
          </div>
        </div>
        <div className={Styles.navigationBox}>
          <span className={Styles.nav}>Navigation</span>
          <div className={Styles.textBox}>
            <Link to={"/job-seeker"} className={Styles.p}>
              Job Seekers
            </Link>
            <Link to={"/"} className={Styles.p}>
              Business
            </Link>
          </div>
        </div>
      </div>
      <div className={Styles.footerBottom}>
        <div className={Styles.images}>
          <img src={Images.LOGO_PERMATA_FOOTER} alt="" />
        </div>
        <span className={Styles.textBottom}>Powered by Permata Indonesia</span>
      </div>
    </div>

    // <footer className={Styles.footer}>
    //   <div className={Styles.contactArea}>
    //     <div className={Styles.logoWrapper}>
    //       <img
    //         src={Images.KERJA365_WHITE}
    //         alt="KERJA_365_LIGHT_LOGO"
    //         className={Styles.logo}
    //       />
    //     </div>
    //     <span className={Styles.primary}>Get Our Fast Service</span>
    //     <span className={Styles.primary}>021 726 5364 {"(hunting)"}</span>
    //     <span className={Styles.secondary}>info@permataindonesia.com</span>
    //   </div>
    //   <div className={Styles.navigationArea}>
    //     <span className={Styles.title}>Navigation</span>
    //     <span onClick={(e) => navigate("/home/job-seeker")}>Job Seekers</span>
    //     <span onClick={(e) => navigate("/home")}>Business</span>
    //   </div>
    //   <div className={Styles.subscribeArea}>
    //     <span className={Styles.title}>Subscibe Newsletter</span>
    //     <span>
    //       Subscribe our newsletter to get updates about our services and offers.
    //     </span>
    //     <form
    //       onSubmit={() => console.log("Footer submit clicked")}
    //       className={Styles.emailInput}
    //     >
    //       <input placeholder="Enter your email" type="text" />
    //       <IconButton type="submit" className={Styles.arrowButton}>
    //         {/* <ArrowRight stroke="#FFF" /> */}
    //       </IconButton>
    //     </form>
    //     <div className={Styles.socialMediaWrapper}>
    //       <a href="https://www.facebook.com/permataindosejahtera/">
    //         {/* <Facebook stroke="#FFF" /> */}
    //       </a>
    //       <a href="https://www.instagram.com/permataindosejahtera/?hl=en">
    //         {/* <Instagram stroke="#FFF" /> */}
    //       </a>
    //       <a href="https://www.youtube.com/channel/UCh5LH5uuU7mYSqX5HMuLwHA">
    //         {/* <Youtube stroke="#FFF" /> */}
    //       </a>
    //     </div>
    //   </div>
    // </footer>
  );
}
