import React, { useState } from "react";
import Styles from "./style.module.scss";
import PICTURE from "../../../../../assets/Images/ava-default.png";
import Icon from "@Atom/Icon";
import GeneralInformationDetail from "./GeneralInformationDetail";
import InformationField from "./InformationField";
import moment from "moment";

const EmployeeDetailPage = ({
  data
}) => {
  const isActive = true;

  const [isOpen, setIsOpen] = useState(false);

  const starsStatus = [
    {
      isShining: true,
    },
    {
      isShining: true,
    },
    {
      isShining: true,
    },
    {
      isShining: true,
    },
    {
      isShining: false,
    },
  ];

  return (
    <div className={Styles.container}>
      <div className={Styles.generalInformationWrapper}>
        <div className={Styles.employee}>
          <img src={PICTURE} alt="employee-pict" className={Styles.picture} />
          <div className={Styles.statusCard} style={{ borderColor: isActive ? '#FBDBB6' : '#FF3E13', backgroundColor: isActive ? '#FEF8F0' : '#FAEFF2' }}>
            <div className={Styles.statusCardText} style={{ color: isActive ? '#96AB12' : '#FF3E13' }}>{isActive ? 'Active' : 'Not Active'}</div>
          </div>
          <div className={Styles.employeeNameWrapper}>
            <div className={Styles.employeeName}>{data?.name}</div>
            <div className={Styles.employeePosition}>{data?.position || '-'}</div>
          </div>
          <div className={Styles.starsWrapper}>
            {starsStatus.map((star, id) => (
              <Icon
                key={id}
                icon="star"
                size={24}
                className={
                  star?.isShining ? Styles.shiningStar : Styles.notShiningStar
                }
              />
            ))}
          </div>
        </div>
        <div className={Styles.giDetailWrapper}>
          <GeneralInformationDetail type='phone' value={data?.phoneNumber?.slice(2)} />
          <GeneralInformationDetail type='email' value={data?.email} />
          <GeneralInformationDetail type='duration' value={data?.contract?.startDate ? moment(new Date(data?.contract?.startDate))?.format("LL") : '-'} value2={data?.contract?.endDate ? moment(new Date(data?.contract?.endDate))?.format("LL") : '-'} />
        </div>
        <div
          className={Styles.giButtonsWrapper}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className={Styles.giButtonsSection}>
            <div className={Styles.giButtonsWrapperText}>Request</div>
            <Icon icon={isOpen ? "arrow-up" : "arrow-down"} size={24} />
          </div>
          <div className={isOpen ? Styles.activeGiButtons : Styles.giButtons}>
            <div className={Styles.terminateButton}>
              <div className={Styles.terminateButtonText}>
                Request Terminate
              </div>
            </div>
            <div className={Styles.extendButton}>
              <div className={Styles.extendButtonText}>Extend Request</div>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.otherInformationWrapper}>
        <div className={Styles.otherInformation}>
          <div className={Styles.otherInformationHeader}>
            Supervisor Contact
          </div>
          <div className={Styles.otherInformationBody}>
            <div className={Styles.supervisorName}>
              <img className={Styles.supervisorPicture} src={PICTURE} alt="spv" />
              <div className={Styles.supervisorNameText}>{data?.supervisor?.name || '-'}</div>
            </div>
            <div className={Styles.supervisorContact}>
              <div className={Styles.supervisorContactText}>Contact</div>
              <div className={Styles.contactWrapper}>
                <div className={Styles.contact}>
                  <Icon icon="phone" size={20} className={Styles.supervisorContactIcon} />
                  <span className={Styles.contactValueText} style={{ color: '#9e9e9e' }}>+62</span>
                  <span className={Styles.contactValueText} style={{ color: '#0a0a0a' }}>{data?.supervisor?.phoneNumber?.slice(2) || '-'}</span>
                </div>
                <div className={Styles.contactBreaker}></div>
                <div className={Styles.contact}>
                  <Icon icon="email" size={20} className={Styles.supervisorContactIcon} />
                  <div className={Styles.contactValueText}>{data?.supervisor?.email || '-'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.otherInformation}>
          <div className={Styles.otherInformationHeader}>
            Personal Information
          </div>
          <div className={Styles.otherInformationBody}>
            <div className={Styles.informationLine}>
              <InformationField title="Full name" value={data?.name} />
              <InformationField title="Language spoken" value={data?.languages?.length > 0 ? data?.languages?.join(', ') : '-'} />
            </div>
            <div className={Styles.informationLine}>
              <InformationField title="Gender" value={data?.gender} />
              <InformationField title="Education" value={data?.lastEducation || '-'} />
            </div>
            <div className={Styles.informationLine}>
              <InformationField title="Marital status" value={data?.maritalStatus} />
              <InformationField title="Emergancty contact" value={data?.emergencyContact ? data?.emergencyContact?.phoneNumber + ` (${data?.emergencyContact?.relationship})` : '-'} />
            </div>
            <div className={Styles.informationLine}>
              <InformationField title="Address" value={data?.address} />
            </div>
          </div>
        </div>
        <div className={Styles.otherInformation}>
          <div className={Styles.otherInformationHeader}>Basic Information</div>
          <div className={Styles.otherInformationBody}>
            <div className={Styles.informationLine}>
              <InformationField title="Employment ID" value={data?.contract?.id || '-'} />
              <InformationField title="Job title" value={data?.contract?.jobTitle || '-'} />
            </div>
            <div className={Styles.informationLine}>
              <InformationField title="Employment type" value={data?.contract?.employmentType || '-'} />
              <InformationField title="Contract type" value={data?.contract?.contractType || '-'} />
            </div>
            <div className={Styles.informationLine}>
              <InformationField title="Department" value={data?.contract?.division || '-'} />
              <InformationField title="Join date" value={data?.contract?.joinData ? moment(new Date(data?.contract?.joinDate))?.format("LL") : '-'} />
            </div>
            <div className={Styles.informationLine}>
              <InformationField title="Status" value={data?.contract?.status || '-'} />
            </div>
          </div>
        </div>
        <div className={Styles.otherInformation}>
          <div className={Styles.otherInformationHeader}>Contract History</div>
          <div className={Styles.otherInformationBody}>
            {data?.contract?.history?.length > 0 ? data?.contract?.history?.map((each, i) => (
              <div key={i} className={Styles.informationLine}>
                <InformationField icon="work-scheme" title="Employment ID" value={each?.id} />
                <InformationField icon="calendar" title="Job title" value={each?.jobTitle} />
              </div>
            )) : (
              <div className={Styles.emptyHistory}>
                no contract history
              </div>
            )}
            {/* <div className={Styles.informationLine}>
              <InformationField icon="work-scheme" title="Employment ID" value="44BB3" />
              <InformationField icon="calendar" title="Job title" value="Digital Marketing" />
            </div>
            <div className={Styles.informationLine}>
              <InformationField icon="work-scheme" title="Employment ID" value="44BB3" />
              <InformationField icon="calendar" title="Job title" value="Digital Marketing" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetailPage;
