import Counter from "@Atom/Counter";
import Icon from "@Atom/Icon";
import Stepper from "@Molecule/Stepper";
import { useEffect, useMemo, useRef, useState } from "react";
import FirstSubStep from "./FirstSubStep";
import SecondSubStep from "./SecondSubStep";
import ThirdSubStep from "./ThirdSubStep";
import Styles from "./style.module.scss";

const stepsOptions = [
  {
    id: 1,
    label: "Salary Allowance",
    isDone: false,
  },
  {
    id: 2,
    label: "Expected Date",
    isDone: false,
  },
  {
    id: 3,
    label: "Job Qualification",
    isDone: false,
  },
];

export default function OnManPowerProgress(props) {
  const {
    data,
    locationIndex,
    indexToAppend,
    handleAddSamePosition,
    form,
    setForm,
    qualifications,
    handleShowAddQualifications,
    handleShowAddQualificationsMobile,
    options,
    trigger,
    setTrigger,
    debounceTrigger,
  } = props;

  const [currentSubStep, setCurrentSubStep] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(
    form[locationIndex]?.jobs[indexToAppend]?.title
  );

  const defaultSteps = useMemo(() => {
    const arr = [];
    for (let i = 0; i < stepsOptions?.length; i++) {
      arr.push({
        identifier: `${locationIndex}-${indexToAppend}-${Math.random()}`,
        id: stepsOptions[i].id,
        label: stepsOptions[i].label,
        isDone: stepsOptions[i].isDone,
      });
    }
    return arr;
  }, [indexToAppend, locationIndex]);

  const [steps, setSteps] = useState(defaultSteps);

  const onSubtract = () => {
    if (data?.demand > 1) {
      const newForm = [...form];
      const perLocation = { ...newForm[locationIndex] };
      let jobData = perLocation?.jobs[indexToAppend];
      jobData.demand = jobData?.demand - 1;
      setForm(form, [...newForm]);
      setTrigger(Math.random());
    }
  };

  const onAdd = () => {
    const newForm = [...form];
    const perLocation = { ...newForm[locationIndex] };
    let jobData = perLocation?.jobs[indexToAppend];
    jobData.demand = jobData?.demand + 1;
    setForm(form, [...newForm]);
    setTrigger(Math.random());
  };

  const onClickStep = (id) => {
    if (id === 1) {
      setCurrentSubStep(id);
    }
    if (id === 2) {
      if (steps[0]?.isDone) {
        setCurrentSubStep(id);
      }
    }
    if (id === 3) {
      if (steps[1]?.isDone) {
        setCurrentSubStep(id);
      }
    }
  };

  const handleDeleteManPower = (manPowerIndex) => {
    const newForm = [...form];
    const perLocation = { ...newForm[locationIndex] };
    perLocation?.jobs?.splice(manPowerIndex, 1);
    setForm(form, [...newForm]);
    setTrigger(Math.random());
  };

  const editTitleRef = useRef();

  const handleClickEdit = (e) => {
    setEditMode(true);
    document
      .getElementById(`title[${locationIndex}][${indexToAppend}]`)
      .focus();
  };

  const handleEditTitle = (e) => {
    setTitle(e.target.value);
  };

  useEffect(() => {
    const newForm = [...form];
    const perLocation = newForm[locationIndex];
    perLocation.jobs[indexToAppend].title = title;
    setForm(form, [...newForm]);
    debounceTrigger();
    // eslint-disable-next-line
  }, [form, title, locationIndex, indexToAppend, debounceTrigger]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setEditMode(false);
    }
  };

  const isSecondSubStepDone = useMemo(() => {
    if (!form[locationIndex]?.jobs[indexToAppend]?.fulfillmentDate) {
      return false;
    }
    return true;
  }, [form, locationIndex, indexToAppend]);

  const isThirdSubStepDone = useMemo(() => {
    let temp = false;
    const qualificationArr =
      form[locationIndex]?.jobs[indexToAppend]?.qualifications;
    if (qualificationArr?.length <= 0) {
      return false;
    }
    if (qualificationArr?.length > 0) {
      for (let i = 0; i < qualificationArr?.length; i++) {
        if (
          !qualificationArr[i]?.value ||
          qualificationArr[i]?.value === 0 ||
          qualificationArr[i]?.value === ""
        ) {
          return false;
        } else {
          temp = true;
        }
      }
    }

    return temp;
  }, [form, indexToAppend, locationIndex]);

  useEffect(() => {
    const newSteps = [...steps];
    if (!isSecondSubStepDone) {
      newSteps[1].isDone = false;
    } else {
      newSteps[1].isDone = true;
    }

    if (!isThirdSubStepDone) {
      newSteps[2].isDone = false;
    } else {
      newSteps[2].isDone = true;
    }
    setTrigger(Math.random());

    //eslint-disable-next-line
  }, [steps, form, isSecondSubStepDone, isThirdSubStepDone]);

  return (
    <div className={Styles.container}>
      <div className={Styles.boxWrapper}>
        <div className={Styles.topWrapper}>
          <div className={Styles.header}>
            <div className={Styles.inputWrapper}>
              <input
                readOnly={!editMode}
                ref={editTitleRef}
                id={`title[${locationIndex}][${indexToAppend}]`}
                className={Styles.titleInput}
                value={title}
                onChange={handleEditTitle}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className={Styles.rightController}>
              <Counter
                value={data?.demand}
                onSubtract={onSubtract}
                onAdd={onAdd}
              />
              <div className={Styles.actionsWrapper}>
                <button
                  className={Styles.deleteButton}
                  onClick={() => handleDeleteManPower(indexToAppend)}
                >
                  <Icon icon="trash" size={20} />
                </button>
                <button className={Styles.editButton} onClick={handleClickEdit}>
                  <Icon icon="edit" size={20} />
                </button>
              </div>
            </div>
          </div>
          <div className={Styles.stepperWrapper}>
            <Stepper
              type="outlined"
              options={steps}
              currentStep={currentSubStep}
              onClick={onClickStep}
            />
          </div>
        </div>
        <div className={Styles.middleWrapper}>
          {currentSubStep === 1 && (
            <FirstSubStep
              steps={steps}
              setSteps={setSteps}
              options={options}
              form={form}
              setForm={setForm}
              locationIndex={locationIndex}
              manPowerIndex={indexToAppend}
              trigger={trigger}
              setTrigger={setTrigger}
              debounceTrigger={debounceTrigger}
              onNext={() => setCurrentSubStep(2)}
            />
          )}
          {currentSubStep === 2 && (
            <SecondSubStep
              steps={steps}
              setSteps={setSteps}
              form={form}
              setForm={setForm}
              locationIndex={locationIndex}
              manPowerIndex={indexToAppend}
              setTrigger={setTrigger}
              onPrevious={() => setCurrentSubStep(1)}
              onNext={() => setCurrentSubStep(3)}
            />
          )}
          {currentSubStep === 3 && (
            <ThirdSubStep
              steps={steps}
              setSteps={setSteps}
              qualifications={qualifications}
              form={form}
              setForm={setForm}
              handleShowAddQualifications={handleShowAddQualifications}
              handleShowAddQualificationsMobile={
                handleShowAddQualificationsMobile
              }
              locationIndex={locationIndex}
              manPowerIndex={indexToAppend}
              options={options}
              trigger={trigger}
              setTrigger={setTrigger}
              debounceTrigger={debounceTrigger}
              onPrevious={() => setCurrentSubStep(2)}
            />
          )}
        </div>
      </div>
      <div className={`${Styles.boxWrapper} ${Styles.addSameBox}`}>
        <p className={Styles.addSameDesc}>
          Tambah posisi <span> {data?.title} </span> dengan ketentuan berbeda
        </p>
        <button
          onClick={() =>
            handleAddSamePosition(locationIndex, indexToAppend, data?.title)
          }
        >
          <Icon icon="add-circle" size={20} />
          <span>Tambah</span>
        </button>
      </div>
    </div>
  );
}
