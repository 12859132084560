import { default as SlickSlider } from "react-slick";
import "./style.scss";

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  appendDots: (dots) => <ul>{dots}</ul>,
  customPaging: function (i) {
    return <div className="dot"></div>;
  },
  dotsClass: "slick-dots slick-thumb",
};

export default function Slider({ children, ...props }) {
  return <SlickSlider {...settings}>{children}</SlickSlider>;
}
