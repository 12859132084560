import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/api";
import UseOutsideClick from "@Hooks/useOutsideClick";
import useWindowSize from "@Hooks/useWindowSize";
import Images from "@Theme/Images";
import styled from "@emotion/styled";
import { Popover } from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Styles from "./Styles.module.scss";

// eslint-disable-next-line no-unused-vars
const MuiPopover = styled(Popover)({
  "& 	.MuiPopover-root": {},
  "& .MuiPopover-paper": {
    borderRadius: 8,
    width: "197px",
    padding: "8px",
    paddingBottom: "16px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
  },
});

export default function HeaderComponent({ user }) {
  // const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  //   const {  isSuccess, user } = useSelector((state) => state.corpData);

  // const user = useMemo(() => {
  //   return {
  //     name: sessionStorage.getItem('username') || null,
  //     position: sessionStorage.getItem('role') || null,
  //   };
  // }, [])

  // const [user, setUser] = useState(defaultUser)
  const { width } = useWindowSize();

  const isSuccess = useMemo(() => {
    if (user?.name) {
      return true;
    }
    return false;
  }, [user]);

  // const memoizedLocation = useMemo(() => {
  //   return location.pathname.split("/")[
  //     location.pathname.split("/").length - 1
  //   ];
  // }, [location]);

  // useEffect(() => {
  //   setGlobalLanguage(language);
  // }, [language, setGlobalLanguage]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);

  const navigateToDashboard = () => {
    // sessionStorage.setItem("currentMode", "dashboard");
    localStorage.setItem("currentMode", "dashboard");
    setAnchorEl(null);
    navigate("/dashboard");
  };

  const handleLogout = () => {
    setAnchorEl(null);

    sessionStorage.clear();
    localStorage.clear();
    // setUser(null)
    navigate("/");
  };

  const [openNav, setOpenNav] = useState(false);

  const boxRef = useRef();
  // eslint-disable-next-line
  const boxOutsideClick = UseOutsideClick(boxRef);

  // nanti nyalain lagi ya kalau gajadi
  // useEffect(() => {
  //   if (openNav) {
  //     if (boxOutsideClick === false) {
  //       setOpenNav(false);
  //     }
  //   }
  // }, [boxOutsideClick, openNav]);

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.headerBody}>
        <div className={Styles.linkArea}>
          <div className={Styles.left}>
            <div className={Styles.logoBox}>
              {width <= 768 ? (
                <Icon
                  onClick={() => setOpenNav(!openNav)}
                  icon={openNav ? "cross" : "burger-icon"}
                  size={20}
                />
              ) : null}
              <div className={Styles.imagesBox}>
                <img
                  className={Styles.logo}
                  src={Images.KERJA365_LIGHT}
                  alt="KERJA_365_LOGO"
                />
              </div>
            </div>
            <Link
              to={"/"}
              className={`${Styles.span} ${
                pathname === "/job-seeker" || pathname === "/"
                  ? Styles.spanActive
                  : null
              }`}
            >
              Job Seekers
            </Link>
            <Link
              to={"/business"}
              className={`${Styles.span} ${
                pathname === "/business" ? Styles.spanActive : null
              }`}
            >
              Business
            </Link>
          </div>

          <div className={Styles.right}>
            {pathname === "/job-seeker" || pathname === "/" ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a href="#" className={Styles.buttonApp} alt="">
                Get app
              </a>
            ) : (
              <>
                {!isSuccess ? (
                  <>
                    {pathname === "/login" ? null : (
                      <Link
                        to={"/login"}
                        className={`${Styles.login} ${
                          pathname === "/register" && Styles.blueBg
                        }`}
                      >
                        Masuk
                      </Link>
                    )}

                    {pathname === "/register" ? null : (
                      <Link to={"/register"} className={Styles.register}>
                        Daftar
                      </Link>
                    )}
                  </>
                ) : (
                  <>
                    {anchorEl ? (
                      <Icon
                        icon={"arrow-up"}
                        size={24}
                        className={Styles.iconDown}
                        onClick={() => setAnchorEl(false)}
                      />
                    ) : (
                      <Icon
                        icon={"arrow-down"}
                        size={24}
                        className={Styles.iconDown}
                        onClick={() => setAnchorEl(true)}
                      />
                    )}

                    <div
                      className={Styles.profileBox}
                      onClick={() => navigate("/in")}
                    >
                      <div className={Styles.imagesProfile}>
                        <img
                          src={
                            user?.profilePicture &&
                            user?.profilePicture !== "null"
                              ? fileBaseUrl + user?.profilePicture
                              : Images.AVA_DEFAULT
                          }
                          alt="PROFILE_PICTURE"
                          className={Styles.profilePicture}
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div className={Styles.nameProfile}>
                        <span className={Styles.username}>{user?.name}</span>
                        <span className={Styles.position}>
                          {user?.position}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {anchorEl && (
          <>
            <div className={Styles.bgModalProfile}>
              <div className={Styles.modalContainer}>
                <div
                  className={Styles.profileBoxDropdown}
                  onClick={() => navigate("/in")}
                >
                  <div className={Styles.imagesProfile}>
                    <img
                      src={
                        user?.profilePicture && user?.profilePicture !== "null"
                          ? fileBaseUrl + user?.profilePicture
                          : Images.AVA_DEFAULT
                      }
                      alt="PROFILE_PICTURE"
                      className={Styles.profilePicture}
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div className={Styles.nameProfile}>
                    <span className={Styles.username}>{user?.name}</span>
                    <span className={Styles.position}>{user?.position}</span>
                  </div>
                </div>
                <div className={Styles.routeAction}>
                  <div className={Styles.box} onClick={navigateToDashboard}>
                    <Icon icon="dashboard-filled" size={17} color="#0a0a0a" />
                    <span className={Styles.dashboard}>Dashboard</span>
                  </div>
                  <div className={Styles.box} onClick={handleLogout}>
                    <Icon icon="log-out" size={17} color="#FF3E13" />
                    <span className={Styles.logout}>Log Out</span>
                  </div>
                </div>
              </div>
              <div
                className={Styles.null}
                onClick={() => setAnchorEl(false)}
              ></div>
            </div>
            <div className={Styles.dropDownProfile}>
              <div className={Styles.act}>Action</div>
              <div className={Styles.routeAction}>
                <div className={Styles.box} onClick={navigateToDashboard}>
                  <Icon icon="dashboard-filled" size={17} color="#0a0a0a" />
                  <span className={Styles.dashboard}>Dashboard</span>
                </div>
                <div className={Styles.box} onClick={handleLogout}>
                  <Icon icon="log-out" size={17} color="#FF3E13" />
                  <span className={Styles.logout}>Log Out</span>
                </div>
              </div>
            </div>
          </>
        )}
        {openNav && (
          <div className={Styles.bgModal}>
            <div className={Styles.modalContainer}>
              <Link
                to={"/"}
                className={`${Styles.h1} ${
                  pathname === "/" ? Styles.spanActive : null
                }`}
              >
                Job Seekers
              </Link>
              <Link
                to={"/business"}
                className={`${Styles.h1} ${
                  pathname === "/business" ? Styles.spanActive : null
                }`}
              >
                Business
              </Link>
            </div>
            <div
              className={Styles.null}
              onClick={() => setOpenNav(false)}
            ></div>
          </div>
        )}
      </div>
    </div>

    // <nav className={Styles.nav}>
    //   <div
    //     className={`${Styles.modalWrapper} ${
    //       openNav ? Styles.showModal : Styles.hideModal
    //     }`}
    //   >
    //     <div className={Styles.modalContainer}>
    //       <div className={`${Styles.wrapper} ${Styles.product}`}>
    //         <span
    //           onClick={(e) => {
    //             navigate("/");
    //             setOpenNav(false);
    //           }}
    //           className={`${Styles.link} ${
    //             memoizedLocation === "" && Styles.active
    //           }`}
    //         >
    //           Business
    //         </span>
    //       </div>
    //       <div className={`${Styles.wrapper}`}>
    //         <span
    //           onClick={(e) => {
    //             navigate("job-seeker");
    //             setOpenNav(false);
    //           }}
    //           className={`${Styles.link} ${
    //             memoizedLocation === "job-seeker" && Styles.active
    //           }`}
    //         >
    //           Job Seekers
    //         </span>
    //       </div>
    //     </div>
    //   </div>
    //   <div
    //     ref={boxRef}
    //     className={`${Styles.overlay} ${openNav ? Styles.visible : ""}`}
    //   />
    //   <div className={Styles.linkArea}>
    //     <div className={Styles.hamburgerMenu}>
    //       <Icon
    //         onClick={() => setOpenNav(!openNav)}
    //         icon={openNav ? "cross" : "burger-icon"}
    //         size={20}
    //       />
    //     </div>
    //     <div className={Styles.logoArea}>
    //       <img
    //         className={Styles.logo}
    //         src={Images.KERJA365_LIGHT}
    //         alt="KERJA_365_LOGO"
    //       />
    //     </div>
    //     <div className={`${Styles.wrapper} ${Styles.product}`}>
    //       <span
    //         onClick={(e) => navigate("/")}
    //         className={`${Styles.link} ${
    //           memoizedLocation === "" && Styles.active
    //         }`}
    //       >
    //         Business
    //       </span>
    //     </div>
    //     <div className={`${Styles.wrapper}`}>
    //       <span
    //         onClick={(e) => navigate("job-seeker")}
    //         className={`${Styles.link} ${
    //           memoizedLocation === "job-seeker" && Styles.active
    //         }`}
    //       >
    //         Job Seekers
    //       </span>
    //     </div>
    //   </div>
    //   {pathname.includes("/job-seeker") && (
    //     <div className={Styles.actionArea}>
    //       <div className={Styles.centerAligner}>
    //         <a
    //           href="https://play.google.com/store/apps/details?id=com.kerja365"
    //           style={{ textDecoration: "none" }}
    //         >
    //           <Button
    //             variant="contained"
    //             sx={{ borderRadius: "20px", textTransform: "capitalize" }}
    //             className={`${Styles.loginButton} ${Styles.jobSeekersButton} ${Styles.register}`}
    //           >
    //             Get App
    //           </Button>
    //         </a>
    //       </div>
    //     </div>
    //   )}
    //   {!pathname.includes("/job-seeker") && isSuccess && (
    //     <div
    //       className={Styles.control}
    //       onClick={() => navigate("/in")}
    //       style={{ cursor: "pointer" }}
    //     >
    //       <div className={Styles.control} onClick={handleClick}>
    //         <img
    //           src={
    //             user?.profilePicture && user?.profilePicture !== "null"
    //               ? fileBaseUrl + user?.profilePicture
    //               : Images.AVA_DEFAULT
    //           }
    //           alt="PROFILE_PICTURE"
    //           className={Styles.profilePicture}
    //           style={{ objectFit: "cover" }}
    //         />
    //         <div className={Styles.User}>
    //           <div>
    //             <span>{user?.name}</span>
    //           </div>
    //           <div>
    //             <span>{user?.position}</span>
    //           </div>
    //         </div>
    //       </div>
    //       <MuiPopover
    //         open={open}
    //         anchorEl={anchorEl}
    //         onClose={handleClose}
    //         anchorOrigin={{
    //           vertical: "bottom",
    //           horizontal: "left",
    //         }}
    //         className={Styles.popover}
    //       >
    //         <div className={Styles.stacked} onClick={navigateToDashboard}>
    //           <Icon icon="dashboard-filled" size={17} color="#0a0a0a" />
    //           <span>Dashboard</span>
    //         </div>
    //         <div className={Styles.stacked} onClick={handleLogout}>
    //           <Icon icon="log-out" size={17} color="#FF3E13" />
    //           <span>Log Out</span>
    //         </div>
    //       </MuiPopover>
    //     </div>
    //   )}

    //   {!pathname.includes("/job-seeker") && !isSuccess && (
    //     <div className={Styles.actionArea}>
    //       <div
    //         className={Styles.centerAligner}
    //         style={{
    //           display: memoizedLocation === "login" ? "none" : "flex",
    //           paddingInline:
    //             memoizedLocation === "login" || memoizedLocation === "register"
    //               ? 20
    //               : 0,
    //         }}
    //       >
    //         <Button
    //           onClick={() =>
    //             navigate("login", {
    //               replace: memoizedLocation === "register",
    //             })
    //           }
    //           className={`${Styles.loginButton} ${
    //             memoizedLocation === "register" ? Styles.register : Styles.login
    //           }`}
    //         >
    //           Masuk
    //         </Button>
    //       </div>
    //       <div
    //         className={Styles.centerAligner}
    //         style={{
    //           display: memoizedLocation === "register" ? "none" : "flex",
    //           paddingInline:
    //             memoizedLocation === "login" || memoizedLocation === "register"
    //               ? 20
    //               : 0,
    //         }}
    //       >
    //         <Button
    //           onClick={() =>
    //             navigate("register", {
    //               replace: memoizedLocation === "login",
    //             })
    //           }
    //           className={`${Styles.loginButton} ${Styles.register}`}
    //         >
    //           Daftar
    //         </Button>
    //       </div>
    //     </div>
    //   )}
    // </nav>
  );
}
