import React, { useRef } from "react";
import { default as SlickSlider } from "react-slick";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import Icon from "@Atom/Icon";
import "./slick.scss";

const testimoniesData = [
  {
    name: "LUKMAN EFENDI",
    position: "SALES EXECUTIVE",
    value:
      "TERIMAKASIH BANYAK UNTUK PERMATA INDONESIA, KARENA TELAH DIBERIKAN KESEMPATAN UNTUK BEKERJA. SEMOGA SEMAKIN SUKSES PERMATA INDONESIA.",
  },
  {
    name: "AGUS HARTADI",
    position: "SECURITY",
    value:
      "Permata perusahaan yang sigap, cepat, dan tanggap. Semoga permata tambah maju",
  },
  {
    name: "RENDY PRADANA AMRI",
    position: "MARKETING",
    value: "Tetaplah berikan pelayanan yang terbaik",
  },
  {
    name: "BIMO JOKO WALUYO",
    position: "TEAM LEADER",
    value: "Lanjutkan lagi sistem permata yang sudah baik ini",
  },
  {
    name: "ANDI SUKANDI",
    position: "UPSELLING",
    value: "Saya merasa sangat puas dengan pelayanan permata saat ini",
  },
];

export default function JobSeekersTestimonies() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    variableWidth: true,
  };
  const slickRef = useRef();

  const goNext = () => {
    slickRef.current && slickRef.current.slickNext();
  };
  const goPrev = () => {
    slickRef.current && slickRef.current.slickPrev();
  };

  return (
    <div
      className={`${Styles.jobSeekersTestimoniesContainer} jobSeekersTestimoniesContainer`}
    >
      <div className={Styles.titleWrapper}>
        <div className={Styles.titleHead}>
          <div className={Styles.line} />
          <p>Aplikasi pencari kerja terbaik</p>
          <div className={Styles.line} />
        </div>
        <div className={Styles.tagLine}>
          <p>
            LIhat <span>Kisah Sukses</span> Mereka Bersama Kami
          </p>
        </div>
      </div>
      <div className={"jobSeekersTestimoniesContainerCarousel"}>
        <SlickSlider {...settings} ref={slickRef}>
          {[
            testimoniesData.map((el, idx) => (
              <div className={Styles.cardContainer}>
                <div className={`${Styles.card}`}>
                  <div className={Styles.cardHead}>
                    <div className={Styles.profile}>
                      <img src={Images.AVA_DEFAULT} alt="avatar" />
                      <div className={Styles.profileText}>
                        <p>{el.name}</p>
                        <p>{el.position}</p>
                      </div>
                    </div>
                    <img
                      src={Images.DOUBLE_QUOTE}
                      alt="double-quote"
                      className={Styles.doubleQuote}
                    />
                  </div>
                  <div className={Styles.cardBody}>
                    <p
                      className={`${Styles.textBody} jobSeekersTestimoniesContainerTestimonies`}
                    >
                      {el.value}
                    </p>
                  </div>
                </div>
              </div>
            )),
          ]}
        </SlickSlider>
        <div className={Styles.sliderAction}>
          <div className={Styles.icon} onClick={goPrev}>
            <Icon icon={"arrow-left-back"} size={24} />
          </div>
          <div className={Styles.icon} onClick={goNext}>
            <Icon icon={"arrow-right-next"} size={24} />
          </div>
        </div>
      </div>
    </div>
  );
}
