import Icon from '@Atom/Icon';
import styled from '@emotion/styled';
import { Avatar, Rating, TableBody, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Styles from './style.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import useQuery from '@Hooks/useQuery';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#1571DE',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

export default function TableData({ data, order, orderBy, setSelectedEmployee }) {

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const query = useQuery()
  const clientName = query.get('n')
  const clientCode = query.get('c')

  const navigate = useNavigate()
  const location = useLocation()


  return (
    <TableBody className={Styles.tableBody}>
      {
        stableSort(data, getComparator(order, orderBy))?.map((each, i) => {
          return (
            <TableRow key={i} className={Styles.tableRow}>
              <TableCell className={Styles.tableCell} padding='none'>
                <div className={Styles.profile}>
                  {each?.profilePicture ? <Avatar src={each?.profilePicture} alt={each?.name} sx={{ width: windowSize[0] > 768 ? 39 : 34, height: windowSize[0] > 768 ? 39 : 34 }} />
                    : <Avatar sx={{ width: windowSize[0] > 768 ? 39 : 34, height: windowSize[0] > 768 ? 39 : 34 }}>{each?.name[0]}</Avatar>
                  }
                  <div className={Styles.desc}>
                    <span>{each?.name}</span>
                    <span>{each?.jobTitle}</span>
                  </div>
                </div>
              </TableCell>
              <TableCell className={`${Styles.tableCell} ${Styles.hideOnMobile}`} padding='none'>
                <div className={Styles.contact}>
                  <div className={Styles.stacked}>
                    <Icon className={Styles.icon} icon="email-outline" size={14} />
                    <span>{each?.email}</span>
                  </div>
                  <div className={Styles.stacked}>
                    <Icon className={Styles.icon} icon="phone-outline" size={14} />
                    <span>{each?.phoneNumber}</span>
                  </div>
                </div>
              </TableCell>
              <TableCell className={`${Styles.tableCell} ${Styles.hideOnMobile}`} padding='none'>
                <div className={Styles.contract}>
                  <div className={Styles.period}>
                    <span>
                      {each?.contract?.startDate ? moment(new Date(each?.contract?.startDate))?.format("DD MMM YYYY") : '-'}
                    </span>
                    <span>
                      {each?.contract?.endDate ? moment(new Date(each?.contract?.endDate))?.format("DD MMM YYYY") : '-'}
                    </span>
                  </div>
                  <div className={Styles.length}>
                    <span>{(each?.contract?.startDate && each?.contract?.endDate) ? moment(new Date(each?.contract?.endDate)).diff(new Date(each?.contract?.startDate), 'years') : '0'} Y</span>
                  </div>
                </div>
              </TableCell>
              <TableCell className={`${Styles.tableCell} ${Styles.hideOnMobile}`} padding='none'>
                <div>
                  <StyledRating
                    value={each?.rating}
                    precision={1}
                    size="large"
                    icon={<Icon icon="star" size={24} />}
                    emptyIcon={<Icon icon="star" size={24} color="#e0e0e0" />}
                    readOnly />
                </div>
              </TableCell>
              <TableCell className={`${Styles.tableCell} ${Styles.hideOnMobile}`} padding='none'>
                <div className={`${Styles.status} ${each?.employmentStatus !== 'active' ? Styles.active : Styles.inactive}`}>
                  <span>{each?.employmentStatus !== 'inactive' ? 'Active' : 'Not Active'}</span>
                </div>
              </TableCell>
              <TableCell className={`${Styles.tableCell} ${Styles.hideOnMobile}`} padding='none'>
                <div className={Styles.seeDetail}>
                  <button
                   onClick={()=> navigate(`${location?.pathname}/candidate?n=${each?.name}&c=${each?.id}&cc=${clientCode}&cn=${clientName}`)}
                   >See Detail</button>
                </div>
              </TableCell>
              <TableCell className={`${Styles.tableCell} ${Styles.showOnMobile}`} padding='none' align='center'>
                <div className={Styles.seeMore}>
                  <button onClick={() => setSelectedEmployee(each)}>See More</button>
                </div>
              </TableCell>

            </TableRow>
          )
        })
      }
    </TableBody>
  )
}