import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import { useDebounce } from "@Hooks/useDebounce";
import { fetchJobPostByRO } from "@Services/officer/recruitment";
import { useEffect, useState } from "react";
import Styles from "../styles.module.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Radio from "@Atom/Radio";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import Pagination from "@Molecule/Pagination";
import Icon from "@Atom/Icon";

export default function Step2({
  selectedPosition,
  setSelectedPosition,
  selectedRecruiter,
  setSelectedRecruiter,
}) {
  const TABLE_HEAD = [
    { id: "position", label: "Posisi", align: "left" },
    // { id: "cityName", label: "Lokasi Kerja", align: "left" },
    { id: "clientName", label: "Klien", align: "left" },
  ];
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadPosition, setLoadPosition] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [pages, setPages] = useState(`1-${limit}`);
  const [pageList, setPageList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [totalData, setTotalData] = useState();

  const debouncedSearch = useDebounce(searchTerm, 300);

  const handleBack = () => {
    setSelectedRecruiter(null);
    setSelectedPosition(null);
  };

  useEffect(() => {
    const fetchJobPosition = async () => {
      try {
        setIsLoading(true);

        const { response } = await fetchJobPostByRO(
          selectedRecruiter?.employeeId,
          `limit=${limit}&page=${page}&search=${debouncedSearch}`
        );
        setLoadPosition(response?.data);
        setTotalPage(response?.totalPage);
        setTotalData(response?.data?.length);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    fetchJobPosition();
  }, [limit, page, debouncedSearch, selectedRecruiter]);

  const clickRadio = (option) => {
    if (selectedPosition === option) {
      setSelectedPosition(null);
    } else {
      setSelectedPosition(option);
    }
  };
  return (
    <div className={Styles.content}>
      <div className={Styles.topBarWrapper}>
        <div className={Styles.backBox} onClick={handleBack}>
          <Icon icon={"arrow-left"} color={"#1571de"} size={20} />
          <span>{`Kembali - ${selectedRecruiter?.name}`}</span>
        </div>
        <div className={Styles.searchBarBox}>
          <OutlinedSearchBar
            value={searchTerm}
            setValue={setSearchTerm}
            placeholder={"Cari Nama Job Posting"}
          />
        </div>
      </div>
      <div className={Styles.tableWrapper}>
        <TableContainer className={Styles.tableContainer}>
          <Table className={Styles.table}>
            <colgroup>
              <col style={{ width: "55%" }} />
              <col style={{ width: "45%" }} />
            </colgroup>
            <TableHead className={Styles.tableHead}>
              <TableRow className={Styles.tableHeadRow}>
                {TABLE_HEAD?.map((eachH, iH) => (
                  <TableCell
                    key={"headcell" + iH}
                    align={eachH?.align}
                    padding="none"
                    className={Styles.tableHeadCell}
                  >
                    <div>
                      <span>{eachH?.label}</span>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={Styles.tableBody}>
              {loadPosition?.map((eachP, iP) => {
                return (
                  <TableRow
                    className={`${Styles.tableRow}`}
                    key={"tableRow" + iP}
                  >
                    <TableCell padding="none" className={Styles.tableCell}>
                      <div className={Styles.stackedProfile}>
                        <div className={Styles.radio}>
                          <Radio
                            setIsChecked={() => clickRadio(eachP)}
                            isChecked={selectedPosition?._id === eachP?._id}
                          />
                        </div>
                        <span>{eachP.title}</span>
                      </div>
                    </TableCell>
                    {/* <TableCell padding="none" className={Styles.tableCell}>
                      <div className={Styles.cityNameBox}>
                        <span>{eachP?.cityName || "-"}</span>
                      </div>
                    </TableCell> */}
                    <TableCell padding="none" className={Styles.tableCell}>
                      {eachP?.positionID == 8612 ? (
                        <div className={Styles.clientWrapper}>
                          <div className={Styles.iconDash}>
                            <Icon
                              icon={"information-solid"}
                              size={16}
                              color={"#1571de"}
                            />
                          </div>
                          <span>
                            {"Kandidat hanya tersimpan di daftar private"}
                          </span>
                        </div>
                      ) : (
                        <span>{eachP?.clientName}</span>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!loadPosition.length && (
          <div className={Styles.emptyDialogWrapper}>
            <TableEmptyHandler
              title={isLoading ? "Tunggu sebentar" : "Data Kosong"}
              desc={
                isLoading
                  ? "Data sedang dimuat"
                  : "Tidak ada data atau data belum ada"
              }
            />
          </div>
        )}

        <div className={Styles.paginationWrapper}>
          <Pagination
            total={totalData}
            totalPage={totalPage}
            value={limit}
            setValue={setLimit}
            pages={pages}
            setPages={setPages}
            pageList={pageList}
            setPageList={setPageList}
            selectedPage={page}
            setSelectedPage={setPage}
          />
        </div>
      </div>
    </div>
  );
}
