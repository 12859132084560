import ManPowerRequestSummaryContentClient from '@Organism/Client/ManPowerRequestSummary/Content'
import ManPowerRequestSummaryNavControllerClient from '@Organism/Client/ManPowerRequestSummary/NavController'
import { Helmet } from 'react-helmet-async'
import Styles from './style.module.scss'
import { getManPowerDetailNeedClient } from '@Services/client/dashboard';
import { Await, defer, useLoaderData } from 'react-router-dom';
import AsyncErrorBoundary from '@Organism/Utils/AsyncErrorBoundary';
import LoadingAnimation from '@Atom/LoadingAnimation';
import { Suspense } from 'react';

export async function loader(route) {
  const url = new URL(route?.request?.url);
  const id = url.searchParams.get("i");

  const packageSummaryPromise = getManPowerDetailNeedClient(id, 10, 1, '', null)

  return defer({
    packageSummary: packageSummaryPromise
  });
}

export default function ManPowerRequestSummaryClient() {
  const { packageSummary } = useLoaderData()

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Dashboard</title>
      </Helmet>
      <div className={Styles.navControlWrapper}>
        <ManPowerRequestSummaryNavControllerClient />
      </div>
      <div className={Styles.contentWrapper}>
        <Suspense fallback={
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        }>
          <Await
            resolve={packageSummary}
            errorElement={
              <div style={{ height: 'calc(100vh - 220px)' }}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageSummary) => (
              <ManPowerRequestSummaryContentClient
                defaultData={packageSummary?.response}
              />
            )}

          </Await>
        </Suspense>
      </div>
    </div>
  )
}