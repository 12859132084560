import LoadingAnimation from "@Atom/LoadingAnimation";
import NewDashboardLayout from "@Organism/Client/NewDashboard";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";

// export async function loader(){
//   return defer({
//     packageProgressBar : getProgressBar(),
//     packageSummary : getPositionsSummary()
//   })
// }

export default function NewClientDashboard() {
  // const { packageProgressBar, packageSummary, packageSync } = useLoaderData()
  const { packageDashboardSync } = useLoaderData();

  return (
    <Suspense
      fallback={<NewDashboardLayout isLoading />}
      // fallback={
      //   <div
      //     style={{
      //       height: "calc(100vh - 65px)",
      //       display: "grid",
      //       placeItems: "center",
      //     }}
      //   >
      //     <LoadingAnimation />
      //   </div>
      // }
    >
      <Await
        resolve={packageDashboardSync}
        errorElement={<AsyncErrorBoundary />}
      >
        {(packageDashboardSync) => (
          <NewDashboardLayout dashboardSync={packageDashboardSync?.response} />
        )}
      </Await>
    </Suspense>
  );

  // return (
  //   <Suspense
  //     // fallback={
  //     //   <NewDashboardLayout
  //     //     isLoading
  //     //   />
  //     // }
  //     fallback={
  //       <div style={{ height: 'calc(100vh - 65px)', display: 'grid', placeItems: 'center' }}>
  //         <LoadingAnimation />
  //       </div>
  //     }
  //   >
  //     <Await
  //       resolve={packageProgressBar}
  //       errorElement={
  //         <AsyncErrorBoundary />
  //       }>
  //       {(packageProgressBar) => (
  //         <Suspense
  //           // fallback={
  //           //   <NewDashboardLayout
  //           //     isLoading
  //           //   />
  //           // }
  //           fallback={
  //             <div style={{ height: 'calc(100vh - 65px)', display: 'grid', placeItems: 'center' }}>
  //               <LoadingAnimation />
  //             </div>
  //           }
  //         >
  //           <Await
  //             resolve={packageSummary}
  //             errorElement={
  //               <AsyncErrorBoundary />
  //             }>
  //             {(packageSummary) => (
  //               <Suspense
  //                 fallback={
  //                   <div style={{ height: 'calc(100vh - 65px)', display: 'grid', placeItems: 'center' }}>
  //                     <LoadingAnimation />
  //                   </div>
  //                 }
  //               >
  //                 <Await
  //                   resolve={packageSync}
  //                   errorElement={
  //                     <AsyncErrorBoundary />
  //                   }
  //                 >
  //                   {(packageSync) => (
  //                     <NewDashboardLayout
  //                       progressBar={packageProgressBar?.response}
  //                       summaries={packageSummary?.response}
  //                       sync={packageSync?.response}
  //                     />

  //                   )}

  //                 </Await>
  //               </Suspense>
  //             )}

  //           </Await>

  //         </Suspense>
  //       )}

  //     </Await>
  //   </Suspense>
  // )
}
