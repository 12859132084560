import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./styles.module.scss";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import CustomTable from "@Molecule/CustomTable";
import Radio from "@Atom/Radio";
import { getLocationJobPost } from "@Services/officer/recruitment";
import { useDebounce } from "@Hooks/useDebounce";

export default function TableLocationToJobPosting({
  selectedLocation,
  setSelectedLocation,
  selectedPosition,
  selectedRecruiter = null,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);

  const [data, setData] = useState(null);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const debouncedSearchNameFilter = useDebounce(searchTerm || "", 300);

  const refetchData = useCallback(async () => {
    try {
      setIsFetchingData(true);
      const previousPage = page;
      const { response } = await getLocationJobPost(
        selectedPosition?._id,
        limit,
        page,
        encodeURIComponent(debouncedSearchNameFilter) || "",
        !selectedRecruiter
          ? selectedPosition?.recruiterID
          : selectedRecruiter?.employeeId
      );
      setData(response);
      setIsFetchingData(false);
      if (response?.totalPage === 1 || response?.totalPage === 0) {
        setPage(1);
      } else {
        const validPage = Math.max(
          1,
          Math.min(previousPage, response?.totalPage)
        );
        setPage(validPage);
      }
    } catch (err) {
      setIsFetchingData(false);
      console.log(err);
    }
  }, [
    page,
    selectedPosition,
    limit,
    selectedRecruiter,
    debouncedSearchNameFilter,
  ]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  const clickRadio = useCallback(
    (option) => {
      if (selectedLocation?.id === option?.id) {
        setSelectedLocation(null);
      } else {
        setSelectedLocation(option);
      }
    },
    [selectedLocation, setSelectedLocation]
  );

  const template = useMemo(() => {
    return {
      data: data?.data?.map((el, idx) => {
        return {
          id: idx,
          _id: el?._id,
          positionID: el?.positionID,
          recruiterID: el?.recruiterID,
          City: el?.City || null,
        };
      }),
      columns: [
        {
          name: "location",
          label: "Lokasi Kerja",
          //   tdClassName: Styles.stickyLeft,
          renderData: (row) => (
            <div className={Styles.locationRow}>
              <div className={Styles.radio}>
                <Radio
                  setIsChecked={() => clickRadio(row)}
                  isChecked={selectedLocation?.id === row?.id}
                />
              </div>
              <span>{row?.City ? row?.City?.name : "-"}</span>
            </div>
          ),
        },
      ],
    };
  }, [selectedLocation, clickRadio, data]);

  return (
    <div className={Styles.content}>
      <div className={Styles.searchBarWrapper}>
        <OutlinedSearchBar
          value={searchTerm}
          setValue={setSearchTerm}
          placeholder={"Cari Kota"}
        />
      </div>

      <div>
        <CustomTable
          columns={template?.columns}
          data={template?.data || []}
          // type={"sticky-left-right"}
          value={limit}
          setValue={setLimit}
          selectedPage={page}
          setSelectedPage={setPage}
          totalPage={data?.totalPage || 0}
          stickyTop
          isLoading={isFetchingData}
          totalData={data?.totalData || 0}
        />
      </div>
    </div>
  );
}
