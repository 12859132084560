import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/api";
import UseOutsideClick from "@Hooks/useOutsideClick";
import { useEffect, useRef } from "react";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Styles from "./styles.module.scss";

export default function JobSeekerDetailModal({
  images,
  handleBack,
  handleDone,
}) {
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      handleDone();
    }
  }, [boxOutsideClick, handleDone]);
  return (
    <div className={Styles.container}>
      <div className={Styles.bodyContainer}>
        <div className={Styles.closeBar}>
          <div className={Styles.null}></div>
          <div className={Styles.closeBox} onClick={handleBack}>
            <Icon icon={"cross"} size={24} />
          </div>
        </div>
        <div className={Styles.imagesBox} ref={boxRef}>
          <img
            src={
              images?.medias?.find((el) => el?.type === "Poster") &&
              images?.medias
                ?.find((el) => el?.type === "Poster")
                .uri.includes("https://ik.imagekit.io/kv")
                ? images?.medias?.find((el) => el?.type === "Poster")?.uri
                : fileBaseUrl +
                  images?.medias?.find((el) => el?.type === "Poster")?.uri
            }
            alt=""
          />
          {/* <LazyLoadImage
            src={
              images?.medias?.find((el) => el?.type === "Poster") &&
              images?.medias
                ?.find((el) => el?.type === "Poster")
                .uri.includes("https://ik.imagekit.io/kv")
                ? images?.medias?.find((el) => el?.type === "Poster")?.uri
                : fileBaseUrl +
                  images?.medias?.find((el) => el?.type === "Poster")?.uri
            }
            // PlaceholderSrc={PlaceholderImage}
            effect="blur"
          /> */}
        </div>
      </div>
    </div>
  );
}
