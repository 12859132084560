import { Avatar } from "@mui/material";
import Styles from "./style.module.scss";

export default function ContactsPerJobTitle({ data, title }) {
  return (
    <div className={Styles.container}>
      <h4>{title}</h4>
      <div className={Styles.contactsWrapper}>
        {data?.map((each, i) => (
          <div key={i} className={Styles.each}>
            {each?.profilePicture ? (
              <Avatar
                alt={each?.name}
                src={each?.profilePicture}
                sx={{ width: 32, height: 32 }}
              />
            ) : (
              <Avatar sx={{ width: 32, height: 32 }}>{each?.name[0]}</Avatar>
            )}
            <span>{each?.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
