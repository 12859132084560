import Styles from "./multiSelectSmallLabel.module.scss";
import React from "react";
import MultiSelect from "@Atom/MultiSelect";
import SmallLabel from "@Atom/SmallLabel";

const MultiSelectSmallLabel = ({
  title,
  options,
  labels,
  setLabels,
  preventDeleteIndicator,
  required,
  type,
  isError = false,
  isErrorV2 = false,
  errorText = '',
  ...props
}) => {
  const removeLabel = (name) => {
    const newArray = labels.filter((label) => label.name !== name);
    setLabels(newArray);
  };

  const addLabel = (label) => {
    setLabels([...labels, { ...label }]);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.titleWrapper}>
        {title}
        {required && <span className={Styles.asterisk}>&#x2a;</span>}
      </div>
      <div className={Styles.multiSelectWrapper}>
        <MultiSelect
          options={options}
          addLabel={addLabel}
          labels={labels}
          removeLabel={removeLabel}
          preventDeleteIndicator={preventDeleteIndicator}
          isError={isError}
          isErrorV2={isErrorV2}
          errorText={errorText}
        />
      </div>
      {type === "Man-Power" ? (
        <div className={Styles.labelsWrapper}>
          {labels
            ?.filter((el) => !el?.type)
            ?.map((label, id) => {
              return (
                <SmallLabel
                  label={label.name}
                  key={id}
                  removeLabel={removeLabel}
                  preventDelete={label?.[preventDeleteIndicator] ? true : false}
                />
              );
            })}
        </div>
      ) : (
        <div className={Styles.labelsWrapper}>
          {labels?.map((label, id) => {
            return (
              <SmallLabel
                label={label?.name}
                key={id}
                removeLabel={removeLabel}
                preventDelete={label?.[preventDeleteIndicator] ? true : false}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MultiSelectSmallLabel;
