import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import { useState } from "react";
import NewAddPositionModal from "@Molecule/_modal/NewAddPositionModal";

export default function Counter(props) {
  const { onAdd, onSubtract, value, setValue=()=> {} } = props;
  const [editMode, setEditMode] = useState(false)
  return (
    <div className={Styles.container}>
      <div className={Styles.subtract} onClick={onSubtract}>
        <Icon icon="minus" size={14} className={Styles.icon} />
      </div>
      <div onClick={() => setEditMode(true)} className={Styles.value}>{value || 1}</div>
      <div className={Styles.add} onClick={onAdd}>
        <Icon icon="add" size={14} className={Styles.icon} />
      </div>

      {
        editMode
        &&
        <NewAddPositionModal
        show={editMode}
        setShow={setEditMode}
        editQtyMode
        defaultQty={value}
        onAdd={(newVal) => setValue(newVal)}
        />
      }
    </div>
  );
}
