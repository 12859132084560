import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";

export default function Hamburger(props) {
  const { open, setOpen, showClose } = props;

  return (
    <div className={Styles.container} onClick={() => setOpen(!open)}>
      {open ? (
        <Icon icon={showClose ? "cross" : ""} size={20} />
      ) : (
        <Icon icon="burger-icon" size={20} />
      )}
    </div>
  );
}
