import Icon from "@Atom/Icon";
import Images from "@Theme/Images";
import React from "react";
import Styles from "./style.module.scss";

export default function ContractTypeSection({ options, contractType }) {
  const option = options?.find((el) => el?.title === contractType);
  return (
    <div className={Styles.container}>
      <div className={Styles.card}>
        <div className={Styles.iconWrapper}>
          <Icon icon="check-circle" size={25} className={Styles?.CheckIcon} />
        </div>
        <div className={Styles.cardHeader}>
          <h4>{option?.title}</h4>
          <span>{option?.label}</span>
        </div>
        <div className={Styles.cardContent}>
          {option?.rules?.map((rule, ir) => {
            return (
              <div key={ir} className={Styles.rule}>
                <img src={Images.CHECK_MARK} alt="check" />
                <p>{rule?.desc}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
