import { useRouteError } from "react-router-dom";
import Styles from "./style.module.scss";

export default function ErrorBoundary({ code, message, height = '100vh' }) {
  let error = useRouteError();

  return (
    <div className={Styles.container} style={{ height }}>
      <div className={Styles.header}>
        <h3>Something went wrong!</h3>
      </div>
      <span className={Styles.status}>
        {error?.status || error?.response?.data?.code || code}
      </span>
      <span className={Styles.message}>
        {error?.data?.message || error?.response?.data?.error || message}
      </span>
    </div>
  );
}
