import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";

const InformationField = ({ title, value, icon, ...props }) => {
  return (
    <div className={Styles.container}>
      <Icon icon={icon} size={20} className={Styles.icon} />
      <div className={Styles.textWrapper}>
        <div className={Styles.title}>{title}</div>
        <div className={Styles.value}>{value}</div>
      </div>
    </div>
  );
};

export default InformationField;
