import React from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";

export default function JobSeekersLinks() {
  return (
    <div className={Styles.container}>
      <div className={Styles.textWrapper}>
        <p>
          Mulai jelajahi dan temukan pekerjaan di aplikasi Kerja 365 Loker
        </p>
        <p>Download Aplikasi Sekarang</p>
        <p>
          Tersedia di Android dan iPhone silahkan download melalui link di bawah
          ini
        </p>
      </div>
      <div className={Styles.buttonWrapper}>
        <a
          className={Styles.button}
          href="https://play.google.com/store/apps/details?id=com.personal365"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Images.GOOGLE_PLAY_WHITE_ICON} alt="apple" />
          <div className={Styles.buttonTextWrapper}>
            <p>Get in on</p>
            <p>Google Play</p>
          </div>
        </a>
        <a
          className={Styles.button}
          href="https://apps.apple.com/id/app/kerja365-loker/id6469481475"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Images.APPLE_WHITE_ICON} alt="apple" />
          <div className={Styles.buttonTextWrapper}>
            <p>Get in on</p>
            <p>App Store</p>
          </div>
        </a>
      </div>
    </div>
  );
}
