import SearchBar from "@Atom/SearchBar";
import Styles from "./style.module.scss";
import { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import Icon from "@Atom/Icon";
import { makeRandomString } from "@Helpers/makeRandomString";

const Desktop = (props) => {
  const {
    searchTerm,
    onChange,
    isSelected,
    handleSelectedLocation,
    onCancel,
    onAdd,
    cities,
  } = props;

  return (
    <div className={Styles.background}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h4>Add New Location</h4>
          <div className={Styles.searchbarWrapper}>
            <SearchBar
              value={searchTerm}
              onChange={onChange}
              placeholder="Search Location"
            />
          </div>
        </div>
        <div className={Styles.content}>
          {cities?.filter((el) =>
            el?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          )?.length > 0 ? (
            <>
              {cities
                ?.filter((el) =>
                  el?.name?.toLowerCase().includes(searchTerm.toLowerCase())
                )
                ?.map((each, i) => {
                  const isItemSelected = Boolean(isSelected(each));
                  return (
                    <div
                      key={i}
                      className={Styles.each}
                      onClick={() => handleSelectedLocation(each)}
                    >
                      <label>{each?.name}</label>
                      <Checkbox
                        checked={isItemSelected}
                        sx={{ margin: 0, padding: 0, zIndex: 0 }}
                        icon={
                          <Icon
                            icon="radio-button-default"
                            size={20}
                            className={Styles.Unchecked}
                          />
                        }
                        checkedIcon={
                          <Icon
                            icon="radio-button-active"
                            size={20}
                            className={Styles.checked}
                          />
                        }
                      />
                    </div>
                  );
                })}
            </>
          ) : (
            <div className={Styles.noOption}>No such location</div>
          )}
        </div>
        <div className={Styles.actionsWrapper}>
          <button onClick={onCancel}>
            <span>Cancel</span>
          </button>
          <button onClick={onAdd}>
            <span>Add</span>
          </button>
        </div>
      </div>
    </div>
  );
};
const Mobile = (props) => {
  const { isSelected, handleSelectedLocation, onCancel, onAdd, cities } = props;

  return (
    <div className={Styles.mobileContainer}>
      <div className={Styles.topWrapper}>
        <div className={Styles.topLine} />
        <div className={Styles.header}>
          <h3>Add New Location</h3>
        </div>
      </div>
      <div className={Styles.content}>
        {cities?.map((each, i) => {
          const isItemSelected = Boolean(isSelected(each));
          return (
            <div
              key={i}
              className={Styles.each}
              onClick={() => handleSelectedLocation(each)}
            >
              <label>{each?.name}</label>
              <Checkbox
                checked={isItemSelected}
                sx={{ margin: 0, padding: 0, zIndex: 0 }}
                icon={
                  <Icon
                    icon="radio-button-default"
                    size={20}
                    className={Styles.Unchecked}
                  />
                }
                checkedIcon={
                  <Icon
                    icon="radio-button-active"
                    size={20}
                    className={Styles.checked}
                  />
                }
              />
            </div>
          );
        })}
      </div>
      <div className={Styles.actionsWrapper}>
        <button onClick={onCancel}>
          <span>Cancel</span>
        </button>
        <button onClick={onAdd}>
          <span>Add</span>
        </button>
      </div>
    </div>
  );
};

export default function AddLocationModal(props) {
  // eslint-disable-next-line
  const { type, setShowAdd, form, setForm, cities, trigger, setTrigger } =
    props;
  const [searchTerm, setSearchTerm] = useState("");
  const [formTemps, setFormTemps] = useState([]);
  const [locationArr, setLocationArr] = useState([]);

  useEffect(() => {
    setLocationArr(form);
    setFormTemps(form);
    sessionStorage.setItem("manPowerForm", JSON.stringify(form));
  }, [form, trigger]);

  const onChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectedLocation = (option) => {
    const data = [...locationArr];
    const isExist = data.find((item) => item?.location === option?.code);
    if (!isExist) {
      data.push({
        location: option?.code,
        jobs: [],
        areaWorkEquipments: [],
        umk: option?.umk,
      });
    }
    // else {
    //   const index = data?.findIndex(object => {
    //     return object.name === option?.name;
    //   });
    //   data.splice(index, 1)
    // }
    setLocationArr(data);
  };

  const onCancel = () => {
    setLocationArr(form);
    setFormTemps(form);
    setShowAdd(false);
  };

  const onAdd = () => {
    const filtered = locationArr?.filter((item) => !formTemps?.includes(item));
    if (filtered?.length !== 0) {
      for (let i = 0; i < filtered.length; i++) {
        form.push(filtered[i]);
      }
    } else {
      for (let i = 0; i < locationArr.length; i++) {
        form.push(locationArr[i]);
      }
    }
    setShowAdd(false);
    setTrigger(makeRandomString(5));
  };

  const isSelected = (option) =>
    locationArr.find((item) => item?.location === option?.code);

  switch (type) {
    case "mobile":
      return (
        <Mobile
          searchTerm={searchTerm}
          onChange={onChange}
          handleSelectedLocation={handleSelectedLocation}
          onCancel={onCancel}
          onAdd={onAdd}
          isSelected={isSelected}
          cities={cities}
        />
      );
    case "desktop":
      return (
        <Desktop
          searchTerm={searchTerm}
          onChange={onChange}
          handleSelectedLocation={handleSelectedLocation}
          onCancel={onCancel}
          onAdd={onAdd}
          isSelected={isSelected}
          cities={cities}
        />
      );
    default:
      return (
        <Desktop
          searchTerm={searchTerm}
          onChange={onChange}
          handleSelectedLocation={handleSelectedLocation}
          onCancel={onCancel}
          onAdd={onAdd}
          isSelected={isSelected}
          cities={cities}
        />
      );
  }
}
