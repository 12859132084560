import React, { useState } from "react";
import Icon from "@Atom/Icon";
import Styles from "./inputPassword.module.scss";

const InputPassword = ({
  value,
  id,
  onChange,
  disabled,
  placeholder,
  type,
  deleteOption,
  onDelete,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <input
          className={Styles.input}
          id={id || "fname"}
          name="fname"
          value={value || ""}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          type={!isVisible ? "password" : "text"}
          autoComplete="on"
        />
        <div
          className={Styles.eyeIcon}
          onClick={() => setIsVisible(!isVisible)}
        >
          <Icon icon={isVisible ? "eye-visible" : "eye-invisible"} size={20} />
        </div>
      </div>
    </div>
  );
};

export default InputPassword;
