import React from "react";
import Styles from "./styles.module.scss";
export default function Spinner({ type }) {
  return (
    <div className={Styles.overlay}>
      <div
        className={`${Styles.container} ${
          type === "contract" && Styles.containerContract
        }`}
      ></div>
    </div>
  );
}
