import SearchBar from "@Atom/SearchBar";
import Styles from "./style.module.scss";
import { useState } from "react";
import Dropdown from "@Atom/DropDown";
import Icon from "@Atom/Icon";

const departments = [
  {
    name: "Business",
  },
  {
    name: "IT",
  },
  {
    name: "Human Resources",
  },
];

export default function EmployeeListController({ searchTerm, setSearchTerm }) {
  const [department, setDepartment] = useState(null);

  return (
    <div className={Styles.container}>
      <div className={Styles.leftController}>
        <div className={Styles.searchBarWrapper}>
          <SearchBar
            placeholder={"Search employee name"}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e?.target?.value)}
          />
        </div>
        <div style={{ display: "none" }} className={Styles.dropdownWrapper}>
          <Dropdown
            options={departments}
            placeholder={"Department"}
            value={department}
            setValue={setDepartment}
            autoClose
            fixedHeight={"40px"}
          />
        </div>
      </div>
      <div style={{ display: "none" }} className={Styles.rightController}>
        <div className={Styles.selected}>
          <span>Selected</span>
          <button>
            <Icon icon="three-dots-grey" size={20} />
          </button>
        </div>
        <div className={Styles.sort}>
          <span>Sort by</span>
        </div>
      </div>
    </div>
  );
}
