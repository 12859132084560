import Images from '@Theme/Images'
import Styles from './style.module.scss'

export default function StackedProfile({
  profilePicture,
  title,
  label,
  extraTitle,
  onClick = () => { },
  isButton = false,
  imgSize = '44.27px',
  titleClassName = ''

}) {

  const handleImageError = (event) => {
    event.target.src = Images.AVA_DEFAULT; // Replace with default image
  };

  return (
    <div className={`${Styles.container} ${isButton ? Styles.button : ''}`} onClick={onClick} >
      <img style={{ width: imgSize }} src={profilePicture || Images?.AVA_DEFAULT} alt={title} onError={handleImageError}/>
      <div className={Styles.desc}>
        <div className={`${Styles.title} ${titleClassName}`}>
          <span>{title}</span>
          {extraTitle}
        </div>
        <div className={Styles.label}>{label}</div>
      </div>
    </div>
  )
}