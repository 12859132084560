import DemandCard from "./DemandCard";
import Styles from "./style.module.scss";

export default function PerArea({ data, qualificationList }) {
  let IDR = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  return (
    <div className={Styles.container}>
      <h4>{data?.cityName}</h4>
      <div className={Styles.demandContainer}>
        {data?.manPowers?.map((each, i) => (
          <DemandCard
            key={i}
            index={i}
            data={each}
            general={data}
            qualificationList={qualificationList}
          />
        ))}
      </div>

      <div className={Styles.equipmentsContainer}>
        <h5>Work Equipment</h5>
        <div className={Styles.content}>
          {data?.areaEquipments?.length > 0 &&
            data?.areaEquipments?.map((eachE, iE) => (
              <div key={iE} className={Styles.stacked}>
                <span>{eachE?.name}</span>
                <span>{IDR.format(eachE?.price || 0)}</span>
              </div>
            ))}
          {data?.areaEquipments?.length === 0 && (
            <div className={Styles.stacked}>
              <span>No equipment</span>
              <span></span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
