import { useMemo } from "react";
import Styles from "./style.module.scss";

export default function TaxAndBPJSSection({ data }) {
  const orderComprehensive = useMemo(() => {
    return data?.order?.orderComprehensives;
  }, [data]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Tax & BPJS</h3>
      </div>
      <div className={Styles.content}>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Taxs</h4>
          </div>
          <div className={`${Styles.col} ${Styles.miniPadding}`}>
            <div className={Styles.stacked}>
              <span>PPH21</span>
              <span>{orderComprehensive?.PPH21}</span>
            </div>
            <div className={Styles.stacked}>
              <span>PPH23</span>
              <span>{orderComprehensive?.PPH23 || '-'}</span>
            </div>
            <div className={Styles.stacked}>
              <span>PPN</span>
              <span>{orderComprehensive?.PPN}</span>
            </div>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>BPJS</h4>
          </div>
          <div className={`${Styles.col} ${Styles.miniPadding}`}>
            <div className={Styles.colSection}>
              <div className={Styles.col}>
                <div className={Styles.stacked}>
                  <span>Pendaftaran BPJS</span>
                  <span>{orderComprehensive?.PendaftaranBPJS}</span>
                </div>
                <div className={Styles.stacked}>
                  <span>Bukti pembayaran BPJS</span>
                  <span>{orderComprehensive?.BuktiPendaftaranBPJS}</span>
                </div>
              </div>
              <h5 className={Styles.extraPadding}>Ketenagakerjaan</h5>
              <div className={Styles.col}>
                <div className={Styles.stacked}>
                  <span>JKK (Jaminan Kelengkapan Kerja)</span>
                  <span>
                    {orderComprehensive?.JKK &&
                    orderComprehensive?.JKK !== "null"
                      ? orderComprehensive?.JKK
                      : "-"}
                  </span>
                </div>
                <div className={Styles.stacked}>
                  <span>JHT (Jaminan Hari Tua)</span>
                  <span>
                    {orderComprehensive?.JHT &&
                    orderComprehensive?.JHT !== "null"
                      ? orderComprehensive?.JHT
                      : "-"}
                  </span>
                </div>
              </div>
              <div className={Styles.col}>
                <div className={Styles.stacked}>
                  <span>JKM (Jaminan Kematian)</span>
                  <span>
                    {orderComprehensive?.JKM &&
                    orderComprehensive?.JKM !== "null"
                      ? orderComprehensive?.JKM
                      : "-"}
                  </span>
                </div>
                <div className={Styles.stacked}>
                  <span>JP (Jaminan Pensiun)</span>
                  <span>
                    {orderComprehensive?.JP && orderComprehensive?.JP !== "null"
                      ? orderComprehensive?.JP
                      : "-"}
                  </span>
                </div>
              </div>

              <h5 className={Styles.extraPadding}>Kesehatan</h5>
              <div className={Styles.stacked}>
                <span>KS (Kesehatan)</span>
                <span>
                  {orderComprehensive?.KS && orderComprehensive?.KS !== "null"
                    ? orderComprehensive?.KS
                    : "-"}
                </span>
              </div>
              <h5 className={Styles.extraPadding}>AKDHK</h5>
              <div className={Styles.stacked}>
                <span>Asuransi Kecelakaan Diluar Hari Kerja</span>
                <span>
                  {orderComprehensive?.AKDHK &&
                  orderComprehensive?.AKDHK !== "null"
                    ? orderComprehensive?.AKDHK
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Pengenaan Manfee</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>
              {orderComprehensive?.PengenaanManfee?.replace(/::/g, ", ")}
            </span>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Penagihan THR</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>{orderComprehensive?.PenagihanTHR}</span>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Penagihan UAK</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>{orderComprehensive?.PenagihanUAK}</span>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Rekening Wajib TKO</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>
              {orderComprehensive?.RekeningWajibTKO?.replace(/::/g, ", ")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
