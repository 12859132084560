import AutocompleteV2 from "@Atom/AutocompleteV2";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import Counter from "@Atom/Counter";
import DecorativeInput from "@Atom/DecorativeInput";
import Dropdown from "@Atom/DropDown";
import Icon from "@Atom/Icon";
import Switch from "@Atom/Switch";
import Textarea from "@Atom/Textarea";
import { makeRandomString } from "@Helpers/makeRandomString";
import UseOutsideClick from "@Hooks/useOutsideClick";
import DinamicDecorativeInputField from "@Molecule/DinamicDecorativeInputField";
import { InputTitle } from "@Molecule/InputField";
import MultiSelectSmallLabel from "@Molecule/MultiSelectSmallLabel";
import RightDecorativeInputField from "@Molecule/RightDecorativeInputField";
import NewAddPositionModal from "@Molecule/_modal/NewAddPositionModal";
import Images from "@Theme/Images";
import { Avatar } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AddLocationModalV2 } from "../Fourth/LocationSection/AddLocationModal";
import Styles from "./style.module.scss";
import { AddQualificationModalV2 } from "../Fourth/LocationSection/WithLocation/OnManPowerProgress/ThirdSubStep/AddQualificationModal";
import InputDateField from "@Molecule/InputDateField";
import ErrorBubble from "@Atom/ErrorBubble";

export default function FourthRevamp({
  onPrevious,
  onNext,
  delegationsForm,
  setDelegationsForm,
  positionsForm,
  setPositionsForm,
  remark,
  setRemark,
  defaultDeployments = [],
  cities = [],
  contractTypeOptions = [],
  personalEquipmentOptions = [],
  allowanceOptions = [],
  qualificationOptions = [],
  areaEquipmentOptions = [],
  selectedValueBPU,
  scrollingTop = () => {},
  selectedButtonBPJS = "",
  alertMessage = "",
}) {
  const [activeStep, setActiveStep] = useState(
    sessionStorage?.getItem("activeFourthStep") || "1"
  );
  const [showAdd, setShowAdd] = useState(false);
  const [showAddPosition, setShowAddPosition] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("activeFourthStep", activeStep);
  }, [activeStep]);

  useEffect(() => {
    scrollingTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  // const [delegationsForm, setDelegationsForm] = useState([
  //   {
  //     id: 1,
  //     cityCode: 11,
  //     cityName: 'Jakarta',
  //     dedicatedROName: 'Susi',
  //     dedicatedROId: 2323,
  //     deployment: defaultDeploymentsData

  //   }
  // ])

  const mainContent = useMemo(() => {
    switch (activeStep) {
      case "1":
        return (
          <div
            className={`${Styles.delegationsAllCities} ${
              alertMessage && !delegationsForm?.length ? Styles.error : ""
            }`}
          >
            {delegationsForm?.map((d, dI) => (
              <DelegationPerLocation
                key={dI}
                isErrorBM={alertMessage}
                delegationData={d}
                setDelegationData={(newData) => {
                  const newDelegationsForm = delegationsForm?.map((obj) => {
                    if (obj?.cityCode === d?.cityCode) {
                      return {
                        ...newData,
                      };
                    }
                    return obj;
                  });
                  setDelegationsForm(newDelegationsForm);
                }}
                onDelete={() =>
                  setDelegationsForm(
                    delegationsForm?.filter(
                      (obj) => obj?.cityCode !== d?.cityCode
                    )
                  )
                }
              />
            ))}
            <button
              className={Styles.addLocationButton}
              onClick={() => setShowAdd(true)}
            >
              <Icon icon="add" size={20} />
              <span>Location</span>
            </button>
          </div>
        );
      case "2":
        return positionsForm?.length ? (
          <div className={Styles.positionsAllCities}>
            {positionsForm?.map((p, pI) => (
              <PerPosition
                key={pI}
                alertMessage={alertMessage}
                selectedButtonBPJS={selectedButtonBPJS}
                positionsForm={positionsForm}
                onDelete={() =>
                  setPositionsForm(
                    positionsForm?.filter((obj) => obj?.id !== p?.id)
                  )
                }
                positionData={p}
                setPositionData={(name, value) => {
                  setPositionsForm(
                    positionsForm?.map((obj) => {
                      if (p?.id === obj?.id) {
                        return {
                          ...obj,
                          [name]: value,
                        };
                      }
                      return obj;
                    })
                  );
                }}
                contractTypeOptions={contractTypeOptions}
                personalEquipmentOptions={personalEquipmentOptions}
                allowanceOptions={allowanceOptions}
                qualificationOptions={qualificationOptions}
                selectedValueBPU={selectedValueBPU}
                roList={delegationsForm
                  ?.map((obj) => obj?.deployments[3]?.delegations)
                  ?.flat()
                  ?.filter((u) => u?.isPicked)
                  ?.filter(
                    (value, index, self) =>
                      index === self?.findIndex((t) => t?.id === value?.id)
                  )}
              />
            ))}
            <div className={Styles.more}>
              <p>
                Tambah posisi <span>Lainnya</span>
              </p>
              <button onClick={() => setShowAddPosition(true)}>
                <Icon icon={"add-circle"} size={20} />
                <span>Tambah</span>
              </button>
            </div>
          </div>
        ) : (
          <div
            className={`${Styles.noPosition} ${
              alertMessage?.includes("list position") ? Styles.error : ""
            }`}
          >
            <img src={Images.TIME_MANAGEMENT} alt="default" />
            <div className={Styles.desc}>
              <h3>Belum Ada List Position yang Ditambahkan</h3>
              <p>Silahkan untuk menambah manpower sesuai kebutuhanmu</p>
            </div>

            <button
              className={Styles.addManPowerButton}
              onClick={() => setShowAddPosition(true)}
            >
              <Icon icon="circle-plus" size={20} />
              <span>Position</span>
            </button>
          </div>
        );
      default:
        return <></>;
    }
  }, [
    activeStep,
    alertMessage,
    allowanceOptions,
    contractTypeOptions,
    delegationsForm,
    personalEquipmentOptions,
    positionsForm,
    qualificationOptions,
    selectedButtonBPJS,
    selectedValueBPU,
    setDelegationsForm,
    setPositionsForm,
  ]);

  return (
    <div className={Styles.container}>
      <div className={`${Styles.main} ${Styles.bg}`}>
        <div className={`${Styles.steps} ${Styles.bg}`}>
          {[
            {
              value: "1",
              label: "Deployment",
            },
            {
              value: "2",
              label: "List Position",
            },
          ]?.map((each, i) => (
            <div
              key={i}
              className={Styles.step}
              onClick={() => setActiveStep(each?.value)}
            >
              {i === 0 && <div className={Styles.border} />}
              <div>
                <span
                  className={activeStep === each?.value ? Styles.active : ""}
                >
                  {each?.value}
                </span>
              </div>

              <span className={activeStep === each?.value ? Styles.active : ""}>
                {each?.label}
              </span>
            </div>
          ))}
        </div>
        <div className={Styles.content}>
          {!delegationsForm?.length ? (
            <NoLocation alertMessage={alertMessage} setShowAdd={setShowAdd} />
          ) : (
            mainContent
          )}
          <div className={Styles.cActions}>
            <button
              disabled={activeStep === "1"}
              onClick={() => setActiveStep("1")}
            >
              <Icon icon={"arrow-left"} size={20} />
              <span>Back</span>
            </button>
            <button
              disabled={activeStep === "2"}
              onClick={() => setActiveStep("2")}
            >
              <span>Next Step</span>
              <Icon icon={"arrow-right"} size={20} />
            </button>
          </div>
        </div>
      </div>
      <div className={Styles.remark}>
        <span>Remark</span>
        <Textarea
          placeholder={"Remark"}
          value={remark}
          onChange={(e) => setRemark(e?.target?.value)}
        />
      </div>
      <div className={Styles.buttonWrapper}>
        <button onClick={onPrevious}>Back</button>
        <button onClick={onNext}>Next Step</button>
      </div>
      {showAdd && (
        <AddLocationModalV2
          // trigger={trigger}
          // setTrigger={setTrigger}
          show={showAdd}
          setShow={setShowAdd}
          cities={cities?.filter(
            (obj) =>
              !delegationsForm?.map((f) => f?.cityCode)?.includes(obj?.code)
          )}
          setValues={(newValues) => {
            const newArr = [...delegationsForm];
            for (let i = 0; i < newValues?.length; i++) {
              newArr.push({
                id: makeRandomString(5),
                cityCode: newValues[i].code,
                cityName: newValues[i].name,
                umk: newValues[i].umk || 4500000,
                deployments: defaultDeployments,
              });
            }
            setDelegationsForm(newArr);
          }}
        />
      )}
      {showAddPosition && (
        <NewAddPositionModal
          show={showAddPosition}
          setShow={setShowAddPosition}
          onAdd={(positionName) => {
            setPositionsForm([
              ...positionsForm,
              {
                id: makeRandomString(5),
                isExpanded: false,
                positionName,
                dedicatedROName: "",
                dedicatedROId: "",
                qualifications: [],
                citiesData: delegationsForm?.map((d) => {
                  return {
                    id: d?.id,
                    isApplied: false,
                    cityCode: d?.cityCode,
                    cityName: d?.cityName,
                    umk: d?.umk,
                    amount: 1,
                    salaryValue: d?.umk || 4500000,
                    salaryDenom: "",
                    personalEquipments: [],
                    allowances: [],
                    jkk: "0",
                    jkm: "0",
                    jht: "0",
                    expectedDate: "",
                  };
                }),
              },
            ]);
          }}
          existingPositionNames={positionsForm?.map((obj) => obj?.positionName)}
        />
      )}
    </div>
  );
}

const NoLocation = ({ setShowAdd, alertMessage }) => {
  return (
    <div
      className={`${Styles.noLocationContainer} ${
        alertMessage ? Styles.error : ""
      }`}
    >
      {alertMessage && (
        <div className={Styles.bubbleWrapper}>
          <ErrorBubble
            errorText="Harap tambahkan lokasi terlebih dahulu"
            noTip
            marginTop="0"
          />
        </div>
      )}
      <img src={Images.PANA} alt="" />
      <span>Belum Ada Data Ditambahkan</span>
      <p>
        Untuk melakukan deployment silahkan untuk menambahkan lokasi terlebih
        dahulu
      </p>
      <button onClick={() => setShowAdd(true)}>
        <Icon icon={"circle-plus"} size={20} />
        <span>Location</span>
      </button>
    </div>
  );
};

const DelegationPerLocation = ({
  delegationData,
  setDelegationData = () => {},
  onDelete = () => {},
  roList = [],
  isErrorBM = false,
}) => {
  const [bmSearch, setBmSearch] = useState("");
  const [amSearch, setAmSearch] = useState("");
  const [aoSearch, setAoSearch] = useState("");
  const [roSearch, setRoSearch] = useState("");

  const [showBm, setShowBm] = useState(false);
  const [showAm, setShowAm] = useState(false);
  const [showAo, setShowAo] = useState(false);
  const [showRo, setShowRo] = useState(false);

  const bmRef = useRef();
  const amRef = useRef();
  const aoRef = useRef();
  const roRef = useRef();

  const bmOutsideClick = UseOutsideClick(bmRef);
  const amOutsideClick = UseOutsideClick(amRef);
  const aoOutsideClick = UseOutsideClick(aoRef);
  const roOutsideClick = UseOutsideClick(roRef);

  useEffect(() => {
    if (bmOutsideClick) {
      setBmSearch("");
      setShowBm(false);
    }
  }, [bmOutsideClick]);
  useEffect(() => {
    if (amOutsideClick) {
      setAmSearch("");
      setShowAm(false);
    }
  }, [amOutsideClick]);
  useEffect(() => {
    if (aoOutsideClick) {
      setAoSearch("");
      setShowAo(false);
    }
  }, [aoOutsideClick]);
  useEffect(() => {
    if (roOutsideClick) {
      setRoSearch("");
      setShowRo(false);
    }
  }, [roOutsideClick]);

  const searchTemplate = useMemo(() => {
    return [
      [bmSearch, setBmSearch, bmRef, showBm, setShowBm],
      [amSearch, setAmSearch, amRef, showAm, setShowAm],
      [aoSearch, setAoSearch, aoRef, showAo, setShowAo],
      [roSearch, setRoSearch, roRef, showRo, setShowRo],
    ];
  }, [amSearch, aoSearch, bmSearch, roSearch, showAm, showAo, showBm, showRo]);

  const onSelect = (user, role) => {
    const newData = {
      ...delegationData,
      deployments: delegationData?.deployments?.map((obj) => {
        if (obj?.role === role) {
          return {
            ...obj,
            delegations: obj?.delegations?.map((u) => {
              if (u?.id === user?.id) {
                return {
                  ...u,
                  isPicked: role === "BUSINESS MANAGER" ? true : !u?.isPicked,
                };
              } else {
                return {
                  ...u,
                  isPicked: role === "BUSINESS MANAGER" ? false : u?.isPicked,
                };
              }
            }),
          };
        } else {
          return obj;
        }
      }),
    };

    if (role === "RECRUITMENT OFFICER") {
      if (!user?.delegationID) {
        setDelegationData(newData);
      }
    } else {
      setDelegationData(newData);
    }

    if (role === "BUSINESS MANAGER") {
      setShowBm(false);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const onSetPIC = (user, role) => {
    const newData = {
      ...delegationData,
      deployments: delegationData?.deployments?.map((obj) => {
        if (obj?.role === role) {
          return {
            ...obj,
            delegations: obj?.delegations?.map((u) => {
              if (u?.id === user?.id) {
                return {
                  ...u,
                  isPIC: !u?.isPIC,
                };
              } else {
                return u;
              }
            }),
          };
        } else {
          return obj;
        }
      }),
    };

    setDelegationData(newData);
    if (role === "BUSINESS MANAGER") {
      setShowBm(false);
    }
  };

  return (
    <div className={Styles.delegationPerCity}>
      <div className={Styles.dPCHeader}>
        <Icon icon={"location"} size={24} color={"#1571DE"} />
        <span>{delegationData?.cityName}</span>
        {!delegationData?.preventDelete && (
          <button onClick={onDelete}>
            <Icon icon={"delete-outline"} size={20} />
            <span>Hapus</span>
          </button>
        )}
      </div>

      <div className={`${Styles.roles} ${Styles.grid}`}>
        {delegationData?.deployments?.map((d, dI) => (
          <div
            key={dI}
            className={`${Styles.role} 
          ${
            isErrorBM && dI === 0 && !d?.delegations?.find((u) => u?.isPicked)
              ? Styles.error
              : ""
          }
          `}
          >
            <p>
              {d?.role}
              {d?.required && <span className={Styles.redStar}>*</span>}
            </p>
            <div ref={searchTemplate[dI][2]} className={Styles.rSearchWrapper}>
              <input
                value={searchTemplate[dI][0]}
                onChange={(e) => searchTemplate[dI][1](e?.target?.value)}
                placeholder="Search"
                onFocus={() => searchTemplate[dI][4](true)}
                className={` ${
                  isErrorBM &&
                  dI === 0 &&
                  !d?.delegations?.find((u) => u?.isPicked)
                    ? Styles.error
                    : ""
                }`}
                // onBlur={() => searchTemplate[dI][4](false)}
              />
              <Icon
                icon={"search"}
                size={"24px"}
                className={Styles.rSearchIcon}
              />
              {searchTemplate[dI][3] && (
                <div className={Styles.rSearchOptions}>
                  {d?.delegations
                    ?.filter((obj) =>
                      obj?.name
                        ?.toLowerCase()
                        ?.includes(searchTemplate[dI][0]?.toLowerCase())
                    )
                    ?.sort((a, b) =>
                      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                    )
                    ?.map((u, uI) => (
                      <div key={uI} className={Styles.user}>
                        {d?.role !== "BUSINESS MANAGER" && (
                          <CheckboxJobPosting
                            checked={u?.isPicked}
                            onChange={() => onSelect(u, d?.role)}
                          />
                        )}

                        <span onClick={() => onSelect(u, d?.role)}>
                          {u?.name}
                        </span>
                      </div>
                    ))}
                </div>
              )}
            </div>
            {isErrorBM &&
              dI === 0 &&
              !d?.delegations?.find((u) => u?.isPicked) && (
                <div>
                  <ErrorBubble errorText="Business manager belum dipilih" />
                </div>
              )}
            {!!d?.delegations?.filter((obj) => obj?.isPicked)?.length && (
              <div className={Styles.delegatedWrapper}>
                {d?.delegations
                  ?.filter((obj) => obj?.isPicked)
                  ?.map((u, uI) => (
                    <div key={uI} className={Styles.delegated}>
                      <Avatar sx={{ width: "32px", height: "32px" }} />
                      <span>{u?.name}</span>
                      <div className={Styles.dAction}>
                        {/* {!d?.role
                          ?.toLowerCase()
                          ?.includes("business") && (
                            <div className={Styles.picSetter}>
                              <span>Set as PIC : </span>
                              <Switch
                                isChecked={u?.isPIC}
                                setIsChecked={() => onSetPIC(u, d?.role)}
                                width="36px"
                                height="20px"
                              />
                            </div>
                          )} */}
                        {d?.role !== "BUSINESS MANAGER" &&
                          (d?.role === "RECRUITMENT OFFICER" ? (
                            !u?.delegationID ? (
                              <button onClick={() => onSelect(u, d?.role)}>
                                <Icon icon={"cross-circle"} size={18} />
                              </button>
                            ) : (
                              <></>
                            )
                          ) : (
                            <button onClick={() => onSelect(u, d?.role)}>
                              <Icon icon={"cross-circle"} size={18} />
                            </button>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div></div>
          </div>
        ))}
      </div>
    </div>
  );
};

const PerPosition = ({
  positionData,
  setPositionData,
  contractTypeOptions = [],
  personalEquipmentOptions = [],
  allowanceOptions = [],
  qualificationOptions = [],
  selectedValueBPU = null,
  roList = [],
  positionsForm = [],
  onDelete = () => {},
  selectedButtonBPJS = "",
  alertMessage = "",
}) => {
  const [editNameMode, setEditNameMode] = useState(false);
  const [expandDetails, setExpandDetails] = useState(
    positionData?.isExpanded || false
  );
  const [showAddQualification, setShowAddQualification] = useState(false);

  useEffect(() => {
    setPositionData("isExpanded", expandDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandDetails]);

  const handleDeleteQualification = useCallback(
    (name) => {
      setPositionData(
        "qualifications",
        positionData?.qualifications?.filter((obj) => obj?.name !== name)
      );
    },
    [positionData?.qualifications, setPositionData]
  );

  const handleChangeQualification = useCallback(
    (name, value) => {
      setPositionData(
        "qualifications",
        positionData?.qualifications?.map((obj) => {
          if (obj?.name === name) {
            return {
              ...obj,
              value,
            };
          }
          return obj;
        })
      );
    },
    [positionData?.qualifications, setPositionData]
  );

  const [roId, setRoId] = useState(positionData?.dedicatedROId || "");

  useEffect(() => {
    setPositionData("dedicatedROId", roId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roId]);

  const sortedQualifications = useMemo(() => {
    const lainnya = positionData?.qualifications?.find(
      (obj) => obj?.name === "Lainnya"
    );
    return lainnya
      ? [
          ...positionData?.qualifications
            ?.filter((obj) => obj?.name !== "Lainnya")
            ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
          lainnya,
        ]
      : positionData?.qualifications?.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
  }, [positionData?.qualifications]);

  const template = useMemo(() => {
    return [
      {
        title: "Recruitment Officer Dedicated",
        component: (
          <div
            className={`${Styles.roWrapper}
             ${
               (alertMessage?.includes(positionData?.positionName) &&
                 alertMessage?.includes("Dedicated")) ||
               !roList?.length
                 ? Styles.error
                 : ""
             }`}
          >
            <AutocompleteV2
              value={positionData?.dedicatedROName}
              setValue={(newVal) => {
                setPositionData("dedicatedROName", newVal);
              }}
              placeholder="Select"
              onClickOption={(option) => {
                if (positionData?.dedicatedROId !== option?.id) {
                  setPositionData("dedicatedROName", option?.name);
                  // setPositionData('dedicatedROId', option?.id)
                  setRoId(option?.id);
                }
              }}
              options={roList}
              isError={
                (alertMessage?.includes(positionData?.positionName) &&
                  alertMessage?.includes("Dedicated")) ||
                !roList?.length
              }
              forceValue
            />
            {alertMessage?.includes(positionData?.positionName) &&
              alertMessage?.includes("Dedicated") && (
                <ErrorBubble errorText="Recruitment Officer Dedicated belum dipilih" />
              )}
            {!roList?.length && (
              <ErrorBubble errorText="Harap mendelegasikan recruitment officer untuk setiap lokasi pada step Deployment" />
            )}
            <div className={Styles.notes}>
              <Icon icon={"information-solid"} size={20} color={"#1571DE"} />
              <p className={Styles.nDesc}>
                RO <span className={Styles.italic}>dedicated</span> yang dipilih
                adalah daftar RO yang telah di masukan di tahap{" "}
                <span className={`${Styles.italic} ${Styles.bold}`}>
                  deployment
                </span>
                , mohoh periksa kembali pada proses{" "}
                <span className={Styles.italic}>deployment</span> jika RO yang
                dipilih tidak muncul
              </p>
            </div>
          </div>
        ),
      },
      {
        title: "Job Qualification",
        extraTitle: positionData?.qualifications?.length ? (
          <button
            onClick={() => setShowAddQualification(true)}
            className={Styles.addQualificationButton}
          >
            <Icon icon={"add-circle"} size={20} color={"#1571DE"} />
            <span>Qualification</span>
          </button>
        ) : (
          <></>
        ),
        component: (
          <div className={Styles.qualifications}>
            <div className={`${Styles.qWrapper} ${Styles.grid}`}>
              {sortedQualifications?.map((q, qI) => (
                <RightDecorativeInputField
                  key={qI}
                  deleteOption={true}
                  onDelete={() => handleDeleteQualification(q?.name)}
                  title={q?.name}
                  placeholder="Enter"
                  value={q?.value}
                  setValue={(newVal) => {
                    if (newVal !== q?.value) {
                      handleChangeQualification(q?.name, newVal);
                    }
                  }}
                  adornment={q?.denom}
                  type={
                    q?.name === "Lainnya" || q?.name === "Pendidikan Terakhir"
                      ? "string"
                      : "number"
                  }
                />
              ))}
            </div>
            {!positionData?.qualifications?.length && (
              <button
                onClick={() => setShowAddQualification(true)}
                className={`${Styles.addQualificationButton} ${
                  alertMessage?.includes("kualifikasi") &&
                  alertMessage?.includes(positionData?.positionName)
                    ? Styles.error
                    : ""
                }`}
              >
                <Icon icon={"add-circle"} size={20} color={"#1571DE"} />
                <span>Qualification</span>
              </button>
            )}

            {alertMessage?.includes("kualifikasi") &&
              alertMessage?.includes(positionData?.positionName) && (
                <div>
                  <ErrorBubble errorText="Kualifikasi belum ditambahkan" />
                </div>
              )}
          </div>
        ),
      },
      {
        title: "Kota",
        component: (
          <div className={Styles.cities}>
            {positionData?.citiesData?.map((c, cI) => (
              <PerPositionPerCity
                key={cI}
                alertMessage={alertMessage}
                selectedButtonBPJS={selectedButtonBPJS}
                cityPositionData={c}
                setCityPositionData={(name, value) => {
                  setPositionData(
                    "citiesData",
                    positionData?.citiesData?.map((obj) => {
                      if (obj?.cityCode === c?.cityCode) {
                        return {
                          ...obj,
                          [name]: value,
                        };
                      }
                      return obj;
                    })
                  );
                }}
                contractTypeOptions={contractTypeOptions}
                personalEquipmentOptions={personalEquipmentOptions}
                allowanceOptions={allowanceOptions}
                qualificationOptions={qualificationOptions}
                selectedValueBPU={selectedValueBPU}
              />
            ))}
          </div>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alertMessage,
    allowanceOptions,
    contractTypeOptions,
    handleChangeQualification,
    handleDeleteQualification,
    personalEquipmentOptions,
    positionData,
    qualificationOptions,
    roList,
    selectedButtonBPJS,
    selectedValueBPU,
    setPositionData,
    sortedQualifications,
  ]);

  return (
    <div className={Styles.perPosition}>
      <div className={Styles.pHeader}>
        <div className={Styles.pHLogo}>
          <Icon icon={"briefcase-outlined"} color={"#1571DE"} size={20} />
        </div>
        <div className={Styles.pHName}>
          <span>{positionData?.positionName}</span>
          {!positionData?.preventDelete && (
            <button onClick={() => setEditNameMode(true)}>
              <Icon icon={"edit"} size={20} color={"#3B62EF"} />
            </button>
          )}
        </div>
        {!positionData?.preventDelete && (
          <button onClick={onDelete}>
            <Icon icon={"trash"} size={20} color={"#FF3E13"} />
          </button>
        )}

        <button
          onClick={() => setExpandDetails(!expandDetails)}
          className={Styles.expandButton}
        >
          <Icon icon={expandDetails ? "arrow-up" : "arrow-down"} size={24} />
        </button>
      </div>
      {expandDetails && (
        <div className={Styles.pDetails}>
          {template?.map((t, tI) => (
            <div key={tI} className={Styles.pDEach}>
              <div className={Styles.pDEHeader}>
                <span>{t?.title}</span>
                {t?.extraTitle ? t?.extraTitle : <></>}
              </div>
              {t?.component}
            </div>
          ))}
        </div>
      )}
      {editNameMode && (
        <NewAddPositionModal
          show={editNameMode}
          editMode={true}
          defaultPositionName={positionData?.positionName}
          setShow={setEditNameMode}
          onAdd={(positionName) => {
            setPositionData("positionName", positionName?.toUpperCase());
          }}
          existingPositionNames={positionsForm?.map((obj) => obj?.positionName)}
        />
      )}
      {showAddQualification && (
        <AddQualificationModalV2
          show={showAddQualification}
          setShow={setShowAddQualification}
          options={qualificationOptions?.filter((obj) =>
            positionData?.qualifications?.every((f) => f?.name !== obj?.name)
          )}
          setValues={(newVal) =>
            setPositionData("qualifications", [
              ...positionData?.qualifications,
              ...newVal,
            ])
          }
        />
      )}
    </div>
  );
};

const PerPositionPerCity = ({
  cityPositionData,
  setCityPositionData = () => {},
  contractTypeOptions = [],
  personalEquipmentOptions = [],
  allowanceOptions = [],
  qualificationOptions = [],
  selectedValueBPU = null,
  selectedButtonBPJS = "",
  alertMessage = "",
}) => {
  const template = useMemo(() => {
    return [
      {
        title: "Salary & Allowance Setiap Manpower",
        component: (
          <div className={`${Styles.salarySection} ${Styles.grid}`}>
            {/* <DropdownField
              title={"Contract Type"}
              value={cityPositionData?.contractType}
              // setValue={(newVal) =>
              //   handleChangeManpowerData("contractType", newVal)
              // }
              placeholder="Select contract type"
              options={contractTypeOptions?.map((obj) => {
                return {
                  name: obj,
                };
              })}
              autoClose
            /> */}
            <div className={Styles.salary}>
              <InputTitle title={"Salary"} />
              <div className={Styles.salaryFields}>
                <DecorativeInput
                  value={cityPositionData?.salaryValue}
                  setValue={(newVal) =>
                    setCityPositionData("salaryValue", newVal)
                  }
                  adornment="Rp"
                  type="currency"
                  placeholder="Salary"
                />
              </div>
            </div>
            <div className={Styles.salary}>
              {/* <InputTitle title={"Salary"} /> */}
              <span style={{ paddingBottom: "18px" }} />
              <Dropdown
                options={["Harian", "Mingguan", "Bulanan"]?.map((obj) => {
                  return {
                    name: obj,
                  };
                })}
                placeholder={"Harian/mingguan/bulanan"}
                value={cityPositionData?.salaryDenom}
                setValue={(newVal) =>
                  setCityPositionData("salaryDenom", newVal)
                }
                autoClose
              />
            </div>

            <div className={Styles.equipments}>
              <MultiSelectSmallLabel
                title="Personal Work Equipment"
                placeholder="Select"
                options={personalEquipmentOptions?.map((obj) => {
                  return {
                    // id: makeRandomString(5),
                    name: obj,
                    value: "",
                  };
                })}
                labels={cityPositionData?.personalEquipments}
                setLabels={(newLabels) =>
                  setCityPositionData("personalEquipments", newLabels)
                }
              />
              {!!cityPositionData?.personalEquipments?.length && (
                <div className={Styles.multifieldsWrapper}>
                  {cityPositionData?.personalEquipments?.map((p, pI) => (
                    <DinamicDecorativeInputField
                      key={pI}
                      onChange={(_, newVal) =>
                        setCityPositionData(
                          "personalEquipments",
                          cityPositionData?.personalEquipments?.map((obj) => {
                            if (p?.name === obj?.name) {
                              return {
                                ...obj,
                                value: newVal,
                              };
                            }
                            return obj;
                          })
                        )
                      }
                      onDelete={() =>
                        setCityPositionData(
                          "personalEquipments",
                          cityPositionData?.personalEquipments?.filter(
                            (obj) => obj?.name !== p?.name
                          )
                        )
                      }
                      isError={
                        alertMessage?.includes(`${p?.name}`) && !p?.value
                      }
                      name={p?.name}
                      value={p?.value || ""}
                      title={p?.name}
                      adornment="Rp"
                      type="currency"
                    />
                  ))}
                </div>
              )}
            </div>
            <div className={Styles.allowances}>
              <MultiSelectSmallLabel
                title="Allowance"
                placeholder="Select"
                options={
                  allowanceOptions?.length
                    ? allowanceOptions?.map((obj) => {
                        return {
                          // id: makeRandomString(5),
                          name: obj,
                          value: "",
                          denom: "Bulanan",
                        };
                      })
                    : []
                }
                preventDeleteIndicator={"disableDelete"}
                labels={cityPositionData?.allowances?.filter(
                  (obj) => obj?.type !== "BPU"
                )}
                setLabels={(newLabels) =>
                  setCityPositionData("allowances", newLabels)
                }
              />
              {!!cityPositionData?.allowances?.length && (
                <div className={Styles.multifieldsWrapper}>
                  {cityPositionData?.allowances?.map((p, pI) => (
                    <div className={Styles.multifields}>
                      <div className={Styles.inputForm}>
                        <DinamicDecorativeInputField
                          key={pI}
                          onChange={(_, newVal) =>
                            setCityPositionData(
                              "allowances",
                              cityPositionData?.allowances?.map((obj) => {
                                if (p?.name === obj?.name) {
                                  return {
                                    ...obj,
                                    value: newVal,
                                  };
                                }
                                return obj;
                              })
                            )
                          }
                          onDelete={() =>
                            setCityPositionData(
                              "allowances",
                              cityPositionData?.allowances?.filter(
                                (obj) => obj?.name !== p?.name
                              )
                            )
                          }
                          isError={
                            alertMessage?.includes(`${p?.name}`) && !p?.value
                          }
                          name={p?.name}
                          value={p?.value || ""}
                          title={p?.name}
                          adornment="Rp"
                          type="currency"
                          preventDelete={true}
                          // preventDelete={p?.disableDelete}
                        />
                      </div>
                      <div className={Styles.inputForm}>
                        <div className={Styles.dropdown}>
                          <Dropdown
                            options={["Harian", "Mingguan", "Bulanan"]?.map(
                              (obj) => {
                                return {
                                  name: obj,
                                };
                              }
                            )}
                            placeholder={"Harian/mingguan/bulanan"}
                            value={p?.denom || "Bulanan"}
                            setValue={(newVal) =>
                              setCityPositionData(
                                "allowances",
                                cityPositionData?.allowances?.map((obj) => {
                                  if (p?.name === obj?.name) {
                                    return {
                                      ...obj,
                                      denom: newVal,
                                    };
                                  }
                                  return obj;
                                })
                              )
                            }
                            autoClose
                          />
                        </div>
                        <div
                          className={Styles.iconDelete}
                          onClick={() => {
                            setCityPositionData(
                              "allowances",
                              cityPositionData?.allowances?.filter(
                                (obj) => obj?.name !== p?.name
                              )
                            );
                          }}
                        >
                          <Icon icon={"trash"} size={20} color={"#FF3E13"} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        title: "BPJS (BPU)",
        extraTitle: <div className={Styles.valueBPU}>{selectedValueBPU}</div>,
        component: (
          <div className={`${Styles.bpuSection} ${Styles.grid}`}>
            <DinamicDecorativeInputField
              title={"JKK (Jaminan Kelengkapan Kerja)"}
              value={cityPositionData?.jkk}
              onChange={(_, newVal) => {
                setCityPositionData("jkk", newVal);
              }}
              adornment="Rp"
              type="currency"
              preventDelete
            />
            <DinamicDecorativeInputField
              title={"JKM (Jaminan Kematian)"}
              value={cityPositionData?.jkm}
              onChange={(_, newVal) => {
                setCityPositionData("jkm", newVal);
              }}
              adornment="Rp"
              type="currency"
              preventDelete
            />
            <DinamicDecorativeInputField
              title={"JHT (Jaminan Hari Tua)"}
              value={cityPositionData?.jht}
              onChange={(_, newVal) => {
                setCityPositionData("jht", newVal);
              }}
              adornment="Rp"
              type="currency"
              preventDelete
            />
          </div>
        ),
      },
      {
        title: "Expected Date",
        component: (
          <InputDateField
            title={"Expected Fulfillment Date"}
            value={cityPositionData?.expectedDate}
            setValue={(newVal) => setCityPositionData("expectedDate", newVal)}
            isError={
              alertMessage?.includes(`Expected fulfillment date`) &&
              !cityPositionData?.expectedDate
            }
            isErrorV2
            errorText="Expected fulfillment date belum diisi"
            placeholder={"Select date"}
            preventPastDates
          />
        ),
      },
    ];
  }, [
    alertMessage,
    allowanceOptions,
    cityPositionData?.allowances,
    cityPositionData?.expectedDate,
    cityPositionData?.jht,
    cityPositionData?.jkk,
    cityPositionData?.jkm,
    cityPositionData?.personalEquipments,
    cityPositionData?.salaryDenom,
    cityPositionData?.salaryValue,
    personalEquipmentOptions,
    selectedValueBPU,
    setCityPositionData,
  ]);

  return (
    <div
      className={`${Styles.perPositionPerCity} ${
        cityPositionData?.isApplied ? Styles.isApplied : ""
      }`}
    >
      <div className={Styles.pCHeader}>
        <Icon icon={"company-outline"} size={20} />
        <span>{cityPositionData?.cityName}</span>
        {!cityPositionData?.positionID && (
          <Switch
            isChecked={cityPositionData?.isApplied}
            setIsChecked={(newVal) => setCityPositionData("isApplied", newVal)}
            width="36px"
            height="20px"
          />
        )}
      </div>
      {cityPositionData?.isApplied && (
        <div className={Styles.pCDetails}>
          <div className={Styles.pCDHeader}>
            <span>Jumlah Pemenuhan</span>
            <Counter
              value={cityPositionData?.amount}
              onSubtract={() => {
                setCityPositionData("amount", +cityPositionData?.amount - 1);
              }}
              onAdd={() => {
                setCityPositionData("amount", +cityPositionData?.amount + 1);
              }}
              setValue={(newVal) => {
                setCityPositionData("amount", +newVal);
              }}
            />
          </div>
          <div className={Styles.pCDMain}>
            {selectedButtonBPJS?.toUpperCase()?.includes("BPU")
              ? template?.map((t, tI) => (
                  <div key={tI} className={Styles.pCDMEach}>
                    <div className={Styles.pCDMEHeader}>
                      <span>{t?.title}</span>
                      {t?.extraTitle || <></>}
                    </div>
                    {t?.component}
                  </div>
                ))
              : template
                  ?.filter((obj) => !obj?.title?.includes("BPJS"))
                  ?.map((t, tI) => (
                    <div key={tI} className={Styles.pCDMEach}>
                      <div className={Styles.pCDMEHeader}>
                        <span>{t?.title}</span>
                        {t?.extraTitle || <></>}
                      </div>
                      {t?.component}
                    </div>
                  ))}
          </div>
        </div>
      )}
    </div>
  );
};
