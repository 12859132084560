import EmployeeListLayout from "@Organism/Client/Employee/EmployeeList";
import { Helmet } from "react-helmet-async";
import Styles from "./style.module.scss";
import EmployeeListController from "@Organism/Client/Employee/EmployeeList/Controller";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { getClientCandidates } from "@Services/client/employee";
import { Await, defer, useLoaderData } from "react-router-dom";
import LoadingAnimation from "@Atom/LoadingAnimation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import debounce from "lodash.debounce";

export async function loader() {

  const packageCandidatesPromise = getClientCandidates(
    "limit=10&page=1"
  );
  return defer({
    packageCandidates: packageCandidatesPromise,
  });
}


export default function EmployeeList() {
  const { packageCandidates } = useLoaderData();
  const [value, setValue] = useState(10);
  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [fixedSearchTerm, setFixedSearchTerm] = useState("");

  const onChange = useCallback(() => {
    setFixedSearchTerm(searchTerm);
  }, [searchTerm]);

  const debounceChange = useMemo(() => debounce(onChange, 500), [onChange]);

  useEffect(() => {
    debounceChange();
  }, [debounceChange]);

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Clients</title>
      </Helmet>

      <div className={Styles.controllerWrapper}>
        <EmployeeListController
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>

      <div className={Styles.contentWrapper}>
        <Suspense
          fallback={
            <div className={Styles.loadingWrapper}>
              <LoadingAnimation />
            </div>
          }
        >
          <Await
            resolve={packageCandidates}
            errorElement={
              <div style={{ height: "calc(100vh - 220px)" }}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageCandidates) => (
              <EmployeeListLayout
                defaultCandidatesPackage={packageCandidates?.response?.employee}
                fixedSearchTerm={fixedSearchTerm}
                totalPage={totalPage}
                setTotalPage={setTotalPage}
                value={value}
                setValue={setValue}
                pages={pages}
                setPages={setPages}
                pageList={pageList}
                setPageList={setPageList}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}
