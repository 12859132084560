import CandidatesTableSet from "@Molecule/CandidatesTableSet";
import Styles from "./style.module.scss";
import StackedProfile from "@Atom/StackedProfile";
// import CompletionIndicatorIcons from "@Atom/CompletionIndicatorIcons";
import CandidateStatusIndicator from "@Atom/CandidateStatusIndicator";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ModalWrapper from "@Atom/ModalWrapper";
import CandidateDetailsModal from "@Molecule/_modal/BusinessSupport/CandidateDetailModal";
import UseOutsideClick from "@Hooks/useOutsideClick";
import { fileBaseUrl } from "@Config/api";
import { getCandidates } from "@Services/support/candidate";
import Images from "@Theme/Images";

export default function BusinessSupportDashboardLayout({
  candidatesData,
  latestCandidatesData,
  limit,
  setLimit,
  page,
  setPage,
  status,
  setStatus,
  // stage,
  // setStage,
  // search,
  // setSearch,
  limit2,
  setLimit2,
  page2,
  setPage2,
  search2,
  setSearch2,
}) {
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [data, setData] = useState(candidatesData || null);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [valueIdCandiate, setValueIdCandidate] = useState(null);

  const refetchData = useCallback(async () => {
    try {
      setIsFetchingData(true);
      const { response } = await getCandidates(
        limit,
        page,
        status,
        "",
        [],
        "",
        "",
        "",
        valueIdCandiate?.id || ""
      );
      setData(response);
      setIsFetchingData(false);
    } catch (err) {
      setIsFetchingData(false);
      console.log(err);
    }
  }, [limit, page, status, valueIdCandiate]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  const getType = (statusFilter, stageFilter, tagFilter) => {
    if (statusFilter?.toUpperCase()?.includes("HOLD")) {
      return "Hold";
    } else if (statusFilter?.toUpperCase()?.includes("CONTRACTED")) {
      return "Contracted";
    } else if (
      statusFilter?.toUpperCase() === "BS-REGISTERED" &&
      stageFilter?.toUpperCase() === "REGISTER"
    ) {
      return "Menunggu Verifikasi";
    } else if (
      statusFilter?.toUpperCase() === "BS-REGISTERED" &&
      stageFilter?.toUpperCase() === "FIRST-VERIFICATION" &&
      tagFilter?.toUpperCase() === "DECLINED"
    ) {
      return "Perbaikan Data";
    } else if (
      statusFilter?.toUpperCase() === "BS-PRE-CONTRACT" &&
      stageFilter?.toUpperCase() === "GENERATE-CONTRACT" &&
      tagFilter?.toUpperCase() !== "DECLINED"
    ) {
      return "Belum Generate";
    } else if (
      statusFilter?.toUpperCase() === "BS-PRE-CONTRACT" &&
      stageFilter?.toUpperCase() === "GENERATE-CONTRACT" &&
      tagFilter?.toUpperCase() === "DECLINED"
    ) {
      return "Perbaikan Kontrak";
    } else if (
      statusFilter?.toUpperCase() === "BS-PRE-CONTRACT" &&
      stageFilter?.toUpperCase() === "APPROVE-CONTRACT"
    ) {
      return "Menunggu Approval BM";
    } else if (
      statusFilter?.toUpperCase() === "BS-PRE-CONTRACT" &&
      stageFilter?.toUpperCase() === "SIGN-CONTRACT" &&
      tagFilter?.toUpperCase() === "ACCEPTED"
    ) {
      return "Menunggu Verifikasi";
    } else if (
      statusFilter?.toUpperCase() === "BS-PRE-CONTRACT" &&
      stageFilter?.toUpperCase() === "SECOND-VERIFICATION" &&
      tagFilter?.toUpperCase() === "DECLINED"
    ) {
      return "Perbaikan Data";
    } else if (
      statusFilter?.toUpperCase() === "BS-CONTRACT" &&
      stageFilter?.toUpperCase() === "DONE"
    ) {
      return "Gagal Inject";
    } else {
      return statusFilter;
    }
  };

  const candidatesTemplate = useMemo(() => {
    if (
      status === "Verifikasi Pertama" ||
      status === "Generate Kontrak" ||
      status === "Verifikasi Kedua"
    ) {
      return {
        data: data?.data?.map((obj) => ({
          id: obj?.id,
          name: obj?.name,
          profilePicture: obj.CandidateDocuments
            ? obj?.CandidateDocuments?.url?.includes("http")
              ? obj?.CandidateDocuments?.url?.replace(
                  "https://myworkspace",
                  "https://api1.myworkspace"
                )
              : fileBaseUrl + obj?.CandidateDocuments?.url
            : Images.AVA_DEFAULT,
          phoneNumber: obj?.phoneNumber,
          roName: obj?.recruiter?.name,
          roCode: obj?.recruiter?.employeeId,
          roProfilePicture: obj?.recruitmentOfficer?.profilePicture
            ? obj?.recruitmentOfficer?.profilePicture?.includes("http")
              ? obj?.recruitmentOfficer?.profilePicture
              : fileBaseUrl + obj?.recruitmentOfficer?.profilePicture
            : null,
          region: obj?.ExpectedPosition?.City?.name,
          stage: obj?.stage,
          status: obj?.status,
          step: obj?.step,
          tag: obj?.tag,
        })),
        columns: [
          {
            name: "name",
            label: "Name",
            renderData: (row) => (
              <StackedProfile
                profilePicture={row?.profilePicture}
                title={row?.name}
                label={row?.phoneNumber + " • " + row?.roCode}
                // extraTitle={
                //   <CompletionIndicatorIcons
                //     times={
                //       row?.stage?.toLowerCase()?.includes("pre-contract")
                //         ? 1
                //         : row?.stage?.toLowerCase()?.includes("bs-contract")
                //         ? 2
                //         : 0
                //     }
                //   />
                // }
              />
            ),
          },
          {
            name: "region",
            label: "Region",
            hideOnMobile: true,
          },
          // {
          //   name: "stage",
          //   label: "Stage",
          //   hideOnMobile: true,
          //   renderData: (row) => (
          //     <div>
          //       {row?.step !== null
          //         ? row?.step?.toUpperCase()
          //         : row?.stage?.toUpperCase()}
          //     </div>
          //   ),
          // },
          {
            name: "status",
            label: "Status",
            hideOnMobile: true,
            renderData: (row) => (
              <CandidateStatusIndicator
                type={getType(row?.status, row?.stage, row?.tag)}
              />
            ),
          },
          {
            name: "action",
            label: "Action",
            showOnMobile: true,
            renderData: (row) => (
              <button
                className={Styles.showDetailButton}
                onClick={() => setSelectedCandidate(row)}
              >
                Detail
              </button>
            ),
          },
        ],
      };
    } else if (status === "TTD Kontrak" || status === "Joined") {
      return {
        data: data?.data?.map((obj) => ({
          id: obj?.id,
          name: obj?.name,
          profilePicture: obj?.profilePicture,
          phoneNumber: obj?.phoneNumber,
          roName: obj?.recruiter?.name,
          roCode: obj?.recruiter?.employeeId,
          roProfilePicture: obj?.recruitmentOfficer?.profilePicture
            ? obj?.recruitmentOfficer?.profilePicture?.includes("http")
              ? obj?.recruitmentOfficer?.profilePicture
              : fileBaseUrl + obj?.recruitmentOfficer?.profilePicture
            : null,
          region: obj?.ExpectedPosition?.City?.name,
          stage: obj?.stage,
          status: obj?.status,
          step: obj?.step,
          tag: obj?.tag,
        })),
        columns: [
          {
            name: "name",
            label: "Name",
            renderData: (row) => (
              <StackedProfile
                profilePicture={row?.profilePicture}
                title={row?.name}
                label={row?.phoneNumber + " • " + row?.roCode}
                // extraTitle={
                //   <CompletionIndicatorIcons
                //     times={
                //       row?.stage?.toLowerCase()?.includes("pre-contract")
                //         ? 1
                //         : row?.stage?.toLowerCase()?.includes("bs-contract")
                //         ? 2
                //         : 0
                //     }
                //   />
                // }
              />
            ),
          },
          {
            name: "region",
            label: "Region",
            hideOnMobile: true,
          },
          // {
          //   name: "stage",
          //   label: "Stage",
          //   hideOnMobile: true,
          //   renderData: (row) => (
          //     <div>
          //       {row?.step !== null
          //         ? row?.step?.toUpperCase()
          //         : row?.stage?.toUpperCase()}
          //     </div>
          //   ),
          // },
          // {
          //   name: "status",
          //   label: "Status",
          //   hideOnMobile: true,
          //   renderData: (row) => (
          //     <CandidateStatusIndicator
          //       type={getType(row?.status, row?.stage, row?.tag)}
          //     />
          //   ),
          // },
          {
            name: "action",
            label: "Action",
            showOnMobile: true,
            renderData: (row) => (
              <button
                className={Styles.showDetailButton}
                onClick={() => setSelectedCandidate(row)}
              >
                Detail
              </button>
            ),
          },
        ],
      };
    }
  }, [data, status]);

  const hiredTemplate = useMemo(() => {
    return {
      data: latestCandidatesData?.map((obj) => ({
        id: obj.id,
        name: obj.name,
        profilePicture: obj.profilePicture,
        phoneNumber: obj?.phoneNumber,
        roCode: obj?.recruiterID,
        region: obj?.ExpectedPosition?.City?.name,
        clientName: obj?.ExpectedPosition?.OrderScheme?.Client.name,
      })),

      columns: [
        {
          name: "name",
          label: "Name",
          renderData: (row) => (
            <StackedProfile
              profilePicture={row?.profilePicture}
              title={row?.name}
              label={row?.jobLevel}
            />
          ),
        },
        {
          name: "clientName",
          label: "Client",
        },
      ],
    };
  }, [latestCandidatesData]);

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && selectedCandidate) {
      setSelectedCandidate(null);
    }
  }, [boxOutsideClick, selectedCandidate]);

  return (
    <div className={Styles.container}>
      <div className={Styles.tablesWrapper}>
        <CandidatesTableSet
          type={"candidates"}
          data={candidatesTemplate?.data || []}
          columns={candidatesTemplate?.columns}
          totalPage={data?.totalPage || 0}
          totalData={data?.totalData || 0}
          value={limit}
          setValue={setLimit}
          selectedPage={page}
          setSelectedPage={setPage}
          activeController={status || "Verifikasi Pertama"}
          setActiveController={setStatus}
          // searchTerm={search}
          setSearchTerm={() => {}}
          // setStatus={setStatus}
          isFetchingData={isFetchingData}
          valueIdCandiate={valueIdCandiate}
          setValueIdCandidate={setValueIdCandidate}
        />
        <CandidatesTableSet
          type={"hired"}
          data={hiredTemplate?.data?.slice(
            (page2 - 1) * limit2,
            limit2 * page2
          )}
          columns={hiredTemplate?.columns}
          value={limit2}
          setValue={setLimit2}
          selectedPage={page2}
          setSelectedPage={setPage2}
          totalPage={Math.ceil(hiredTemplate?.data?.length / limit2)}
          searchTerm={search2}
          setSearchTerm={setSearch2}
        />
      </div>
      <div>
        <ModalWrapper
          show={selectedCandidate}
          handleClose={() => setSelectedCandidate(null)}
        >
          {selectedCandidate && (
            <div ref={boxRef}>
              <CandidateDetailsModal data={selectedCandidate} />
            </div>
          )}
        </ModalWrapper>
      </div>
    </div>
  );
}
