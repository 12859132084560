import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useState } from "react";

export default function ShowDetailTable({
  data,
  selectedButton,
  selectedButtonStepContract,
  selectedButtonStepRecruitment,
  setShowNotif,
}) {
  const handleOpenJobPost = (link) => {
    window.open(link);
  };

  const [hover, setHover] = useState(null);

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      setShowNotif(true);
      setTimeout(() => {
        setShowNotif(false);
      }, 1000);
    });
  };

  return (
    <div className={Styles.modalContainer}>
      {(selectedButton === "Kontrak" &&
        selectedButtonStepContract === "TTD Kontrak") ||
      (selectedButton === "Kontrak" &&
        selectedButtonStepContract === "Joined") ? null : (
        <div>
          <span>Status</span>
          <div>
            <div>
              <span
                className={`${Styles.statusText} ${
                  data?.Status === "Menolak Undangan" && Styles.red
                } ${
                  data?.Status === "Diundang" || data?.Status === "Dikirim"
                    ? Styles.green
                    : ""
                } ${
                  data?.Status === "Tidak Respon" ||
                  data?.Status === "Tidak Hadir" ||
                  data?.Status === "Tidak Lolos" ||
                  data?.Status === "Perbaikan Data" ||
                  data?.Status === "Perbaikan Kontrak" ||
                  data?.Status === "Gagal Inject" ||
                  data?.Status === "Penawaran Ditolak"
                    ? Styles.red
                    : ""
                } ${
                  data?.Status === "Hold" || data?.Status === "Belum Generate"
                    ? Styles.grey
                    : ""
                }  ${data?.Status === "Req. Reschedule" && Styles.pink}`}
              >
                {data?.Status || "-"}
              </span>

              {data?.Status === "Hold" && data?.remark?.includes("HOLD") ? (
                <div
                  className={Styles.icon}
                  onMouseEnter={() => {
                    setHover(data);
                  }}
                  onMouseLeave={() => {
                    setHover(null);
                  }}
                >
                  <Icon
                    icon={"information-solid"}
                    size={16}
                    color={"#DE9115"}
                  />
                  {hover?.id === data?.id ? (
                    <div className={Styles.remark}>
                      {hover?.remark?.includes("IOS") ? (
                        <span>
                          Kandidat masih aktif di{" "}
                          <span>{hover?.remark?.split("::")[1]}</span>
                          untuk posisi{" "}
                          <span>{hover?.remark?.split("::")[2]}</span>
                        </span>
                      ) : (
                        <span>
                          Kandidat sedang dalam proses kontrak di klien{" "}
                          <span>{hover?.remark?.split("::")[1]}</span> untuk
                          posisi <span>{hover?.remark?.split("::")[2]}</span>{" "}
                          oleh <span>{hover?.remark?.split("::")[3]}</span>
                        </span>
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}

      <div>
        <span>Sumber</span>
        <span>{data?.sumber || "-"}</span>
      </div>

      <div>
        <span>Perusahaan</span>
        <span>{data?.company || "-"}</span>
      </div>

      <div>
        <span>Lokasi Kerja</span>
        <span>{data?.placement || "-"}</span>
      </div>

      <div>
        <span>Link Job Posting</span>
        <span
          className={Styles.detail}
          onClick={() => handleOpenJobPost(data?.jobPostLink)}
        >
          Lihat Detail
        </span>
      </div>

      <div>
        <span>
          {(selectedButton === "Rekrutmen" &&
            selectedButtonStepRecruitment === "Kirim Offering") ||
          selectedButton === "Kontrak"
            ? "Link Offering"
            : "Link Undangan 1"}
        </span>
        <span
          className={`${Styles.detail} ${
            !data?.linkInterviewRO && Styles.disabledLink
          }`}
          onClick={() => {
            if (data?.linkInterviewRO) {
              handleOpenJobPost(data?.linkInterviewRO);
            }
          }}
        >
          Lihat Detail
        </span>
      </div>

      {(selectedButton === "Rekrutmen" &&
        selectedButtonStepRecruitment === "Undang Interview Klien") ||
      (selectedButtonStepRecruitment === "Kirim Rekomendasi" &&
        selectedButton === "Rekrutmen") ? (
        <div>
          <span>Link Undangan 2</span>
          <span
            className={`${Styles.detail} ${
              !data?.linkInterviewClient && Styles.disabledLink
            }`}
            onClick={() => {
              if (data?.linkInterviewClient) {
                handleOpenJobPost(data?.linkInterviewClient);
              }
            }}
          >
            Lihat Detail
          </span>
        </div>
      ) : null}

      {(selectedButton === "Kontrak" &&
        selectedButtonStepContract === "Joined") ||
      (selectedButtonStepContract === "Verifikasi Kedua" &&
        selectedButton === "Kontrak") ||
      (selectedButtonStepContract === "TTD Kontrak" &&
        selectedButton === "Kontrak") ? (
        <div>
          <span>Link TTD Kontrak</span>
          <span
            className={`${Styles.detail} ${
              !data?.linkContract && Styles.disabledLink
            }`}
            onClick={() => {
              if (data?.linkContract) {
                handleOpenJobPost(data?.linkContract);
              }
            }}
          >
            Lihat Detail
          </span>
        </div>
      ) : null}

      <div>
        <span>Email & Nomor Handphone</span>
        <div>
          <div>
            <span>{data?.email || "-"}</span>

            <Icon
              icon={"duplicate"}
              size={16}
              color={"#9E9E9E"}
              className={Styles.iconCopy}
              onClick={() => {
                handleCopy(data?.email);
              }}
            />
          </div>
          <div>
            <span>{data?.phoneNumber || "-"}</span>
            <Icon
              icon={"duplicate"}
              size={16}
              color={"#9E9E9E"}
              className={Styles.iconCopy}
              onClick={() => {
                handleCopy(data?.phoneNumber);
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <span>Domisili</span>
        <span>{data?.domicili || "-"}</span>
      </div>

      <div>
        <span>Alamat</span>
        <span>{data?.address || "-"}</span>
      </div>
    </div>
  );
}
