import { useCallback, useState } from 'react'
import ControlTab from './ControlTab'
import Styles from './style.module.scss'
import DataTable from './DataTable'
import useQuery from '@Hooks/useQuery'
import { getManPowerDetailNeedClient } from '@Services/client/dashboard'

export default function ManPowerRequestSummaryContentClient({ defaultData }) {
  const query = useQuery()
  const id = query.get('i')
  const [tag, setTag] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [data, setData] = useState(defaultData)

  const handleRenew = useCallback(async (limit, page) => {
    const { response } = await getManPowerDetailNeedClient(id, limit, page, searchTerm, tag)
    setData(response)
  }, [id, searchTerm, tag])


  return (
    <div className={Styles.container}>
      <ControlTab
        tag={tag}
        setTag={setTag}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        data={data}
      />
      <DataTable
        handleRenew={handleRenew}
        candidates={data?.candidates?.data}
        defaultTotalPage={data?.candidates?.totalPage}
      />
    </div>
  )
}