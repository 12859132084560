import { useEffect, useRef } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Images from "@Theme/Images";

export default function FirstStep({ form, setForm, options, isDone, onNext }) {
  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  const onChange = (type) => {
    setForm((prev) => {
      return {
        ...prev,
        contract: type?.title,
      };
    });
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <div className={Styles.header}>
        <h3>Choose Contract Type<span className={Styles.asterisk}>&#x2a;</span></h3>
        <span>Pilih tipe kontrak sesuai kebutuhan anda</span>
      </div>
      <div className={Styles.content}>
        {options.map((type, it) => {
          const isType = Boolean(type?.title === form?.contract);
          return (
            <div
              key={it}
              onClick={() => onChange(type)}
              className={`${Styles.card} ${isType && Styles.active}`}
            >
              <div
                className={`${Styles.iconWrapper} ${isType ? Styles.Selected : Styles.NotSelected
                  }`}
              >
                <Icon
                  icon="check-circle"
                  size={25}
                  className={Styles.checkIcon}
                />
              </div>
              <div className={Styles.cardHeader}>
                <h4>{type?.title}</h4>
                <span>{type?.label}</span>
              </div>
              <div className={Styles.cardContent}>
                {type?.rules?.map((rule, ir) => {
                  return (
                    <div key={ir} className={Styles.rule}>
                      <img src={Images.CHECK_MARK} alt="check" />
                      <p>{rule?.desc}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className={Styles.buttonWrapper}>
        <button disabled={!form?.contract} onClick={onNext}>
          Next
        </button>
      </div>
    </div>
  );
}
