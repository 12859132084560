import Stepper from "@Molecule/Stepper";
import Styles from "./style.module.scss";

export default function MPOStepper({ currentStep, onClickStep, options }) {
  return (
    <div className={Styles.container}>
      <Stepper
        type="filled"
        options={options}
        currentStep={currentStep}
        onClick={onClickStep}
      />
    </div>
  );
}
