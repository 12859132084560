import { API } from "@Config/api";

export function getClientDashboardDetail(code, limit, page) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/client/${code}?limit=${limit}&page=${page}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getManPowerDetailNeedClient(id, limit, page, search, tag) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/candidate/position/${id}?limit=${limit}&page=${page}&search=${search}&filter=${tag}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateClientInfoClient(id, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/client/${id}`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addClientContactClient(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/client-contact/client`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getProgressBar() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client/progression`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addManPowerRequest(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/client/manpower`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getPositionsSummary() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client/manpower-summary`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function createManpowerRequest(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/client/manpower`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getLogsSummary(page, limit, position) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client/summary-logs`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        params: { page, limit, position },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getPositionsQualification(summaryID = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client/manpower-qualification`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        params: { summaryID },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getQualificationSync() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client/qualification-sync`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateQualifications(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/client/manpower/qualifications`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function updateVacantQuota(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/client/manpower/amounts`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getBMDashboardLog(page, limit) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/bm/dashboard/clients/summaries`,
        {
          headers: {
            Authorization: localStorage.getItem('accessToken'),
          },
          params: { page, limit }
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClientDashboardSync(code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client/dashboard/sync/${code}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getFullfillment(code, position){
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client/dashboard/fulfillment?clientCode=${code}&position=${position}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getQualificationSummary(position = "", clientCode = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client/qualifications`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        params: { position, clientCode },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClientSummary(code, limit, page) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/client/summaries/${code}?limit=${limit}&page=${page}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClientEmployee(clientCode, page = 1, limit = 10, search = "",  position, city) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/client/dashboard/fulfillment/employee/${clientCode}?limit=${limit}&page=${page}&search=${search}&position=${position}&city=${city}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getClientEmployeeByEmployeeID(employeeId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/client/dashboard/fulfillment/employee-detail/${employeeId}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
