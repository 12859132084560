import useQuery from '@Hooks/useQuery'
import Images from '@Theme/Images'
import { Avatar } from '@mui/material'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'
import debounce from 'lodash.debounce'
import SearchBar from '@Atom/SearchBar'
import Icon from '@Atom/Icon'



export default function ControlTab({
  setTag,
  searchTerm,
  setSearchTerm,
  data
}) {
  const query = useQuery()
  const name = query.get('n')
  const [currentTab, setCurrentTab] = useState(1)
  const [searchTemp, setSearchTemp] = useState(searchTerm)

  const onChange = useCallback(() => {
    setSearchTerm(searchTemp)
  }, [setSearchTerm, searchTemp])

  const debouncedChange = useMemo(() => debounce(onChange, 500), [onChange])

  useEffect(() => {
    debouncedChange()
  }, [debouncedChange])

  const initial = useMemo(() => {
    if ((name?.slice(0, 2)?.toLowerCase() === 'cv') || (name?.slice(0, 2)?.toLowerCase() === 'pt')) {
        if(name[3] !== ' ') {
            return name[3]
        } else {
            return name[4]
        }
    } return name[0]
}, [name])

  const tabOptions = [
    {
      id: 1,
      icon: <img src={currentTab === 1 ? Images.APPLIED_ACTIVE : Images.APPLIED_INACTIVE} alt={"applied"} />,
      title: 'Applied',
      query: '',
      amount: data?.applied || 0
    },
    {
      id: 2,
      icon: <div className={Styles.logoWrapper}><img src={Images.INITIAL_LOGO} alt={"applied"} /></div>,
      title: 'Interview',
      query: '',
      amount: data?.roInterview || 0
    },
    {
      id: 3,
      icon: <Avatar className={Styles.initial}>{initial}</Avatar>,
      title: 'Interview',
      query: '',
      amount: data?.clientInterview || 0
    },
    {
      id: 4,
      icon: <img src={currentTab === 4 ? Images.OFFERING_ACTIVE : Images.OFFERING_INACTIVE} alt={"applied"} />,
      title: 'Offering',
      query: '',
      amount: data?.offering || 0
    },
  ]

  useEffect(()=>{
    if(currentTab === 1) {
      setTag(null)
    } else if(currentTab === 2) {
      setTag('RO')
    } if(currentTab === 3) {
      setTag('CLIENT')
    } if(currentTab === 4) {
      setTag('OFFER')
    }
  }, [currentTab,setTag])

  const requestData = [
    {
      title: 'Total need',
      content: data?.position?.amount
    },
    {
      title: 'Request date',
      content: moment(new Date(data?.position?.createdAt)).format('LL')
    },
    {
      title: 'Expected date',
      content: moment(new Date(data?.position?.expectedFulfillmentDate)).format('LL')
    },
  ]

  return (
    <div className={Styles.container}>
      <h4>Select to view detail</h4>
      <div className={Styles.topWrapper}>
        <div className={Styles.left}>
          {tabOptions?.map((each, i) => (
            <div
              key={i}
              className={`${Styles.each} ${currentTab === each?.id ? Styles.active : ''}`}
              onClick={() => setCurrentTab(each?.id)}
            >
              {each?.icon}
              <div className={Styles.detail}>
                <span>{each?.title}</span>
                <span>{each?.amount}</span>
              </div>
            </div>
          ))}
        </div>
        <div className={Styles.right}>
          {requestData?.map((each, i) => (
            <div key={i} className={Styles.each}>
              {i === 1 && <div className={Styles.divider1} />}
              <span className={Styles.title}>{each?.title}</span>
              <span className={Styles.content}>{each?.content}</span>
              {i === 1 && <div className={Styles.divider2} />}
            </div>
          ))}
        </div>

      </div>
      <div className={Styles.bottomWrapper}>
        <div className={Styles.amountWrapper}>
          <Icon icon="employee" size={20} />
          <span>Candidate</span>
          <span>{tabOptions[currentTab - 1]?.amount}</span>
        </div>
        <div className={Styles.searchBarWrapper}>
          <SearchBar
            placeholder={'Search employee name'}
            value={searchTemp}
            onChange={(e) => setSearchTemp(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}