// import { LocationOn } from "@mui/icons-material";
import Icon from "@Atom/Icon";
import React from "react";
import Styles from "./Styles.module.scss";
import Images from "@Theme/Images";

const location = [
  "Aceh",
  "Medan",
  "Pekanbaru",
  "Batam",
  "Padang",
  "Palembang",
  "Jakarta",
  "Bandung",
  "Semarang",
  "Surabaya",
  "Denpasar",
  "Banjarmasin",
  "Makassar",
  "Manado",
];
export default function JobSeekersLocation() {
  return (
    <div className={Styles.container}>
      <div className={Styles.topSection}>
        <div className={Styles.headWrapper}>
          <div className={Styles.wrapper}>
            <Icon icon="pin" size={24} color="#1571DE" />
          </div>
          <div className={Styles.wrapper}>
            <p className={Styles.smallTitle}>Lokasi cabang</p>
          </div>
          <div className={Styles.wrapper}>
            <p className={Styles.bigTitle}>
              Perusahaan kami tersebar di berbagai kota di Indonesia
            </p>
          </div>
        </div>
        <div className={Styles.pillsWrapper}>
          {location.map((el, idx) => (
            <div className={Styles.pil} key={idx}>
              <p>{el}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={Styles.bottomSection}>
        <img src={Images.INDONESIA_GLOBE} alt="maps" />
      </div>
    </div>
  );
}
