import EmployeeDetailPage from '@Organism/Client/Employee/EmployeeDetail'
import EmployeeDetailNavigator from '@Organism/Client/Employee/EmployeeDetail/Navigator'
import { Helmet } from 'react-helmet-async'
import Styles from './style.module.scss'
import { getClientCandidateDetails } from '@Services/client/employee';
import { Await, defer, useLoaderData } from 'react-router-dom';
import { Suspense } from 'react';
import LoadingAnimation from '@Atom/LoadingAnimation';
import AsyncErrorBoundary from '@Organism/Utils/AsyncErrorBoundary';

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const id = params?.get("c");

  const candidateDetailsPromise = getClientCandidateDetails(id)

  return defer({
    packageCandidateDetails: candidateDetailsPromise,
  });
}

export default function EmployeeDetail() {
  const { packageCandidateDetails } = useLoaderData()

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Employee Detail</title>
      </Helmet>

      <div className={Styles.controllerWrapper}>
        <EmployeeDetailNavigator />
      </div>

      <div className={Styles.contentWrapper}>
        <Suspense fallback={
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        }>
          <Await
            resolve={packageCandidateDetails}
            errorElement={
              <div style={{ height: 'calc(100vh - 220px)' }}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageCandidateDetails) => (
              <EmployeeDetailPage
                data={packageCandidateDetails?.response}
              />

            )}

          </Await>

        </Suspense>

      </div>
    </div>
  );
}
