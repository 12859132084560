import Icon from "@Atom/Icon";
import Styles from "./rightDecorativeInput.module.scss";
import React, { useState, useEffect } from "react";

const RightDecorativeInput = ({
  type,
  value,
  setValue,
  adornment,
  deleteOption,
  onDelete,
  placeholder = '',
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState(value || "");

  const changeValue = (targetValue) => {
    const re = /^[0-9\b]+$/;
    if (type === "number") {
      if (targetValue === "" || re.test(targetValue)) {
        setCurrentValue(targetValue);
      }
    }
    if (type === "string") {
      setCurrentValue(targetValue);
    }
  };

  useEffect(() => {
    setValue(currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <input
          className={`${Styles.input} ${!adornment ? Styles.round : ''}`}
          type="text"
          id="rightDecorativeInput"
          name="rightDecorativeInput"
          value={currentValue}
          onChange={(e) => changeValue(e.target.value)}
          placeholder={placeholder}
        />
        {
          !!adornment &&
          <div className={Styles.adornment}>{adornment}</div>
        }
      </div>
      {deleteOption && (
        <button onClick={onDelete}>
          <Icon icon="trash" size={20} />
        </button>
      )}
    </div>
  );
};

export default RightDecorativeInput;
