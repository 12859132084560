import Styles from "./style.module.scss";

export default function CandidateStepButton({
  id,
  title,
  label,
  isActive,
  onClick,
}) {
  return (
    <div
      className={`${Styles.container} ${isActive ? Styles.active : ""}`}
      onClick={() => onClick(id)}
    >
      <div className={Styles.id}>{id}</div>
      <div className={Styles.label}>
        <span>{title}</span>
        <span>{label}</span>
      </div>
    </div>
  );
}
