import React from "react";
import Styles from "./select.module.scss";

const Select = ({ setSelectedValue, options, value, ...props }) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <select className={Styles.container} onChange={handleChange} value={value}>
      {options?.map((option, index) => {
        return (
          <option key={index} value={option?.value}>
            {option?.label}
          </option>
        );
      })}
    </select>
  );
};

export default Select;
