/* eslint-disable eqeqeq */
import Icon from "@Atom/Icon";
import { candidateTransferType } from "@Services/officer/recruitment";
import { useEffect, useMemo, useState } from "react";
import TableCandidateToJobPost from "./TableCandidateToJobPost";
import TableCandidateToRecruiter from "./TableCandidateToRecruiter";
import Styles from "./styles.module.scss";
import TableLocationToJobPosting from "./TableLocationToJobPosting";

export default function TransferModal({
  mode,
  type,
  data,
  setData,
  setShowModalTransfer,
  setShowModalDone,
  setDoneText,
  setDoneTitle,
  setTrigger,
  isRecruitment,
  refetchDataAllCandidate,
  setType,
}) {
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedRecruiter, setSelectedRecruiter] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [errorText, setTextError] = useState(null);

  // useEffect(() => {
  //     console.log('posisi', selectedPosition)
  //     console.log('recruiter', selectedRecruiter)
  //     console.log('data', data)
  // }, [selectedPosition, selectedRecruiter])

  const title = useMemo(() => {
    let headerTitle = "";

    switch (type) {
      case "RegisterToJobPosting":
        headerTitle = "Daftarkan Ke Job Posting";
        break;
      case "TransferToJobPosting":
        headerTitle = "Alihkan Kandidat Ke Job Posting Lain";
        break;
      case "TransferToAnotherRecruiter":
        headerTitle = "Alihkan Kandidat Untuk Recruiter Lain";
        break;
      case "AddLocationAddToJobPosting":
        headerTitle = "Daftarkan Ke Job Posting";
        break;
      case "AddLocationTransferToJobPosting":
        headerTitle = "Alihkan Kandidat Ke Job Posting Lain";
        break;
      case "AddLocationTransferToAnotherRecruiter":
        headerTitle = "Alihkan Kandidat Untuk Recruiter Lain";
        break;
      default:
        headerTitle = "Daftarkan Ke Job Posting";
    }
    return headerTitle;
  }, [type]);

  console.log(type, "test apa ya");

  const contentTable = useMemo(() => {
    switch (type) {
      case "RegisterToJobPosting":
        return (
          <TableCandidateToJobPost
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
          />
        );
      case "TransferToJobPosting":
        return (
          <TableCandidateToJobPost
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
          />
        );
      case "TransferToAnotherRecruiter":
        return (
          <TableCandidateToRecruiter
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
            selectedRecruiter={selectedRecruiter}
            setSelectedRecruiter={setSelectedRecruiter}
          />
        );
      case "AddLocationAddToJobPosting":
      case "AddLocationTransferToJobPosting":
      case "AddLocationTransferToAnotherRecruiter":
        return (
          <TableLocationToJobPosting
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            selectedPosition={selectedPosition}
            selectedRecruiter={
              type === "AddLocationTransferToAnotherRecruiter"
                ? selectedRecruiter
                : null
            }
          />
        );
      default:
        return (
          <TableCandidateToJobPost
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
          />
        );
    }
  }, [type, selectedPosition, selectedRecruiter, selectedLocation]);

  const onCancel = () => {
    setData(null);
    setSelectedPosition(null);
    setSelectedRecruiter(null);
    setSelectedLocation(null);
    if (isRecruitment) {
      setShowModalTransfer(null);
    } else {
      setShowModalTransfer(false);
    }
  };

  const handleSubmit = async () => {
    try {
      if (
        type === "RegisterToJobPosting" ||
        type === "TransferToJobPosting" ||
        type === "TransferToAnotherRecruiter"
      ) {
        if (type === "RegisterToJobPosting") {
          setType("AddLocationAddToJobPosting");
        } else if (type === "TransferToJobPosting") {
          setType("AddLocationTransferToJobPosting");
        } else if (type === "TransferToAnotherRecruiter") {
          setType("AddLocationTransferToAnotherRecruiter");
        }
      } else {
        setIsSubmiting(true);
        let payload = {
          positionID: selectedLocation?.positionID,
          recruiterID: selectedLocation?.recruiterID,
          jobPostingID: selectedLocation?._id,
          jobPostingTitle: selectedPosition?.title,
        };
        let tempType = "JobPosting";
        if (selectedPosition?.positionID == 8612) {
          tempType = "Private";
        } else {
          tempType = "JobPosting";
        }

        await candidateTransferType(
          data?.id,
          tempType,
          encodeURIComponent(data?.lastPosition),
          payload
        );

        setShowModalDone(true);
        setDoneText(
          `Kandidat berhasil dialihkan ke job posting lain untuk posisi ${selectedPosition?.title} di ${selectedLocation?.City.name}`
        );
        setDoneTitle("Berhasil Mengalihkan Kandidat");
        setIsSubmiting(false);
        // setSelectedPosition(null);
        // setSelectedRecruiter(null);

        if (isRecruitment) {
          setShowModalTransfer(null);
          refetchDataAllCandidate();
        } else {
          setShowModalTransfer(false);
          setTrigger(Math.random());
        }
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || "something went wrong";
      setTextError(errorMessage);
      setIsSubmiting(false);
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setTextError(null);
    }, 3000);
  }, [errorText]);

  const disableButton = useMemo(() => {
    let temp;
    switch (type) {
      case "RegisterToJobPosting":
        if (!selectedPosition) {
          temp = true;
        } else {
          temp = false;
        }
        break;
      case "TransferToJobPosting":
        if (!selectedPosition) {
          temp = true;
        } else {
          temp = false;
        }
        break;
      case "TransferToAnotherRecruiter":
        if (selectedPosition && selectedRecruiter) {
          temp = false;
        } else {
          temp = true;
        }
        break;

      case "AddLocationAddToJobPosting":
      case "AddLocationTransferToJobPosting":
        if (selectedPosition && selectedLocation) {
          temp = false;
        } else {
          temp = true;
        }
        break;
      case "AddLocationTransferToAnotherRecruiter":
        if (selectedPosition && selectedLocation && selectedRecruiter) {
          temp = false;
        } else {
          temp = true;
        }
        break;
      default:
        temp = true;
    }
    return temp;
  }, [type, selectedPosition, selectedRecruiter, selectedLocation]);

  const onBack = () => {
    if (type === "AddLocationAddToJobPosting") {
      setType("RegisterToJobPosting");
      setSelectedLocation(null);
    } else if (type === "AddLocationTransferToJobPosting") {
      setType("TransferToJobPosting");
      setSelectedLocation(null);
    } else if (type === "AddLocationTransferToAnotherRecruiter") {
      setType("TransferToAnotherRecruiter");
      setSelectedLocation(null);
    }
  };

  const titleStep = useMemo(() => {
    let title = "";
    if (
      type === "RegisterToJobPosting" ||
      type === "TransferToJobPosting" ||
      type === "TransferToAnotherRecruiter"
    ) {
      if (type === "TransferToAnotherRecruiter" && !selectedRecruiter) {
        title = "Pilih Rekruiter";
      } else {
        title = "Pilih Posisi";
      }
    } else if (
      type === "AddLocationAddToJobPosting" ||
      type === "AddLocationTransferToJobPosting" ||
      type === "AddLocationTransferToAnotherRecruiter"
    ) {
      title = "Pilih lokasi";
    }
    return title;
  }, [type, selectedRecruiter]);

  const stepper = useMemo(() => {
    let step = "";
    if (
      type === "RegisterToJobPosting" ||
      type === "TransferToJobPosting" ||
      type === "TransferToAnotherRecruiter"
    ) {
      if (type === "TransferToAnotherRecruiter") {
        if (!selectedRecruiter) {
          step = `1/3`;
        } else {
          step = `2/3`;
        }
      } else {
        step = `1/2`;
      }
    } else if (
      type === "AddLocationAddToJobPosting" ||
      type === "AddLocationTransferToJobPosting" ||
      type === "AddLocationTransferToAnotherRecruiter"
    ) {
      if (type === "AddLocationTransferToAnotherRecruiter") {
        step = `3/3`;
      } else {
        step = `2/2`;
      }
    }

    return step;
  }, [type, selectedRecruiter]);

  return (
    <div className={Styles.background}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>
            <span>{title}</span>
          </h3>
          <button onClick={onCancel}>
            <Icon icon={"cross"} size={"24"} />
          </button>
        </div>
        <div className={Styles.normal}>
          <div className={Styles.detailWrapper}>
            <div className={Styles.detailBox}>
              <div className={Styles.iconDash}>
                <Icon icon={"user-circle"} size={"20"} />
              </div>
              <div className={Styles.contentDetailBox}>
                <span>Candidate</span>
                <span>{data?.name || "-"}</span>
              </div>
            </div>
            <div className={Styles.detailBox}>
              <div className={Styles.iconDash}>
                <Icon icon={"work-scheme"} size={"20"} />
              </div>
              <div className={Styles.contentDetailBox}>
                <span>Job</span>
                <span>{data?.lastPosition || "-"}</span>
              </div>
            </div>
          </div>

          {type === "TransferToAnotherRecruiter" ? null : (
            <div className={Styles.onBack} onClick={onBack}>
              <div>
                <Icon
                  icon={"arrow-left-back"}
                  size={16}
                  color={
                    type === "RegisterToJobPosting" ||
                    type === "TransferToJobPosting"
                      ? "#C2C2C2"
                      : "#000"
                  }
                />
              </div>
              <span
                className={`${
                  type === "RegisterToJobPosting" ||
                  type === "TransferToJobPosting"
                    ? Styles.disabled
                    : ""
                }`}
              >
                Kembali
              </span>
            </div>
          )}

          <div className={Styles.titleBox}>
            <span>{titleStep}</span>
            <span>step {stepper}</span>
          </div>

          {contentTable}
        </div>
        <div className={`${Styles.buttonsWrapper}`}>
          {/* {mode === "public" ? (
            <button onClick={onCancel}>Batal</button>
          ) : (
            <></>
          )} */}
          <button
            className={`${Styles.submitOnly} ${
              isSubmiting ? Styles.process : ""
            }`}
            disabled={disableButton}
            onClick={handleSubmit}
          >
            {type === "RegisterToJobPosting" ||
            type === "TransferToJobPosting" ||
            type === "TransferToAnotherRecruiter" ? (
              <span>Lanjut</span>
            ) : !isSubmiting ? (
              <span>Simpan</span>
            ) : (
              <span>Menyimpan...</span>
            )}
          </button>
        </div>
        {errorText && (
          <div className={Styles.errorWrapper}>
            <span>{errorText}</span>
          </div>
        )}
      </div>
    </div>
  );
}
