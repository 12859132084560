import Icon from "@Atom/Icon";
import EmptyData from "../EmptyData";
import Styles from "./styles.module.scss";
import { fileBaseUrl } from "@Config/api";
import { useEffect, useState } from "react";
const PDFJS = require("pdfjs-dist/webpack");

export default function SixthStep({ data, documents, selectedButton }) {
  const [images, setImages] = useState();
  const doc = documents?.find((d) => d?.type === "CV");
  const docURL = doc ? fileBaseUrl + doc?.url : undefined;
  useEffect(() => {
    if (docURL) {
      const loadDefaultImages = async () => {
        const imagesArr = [];
        if (docURL === undefined) {
          imagesArr.push(null);
        } else {
          const uri = docURL;
          var pdf = await PDFJS.getDocument({ url: uri }).promise;
          const canvas = document.createElement("canvas");
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport: viewport })
              .promise;
            imagesArr.push(canvas.toDataURL());
          }
          canvas.remove();
        }

        setImages(imagesArr[0]);
      };
      loadDefaultImages();
    } else {
      setImages(null);
    }
  }, [docURL]);

  const handleDownload = (imageName) => {
    const imageUrl = fileBaseUrl + imageName;
    window.open(imageUrl, "_blank");
  };
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return (
    <div className={Styles.bodySixthStep}>
      {documents?.length === 0 && <EmptyData label={"Dokumen"} type={"doc"} />}
      {documents?.length > 0 && (
        <div className={Styles.cardDocument}>
          {documents?.map((el, index) => {
            return (
              <div key={index} className={Styles.card}>
                <div className={Styles.left}>
                  {el?.type === "CV" ? (
                    el?.url ? (
                      <div className={Styles.images}>
                        <img src={images} alt="" />
                      </div>
                    ) : (
                      <div className={Styles.iconNull}>
                        <Icon icon={"pdf-type"} size={24} color={"#1571DE"} />
                      </div>
                    )
                  ) : el?.type !== "FOTO SELFIE" &&
                    el?.type !== "SELF PICTURE" &&
                    el?.type !== "KTP" &&
                    el?.type !== "CV" &&
                    el?.type !== "SURAT LAMARAN" &&
                    el?.type !== "IJAZAH" &&
                    el?.type !== "TRANSKRIP NILAI" &&
                    el?.type !== "KARTU KELUARGA" &&
                    el?.type !== "REKENING" &&
                    el?.type !== "NPWP" &&
                    el?.type?.toLowerCase() !== "jamsostek" &&
                    el?.type?.toLowerCase() !== "surat referensi kerja" &&
                    el?.type?.toLowerCase() !== "surat nikah" &&
                    el?.type?.toLowerCase() !== "akta lahir" &&
                    el?.type?.toLowerCase() !== "surat persetujuan keluarga" &&
                    el?.type?.toLowerCase() !== "skck" &&
                    el?.type?.toLowerCase() !==
                      "surat keterangan berbadan sehat" ? (
                    <div className={Styles.iconNull}>
                      <Icon icon={"pdf-type"} size={24} color={"#1571DE"} />
                    </div>
                  ) : el?.url ? (
                    <div className={Styles.images}>
                      <img src={fileBaseUrl + el?.url} alt="" />
                    </div>
                  ) : (
                    <div className={Styles.iconNull}>
                      <Icon icon={"pdf-type"} size={24} color={"#1571DE"} />
                    </div>
                  )}
                  <div className={Styles.title}>
                    <span className={Styles.p}>
                      {el?.type === "CV" || el?.type === "KTP"
                        ? el?.type
                        : toTitleCase(el?.type)}
                    </span>
                    <span className={Styles.preview}>
                      {el?.url ? el?.url : "Dokumen belum tersedia"}
                    </span>
                  </div>
                </div>

                {el?.url ? (
                  <div
                    className={Styles.iconDownload}
                    onClick={() => {
                      if (el?.type !== "CV") {
                        handleDownload(el?.url);
                      } else {
                        handleDownload(el?.url);
                      }
                    }}
                  >
                    <Icon icon={"download"} color={"#91BDF1"} size={20} />
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
