import { useEffect, useRef } from "react";
import Styles from "./style.module.scss";

export default function ModalWrapper({
  children,
  show,
  type,
  forceHide,
  wrapperClassName,
  forceCenter,
  isTransparent = false,
  handleClose = () => {},
  isLandingPage,
}) {
  const layoutRef = useRef();

  useEffect(() => {
    if (show) {
      if (!isLandingPage) {
        document.body.style.overflow = "hidden";
      }
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [show, isLandingPage]);

  return (
    <div
      className={`${Styles.container} ${show ? Styles.onShow : Styles.onHide} ${
        type === "fullscreen" ? Styles.fullscreen : Styles.normal
      } ${forceHide ? Styles.forceHide : ""} ${
        forceCenter ? Styles.forceCenter : Styles.default
      }`}
    >
      <div
        className={wrapperClassName ? wrapperClassName : Styles.defaultWrapper}
      >
        {children}
      </div>
      <div
        ref={layoutRef}
        onClick={() => {
          if (show) {
            handleClose();
          }
        }}
        className={`${Styles.overlay} ${show ? Styles.onShow : Styles.onHide} ${
          isTransparent ? Styles.transparent : ""
        }`}
      />
    </div>
  );
}
