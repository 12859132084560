import React, { useState } from "react";
import Styles from "./style.module.scss";
import Stepper from "@Molecule/Stepper";
import Icon from "@Atom/Icon";
import moment from "moment";

const ManPowerTable = ({ job, ...props }) => {
  let [currentSubStep, setCurrentSubStep] = useState(1);

  let IDR = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  const onClickStep = (id) => {
    setCurrentSubStep(id);
  };

  const onClickNext = () => {
    setCurrentSubStep((currentSubStep += 1));
  };

  const onClickBack = () => {
    setCurrentSubStep((currentSubStep -= 1));
  };

  const stepsOptions = [
    {
      id: 1,
      label: "Salary Allowance",
      isDone: false,
    },
    {
      id: 2,
      label: "Expected Date",
      isDone: false,
    },
    {
      id: 3,
      label: "Job Qualification",
      isDone: false,
    },
  ];

  return (
    <div className={Styles.manPowerInformationTable}>
      <div className={Styles.manPowerTitleText}>{job?.title}</div>
      <Stepper
        type="outlined"
        options={stepsOptions}
        currentStep={currentSubStep}
        onClick={onClickStep}
      />
      {currentSubStep === 1 && (
        <div className={Styles.salaryAllowancesWrapper}>
          <div className={Styles.salaryAllowancesTop}>
            <div className={Styles.salaryAllowancesCard} style={{ gap: 4 }}>
              <div className={Styles.tinyGreyText}>Contract type</div>
              <div className={Styles.text}>{job?.contractType}</div>
            </div>
            <div className={Styles.salaryAllowancesCard} style={{ gap: 4 }}>
              <div className={Styles.tinyGreyText}>Salary</div>
              <div className={Styles.text}>{IDR.format(job?.salary)}</div>
            </div>
          </div>
          <div className={Styles.salaryAllowancesBottom}>
            <div className={Styles.salaryAllowancesCard} style={{ gap: 8 }}>
              <div className={Styles.boldText}>Personal Work Equipment</div>
              {job?.personalEquipments?.length > 0 ? (
                job?.personalEquipments.map((equipment, id) => (
                  <div className={Styles.contentWrapper} key={id}>
                    <div className={Styles.text}>{equipment?.name}</div>
                    <div className={Styles.text}>
                      {IDR.format(equipment?.value)}
                    </div>
                  </div>
                ))
              ) : (
                <div className={Styles.text}>Tidak ada</div>
              )}
            </div>
            <div className={Styles.salaryAllowancesCard} style={{ gap: 8 }}>
              <div className={Styles.boldText}>Allowance</div>
              {job?.allowances?.length > 0 ? (
                job?.allowances.map((allowance, id) => (
                  <div className={Styles.contentWrapper} key={id}>
                    <div className={Styles.text}>{allowance?.name}</div>
                    <div className={Styles.text}>
                      {IDR.format(allowance?.value)}
                    </div>
                  </div>
                ))
              ) : (
                <div className={Styles.text}>Tidak ada</div>
              )}
            </div>
          </div>
          <div
            className={Styles.nextButtonWrapper}
            style={{ justifyContent: "flex-end" }}
          >
            <div className={Styles.nextButton} onClick={onClickNext}>
              <div className={Styles.nextButtonTitle}>Selanjutnya</div>
              <Icon className={Styles.iconArrow} icon="arrow-right" size={24} />
            </div>
          </div>
        </div>
      )}
      {currentSubStep === 2 && (
        <div className={Styles.stepWrapper}>
          <div className={Styles.fulfillmentDateWrapper}>
            <div className={Styles.fulfillmentDateTitle}>Fulfillment Date</div>
            <div className={Styles.dateWrapper}>
              <Icon className={Styles.icon} icon="calendar" size={20} />
              <div className={Styles.fulfillmentDateText}>
                {job?.fulfillmentDate
                  ? moment(job?.fulfillmentDate).format("LL")
                  : "Tanggal belum dipilih"}
              </div>
            </div>
          </div>
          <div
            className={Styles.nextButtonWrapper}
            style={{ justifyContent: "space-between" }}
          >
            <div className={Styles.nextButton} onClick={onClickBack}>
              <Icon className={Styles.iconArrow} icon="arrow-left" size={24} />
              <div className={Styles.nextButtonTitle}>Kembali</div>
            </div>
            <div className={Styles.nextButton} onClick={onClickNext}>
              <div className={Styles.nextButtonTitle}>Selanjutnya</div>
              <Icon className={Styles.iconArrow} icon="arrow-right" size={24} />
            </div>
          </div>
        </div>
      )}
      {currentSubStep === 3 && (
        <div className={Styles.stepWrapper}>
          <div className={Styles.jobQualificationWrapper}>
            <div className={Styles.tableHeadWrapper}>
              <div className={Styles.tableHeadText}>Title</div>
              <div className={Styles.tableHeadText}>Qualification</div>
            </div>
            <div className={Styles.tableBodyWrapper}>
              {job?.qualifications?.length > 0 ? (
                job?.qualifications.map((qualification, id) => (
                  <div className={Styles.tableBodyData} key={id}>
                    <div className={Styles.tableBodyText}>
                      {qualification?.name}
                    </div>
                    <div className={Styles.tableBodyTextSB}>
                      {qualification?.value}
                    </div>
                  </div>
                ))
              ) : (
                <div className={Styles.emptyText}>Tidak ada</div>
              )}
            </div>
          </div>
          <div className={Styles.nextButtonWrapper}>
            <div className={Styles.nextButton} onClick={onClickBack}>
              <Icon className={Styles.iconArrow} icon="arrow-left" size={24} />
              <div className={Styles.nextButtonTitle}>Kembali</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const EquipmentSection = ({ equipments, ...props }) => {
  let IDR = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  return (
    <div className={Styles.workEquipmentWrapper}>
      <div className={Styles.titleText}>Work Equipment</div>
      <div className={Styles.equipmentListWrapper}>
        <div className={Styles.equipmentList}>
          {equipments?.length > 0 ? (
            equipments.map((equipment, id) => (
              <div className={Styles.equipmentListData} key={id}>
                <div className={Styles.equipmentListText}>
                  {equipment?.name}
                </div>
                <div className={Styles.equipmentListTextSB}>
                  {IDR.format(equipment?.value)}
                </div>
              </div>
            ))
          ) : (
            <div className={Styles.emptyText}>Tidak ada equipment</div>
          )}
        </div>
      </div>
    </div>
  );
};

const RegionalSection = ({ data, cities, ...props }) => {
  const jobs = data?.jobs;
  const equipments = data?.areaWorkEquipments;

  const getLocationName = (locationCode) => {
    return cities?.find((c) => c.code === locationCode)?.name;
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.regionNameText}>
        {getLocationName(data?.location)}
      </div>
      {jobs.map((job, id) => (
        <ManPowerTable job={job} key={id} />
      ))}
      <EquipmentSection equipments={equipments} />
    </div>
  );
};

export default RegionalSection;
