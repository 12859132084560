import Images from '@Theme/Images'
import Styles from './style.module.scss'
import { useNavigate } from 'react-router-dom'

export default function NotFoundPage({
  type = "page",
  notFullWidth = false,
}) {
  const navigate = useNavigate()

  return (
    <div className={`${Styles.container} ${!notFullWidth ? Styles.fullWidth : ''}`}>
      <img src={Images.LOGO_404} alt='' />
      <h3>{type === 'page' ? 'Page' : 'Data'} Not Found</h3>
      <p>We’re sorry, the {type === 'page' ? 'page' : 'data'} you request not be found please go back to dashboard</p>
      <button
        onClick={() => {
          const isClient = localStorage.getItem("isClient") === 'true' ? true : false
          if (isClient) {
            navigate('/dashboard', { replace: true })
          } else {
            navigate('/', { replace: true })
          }
        }}
      >
        Go to Dashboard
      </button>
    </div>
  )
}