import { useState } from "react";
import Styles from "./style.module.scss";

const Desktop = (props) => {
  const { onAdd, title, setTitle, demand, setDemand, onCancel } = props;
  return (
    <div className={Styles.background}>
      <div className={Styles.container}>
        <h4>Add Man Power</h4>
        <form className={Styles.form} onSubmit={onAdd}>
          <div className={Styles.boxInput}>
            <label>Man Power</label>
            <input
              name="title"
              value={title}
              type="text"
              placeholder="Nama pekerjaan"
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className={Styles.boxInput}>
            <label>Jumlah</label>
            <input
              name="demand"
              value={demand}
              type="number"
              placeholder="Jumlah yang dibutuhkan"
              min="1"
              onChange={(e) => setDemand(e.target.value)}
              required
            />
          </div>

          <div className={Styles.actionsWrapper}>
            <button onClick={onCancel} type="button">
              <span>Cancel</span>
            </button>
            <button type="submit">
              <span>Add</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
const Mobile = (props) => {
  const { onAdd, title, setTitle, demand, setDemand, onCancel } = props;
  return (
    <div className={Styles.mobileContainer}>
      <div className={Styles.topWrapper}>
        <div className={Styles.topLine} />
        <div className={Styles.header}>
          <h4>Add Man Power</h4>
        </div>
      </div>

      <form className={Styles.form} onSubmit={onAdd}>
        <div className={Styles.boxInput}>
          <label>Man Power</label>
          <input
            name="title"
            value={title}
            type="text"
            placeholder="Nama pekerjaan"
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className={Styles.boxInput}>
          <label>Jumlah</label>
          <input
            name="demand"
            value={demand}
            type="number"
            placeholder="Jumlah yang dibutuhkan"
            min="1"
            onChange={(e) => setDemand(e.target.value)}
            required
          />
        </div>

        <div className={Styles.actionsWrapper}>
          <button onClick={onCancel} type="button">
            <span>Cancel</span>
          </button>
          <button type="submit">
            <span>Add</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default function AddManPowerModal(props) {
  const { type, setShowAdd, handleAddManPower, locationIndex } = props;
  const [title, setTitle] = useState("");
  const [demand, setDemand] = useState("");

  const onCancel = () => {
    setShowAdd(false);
  };

  const onAdd = (e) => {
    e.preventDefault();
    handleAddManPower(locationIndex, title, demand);
    setTitle("");
    setDemand("");
    setShowAdd(false);
  };

  switch (type) {
    case "mobile":
      return (
        <Mobile
          title={title}
          setTitle={setTitle}
          demand={demand}
          setDemand={setDemand}
          onAdd={onAdd}
          onCancel={onCancel}
        />
      );
    case "desktop":
      return (
        <Desktop
          title={title}
          setTitle={setTitle}
          demand={demand}
          setDemand={setDemand}
          onAdd={onAdd}
          onCancel={onCancel}
        />
      );
    default:
      return (
        <Desktop
          title={title}
          setTitle={setTitle}
          demand={demand}
          setDemand={setDemand}
          onAdd={onAdd}
          onCancel={onCancel}
        />
      );
  }
}
