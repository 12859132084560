/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import UploadBox from "@Atom/UploadBox";
import { isValidEmail } from "@Helpers/isValidEmail";
import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import CustomAutocompleteField from "@Molecule/CustomAutocompleteField";
import InputField from "@Molecule/InputField";
import DoneModal from "@Molecule/_modal/DoneModal";
import OTPConfirmationModal from "@Molecule/_modal/OTPConfirmationModal";
import {
  addCandidateByJobForm,
  getIdNumber,
  sendOTP,
} from "@Services/officer/recruitment";
import Images from "@Theme/Images";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { default as SlickSlider } from "react-slick";
import Styles from "./style.module.scss";
import SelectOptions from "@Molecule/SelectOption";
import InputWorkExperience from "@Molecule/_applicationForm/InputWorkExperience";
// import { fileBaseUrlDropbox } from "@Config/api";
import { fileToBase64 } from "@Helpers/convertFileToBase64";
import { base64ToFile } from "@Helpers/base64ToFile";
import { Box } from "@mui/material";
import PrivacyPolicyModal from "@Molecule/_modal/PrivacyPolicyModal";
import loading from "@Helpers/debounceLoading";
import { fileBaseUrl } from "@Config/api";
import TutorialSelfieModal from "@Molecule/_modal/TutorialSelfieModal";
import { allFilledObjChecker } from "@Helpers/allFilledObjChecker";
import { imageToPdf } from "@Helpers/convertImageToPdf";
import ErrorPortalModal from "@Molecule/_modal/ErrorPortalModal";
import InputDateField from "@Molecule/InputDateField";
import { capitalizedFirstLetter } from "@Helpers/capitalizedFirstLetter";
import { rupiahFormat } from "@Helpers/formatRupiah";
import ErrorSorryModal from "@Molecule/_modal/ErrorSorryModal";

const sliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  appendDots: (dots) => <ul>{dots}</ul>,
  customPaging: function (i) {
    return <div className="dot"></div>;
  },
  dotsClass: "slick-dots slick-thumb",
  fade: true,
};

export default function JobApplicationFormLayout({ children, syncData }) {
  const { hash } = useParams();
  const query = useQuery();
  const [data, setData] = useState(syncData);
  const [searchTerm, setSearchTerm] = useState("");
  // const debouncedSearchTerm = useDebounce(searchTerm?.split("/")[0], 300);
  const [position, setPosition] = useState(
    hash
      ? { ...syncData?.positions, positionId: syncData?.positions?.id }
      : null
  );

  const [isSubmitting, setIsSubmiting] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const dateRef = useRef();
  const [error, setError] = useState(null);
  const [errorField, setErrorField] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isBlacklistError, setIsBlacklistError] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [errorDate, setErrorDate] = useState("");

  function isUnder18(birthDate) {
    const today = new Date();
    const birthDateObj = new Date(birthDate).setHours(0, 0, 0, 0);

    const eighteenYearsAgo = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    ).setHours(0, 0, 0, 0);

    return birthDateObj > eighteenYearsAgo;
  }

  useEffect(() => {
    if (error && !showError && !isBlacklistError) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error, showError, isBlacklistError]);

  const domiciles = useMemo(() => {
    return data?.cities?.map((el) => el?.name);
  }, [data]);

  const dropdownOptions = useMemo(() => {
    const educations = [];
    const englishLevel = [];
    const vehicles = [];
    const genders = [];

    for (let i = 0; i < data?.dropdownOptions?.length; i++) {
      const option = data?.dropdownOptions[i];
      if (option?.type?.toLowerCase() === "education") {
        educations.push(option.value);
      }
      if (option?.type === "English Level") {
        englishLevel.push(option.value);
      }
      if (option?.type === "Vehicle") {
        vehicles.push(option.value);
      }
      if (option?.type === "Gender") {
        genders.push(option.value);
      }
    }

    return {
      educations: educations.map((el) => ({ name: el })),
      englishLevel: englishLevel.map((el) => ({ name: el })),
      genders: genders.map((el) => ({ name: el })),
      vehicles: vehicles.map((el) => ({ name: el })),
    };
  }, [data]);

  const defaultValues = JSON.parse(
    sessionStorage.getItem("jobApplicationForm")
  );

  const defaultCV = useMemo(() => {
    var base64 = sessionStorage["cv"];
    var base64Name = sessionStorage["cvName"];
    if (base64) {
      if (base64.url) {
        return JSON.parse(base64);
      } else {
        return base64ToFile(base64, base64Name);
      }
    }
    return null;
  }, []);

  const defaultOptionalDocument = useMemo(() => {
    var base64 = JSON.parse(sessionStorage.getItem("optionalDocument"));
    const arr = [];
    if (base64) {
      for (let i = 0; i < base64.length; i++) {
        const data = base64ToFile(base64[i].file, base64[i].name);
        arr.push(data);
      }
    }
    return arr;
  }, []);

  const defaultSelfPicture = useMemo(() => {
    var base64 = sessionStorage["selfPicture"];
    if (base64) {
      return base64ToFile(base64);
    }
    return null;
  }, []);

  const [value, setValue] = useState({
    optionalDocument: defaultOptionalDocument || [],
    positionId: defaultValues?.positionId || "",
    name: defaultValues?.name || "",
    dateOfBirth: defaultValues?.dateOfBirth || "",
    email: defaultValues?.email || "",
    phoneNumber: defaultValues?.phoneNumber || "",
    domicile: defaultValues?.domicile || "",
    address: defaultValues?.address || "",
    lastEducation: defaultValues?.lastEducation || "",
    lastPosition: defaultValues?.lastPosition || [],
    cv: defaultCV || null,
    gender: defaultValues?.gender || "",
    englishLevel: defaultValues?.englishLevel || "",
    vehicle: defaultValues?.vehicle || "",
    selfPicture: defaultSelfPicture || "",
    personId: defaultValues?.personId || "",
    isValidated: defaultValues?.isValidated || false,
    isPhoneVerified: defaultValues?.isPhoneVerified || false,
  });

  const [tempValue, setTempValue] = useState({});

  // function filesToURI(file) {
  //   // var file = element.files[0];
  //   var reader = new FileReader();
  //   reader.onload = function (fileLoadedEvent) {
  //     return reader.result;
  //   };
  //   reader.readAsDataURL(file);
  // }

  // useEffect(() => {
  //   // Saved to localstorage
  // function fileToBase64(file) {
  //   // var file = element.files[0];
  //   var reader = new FileReader();
  //   reader.onload = function (fileLoadedEvent) {
  //     sessionStorage["file"] = reader.result;
  //   };
  //   reader.readAsDataURL(file);
  // }
  //   if (value?.cv && !Boolean(value?.cv?.url)) {
  //     gotCV(value?.cv);
  //   }
  // }, [value?.cv]);

  const [searchDomicile, setSearchDomicile] = useState(value?.domicile || "");
  const debouncedSearch = useDebounce(searchDomicile, 300);

  const filteredDomiciles = useMemo(() => {
    return domiciles.filter((el) =>
      el?.toLowerCase()?.includes(debouncedSearch?.toLowerCase())
    );
  }, [domiciles, debouncedSearch]);

  // const [expectedPosition, setExpectedPosition] = useState(position?.name)

  const debouncedName = useDebounce(value?.name, 300);
  const debouncedEmail = useDebounce(value?.email, 300);
  const debouncedPhoneNumber = useDebounce(value?.phoneNumber, 300);
  // const debouncedDomicile = useDebounce(value?.domicile, 300)
  const debouncedAddress = useDebounce(value?.address, 300);
  const debouncedLastPosition = useDebounce(value?.lastPosition, 300);
  const debouncedPersonID = useDebounce(value?.personId, 300);
  // const debouncedLastPosition = useDebounce(value?.lastPosition, 300);

  const debouncedValues = useMemo(() => {
    return {
      positionId: value?.positionId,
      name: debouncedName,
      email: debouncedEmail,
      phoneNumber: debouncedPhoneNumber,
      domicile: value?.domicile,
      address: debouncedAddress,
      lastEducation: value?.lastEducation,
      lastPosition: debouncedLastPosition,
      cv: value?.cv,
      vehicle: value?.vehicle,
      gender: value?.gender,
      dateOfBirth: value?.dateOfBirth,
      englishLevel: value?.englishLevel,
      isValidated: value.isValidated,
      isPhoneVerified: value.isPhoneVerified,
      personId: debouncedPersonID,
    };
  }, [
    debouncedAddress,
    debouncedEmail,
    debouncedLastPosition,
    debouncedName,
    debouncedPhoneNumber,
    debouncedPersonID,
    value?.cv,
    value?.dateOfBirth,
    value?.domicile,
    value?.englishLevel,
    value?.gender,
    value?.lastEducation,
    value?.positionId,
    value?.vehicle,
    value.isValidated,
    value.isPhoneVerified,
  ]);

  useEffect(() => {
    sessionStorage.setItem(
      "jobApplicationForm",
      JSON.stringify(debouncedValues)
    );
  }, [debouncedValues]);

  // useEffect(() => {
  //   if (value?.cv) {
  //     sessionStorage.setItem("fileName", value?.cv?.name);
  //   }
  // }, [value?.cv]);

  const [experienceTemp, setExperienceTemp] = useState([]);
  const [lastEducation, setLastEducation] = useState(value?.lastEducation);
  const [gender, setGender] = useState(value?.gender);
  const [englishLevel, setEnglishLevel] = useState(value?.englishLevel);
  const [vehicle, setVehicle] = useState(value?.vehicle);

  const [formScreen, setFormScreen] = useState(false);
  const [loadingPersonId, setLoadingPersonId] = useState(false);
  const [openValidateModal, setOpenValidateModal] = useState(false);
  const [selectexperience, setSelectExperience] = useState("not exist");

  // console.log(selectexperience);

  // useEffect(() => {
  //   sessionStorage.setItem("screen", formScreen);
  // }, [formScreen]);

  // useEffect(() => {
  //   setFormScreen(sessionStorage.getItem("screen"));
  // }, []);

  useEffect(() => {
    setValue({
      ...value,
      // cv: file,
      domicile: searchDomicile,
      lastEducation: lastEducation,
      gender,
      vehicle,
      englishLevel,
      positionId: position?.positionId || searchTerm?.split("/")[2] || "",
    });
    // eslint-disable-next-line
  }, [
    // file,
    searchDomicile,
    lastEducation,
    position,
    searchTerm,
    gender,
    vehicle,
    englishLevel,
  ]);

  const handleChange = (e) => {
    if (e?.name === "personId") {
      // Gunakan regex untuk memastikan bahwa nilai hanya berisi angka
      const numericValue = /^[0-9]*$/;
      if (!numericValue?.test(e.value)) {
        // Jika nilai tidak valid, kita tidak mengubah state
        return;
      }
    }
    if (e.name === "phoneNumber") {
      setValue((prev) => ({ ...prev, isPhoneVerified: false }));
    }
    setValue((prev) => ({ ...prev, [e.name]: e.value }));
  };

  const inputFileRef = useRef();
  const inputCvRef = useRef();
  const inputCvExist = useRef();

  // useEffect(() => {
  //   // Ketika komponen dimuat, panggil metode click pada elemen input file
  //   inputCvRef.current.click();
  // }, []); // Efek ini hanya dijalankan sekali setelah komponen dimuat

  const onClick = () => {
    inputFileRef.current.click();
  };

  const onClickCv = () => {
    inputCvRef.current.click();
  };

  const onClickCvExist = () => {
    inputCvExist.current.click();
  };

  const inputOptionalRef = useRef();

  const onClickOptional = () => {
    inputOptionalRef.current.click();
  };

  const onFileChange = (e) => {
    // console.log(e.target.files);
    if (e?.target?.files?.length > 0) {
      // setValue((prev) => ({ ...prev, cv: e?.target?.files[0] }));
      // fileToBase64(e?.target?.input?.files[0], async (base64Data) => {
      //   sessionStorage.setItem("cv", base64Data);
      // });
    }
  };

  const onOptionalChange = (e) => {
    if (e?.target?.files?.length > 0) {
      fileToBase64(e?.target?.files[0], (base64Data) => {
        setValue((prev) => ({
          ...prev,
          optionalDocument: prev.optionalDocument.map((el) => [
            ...el,
            { name: e?.target?.input[0].name, file: base64Data },
          ]),
        }));
      });
    }
  };

  const [errorFormat, setErrorFormat] = useState("");
  const [errorFileReject, setErrorFileReject] = useState({});

  const onDrop = (acceptedFiles, fileRejections) => {
    if (acceptedFiles?.length > 0) {
      if (
        acceptedFiles[0]?.type === "image/jpeg" ||
        acceptedFiles[0]?.type === "image/jpg" ||
        acceptedFiles[0]?.type === "image/png" ||
        acceptedFiles[0]?.type === "application/pdf"
      ) {
        setValue((prev) => ({ ...prev, cv: acceptedFiles[0] }));
        sessionStorage.setItem("cvName", acceptedFiles[0].name);
        fileToBase64(acceptedFiles[0], async (base64Data) => {
          sessionStorage.setItem("cv", base64Data);
        });
        setErrorFormat("");
        setErrorFileReject({});
      } else {
        setErrorFormat(
          "Mohon unggah file dalam format PDF, JPG, JPEG atau PNG."
        );
      }
    }
    if (fileRejections?.length) {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrorFileReject({
              key: "cv",
              message: "File terlalu besar. Ukuran maksimal file adalah 5MB !",
            });
          }
        });
      });
    }
  };

  const onDropImage = (acceptedFiles, fileRejections) => {
    if (acceptedFiles?.length > 0) {
      fileToBase64(acceptedFiles[0], (base64Data) => {
        setValue((prev) => ({
          ...prev,
          selfPicture: acceptedFiles[0],
        }));
        fileToBase64(acceptedFiles[0], async (base64Data) => {
          sessionStorage.setItem("selfPicture", base64Data);
        });
      });
      setShowModalTutorial(false);
      setErrorFileReject({});
    }
    if (fileRejections?.length) {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrorFileReject({
              key: "selfPicture",
              message: "File terlalu besar. Ukuran maksimal file adalah 5MB !",
            });
          }
        });
      });
    }
  };

  const onDropOptional = (acceptedFiles, fileRejections) => {
    if (acceptedFiles?.length > 0) {
      fileToBase64(acceptedFiles[0], (base64Data) => {
        const doc = [
          ...value.optionalDocument,
          { name: acceptedFiles[0].name, file: base64Data },
        ];
        setValue((prev) => ({
          ...prev,
          optionalDocument: [...prev.optionalDocument, acceptedFiles[0]],
        }));

        const prev = JSON.parse(sessionStorage.getItem("optionalDocument"));

        if (prev) {
          sessionStorage.setItem(
            "optionalDocument",
            JSON.stringify([
              ...prev,
              { name: acceptedFiles[0].name, file: base64Data },
            ])
          );
        } else {
          sessionStorage.setItem(
            "optionalDocument",
            JSON.stringify([{ name: acceptedFiles[0].name, file: base64Data }])
          );
        }
      });
      setErrorFileReject({});
    }

    if (fileRejections?.length) {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrorFileReject({
              key: "optional",
              message: "File terlalu besar. Ukuran maksimal file adalah 5MB !",
            });
          }
        });
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    multiple: false,
    maxSize: 1048576 * 5,
  });

  const dropdownImage = useDropzone({
    onDrop: onDropImage,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    multiple: false,
    maxSize: 1048576 * 5,
  });

  const dropOptional = useDropzone({
    onDrop: onDropOptional,
    multiple: false,
    maxSize: 1048576 * 5,
  });

  // const disableSubmit = useMemo(() => {
  //   if (
  //     !value?.positionId ||
  //     !value?.name ||
  //     !value?.phoneNumber ||
  //     !value?.domicile ||
  //     !value?.address ||
  //     !value?.lastEducation ||
  //     !value?.cv ||
  //     !value?.selfPicture ||
  //     !checkbox ||
  //     isSubmitting ||
  //     !isPhoneVerified
  //   ) {
  //     return true;
  //   } else if (selectexperience === "exist") {
  //     if (value?.lastPosition?.length === 0) {
  //       return true;
  //     } else if (value?.lastPosition?.length > 0) {
  //       const data = value?.lastPosition?.map((el) => {
  //         return {
  //           startYear: el?.startYear,
  //           endYear: el?.endYear,
  //           position: el?.position || "",
  //           companyName: el?.companyName || "",
  //           description: el?.description || "",
  //           startMonth: el?.startMonth,
  //           endMonth: el?.endMonth,
  //         };
  //       });

  //       for (let i = 0; i < data?.length; i++) {
  //         const datas = data[i];
  //         if (
  //           !datas.startYear ||
  //           !datas.endYear ||
  //           !datas.position ||
  //           !datas.companyName ||
  //           !datas.description ||
  //           !datas.startMonth ||
  //           !datas.endMonth
  //         ) {
  //           return true;
  //         }
  //       }

  //       return false;
  //     } else {
  //       return false;
  //     }
  //   }
  // }, [value, isSubmitting, checkbox, selectexperience]);

  const disableInput = useMemo(() => {
    return (
      value.personId.length !== 16 ||
      !value.isValidated ||
      !value.isPhoneVerified
    );
  }, [value.isValidated, value.personId.length, value.isPhoneVerified]);

  const disablePhoneVerification = useMemo(() => {
    return value.personId.length !== 16 || !value.isValidated;
  }, [value.isValidated, value.personId.length]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleSubmit = async () => {
    try {
      setIsSubmiting(true);

      if (!value?.selfPicture) {
        setErrorField(true);
        setError("Anda belum mengambil foto selfie");
        setIsSubmiting(false);
        setErrorDate("");
        return true;
      }

      if (value?.dateOfBirth && isUnder18(value?.dateOfBirth)) {
        // setErrorField(true);
        setError(
          "Cek kembali form dan pastikan semua data telah terisi dengan benar"
        );
        setIsSubmiting(false);
        setErrorDate("Minimal usia 18 tahun");
        return true;
      }

      if (!value?.isPhoneVerified) {
        setErrorField(true);
        setError("Cek kembali form dan pastikan semua data telah terisi");
        setIsSubmiting(false);
        setErrorDate("");
        return true;
      }

      if (!value?.email) {
        setErrorField(true);
        setError("Email harus diisi");
        setIsSubmiting(false);
        setErrorDate("");
        return true;
      }

      if (value?.email) {
        if (!isValidEmail(value?.email)) {
          setErrorField(true);
          setError("Email tidak sesuai format");
          setIsSubmiting(false);
          setErrorDate("");
          return true;
        }
      }

      if (selectexperience === "exist") {
        if (value?.lastPosition?.length === 0) {
          setErrorField(true);
          setError("Cek kembali form dan pastikan semua data telah terisi");
          setIsSubmiting(false);
          setErrorDate("");
          return true;
        } else if (value?.lastPosition?.length > 0) {
          const data = value?.lastPosition?.map((el) => {
            return {
              startYear: el?.startYear,
              endYear: el?.endYear,
              position: el?.position || "",
              companyName: el?.companyName || "",
              description: el?.description || "",
              startMonth: el?.startMonth,
              endMonth: el?.endMonth,
            };
          });

          for (let i = 0; i < data?.length; i++) {
            const datas = data[i];
            if (
              !datas.startYear ||
              !datas.endYear ||
              !datas.position ||
              !datas.companyName ||
              !datas.description ||
              !datas.startMonth ||
              !datas.endMonth
            ) {
              setErrorField(true);
              setError("Cek kembali form dan pastikan semua data telah terisi");
              setIsSubmiting(false);
              setErrorDate("");
              return true;
            }
          }
        }
      }

      if (!value?.cv) {
        setErrorField(true);
        setError("File CV perlu di unggah");
        setIsSubmiting(false);
        setErrorDate("");
        return true;
      }

      if (!checkbox) {
        setErrorField(true);
        setError("Perlu menyetujui Privacy Policy");
        setIsSubmiting(false);
        setErrorDate("");
        return true;
      }

      const formData = new FormData();

      formData.append("name", value?.name);
      formData.append("positionID", value?.positionId);
      formData.append("email", value?.email);
      formData.append("phoneNumber", "62" + value?.phoneNumber);
      formData.append("domicile", value?.domicile);
      formData.append("jobPostingID", syncData?.jobPost?._id);
      formData.append("address", value?.address);
      formData.append("lastEducation", value?.lastEducation);
      formData.append("personId", value?.personId);
      formData.append("englishLevel", value?.englishLevel);
      formData.append("vehicle", value?.vehicle);
      formData.append("gender", value?.gender);
      formData.append("dateOfBirth", value?.dateOfBirth);
      formData.append("lastPosition", null);
      // formData.append("fileTypes[1]", "SELF PICTURE");
      // formData.append("files", value.selfPicture);
      formData.append("fileTypes[0]", "SELF PICTURE");
      formData.append("files", value.selfPicture);

      for (let i = 0; i < value.optionalDocument.length; i++) {
        const doc = value.optionalDocument[i];
        formData.append(`fileTypes[${i + 1}]`, "OPTIONAL");
        formData.append(`files`, doc);
      }

      if (!Boolean(value?.cv?.url)) {
        const fileExtension = value?.cv?.type.split("/").pop().toLowerCase();

        let validPdf = value.cv;
        if (
          fileExtension === "jpeg" ||
          fileExtension === "jpg" ||
          fileExtension === "png"
        ) {
          validPdf = await imageToPdf(value.cv);
        }

        formData.append(
          `fileTypes[${value.optionalDocument.length + 1}]`,
          "CV"
        );

        formData.append("files", validPdf);
        // formData.append("files", value.cv);
      } else {
        formData.append("existingCV", value.cv.id);
      }

      formData.append("portal", "Web Application");
      formData.append("recruiterID", syncData?.jobPost?.recruiterID);
      for (let i = 0; i < value.lastPosition.length; i++) {
        formData.append(
          `workExperience[${i}][startYear]`,
          value.lastPosition[i].startYear
        );
        formData.append(
          `workExperience[${i}][endYear]`,
          value.lastPosition[i].endYear
        );
        formData.append(
          `workExperience[${i}][position]`,
          value.lastPosition[i].position
        );
        formData.append(
          `workExperience[${i}][companyName]`,
          value.lastPosition[i].companyName
        );
        formData.append(
          `workExperience[${i}][description]`,
          value.lastPosition[i].description
        );
        formData.append(
          `workExperience[${i}][startMonth]`,
          value.lastPosition[i].startMonth
        );
        formData.append(
          `workExperience[${i}][endMonth]`,
          value.lastPosition[i].endMonth
        );
      }

      await addCandidateByJobForm(formData);

      setIsSubmiting(false);
      handleReset();
      setErrorField(false);
      setIsDone(true);
      setErrorDate("");
    } catch (err) {
      const errorMessage = err?.response?.data?.error || "something went wrong";
      console.log(err);
      setIsSubmiting(false);
      setErrorDate("");
      if (errorMessage?.toLowerCase()?.includes("fill all field")) {
        setErrorField(true);
        setError("Cek kembali form dan pastikan semua data telah terisi");
      } else if (errorMessage?.toLowerCase()?.includes("documents required")) {
        setErrorField(true);
        setError("Cek kembali form dan pastikan semua data telah terisi");
      } else if (
        errorMessage?.toLowerCase()?.includes("still on process for this job")
      ) {
        setShowError(true);
        setError(
          "Kamu telah melamar pada posisi yang sama, silahkan untuk melanjutkan proses pada lamaran tersebut"
        );
      } else if (
        errorMessage?.toLowerCase()?.includes("candidate is blacklisted")
      ) {
        setIsBlacklistError(true);
        setError("Kamu tidak dapat melamar pekerjaan ini.");
      } else {
        setError(errorMessage);
      }
    }
  };

  // const handleSubmit = async () => {
  //   try {
  //     setIsSubmiting(true);

  //     const { response } = await sendOTP("62" + value?.phoneNumber);
  //     setOpenValidateModal(false);

  //     navigate(`${pathname}?h=${response?.hash}&v=true`, { replace: true });

  //     setIsSubmiting(false);
  //   } catch (err) {
  //     const errorMessage = err?.response?.data?.error || "something went wrong";
  //     setIsSubmiting(false);
  //     if (errorMessage?.toLowerCase()?.includes("fill all field")) {
  //       setError("Cek kembali form dan pastikan semua data telah terisi");
  //     } else {
  //       setError(errorMessage);
  //     }
  //   }
  // };

  const handleReset = () => {
    setValue((prev) => ({
      ...prev,
      isValidated: false,
      name: "",
      dateOfBirth: "",
      email: "",
      phoneNumber: "",
      domicile: "",
      address: "",
      lastEducation: "",
      lastPosition: [],
      cv: null,
      gender: "",
      englishLevel: "",
      vehicle: "",
      selfPicture: "",
      optional: "",
      optionalDocument: [],
      personId: "",
    }));
    setSearchDomicile("");
    setSearchTerm("");
    setLastEducation("");

    sessionStorage.removeItem("jobApplicationForm");
    sessionStorage.removeItem("cv");
    sessionStorage.removeItem("cvName");
    sessionStorage.removeItem("optionalDocument");
    sessionStorage.removeItem("optionalName");
    sessionStorage.removeItem("selfPicture");
  };

  const handleClearCv = () => {
    setValue((prev) => ({ ...prev, cv: null }));
    sessionStorage.removeItem("cv");
    sessionStorage.removeItem("cvName");
  };

  // const value = JSON.parse(sessionStorage.getItem('jobApplicationForm'))

  // const handleSubmitOTP = async () => {
  //   try {
  //     setIsSubmiting(true);
  //     const formData = new FormData();

  //     formData.append("name", value?.name);
  //     formData.append("positionID", value?.positionId);
  //     formData.append("email", value?.email);
  //     formData.append("phoneNumber", "62" + value?.phoneNumber);
  //     formData.append("domicile", value?.domicile);
  //     formData.append("address", value?.address);
  //     formData.append("lastEducation", value?.lastEducation);
  //     formData.append("personId", value?.personId);
  //     formData.append("englishLevel", value?.englishLevel);
  //     formData.append("vehicle", value?.vehicle);
  //     formData.append("gender", value?.gender);
  //     formData.append("dateOfBirth", value?.dateOfBirth);
  //     formData.append("lastPosition", null);
  //     // formData.append("fileTypes[1]", "SELF PICTURE");
  //     // formData.append("files", value.selfPicture);
  //     formData.append("fileTypes[0]", "SELF PICTURE");
  //     formData.append("files", value.selfPicture);

  //     for (let i = 0; i < value.optionalDocument.length; i++) {
  //       const doc = value.optionalDocument[i];
  //       formData.append(`fileTypes[${i + 1}]`, "OPTIONAL");
  //       formData.append(`files`, doc);
  //     }

  //     if (!Boolean(value?.cv?.url)) {
  //       const fileExtension = value.cv.type.split("/").pop().toLowerCase();

  //       let validPdf = value.cv;
  //       if (
  //         fileExtension === "jpeg" ||
  //         fileExtension === "jpg" ||
  //         fileExtension === "png"
  //       ) {
  //         validPdf = await imageToPdf(value.cv);
  //       }

  //       formData.append(
  //         `fileTypes[${value.optionalDocument.length + 1}]`,
  //         "CV"
  //       );

  //       formData.append("files", validPdf);
  //       // formData.append("files", value.cv);
  //     } else {
  //       formData.append("existingCV", value.cv.id);
  //     }

  //     formData.append("portal", "Social Media");
  //     formData.append("recruiterID", syncData?.jobPost?.recruiterID);
  //     for (let i = 0; i < value.lastPosition.length; i++) {
  //       formData.append(
  //         `workExperience[${i}][startYear]`,
  //         value.lastPosition[i].startYear
  //       );
  //       formData.append(
  //         `workExperience[${i}][endYear]`,
  //         value.lastPosition[i].endYear
  //       );
  //       formData.append(
  //         `workExperience[${i}][position]`,
  //         value.lastPosition[i].position
  //       );
  //       formData.append(
  //         `workExperience[${i}][companyName]`,
  //         value.lastPosition[i].companyName
  //       );
  //       formData.append(
  //         `workExperience[${i}][description]`,
  //         value.lastPosition[i].description
  //       );
  //       formData.append(
  //         `workExperience[${i}][startMonth]`,
  //         value.lastPosition[i].startMonth
  //       );
  //       formData.append(
  //         `workExperience[${i}][endMonth]`,
  //         value.lastPosition[i].endMonth
  //       );
  //     }

  //     await addCandidateByJobForm(formData);

  //     setIsSubmiting(false);
  //     setOpenValidateModal(false);
  //     navigate(`${pathname}?h=${hash}&v=false`, { replace: true });
  //     handleReset();
  //     setIsDone(true);
  //   } catch (err) {
  //     const errorMessage = err?.response?.data?.error || "something went wrong";
  //     setIsSubmiting(false);
  //     if (errorMessage?.toLowerCase()?.includes("fill all field")) {
  //       setErrorField(true)
  //       setError("Cek kembali form dan pastikan semua data telah terisi");
  //     } else {
  //       setError(errorMessage);
  //     }
  //   }
  // };

  const handleSubmitCheckIdOTP = () => {
    setValue({
      ...value,
      ...tempValue,
      phoneNumber: tempValue.phoneNumber.includes("62")
        ? tempValue?.phoneNumber.slice(2)
        : tempValue?.phoneNumber,
    });
    setGender(tempValue.gender);
    setVehicle(tempValue.vehicle);
    setEnglishLevel(tempValue.englishLevel);
    setLastEducation(tempValue.lastEducation);
    setExperienceTemp(tempValue.lastPosition);
    setSearchDomicile(tempValue.domicile);
    sessionStorage.setItem("cvName", tempValue?.cv?.name);
    sessionStorage.setItem("cv", JSON.stringify(tempValue?.cv));
    setOpenValidateModal(false);
    if (tempValue.lastPosition.length > 0) {
      setSelectExperience("exist");
    } else {
      setSelectExperience("not exist");
    }
    setValue((prev) => ({ ...prev, isValidated: true, isPhoneVerified: true }));
    navigate(`${pathname}?h=${data.response?.hash}&v=false`, {
      replace: true,
    });
  };

  const [isVerifying, setIsVerifying] = useState(false);

  const onClickPhoneVerification = async () => {
    try {
      setIsVerifying(true);
      const { response } = await sendOTP("62" + value?.phoneNumber);
      setOpenValidateModal(false);
      setIsVerifying(false);
      navigate(`${pathname}?h=${response?.hash}&v=true`, { replace: true });
    } catch (err) {
      const errorMessage = err?.response?.data?.error || "something went wrong";
      setIsVerifying(false);
      if (errorMessage?.toLowerCase()?.includes("fill all field")) {
        setErrorField(true);
        setError("Cek kembali form dan pastikan semua data telah terisi");
      } else {
        setError(errorMessage);
      }
    }
  };

  const handleSuccessPhoneVerification = () => {
    setValue((prev) => ({ ...prev, isPhoneVerified: true }));
    navigate(`${pathname}?h=${data.response?.hash}&v=false`, {
      replace: true,
    });
  };

  useEffect(() => {
    const handleCheckId = async () => {
      if (value?.personId.length === 16) {
        try {
          setLoadingPersonId(true);
          await loading(5000);
          const { response } = await getIdNumber(value.personId);
          setLoadingPersonId(false);
          if (response) {
            setOpenValidateModal(true);
            const data = await sendOTP(response?.phoneNumber);

            navigate(`${pathname}?h=${data.response?.hash}&v=true`, {
              replace: true,
            });
            setTempValue({
              positionId: response?.positionId || "",
              name: response?.name || "",
              dateOfBirth: response?.dateOfBirth || "",
              email: response?.email || "",
              phoneNumber: response?.phoneNumber || "",
              domicile: response?.domicile || "",
              address: response?.address || "",
              lastEducation: response?.lastEducation || "",
              lastPosition: response?.CandidateWorkExperiences || [],
              gender: response?.gender || "",
              englishLevel: response?.englishLevel || "",
              vehicle: response?.vehicle || "",
              // selfPicture: response.CandidateDocuments.find(
              //   (el) => el.type === "SELF PICTURE"
              // ),
              cv: response.CandidateDocuments.find((el) => el.type === "CV"),
            });
          } else {
            setValue((prev) => ({ ...prev, isValidated: true }));
            // handleReset();
          }
        } catch (error) {
          const errorMessage =
            error?.response?.data?.error || "something went wrong";
          if (
            errorMessage?.toLowerCase()?.includes("candidate is blacklisted")
          ) {
            setError("Kamu tidak dapat melamar pekerjaan ini.");
            setIsBlacklistError(true);
          } else {
            setShowError(true);
            setError(errorMessage);
          }
          console.log(error);
          setLoadingPersonId(false);
          setValue((prev) => ({ ...prev, isValidated: false }));
        }
      }
    };
    if (!value.isValidated) {
      handleCheckId();
    }

    if (value.isValidated && value.personId.length < 16) {
      setValue((prev) => ({ ...prev, isValidated: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.personId]);

  useEffect(() => {
    if (value.lastPosition.length > 0) {
      setSelectExperience("exist");
      setExperienceTemp(value.lastPosition);
    } else {
      setSelectExperience("not exist");
    }
  }, [value.lastPosition]);

  console.log(isUnder18(value?.dateOfBirth));

  useEffect(() => {
    if (selectexperience === "not exist") {
      setValue((prev) => ({ ...prev, lastPosition: [] }));
    }
  }, [selectexperience]);

  const handleChangePhoto = (e) => {
    if (e?.target?.files?.length > 0) {
      fileToBase64(e?.target?.files[0], (base64Data) => {
        setValue((prev) => ({
          ...prev,
          selfPicture: e?.target?.files[0],
        }));
        fileToBase64(e?.target?.files[0], async (base64Data) => {
          sessionStorage.setItem("selfPicture", base64Data);
        });
      });
      setShowModalTutorial(false);
    }
  };

  const handleDeleteOptional = (index) => {
    const temp = [...value.optionalDocument];
    temp.splice(index, 1);
    setValue((prev) => ({ ...prev, optionalDocument: temp }));
    sessionStorage.setItem("optionalDocument", JSON.stringify(temp));
  };

  const jobPoster = useMemo(() => {
    const file = syncData?.jobPost?.medias.find((el) => el.type === "Poster");
    const img = fileBaseUrl + file?.uri;
    if (img) {
      return img;
    }
  }, [syncData.jobPost]);

  const jobSalary = useMemo(() => {
    const salary = syncData?.jobPost?.salary?.trim()?.split("-");
    if (syncData?.jobPost?.isSalaryDisplay === false) {
      return "Informasi tidak tersedia";
    } else {
      if (salary?.length) {
        let text = "";
        if (salary.length == 2) {
          text = `${rupiahFormat(salary[0])} - ${rupiahFormat(salary[1])}`;
        } else {
          text = `${rupiahFormat(salary[0])}`;
        }
        return text;
      } else {
        return "Informasi tidak tersedia";
      }
    }
  }, [syncData?.jobPost?.salary, syncData?.jobPost?.isSalaryDisplay]);

  const [showModalTutorial, setShowModalTutorial] = useState(false);

  const handleClickAndroid = () => {
    // Buka URL yang diinginkan dalam tab atau jendela baru
    window.open(
      "https://play.google.com/store/apps/details?id=com.personal365",
      "_blank"
    );
  };

  const handleClickIOS = () => {
    // Buka URL yang diinginkan dalam tab atau jendela baru
    window.open(
      "https://apps.apple.com/id/app/kerja365-loker/id6469481475",
      "_blank"
    );
  };

  const [jobPostRecomendations, setJobPostRecomendations] = useState([]);

  useEffect(() => {
    if (syncData?.jobPostRecomendations?.length > 0) {
      const recommend = syncData?.jobPostRecomendations;
      let temp = [];
      for (const value of recommend) {
        //poster, pekerjaan, title, location, link job post
        const poster =
          value?.medias?.find((f) => f.type.toUpperCase() === "POSTER") || {};
        console.log("poster", poster);
        const title = value?.title;
        const placement = Array.isArray(value?.placement)
          ? value?.placement?.map((el) => el?.city)?.join(", ")
          : value?.placement?.replace(/::/g, ", ");
        const link = value?.link;
        temp.push({
          poster: fileBaseUrl + poster?.uri,
          title,
          placement: capitalizedFirstLetter(placement),
          link,
        });
      }
      setJobPostRecomendations(temp);
    }
  }, [syncData?.jobPostRecomendations]);

  return (
    <div className={Styles.Body}>
      {syncData?.jobPost && syncData?.jobPost?.status === "Publish" ? (
        <div
          className={`${Styles.container} ${formScreen ? Styles.ShowForm : ""}`}
        >
          <div className={`${Styles.bannerSection} ${Styles.hideOnMobile}`}>
            <div className={Styles.logoWrapper}>
              <div className={Styles.imagesLogoKerja365}>
                <img src={Images.KERJA365_TEXTWHITE} alt="" />
              </div>
              <div className={Styles.logoRight}>
                <div className={Styles.logoIcon}>
                  <img src={Images.PERMATA_LOGO} alt="permata-logo" />
                </div>
                <div className={Styles.textPermata}>
                  <span className={Styles.poweredBy}>Powered by</span>
                  <span className={Styles.p}>Permata Indonesia</span>
                </div>
              </div>
            </div>
            <div className={Styles.bannerWrapper}>
              <div className={Styles.bannerImageWrapper}>
                <div className={Styles.cardWrapper}>
                  <div className={Styles.box}>
                    <img src={jobPoster} alt="banner" />
                    <div></div>
                    <div className={Styles.flipCard}>
                      <div className={Styles.flipCardInner}>
                        <div className={Styles.flipCardFront}>
                          <img src={jobPoster} alt="banner" />
                        </div>
                        <div className={Styles.flipCardBack}>
                          <div className={Styles.bannerPosterWrapper}>
                            <div className={Styles.bannerHeader}>
                              <img src={Images.RESUME_ICON} alt="icon" />
                              <div className={Styles.headerText}>
                                <p>Job kualifikasi</p>
                                <p>{syncData?.jobPost?.title}</p>
                              </div>
                            </div>
                            <div className={Styles.bannerContent}>
                              <div className={Styles.sectionJob}>
                                <div className={Styles.jobType}>
                                  <p>Pengalaman</p>
                                  <p>{syncData.jobPost?.experience}</p>
                                </div>
                                <div className={Styles.jobType}>
                                  <p>Metode Kerja</p>
                                  <p>{syncData.jobPost?.workMethod}</p>
                                </div>
                                <div className={Styles.jobType}>
                                  <p>Tipe kontrak</p>
                                  <p>{syncData.jobPost?.contractType}</p>
                                </div>
                                <div className={Styles.jobType}>
                                  <p>Tipe pekerjaan</p>
                                  <p>{syncData.jobPost?.workType}</p>
                                </div>
                                <div className={Styles.jobType}>
                                  <p>Tipe Kerja</p>
                                  <p>{syncData.jobPost?.workSystem}</p>
                                </div>
                                {syncData?.jobPost?.isSalaryDisplay === true ? (
                                  <div className={Styles.jobType}>
                                    <p>Range Gaji</p>
                                    <p>{jobSalary}</p>
                                  </div>
                                ) : null}
                              </div>
                              <div className={Styles.sectionDesc}>
                                <p>Lokasi</p>
                                <div className={Styles.placementBox}>
                                  {syncData?.jobPost?.placement?.length > 0
                                    ? syncData?.jobPost?.placement
                                        ?.map((item) => item.city)
                                        .join(", ")
                                    : "-"}
                                </div>
                              </div>
                              <div className={Styles.sectionDesc}></div>
                              <div className={Styles.sectionDesc}>
                                <p>Deskripsi</p>
                                <p>{syncData.jobPost?.description}</p>
                              </div>
                              <div className={Styles.sectionRequirement}>
                                <p>Kriteria</p>
                                <ul>
                                  {syncData.jobPost?.requirements.map(
                                    (el, idx) => (
                                      <li key={idx}>{el.value}</li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className={Styles.boxContent}>
                    <p>text</p>
                  </div> */}
                  </div>
                </div>
              </div>
              <div className={Styles.bannerInformation}>
                <div>
                  <p>Mau lamar lebih mudah, coba explore aplikasi kami</p>
                  <p>Download Kerja 365 Loker Sekarang</p>
                </div>
                <div>
                  <div
                    className={Styles.downloadIcon}
                    onClick={handleClickAndroid}
                  >
                    <img alt="download" src={Images.PLAYSTORE_OUTLINED_WHITE} />
                  </div>
                  <div className={Styles.downloadIcon} onClick={handleClickIOS}>
                    <img alt="download" src={Images.APPLE_WHITE_ICON} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${Styles.bannerSectionMobile} ${Styles.showOnMobile}`}
          >
            <div className={Styles.InformationBox}>
              <div
                className={Styles.informationButton}
                onClick={handleClickIOS}
              >
                <img alt="download" src={Images.APPLE_WHITE_ICON} />
                <span className={Styles.titleDownload}>App Store</span>
              </div>
              <div
                className={Styles.informationButton}
                onClick={handleClickAndroid}
              >
                <img alt="download" src={Images.PLAYSTORE_OUTLINED_WHITE} />
                <span className={Styles.titleDownload}>Play Store</span>
              </div>
            </div>

            <div className={Styles.bannerContent}>
              <SlickSlider {...sliderSettings}>
                <div className={Styles.CarouselItem}>
                  <img src={jobPoster} alt="banner" />
                </div>
                <div className={Styles.CarouselItem}>
                  <div className={Styles.bannerPosterWrapper}>
                    <div className={Styles.bannerHeader}>
                      <img src={Images.RESUME_ICON} alt="icon" />
                      <div className={Styles.headerText}>
                        <p>Job kualifikasi</p>
                        <p>{syncData.jobPost?.title}</p>
                      </div>
                    </div>
                    <div className={Styles.bannerContent}>
                      <div className={Styles.sectionJob}>
                        <div className={Styles.jobType}>
                          <p>Pengalaman</p>
                          <p>{syncData.jobPost?.experience}</p>
                        </div>
                        <div className={Styles.jobType}>
                          <p>Metode Kerja</p>
                          <p>{syncData.jobPost?.workMethod}</p>
                        </div>
                        <div className={Styles.jobType}>
                          <p>Tipe kontrak</p>
                          <p>{syncData.jobPost?.contractType}</p>
                        </div>
                        <div className={Styles.jobType}>
                          <p>Tipe pekerjaan</p>
                          <p>{syncData.jobPost?.workType}</p>
                        </div>
                        <div className={Styles.jobType}>
                          <p>Tipe Kerja</p>
                          <p>{syncData.jobPost?.workSystem}</p>
                        </div>
                        {syncData?.jobPost?.isSalaryDisplay === true ? (
                          <div className={Styles.jobType}>
                            <p>Range Gaji</p>
                            <p>{jobSalary}</p>
                          </div>
                        ) : null}
                      </div>
                      <div className={Styles.sectionDesc}>
                        <p>Lokasi</p>
                        <div className={Styles.placementBox}>
                          {syncData?.jobPost?.placement?.length > 0
                            ? syncData?.jobPost?.placement
                                ?.map((item) => item.city)
                                .join(", ")
                            : "-"}
                        </div>
                      </div>
                      <div className={Styles.sectionDesc}>
                        <p>Deskripsi</p>
                        <p>{syncData.jobPost?.description}</p>
                      </div>
                      <div className={Styles.sectionRequirement}>
                        <p>Kriteria</p>
                        <ul>
                          {syncData.jobPost?.requirements.map((el, idx) => (
                            <li key={idx}>{el.value}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </SlickSlider>
              <div className={Styles.bannerAction}>
                {/* <div className={Styles.bannerInformationMobile}>
                  <div>
                    <p>Download Kerja 365 </p>
                    <p> Loker Sekarang</p>
                  </div>
                  <div>
                    <div className={Styles.downloadIcon}>
                      <img
                        alt="download"
                        src={Images.PLAYSTORE_OUTLINED_WHITE}
                      />
                    </div>
                    <div className={Styles.downloadIcon}>
                      <img alt="download" src={Images.APPLE_WHITE_ICON} />
                    </div>
                  </div>
                </div> */}
                <div>
                  <Button
                    title="Isi Formulir"
                    onClick={() => setFormScreen(true)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.contentSection}>
            <div className={Styles.contentContainer}>
              <div className={Styles.headWrapper}>
                <div className={Styles.titleHead}>
                  <div className={Styles.descText}>
                    <span className={Styles.H1}>Form Lamar Pekerjaan</span>
                    <span className={Styles.descForm}>
                      Silahkan isi data diri anda dan jangan lupa untuk
                      memastikan kembali data anda sebelum mengirim form
                      pekerjaan , good luck 👍🏻
                    </span>
                  </div>
                  {/* <p className={Styles.hideOnMobile}>Form Lamar Pekerjaan</p> */}
                  <div
                    className={`${Styles.showOnMobile} ${Styles.backArrow}`}
                    onClick={() => setFormScreen(false)}
                  >
                    <Icon icon={"arrow-left-back"} size={24} />
                    <p>Kembali</p>
                  </div>

                  <div className={Styles.infoLocation}>
                    <div>
                      <Icon
                        icon={"map-pin-solid"}
                        size={16}
                        color={"#1571DE"}
                      />
                    </div>
                    <span>Lokasi Kerja Dipilih</span>
                    <span>:</span>
                    <span>{syncData?.positions?.City?.name || "-"}</span>
                  </div>
                </div>
              </div>
              <div className={Styles.boxInputForm}>
                {errorField && (
                  <div className={Styles.errorBannerContainer}>
                    <Icon icon={"alert-warning"} size={24} color={"#FF3E13"} />
                    <span>
                      Terdapat data yang belum diisi, silahkan lengkapi data
                      dibawah untuk dapat melanjutkan ke step selanjutnya
                    </span>
                  </div>
                )}
                <div className={Styles.imageInput}>
                  {value.selfPicture ? (
                    <div>
                      <div
                        className={`${Styles.Section} ${Styles.hideOnMobile}`}
                      >
                        <div
                          className={Styles.ImageidContainer}
                          {...dropdownImage.getRootProps()}
                        >
                          <img
                            src={URL.createObjectURL(value.selfPicture)}
                            alt="imageid"
                          />
                          <div className={Styles.Footer}>
                            <Icon icon={"camera"} size="24px" />
                            <p>Foto Ulang</p>
                            <input
                              {...dropdownImage.getInputProps()}
                              type="file"
                              // ref={inputFileRef}
                              name="file"
                              onChange={handleChangePhoto}
                              hidden
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${Styles.Section} ${Styles.showOnMobile}`}
                      >
                        <label htmlFor="cameraPhoto">
                          <div className={Styles.ImageidContainer}>
                            <img
                              src={URL.createObjectURL(value.selfPicture)}
                              alt="imageid"
                            />
                            <div className={Styles.Footer}>
                              <Icon icon={"camera"} size="24px" />
                              <p>Foto Ulang</p>
                              <input
                                type="file"
                                // ref={inputFileRef}
                                name="file"
                                capture="user"
                                onChange={handleChangePhoto}
                                hidden
                                multiple={false}
                                id="cameraPhoto"
                              />
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  ) : disableInput ? (
                    <div className={`${Styles.Section}`}>
                      <div
                        className={`${Styles.DropdownSection} ${Styles.disabledOnDrop}`}
                      >
                        <Icon icon="camera" size={28} />
                        <div>
                          <p className={Styles.label}>Ambil Foto Selfie</p>
                          <p className={Styles.requiredLabel}>Wajib Diisi</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div
                        className={`${Styles.Section} ${Styles.hideOnMobile}`}
                      >
                        <div
                          className={`${Styles.DropdownSection} ${Styles.onPointer}`}
                          // {...dropdownImage.getRootProps()}
                          onClick={() => setShowModalTutorial(true)}
                        >
                          <Icon icon="camera" size={28} color="#1571DE" />
                          <div>
                            <p>Ambil Foto Selfie</p>
                            <p className={Styles.requiredLabel}>Wajib Diisi</p>
                          </div>
                          {/* <input
                            {...dropdownImage.getInputProps()}
                            type="file"
                            // ref={inputFileRef}
                            capture="user"
                            name="file"
                            onChange={handleChangePhoto}
                            hidden
                          /> */}
                        </div>
                      </div>
                      <div
                        className={`${Styles.Section} ${Styles.showOnMobile}`}
                      >
                        <label
                          onClick={() => setShowModalTutorial(true)}
                          // htmlFor="camera"
                          className={`${Styles.DropdownSection} ${Styles.onPointer}`}
                        >
                          <Icon icon="camera" size={28} color="#1571DE" />
                          <div>
                            <p>Ambil Foto Selfie</p>
                            <p className={Styles.requiredLabel}>Wajib Diisi</p>
                          </div>
                          {/* <input
                            type="file"
                            // ref={inputFileRef}
                            capture="user"
                            name="file"
                            onChange={handleChangePhoto}
                            hidden
                            multiple={false}
                            id="camera"
                          /> */}
                        </label>
                      </div>
                    </div>
                  )}
                  {errorFileReject?.key === "selfPicture" ? (
                    <span className={Styles.error}>
                      *{errorFileReject?.message}
                    </span>
                  ) : null}
                </div>
                <div className={Styles.info}>
                  <Icon
                    icon={"information-solid"}
                    size={20}
                    color={"#1571DE"}
                  />
                  <span>
                    Mohon untuk mengisi Nomor Induk Kependudukan anda terlebih
                    dahulu{" "}
                  </span>
                </div>
                <div className={Styles.inputWrapper}>
                  {/* NIK */}
                  <div className={Styles.inputEndAdornment}>
                    <InputField
                      value={value?.personId}
                      title="Nomor Induk Kependudukan (16 Digit)"
                      requiredLabel="Wajib diisi"
                      placeholder="Nomor Induk Kependudukan"
                      onChange={(e) => {
                        if (e.target.value.length < 17) {
                          handleChange({
                            name: "personId",
                            value: e.target.value,
                          });
                        }
                      }}
                      required
                      isError={errorField && !value?.personId}
                      errorText="*NIK belum diisi"
                    />
                    {loadingPersonId && (
                      <button type="button" className={Styles.endAdornment}>
                        <Box sx={{ position: "relative" }}>
                          <CircularProgress
                            variant="determinate"
                            sx={{
                              color: (theme) =>
                                theme.palette.grey[
                                  theme.palette.mode === "light" ? 200 : 800
                                ],
                            }}
                            size={20}
                            thickness={4}
                            value={100}
                          />
                          <CircularProgress
                            variant="indeterminate"
                            disableShrink
                            sx={{
                              color: (theme) =>
                                theme.palette.mode === "light"
                                  ? "#1a90ff"
                                  : "#308fe8",
                              animationDuration: "550ms",
                              position: "absolute",
                              left: 0,
                              [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: "round",
                              },
                            }}
                            size={20}
                            thickness={4}
                          />
                        </Box>
                      </button>
                    )}
                  </div>
                  {/* Nomor HP */}
                  <InputField
                    type="phoneVerification"
                    value={value.phoneNumber}
                    title="Nomor Whatsapp"
                    placeholder=""
                    onChange={(e) =>
                      handleChange({
                        name: "phoneNumber",
                        value: e.target.value,
                      })
                    }
                    required
                    requiredLabel="Wajib diisi"
                    disabled={disablePhoneVerification}
                    onClick={onClickPhoneVerification}
                    isLoading={isVerifying}
                    isVerified={value.isPhoneVerified}
                    isError={
                      errorField &&
                      (!value?.phoneNumber || !value?.isPhoneVerified)
                    }
                    errorText={
                      value?.phoneNumber.length === 0
                        ? "*Nomor belum diisi"
                        : "*Nomor belum diverifikasi"
                    }
                  />

                  {/* Nama */}
                  <InputField
                    value={value?.name}
                    title="Nama (Sesuai KTP)"
                    placeholder="Nama sesuai KTP"
                    onChange={(e) =>
                      handleChange({
                        name: "name",
                        value: e.target.value.toUpperCase(),
                      })
                    }
                    required
                    requiredLabel="Wajib diisi"
                    disabled={disableInput}
                    isError={errorField && !value?.name}
                    errorText="*Nama belum diisi"
                  />

                  {/* Tanggal Lahir */}
                  <div
                    className={Styles.inputDateWrapper}
                    onClick={() =>
                      !disableInput && dateRef.current.showPicker()
                    }
                  >
                    <div className={Styles.title}>
                      <p>Tanggal Lahir</p>

                      <span className={Styles.redStar}>Wajib Diisi</span>
                    </div>
                    {/* {!interviewDate && <span className={Styles.placeholderReplacement}>Date</span>} */}
                    <input
                      ref={dateRef}
                      className={`${
                        dateRef?.current?.value ? "has-value" : "no-value"
                      }
                        ${
                          errorDate || (errorField && !value?.dateOfBirth)
                            ? Styles.error
                            : ""
                        } `}
                      value={value.dateOfBirth}
                      onChange={(e) => {
                        if (isUnder18(e.target.value)) {
                          // setErrorField(true);
                          setErrorDate("Minimal usia 18 tahun");
                          setValue((prev) => ({
                            ...prev,
                            dateOfBirth: "",
                          }));
                        } else {
                          setValue((prev) => ({
                            ...prev,
                            dateOfBirth: e.target.value,
                          }));
                          // setErrorField(false);
                          setErrorDate("");
                        }
                      }}
                      type="date"
                      placeholder="Tanggal lahir"
                      disabled={disableInput}
                    />

                    <button type="button" className={Styles.endAdornment}>
                      <Icon icon="calendar-v2" size={20} />
                    </button>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                        paddingTop: "2px",
                      }}
                    >
                      {errorDate ||
                      (errorField && !disableInput && !value?.dateOfBirth) ? (
                        <span className={Styles.errorText}>
                          {errorDate
                            ? `*${errorDate}`
                            : "*Tanggal lahir belum diisi"}
                        </span>
                      ) : null}

                      {/* {errorDate && (
                        <span className={Styles.errorText}>*{errorDate}</span>
                      )} */}
                    </div>
                  </div>

                  {/* jenis kelamin */}
                  <SelectOptions
                    title={"Jenis Kelamin"}
                    options={dropdownOptions.genders}
                    value={gender}
                    setValue={setGender}
                    placeholder={"Pilih salah satu"}
                    autoClose={true}
                    required
                    requiredLabel="Wajib diisi"
                    disabled={disableInput}
                    isError={errorField && !gender}
                    errorText="*Jenis kelamin belum diisi"
                  />

                  {/* Domisili */}
                  <CustomAutocompleteField
                    title={"Domisili (Tempat Tinggal Sekarang)"}
                    options={filteredDomiciles}
                    value={searchDomicile || ""}
                    setValue={setSearchDomicile}
                    placeholder={"Kota tempat tinggal"}
                    required
                    requiredLabel="Wajib diisi"
                    disabled={disableInput}
                    isError={errorField && !searchDomicile}
                    errorText="*Domisili belum diisi"
                  />

                  {/* Alamat */}
                  <InputField
                    type="desc"
                    value={value.address}
                    title="Alamat"
                    placeholder="Tulis alamat anda"
                    requiredLabel="Wajib diisi"
                    onChange={(e) =>
                      handleChange({
                        name: "address",
                        value: e.target.value
                          .replace(
                            // eslint-disable-next-line no-useless-escape
                            /[^0-9/\[\]\\_+,.@!`~#%$&*()={}|:;"'<,>?\-\n a-zA-Z]+/gi,
                            ""
                          )
                          .toUpperCase(),
                      })
                    }
                    required
                    disabled={disableInput}
                    isError={errorField && !value?.address}
                    errorText="*Alamat belum diisi"
                  />

                  {/* Email */}
                  <InputField
                    value={value.email}
                    title="Email"
                    placeholder="example@mail.com"
                    onChange={(e) =>
                      handleChange({
                        name: "email",
                        value: e.target.value?.toLowerCase()?.trim(),
                      })
                    }
                    disabled={disableInput}
                    isError={
                      errorField &&
                      (!value?.email || !isValidEmail(value?.email))
                    }
                    errorText="*Email belum diisi atau format tidak sesuai"
                  />

                  {/* Pendidikan Terakhir */}
                  <SelectOptions
                    title="Pendidikan Terakhir"
                    options={dropdownOptions.educations}
                    value={lastEducation}
                    setValue={setLastEducation}
                    placeholder="Pilih salah satu"
                    autoClose={true}
                    required
                    disabled={disableInput}
                    requiredLabel="Wajib diisi"
                    isError={errorField && !lastEducation}
                    errorText="*Pendidikan belum diisi"
                  />

                  {/* Pengalaman Terakhir */}
                  <InputWorkExperience
                    data={value.lastPosition}
                    selected={selectexperience}
                    setSelected={setSelectExperience}
                    setValue={setValue}
                    isError={errorField && selectexperience === "exist"}
                    errorText={"*Pengalaman perlu ditambahkan"}
                  />

                  {/* kemampuan berbahasa ingggris */}
                  <SelectOptions
                    title="Kemampuan Bahasa Inggris"
                    options={dropdownOptions.englishLevel}
                    value={englishLevel}
                    setValue={setEnglishLevel}
                    placeholder="Pilih salah satu"
                    autoClose={true}
                    required
                    disabled={disableInput}
                    requiredLabel="Wajib diisi"
                    isError={errorField && !englishLevel}
                    errorText="*Kemampuan bahasa belum diisi"
                  />

                  {/* Apakah Memiliki Kendaraan Sendiri */}
                  <SelectOptions
                    title="Apakah Memiliki Kendaraan Sendiri"
                    options={dropdownOptions.vehicles}
                    value={vehicle}
                    setValue={setVehicle}
                    placeholder="Pilih salah satu"
                    autoClose={true}
                    required
                    disabled={disableInput}
                    requiredLabel="Wajib diisi"
                    isError={errorField && !vehicle}
                    errorText="*Kendaraan belum diisi"
                  />

                  {/* CV */}
                  <div className={Styles.fileUploadSection}>
                    <div className={Styles.title}>
                      <p>Unggah CV</p>
                      <span className={Styles.redStar}> Wajib diisi</span>
                    </div>
                    <div className={Styles.boxDoc}>
                      <div className={Styles.title}>
                        Mohon unggah file dalam format PDF, JPG, JPEG atau PNG.
                      </div>

                      {errorFormat ? (
                        <span className={Styles.error}>*{errorFormat}</span>
                      ) : null}

                      {errorFileReject?.key === "cv" ? (
                        <span className={Styles.error}>
                          *{errorFileReject?.message}
                        </span>
                      ) : null}

                      {errorField && !value?.cv ? (
                        <span className={Styles.error}>
                          *File CV perlu di unggah
                        </span>
                      ) : null}

                      {value.cv?.name ||
                      value.cv?.url ||
                      sessionStorage.getItem("cvName") ? (
                        <div className={Styles.documentBox}>
                          <div className={Styles.documentFile}>
                            <Icon icon={"file"} size={40} />
                            <p>
                              {value.cv?.name ||
                                value.cv?.url.split("-").slice(1)[0] ||
                                sessionStorage.getItem("cvName")}
                            </p>
                          </div>
                          <div className={Styles.documentAction}>
                            <div onClick={handleClearCv}>
                              <p>Hapus File</p>
                            </div>
                            <div {...getRootProps()}>
                              <p>Ubah File</p>
                              <input
                                {...getInputProps()}
                                disabled={false}
                                type="file"
                                // ref={inputCvRef}
                                name="file"
                                // onChange={onFileChange}
                                hidden
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div {...getRootProps()} className={Styles.box}>
                          <UploadBox isPdf={false} />
                          <input
                            {...getInputProps()}
                            disabled={false}
                            type="file"
                            // ref={inputCvRef}
                            name="file"
                            // onChange={onFileChange}
                            hidden
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* optional */}
                  <div className={Styles.fileUploadWrapper}>
                    <p>Dokumen Opsional</p>
                    <div className={Styles.fileUploadSection}>
                      <div className={Styles.title}>
                        Unggah Dokumen Lainnya (Maksimal 30 MB).
                      </div>
                      {errorFileReject?.key === "optional" ? (
                        <span className={Styles.error}>
                          *{errorFileReject?.message}
                        </span>
                      ) : null}
                      <div
                        {...dropOptional.getRootProps()}
                        className={Styles.box}
                      >
                        <UploadBox isPdf={false} />
                        <input
                          {...dropOptional.getInputProps()}
                          disabled={false}
                          type="file"
                          // ref={inputOptionalRef}
                          name="optional"
                          // onChange={onOptionalChange}
                          hidden
                        />
                      </div>
                      <div className={Styles.cardWrapper}>
                        {value.optionalDocument.map((el, idx) => (
                          <div className={Styles.card} key={idx}>
                            <span>{el?.name}</span>
                            <button onClick={() => handleDeleteOptional(idx)}>
                              <Icon icon="cross-circle" size={18} />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Styles.checkboxWrapper}>
                  <div className={Styles.checkbox}>
                    <input
                      type="checkbox"
                      checked={checkbox}
                      onChange={(e) => setCheckbox(e.target.checked)}
                    />
                    <p>
                      Saya menyetujui{" "}
                      <span onClick={() => setPrivacyPolicy(true)}>
                        {" "}
                        Privacy Policy{" "}
                      </span>{" "}
                      PT Permata Indo Sejahtera
                    </p>
                  </div>
                </div>
                <div
                  className={`${Styles.errorWrapper} ${
                    error ? Styles.error : ""
                  }`}
                >
                  {error ===
                  "[WHATSAPP ERROR] User is not registered on WhatsApp."
                    ? "Nomor Whatsapp anda tidak terdaftar, mohon periksa kembali nomor anda"
                    : error}
                  *
                </div>
                {!isDone && (
                  <div className={Styles.buttonWrapper}>
                    <div className={Styles.button}>
                      <Button
                        title={isSubmitting ? "Mengirim Form..." : "Kirim Form"}
                        onClick={handleSubmit}
                        // disabled={disableSubmit}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={Styles.notFoundContainer}>
          <img src={Images.SORRY} alt="sorry" />
          <div className={Styles.textWrapper}>
            <p>Lamaran Tidak Tersedia</p>
            <p>
              Maaf lamaran sudah tidak tersedia, berikut rekomendasi pekerjaan
              yang cocok untukmu
            </p>
          </div>
          <div className={Styles.cardWrapper}>
            {jobPostRecomendations?.map((value) => (
              <div className={Styles.card}>
                <div className={Styles.content}>
                  <img src={value?.poster} alt="Poster" />
                  <div className={Styles.informationWrapper}>
                    <p className={Styles.subTitle}>{"Pekerjaan"}</p>
                    <p className={Styles.title}>{value.title}</p>
                    <div className={Styles.placementWrapper}>
                      <Icon
                        icon={"send-outline"}
                        color={"#1571DE"}
                        size={"16"}
                      />
                      <p className={Styles.textPlacement}>{value?.placement}</p>
                    </div>
                  </div>
                </div>
                <div className={Styles.buttonWrapper}>
                  <Button
                    title={"Lamar Sekarang"}
                    type={"Primary"}
                    onClick={() => (window.location.href = value?.link)}
                  />
                </div>
              </div>
            ))}
          </div>

          <div
            className={Styles.exploreWrapper}
            onClick={() => (window.location.href = "https://kerja365.id/")}
          >
            <span>{"Explore Pekerjaan Lainnnya"}</span>
            <Icon icon={"arrow-right"} size={"24px"} color={"#1571de"} />
          </div>
        </div>
      )}

      {isDone && (
        <DoneModal
          title={"Terkirim!"}
          description={"Lamaran berhasil dikirimkan"}
          show={isDone}
          doneButton={true}
          doneTitle={"Ok"}
          handleDone={() => setIsDone(false)}
        />
      )}
      {query.get("v") === "true" && (
        <ModalWrapper show={query.get("v") === "true"} forceCenter>
          <div
            style={{
              visibility: query.get("v") === "true" ? "visible" : "hidden",
            }}
          >
            <OTPConfirmationModal
              setIsDone={setIsDone}
              handleReset={handleReset}
              handleSubmit={
                // openValidateModal ? handleSubmitCheckIdOTP : handleSubmitOTP
                openValidateModal
                  ? handleSubmitCheckIdOTP
                  : handleSuccessPhoneVerification
              }
              data={tempValue.phoneNumber ? tempValue : value}
              errorApi={error}
              loading={isSubmitting}
            />
          </div>
        </ModalWrapper>
      )}

      {showModalTutorial && (
        <TutorialSelfieModal
          closeModal={() => setShowModalTutorial(false)}
          dropdownImage={dropdownImage}
          handleChangePhoto={handleChangePhoto}
          handleDone={() => setShowModalTutorial(false)}
        />
      )}

      {privacyPolicy && (
        <ModalWrapper show={privacyPolicy} forceCenter>
          <PrivacyPolicyModal handleClose={() => setPrivacyPolicy(false)} />
        </ModalWrapper>
      )}

      {showError && (
        <ErrorPortalModal
          title={"Error!"}
          error={error}
          show={showError}
          handleDone={() => {
            setShowError(false);
            setError(null);
          }}
        />
      )}

      {isBlacklistError && (
        <ErrorSorryModal
          title={"Maaf..."}
          error={error}
          show={isBlacklistError}
          handleDone={() => {
            setIsBlacklistError(false);
            setError(null);
          }}
        />
      )}

      <div
        className={`${Styles.assistButton} ${
          syncData?.jobPost?.status === "Publish" || !syncData.jobPost
            ? Styles.hideOnMobile
            : ""
        }`}
        onClick={() => window.open("https://wa.me/6287840101425")}
      >
        <img src={Images.WHATSAPP_LOGO} alt="wa-logo" />
        <p>Butuh bantuan ?</p>
      </div>
    </div>
  );
}
