import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useCallback, useEffect, useState } from "react";
import { getQualificationFulfillment } from "@Services/manager/bm";
import useQuery from "@Hooks/useQuery";
import LoadingAnimation from "@Atom/LoadingAnimation";
// import { EmptyPlaceholder } from "@Organism/Additional/RenewData/Third";
import Images from "@Theme/Images";

export default function QualificationModal({ data, handleClose }) {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [dataFetch, setDataFetch] = useState(null);
  const query = useQuery();

  const fetchData = useCallback(async () => {
    try {
      setIsLoadingData(true);
      const { response } = await getQualificationFulfillment(
        data?.position_name,
        query.get("c")
      );
      setDataFetch(response);
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log(err, "error on fetching data");
    }
  }, [query, data]);

  useEffect(() => {
    if (data) {
      fetchData();
    }
  }, [data, fetchData]);

  return (
    <div className={Styles.containerModal}>
      <div className={Styles.header}>
        <span>Daftar Kualifikasi - {data?.position_name || "-"}</span>
      </div>

      {isLoadingData ? (
        <div
          style={{
            height: "300px",
            display: "grid",
            placeItems: "center",
          }}
        >
          <LoadingAnimation />
        </div>
      ) : dataFetch?.qualifications?.length > 0 ? (
        <div className={Styles.listBox}>
          {dataFetch?.qualifications?.map((qualification, idx) => {
            return (
              <div key={idx}>
                <div className={Styles.icon}>
                  <Icon icon={"triangle-point"} size={16} color={"#125EB9"} />
                </div>
                <span>
                  {qualification?.title || "-"} : {qualification?.value || "-"}
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={Styles.notFoundBox}>
          <div>
            <img src={Images.RAFIKI_2} alt="" />
          </div>
          <span>Data Kualifikasi tidak ditemukan</span>
        </div>
      )}

      <div className={Styles.buttonSection}>
        <span onClick={handleClose}>Tutup</span>
      </div>
    </div>
  );
}
