import Button from "@Atom/Button";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import { useDebounce } from "@Hooks/useDebounce";
import Pagination from "@Molecule/Pagination";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
// import { getCandidateSync } from "@Services/officer/recruitment";
import SearchBar from "@Atom/SearchBar";
import { getCandidatesSync } from "@Services/support/candidate";

export default function CandidatesTableSet({
  type,
  data,
  totalPage = 0,
  columns,
  value = 10,
  setValue = () => {},
  selectedPage = 1,
  setSelectedPage = () => {},
  activeController,
  setActiveController = () => {},
  searchTerm = "",
  setSearchTerm = () => {},
  setStatus,
  isFetchingData,
  valueIdCandiate,
  setValueIdCandidate,
  totalData,
}) {
  // const [activeController, setActiveController] = useState(null)

  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [searchName, setSearchName] = useState("");
  const debouncedSearchName = useDebounce(searchName || "", 300);
  const [dataSync, setDataSync] = useState(null);
  const [isLoadingSync, setIsLoadingSync] = useState(false);

  const refetchDataSync = useCallback(async () => {
    try {
      setIsLoadingSync(true);
      const { response } = await getCandidatesSync(debouncedSearchName || "?");
      setDataSync(response);
      setIsLoadingSync(false);
    } catch (err) {
      setIsLoadingSync(false);
      console.log(err);
    }
  }, [debouncedSearchName]);

  useEffect(() => {
    refetchDataSync();
  }, [refetchDataSync]);

  const candidatesControllerOptions = useMemo(() => {
    return [
      {
        name: "Verifikasi Pertama",
        value: "Verifikasi Pertama",
      },
      {
        name: "Generate Kontrak",
        value: "Generate Kontrak",
      },
      {
        name: "TTD Kontrak",
        value: "TTD Kontrak",
      },
      {
        name: "Verifikasi Kedua",
        value: "Verifikasi Kedua",
      },
      {
        name: "Joined",
        value: "Joined",
      },
    ];
  }, []);

  const template = useMemo(() => {
    switch (type) {
      case "candidates":
        return {
          title: "Candidate",
          extraController: (
            <div className={Styles.controllerWrapper}>
              {candidatesControllerOptions?.map((el, i) => (
                <Button
                  key={i}
                  type={"candidate-tab"}
                  title={el?.name}
                  isActive={activeController === el?.value}
                  onClick={() => {
                    setActiveController(el?.value);
                    setSelectedPage(1);
                  }}
                />
              ))}
            </div>
          ),
        };
      case "hired":
        return {
          title: "Last 50 Hired",
          extraController: undefined,
        };
      default:
        return {
          title: "Candidate",
          extraController: undefined,
        };
    }
  }, [
    type,
    candidatesControllerOptions,
    activeController,
    setActiveController,
    setSelectedPage,
  ]);

  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef();

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  const navigate = useNavigate();

  const onClickOption = (status, stage, tag, jobType, obj, name) => {
    if (
      (status === "BS-REGISTERED" && stage === "REGISTER") ||
      (status === "BS-REGISTERED" &&
        tag === "Declined" &&
        stage === "FIRST-VERIFICATION") ||
      (status?.includes("BS-REGISTERED-HOLD") && stage === "REGISTER") ||
      (status?.includes("BS-REGISTERED-HOLD") &&
        tag === "Declined" &&
        stage === "FIRST-VERIFICATION") ||
      (status?.includes("BS-REGISTERED-CONTRACTED") && stage === "REGISTER") ||
      (status?.includes("BS-REGISTERED-CONTRACTED") &&
        tag === "Declined" &&
        stage === "FIRST-VERIFICATION")
    ) {
      setActiveController("Verifikasi Pertama");
    } else if (
      (status === "BS-PRE-CONTRACT" &&
        stage === "GENERATE-CONTRACT" &&
        tag !== "Declined") ||
      (status === "BS-PRE-CONTRACT" &&
        tag === "Declined" &&
        stage === "GENERATE-CONTRACT") ||
      (status === "BS-PRE-CONTRACT" && stage === "APPROVE-CONTRACT") ||
      (status?.includes("BS-PRE-CONTRACT-HOLD") &&
        stage === "GENERATE-CONTRACT") ||
      (status?.includes("BS-PRE-CONTRACT-HOLD") &&
        tag === "Declined" &&
        stage === "GENERATE-CONTRACT") ||
      (status?.includes("BS-PRE-CONTRACT-HOLD") &&
        stage === "APPROVE-CONTRACT") ||
      (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
        stage === "GENERATE-CONTRACT") ||
      (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
        tag === "Declined" &&
        stage === "GENERATE-CONTRACT") ||
      (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
        stage === "APPROVE-CONTRACT")
    ) {
      setActiveController("Generate Kontrak");
    } else if (
      (status === "BS-PRE-CONTRACT" &&
        stage === "SIGN-CONTRACT" &&
        tag === "Invited") ||
      (status?.includes("BS-PRE-CONTRACT-HOLD") &&
        stage === "SIGN-CONTRACT" &&
        tag === "Invited") ||
      (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
        stage === "SIGN-CONTRACT" &&
        tag === "Invited")
    ) {
      setActiveController("TTD Kontrak");
    } else if (
      (status === "BS-PRE-CONTRACT" &&
        stage === "SIGN-CONTRACT" &&
        tag === "Accepted") ||
      (status === "BS-PRE-CONTRACT" &&
        tag === "Declined" &&
        stage === "SECOND-VERIFICATION") ||
      (status === "BS-CONTRACT" && stage === "DONE") ||
      (status?.includes("BS-PRE-CONTRACT-HOLD") &&
        stage === "SIGN-CONTRACT" &&
        tag === "Accepted") ||
      (status?.includes("BS-PRE-CONTRACT-HOLD") &&
        tag === "Declined" &&
        stage === "SECOND-VERIFICATION") ||
      (status?.includes("BS-CONTRACT-HOLD") && stage === "DONE") ||
      (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
        stage === "SIGN-CONTRACT" &&
        tag === "Accepted") ||
      (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
        tag === "Declined" &&
        stage === "SECOND-VERIFICATION") ||
      (status?.includes("BS-CONTRACT-CONTRACTED") && stage === "DONE")
    ) {
      setActiveController("Verifikasi Kedua");
    } else if (stage === "IOS-SUCCESS") {
      setActiveController("Joined");
    }
  };

  return (
    <div className={Styles.container}>
      <div ref={headerRef} className={Styles.header}>
        <div className={Styles.mainHeader}>
          <h4>{template?.title}</h4>
          {type === "candidates" ? (
            <div className={Styles.searchbar}>
              <SearchBar
                placeholder={"Cari Nama Kandidat"}
                value={searchName}
                onChange={setSearchName}
                isRecruitment={true}
                isBS={true}
                options={dataSync?.candidates || []}
                type={"none"}
                isLoadingFilter={isLoadingSync}
                onClickOption={onClickOption}
                setValueIdCandidate={setValueIdCandidate}
                valueIdCandidate={valueIdCandiate}
              />
            </div>
          ) : (
            <div className={Styles.searchbar}>
              <SearchBar
                value={searchTerm}
                onChange={(e) => setSearchTerm(e?.target?.value)}
                placeholder={"Search by name"}
                // type={"none"}
                isWhite={true}
              />
            </div>
          )}

          {type === "candidates" && (
            <button onClick={() => navigate("/candidates")}>View All</button>
          )}
        </div>
        {template?.extraController}
        <div
          className={Styles.filler}
          style={{ position: "absolute", top: `${headerHeight}px`, left: 0 }}
        />
      </div>
      <div className={Styles.content}>
        <table>
          <thead style={{ position: "sticky", top: `${headerHeight + 2}px` }}>
            <tr>
              {columns?.map((column) => (
                <th
                  key={column?.name}
                  style={{
                    textAlign: column?.align || "left",
                    minWidth: column?.minWidth || "auto",
                    maxWidth: column?.maxWidth || "auto",
                  }}
                  className={`${column?.thClassName || ""} ${
                    column?.hideOnMobile ? Styles.hideOnMobile : ""
                  } ${column?.showOnMobile ? Styles.showOnMobile : ""}`}
                >
                  {column?.renderLabel ? (
                    column?.renderLabel(column)
                  ) : (
                    <span>{column?.label}</span>
                  )}
                  <div className={Styles.borderBottom} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 &&
              !isFetchingData &&
              data?.map((row, i) => (
                <tr key={i}>
                  {columns?.map((column) => (
                    <td
                      key={column?.name}
                      className={`${column?.tdClassName || ""} ${
                        column?.hideOnMobile ? Styles.hideOnMobile : ""
                      } ${column?.showOnMobile ? Styles.showOnMobile : ""}`}
                    >
                      {column?.renderData
                        ? column?.renderData(row)
                        : row?.[column?.name] || "-"}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {data?.length === 0 || isFetchingData ? (
        <TableEmptyHandler
          title={isFetchingData ? "Tunggu sebentar" : "Data Kosong"}
          desc={
            isFetchingData
              ? "Data sedang dimuat"
              : "Tidak ada data atau data belum ada"
          }
          // isV2={!!isContractList}
        />
      ) : null}
      <div className={Styles.paginationWrapper}>
        <Pagination
          total={totalData || 0}
          totalPage={totalPage || 0}
          value={value}
          setValue={setValue}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          siblingCount={0}
          bgColor={"#fafafa"}
          // isV2
        />
        <div
          className={Styles.filler}
          style={{
            position: "absolute",
            bottom: "-1px",
            left: 0,
            border: "none",
          }}
        />
      </div>
    </div>
  );
}
