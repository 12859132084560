import Dropdown from "@Atom/DropDown";
import ToggleButton from "@Atom/ToggleButton";
import Styles from "./selectOptionsToggle.module.scss";

const SelectOptionsToggle = ({
  title,
  placeholder,
  options,
  withToggle,
  toggleValue,
  setToggleValue=() => {},
  value,
  setValue,
  autoClose,
  iconColor,
  required,
  isError = false,
  isErrorV2 = false,
  errorText = '',
  ...props
}) => {

  return (
    <div className={Styles.container}>
      <div className={`${Styles.header} ${!withToggle && Styles.noToggle}`}>
        <div className={Styles.title}>{title}{required && <span className={Styles.asterisk}>&#x2a;</span>}</div>
        {withToggle && (
          <ToggleButton
            defaultTrue={toggleValue || value ? true : false}
            setTrue={(newVal) => setToggleValue(!newVal)}
          />
        )}
      </div>
      <div className={Styles.dropdownContainer}>
        <Dropdown
          placeholder={placeholder}
          disabled={withToggle ? !toggleValue : false}
          options={options}
          value={value}
          setValue={setValue}
          autoClose={autoClose}
          iconColor={iconColor}
          isError={isError}
          isErrorV2={isErrorV2}
          errorText={errorText}
        />
      </div>
    </div>
  );
};

export default SelectOptionsToggle;
