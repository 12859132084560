import React from "react";
import Styles from "./style.module.scss";
import RegionalSection from "./RegionalSection";

const ManPowerSection = ({ manPower, remark, cities, ...props }) => {
  return (
    <div className={Styles.container}>
      {manPower.map((data, id) => (
        <RegionalSection data={data} key={id} cities={cities} />
      ))}
      <div className={Styles.remarkWrapper}>
        <div className={Styles.remarkTitleText}>Remark</div>
        <div className={Styles.remark}>
          <div className={Styles.descriptionTitleText}>Deskripsi</div>
          <div className={Styles.descriptionContentText}>{remark}</div>
        </div>
      </div>
    </div>
  );
};

export default ManPowerSection;
