/* eslint-disable no-unused-vars */
import UnderMaintenacePage from "@Organism/Utils/UnderMaintenance";
import {
  getClientDashboardDetail,
  getClientDashboardSync,
  getPositionsSummary,
  getProgressBar,
  getQualificationSync,
} from "@Services/client/dashboard";
import {
  getCandidates,
  getLatestCandidates,
} from "@Services/support/candidate";
import { useMemo } from "react";
import { defer } from "react-router-dom";
import BusinessSupportDashboard from "../BusinessSupport/Dashboard";
import NewClientDashboard from "../Client/NewDashboard";
import BMDashboardPage from "../Manager/Dashboard";
import {
  getBMClients,
  getBMDashboardSync,
  getBMList,
  getRBMClients,
} from "@Services/manager/bm";

export async function loader(route) {
  const isClient = localStorage.getItem("isClient");
  const clientCode = localStorage.getItem("clientCode");
  // const isClient = sessionStorage.getItem("isClient");
  // const clientCode = sessionStorage.getItem("clientCode");

  const url = new URL(route?.request?.url);
  const status = url.searchParams.get("s") || "";
  const stage = url.searchParams.get("st") || "";
  const limit = url.searchParams.get("l") || 10;
  const page = url.searchParams.get("p") || 1;
  const search = url.searchParams.get("n") || "";
  const limit2 = url.searchParams.get("l2") || 10;
  const page2 = url.searchParams.get("p2") || 1;
  const search2 = url.searchParams.get("n2") || "";
  const type = url.searchParams.get("t") || "1";

  if (isClient === "true") {
    return defer({
      packageDashboardSync: getClientDashboardSync(localStorage?.getItem('clientCode')),
    });
    // return defer({
    //   packageProgressBar: getProgressBar(),
    //   packageSummary: getPositionsSummary(),
    //   packageSync: getQualificationSync(),
    // });
  } else {
    if (localStorage.getItem("role") === "BUSINESS SUPPORT") {
      const packageCandidateListPromise = getCandidates(
        limit,
        page,
        status,
        stage,
        search
      );
      const packageLatesCandidatesPromise = getLatestCandidates(search2);

      return defer({
        packageCandidateList: packageCandidateListPromise,
        packageLatestCandidates: packageLatesCandidatesPromise,
      });
    } else if (
      localStorage.getItem("role")?.includes("BUSINESS MANAGER") ||
      localStorage.getItem("role") === "BUSINESS DIRECTOR"
    ) {
      return defer({
        packageDashboardSync: getBMDashboardSync(
          route?.params?.id,
          "",
          "",
          localStorage.getItem("role")?.includes("REGION")
        ),
        packageBM: getBMList(page, limit, search),
        packageClients: getRBMClients(
          page2,
          limit2,
          search2,
          localStorage.getItem("role")?.includes("REGION")
        ),
      });
    } else {
      return null;
    }
  }
}

export default function Dashboard() {
  const isClient = localStorage.getItem("isClient");
  const role = localStorage.getItem("role");
  // const isClient = sessionStorage.getItem("isClient")
  // const role = sessionStorage.getItem("role")

  const nonClientDashboard = useMemo(() => {
    switch (role) {
      case "BUSINESS SUPPORT":
        return <BusinessSupportDashboard />;
      case "REGION BUSINESS MANAGER":
        return <BMDashboardPage rbmView />;
      case "BUSINESS MANAGER":
        return <BMDashboardPage />;
      case "BUSINESS DIRECTOR":
        return <BMDashboardPage />;
      default:
        return <UnderMaintenacePage />;
    }
  }, [role]);

  switch (isClient) {
    case "true":
      return <NewClientDashboard />;
    default:
      return nonClientDashboard;
  }
}
