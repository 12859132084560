import { useMemo } from "react";
import Styles from "./style.module.scss";

export default function CandidateStatusIndicator({ type }) {
  const template = useMemo(() => {
    switch (type) {
      case "new":
        return {
          className: Styles.new,
          text: "New",
        };
      case "process":
        return {
          className: Styles.process,
          text: "Process",
        };
      case "hired":
        return {
          className: Styles.hired,
          text: "Hired",
        };
      case "decline":
        return {
          className: Styles.decline,
          text: "Decline",
        };
      case "active":
        return {
          className: Styles.active,
          text: "Active",
        };
      case "inactive":
        return {
          className: Styles.inactive,
          text: "Not Active",
        };
      case "Hold":
        return {
          className: Styles.hold,
          text: "Hold",
        };

      case "Contracted":
        return {
          className: Styles.contracted,
          text: "Contracted",
        };

      case "Menunggu Verifikasi":
        return {
          className: Styles.verification,
          text: "Menunggu Verifikasi",
        };

      case "Perbaikan Data":
        return {
          className: Styles.revisionOffer,
          text: "Perbaikan Data",
        };

      case "Belum Generate":
        return {
          className: Styles.generate,
          text: "Belum Generate",
        };
      case "Perbaikan Kontrak":
        return {
          className: Styles.revisionContract,
          text: "Perbaikan Kontrak",
        };
      case "Menunggu Approval BM":
        return {
          className: Styles.waitingApproval,
          text: "Menunggu Approval BM",
        };
      case "Gagal Inject":
        return {
          className: Styles.gagalInject,
          text: "Gagal Inject",
        };
      default:
        return {
          className: Styles.new,
          text: "New",
        };
    }
  }, [type]);
  return (
    <div className={`${Styles.container} ${template?.className}`}>
      {template?.text}
    </div>
  );
}
