import Navbar from "@Molecule/Navbar";
import BSNavbar from "@Molecule/BSNavbar";
import HCNavbar from "@Molecule/HCNavbar";
import { Outlet } from "react-router-dom";

export default function Auth(props) {
  const { role } = props;

  if (role === "BUSINESS SUPPORT") {
    return (
      <BSNavbar>
        <Outlet />
      </BSNavbar>
    );
  }
  if (role === "HUMAN CAPITAL MANAGER") {
    return (
      <HCNavbar>
        <Outlet />
      </HCNavbar>
    );
  }
  return (
    <Navbar role={role}>
      <Outlet />
    </Navbar>
  );
}
