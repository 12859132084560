import LoadingAnimation from '@Atom/LoadingAnimation'
import Styles from './style.module.scss'

export default function LoadingAnimationPage({ height = '100vh' }) {

  return (
    <div style={{ height }} className={Styles.container}>
      <LoadingAnimation />
    </div>
  )
}