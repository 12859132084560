import SearchBar from "@Atom/SearchBar";
import Pagination from "@Molecule/Pagination";
import { Table, TableContainer } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import EmptyHandler from "./EmptyHandler";
import TableData from "./TableData";
import TableHeader from "./TableHeader";
import Styles from './style.module.scss';
import debounce from "lodash.debounce";

const CandidateListSection = ({
  setCValue,
  setSelectedCPage,
  data,
  setSelectedCandidate,
  setCName
}) => {
  const [value, setValue] = useState(10);
  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('')


  useEffect(() => {
    setCValue(value)
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    setSelectedCPage(selectedPage)
    // eslint-disable-next-line
  }, [selectedPage])

  const onChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const debounceChange = useMemo(() => debounce(()=> {setCName(searchTerm)}, 500), [searchTerm, setCName])

  useEffect(()=> {
    debounceChange()
  }, [debounceChange])


  // const boxRef = useRef();
  // const boxOutsideClick = UseOutsideClick(boxRef);

  // useEffect(() => {
  //   if (selectedCandidate !== null) {
  //     if (boxOutsideClick === false) {
  //       setSelectedCandidate(null);
  //     }
  //   }
  // }, [boxOutsideClick, selectedCandidate]);

  return (
    <div className={Styles.container}>
      <div className={Styles.sectionTitle}>Candidate List</div>
      <div className={Styles.tableWrapper}>
        <div className={Styles.searchbarSection}>
          <div className={Styles.searchbarWrapper}>
            <SearchBar
              value={searchTerm}
              onChange={onChange}
              placeholder='Search employee name'
            />
          </div>
        </div>
        <TableContainer>
          <Table className={Styles.table}>
            <TableHeader />
            {data?.candidates?.data?.length > 0 && <TableData data={data?.candidates?.data} setSelectedEmployee={setSelectedCandidate} />}
          </Table>
        </TableContainer>
        {(data?.candidates?.data?.length < 1 || !data?.candidates) && <EmptyHandler />}
        <div className={Styles.PaginationWrapper}>
          <Pagination
            total={data?.candidates?.data?.length || 0}
            totalPage={data?.candidates?.totalPage || 0}
            value={value}
            setValue={setValue}
            pages={pages}
            setPages={setPages}
            pageList={pageList}
            setPageList={setPageList}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
        </div>
      </div>

    </div>
  );
};

export default CandidateListSection;
