/* eslint-disable no-unused-vars */
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import Textarea from "@Atom/Textarea";
import { capitalizedFirstLetter } from "@Helpers/capitalizedFirstLetter";
import { makeRandomString } from "@Helpers/makeRandomString";
import { minDate } from "@Helpers/minDate";
import { titleCase } from "@Helpers/titleCase";
import { useDebounce } from "@Hooks/useDebounce";
import UseOutsideClick from "@Hooks/useOutsideClick";
import CustomTable from "@Molecule/CustomTable";
import Pagination from "@Molecule/Pagination";
import Stepper from "@Molecule/Stepper";
import {
  getTransferSync,
  sendInvitation,
  transferCandidate,
} from "@Services/officer/recruitment";
import Images from "@Theme/Images";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CandidateResumeLayout from "../CandidateResumeLayout";
import Styles from "./style.module.scss";
import Switch from "@Atom/Switch";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import TableEmptyHandler from "@Atom/TableEmptyHandler";

const historyDataTemplate = [
  {
    date: new Date().getTime() - 3 * 60 * 60 * 60 * 1000,
    note: "kandidat tidak memenuhi kualifikasi yang dibutuhkan",
    name: "Agnes",
    position: "Admin",
    status: "Unqualified",
  },
  {
    date: new Date() - 2 * 60 * 60 * 60 * 1000,
    note: "Kandidat Tidak Memiliki Pengalaman",
    name: "Agnes",
    status: "Tidak Lolos",
    position: "Admin",
  },
];

function InvitationLayout({
  invitationSyncData,
  data,
  setTrigger,
  handleClose,
  setIsSuccess,
}) {
  const [expandMethod, setExpandMethod] = useState(false);
  // const [expandRO, setExpandRO] = useState(false)
  const [expandAddress, setExpandAddress] = useState(false);
  const dateRef = useRef();
  const timeRef = useRef();

  // const [roSearch, setRoSearch] = useState('')
  const [addressSearch, setAddressSearch] = useState("");

  const [buildingName, setBuildingName] = useState("");
  const [buildingAddress, setBuildingAddress] = useState("");

  const [interviewMethod, setInterviewMethod] = useState("");
  const [interviewDate, setInterviewDate] = useState("");
  const [interviewTime, setInterviewTime] = useState("");
  const [remark, setRemark] = useState("");

  const [submitting, setSubmitting] = useState(false);

  const [link, setLink] = useState("");

  const [error, setError] = useState("");

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setSubmitting(true);
      await sendInvitation({
        candidateID: data?.id,
        method: interviewMethod,
        date: moment(new Date(interviewDate)).format("YYYY-MM-DD"),
        recruiterID: invitationSyncData?.recruitmentOfficer?.employeeId,
        via:
          interviewMethod?.toLowerCase() === "offline"
            ? addressSearch?.toLowerCase()?.includes("custom")
              ? `${buildingName}::${buildingAddress}`
              : locationOptions?.find((obj) =>
                obj?.toLowerCase()?.includes(addressSearch?.toLowerCase())
              )
            : link,
        addressType: addressSearch?.toLowerCase()?.includes("custom")
          ? "CUSTOM"
          : "NON-CUSTOM",
        time: interviewTime?.replace(":", "."),
        notes: remark,
        ordinal: 1,
      });
      setSubmitting(false);
      setTrigger(Math.random());
      setIsSuccess(true);
      handleClose();
    } catch (err) {
      setSubmitting(false);
      const errorText = err?.response?.data?.error;
      if (errorText?.toLowerCase()?.includes("fill all field")) {
        setError("Check inputted data and make sure to fill all the fields");
      } else {
        setError(errorText);
      }
    }
  };

  const ableSubmit = useMemo(() => {
    if (
      interviewMethod &&
      interviewDate &&
      interviewTime
      //  &&
      // roSearch
    ) {
      if (interviewMethod?.toLowerCase() === "online") {
        if (link) {
          return true;
        } else {
          return false;
        }
      } else {
        if (addressSearch) {
          return true;
        } else {
          return false;
        }
      }
    }

    return false;
  }, [
    interviewMethod,
    interviewDate,
    interviewTime,
    link,
    addressSearch,
    // roSearch
  ]);

  const methodRef = useRef();
  // const roRef = useRef();
  const addressRef = useRef();

  const backupAddressRef = useRef();

  const outsideMethodClick = UseOutsideClick(methodRef);
  // const outsideInterviewerClick = UseOutsideClick(interviewerRef)
  const outsideAddressClick = UseOutsideClick(addressRef);

  useEffect(() => {
    if (expandMethod && outsideMethodClick) {
      setExpandMethod(false);
    }
  }, [expandMethod, outsideMethodClick]);

  useEffect(() => {
    if (expandAddress && outsideAddressClick) {
      setExpandAddress(false);
    }
  }, [expandAddress, outsideAddressClick]);

  const locationOptions = useMemo(() => {
    if (invitationSyncData) {
      return invitationSyncData?.dropdownOptions
        ?.filter((obj) =>
          obj?.type?.toLowerCase()?.includes("interview address")
        )
        ?.map((obj) => obj?.value);
    }

    return [];
  }, [invitationSyncData]);

  const interviewOptions = useMemo(() => {
    if (invitationSyncData) {
      return invitationSyncData?.dropdownOptions
        ?.filter((obj) => obj?.type?.toLowerCase() === "interview method")
        ?.map((obj) => obj?.value);
    }

    return [];
  }, [invitationSyncData]);

  const filteredAddress = useMemo(() => {
    return [
      ...locationOptions
        ?.map((obj) => obj?.split("::")[0])
        ?.filter((el) =>
          el?.toLowerCase()?.includes(addressSearch?.toLowerCase())
        ),
      "Custom Address",
    ];
  }, [locationOptions, addressSearch]);

  return (
    <div className={Styles.inviteContainer}>
      <div className={Styles.intro}>
        <div className={Styles.stackedIntro}>
          <div>
            <img src={Images.INVITE_USER} alt="name" />
          </div>
          <div>
            <span>Candidate</span>
            <span>{capitalizedFirstLetter(data?.name)}</span>
          </div>
        </div>
        <div className={Styles.stackedIntro}>
          <div>
            <img src={Images.INVITE_JOB} alt="name" />
          </div>
          <div>
            <span>Job</span>
            <span>{data?.ExpectedPosition?.name}</span>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={Styles.section}>
          <div className={Styles.boxForm}>
            <label>Method</label>
            <div
              ref={methodRef}
              onClick={() => setExpandMethod(!expandMethod)}
              className={`${Styles.selectWrapper} ${expandMethod ? Styles.active : ""
                }`}
            >
              <span className={`${interviewMethod ? Styles.filled : ""}`}>
                {interviewMethod
                  ? interviewMethod === "Offline"
                    ? "Offline Interview (office)"
                    : "Online Interview"
                  : "Interview method"}
              </span>
              <button
                onClick={() => setExpandMethod(!expandMethod)}
                type="button"
                className={Styles.expandButton}
              >
                <Icon
                  icon={expandMethod ? "arrow-up" : "arrow-down"}
                  size={20}
                />
              </button>
              {expandMethod && (
                <div className={Styles.optionsWrapper}>
                  {interviewOptions?.map((option, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        setInterviewMethod(option);
                        setExpandMethod(false);
                      }}
                    >
                      {option}
                    </div>
                  ))}
                  {!interviewOptions?.length && <div>no option</div>}
                </div>
              )}
            </div>
          </div>
          <div className={Styles.boxForm}>
            <label>Date & Time</label>
            <div className={Styles.stacked}>
              <div className={Styles.inputWrapper}>
                {/* {!interviewDate && <span className={Styles.placeholderReplacement}>Date</span>} */}
                <input
                  ref={dateRef}
                  className={`${dateRef?.current?.value ? "has-value" : "no-value"
                    } ${"with-min"}`}
                  min={minDate(new Date())}
                  value={interviewDate}
                  onChange={(e) => setInterviewDate(e.target.value)}
                  type="date"
                  placeholder="Date"
                />

                <button
                  onClick={() => dateRef.current.showPicker()}
                  type="button"
                  className={Styles.endAdornment}
                >
                  <Icon icon="calendar" size={20} />
                </button>
              </div>
              <div className={Styles.inputWrapper}>
                <input
                  className={`${!interviewTime ? Styles.empty : ""}`}
                  ref={timeRef}
                  type="time"
                  value={interviewTime}
                  onChange={(e) => setInterviewTime(e.target.value)}
                />
                <button
                  onClick={() => timeRef.current.showPicker()}
                  type="button"
                  className={Styles.endAdornment}
                >
                  <Icon icon="time" size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={`${Styles.section}`}>
        <div className={Styles.boxForm}>
          <label>Recruitment Officer</label>
          <div ref={roRef} onClick={() => setExpandRO(true)} className={`${Styles.selectWrapper} ${expandRO ? Styles.active : ''}`}>
            <img src={roProfilePicture || Images?.AVA_DEFAULT} alt="pp" />
            <input
              value={roSearch}
              onChange={(e) => {
                setExpandRO(true)
                setRoSearch(e?.target?.value)
              }}
              placeholder="Select recruitment officer"
            />
            {(expandRO && roSearch) && (
              <button onClick={(e) => {
                e.stopPropagation()
                setRoSearch('')
              }
              } type="button" className={Styles.clearButton}>
                <Icon icon={'cross'} size={15} />
              </button>
            )}

            <button onClick={(e) => {
              e.stopPropagation()
              setExpandRO(!expandRO)
            }
            } type="button" className={Styles.expandButton}>
              <Icon icon={expandRO ? 'arrow-up' : 'arrow-down'} size={20} />
            </button>
            {
              expandRO && (
                <div className={Styles.optionsWrapper}>
                  {filteredRO?.length ? filteredRO?.map((ro, i) => (
                    <div key={i} onClick={(e) => {
                      e?.preventDefault()
                      e?.stopPropagation()
                      setExpandRO(false)
                      setRoSearch(ro?.name)
                    }}>
                      <img src={ro?.profilePicture ? (ro?.profilePicture?.toLowerCase()?.includes('http') ? ro?.profilePicture : fileBaseUrl + ro?.profilePicture) : Images?.AVA_DEFAULT} alt={ro?.name} />
                      <span>{ro?.name}</span>
                    </div>
                  )) : (
                    <div>
                      no option
                    </div>
                  )}
                </div>
              )
            }
          </div>
        </div>
      </div> */}
        {interviewMethod && (
          <div
            ref={expandAddress ? backupAddressRef : addressRef}
            className={`${Styles.section} ${Styles.smallerGap} ${Styles.address}`}
          >
            {interviewMethod &&
              (interviewMethod?.toLowerCase()?.includes("offline") ? (
                <div className={Styles.boxForm}>
                  <label>Address</label>
                  <div
                    ref={expandAddress ? addressRef : backupAddressRef}
                    onClick={() => setExpandAddress(true)}
                    className={`${Styles.selectWrapper} ${expandAddress ? Styles.active : ""
                      }`}
                  >
                    <input
                      value={addressSearch}
                      onChange={(e) => {
                        setExpandAddress(true);
                        setAddressSearch(e?.target?.value);
                      }}
                      placeholder="Select location for interview"
                    />
                    {expandAddress && addressSearch && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setAddressSearch("");
                        }}
                        type="button"
                        className={Styles.clearButton}
                      >
                        <Icon icon={"cross"} size={15} />
                      </button>
                    )}

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setExpandAddress(!expandAddress);
                      }}
                      type="button"
                      className={Styles.expandButton}
                    >
                      <Icon
                        icon={expandAddress ? "arrow-up" : "arrow-down"}
                        size={20}
                      />
                    </button>
                    {expandAddress && (
                      <div className={Styles.optionsWrapper}>
                        {filteredAddress?.length ? (
                          filteredAddress?.map((address, i) => (
                            <div
                              key={i}
                              onClick={(e) => {
                                e?.preventDefault();
                                e?.stopPropagation();
                                setExpandAddress(false);
                                setAddressSearch(address);
                              }}
                            >
                              <span>{address}</span>
                            </div>
                          ))
                        ) : (
                          <div>no option</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={Styles.boxForm}>
                  <label>Link</label>
                  <div className={Styles.stacked}>
                    <div className={Styles.inputWrapper}>
                      <input
                        type="text"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        placeholder="Paste link here"
                      />
                    </div>
                  </div>
                </div>
              ))}
            {interviewMethod?.toLowerCase()?.includes("offline") &&
              addressSearch?.toLowerCase()?.includes("custom") && (
                <div className={Styles.addressWrapper}>
                  <div className={Styles.divider} />
                  <Input
                    value={buildingName}
                    onChange={(e) => setBuildingName(e?.target?.value)}
                    placeholder={
                      "Nama tempat, contoh : Gedung Permata Indonesia"
                    }
                  />
                  <Textarea
                    value={buildingAddress}
                    onChange={(e) => setBuildingAddress(e?.target?.value)}
                    placeholder={"Alamat"}
                  />
                </div>
              )}
          </div>
        )}

        <div className={Styles.section}>
          <div className={Styles.boxForm}>
            <label htmlFor="">Catatan</label>
            <Textarea
              value={remark}
              onChange={(e) => setRemark(e?.target?.value)}
              placeholder={"Masukan catatan anda"}
            />
          </div>
        </div>
        <div className={Styles.section}>
          <div
            className={`${Styles.errorWrapper} ${error ? Styles.error : ""}`}
          >
            *{error || "error placeholder"}
          </div>
        </div>

        <button type="submit" disabled={!ableSubmit || error || submitting}>
          {submitting ? "Sending..." : "Send"}
        </button>
      </form>
    </div>
  );
}

function UnqualifiedLayout({
  data,
  setTrigger = () => { },
  handleClose = () => { },
}) {
  const [storageType, setStorageType] = useState("private");
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [pages, setPages] = useState(`1-${limit}`);
  const [pageList, setPageList] = useState([]);
  const [step, setStep] = useState(1);
  const [remark, setRemark] = useState("");
  const [error, setError] = useState(null);
  // value id posisi, pake ini untuk ngaish value buat submit
  const [selectedID, setSelectedID] = useState(null);

  // value name posisi, pake ini untuk ngasih value buat submit
  const [selectedName, setSelectedName] = useState(null);

  const [isClient, setIsClient] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [listData, setListData] = useState([]);

  const fetchList = useCallback(async () => {
    try {
      setIsLoading(true);
      setListData([]);
      const { response } = await getTransferSync(
        `page=${page}&limit=${limit}&type=${storageType === "private" && !isClient
          ? "PUBLIC"
          : storageType === "private" && isClient
            ? "PRIVATE"
            : "PUBLIC"
        }&search=${debouncedSearch}&isPrivatePosition=${storageType === "private" && !isClient
          ? "yes"
          : storageType === "public"
            ? "no"
            : ""
        }`
      );
      setListData(response?.data);
      setTotalPage(response?.totalPage);
      setTotalData(response?.totalData);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }, [page, limit, storageType, isClient, debouncedSearch]);

  useEffect(() => {
    if (storageType) {
      fetchList();
    }
  }, [fetchList, storageType]);

  const templateClientPosition = useMemo(() => {
    return listData?.map((obj) => {
      return {
        ...obj,
        id: obj?.id,
        clientName: obj?.OrderScheme?.Client?.name,
        position: obj?.name,
        amount: obj?.amount,
      };
    });
  }, [listData]);
  const handleChangeStorage = (type) => {
    if (type !== storageType) {
      setStorageType(type);
      setSelectedPosition("");
    }
  };

  const template = useMemo(() => {
    return {
      data: templateClientPosition?.map((el) => {
        return {
          id: el?.id,
          clientName: el?.clientName,
          position: el?.position,
          amount: el?.amount,
        };
      }),
      columns: [
        {
          name: "position",
          label: "Posisi",
          minWidth: "200px",
          maxWidth: "200px",
          // hideOnMobile: true,
          renderData: (raw) => (
            <div className={Styles.customTableText}>
              <div className={Styles.radioButton}>
                <input
                  type="radio"
                  checked={selectedPosition === raw.id}
                  onChange={() => {
                    setSelectedPosition(raw?.id);
                    setSelectedID(raw?.id);
                    setSelectedName(null);
                  }}
                ></input>
              </div>
              <p>{raw.position}</p>
            </div>
          ),
        },
        {
          name: "clientName",
          label: "klien",
        },
        {
          name: "amount",
          label: "Jumlah",
          // minWidth: "96px",
        },
        // {
        //   name: "email",
        //   label: "Email",
        // },
        // {
        //   name: 'phoneNumber',
        //   label: 'Nomor Hp',
        //   hideOnMobile: true,
        // },
      ],
    };
  }, [selectedPosition, templateClientPosition]);

  const stepperOptions = useMemo(() => {
    return [
      {
        id: 1,
        label: "Pilih Penyimpanan",
        isDone: storageType && selectedPosition,
      },
      { id: 2, label: "Masukan Catatan", isDone: false },
    ];
  }, [selectedPosition, storageType]);

  const disableSave = useMemo(() => {
    if (isSubmitting) {
      return true;
    }
    return false;
  }, [isSubmitting]);

  const [dataList, setDatalist] = useState([]);
  const [dataListPublic, setDatalistPublic] = useState([]);

  // const fetchPrivateData = async (items) => {
  //   // Simulate API call (replace with actual API call)
  //   await new Promise((resolve) => setTimeout(resolve, 1000));
  //   // Your actual API call to update private data
  // };

  // const fetchPublicData = async (items) => {
  //   // Simulate API call (replace with actual API call)
  //   await new Promise((resolve) => setTimeout(resolve, 1000));
  //   // Your actual API call to update public data
  // };

  const handleSubmitNewPosition = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true); // Set loading to true when starting the fetch
      const date = new Date();
      const time = date.getTime();
      let items = {
        id: time,
        positionName: search,
      };
      if (storageType === "private") {
        // await fetchPrivateData(items);
        setDatalist([items, ...dataList]);
        setSearch("");
        setSelectedPosition(items.id);
        setSelectedName(items?.positionName);
        setSelectedID(null);
        setPage(1);
      } else {
        // await fetchPublicData(items);
        setDatalistPublic([items, ...dataListPublic]);
        setSearch("");
        setSelectedPosition(items.id);
        setPage(1);
        setSelectedID(null);
        setSelectedName(items?.positionName);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Set loading to false when fetch is complete (whether successful or not)
    }
  };

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      await transferCandidate({
        candidateID: data?.id ? data?.id : "",
        positionID: selectedID ? selectedID : "",
        type: storageType ? storageType?.toUpperCase() : "",
        rejectionNote: remark ? remark : "",
        name: selectedName ? selectedName : "",
      });
      setIsSubmitting(false);
      setTrigger(makeRandomString(5));
      handleClose();
    } catch (err) {
      setIsSubmitting(false);
      console.log(err);
      setError(err?.response?.data?.error || "Something went wrong");
    }
  };

  const filteredOptions = useMemo(() => {
    return dataList?.filter((el) =>
      el?.positionName?.toLowerCase()?.includes(debouncedSearch?.toLowerCase())
    );
  }, [dataList, debouncedSearch]);

  const filteredOptionsPublic = useMemo(() => {
    return dataListPublic?.filter((el) =>
      el?.positionName?.toLowerCase()?.includes(debouncedSearch?.toLowerCase())
    );
  }, [dataListPublic, debouncedSearch]);

  return (
    <div className={Styles.unqualifiedSection}>
      <div className={Styles.intro}>
        <div className={Styles.stackedIntro}>
          <div>
            <img src={Images.INVITE_USER} alt="name" />
          </div>
          <div>
            <span>Candidate</span>
            <span>{capitalizedFirstLetter(data?.name)}</span>
          </div>
        </div>
        <div className={Styles.stackedIntro}>
          <div>
            <img src={Images.INVITE_JOB} alt="name" />
          </div>
          <div>
            <span>Job</span>
            <span>{data?.ExpectedPosition?.name}</span>
          </div>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.progressBar}>
          <Stepper
            // type="outlined"
            options={stepperOptions}
            currentStep={step}
            onClick={() => {
              return;
            }}
          />
        </div>
        <div
          className={`${Styles.storageSection} ${step === 1 ? "" : Styles.hidden
            }`}
        >
          <p>Alihkan kandidat yang tidak memenuhi kualifikasi</p>
          <div className={Styles.wrapper}>
            {["private", "public"].map((el, idx) => (
              <div
                className={Styles.card}
                key={idx}
                onClick={() => handleChangeStorage(el)}
              >
                <div className={Styles.radioButton}>
                  <CheckboxJobPosting
                    checked={storageType === el ? true : false}
                    onChange={() => handleChangeStorage(el)}
                    type={"radio-section"}
                  />

                  {/* <input
                    type="radio"
                    checked={storageType === el}
                    onClick={() => handleChangeStorage(el)}
                  ></input> */}
                </div>
                <div className={Styles.icon}>
                  <Icon size={24} icon={"building"} />
                </div>
                <div className={Styles.text}>
                  <p>Penyimpanan</p>
                  <p>{capitalizedFirstLetter(el)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={`${Styles.tableContainer} ${storageType && step === 1 ? "" : Styles.hidden
            }`}
        >
          {storageType === "private" && (
            <div className={Styles.isClientSetter}>
              <span>Tampilkan klien pada posisi yang sudah ada</span>
              <Switch
                isChecked={isClient}
                setIsChecked={setIsClient}
                height="40px"
                padding="4px"
                width="72px"
                offBg="#F5F5F5"
              />
            </div>
          )}
          <div className={Styles.searchbarWrapper}>
            <OutlinedSearchBar
              placeholder={
                storageType === "private"
                  ? "Cari nama posisi atau klien"
                  : "Cari nama posisi"
              }
              value={search}
              setValue={setSearch}
            // onChange={(e) => setSearch(e?.target?.value)}
            />
            {debouncedSearch &&
              !template?.data?.length &&
              !isClient &&
              !filteredOptions?.length &&
              !filteredOptionsPublic?.length && (
                <button onClick={handleSubmitNewPosition}>
                  <Icon icon={"circle-plus"} size={16} />
                  <span>Tambah ke posisi baru</span>
                </button>
              )}
          </div>

          <div
            className={
              storageType === "private" && isClient ? "" : Styles.hidden
            }
          >
            <CustomTable
              columns={template?.columns}
              data={template?.data || []}
              // type={"sticky-left-right"}
              value={limit}
              setValue={setLimit}
              selectedPage={page}
              setSelectedPage={setPage}
              totalPage={1}
              height="350px"
              stickyTop
              isLoading={isLoading}
            />
          </div>
          <div
            className={`${Styles.positionList} ${storageType === "public" ||
                (storageType === "private" && !isClient)
                ? ""
                : Styles.hidden
              }`}
          >
            <div className={Styles.tableWrapper}>
              {filteredOptions?.length > 0 ? (
                <>
                  {storageType === "private" &&
                    page === 1 &&
                    filteredOptions?.map((obj, idx) => {
                      return (
                        <div className={Styles.list} key={idx}>
                          <div className={Styles.radioButton}>
                            <CheckboxJobPosting
                              checked={
                                obj?.id === selectedPosition ? true : false
                              }
                              onChange={() => {
                                setSelectedPosition(obj?.id);
                                setSelectedName(obj?.positionName);
                                setSelectedID(null);
                              }}
                              type={"radio-section"}
                            />
                          </div>
                          <Icon
                            size={20}
                            icon={"contracted"}
                            className={Styles.icon}
                          />
                          <p>{obj?.positionName}</p>
                        </div>
                      );
                    })}
                </>
              ) : null}
              {filteredOptionsPublic?.length > 0 && (
                <>
                  {storageType === "public" &&
                    page === 1 &&
                    filteredOptionsPublic?.map((obj, idx) => {
                      return (
                        <div className={Styles.list} key={idx}>
                          <div className={Styles.radioButton}>
                            <CheckboxJobPosting
                              checked={
                                obj?.id === selectedPosition ? true : false
                              }
                              onChange={() => {
                                setSelectedPosition(obj?.id);
                                setSelectedName(obj?.positionName);
                                setSelectedID(null);
                              }}
                              type={"radio-section"}
                            />
                          </div>
                          <Icon
                            size={20}
                            icon={"contracted"}
                            className={Styles.icon}
                          />
                          <p>{obj?.positionName}</p>
                        </div>
                      );
                    })}
                </>
              )}
              {templateClientPosition?.length > 0 && (
                <>
                  {templateClientPosition.map((el, idx) => (
                    <div className={Styles.list} key={idx}>
                      <div className={Styles.radioButton}>
                        {/* <input
                      type="radio"
                      checked={selectedPosition?.id === el?.id}
                      onClick={() => setSelectedPosition(el)}
                      onChange={() => { }}
                    ></input> */}
                        <CheckboxJobPosting
                          checked={el?.id === selectedPosition ? true : false}
                          onChange={() => {
                            setSelectedPosition(el?.id);
                            setSelectedID(el?.id);
                            setSelectedName(null);
                          }}
                          type={"radio-section"}
                        />
                      </div>
                      <Icon
                        size={20}
                        icon={"contracted"}
                        className={Styles.icon}
                      />
                      <p>{el?.position}</p>
                    </div>
                  ))}
                </>
              )}

              {templateClientPosition?.length === 0 &&
                !filteredOptions?.length &&
                !filteredOptionsPublic?.length ? (
                <div className={Styles.emptyWrapper}>
                  <TableEmptyHandler
                    title={isLoading ? "Tunggu sebentar" : "Data Kosong"}
                    desc={
                      isLoading
                        ? "Data sedang dimuat"
                        : "Tidak ada data atau data belum ada"
                    }
                  />
                </div>
              ) : null}
            </div>
            <div className={Styles.paginationWrapper}>
              <Pagination
                total={
                  totalData || templateClientPosition.length || data?.length
                }
                totalPage={totalPage || 1}
                value={limit}
                setValue={setLimit}
                pages={pages}
                setPages={setPages}
                pageList={pageList}
                setPageList={setPageList}
                selectedPage={page}
                setSelectedPage={setPage}
                bgColor={"#fff"}
              />
            </div>
          </div>
        </div>
        <div
          className={`${Styles.actionContainer} ${Boolean(storageType) && step === 1 ? "" : Styles.hidden
            }`}
        >
          <div className={Styles.buttonWrapper}>
            <Button
              title={"Lanjutkan"}
              type={"primary"}
              onClick={() => setStep(2)}
              disabled={!selectedPosition}
            />
          </div>
        </div>
        <div
          className={`${Styles.remarkContainer} ${Boolean(storageType) && step === 2 ? "" : Styles.hidden
            }`}
        >
          <p>Catatan</p>
          <Textarea
            value={remark}
            onChange={(e) => setRemark(e?.target?.value)}
            placeholder={"Masukan catatan anda"}
          />
        </div>
        <div className={`${Styles.errorWrapper} ${error ? Styles.error : ""}`}>
          *{error || "error placeholder"}
        </div>
        <div
          className={`${Styles.actionContainer} ${Boolean(storageType) && step === 2 ? "" : Styles.hidden
            }`}
        >
          <div className={Styles.buttonWrapper}>
            <Button
              title={"Kembali"}
              type={"secondary-blue"}
              onClick={() => setStep(1)}
            />
            <Button
              title={"Simpan"}
              type={"primary"}
              onClick={handleSave}
              disabled={disableSave}
            // onClick={() => setStep(2)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function CandidateInformationModal({
  data,
  handleClose,
  // url,
  // roOptions,
  setTrigger,
  setIsSuccess,
  invitationSyncData,
  inviteOption,
  // type,
  includeUnqualified = true
}) {
  const [expandId, setExpandId] = useState(1);
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);
  const [mode, setMode] = useState("");

  useEffect(() => {
    if (boxOutsideClick) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxOutsideClick]);

  return (
    <div className={Styles.background}>
      <div className={Styles.container} ref={boxRef}>
        <div className={Styles.header}>
          <div className={Styles.title}>
            <h3>{titleCase(data?.name || "name placeholder")}</h3>
            <div className={Styles.closeIcon} onClick={handleClose}>
              <Icon icon={"cross"} size={24} />
            </div>
          </div>
          {!mode ? (
            <div className={Styles.controller}>
              <button
                className={expandId === 1 ? Styles.active : ""}
                onClick={() => setExpandId(1)}
              >
                <span>Personal Data</span>
                <Icon
                  icon={expandId === 1 ? "arrow-up" : "arrow-down"}
                  size={18}
                />
              </button>
              {/* <button
                className={expandId === 2 ? Styles.active : ""}
                onClick={() => setExpandId(2)}
              >
                <span>History</span>
                <Icon
                  icon={expandId === 2 ? "arrow-up" : "arrow-down"}
                  size={18}
                />
              </button> */}
              {inviteOption && (
                <div className={Styles.inviteButtonWrapper}>
                  {
                    (!data?.status?.toLowerCase()?.includes('hold') && !data?.status?.toLowerCase()?.includes('contracted'))
                  ?
                  <div className={Styles.inviteButton}>
                    <Button
                      title={"Undang Interview"}
                      type={"primary"}
                      onClick={() => setMode("invitation")}
                    />
                  </div>
                  :
                  <span className={Styles.holdText}>{capitalizedFirstLetter(data?.status?.split('-')[data?.status?.split('-')?.length - 1])}</span>
                  }

                </div>
              )}
            </div>
          ) : (
            <div className={Styles.headerControl}>
              <div className={Styles.backArrow} onClick={() => setMode("")}>
                <Icon size={24} icon={"arrow-left-back"} />
                <p>kembali</p>
              </div>
              <p>
                {mode === "invitation"
                  ? "Undangan Interview RO"
                  : "Unqualified Kandidat"}
              </p>
            </div>
          )}
        </div>

        {!mode && (
          <div className={Styles.content}>
            {expandId === 1 && (
              <div className={Styles.candidateProfile}>
                <CandidateResumeLayout
                  setMode={setMode}
                  data={data}
                  unqualifiedButton={includeUnqualified && !data?.tag}
                // noDocument={noDocument}
                />
              </div>
            )}
            {expandId === 2 && (
              <div className={Styles.candidateHistory}>
                <div className={Styles.historyContainer}>
                  {historyDataTemplate.map((el, idx) => (
                    <div className={Styles.historyWrapper} key={idx}>
                      <div className={Styles.head}>
                        <div className={Styles.textHead}>
                          <p>{moment(el?.date).format("dddd")}</p>
                          <p>{moment(el?.date).format("DD MMMM YYYY")}</p>
                        </div>
                        <div
                          className={`${Styles.status} 
                          ${el?.status === "Unqualified" && Styles.unqualified}
                          ${el?.status === "Tidak Lolos" && Styles.notPassed}
                          `}
                        >
                          <Icon icon={"black-dot"} size={12} />
                          <p>{el?.status}</p>
                        </div>
                      </div>
                      <div className={Styles.historyContent}>
                        <div className={Styles.contentHead}>
                          <p>{el?.position}</p>
                          <div className={Styles.profileWrapper}>
                            <div className={Styles.profilePicture}>
                              <img src={Images.AVA_DEFAULT} alt="" />
                            </div>
                            <div className={Styles.userInformation}>
                              <div className={Styles.username}>{el?.name}</div>
                              <div className={Styles.jobTitle}>
                                Recruitment Officer
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={Styles.contentBody}>
                          <p>Note :</p>
                          <p>{el?.note}</p>
                        </div>
                      </div>
                      <div className={Styles.topIcon}>
                        <Icon icon={"calendar"} size={20} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {mode === "invitation" && (
          <div className={Styles.content}>
            <InvitationLayout
              invitationSyncData={invitationSyncData}
              data={data}
              setTrigger={setTrigger}
              handleClose={handleClose}
              setIsSuccess={setIsSuccess}
            />
          </div>
        )}
        {mode === "unqualified" && (
          <div className={Styles.content}>
            <UnqualifiedLayout
              data={data}
              setTrigger={setTrigger}
              handleClose={handleClose}
            />
          </div>
        )}
      </div>
    </div>
  );
}
