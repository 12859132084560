import Icon from '@Atom/Icon';
import MultiSelectSmallLabel from '@Molecule/MultiSelectSmallLabel';
import SelectOptionsToggle from '@Molecule/SelectOptionToggle';
import styled from '@emotion/styled';
import { Autocomplete, Checkbox, Paper, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Styles from './style.module.scss';

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    color: '#0a0a0a',
    fontSize: '13px',
    fontWeight: 400,
    background: '#fff',

    "& fieldset": {
      borderRadius: 8,
      padding: 0,
      border: '1px solid #e0e0e0',
    },
    '&:hover fieldset': {
      border: '1px solid #e0e0e0',
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #1571DE"
    }
  },
});

const MuiAutocomplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 29px;
  }
`;


export default function OthersSection(props) {
  const { options, bankOptions, form, setForm, setTrigger } = props;

  const [manFee, setManFee] = useState(form?.others?.manfee)
  const [thr, setThr] = useState(form?.others?.thr)
  const [uak, setUak] = useState(form?.others?.uak)
  const [banks, setBanks] = useState(form?.others?.tkoBank)

  useEffect(() => {
    const newForm = form
    newForm.others.manfee = manFee
    newForm.others.thr = thr
    newForm.others.uak = uak
    newForm.others.tkoBank = banks
    setForm(newForm)
    setTrigger(Math.random())
  }, [form, setForm, manFee, thr, uak, banks, setTrigger])

  const tkoBankOptions = useMemo(() => {
    return bankOptions?.map((each) => each?.name)
  }, [bankOptions])

  const handleSelectedOption = (e, value) => {
    const isExist = banks.find((item) => (item?.name || item) === (value?.name || value));
    if (banks?.length < 3) {
      if (!isExist) {
        let temp = banks.concat(value)
        setBanks(temp)
      } else {
        handleDeleteOption(value)
      }
    }
  }
  const handleDeleteOption = (value) => {
    const x = banks.filter((item) => item !== value);
    setBanks(x)
  }
  const isSelected = (option) => banks?.indexOf(option) !== -1;

  return (
    <div className={Styles.container}>
      <h3>Others</h3>

      <div className={Styles.content}>
        <div className={Styles.selectWrapper}>
          <MultiSelectSmallLabel
            title="Pengenaan Manfee"
            options={options?.manfee}
            placeholder="Select"
            labels={manFee}
            setLabels={setManFee}
            required
          />
        </div>
        <div className={Styles.selectWrapper}>
          <SelectOptionsToggle
            title="Penagihan THR"
            options={options?.thr}
            placeholder="Select"
            value={thr}
            setValue={setThr}
            autoClose={true}
            required
          />
        </div>
        <div className={Styles.selectWrapper}>
          <SelectOptionsToggle
            title="Penagihan UAK"
            options={options?.uak}
            placeholder="Select"
            value={uak}
            setValue={setUak}
            autoClose={true}
            required
          />
        </div>
        <div className={Styles.selectWrapper}>
          <div className={Styles.title}>{'Rekening Wajib TKO (maksimal 3)'}<span className={Styles.asterisk}>&#x2a;</span></div>
          <MuiAutocomplete
            disablePortal
            value={null}
            blurOnSelect={true}
            disableCloseOnSelect
            popupIcon={<Icon icon="arrow-down" size="20px" color="#0a0a0a" />}
            id="combo-box-demo"
            options={tkoBankOptions}
            sx={{ width: '100%' }}
            onChange={(e, value) =>
              value === null
                ? setBanks(e, null)
                : setBanks(e, value)
            }
            PaperComponent={({ children }) => (
              <Paper className={Styles.Paper}>{children}</Paper>
            )}
            renderOption={(props, option) => {
              const isItemSelected = isSelected(option)
              return (
                <li {...props} onClick={(e) => e.preventDefault()} key={option?.id || Math.random()}>
                  <div
                    onClick={(e) => handleSelectedOption(e, option)}
                  >
                    <span className={Styles.clientOption}>{(option?.name || option)}</span>
                    <Checkbox
                      checked={isItemSelected}
                      sx={{ margin: 0, padding: 0, zIndex: 0 }}
                      icon={<Icon icon="radio-button-default" size={20} />}
                      checkedIcon={<Icon icon="radio-button-active" size={20} />}
                    />

                  </div>
                  <div className={Styles.separator} />
                </li>

              );
            }}
            renderInput={(params) => <MuiTextField {...params} size='small' placeholder='Select' />}
          />
          {banks?.length > 0 && (
            <div className={Styles.labelsContainer}>
              {banks.map((option, i) => (
                <div key={i} className={Styles.label}>
                  <span>
                    {(option?.name || option)}
                  </span>
                  <button onClick={() => handleDeleteOption(option)}>
                    <Icon icon="cross-circle" size={12} />
                  </button>
                </div>
              ))}

            </div>
          )}
        </div>
      </div>
    </div>
  )
}