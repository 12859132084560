import { useAsyncError } from "react-router-dom";
import NotFoundPage from "../NotFound";
import Styles from "./style.module.scss";

export default function AsyncErrorBoundary() {
  const { response } = useAsyncError();
  const error = response?.data;
  const errorBackup = useAsyncError();

  if (+error?.code === 404) {
    return (
      <NotFoundPage
        type="data"
        notFullWidth
      />
    )
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Something went wrong!</h3>
      </div>
      <span className={Styles.status}>{error?.code || errorBackup?.code}</span>
      <span className={Styles.message}>
        {error?.error || errorBackup?.message}
      </span>
    </div>
  );
}
