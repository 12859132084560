/* eslint-disable no-unused-vars */
import React from "react";
import Lottie from "lottie-react";
import animationData from "@Assets/Lotties/pageLoading.json";
import clock from "../../../assets/Gifs/clock.gif"
import Styles from './style.module.scss'

const LoadingAnimation = () => {
  return (
    // <Lottie
    //   animationData={animationData}
    //   height={50}
    //   width={50}
    //   loop
    //   autoplay
    // />
    <div className={Styles.container}>
      <img src={clock} style={{ width: '124px', aspectRatio: 1, objectFit: 'cover' }} alt="" />
      <h3>Loading...</h3>
      <span>Mohon tunggu data sedang dimuat</span>
    </div>
  );
};

export default LoadingAnimation;
