import moment from "moment";
import Styles from "./style.module.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";
import { getClientOrdersClient } from "@Services/client/order";
import Pagination from "@Molecule/Pagination";

export default function ClientOrderListData({
  defaultOrderData,
  defaultTotalPage,
  searchTerm,
  sort,
}) {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const query = useQuery();
  const name = query.get("n");
  const code = query.get("c");

  const [orderData, setOrderData] = useState(defaultOrderData || []);
  const [totalPage, setTotalPage] = useState(defaultTotalPage || 0);

  const [value, setValue] = useState(10);
  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    setSelectedPage(1);
  }, [value]);

  const renewData = useCallback(async () => {
    const { response } = await getClientOrdersClient(code, selectedPage, value);
    setOrderData(response?.data);
    setTotalPage(response?.totalPage);
  }, [code, selectedPage, value]);

  useEffect(() => {
    renewData();
  }, [renewData]);

  const schemes = useMemo(() => {
    const arr = [];
    const sortedData = orderData?.sort((a, b) => {
      return new Date(b?.createdAt) - new Date(a?.createdAt);
    });

    for (let i = 0; i < sortedData.length; i++) {
      const data = sortedData[i];

      const each = {
        id: data?.id,
        type:
          data?.contractType?.toLowerCase() === "jasa pemborongan"
            ? "PKM"
            : "PJTK",
        locations: data?.cities?.join(", "),
        jobs: data?.manPower,
        date: moment(new Date(data?.createdAt)).format("LL"),
        status: data?.status,
      };

      arr.push(each);
    }
    return arr;
  }, [orderData]);

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  const handleViewDetail = (id) => {
    navigate(`${pathname}/details/${id}?n=${name}&c=${code}`);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      {schemes?.map((each, i) => {
        return (
          <div key={i} className={Styles.schemeCard}>
            <div className={Styles.left}>
              <Icon icon="work-scheme" className={Styles.icon} size={27} />
            </div>
            <div className={Styles.middle}>
              <h5>
                {each?.jobs?.slice(0, 3)?.join(" • ")}
                {each?.jobs?.length > 3 && ` +${each?.jobs?.length - 3} more`}
              </h5>
              <span>{each?.type}</span>
              <div className={Styles.location}>
                <Icon icon="location" size={18} />
                <span>{each?.locations}</span>
              </div>
              <span>{each?.date}</span>
            </div>
            <div className={Styles.right}>
              <button onClick={() => handleViewDetail(each?.id)}>
                Lihat Detail
              </button>
            </div>
          </div>
        );
      })}
      {!schemes?.length && (
        <div className={Styles.emptyCard}>no order to show</div>
      )}
      <div className={Styles.paginationContainer}>
        <Pagination
          total={schemes?.length}
          totalPage={totalPage}
          value={value}
          setValue={setValue}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>
    </div>
  );
}
