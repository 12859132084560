import SelectOptionsToggle from "@Molecule/SelectOptionToggle";
import { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import { makeRandomString } from "@Helpers/makeRandomString";

export default function TaxesSection(props) {
  const { options, form, setForm, setTrigger } = props;
  const [PPH21, setPPH21] = useState(form?.taxs?.pph21);
  const [PPH23, setPPH23] = useState(form?.taxs?.pph23);
  const [PPN, setPPN] = useState(form?.taxs?.ppn);

  useEffect(() => {
    const newForm = form;
    newForm.taxs.pph21 = PPH21;
    newForm.taxs.pph23 = PPH23;
    newForm.taxs.ppn = PPN;
    setForm(newForm);
    setTrigger(makeRandomString(5));
  }, [form, PPH21, PPH23, PPN, setForm, setTrigger]);

  return (
    <div className={Styles.container}>
      <h3>Taxes</h3>
      <div className={Styles.content}>
        <div className={Styles.selectWrapper}>
          <SelectOptionsToggle
            title="PPH21"
            options={options?.pph21}
            placeholder="Select"
            value={PPH21}
            setValue={setPPH21}
            autoClose={true}
            required
          />
        </div>
        <div>
          <SelectOptionsToggle
            title="PPH23"
            options={options?.pph23}
            placeholder="Select"
            value={PPH23}
            setValue={setPPH23}
            autoClose={true}
            required
          />
        </div>
        <div className={Styles.selectWrapper}>
          <SelectOptionsToggle
            title="PPN"
            options={options?.ppn}
            placeholder="Select"
            value={PPN}
            setValue={setPPN}
            autoClose={true}
            required
          />
        </div>
      </div>
    </div>
  );
}
