import Styles from "./smallLabel.module.scss";
import Icon from "@Atom/Icon";

const SmallLabel = ({ label, removeLabel, preventDelete, ...props }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.labelFont}>{label}</div>
      {
        !preventDelete && (
          <div className={Styles.icon} onClick={() => removeLabel(label)}>
            <Icon icon="cross-circle" size={12} />
          </div>
        )
      }
    </div>
  );
};

export default SmallLabel;
