import Icon from '@Atom/Icon';
import SearchBar from '@Atom/SearchBar';
import { makeRandomString } from '@Helpers/makeRandomString';
import NewAddPositionModal from '@Molecule/_modal/NewAddPositionModal';
import Images from '@Theme/Images';
import { useState } from 'react';
import Styles from './style.module.scss';

export default function PositionStep({
  delegationsForm = [],
  positionsForm = [],
  setPositionsForm = () => { },
  headerHeight,
  alertMessage = ''
}) {
  const [showAdd, setShowAdd] = useState(false);
  const [search, setSearch] = useState('')

  const [activeCityID, setActiveCityID] = useState('')


  return (
    <div className={Styles.container} style={{ maxHeight: `calc(100vh - 70px - ${headerHeight}px - 76px - 24px)` }}>
      <div style={{ maxHeight: `calc(100vh - 70px - ${headerHeight}px - 76px - 24px)` }} className={Styles.index}>
        <div className={Styles.iHeader}>
          <div className={Styles.iHLogo}>
            <Icon icon={'contracted'} size={16} color={'#1571DE'} />
          </div>
          <span>Daftar Posisi</span>
        </div>
        {
          !!positionsForm?.length
            ?
            <div className={Styles.iContent}>
              <div className={Styles.icHeader}>
                <button onClick={() => setShowAdd(true)}              >
                  <Icon icon="add-circle" size={20} />
                  <span>Tambah Lokasi</span>
                </button>
                <div className={Styles.searchWrapper}>
                  <SearchBar
                    value={search}
                    onChange={(e) => setSearch(e?.target?.value)}
                    placeholder={'Cari'}
                  />
                </div>
              </div>

              <div className={Styles.iList}>
                {
                  positionsForm
                    ?.filter(obj => obj?.cityName?.toUpperCase()?.includes(search?.toUpperCase()))
                    ?.map((d, dI) => (
                      <div key={dI} onClick={() => setActiveCityID(d?.cityCode)} className={`${Styles.each} ${Styles[activeCityID === d?.cityCode ? 'active' : '']}`}>
                        <Icon icon={'building'} size={20} />
                        <span>{d?.cityName}</span>
                        <Icon icon={'arrow-right'} size={20} />
                      </div>
                    ))
                }
              </div>
            </div>
            :
            <div className={Styles.iEmpty}>
              <img src={Images.HIGH_BUILDING} alt='' />
              <span>Belum ada data posisi ditambahkan</span>
            </div>
        }
      </div>
      <div style={{ maxHeight: `calc(100vh - 70px - ${headerHeight}px - 76px - 24px)` }} className={Styles.main}>
        {
          positionsForm?.length
            ?

            positionsForm?.map((d, dI) => (
              <div key={dI} className={`${Styles.locationData} ${Styles[activeCityID === d?.cityCode ? 'visible' : 'hidden']}`}>
                <div className={Styles.ldHeader}>
                  <h3>{d?.cityName}</h3>
                  <button>
                    <Icon icon={'trash'} size={20} />
                  </button>
                </div>
                <div className={Styles.ldMain}>

                  main
                </div>
              </div>
            ))

            :
            <div className={Styles.noLocation}>
              <img src={Images.TIME_MANAGEMENT} alt="default" />
              <div className={Styles.desc}>
                <h3>Belum Ada List Position yang Ditambahkan</h3>
                <p>
                  Silahkan untuk menambah manpower sesuai kebutuhanmu
                </p>
              </div>

              <button
                className={Styles.addLocationButton}
                onClick={() => setShowAdd(true)}
              >
                <Icon icon="circle-plus" size={20} />
                <span>Position</span>
              </button>
            </div>
        }
      </div>

      {showAdd && (
        <NewAddPositionModal
          show={showAdd}
          setShow={setShowAdd}
          onAdd={({ positionName, dedicatedROId = '', dedicatedROName = '', qualifications = [] }) => {
            setPositionsForm([
              ...positionsForm,
              {
                id: makeRandomString(5),
                isExpanded: false,
                positionName,
                dedicatedROName: dedicatedROName,
                dedicatedROId: dedicatedROId,
                qualifications: qualifications,
                citiesData: delegationsForm?.map((d) => {
                  return {
                    id: d?.id,
                    isApplied: false,
                    cityCode: d?.cityCode,
                    cityName: d?.cityName,
                    umk: d?.umk,
                    amount: 1,
                    salaryValue: d?.umk || 4500000,
                    salaryDenom: "",
                    personalEquipments: [],
                    allowances: [],
                    jkk: "0",
                    jkm: "0",
                    jht: "0",
                    expectedDate: "",
                  };
                }),
              },
            ]);
          }}
          existingPositionNames={positionsForm?.map((obj) => obj?.positionName)}
          delegationsForm={delegationsForm}
          alertMessage={alertMessage}
        />
      )}

    </div>
  )
}

