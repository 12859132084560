import Images from "@Theme/Images";
import React, { useMemo } from "react";
import Styles from "./style.module.scss";

export default function Summary({ TotalManfeeCost, generalCost, ppn }) {
const ppnCost = useMemo(()=> {
  if(ppn?.toLowerCase()?.includes('manfee')) {
    return TotalManfeeCost * 11 / 100
  } else {
    return ((generalCost) * 11) / 100;
  }
}, [TotalManfeeCost, generalCost, ppn])
  const subTotal = +generalCost - +TotalManfeeCost
  return (
    <div className={Styles.container}>
      <div className={Styles.rightSection}>
        <div className={Styles.card}>
          <img src={Images.PAYDAY} alt="payday" />
          <div className={Styles.cardContent}>
            <p className={Styles.cardTitle}>Pembayaran</p>
            <p className={Styles.cardBody}>TOP 0 Day</p>
            <p className={Styles.cardDesc}>
              Invoice diterbitkan sebelum penggajian dan pembayaran maksimal H-2
              penggajian
            </p>
          </div>
        </div>
      </div>
      <div className={Styles.leftSection}>
        <p className={Styles.headTitle}>Total Estimated Cost</p>
        <div className={Styles.summaryWrapper}>
          <div className={Styles.variable}>
            <p>Sub Total</p>
            <p>Rp{subTotal.toLocaleString("id")}</p>
          </div>
          <div className={Styles.variable}>
            <p>Total Management Fee</p>
            <p>Rp{TotalManfeeCost.toLocaleString("id")}</p>
          </div>
          <div className={Styles.variable}>
            <p>PPN 11% {ppn?.toLowerCase()}</p>
            <p>
              Rp
              {ppnCost.toLocaleString("id")}
            </p>
          </div>
        </div>
        <div className={Styles.total}>
          <p>Total</p>
          <p>Rp{(subTotal + TotalManfeeCost + ppnCost).toLocaleString("id")}</p>
        </div>
      </div>
    </div>
  );
}
