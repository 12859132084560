import Icon from "@Atom/Icon";
import Styles from "./input.module.scss";
import CurrencyInput from "react-currency-input-field";
import { useCallback, useEffect, useState } from "react";
import ErrorBubble from "@Atom/ErrorBubble";

const Input = ({
  value,
  id,
  onChange = () => { },
  disabled,
  placeholder,
  type,
  deleteOption,
  onDelete,
  floatingEndAdornment,
  floatingStartAdornment,
  startAdornmentDefaultPaddingLeft,
  readOnly,
  isCurrency,
  isError = false,
  isErrorV2 = false,
  errorText = "",
  forceUpperCase = false,
  onKeyDown = () => { },
  onInput = () => { },
  top = "12px",
  left = "12px",
  right = "12px",
  maxLength = 1000,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState(value || "");
  const changeValue = useCallback((targetValue) => {
    if (targetValue) {
      const firstParsing = targetValue.replace(/[^\d.]/gi, "");
      const parsedValue = firstParsing
        ?.replaceAll(",", "")
        ?.replaceAll(".", "");
      setCurrentValue(parsedValue);
    } else {
      setCurrentValue("");
    }
    // onChange(fixedValue);
  }, []);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (isCurrency) {
      onChange(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrency, currentValue]);

  const [onFocus, setOnFocus] = useState(false)

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.innerWrapper}>
        <div
          className={`${Styles.container} ${isCurrency ? Styles.withCurrency : ""
            }  ${isError ? Styles.error : ""}`}
        >
          {isCurrency && (
            <div
              className={`${Styles.currencyAdornment} ${isError ? Styles.error : ""
                }`}
            >
              Rp
            </div>
          )}
          {isCurrency ? (
            <CurrencyInput
              value={currentValue}
              onChange={(e) => changeValue(e?.target?.value)}
              className={`${Styles.input} ${Styles.currency} ${isError ? Styles.error : ""
                }`}
              allowDecimals={false}
              decimalSeparator=","
              groupSeparator="."
              placeholder={placeholder}
            />
          ) : (
            <input
              className={`${Styles.input} ${isError && !onFocus ? Styles.error : ""}`}
              type={type || "text"}
              id={id || "fname"}
              name="fname"
              value={value || ""}
              onChange={onChange}
              placeholder={placeholder}
              disabled={disabled}
              style={{
                paddingLeft: floatingStartAdornment
                  ? startAdornmentDefaultPaddingLeft || "50px"
                  : "",
              }}
              onFocus={()=> setOnFocus(true)}
              onBlur={()=> setOnFocus(false)}
              readOnly={readOnly}
              onKeyDown={onKeyDown}
              onInput={onInput}
              maxLength={maxLength}
            />
          )}

          {floatingStartAdornment && (
            <div
              className={Styles.floatingStartAdornment}
              style={{ top, left }}
            >
              {floatingStartAdornment}
            </div>
          )}
          {floatingEndAdornment && (
            <div className={Styles.floatingEndAdornment}>
              {floatingEndAdornment}
            </div>
          )}
        </div>

        {isError && !disabled && !readOnly && !onFocus && (
          !isErrorV2
            ?
            <span className={Styles.errorText}>{errorText}</span>
            :
            <div>
              <ErrorBubble
                errorText={errorText}
              />
            </div>
        )}
      </div>
      {deleteOption && (
        <button onClick={onDelete}>
          <Icon icon="trash" size={20} />
        </button>
      )}
    </div>
  );
};

export default Input;
