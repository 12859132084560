import { useMemo } from "react";
import ContactsPerJobTitle from "./ContactsPerJobTitle";
import Styles from "./style.module.scss";

export default function ContactSection({ data }) {
  const contacts = useMemo(() => {
    return data?.order?.Client?.contacts;
  }, [data]);

  const jobTitles = useMemo(() => {
    const arr = [];

    for (let i = 0; i < contacts?.length; i++) {
      arr.push(contacts[i]?.jobTitle);
    }

    let uniqueChars = arr.filter((c, index) => {
      return arr.indexOf(c) === index;
    });

    return uniqueChars;
  }, [contacts]);

  const dataContacts = useMemo(() => {
    const arr = [];

    for (let j = 0; j < jobTitles.length; j++) {
      const usersArr = [];
      for (let k = 0; k < contacts?.length; k++) {
        const isJobMatch = Boolean(contacts[k]?.jobTitle === jobTitles[j]);

        isJobMatch && usersArr.push(contacts[k]);
      }
      const jobExist = contacts?.some((el) => el?.jobTitle === jobTitles[j]);
      if (jobExist) {
        arr.push({
          jobTitle: jobTitles[j],
          peopleInCharge: usersArr,
        });
      }
    }
    return arr;
  }, [contacts, jobTitles]);

  return (
    <div className={Styles.container}>
      {dataContacts.map((eachD, iD) => {
        return (
          <div key={iD} className={Styles.card}>
            <ContactsPerJobTitle
              title={eachD?.jobTitle}
              data={eachD?.peopleInCharge}
            />
          </div>
        );
      })}
    </div>
  );
}
