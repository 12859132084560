import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import CustomBreadCrumb from "@Molecule/CustomBreadCrumb";
import useQuery from "@Hooks/useQuery";
import { useNavigate } from "react-router-dom";

export default function EmployeeDetailNavigator() {
  const query = useQuery();
  const name = query.get("n");
  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate("/employee");
  };
  return (
    <div className={Styles.container}>
      <button onClick={handleClickBack}>
        <Icon icon="arrow-left-rounded-primary" color="#1571DE" size={20} />
      </button>
      <CustomBreadCrumb>
        <button onClick={handleClickBack} className={Styles.inactive}>
          Employee
        </button>
        <button disabled className={Styles.active}>
          {name}
        </button>
      </CustomBreadCrumb>
    </div>
  );
}
