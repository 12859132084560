import { Document, Page, Image, pdf, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        padding: 8,
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        flexWrap: 'wrap',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
        alignSelf: 'center',
    },
});

export function imageToPdf(image) {
    return new Promise(async (resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.onload = async () => {
                const imageData = reader.result;
                const convertedPdf = (
                    <Document>
                        <Page size="A4" style={styles.page}>
                            <Image src={imageData} style={styles.image} />
                        </Page>
                    </Document>
                );
                const blobPdf = await pdf(convertedPdf).toBlob();
                const createPdf = new File([blobPdf], 'imageToPdf.pdf', { type: blobPdf.type });
                resolve(createPdf);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(image);
        } catch (error) {
            reject(error);
        }
    });
}
