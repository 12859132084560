import Styles from "./multiSelect.module.scss";
import React, { useState, useEffect } from "react";

const Radio = ({ option, addLabel, labels, removeLabel, preventDeleteIndicator,...props }) => {
  const [isChecked, setIsChecked] = useState(false);

  const selectValue = () => {
    if (!isChecked) {
      addLabel(option);
    }
    if (isChecked) {
      if(!option?.[preventDeleteIndicator]) {
        removeLabel(option?.name);
        setIsChecked(false);
      }
    }
    return null;
  };

  useEffect(() => {
    labels.map((obj) => {
      if (option.name === obj.name) {
        setIsChecked(true);
      }
      return null;
    });
  }, [labels, option]);

  return (
    <div className={Styles.radioWrapper} onClick={selectValue}>
      <div className={Styles.option}>{option?.name}</div>
      {isChecked ? (
        <div className={Styles.selectedRadio}></div>
      ) : (
        <div className={Styles.radio}></div>
      )}
    </div>
  );
};

export default Radio;
