import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import { useEffect, useRef } from "react";
import UseOutsideClick from "@Hooks/useOutsideClick";

export default function DoneModal({
  handleDone = () => { },
  title,
  description,
  doneButton,
  doneTitle,
  show
}) {
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow')
    }
  }, [show])


  useEffect(() => {
    if (boxOutsideClick) {
      handleDone();
    }
  }, [boxOutsideClick, handleDone]);

  useEffect(() => {
    if (!doneButton) {
      setTimeout(function () {
        handleDone();
      }, 3000);
    }
  }, [doneButton, handleDone]);

  return (
    <div className={Styles.background}>
      <div className={Styles.container} ref={boxRef}>
        <span className={Styles.circle}>
          <Icon icon="check" size={53} className={Styles.checkIcon} />
        </span>
        <h4>{title}</h4>
        <p>{description}</p>
        {doneButton && (
          <button onClick={handleDone}>{doneTitle || "Done"}</button>
        )}
      </div>
    </div>
  );
}
