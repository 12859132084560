/* eslint-disable no-unused-vars */
import { useMemo, useRef } from "react";
import Styles from "./styles.module.scss";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler,
  CategoryScale,
} from "chart.js";
import Rotation from "@Atom/Rotation";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler,
  CategoryScale
);

export default function ProcessCandidateChart({
  arrData = [],
  isLoading = false,
}) {
  const boxRef = useRef();
  const labels = useMemo(() => {
    return arrData?.map(obj => obj?.day)
  }, [arrData])
  // const labels = [
  //   "1 Jan",
  //   "2 Jan",
  //   "3 Jan",
  //   "4 Jan",
  //   "5 Jan",
  //   "6 Jan",
  //   "7 Jan",
  //   "8 Jan",
  //   "9 Jan",
  //   "10 Jan",
  //   "11 Jan",
  //   "12 Jan",
  //   "13 Jan",
  //   "14 Jan",
  //   "15 Jan",
  //   "16 Jan",
  //   "17 Jan",
  //   "18 Jan",
  //   "19 Jan",
  //   "20 Jan",
  //   "21 Jan",
  //   "22 Jan",
  //   "23 Jan",
  //   "24 Jan",
  //   "25 Jan",
  //   "26 Jan",
  //   "27 Jan",
  //   "28 Jan",
  //   "29 Jan",
  //   "30 Jan",
  //   "31 Jan",
  // ];

  // const dataTemporary = [
  //   100, 200, 200, 200, 100, 100, 100, 90, 100, 200, 200, 200, 100, 100, 100,
  //   90, 100, 200, 200, 200, 100, 100, 100, 90, 100, 200, 200, 200, 100, 100,
  //   100,
  // ];

  const dataTemporary = useMemo(() => {
    return arrData?.map(obj => obj?.total)
  }, [arrData])

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataTemporary,
        backgroundColor: (context) => {
          const bgColor = [
            "#4595F5",
            "rgba(77, 157, 253, 0.54)",
            "rgba(63, 147, 248, 0.00)",
          ];

          if (!context?.chart?.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context?.chart;

          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);

          gradientBg.addColorStop(0.1, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        label: "Total",
        borderWidth: 0.5,
        borderColor: "#0065DD",
        fill: true, // Ensure fill is set to true
        tension: 0.4, // Nilai tension untuk mode "bezier"
      },
    ],
  };

  const options = {
    pointRadius: 0,
    pointHoverRadius: 7,
    hoverBackgroundColor: "#4595F5",
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      stacked100: { enable: true },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 4,
        },
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        stacked: true,
        grace: "50%",
        // ticks: {
        //   display: false,
        // },
        ticks: {
          maxTicksLimit: 5,
        },
      },
    },
  };

  const hoverLine = {
    id: "hoverLine",
    // events: "mousemove",
    // beforeDatasetsDraw(chart, args, plugins) {
    //   if (crossHair) {
    //     const { ctx } = chart;
    //     ctx.save();
    //     crossHair.forEach((line, index) => {
    //       ctx.beginPath();
    //       ctx.lineWidth = 3;
    //       ctx.strokeStyle = "#4595F5";
    //       ctx.setLineDash([6]);
    //       ctx.moveTo(line.startX, line.startY);
    //       ctx.lineTo(line.endX, line.endY);
    //       ctx.stroke();
    //     });
    //     ctx.restore();
    //   }
    // },
    // afterEvent(chart, args, pluginOptions) {
    //   const event = args.event;

    //   const {
    //     ctx,
    //     tooltip,
    //     scales: { x, y },
    //     chartArea: { top, bottom, left, right, width, height },
    //   } = chart;

    //   if (!args.inChartArea && crossHair) {
    //     crossHair = undefined;
    //     args.changed = true;
    //   } else if (args.inChartArea) {
    //     crossHair = [
    //       {
    //         startX: event.x,
    //         startY: top,
    //         endX: event.x,
    //         endY: bottom,
    //       },
    //     ];
    //     args.changed = true;
    //   }
    //   // if (event.type === "mousemove") {
    //   //   // process the event
    //   // }
    // },

    beforeDatasetsDraw(chart) {
      const {
        ctx,
        tooltip,
        scales: { x, y },
        chartArea: { top, bottom, left, right, width, height },
      } = chart;

      if (dataTemporary?.length > 0 && dataTemporary) {
        if (tooltip?._active[0] && tooltip?._active.length > 0) {
          const xCoordinate = tooltip?._active[0]?.element?.x;

          if (typeof xCoordinate !== "undefined") {
            ctx.beginPath();
            ctx.strokeStyle = "#4595F5";
            ctx.lineWidth = 1;
            ctx.setLineDash([6]);
            ctx.moveTo(xCoordinate, top);
            ctx.lineTo(xCoordinate, bottom);
            ctx.stroke();
            ctx.closePath();
            ctx.setLineDash([0]); // Kembalikan setLineDash ke nilai default
          }
        }
      }
    },
  };

  return (
    <div className={Styles.lineChartContent}>
      {isLoading
        &&
        <div className={Styles.loading}>
          <Rotation width="40px" />
        </div>
      }
      <div className={Styles.line}>
        <Line
          data={data}
          options={options}
          plugins={[hoverLine]}
          ref={boxRef}
        // height={250}
        />

      </div>

    </div>
  );
}
