import AutocompleteV2 from "@Atom/AutocompleteV2";
import Icon from "@Atom/Icon";
import InputSearchJob from "@Atom/InputSearchJob";
import useQuery from "@Hooks/useQuery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./styles.module.scss";

export default function FilterJobSeekerList({
  options,
  isLoading,
  showFilter,
  setShowFilter,
}) {
  const query = useQuery();
  const [valuePlacement, setValuePlacement] = useState(query.get("p") || "");
  const [valueTitle, setValueTitle] = useState(query.get("t") || "");

  useEffect(() => {
    setValuePlacement(query.get("p") || "");
    setValueTitle(query.get("t") || "");
  }, [query]);

  const navigate = useNavigate();

  function handleSearch() {
    navigate(`/job-seeker`, {
      state: { valueTitle, valuePlacement },
      // replace: true,
    });
    // setValuePlacement("");
    // setValueTitle("");
  }

  // const handleReset = () => {
  //   // setValuePlacement("");
  //   // setValueTitle("");
  //   navigate(`/job-seeker?p=&t=`, {
  //     // state: { valueTitle, valuePlacement },
  //     replace: true,
  //   });
  // };
  return (
    <div className={Styles.container}>
      <div className={Styles.bodyFilter}>
        <div className={Styles.topTitleFilter}>
          <span className={Styles.temukanPekerjaanmu}>Temukan Pekerjaanmu</span>
          <div
            className={Styles.filterIconBox}
            onClick={() => setShowFilter(true)}
          >
            <Icon icon={"adjustment"} size={20} color={"#404040"} />
            <span className={Styles.filterSpan}>Filter</span>
          </div>
        </div>
        <div className={Styles.inputSection}>
          <div className={Styles.inputBox}>
            <span className={Styles.text}>Pekerjaan</span>
            <div className={Styles.searchBox}>
              <InputSearchJob setValue={setValueTitle} value={valueTitle} />
            </div>
          </div>
          <div className={Styles.inputBox}>
            <span className={Styles.text}>Lokasi</span>
            <div className={Styles.searchBox}>
              <AutocompleteV2
                type={"job-seeker-placement"}
                placeholder={"Masukkan lokasi"}
                options={options}
                value={valuePlacement}
                setValue={setValuePlacement}
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className={Styles.buttonBox} onClick={handleSearch}>
            <span className={Styles.searchText}>Cari Pekerjaan</span>
            <Icon icon={"search"} size={20} color={"#FFFFFF"} />
          </div>
        </div>
      </div>
      {/* <div className={Styles.resetSection}>
        <span>
          Hapus pencarian posisi dan lokasi dengan mengklik tombol reset
        </span>
        <button className={`${Styles.reset}`} onClick={handleReset}>
          <span className={Styles.resetText}>Reset</span>
        </button>
      </div> */}
    </div>
  );
}
