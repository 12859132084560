import Icon from "@Atom/Icon";
import React from "react";
import Styles from "./style.module.scss";

export default function CustomBreadCrumb({ children }) {
  return (
    <BreadCrumb
      aria-label="breadcrumb"
      separator={<Icon icon="arrow-right" size={"20px"} />}
    >
      {children}
    </BreadCrumb>
  );
}

const BreadCrumb = ({ children, separator }) => {
  return (
    <div className={Styles.breadCrumb}>
      {children?.map((each, i) => (
        <div key={i} className={Styles.each}>
          {each}
          {i !== children?.length - 1 && (
            <div className={Styles.separator}>{separator}</div>
          )}
        </div>
      ))}
    </div>
  );
};
