import { Skeleton } from "@mui/material";
import Styles from "./style.module.scss";

export default function LoadingSkeleton() {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Skeleton
          variant="rectangular"
          animation={"wave"}
          className={Styles.title}
        />
        <Skeleton
          variant="rectangular"
          animation={"wave"}
          className={Styles.Description}
        />
      </div>
      <div className={Styles.content}>
        <Card />
        <Card />
      </div>
    </div>
  );
}

const Card = () => {
  return (
    <div className={Styles.card}>
      <div className={Styles.header}>
        <Skeleton
          variant="rectangular"
          animation={"wave"}
          className={Styles.title}
        />
        <Skeleton
          variant="rectangular"
          animation={"wave"}
          className={Styles.subTitle}
        />
      </div>
      <div className={Styles.content}>
        <div className={Styles.stacked}>
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            className={Styles.bullet}
          />
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            width={"371px"}
            className={Styles.text}
          />
        </div>
        <div className={Styles.stacked}>
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            className={Styles.bullet}
          />
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            width={"431px"}
            className={Styles.text}
          />
        </div>
        <div className={Styles.stacked}>
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            className={Styles.bullet}
          />
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            width={"451px"}
            className={Styles.text}
          />
        </div>
        <div className={Styles.stacked}>
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            className={Styles.bullet}
          />
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            width={"419px"}
            className={Styles.text}
          />
        </div>
        <div className={Styles.stacked}>
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            className={Styles.bullet}
          />
          <Skeleton
            variant="rectangular"
            animation={"wave"}
            width={"428px"}
            className={Styles.text}
          />
        </div>
      </div>
    </div>
  );
};
