import { useEffect, useRef } from "react";
import Styles from "./style.module.scss";
import TaxesSection from "./TaxesSection";
import BPJSSection from "./BPJSSection";
import OthersSection from "./OthersSection";

export default function SecondStep({
  form,
  setForm,
  options,
  isDone,
  onNext,
  onPrevious,
  setTrigger,
  bankOptions,
}) {
  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <TaxesSection
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        options={options.taxs}
      />
      <BPJSSection
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        options={options?.bpjs}
      />
      <OthersSection
        bankOptions={bankOptions}
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        options={options?.others}
      />
      <div className={Styles.buttonWrapper}>
        <button onClick={onPrevious}>Back</button>
        <button disabled={!isDone} onClick={onNext}>
          Next Step
        </button>
      </div>
    </div>
  );
}
