import Images from "@Theme/Images";
import Styles from "./style.module.scss";

export default function Zero({ handleMakeRequest }) {
  return (
    <div className={Styles.container}>
      <div className={Styles.imageBox}>
        <img src={Images?.NOTE_BOARD} alt="request" />
      </div>
      <h3>Man Power Request</h3>
      <p>Start requesting according to your needs</p>
      <button onClick={handleMakeRequest}>Make Request</button>
    </div>
  );
}
