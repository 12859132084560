import Styles from "./rightDecorativeInputField.module.scss";
import RightDecorativeInput from "@Atom/RightDecorativeInput";

const RightDecorativeInputField = ({
  title,
  type,
  value,
  setValue,
  adornment,
  deleteOption,
  onDelete,
  placeholder='',
  ...props
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.titleWrapper}>{title}</div>
      <RightDecorativeInput
        deleteOption={deleteOption}
        onDelete={onDelete}
        type={type}
        adornment={adornment}
        value={value}
        setValue={setValue}
        placeholder={placeholder}
      />
    </div>
  );
};

export default RightDecorativeInputField;
