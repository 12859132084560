export const getAddress = async (lat, lng, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB0Gy-BuZ0XyKlvhNtuhRPJh4RGdfQOQgE`
    );
    const data = await response.json();
    setLoading(false);
    if (data) {
      return data?.results[0]?.formatted_address || "Alamat tidak ditemukan";
    } else {
      return "Alamat tidak ditemukan";
    }
  } catch (error) {
    console.error("Error fetching address:", error);
    return "Gagal mendapatkan alamat";
  }
};
