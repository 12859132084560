import { useEffect, useState } from "react";
import Styles from "./decorativeInput.module.scss";
import CurrencyInput from "react-currency-input-field";

const DecorativeInput = ({ type, value, setValue=()=> {}, adornment, ...props }) => {
  const [currentValue, setCurrentValue] = useState(value || "");
  // const [maskedValue, setMaskedValue] = useState(10000);

  const changeValue = (targetValue) => {
    if (type === "number") {
      const re = /^[0-9\b]+$/;
      if (targetValue === "" || re.test(targetValue)) {
        setCurrentValue(targetValue);
      }
    }
    if (type === "string") {
      setCurrentValue(targetValue);
    }
    if (type === "currency") {
      // if (targetValue) {
        const firstParsing = targetValue.replace(/[^\d.]/gi, "");
        const parsedValue = firstParsing
          ?.replaceAll(",", "")
          ?.replaceAll(".", "");
        setCurrentValue(parsedValue);
      }
    // }
  };

  useEffect(() => {
    setValue(currentValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return (
    <div className={Styles.container}>
      <div className={Styles.adornment}>{adornment}</div>
      {type !== "currency" ? (
        <input
          className={Styles.input}
          type="text"
          id="fname"
          name="fname"
          value={currentValue}
          onChange={(e) => changeValue(e.target.value)}
        />
      ) : (
        <CurrencyInput
          value={currentValue}
          onChange={(e) => changeValue(e.target.value)}
          className={Styles.input}
          allowDecimals={false}
          decimalSeparator=","
          groupSeparator="."
        />
      )}
    </div>
  );
};

export default DecorativeInput;
