import LoadingAnimation from "@Atom/LoadingAnimation";
import useQuery from "@Hooks/useQuery";
import FulfillmentDetailRBM from "@Organism/Manager/FulfillmentDetailRBM";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getFulfillmentDetail } from "@Services/manager/bm";
import { Suspense, useEffect } from "react";
import {
  Await,
  defer,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

export async function loader(route) {
  const url = new URL(route?.request?.url);

  const clientCode = url.searchParams?.get("c");
  const id = route.params.id;

  const fulFillmentDetail = getFulfillmentDetail(clientCode, id, "");

  return defer({
    PackageFulFillmentDetail: fulFillmentDetail,
  });
}

export default function FulfillmentDetailRBMPage() {
  const { PackageFulFillmentDetail } = useLoaderData();
  const query = useQuery();
  const client = query?.get("c");
  const nameBM = query?.get("u");
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const pathForm = query.get("fr") || "";

  useEffect(() => {
    navigate(
      `${pathname}?c=${client || ""}&u=${nameBM || ""}&fr=${pathForm || ""}`
    );
  }, [client, pathname, navigate, nameBM, pathForm]);

  return (
    <Suspense
      fallback={
        <div
          style={{
            height: "calc(100vh - 220px)",
            display: "grid",
            placeItems: "center",
          }}
        >
          <LoadingAnimation />
        </div>
      }
    >
      <Await
        resolve={PackageFulFillmentDetail}
        errorElement={
          <div style={{ height: "calc(100vh - 220px)" }}>
            <AsyncErrorBoundary />
          </div>
        }
      >
        {(PackageFulFillmentDetail) => (
          <FulfillmentDetailRBM data={PackageFulFillmentDetail?.response} />
        )}
      </Await>
    </Suspense>
  );
}
