import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import Styles from "./style.module.scss";

const Desktop = (props) => {
  const {
    searchTerm,
    onChange,
    isSelected,
    handleSelectedEquipment,
    onCancel,
    onAdd,
    options,
  } = props;

  return (
    <div className={Styles.background}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h4>Work Equipment</h4>
          <div className={Styles.searchbarWrapper}>
            <SearchBar
              value={searchTerm}
              onChange={onChange}
              placeholder="Search Qualification"
            />
          </div>
        </div>
        <div className={Styles.content}>
          {options?.filter((el) =>
            el?.name?.toLowerCase().includes(searchTerm.toLowerCase())
          )?.length > 0 ? (
            <>
              {options
                ?.filter((el) =>
                  el?.name?.toLowerCase().includes(searchTerm.toLowerCase())
                )
                ?.map((each, i) => {
                  const isItemSelected = Boolean(isSelected(each));
                  return (
                    <div
                      key={i}
                      className={Styles.each}
                      onClick={() => handleSelectedEquipment(each)}
                    >
                      <label>{each?.name}</label>
                      <Checkbox
                        checked={isItemSelected}
                        sx={{ margin: 0, padding: 0, zIndex: 0 }}
                        icon={
                          <Icon
                            icon="radio-button-default"
                            size={20}
                            className={Styles.unchecked}
                          />
                        }
                        checkedIcon={
                          <Icon
                            icon="radio-button-active"
                            size={20}
                            className={Styles.checked}
                          />
                        }
                      />
                    </div>
                  );
                })}
            </>
          ) : (
            <div className={Styles.noOption}>No such location</div>
          )}
        </div>
        <div className={Styles.actionsWrapper}>
          <button onClick={onCancel}>
            <span>Cancel</span>
          </button>
          <button onClick={onAdd}>
            <span>Add</span>
          </button>
        </div>
      </div>
    </div>
  );
};
const Mobile = (props) => {
  const { isSelected, handleSelectedEquipment, onCancel, onAdd, options } =
    props;

  return (
    <div className={Styles.mobileContainer}>
      <div className={Styles.topWrapper}>
        <div className={Styles.topLine} />
        <div className={Styles.header}>
          <h3>Add Qualification</h3>
        </div>
      </div>
      <div className={Styles.content}>
        {options?.map((each, i) => {
          const isItemSelected = Boolean(isSelected(each));
          return (
            <div
              key={i}
              className={Styles.each}
              onClick={() => handleSelectedEquipment(each)}
            >
              <label>{each?.name}</label>
              <Checkbox
                checked={isItemSelected}
                sx={{ margin: 0, padding: 0, zIndex: 0 }}
                icon={
                  <Icon
                    icon="radio-button-default"
                    size={20}
                    className={Styles.unchecked}
                  />
                }
                checkedIcon={
                  <Icon
                    icon="radio-button-active"
                    size={20}
                    className={Styles.checked}
                  />
                }
              />
            </div>
          );
        })}
      </div>
      <div className={Styles.actionsWrapper}>
        <button onClick={onCancel}>
          <span>Cancel</span>
        </button>
        <button onClick={onAdd}>
          <span>Add</span>
        </button>
      </div>
    </div>
  );
};

export default function AreaEquipmentsModal(props) {
  const {
    type,
    setShowAdd,
    form,
    locationIndex,
    options,
    trigger,
    setTrigger,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");

  const [equipmentsArr, setEquipmentsArr] = useState([]);

  useEffect(() => {
    setEquipmentsArr(form[locationIndex]?.areaWorkEquipments);
  }, [form, locationIndex, trigger]);

  const onChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectedEquipment = (option) => {
    const data = [...equipmentsArr];
    const isExist = data?.find((item) => item?.name === option?.name);

    if (!isExist) {
      data.push({ ...option });
    } else {
      const index = data?.findIndex((object) => {
        return object.name === option?.name;
      });
      data.splice(index, 1);
    }

    setEquipmentsArr(data);
  };

  const onCancel = () => {
    setEquipmentsArr(form[locationIndex].areaWorkEquipments);
    setShowAdd(false);
  };

  const onAdd = () => {
    form[locationIndex].areaWorkEquipments = equipmentsArr;
    setTrigger(Math.random());
    setShowAdd(false);
  };

  const isSelected = (option) =>
    equipmentsArr?.find((item) => item?.name === option?.name);

  switch (type) {
    case "mobile":
      return (
        <Mobile
          searchTerm={searchTerm}
          onChange={onChange}
          handleSelectedEquipment={handleSelectedEquipment}
          onCancel={onCancel}
          onAdd={onAdd}
          isSelected={isSelected}
          options={options}
        />
      );
    case "desktop":
      return (
        <Desktop
          searchTerm={searchTerm}
          onChange={onChange}
          handleSelectedEquipment={handleSelectedEquipment}
          onCancel={onCancel}
          onAdd={onAdd}
          isSelected={isSelected}
          options={options}
        />
      );
    default:
      return (
        <Desktop
          searchTerm={searchTerm}
          onChange={onChange}
          handleSelectedEquipment={handleSelectedEquipment}
          onCancel={onCancel}
          onAdd={onAdd}
          isSelected={isSelected}
          options={options}
        />
      );
  }
}
