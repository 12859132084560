import React, { useEffect, useState } from 'react';
import Styles from './style.module.scss';
import ModalWrapper from '@Atom/ModalWrapper';
import Tooltip from '@Atom/Tooltip';
import Icon from '@Atom/Icon';
import { getClientEmployee, getClientEmployeeByEmployeeID } from '@Services/client/dashboard';
import { fileBaseUrl } from '@Config/api';
import AVA_DEFAULT from '@Assets/Images/ava-default.png';
import moment from 'moment';
import SearchBar from '@Atom/SearchBar';
import StaticProgressBar from '@Atom/StaticProgressBar';
import TableEmptyHandler from '@Atom/TableEmptyHandler';
import { Skeleton } from '@mui/material';
import Pagination from '@Molecule/Pagination';
import useWindowSize from '@Hooks/useWindowSize';
import { useDebounce } from '@Hooks/useDebounce';
import { useCallback } from 'react';

export default function DetailLocationModal({
    vacant,
    vendor,
    request,
    fulfilled,
    cityName,
    position,
    clientCode,
    show,
    onClose = () => {},
}) {
    const [selectedEmployee, setSelectedEmployee] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataEmployee, setDataEmployee] = useState({});
    const [tab, setTab] = useState('LIST');
    const [totalPage, setTotalPage] = useState(1);
    const [totalData, setTotalData] = useState(1);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState('');
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [pageList, setPageList] = useState([]);
    const [error, setError] = useState('');
    const { width } = useWindowSize();
    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        async function fetch() {
            try {
                setIsLoading(true);
                const { response } = await getClientEmployee(
                    clientCode,
                    page,
                    limit,
                    debouncedSearch,
                    position,
                    cityName
                );
                setTotalPage(response?.totalPage || 1);
                setTotalData(response?.totalData || 1);
                setData(response?.data || []);
                setIsLoading(false);
            } catch (error) {
                console.log('error', error);
                setIsLoading(false);
                setError(error?.response);
            }
        }
        fetch();
    }, [page, limit, position, cityName, debouncedSearch, clientCode]);

    useEffect(() => {
        async function fetchDetail() {
            try {
                setIsLoading(true);
                const { response } = await getClientEmployeeByEmployeeID(selectedEmployee?.employeeId);
                setDataEmployee(response);
                setIsLoading(false);
            } catch (error) {
                console.log('error', error);
                setIsLoading(false);
                setError(error?.response);
            }
        }
        if (selectedEmployee?.employeeId) {
            setTab('DETAIL');
            fetchDetail();
        }
    }, [selectedEmployee?.employeeId]);

    return (
        <ModalWrapper show={show} handleClose={onClose}>
            <div className={Styles.container}>
                <div className={Styles.headerWrapper}>
                    <div className={Styles.closeButton} onClick={onClose}>
                        <Icon icon={'cross'} size={'24px'} color={'#000'} />
                    </div>
                    <span>Daftar Karyawan Hired - {position}</span>
                    <div className={Styles.locationIcon}>
                        <Icon icon={'company'} size={'24px'} />
                        <span>{cityName}</span>
                    </div>
                </div>
                <div className={Styles.progressWrapper}>
                    <span>Jumlah Karyawan Hired</span>
                    <Tooltip
                        content={
                            <div className={Styles.tooltip}>
                                <p>
                                    <span>Jumlah Terpenuhi</span>
                                    <span>:</span>
                                    <span>{`${fulfilled} (${
                                        request == 0 ? 0 : Math.floor((fulfilled / request) * 100)
                                    }%)`}</span>
                                </p>
                                <p>
                                    <span>Vendor Lain</span>
                                    <span>:</span>
                                    <span>{vendor}</span>
                                </p>
                                <p>
                                    <span>Vacant</span>
                                    <span>:</span>
                                    <span>{vacant}</span>
                                </p>
                            </div>
                        }
                        direction="top"
                        backgroundColor="#fff"
                        boxShadow="0px 6px 24px 0px rgba(0, 0, 0, 0.15)"
                        tooltipMargin="75px"
                        isV2>
                        <StaticProgressBar
                            fulfilledAmount={fulfilled}
                            candidatesAmount={vendor}
                            candidatesText={vendor}
                            requestAmount={request}
                            vacantAmount={vacant}
                            isV3
                            borderRadius="0px"
                        />
                    </Tooltip>
                </div>
                <div className={Styles.content}>
                    <div
                        className={`${Styles.tabDetailContainer} ${
                            tab === 'DETAIL' ? Styles.slideIn : Styles.slideOut
                        }`}>
                        <div className={Styles.titleDetailWrapper}>
                            <div
                                className={Styles.buttonIcon}
                                onClick={() => {
                                    setTab('LIST');
                                    setDataEmployee({});
                                    setSelectedEmployee({});
                                }}>
                                <Icon icon={'arrow-left'} size={'24px'} />
                            </div>
                            <span>Detail Karyawan</span>
                        </div>
                        <div className={Styles.contentDetailWrapper}>
                            <div className={Styles.leftWrapper}>
                                <div className={Styles.profile}>
                                    <img
                                        src={
                                            dataEmployee?.profilePicture?.url
                                                ? fileBaseUrl + dataEmployee?.profilePicture?.url
                                                : AVA_DEFAULT
                                        }
                                        alt="profile"
                                    />
                                    <div className={Styles.nameWrapper}>
                                        <span>{dataEmployee?.name || '-'}</span>
                                        <span>{dataEmployee?.position || '-'}</span>
                                    </div>
                                </div>
                                <div className={Styles.divider} />
                                <div className={Styles.information}>
                                    <div className={Styles.title}>
                                        <span>Informasi Umum</span>
                                    </div>
                                    <div className={Styles.textInformation}>
                                        <span>Posisi</span>
                                        <span>: {dataEmployee?.position || '-'}</span>
                                    </div>
                                    <div className={Styles.textInformation}>
                                        <span>Tanggal Lahir</span>
                                        <span>
                                            :{' '}
                                            {dataEmployee?.birthDate
                                                ? moment(dataEmployee?.birthDate).format('DD MMMM YYYY')
                                                : '-'}
                                        </span>
                                    </div>
                                    <div className={Styles.textInformation}>
                                        <span>Domisili</span>
                                        <span>: {dataEmployee?.domisili || '-'}</span>
                                    </div>
                                    <div className={Styles.textInformation}>
                                        <span>Alamat</span>
                                        <span>: {dataEmployee?.alamat || '-'}</span>
                                    </div>
                                </div>
                            </div>

                            <div className={Styles.rightWrapper}>
                                <div className={Styles.workExperience}>
                                    <div className={Styles.title}>
                                        <span>Pengalaman Kerja</span>
                                    </div>
                                    <div className={Styles.workWrapper}>
                                        {dataEmployee?.workExperiences?.map((value, index) => (
                                            <div key={index}>
                                                <div
                                                    className={`${Styles.itemWrapper} ${
                                                        index === 0 ? Styles.firstChildPadding : ''
                                                    }`}
                                                    key={`workExperiences_${index}`}>
                                                    <div className={Styles.leftItem}>
                                                        <span>
                                                            {value?.startMonth} {value?.startYear} - {value?.endMonth}{' '}
                                                            {value?.endYear}
                                                        </span>
                                                        <span>
                                                            {value?.position} {value?.companyName}
                                                        </span>
                                                    </div>
                                                    <div className={Styles.rightItem}>
                                                        <span>{value?.remark}</span>
                                                    </div>
                                                </div>
                                                <div className={Styles.divider}/>
                                            </div>
                                        ))}
                                        {dataEmployee?.workExperiences?.length === 0 && (
                                            <>
                                                <div className={Styles.emptyData}>
                                                    <span>Tidak ada data</span>
                                                </div>
                                                <div className={Styles.divider} />
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className={Styles.lastEducation}>
                                    <div className={Styles.title}>
                                        <span>Pendidikan Terakhir</span>
                                    </div>
                                    {dataEmployee?.lastEducation ? (
                                        <div className={Styles.text}>
                                            <span>{dataEmployee?.lastEducation}</span>
                                        </div>
                                    ) : (
                                        <div className={Styles.emptyData}>
                                            <span>Tidak ada data</span>
                                        </div>
                                    )}
                                    <div className={Styles.divider} />
                                </div>
                                <div className={Styles.otherInformation}>
                                    <div className={Styles.title}>
                                        <span>Informasi Lainnya</span>
                                    </div>
                                    {dataEmployee?.otherInformations?.length > 0 ? (
                                        dataEmployee?.otherInformations?.map((value, index) => (
                                            <div className={Styles.textInformation} key={`otherInformation_${index}`}>
                                                <span>{value?.skill}</span>
                                                <span>{value?.value}</span>
                                            </div>
                                        ))
                                    ) : (
                                        <div className={Styles.emptyData}>
                                            <span>Tidak ada data</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${Styles.tabListContainer} ${tab === 'LIST' ? Styles.slideIn : Styles.slideOut}`}>
                        <div className={Styles.titleWrapper}>
                            <span>Daftar Karyawan</span>
                            <div className={Styles.searchWrapper}>
                                <SearchBar
                                    value={search}
                                    onChange={(e) => setSearch(e?.target?.value)}
                                    placeholder={'Cari Nama'}
                                />
                            </div>
                        </div>
                        <div className={Styles.tableWrapper} style={{ flexGrow: data?.length ? 1 : 'auto' }}>
                            <table>
                                <thead>
                                    {width > 788 ? (
                                        <tr>
                                            <th>Nama</th>
                                            <th>Tanggal Bergabung & Berakhir</th>
                                            <th>Status Kontrak</th>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <th>Nama</th>
                                            <th>Tanggal Bergabung & Berakhir</th>
                                        </tr>
                                    )}
                                </thead>
                                <tbody>
                                    {isLoading
                                        ? [...Array(5)]?.map((value, index) => {
                                              return width > 768 ? (
                                                  <tr key={index}>
                                                      <td>
                                                          <Skeleton
                                                              // variant="circular"
                                                              width={'100%'}
                                                              height={30}
                                                              sx={{
                                                                  borderRadius: '12px',
                                                              }}
                                                          />
                                                      </td>
                                                      <td>
                                                          <Skeleton
                                                              // variant="circular"
                                                              width={'100%'}
                                                              height={30}
                                                              sx={{
                                                                  borderRadius: '12px',
                                                              }}
                                                          />
                                                      </td>
                                                      <td>
                                                          <Skeleton
                                                              // variant="circular"
                                                              width={'100%'}
                                                              height={30}
                                                              sx={{
                                                                  borderRadius: '12px',
                                                              }}
                                                          />
                                                      </td>
                                                  </tr>
                                              ) : (
                                                  <tr key={index}>
                                                      <td>
                                                          <Skeleton
                                                              // variant="circular"
                                                              width={'100%'}
                                                              height={30}
                                                              sx={{
                                                                  borderRadius: '12px',
                                                              }}
                                                          />
                                                      </td>
                                                      <td>
                                                          <Skeleton
                                                              // variant="circular"
                                                              width={'100%'}
                                                              height={30}
                                                              sx={{
                                                                  borderRadius: '12px',
                                                              }}
                                                          />
                                                      </td>
                                                  </tr>
                                              );
                                          })
                                        : data.length > 0 &&
                                          data?.map((value, index) =>
                                              width > 768 ? (
                                                  <tr
                                                      key={index}
                                                      onClick={() => {
                                                          setSelectedEmployee(value);
                                                      }}>
                                                      <td className={Styles.profile}>
                                                          <img
                                                              src={
                                                                  value?.profilePicture?.url
                                                                      ? fileBaseUrl + value?.profilePicture?.url
                                                                      : AVA_DEFAULT
                                                              }
                                                              alt="profile"
                                                          />
                                                          <span>{value?.nama}</span>
                                                      </td>
                                                      <td>
                                                          <span>
                                                              {moment(value?.kontrak_start).format('DD MMM YY')} -{' '}
                                                              {moment(value?.kontrak_end).format('DD MMM YY')}
                                                          </span>
                                                      </td>
                                                      <td>
                                                          <span>{value?.jenis_kontrak}</span>
                                                      </td>
                                                  </tr>
                                              ) : (
                                                  <tr
                                                      key={index}
                                                      onClick={() => {
                                                          setSelectedEmployee(value);
                                                      }}>
                                                      <td className={Styles.profile}>
                                                          <img
                                                              src={
                                                                  value?.profilePicture?.url
                                                                      ? fileBaseUrl + value?.profilePicture?.url
                                                                      : AVA_DEFAULT
                                                              }
                                                              alt="profile"
                                                          />
                                                          <span>{value?.nama}</span>
                                                      </td>
                                                      <td>
                                                          <span>
                                                              {moment(value?.kontrak_start).format('DD MMM YY')} -{' '}
                                                              {moment(value?.kontrak_end).format('DD MMM YY')}
                                                          </span>
                                                      </td>
                                                  </tr>
                                              )
                                          )}
                                </tbody>
                            </table>
                        </div>
                        {!isLoading && !data?.length && (
                            <div className={Styles.emptyWrapper}>
                                <TableEmptyHandler />
                            </div>
                        )}
                        <div className={Styles.paginationWrapper}>
                            <Pagination
                                total={totalData || 1}
                                totalPage={totalPage || 0}
                                value={limit}
                                setValue={setLimit}
                                pages={pages}
                                setPages={setPages}
                                selectedPage={page}
                                setSelectedPage={setPage}
                                pageList={pageList}
                                setPageList={setPageList}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
}
