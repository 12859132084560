/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import LoadingAnimation from "@Atom/LoadingAnimation";
import CustomBreadCrumb from "@Molecule/CustomBreadCrumb";
import ClientOrderListData from "@Organism/Client/Orders/OrderList";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getClientOrdersClient } from "@Services/client/order";
import { Suspense, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export async function loader(route) {
  const code = localStorage.getItem("clientCode");
  // const code = sessionStorage.getItem('clientCode');

  const packageOrdersPromise = getClientOrdersClient(code, 1, 10)

  return defer({
    packageOrders: packageOrdersPromise
  })


}

export default function ClientOrderList() {
  const { packageOrders } = useLoaderData();

  const [searchTerm, setSearchTerm] = useState('')
  const [sort, setSort] = useState('')

  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate(`/dashboard`);
  };

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Work Schemes</title>
      </Helmet>
      <div className={Styles.topWrapper}>
        <div className={Styles.breadcrumbWrapper}>
          <span onClick={handleClickBack}>
            <Icon icon="arrow-left-rounded-primary" color="#1571DE" size={20} />
          </span>
          <CustomBreadCrumb>
            <span className={Styles.breadcrumbText}>Job Orders</span>
            <span className={Styles.breadcrumbText2}>All List</span>
          </CustomBreadCrumb>
        </div>
      </div>

      <div className={Styles.contentWrapper}>
        <Suspense fallback={
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        }>
          <Await
            resolve={packageOrders}
            errorElement={<div style={{ height: "calc(100vh - 220px)" }}>
              <AsyncErrorBoundary />
            </div>}
          >
            {(packageOrders) => (
              <ClientOrderListData
                defaultOrderData={packageOrders?.response?.data}
                defaultTotalPage={packageOrders?.response?.totalPage}
                searchTerm={searchTerm}
                sort={sort}
              />
            )}

          </Await>

        </Suspense>

      </div>
    </div>
  );
}
