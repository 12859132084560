import { useMemo, useState } from "react";
import Styles from "./style.module.scss";

const Tooltip = ({
  delay = 200,
  children,
  direction = "bottom",
  content,
  width = "auto",
  noWhiteSpace = false,
  backgroundColor = "#404040",
  boxShadow = "",
  tooltipMargin = "30px",
  isV2 = false,
  padding = "6px 8px",
  bottomMargin = "",
}) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const top = useMemo(() => {
    switch (direction) {
      case "top":
        return `calc(${tooltipMargin} * -1)`;
      case "bottom":
        return "";
      default:
        return "50%";
    }
  }, [direction, tooltipMargin]);

  // const styles1 = `
  // .tooltipTip.top::before {
  //   border-top-color: ${backgroundColor}
  // }
  // `
  // const styles = {
  //   '&.tooltipTip.top::before': {
  //     borderTopColor: backgroundColor
  //   }
  // }

  return (
    <div
      className={Styles.tooltipWrapper}
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {children}
      {active && (
        <div
          // tooltipMargin={tooltipMargin}
          data-backgroundcolor={backgroundColor}
          className={`${Styles.tooltipTip} ${Styles[direction]} ${
            isV2 ? Styles.v2 : ""
          }`}
          style={{
            width,
            whiteSpace: noWhiteSpace ? "nowrap" : "normal",
            backgroundColor,
            boxShadow,
            top,
            padding,
            bottom: bottomMargin ? bottomMargin : "",
          }}
        >
          {/* Content */}
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
