import SearchBar from "@Atom/SearchBar";
import StaticProgressBar from "@Atom/StaticProgressBar";
import Tooltip from "@Atom/Tooltip";
import { fileBaseUrl } from "@Config/api";
import useWindowSize from "@Hooks/useWindowSize";
import CustomTable from "@Molecule/CustomTable";
import Images from "@Theme/Images";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export default function BMListLayout({
  isLoading,
  totalPage = 0,
  value = 10,
  setValue,
  selectedPage,
  setSelectedPage,
  pageList,
  setPageList,
  pages,
  setPages,
  searchTerm,
  setSearchTerm,
  data = [],
}) {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const columns = [
    {
      name: "businessManager",
      label: (
        <div className={Styles.ppTitle}>
          <span>Business Manager</span>
          <span>Jumlah Klien</span>
        </div>
      ),
      renderData: (row) => (
        <div className={Styles.ppWrapper}>
          <div className={Styles.pp}>
            <img
              src={
                row?.User?.profilePicture
                  ? row?.User?.profilePicture?.includes("http")
                    ? row?.User?.profilePicture?.replace(
                        "https://myworkspace",
                        "https://api1.myworkspace"
                      )
                    : fileBaseUrl + row?.User?.profilePicture
                  : Images.AVA_DEFAULT
              }
              alt=""
            />
            <div>
              <span>{row?.User?.name}</span>
              <span>{row?.User?.jobLevel}</span>
            </div>
          </div>
          <span>{row?.totalClient}</span>
        </div>
      ),
      minWidth: "300px",
      maxWidth: "300px",
      tdClassName: Styles.sticky,
      thClassName: Styles.sticky,
    },
    // {
    //   name: 'totalClient',
    //   label: 'Jumlah Klien',
    //   align: 'right'
    // },
    {
      name: "",
      hideOnMobile: true,
      label: "Jumlah Permintaan & Terpenuhi",
      renderData: (row) => (
        <div className={Styles.tooltipWrapper}>
          <Tooltip
            content={
              <div className={Styles.tooltip}>
                <p>
                  <span>Jumlah Terpenuhi</span>
                  <span>:</span>
                  <span>{`${row?.fullfillment || 0} (${Math.floor(
                    (row?.fullfillment / row?.quota) * 100
                  )}%)`}</span>
                </p>
                <p>
                  <span>Vendor Lain</span>
                  <span>:</span>
                  <span>{row?.vendor || 0}</span>
                </p>
                <p>
                  <span>Vacant</span>
                  <span>:</span>
                  <span>{row?.vacant || 0}</span>
                </p>
              </div>
            }
            direction="top"
            backgroundColor="#fff"
            boxShadow="0px 6px 24px 0px rgba(0, 0, 0, 0.15)"
            tooltipMargin="80px"
            isV2
          >
            <StaticProgressBar
              fulfilledAmount={row?.fullfillment || 0}
              middleFulfilledText={`${Math.floor(
                (row?.fullfillment / row?.quota) * 100
              )}%`}
              // fulfilledText={`${row?.fulfillment || 0} (${Math.floor(+row?.fulfillment / +row?.quota * 100)}%)`}
              candidatesAmount={row?.fulfillment || 0 + row?.vendor || 0}
              candidatesText={row?.vendor || 0}
              requestAmount={row?.quota || 0}
              vacantAmount={row?.vacant || 0}
              isV3
              borderRadius="0px"
              height={width > 768 ? "32px" : "28px"}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.searchWrapper}>
          <SearchBar
            value={searchTerm}
            onChange={(e) => setSearchTerm(e?.target?.value)}
            placeholder={"Search Business Manager"}
          />
        </div>
        <div className={Styles.legends}>
          {[
            {
              label: <p>Jumlah Terpenuhi</p>,
              backgroundColor: "#1571DE",
            },
            {
              label: (
                <p>
                  <span>Vendor</span> Lain
                </p>
              ),
              backgroundColor: "#91BDF1",
            },
            {
              label: (
                <p>
                  <span>Vacant</span>
                </p>
              ),
              backgroundColor: "#E6E3E3",
            },
          ]?.map((l, lI) => (
            <div key={lI}>
              <div style={{ backgroundColor: l?.backgroundColor }} />
              {l?.label}
            </div>
          ))}
        </div>
      </div>
      <div className={Styles.tableWrapper}>
        <CustomTable
          data={data?.filter((obj) =>
            obj?.name?.toUpperCase()?.includes(searchTerm?.toUpperCase())
          )}
          columns={columns}
          // isLoading={isLoading}
          isLoadingSkeleton={isLoading}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          value={value}
          setValue={setValue}
          totalPage={totalPage}
          withNumbering={width > 768}
          oddRowClassName={Styles.odd}
          isLoading={isLoading}
          type="sticky-left"
          onClickRow={(row) =>
            navigate(`${pathname}/${row?.bmID}?u=${row?.User?.name}`)
          }
          // isError={showAlert && !tempContacts?.length}
        />
      </div>
    </div>
  );
}
