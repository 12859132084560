import Icon from "@Atom/Icon";
import styled from "@emotion/styled";
import { Autocomplete, Paper, TextField } from "@mui/material";
import Styles from "./style.module.scss";

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    color: "#0a0a0a",
    fontSize: "14px",
    fontWeight: 400,
    background: "#ffffff",
    borderRadius: 8,
    "& fieldset": {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
    "&:hover fieldset": {
      border: "1px solid #E0E0E0",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #1571DE",
    },
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "14px",
  },
  "& .MuiInputBase-input": {
    height: "16px",
  },
});

export default function AutoCompleteStaticField({
  type,
  options,
  placeholder,
  value,
  setValue,
  icon,
  readOnly,
}) {
  return (
    <div className={Styles.container}>
      <Autocomplete
        disablePortal
        readOnly={readOnly || false}
        value={null}
        blurOnSelect
        options={options}
        getOptionLabel={(option) => option?.name || option}
        popupIcon={<Icon icon="arrow-down" size="24px" color="#0a0a0a" />}
        onChange={(e, value) =>
          value === null ? setValue(null) : setValue(value)
        }
        PaperComponent={({ children }) => (
          <Paper onClick={(e) => e.preventDefault()} className={Styles.paper}>
            {children}
          </Paper>
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option?.id || Math.random()}>
              <div>
                <span className={Styles.clientOption}>
                  {option?.name || option}
                </span>
              </div>
              <div className={Styles.separator} />
            </li>
          );
        }}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: <Icon icon={icon} size={20} color={"#404040"} />,
            }}
          />
        )}
      />
    </div>
  );
}
