/* eslint-disable no-unused-vars */

import styled from "@emotion/styled";
import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import ContractTypeSection from "./ContractType";
import PaymentSection from "./Payment";
import Styles from "./style.module.scss";
import WorkSchemesSection from "./WorkScheme";
import ManPowerSection from "./ManPower";

const CustomTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1571DE",
    height: "3px",
  },
  "& .MuiButtonBase-root": {
    // backgroundColor: "#1890ff",
    fontWeight: 400,
    fontSize: "14px",
    textTransform: "capitalize",
  },
  "& .Mui-selected": {
    color: "#1571DE",
    fontWeight: 500,
    fontSize: "15px",
  },
});

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default function FifthStep({
  form,
  contractType,
  fixedManfee,
  fixedBpjsCut,
  cities,
  onPrevious,
  onSubmit,
  isSubmitting,
  isSuccess,
  setIsSuccess,
  error,
  setError,
  ppn
}) {
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.tabWrapper}>
        <div className={Styles.tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <CustomTabs
              value={tab}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons={true}
              allowScrollButtonsMobile
            >
              <Tab label="Contract Type" />
              <Tab label="Work Scheme" />
              <Tab label="Man Power" />
              <Tab label="Payment Method & Estimation" />
            </CustomTabs>
          </Box>
        </div>
      </div>
      <div className={Styles.contentWrapper}>
        <TabPanel value={tab} index={0}>
          <ContractTypeSection
            options={contractType}
            contractType={form.contractType}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <WorkSchemesSection data={form.workSchemes} />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <ManPowerSection
            manPower={form.manPowers}
            cities={cities}
            remark={form.remark}
          />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <PaymentSection
            fixedManfee={fixedManfee}
            fixedBpjsCut={fixedBpjsCut}
            cities={cities}
            form={form.manPowers}
            workSchemeForm={form.workSchemes}
            manfee={form.setManFee}
            data={form}
            ppn={ppn}
          />
        </TabPanel>
      </div>
      <div className={Styles.buttonWrapper}>
        <button onClick={onPrevious} type="secondary-black">
          Back
        </button>
      </div>
    </div>
  );
}
