import React from "react";
import Styles from "./style.module.scss";

export default function WorkSchemesSection({ data }) {
  const datas = [
    {
      title: "Tax",
      content: [
        {
          title: "PPH21",
          value: data.taxs.pph21,
        },
        {
          title: "PPH23",
          value: data.taxs.pph23,
        },
        {
          title: "PPN",
          value: data.taxs.ppn,
        },
      ],
    },
    {
      title: "BPJS",
      content: [
        {
          title: "Pendfataran BPJS",
          value: data.bpjs.registration,
        },
        {
          title: "Bukti Pembayaran BPJS",
          value: data.bpjs.paymentProof,
        },
        {
          title: "Potongan BPJS TKO",
          value: data.bpjs.cutOffBPJSTKO,
        },
      ],
    },
    {
      title: "Ketenagakerjaan",
      content: [
        {
          title: "JKK (Jaminan Kelengkapan Kerja)",
          value: data.bpjs.jkk || "-",
        },
        {
          title: "JKM (Jaminan Kematian)",
          value: data.bpjs.jkm || "-",
        },
        {
          title: "JHT (Jaminan Hari Tua)",
          value: data.bpjs.jht || "-",
        },
        {
          title: "JP (Jaminan Pansiun)",
          value: data.bpjs.jp || "-",
        },
        {
          title: "KS (Kesehatan)",
          value: data.bpjs.health || "-",
        },
        {
          title: "AKDHK (Asuransi Kecelakaan Diluar Hari Kerja)",
          value: data.bpjs.akhdk || "-",
        },
      ],
    },
    {
      title: "Others",
      content: [
        {
          title: "Pengenaan manfee",
          value: data.others.manfee.map((el) => el.name).join(", "),
        },
        {
          title: "Penagihan THR",
          value: data.others.thr,
        },
        {
          title: "Penagihan UAK",
          value: data.others.uak,
        },
        {
          title: "Rekening Wajib TKO",
          value: data.others.tkoBank.map((el) => el).join(", "),
        },
      ],
    },
  ];

  return (
    <div className={Styles.container}>
      <div className={Styles.sectionWrapper}>
        {datas.map((each, idx) =>
          each.title !== "Ketenagakerjaan" ? (
            <div className={Styles.section} key={idx}>
              <p className={Styles.headTitle}>{each.title}</p>
              <div className={`${Styles.contentWrapper} ${Styles.twoRows}`}>
                {each?.content.map((el, idxs) => (
                  <div className={Styles.content} key={idxs}>
                    <p className={Styles.contentTitle}>{el.title}</p>
                    <p className={Styles.contentBody}>{el.value}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div
              className={`${Styles.section} ${Styles.childSection}`}
              key={idx}
            >
              <p className={Styles.sectionTitle}>{each.title}</p>
              <div className={`${Styles.contentWrapper} ${Styles.threeRows}`}>
                {each?.content.map((el, idxs) => (
                  <div
                    className={`${Styles.content} ${Styles.childContent}`}
                    key={idxs}
                  >
                    <p className={Styles.contentTitle}>{el.title}</p>
                    <p className={Styles.contentBody}>{el.value}</p>
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
