import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";

export default function InputSearchJob({ value, setValue, type, onReset }) {
  return (
    <div className={Styles.inputBox}>
      {type === "filter-add" ? null : (
        <Icon
          icon={"briefcase-outlined"}
          size={20}
          color={"#0A0A0A"}
          className={Styles.iconJob}
        />
      )}
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        type="text"
        placeholder="Tulis Posisi"
        className={`${Styles.inputSearchJob} ${
          type === "filter-add" && Styles.inputAdd
        }`}
      />
      {type === "job-seeker" && (
        <div className={Styles.iconClose} onClick={onReset}>
          <Icon icon={"cross-circle"} size={20} />
        </div>
      )}
    </div>
  );
}
