import Styles from './style.module.scss'

export default function Switch({
  isChecked = false,
  setIsChecked = () => { },
  height = '28px',
  width = '',
  padding = '2px',
  offBg = '',
  onBg = ''
}) {

  return (
    <div
      className={`${Styles.container} ${isChecked ? Styles.checked : ''}`}
      onClick={() => {
        console.log('cliccc')
        setIsChecked(!isChecked)
      }}
      style={{ height: height, width: width || `calc(${height} * 2)`, borderRadius: height, backgroundColor: isChecked ? onBg : offBg }}
    >
      <div
        className={Styles.ball}
        style={{ height: `calc(${height} - calc(${padding} * 2))`, left: isChecked ? (width ? `calc(${width} - ${height} + ${padding})` : `calc(${height} + ${padding})`) : padding, top: padding }}
      />
    </div>
  )
}