import { Avatar, Rating } from "@mui/material";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#1571DE",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

export default function ViewDetailModal({ data, setData }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={Styles.container}>
      <div className={Styles.topLine} />
      <div className={Styles.header}>
        <h3>Employee Detail</h3>
      </div>
      <div className={Styles.infoWrapper}>
        <div className={Styles.profile}>
          {data?.profilePicture ? (
            <Avatar className={Styles.avatar} src={data?.profilePicture} />
          ) : (
            <Avatar className={Styles.avatar}>{data?.name[0]}</Avatar>
          )}
          <div className={Styles.desc}>
            <span>{data?.name}</span>
            <span>{data?.jobTitle}</span>
          </div>
        </div>
        <div className={Styles.contact}>
          <h4>Contact</h4>
          <div className={Styles.stacked}>
            <Icon icon="email-outline" className={Styles.icon} size={15} />
            <span>{data?.email}</span>
          </div>
          <div className={Styles.stacked}>
            <Icon icon="phone-outline" className={Styles.icon} size={15} />
            <span>{data?.phoneNumber}</span>
          </div>
        </div>
        <div className={Styles.rating}>
          <h4>Rating</h4>
          <StyledRating
            value={data?.rating}
            precision={1}
            size="large"
            icon={<Icon icon="star" size={22} />}
            emptyIcon={<Icon icon="star" size={22} color="#e0e0e0" />}
            readOnly
          />
        </div>
        <div className={Styles.status}>
          <h4>Status</h4>
          <span
            className={
              data?.status === "Active" ? Styles.active : Styles.inactive
            }
          >
            {data?.status}
          </span>
        </div>
      </div>
      <button
        onClick={() =>
          navigate(
            `${location?.pathname}/details?n=${data?.name}&c=${data?.id}`
          )
        }
      >
        More Info
      </button>
    </div>
  );
}
