import Icon from "@Atom/Icon";
import { NavLink } from "react-router-dom";
import Styles from "../navbar.module.scss";
import { useState } from "react";

export default function ClientNavbar({
  active,
  activeInfo,
  setOpen,
  onHover,
  width,
}) {
  const [expandOrder, setExpandOrder] = useState(false);
  return (
    <div className={Styles.navWrapper}>
      <NavLink
        to={`/dashboard`}
        onClick={() => setOpen(false)}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          <div className={Styles.iconDash}>
            <Icon
              icon="dashboard-filled"
              color={activeInfo[1] !== "dashboard" ? "#757575" : "#1571DE"}
              size={20}
            />
          </div>

          {width > 768 && onHover && <span>Dashboard</span>}
          {width <= 768 && <span>Dashboard</span>}
        </button>
      </NavLink>
      <NavLink
        to={`/employee`}
        onClick={() => setOpen(false)}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          <div className={Styles.iconDash}>
            <Icon
              icon="employee"
              size={20}
              color={activeInfo[1] === "employee" ? "#1571DE" : "#757575"}
            />
          </div>

          {width > 768 && onHover && <span>Employee</span>}
          {width <= 768 && <span>Employee</span>}
        </button>
      </NavLink>
      {/* <NavLink
        to={`/order`}
        onClick={() => setOpen(false)}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      > */}
      <button
        onClick={() => setExpandOrder(!expandOrder)}
        className={`${Styles.navButton} ${
          activeInfo[1] === "order" ? Styles.navActive : ""
        }`}
      >
        <div className={Styles.iconDash}>
          <Icon
            icon="order"
            size={20}
            color={activeInfo[1] === "order" ? "#1571DE" : "#757575"}
          />
        </div>
        {width > 768 && onHover && (
          <span style={{ flexGrow: 1, textAlign: "left" }}>Order</span>
        )}

        {width <= 768 && (
          <span style={{ flexGrow: 1, textAlign: "left" }}>Order</span>
        )}

        {width > 768 && onHover && (
          <Icon
            icon={expandOrder ? "arrow-up" : "arrow-down"}
            size={20}
            color={activeInfo[1] === "order" ? "#1571DE" : "#757575"}
          />
        )}

        {width <= 768 && (
          <Icon
            icon={expandOrder ? "arrow-up" : "arrow-down"}
            size={20}
            color={activeInfo[1] === "order" ? "#1571DE" : "#757575"}
          />
        )}
      </button>
      {width > 768 && expandOrder && onHover && (
        <div className={Styles.subMenuWrapper}>
          <NavLink
            to={`/order/manpower-outsource`}
            onClick={() => setOpen(false)}
            className={({ isActive, isPending }) =>
              isActive ? Styles.navActive : isPending ? "pending" : ""
            }
          >
            <div className={Styles.subNav}>
              <div className={Styles.mainBranch} />
              <div className={Styles.treeBranch} />
              <button className={`${Styles.navButton}`}>
                <span>Man Power Outsource</span>
              </button>
            </div>
          </NavLink>
        </div>
      )}

      {width <= 768 && expandOrder && (
        <div className={Styles.subMenuWrapper}>
          <NavLink
            to={`/order/manpower-outsource`}
            onClick={() => setOpen(false)}
            className={({ isActive, isPending }) =>
              isActive ? Styles.navActive : isPending ? "pending" : ""
            }
          >
            <div className={Styles.subNav}>
              <div className={Styles.mainBranch} />
              <div className={Styles.treeBranch} />
              <button className={`${Styles.navButton}`}>
                <span>Man Power Outsource</span>
              </button>
            </div>
          </NavLink>
        </div>
      )}
    </div>
  );
}
