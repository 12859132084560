import Icon from "@Atom/Icon";
import { NavLink } from "react-router-dom";
import Styles from "../navbar.module.scss";

export default function BMNavbar({
  active,
  activeInfo,
  setOpen,
  onHover,
  width,
}) {
  if (localStorage?.getItem("role") === "REGION BUSINESS MANAGER") {
    return (
      <div className={Styles.navWrapper}>
        <NavLink
          to={`/`}
          onClick={() => setOpen(false)}
          className={({ isActive, isPending }) =>
            isActive && active === "/"
              ? Styles.navActive
              : isPending
              ? "pending"
              : ""
          }
        >
          <button className={`${Styles.navButton}`}>
            <div className={Styles.iconDash}>
              <Icon
                icon="dashboard-filled"
                color={active !== "/" ? "#757575" : "#1571DE"}
                size={20}
              />
            </div>
            {width > 768 && onHover && <span>Dashboard</span>}
            {width <= 768 && <span>Dashboard</span>}
          </button>
        </NavLink>
        {/* <NavLink
          to={`/daftar-bm`}
          onClick={() => setOpen(false)}
          className={({ isActive, isPending }) =>
            isActive ? Styles.navActive : isPending ? "pending" : ""
          }
        >
          <button className={`${Styles.navButton}`}>
            <div className={Styles.iconDash}>
              <Icon
                icon={ activeInfo[1] === "daftar-bm" ? "people" : 'people-v2'}
                size={20}
                color={activeInfo[1] === "daftar-bm" ? "#1571DE" : "#757575"}
              />
            </div>

            {width > 768 && onHover && <span>Daftar BM</span>}
            {width <= 768 && <span>Daftar BM</span>}
          </button>
        </NavLink> */}
        <NavLink
          to={`/tambah-lokasi`}
          onClick={() => setOpen(false)}
          className={({ isActive, isPending }) =>
            isActive ? Styles.navActive : isPending ? "pending" : ""
          }
        >
          <button className={`${Styles.navButton}`}>
            <div className={Styles.iconDash}>
              <Icon
                icon="file"
                size={20}
                color={
                  activeInfo[1] === "tambah-lokasi" ? "#1571DE" : "#757575"
                }
              />
            </div>

            {width > 768 && onHover && <span>Tambah Lokasi</span>}
            {width <= 768 && <span>Tambah Lokasi</span>}
          </button>
        </NavLink>

        <NavLink
          to={`/recruitment`}
          onClick={() => setOpen(false)}
          className={({ isActive, isPending }) =>
            isActive ? Styles.navActive : isPending ? "pending" : ""
          }
        >
          <button className={`${Styles.navButton}`}>
            <div className={Styles.iconDash}>
              <Icon
                icon="recruitment-outline"
                size={20}
                color={activeInfo[1] === "recruitment" ? "#1571DE" : "#757575"}
              />
            </div>

            {width > 768 && onHover && <span>Recruitment</span>}
            {width <= 768 && <span>Recruitment</span>}
          </button>
        </NavLink>
      </div>
    );
  }

  return (
    <div className={Styles.navWrapper}>
      <NavLink
        to={`/`}
        onClick={() => setOpen(false)}
        className={({ isActive, isPending }) =>
          isActive && active === "/"
            ? Styles.navActive
            : isPending
            ? "pending"
            : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          <div className={Styles.iconDash}>
            <Icon
              icon="dashboard-filled"
              color={active !== "/" ? "#757575" : "#1571DE"}
              size={20}
            />
          </div>
          {width > 768 && onHover && <span>Dashboard</span>}
          {width <= 768 && <span>Dashboard</span>}
        </button>
      </NavLink>
      <NavLink
        to={`/clients`}
        onClick={() => setOpen(false)}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          <div className={Styles.iconDash}>
            <Icon
              icon="company"
              size={20}
              color={activeInfo[1] === "clients" ? "#1571DE" : "#757575"}
            />
          </div>

          {width > 768 && onHover && <span>Clients</span>}
          {width <= 768 && <span>Clients</span>}
        </button>
      </NavLink>
      <NavLink
        to={`/approval`}
        onClick={() => setOpen(false)}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          <div className={Styles.iconDash}>
            <Icon
              icon="recruitment-outline"
              size={20}
              color={activeInfo[1] === "approval" ? "#1571DE" : "#757575"}
            />
          </div>

          {width > 768 && onHover && <span>Client Approval</span>}
          {width <= 768 && <span>Client Approval</span>}
        </button>
      </NavLink>
      {/* <NavLink
        to={`/pipeline`}
        onClick={() => setOpen(false)}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          <Icon
            icon="pipeline"
            size={20}
            color={activeInfo[1] === "pipeline" ? "#1571DE" : "#757575"}
          />

          <span>Pipeline</span>
        </button>
      </NavLink> */}
    </div>
  );
}
