/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import { makeRandomString } from "@Helpers/makeRandomString";
import UseOutsideClick from "@Hooks/useOutsideClick";
import DoneModal from "@Molecule/_modal/DoneModal";
import InterviewInvitationModal from "@Molecule/_modal/InterviewInvitationModal";
import MoveCandidateModal from "@Molecule/_modal/MoveCandidateModal";
import CandidateDetailModal from "@Molecule/_recruitment/CandidateDetailModal";
import CandidateInformationModal from "@Molecule/_recruitment/CandidateInformationModal";
import ClientInterviewTable from "@Molecule/_recruitment/ClientInterviewTable";
import { resendInvitation, updateTag } from "@Services/officer/recruitment";
import { InputBase, styled } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import CandidatePreview from "../First/ListMode/Accepted/CandidatesPreview";
import SendModal from "../First/ListMode/Accepted/SendModal";
import Button from "./button";
import Styles from "./style.module.scss";
import { RefreshButton } from "@Atom/Button";

const CustomInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    width: "100%",
    border: "none",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#0A0A0A",

    "&:focus": {
      border: "none",
      // color: '#1571DE',
      boxShadow: "none",
    },
  },
});

const controlOptions = [
  {
    id: 1,
    title: "Undang",
  },
  {
    id: 2,
    title: "Tidak Lolos",
  },
];

const markOptions1 = ["Tidak Lolos", "Lolos"];

const invitationStatuses = ["Sudah Diundang", "Belum Diundang"];

export default function SecondStage({
  source,
  locationFilter,
  positionFilter,
  setCurrentStep,
  trigger,
  setTrigger,
  clientContacts = [],
  // requestDetails,
  invitationSyncData,
  syncData,
  refetchSync,
  positionIDFilter,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentController, setCurrentController] = useState(1);
  const [expand, setExpand] = useState(false);
  const [expandTag, setExpandTag] = useState(false);
  const [checkedInvitationArr, setCheckedInvitationArr] = useState([]);
  const [checkedRejectedArr, setCheckedRejectedArr] = useState([]);
  const [checkedAcceptedArr, setCheckedAcceptedArr] = useState([]);

  const [contacts, setContacts] = useState(clientContacts || []);

  useEffect(() => {
    console.log("contacts change", contacts);
  }, [contacts]);

  const [isSuccess, setIsSuccess] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  const [selectedContact, setSelectedContact] = useState(null);

  const [candidateToInvite, setCandidateToInvite] = useState(null);

  const [dataToMove, setDataToMove] = useState(null);

  const selectedTag = useMemo(() => {
    return controlOptions[currentController - 1];
  }, [currentController]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const viewType = useMemo(() => {
    if (windowSize[0] > 768) {
      return "desktop";
    } else if (windowSize[0] <= 768) {
      return "mobile";
    }
  }, [windowSize]);

  const [selectedCandidateDetail, setSelectedCandidateDetail] = useState(null);

  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [showCandidateInfo, setShowCandidateInfo] = useState(false);
  const [showCandidateInfoMobile, setShowCandidateInfoMobile] = useState(false);

  const [defaultExpandId, setDefaultExpandId] = useState(1);

  const handleViewInfo = (data) => {
    setDefaultExpandId(2);

    if (viewType === "desktop") {
      setSelectedCandidate(data);

      setShowCandidateInfo(true);
    } else {
      setSelectedCandidate(data);

      setShowCandidateInfoMobile(true);
    }
  };

  const handleCloseInfo = () => {
    setDefaultExpandId(1);
    if (showCandidateInfo) {
      setShowCandidateInfo(false);
    } else {
      setShowCandidateInfoMobile(false);
    }
    setSelectedCandidate(null);
  };

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (selectedCandidateDetail !== null) {
      if (boxOutsideClick === false) {
        setSelectedCandidateDetail(null);
      }
    }
    if (showCandidateInfoMobile) {
      if (boxOutsideClick === false) {
        setShowCandidateInfoMobile(false);
      }
    }
  }, [boxOutsideClick, selectedCandidateDetail, showCandidateInfoMobile]);

  const handleMark = async (newTag, arr) => {
    const payload = {
      status: newTag,
      candidateIDs: arr,
    };

    try {
      await updateTag(payload);
      setTrigger(Math.random());
    } catch (err) {
      console.log(err);
    }
  };

  // const handleMoveToOffering = () => {
  //   handleMark("OFFER-INVITE", checkedAcceptedArr).then(() =>
  //     setShowDone(true)
  //   );
  // };

  const [showSend, setShowSend] = useState(false);

  const disableToSend = useMemo(() => {
    if (!checkedInvitationArr?.length) {
      return true;
    }
    return false;
  }, [checkedInvitationArr?.length]);

  const handleSendToClient = () => {
    if (!disableToSend) {
      setShowSend(true);
    }
  };

  const [data, setData] = useState([]);

  const selectedCandidates = useMemo(() => {
    const arr = [];
    for (let i = 0; i < checkedInvitationArr?.length; i++) {
      const id = checkedInvitationArr[i];
      const found = data?.find((el) => el?.id === id);
      if (found) {
        arr?.push(found);
      }
    }
    return arr;
  }, [checkedInvitationArr, data]);

  const requestDetails = useMemo(() => {
    let amount = 0;
    let positionsArray = [];
    let locationsArray = [];

    for (let i = 0; i < checkedInvitationArr?.length; i++) {
      const id = checkedInvitationArr[i];
      const found = data?.find((el) => el?.id === id);
      if (found) {
        amount++;

        const positionName = found.ExpectedPosition?.name;
        if (!positionsArray.includes(positionName)) {
          positionsArray.push(positionName);
        }

        const locationName = found.ExpectedPosition?.City?.name;
        if (!locationsArray.includes(locationName)) {
          locationsArray.push(locationName);
        }
      }
    }

    return {
      amount: amount,
      positions: positionsArray,
      locations: locationsArray,
    };
  }, [checkedInvitationArr, data]);

  const [invitationStatus, setInvitationStatus] = useState("");

  const handleResend = async (data) => {
    try {
      await resendInvitation(data?.CandidateInvitations?.id);
      setTrigger(makeRandomString(5));
      setIsSuccess(true);
      data.updatedAt = new Date();
    } catch (err) {
      console.log(err?.response?.data?.error, "error on resendInvitation");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    }
  }, [isSuccess]);

  return (
    <div className={Styles.container}>
      <div className={Styles.topWrapper}>
        <div className={Styles.header}>
          <div className={Styles.titleWrapper}>
            <h4>Interview Klien</h4>
            <RefreshButton onClick={() => setTrigger(makeRandomString(5))} />
          </div>
          <div className={Styles.searchBarWrapper}>
            <OutlinedSearchBar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder={"Search Candidate"}
            />
          </div>
        </div>
        <div className={Styles.controller}>
          <div className={Styles.left}>
            {controlOptions?.map((each, i) => {
              return (
                <button
                  key={i}
                  className={`${
                    each?.id === currentController ? Styles.active : ""
                  } ${Styles.hideOnMobile}`}
                  onClick={() => {
                    setCurrentController(each?.id);
                    setExpand(false);
                  }}
                >
                  {each?.title}
                </button>
              );
            })}
            <button
              className={`${Styles.active} ${Styles.showOnMobile}`}
              onClick={() => setExpandTag(!expandTag)}
            >
              <span>{selectedTag?.title}</span>
              <Icon icon={expandTag ? "arrow-up" : "arrow-down"} size={18} />
              {expandTag && (
                <div className={Styles.options}>
                  {controlOptions?.map((each, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => setCurrentController(each?.id)}
                      >
                        {each?.title}
                        <div className={Styles.separator} />
                      </div>
                    );
                  })}
                </div>
              )}
            </button>
          </div>
          <div className={Styles.right}>
            {currentController === 1 && (
              <>
                <button
                  onClick={() => handleSendToClient()}
                  className={Styles.primaryButton}
                  disabled={!checkedInvitationArr?.length}
                >
                  <span>Kirim ke klien</span>
                  <Icon icon={"move-folder"} size={18} />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={Styles.main}>
        {currentController === 1 && (
          <ClientInterviewTable
            data={data}
            setData={setData}
            checkedArr={checkedInvitationArr}
            setCheckedArr={setCheckedInvitationArr}
            searchTerm={searchTerm}
            locationFilter={locationFilter}
            positionFilter={positionFilter}
            totalPage={1}
            tag={"CLIENT-INVITE"}
            type={"client-invite"}
            setSelectedCandidateDetail={setSelectedCandidateDetail}
            statusQuery={
              !invitationStatus
                ? ""
                : invitationStatus?.toLowerCase() === "undang"
                ? "&tag=null"
                : "&tag=invited&tag=accepted"
            }
            // statusQuery={''}
            trigger={trigger}
            handleViewInfo={handleViewInfo}
            withCheckbox
            withActionColumn
            actionColumnWidth="100px"
            actionColumnHead={() => (
              // <Select
              //   style={{ width: "120px" }}
              //   input={<CustomInput />}
              //   displayEmpty={true}
              //   renderValue={(value) =>
              //     value?.length
              //       ? Array.isArray(value)
              //         ? value.join(", ")
              //         : value
              //       : "Status"
              //   }
              //   IconComponent={(props) => (
              //     <Icon {...props} icon={"arrow-down"} size={18} />
              //   )}
              //   MenuProps={{
              //     PaperProps: {
              //       sx: {
              //         borderRadius: "8px",
              //         "& .MuiMenuItem-root": {
              //           fontFamily: "Poppins",
              //           fontSize: "14px",
              //           fontWeight: 400,
              //           color: "#404040",
              //         },
              //       },
              //     },
              //   }}
              //   value={invitationStatus}
              //   onChange={(e) => setInvitationStatus(e?.target?.value)}
              // >
              //   <MenuItem value={""}>
              //     <em>Semua</em>
              //   </MenuItem>
              //   <MenuItem value={"Undang"}>Undang</MenuItem>
              //   <MenuItem value={"Terundang"}>Terundang</MenuItem>\{" "}
              // </Select>
              <span>Status</span>
            )}
            actionColumnRow={(row) =>
              `${row?.CandidateInvitations?.ordinal}` === "2" ? (
                row?.status?.toLowerCase().includes("hold") ? (
                  <div className={Styles.hold}>Hold</div>
                ) : row?.CandidateInvitations?.status?.toLowerCase() ===
                  "accepted" ? (
                  <div
                    className={Styles.accepted}
                    // onClick={() => setCandidateToInvite(row)}
                  >
                    <span>Hadir</span>
                    {/* <Icon icon={'check-circle'} size={20} /> */}
                  </div>
                ) : !row?.CandidateInvitations?.status ||
                  row?.CandidateInvitations?.status?.toLowerCase() ===
                    "invited" ||
                  row?.CandidateInvitations?.status?.toLowerCase() ===
                    "resend" ? (
                  <Button
                    recruiterId={row?.recruiterID}
                    tag={row?.tag}
                    onClick={(e) =>
                      row?.tag && row?.recruiterID
                        ? handleResend(row)
                        : // resendInvitation(e, row?.id)
                          setCandidateToInvite(row)
                    }
                    updatedAt={row?.updatedAt}
                    isROInterview
                  />
                ) : (
                  <button className={Styles.rejected}>Tidak Hadir</button>
                )
              ) : (
                <div className={Styles.buttonOrdinal1}>
                  {row?.status?.toLowerCase().includes("hold") ? (
                    <div className={Styles.hold}>Hold</div>
                  ) : (
                    <button
                      onClick={() => setCandidateToInvite(row)}
                      className={Styles.invite}
                    >
                      Undang
                    </button>
                  )}
                </div>
              )
            }
            disableRowChekbox={(row) => {
              if (
                `${row?.CandidateInvitations?.ordinal}` === "2" &&
                row?.CandidateInvitations?.status?.toLowerCase() === "accepted"
              ) {
                return false;
              }
              return true;
            }}
            positionIDFilter={positionIDFilter}
          />
        )}
        {currentController === 2 && (
          <ClientInterviewTable
            data={data}
            setData={setData}
            checkedArr={checkedRejectedArr}
            setCheckedArr={setCheckedRejectedArr}
            searchTerm={searchTerm}
            locationFilter={locationFilter}
            positionFilter={positionFilter}
            totalPage={1}
            tag={"CLIENT-DISQUALIFIED"}
            type={"client-reject"}
            statusQuery={""}
            setSelectedCandidateDetail={setSelectedCandidateDetail}
            trigger={trigger}
            handleViewInfo={handleViewInfo}
            withActionColumn
            actionColumnWidth="100px"
            actionColumnHead={() => <span>Actions</span>}
            actionColumnRow={(row) => (
              <button
                onClick={() => setDataToMove(row)}
                className={Styles.transfer}
              >
                Alihkan
              </button>
            )}
            positionIDFilter={positionIDFilter}
          />
        )}
        {currentController === 3 && (
          <ClientInterviewTable
            data={data}
            setData={setData}
            checkedArr={checkedAcceptedArr}
            setCheckedArr={setCheckedAcceptedArr}
            searchTerm={searchTerm}
            locationFilter={locationFilter}
            positionFilter={positionFilter}
            totalPage={1}
            tag={"CLIENT-QUALIFIED"}
            setSelectedCandidateDetail={setSelectedCandidateDetail}
            statusQuery={""}
            trigger={trigger}
            handleViewInfo={handleViewInfo}
            positionIDFilter={positionIDFilter}
          />
        )}
      </div>
      {/* {showDone && (
        <DoneModal
          handleDone={handleDone}
          title="Done!"
          description="Candidates have been moved to the next stage"
        />
      )} */}

      <ModalWrapper show={dataToMove} handleClose={() => setDataToMove(null)}>
        {dataToMove && (
          <MoveCandidateModal
            candidate={dataToMove}
            onClose={() => setDataToMove(null)}
          />
        )}
      </ModalWrapper>

      <div
        className={`${Styles.modalWrapper} ${
          selectedCandidateDetail ? Styles.showModal : Styles.hideModal
        }`}
      >
        <CandidateDetailModal
          data={selectedCandidateDetail}
          setData={setSelectedCandidateDetail}
          // inviteOption={(selectedCandidate?.status === 'CLIENT-INVITE') ? true : false}
          inviteOption={false}
          additionalButtonComponent={
            `${selectedCandidateDetail?.CandidateInvitations?.ordinal}` ===
            "2" ? (
              selectedCandidateDetail?.status
                ?.toLowerCase()
                .includes("hold") ? (
                <div className={Styles.hold}>Hold</div>
              ) : selectedCandidateDetail?.CandidateInvitations?.status?.toLowerCase() ===
                "accepted" ? (
                <div
                  className={Styles.accepted}
                  // onClick={() => setCandidateToInvite(selectedCandidateDetail)}
                >
                  <span>Hadir</span>
                  {/* <Icon icon={'check-circle'} size={20} /> */}
                </div>
              ) : (
                (!selectedCandidateDetail?.CandidateInvitations?.status ||
                  selectedCandidateDetail?.CandidateInvitations?.status?.toLowerCase() ===
                    "invited" ||
                  selectedCandidateDetail?.CandidateInvitations?.status?.toLowerCase() ===
                    "resend") && (
                  <Button
                    recruiterId={selectedCandidateDetail?.recruiterID}
                    tag={selectedCandidateDetail?.tag}
                    onClick={(e) =>
                      selectedCandidateDetail?.tag &&
                      selectedCandidateDetail?.recruiterID
                        ? handleResend(selectedCandidateDetail)
                        : // resendInvitation(e, selectedCandidateDetail?.id)
                          setCandidateToInvite(selectedCandidateDetail)
                    }
                    updatedAt={selectedCandidateDetail?.updatedAt}
                    isROInterview
                  />
                )
                // :
                // <button className={Styles.rejected}>
                //   Tidak Hadir
                // </button>
              )
            ) : (
              <>
                {selectedCandidateDetail?.status
                  ?.toLowerCase()
                  .includes("hold") ? (
                  <div className={Styles.hold}>Hold</div>
                ) : (
                  <button
                    onClick={() =>
                      setCandidateToInvite(selectedCandidateDetail)
                    }
                    className={Styles.invite}
                  >
                    Undang
                  </button>
                )}
              </>
            )
          }
          type="client"
          handleResend={handleResend}
          setCandidateToInvite={setCandidateToInvite}
        />
      </div>
      {(showCandidateInfo || showCandidateInfoMobile) && selectedCandidate && (
        <CandidateInformationModal
          defaultExpandId={defaultExpandId || 2}
          data={selectedCandidate}
          handleClose={handleCloseInfo}
          // inviteOption={(selectedCandidate?.status === 'CLIENT-INVITE') ? true : false}
          type={"desktop"}
        />
      )}
      <div
        className={`${Styles.modalWrapper} ${
          showCandidateInfoMobile ? Styles.showModal : Styles.hideModal
        }`}
      >
        {/* {showCandidateInfo && selectedCandidate && (
          <CandidateInformationModal
            defaultExpandId={defaultExpandId}
            data={selectedCandidate}
            handleClose={handleCloseInfo}
            inviteOption={false}
            type={"mobile"}
          />
        )} */}
      </div>
      {showSend &&
        (viewType === "desktop" ? (
          <SendModal
            setShowPreview={setShowPreview}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            setShow={setShowSend}
            contacts={contacts}
            setContacts={setContacts}
            type={viewType}
            setTrigger={setTrigger}
            refetchSync={refetchSync}
          />
        ) : (
          <div
            className={`${Styles.modalWrapper} ${
              showSend ? Styles.showModal : Styles.hideModal
            }`}
          >
            <SendModal
              setShowPreview={setShowPreview}
              selectedContact={selectedContact}
              setSelectedContact={setSelectedContact}
              setShow={setShowSend}
              contacts={contacts}
              setContacts={setContacts}
              type={viewType}
              setTrigger={setTrigger}
              refetchSync={refetchSync}
            />
          </div>
        ))}
      <ModalWrapper type="fullscreen" show={showPreview} zIndex={10}>
        <CandidatePreview
          data={selectedCandidates}
          setShow={setShowPreview}
          setShowSend={setShowSend}
          selectedContact={selectedContact}
          requestDetails={requestDetails}
          setTrigger={setTrigger}
        />
      </ModalWrapper>
      <ModalWrapper
        show={candidateToInvite ? true : false}
        handleClose={() => setCandidateToInvite(null)}
      >
        {candidateToInvite && (
          <InterviewInvitationModal
            data={candidateToInvite}
            setTrigger={setTrigger}
            handleClose={() => setCandidateToInvite(null)}
            interviewOptions={[]}
            locationOptions={[]}
            interviewerList={[]}
            invitationSyncData={invitationSyncData}
            contacts={contacts}
            setContacts={setContacts}
          />
        )}
      </ModalWrapper>
      <div
        ref={boxRef}
        className={`${Styles.overlay} ${
          selectedCandidateDetail ||
          showCandidateInfoMobile ||
          (showSend && viewType !== "desktop")
            ? Styles.visible
            : ""
        }`}
      />
      {isSuccess && (
        <DoneModal
          handleDone={() => setIsSuccess(false)}
          title={"Sent!"}
          description={"Invitation has been sent"}
        />
      )}
    </div>
  );
}
