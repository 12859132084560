import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import CustomBreadCrumb from "@Molecule/CustomBreadCrumb";
import { useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";

export default function ClientOrderDetailsNavigator() {
  const navigate = useNavigate();

  const query = useQuery();
  const code = query.get("c");

  const handleClickBack = () => {
    navigate(`/dashboard/orders?&c=${code}`);
  };

  return (
    <div className={Styles.container}>
      <button onClick={handleClickBack}>
        <Icon icon="arrow-left-rounded-primary" color="#1571DE" size={20} />
      </button>
      <CustomBreadCrumb>
        <button onClick={handleClickBack} className={Styles.inactive}>
          Job Orders
        </button>
        <button onClick={handleClickBack} className={Styles.inactive}>
          All List
        </button>
        <button disabled className={Styles.active}>
          Detail
        </button>
      </CustomBreadCrumb>
    </div>
  );
}
