import { API } from "@Config/api";

export function getJobPostingList(
  limit,
  page,
  sort,
  startDate,
  endDate,
  status,
  search
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/job-post?limit=${limit}&page=${page}&sort=${sort}&startDate=${startDate}&endDate=${endDate}&status=${status}&search=${search}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getJobPostingSync(
  // limit,
  // page,
  search
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        // `/job-post/sync?limit=${limit}&page=${page}&search=${search}`,
        `/job-post/sync?search=${search}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getJobPostPerID(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        // `/job-post/sync?limit=${limit}&page=${page}&search=${search}`,
        `/job-post/${id}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateJobPost(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.put(`/job-post/${id}`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// /job-post/666aa9e5f1d228bafed1ca55

export function getClientPerCode(code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/job-post/client-sync/${code}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendJobPosting(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/job-post`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "multipart/form-data",
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendLinkJobPost(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/job-post/link`, payload, {});

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// /job-post/link

export function updateStatus(id, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/job-post/status/${id}`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getPopularCategories() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/job-categories/popular`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// /job-post/status/650e6a957ea4095a4beb1523
