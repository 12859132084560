import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Styles from "./style.module.scss";
import { visuallyHidden } from "@mui/utils";

const TABLE_HEAD = [
  { id: "name", label: "Name", align: "left" },
  { id: "contact", label: "Contact", align: "left" },
  { id: "contractDate", label: "Contract Date", align: "left" },
  { id: "rating", label: "Rating", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "action", label: "Action", align: "left" },
];

export default function TableHeader({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className={Styles.tableHead}>
      <TableRow className={Styles.tableHeadRow}>
        {TABLE_HEAD?.map((each, i) => (
          <TableCell
            key={"headCell" + i}
            className={`${Styles.tableHeadCell} ${
              i !== 0 ? Styles.HideOnMobile : ""
            }`}
            padding="none"
          >
            <TableSortLabel
              active={orderBy === each.id}
              direction={orderBy === each.id ? order : "asc"}
              onClick={createSortHandler(each.id)}
            >
              <span>{each?.label}</span>
              {orderBy === each.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          align="center"
          className={`${Styles.tableHeadCell} ${Styles.showOnMobile}`}
          padding="none"
        >
          <span>Action</span>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
