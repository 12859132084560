import { useState } from "react";
import Styles from "./textarea.module.scss";
import Icon from "@Atom/Icon";
import ErrorBubble from "@Atom/ErrorBubble";

const Textarea = ({
  icon,
  hideIcon,
  id,
  value,
  onChange,
  placeholder,
  onDelete,
  disabled,
  deleteOption,
  isError = false,
  isErrorV2 = false,
  errorText = "",
  isRemark,
  readOnly = false,
  ...props
}) => {
  const [onFocus, setOnFocus] = useState(false)

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <textarea
          className={`${Styles.textArea} ${isError && !onFocus ? Styles.error : ""}`}
          type="text"
          id={id || "fname"}
          name="fname"
          value={value || ""}
          onChange={onChange}
          onFocus={() => setOnFocus(true)}
          onBlur={() => setOnFocus(false)}
          placeholder={placeholder}
          disabled={disabled}
        />
        {icon && !hideIcon && (
          <div className={Styles.addressIconWrapper}>
            <Icon icon={icon} size="24px" />
          </div>
        )}

        {isError && !disabled && !readOnly && !onFocus && (
          !isErrorV2
            ?
            <span className={Styles.errorText}>{errorText}</span>
            :
            <div>
              <ErrorBubble
                errorText={errorText}
              />
            </div>
        )}
      </div>
      {deleteOption && (
        <button onClick={onDelete}>
          <Icon icon="trash" size={20} />
        </button>
      )}
    </div>
  );
};

export default Textarea;
