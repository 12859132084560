import Styles from "./decorativeInputField.module.scss";
import DecorativeInput from "@Atom/DecorativeInput";

const DecorativeInputField = ({
  title,
  value,
  setValue,
  type,
  adornment,
  required,
  ...props
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.titleWrapper}>{title}{required && <span className={Styles.asterisk}>&#x2a;</span>}</div>
      <DecorativeInput
        value={value}
        setValue={setValue}
        adornment={adornment}
        type={type}
      />
    </div>
  );
};

export default DecorativeInputField;
