import ModalWrapper from '@Atom/ModalWrapper'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'

export default function RequestSuccessModal({
  title = '',
  description = '',
  closeButtonText = '',
  show,
  onClose = () => { }
}) {

  return (
    <ModalWrapper show={show} handleClose={onClose} forceCenter={true}>
      <div className={Styles.container}>
        <span>{title}</span>
        <Icon icon={'check-circle'} size={189} color={'#1571DE'} />
        <p>{description}</p>
        {
          !!closeButtonText &&
          <button onClick={onClose}>{closeButtonText}</button>
        }
      </div>
    </ModalWrapper>
  )
}