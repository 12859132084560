/* eslint-disable no-unused-vars */
import ModalWrapper from "@Atom/ModalWrapper";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import Icon from "@Atom/Icon";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { getClientSummary, getLogsSummary } from "@Services/client/dashboard";
import LoadingAnimation from "@Atom/LoadingAnimation";
import { EmptyPlaceholder } from "@Organism/Additional/RenewData/Third";
import { getPositionLogRBM } from "@Services/manager/bm";
import { Tooltip } from "@mui/material";

export default function ClientDashboardLogModal({
  show = false,
  onClose = () => { },
  dataPositions = [],
  isFulfillment = false,
}) {
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [isLoadingData, setIsLoadingData] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoadingData(true);
      const { response } = isFulfillment
        ? await getPositionLogRBM(
          show?.client?.code || "",
          page,
          10,
          selectedPosition?.positionName || ""
        )
        // : await getLogsSummary(page, 10, selectedPosition?.positionName || "");
        : await getClientSummary(localStorage?.getItem('clientCode'), 10, page);
      setData(response?.data);
      setTotalPage(response?.totalPage);
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log(err, "error on fetching data");
    }
  }, [page, selectedPosition?.positionName, isFulfillment, show]);

  const fetchMore = useCallback(async () => {
    try {
      setIsLoadingData(true);
      const { response } = isFulfillment
        ? await getPositionLogRBM(
          show?.client?.code || "",
          page,
          10,
          selectedPosition?.positionName || ""
        )
        : await getLogsSummary(page, 10, selectedPosition?.positionName || "");
      setData([...data, response?.data]);
      setPage(page + 1);
      setTotalPage(response?.totalPage);
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log(err, "error on fetching data");
    }
  }, [page, selectedPosition?.positionName, isFulfillment, show, data]);

  useEffect(() => {
    if (show) {
      fetchData();
    }
  }, [fetchData, show]);

  const mappedData = useMemo(() => {
    return data?.map((obj) => {
      return {
        id: obj?.id,
        label: obj?.FulfillmentSummary ? obj.FulfillmentSummary?.position : "-",
        title: obj?.remark,
        description: `${obj?.field}${obj?.oldValue ? " " + obj?.oldValue : ""
          } ${obj?.oldValue ? " →" : ":"} ${obj?.newValue}`,
        timestamp: new Date(obj?.updatedAt),
      };
    });
  }, [data]);

  return (
    <ModalWrapper show={show} handleClose={onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.top}>
            <img src={Images.LOG_ICON} alt="" />
            <h3>Riwayat Perubahan</h3>
            <button onClick={onClose}>
              <Icon icon={"cross"} size={24} />
            </button>
          </div>
          <div className={Styles.bottom}>
            <div
              className={!selectedPosition ? Styles.active : ""}
              onClick={() => setSelectedPosition(null)}
            >
              Semua
            </div>
            {dataPositions?.map((each, i) => (
              <Tooltip title={<span>{each?.positionName}</span>} key={i}>
                <div
                  className={
                    selectedPosition?.positionName === each?.positionName
                      ? Styles.active
                      : ""
                  }
                  onClick={() => setSelectedPosition(each)}
                >
                  {each?.positionName}
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
        {isLoadingData ? (
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        ) : mappedData?.length > 0 ? (
          <div className={`${Styles.content}`}>
            {mappedData?.map((each, i) => (
              <div key={i} className={Styles.each}>
                {i !== mappedData?.length - 1 && (
                  <div className={Styles.border} />
                )}
                <div className={Styles.dot} />
                <div className={Styles.eContent}>
                  <div className={Styles.eTop}>
                    <h4>{each?.label}</h4>
                    <span>
                      {moment(each?.timestamp)
                        ?.format("LLL")
                        ?.replace("pukul", ", ")
                        ?.replace(".", ":")}
                    </span>
                  </div>
                  <span>{each?.title}</span>
                  <div className={Styles.desc}>{each?.description}</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={Styles.notFoundBox}>
            <EmptyPlaceholder title={"No Data"} desc="No Data Yet" />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
}
