import UseOutsideClick from '@Hooks/useOutsideClick'
import Pagination from '@Molecule/Pagination'
import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import ViewDetailModal from './ViewDetailModal'
import Styles from './style.module.scss'


// eslint-disable-next-line
const TABLE_HEAD = [
  { id: "name", label: "Nama Kandidat", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "phoneNumber", label: "Nomor Handphone", align: "left" },
  { id: "domicile", label: "Domisili", align: "left" },
  { id: "address", label: "Alamat", align: "left" },
  { id: "lastEducation", label: "Pendidikan", align: "left" },
  { id: "lastPosition", label: "Pengalaman Terakhir", align: "left" },
]

export default function DataTable({
  handleRenew,
  candidates,
  defaultTotalPage

}) {
  const [value, setValue] = useState(10)

  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  // eslint-disable-next-line
  const [totalPage, setTotalPage] = useState(defaultTotalPage)

  const [selectedCandidate, setSelectedCandidate] = useState(null)
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (selectedCandidate !== null) {
      if (boxOutsideClick === false) {
        setSelectedCandidate(null);
      }
    }
  }, [boxOutsideClick, selectedCandidate]);

  useEffect(() => {
    handleRenew(value, selectedPage)
  }, [handleRenew, value, selectedPage])

  return (
    <div className={Styles.tableWrapper}>
      <TableContainer className={Styles.tableContainer}>
        <Table className={Styles.table}>
          <TableHead className={Styles.tableHead}>
            <TableRow className={Styles.tableHeadRow}>
              {TABLE_HEAD?.map((eachH, iH) => (
                <TableCell
                  key={'headCell' + iH}
                  align={eachH?.align}
                  padding='none'
                  className={`${Styles.tableHeadCell} ${iH !== 0 ? Styles.hideOnMobile : ''}`}
                >
                  <div>
                    <span>
                      {eachH?.label}
                    </span>
                  </div>
                </TableCell>
              ))}
              <TableCell className={`${Styles.tableHeadCell} ${Styles.showOnMobile}`}>
                <div>
                  <span>
                    Action
                  </span>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={Styles.tableBody}>
            {candidates?.map((eachD, iD) => {
              return (
                <TableRow key={`tableRow` + iD} className={Styles.tableRow}>
                  <TableCell className={`${Styles.stickyLeft} ${Styles.tableCell}`}>

                    <button className={Styles.stackedProfile}>
                      {eachD?.profilePicture
                        ? <Avatar
                          alt={eachD?.name}
                          src={eachD?.profilePicture}
                          sx={{ width: 36, height: 36 }}
                        />
                        : <Avatar
                          sx={{ width: 36, height: 36 }}
                        >{eachD?.name[0]}</Avatar>}
                      <span>
                        {eachD?.name}
                      </span>
                    </button>
                  </TableCell>
                  <TableCell className={`${Styles.tableCell} ${Styles.hideOnMobile}`}>
                    <span>{eachD?.email ? eachD?.email : '-'}</span>
                  </TableCell>
                  <TableCell className={`${Styles.tableCell} ${Styles.hideOnMobile}`}>
                    <span>{eachD?.phoneNumber ? eachD?.phoneNumber : '-'}</span>
                  </TableCell>
                  <TableCell className={`${Styles.tableCell} ${Styles.hideOnMobile}`}>
                    <span>{eachD?.domicile ? eachD?.domicile : '-'}</span>
                  </TableCell>
                  <TableCell className={`${Styles.tableCell} ${Styles.hideOnMobile}`}>
                    <span className={Styles.address}>{eachD?.address ? eachD?.address : '-'}</span>
                  </TableCell>
                  <TableCell className={`${Styles.tableCell} ${Styles.hideOnMobile}`}>
                    <span>{eachD?.lastEducation ? eachD?.lastEducation : '-'}</span>
                  </TableCell>
                  <TableCell className={`${Styles.tableCell} ${Styles.hideOnMobile}`}>
                    <span>{eachD?.lastPosition ? eachD?.lastPosition : '-'}</span>
                  </TableCell>
                  <TableCell className={`${Styles.tableCell} ${Styles.showOnMobile}`}>
                    <button onClick={() => setSelectedCandidate(eachD)}>See More</button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={Styles.PaginationContainer}>
        <Pagination
          total={candidates?.length}
          totalPage={totalPage}
          value={value}
          setValue={setValue}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>

      <div
        className={`${Styles.modalWrapper} ${selectedCandidate ? Styles.showModal : Styles.hideModal
          }`}
      >
        <ViewDetailModal data={selectedCandidate} setData={setSelectedCandidate} />
      </div>
      <div
        ref={boxRef}
        className={`${Styles.overlay} ${selectedCandidate ? Styles.visible : ""}`}
      />
    </div>
  )
}
