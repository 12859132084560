import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/api";
import Images from "@Theme/Images";
import Styles from "./style.module.scss";

const Profile = ({ setEditProfile, data, ...props }) => {
  // const [isVisible, setIsVisible] = useState(false);
  const clientName = localStorage.getItem("clientName") || "-";
  const isClient = localStorage.getItem("isClient") === "true" ? true : false;

  // const passwordGenerator = (password, isVisible) => {
  //   if (isVisible) {
  //     return password;
  //   }
  //   return password.replace(/./g, "*");
  // };

  return (
    <div className={Styles.container}>
      <div className={Styles.basicProfileSection}>
        <div className={Styles.basicProfileLeft}>
          <div className={Styles.profilePicture}>
            <img
              src={
                data?.profilePicture && data?.profilePicture !== "null"
                  ? data?.profilePicture?.includes("myworkspace.id")
                    ? data?.profilePicture
                    : fileBaseUrl + data?.profilePicture
                  : Images.AVA_DEFAULT
              }
              height={75}
              width={75}
              alt="profilePict"
              style={{ borderRadius: 37.5, objectFit: "cover" }}
            />
          </div>
          <div className={Styles.basicProfileInformation}>
            <div className={Styles.clientName}>{data?.name || "-"}</div>
            <div className={Styles.clientPosition}>
              {isClient ? clientName : data?.jobTitle || "-"}
            </div>
          </div>
        </div>
        <div
          className={Styles.editProfileButton}
          onClick={() => {
            setEditProfile(true);
          }}
        >
          <Icon
            className={Styles.editProfileButtonIcon}
            icon="edit"
            size={20}
          />
          <div className={Styles.editProfileButtonTitle}>Edit</div>
        </div>
      </div>
      <div className={Styles.personalInformationSection}>
        <div className={Styles.personalInformationText}>
          Personal Information
        </div>
        <div className={Styles.personalInformationWrapper}>
          {/* <div className={Styles.personalInformation}>
            <div className={Styles.informationTitle}>Posisi</div>
            <div className={Styles.informationValue}>{data?.jobTitle || "-"}</div>
          </div> */}
          <div className={Styles.personalInformation}>
            <div className={Styles.informationTitle}>Email</div>
            <div className={Styles.informationValue}>{data?.email || "-"}</div>
          </div>
          <div className={Styles.personalInformation}>
            <div className={Styles.informationTitle}>Nomor HP</div>
            <div className={Styles.informationValue}>
              <span className={Styles.greySpan}>+62 </span>
              {data?.phoneNumber?.split("62")[1] || "-"}
            </div>
          </div>
          <div className={Styles.personalInformation}>
            <div className={Styles.informationTitle}>Unit Kerja</div>
            <div className={Styles.informationValue}>
              {data?.workUnit || "-"}
            </div>
          </div>
        </div>
      </div>
      {/* <div className={Styles.personalInformationSection}>
        <div className={Styles.personalInformationText}>Access</div>
        <div className={Styles.personalInformationWrapper}>
          <div className={Styles.personalInformation}>
            <div className={Styles.informationTitle}>Password</div>
            <div className={Styles.informationValue}>
              {passwordGenerator("ilovebandung", isVisible)}
            </div>
            <Icon
              icon={isVisible ? "eye-visible" : "eye-invisible"}
              className={Styles.icon}
              size={24}
              onClick={() => setIsVisible(!isVisible)}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Profile;
