import { API } from "@Config/api";

export function getClientCandidates(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate?${query}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

}
export function getClientCandidateDetails(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate/by-id/${id}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

