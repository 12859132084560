import {API} from "../config/api";

export const getClientProfile = (token, id) => {
  return API({
    url: `/client-contact/client/${id}`,
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
}

export const patchClientProfile = (token, payload, id) => {
  return API({
    url: `/client-contact/client/${id}`,
    method: 'PATCH',
    headers: {
      Authorization: token,
    },
    data: payload,
  })
};
