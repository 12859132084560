import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/api";
import convertHtmlToPdf from "@Helpers/convertHtmlToPdf";
import Images from "@Theme/Images";
import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import { openInNewTab } from "@Helpers/openInNewTab";

export default function ApprovedContract({ data, pdf }) {
  const [pdfUrl, setPdfUrl] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPdfUrl = async () => {
      setLoading(true);
      const convertedHtml = await convertHtmlToPdf(
        data.candidateContract?.template,
        "contract",
        {
          ...data.candidate,
          ...data.candidateContract,
          image: data.candidateContract.image
            ? fileBaseUrl + data.candidateContract.image
            : "",
          initials: data.candidateContract.initials
            ? fileBaseUrl + data.candidateContract.initials
            : "",
          signature: data.candidateContract.signature
            ? fileBaseUrl + data.candidateContract.signature
            : "",
          identityCardImage: data?.candidateContract?.identityCardImage
            ? fileBaseUrl + data?.candidateContract?.identityCardImage
            : "",
          // latitude: data.candidateContract.latitude,
          // longitude: data.candidateContract.longitude,
          // businessManagerID: data.candidateContract.businessManagerID,
          contractType: data?.expectedPosition?.contractType,
        }
      );
      setLoading(false);
      setPdfUrl(convertedHtml);
    };
    getPdfUrl();
  }, [
    data.candidate,
    data.candidateContract,
    data?.expectedPosition?.contractType,
  ]);

  const detailsTemplate = useMemo(() => {
    return [
      {
        title: "Nama",
        value: data?.candidate?.name,
      },
      {
        title: "Telepon",
        value: data?.candidate?.phoneNumber,
      },
      {
        title: "NRK",
        value: data?.candidateContract?.employeeId,
      },
      {
        title: "Klien",
        value: data?.expectedPosition?.OrderScheme?.Client?.name || "-",
      },
      {
        title: "Penempatan",
        value: data?.placement || "-",
      },
    ];
  }, [
    data?.candidate?.name,
    data?.candidate?.phoneNumber,
    data?.candidateContract?.employeeId,
    data?.expectedPosition?.OrderScheme?.Client?.name,
    data?.placement,
  ]);

  return (
    <div className={Styles.Main}>
      <div className={Styles.Container}>
        <div className={Styles.Headers}>
          <div className={Styles.Logo}>
            <img src={Images.PERMATA_LOGO} alt="permata" />
          </div>
          <p>Permata Indo Sejahtera</p>
        </div>
        <div className={Styles.Content}>
          <div className={Styles.ContentText}>
            <p>Hi {data.candidate.name}</p>
            <p>
              Selamat anda kontrak kerja kamu telah selesai di proses, sekarang
              anda resmi menjadi bagian dari PT Permata Indo Sejahtera untuk
              posisi <span>{data?.expectedPosition?.name}</span>. Selanjutnya
              silahkan unduh aplikasi MyWorkSpace sebagai layanan untuk
              memudahkan pekerjaan anda dan nikmati berbagai macam fitur dan
              layanan yang telah kami sediakan.
            </p>
          </div>
          <div className={Styles.ContentDetails}>
            <p>Gunakan data berikut untuk akses aplikasi MyWorkSpace</p>
            <div className={Styles.DetailsWrapper}>
              {detailsTemplate?.map((each, i) => (
                <div key={i}>
                  <span>{each?.title}</span>
                  <span>:</span>
                  <span>{each?.value}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={Styles.ContentFile}>
            <p>Silahkan download kontrak kerja anda dibawah ini</p>
            <div className={Styles.File}>
              <div className={Styles.FileWrapper}>
                <Icon icon={"document"} size={24} />
                <p>Download dokumen kontrak kerja</p>
              </div>
              <div onClick={() => window.open(pdfUrl)}>
                <Icon icon={"arrow-down-tray"} size={24} color="#1571DE" />
              </div>
            </div>
          </div>

          <div className={Styles.DownloadCard}>
            <div className={Styles.DownloadTitle}>
              <h4>Download Aplikasi MyWorkSpace!</h4>
              <span>
                Tersedia di Android dan iPhone silahkan download melalui link di
                bawah ini
              </span>
            </div>
            <div className={Styles.Downloads}>
              <div
                className={Styles.Button}
                onClick={() =>
                  openInNewTab(
                    "https://apps.apple.com/app/myworkspace/id1618256600"
                  )
                }
              >
                <img src={Images.APPLE_WHITE_ICON} alt="" />
                <span>App Store</span>
              </div>
              <div
                className={Styles.Button}
                onClick={() =>
                  openInNewTab(
                    "https://play.google.com/store/apps/details?id=com.myworkspace"
                  )
                }
              >
                <img src={Images.PLAYSTORE_OUTLINED_WHITE} alt="" />
                <span>Google Play</span>
              </div>
            </div>
          </div>

          <div className={Styles.ContentFooter}>
            <p>Kontak Kami</p>
            <div className={Styles.ContactWrapper}>
              <div className={Styles.Contact}>
                <Icon icon={"phone"} size="20px" color={"#C2C2C2"} />
                <p>021 726 5364</p>
              </div>
              <div className={Styles.Contact}>
                <Icon icon={"email"} size="20px" color={"#C2C2C2"} />
                <p>info@permataindonesia.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
