import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import CustomBreadCrumb from '@Molecule/CustomBreadCrumb'
import useQuery from '@Hooks/useQuery'
import { useNavigate } from 'react-router-dom'

export default function ManPowerRequestSummaryNavControllerClient() {
  const query = useQuery()
  // const name = query.get('n')
  // eslint-disable-next-line
  const code = query.get('c')
  const position = query.get('p')
  const navigate = useNavigate()

  const handleClickBack = () => {
    navigate('/dashboard')
  }



  return (
    <div className={Styles.container}>
      <div className={Styles.breadcrumbWrapper}>
        <button onClick={handleClickBack}>
          <Icon icon="arrow-left-rounded-primary" color="#1571DE" size={20} />
        </button>
        <CustomBreadCrumb>
          <span className={Styles.breadcrumbText}>Man Power Request Summary</span>
          <span className={Styles.breadcrumbTextBold}>{position}</span>
        </CustomBreadCrumb>
      </div>
    </div>
  )
}