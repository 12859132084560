import StackedProfile from "@Atom/StackedProfile";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import { useMemo } from "react";
import CandidateStatusIndicator from "@Atom/CandidateStatusIndicator";

export default function CandidateDetailsModal({ data }) {
  const template = useMemo(() => {
    return [
      {
        name: "region",
        label: "Region",
      },
      {
        name: "roName",
        label: "RO",
        component: (
          <StackedProfile
            profilePicture={data?.roProfilePicture || Images.AVA_DEFAULT}
            title={data?.roName}
            label={data?.roCode}
          />
        ),
      },
      {
        name: "stage",
        label: "Stage",
        component: (
          <div>
            {data?.step !== null
              ? data?.step?.toUpperCase()
              : data?.stage?.toUpperCase()}
          </div>
        ),
      },
      {
        name: "status",
        label: "Status",
        component: (
          <CandidateStatusIndicator type={data?.status?.toLowerCase()} />
        ),
      },
    ];
  }, [data]);
  return (
    <div className={Styles.container}>
      <div className={Styles.topLine} />
      <div className={Styles.title}>Detail Kandidat</div>
      <div className={Styles.content}>
        <StackedProfile
          profilePicture={data?.profilePicture || Images.AVA_DEFAULT}
          title={data?.name}
          label={data?.phoneNumber}
        />
        {template?.map((each, i) => (
          <div className={Styles.each}>
            <span>{each?.label}</span>
            {each?.component || <span>{data[each?.name]}</span>}
          </div>
        ))}
      </div>
    </div>
  );
}
