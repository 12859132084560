import Icon from "@Atom/Icon";
import StaticProgressBar from "@Atom/StaticProgressBar";
import UseOutsideClick from "@Hooks/useOutsideClick";
import useWindowSize from "@Hooks/useWindowSize";
import AddPositionLocationModal from "@Molecule/_modal/AddPositionLocationModal";
import RequestTKModal from "@Molecule/_modal/RequestTKModal";
import SeeQualificationsModal from "@Molecule/_modal/SeeQualificationsModal";
import UpdateVacantQuotaModal from "@Molecule/_modal/UpdateVacantQuotaModal";
import Images from "@Theme/Images";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { makeRandomString } from "@Helpers/makeRandomString";
import ClientDashboardLogModal from "@Molecule/_modal/ClientDashboardLogModal";
import Tooltip from "@Atom/Tooltip";
import SearchBar from "@Atom/SearchBar";
import { getFullfillment } from "@Services/client/dashboard";
import { Skeleton } from "@mui/material";
import DetailLocationModal from "./DetailLocationModal";

export default function NewDashboardLayout({
  isLoading = false,
  progressBar = null,
  summaries = [],
  sync = null,
  dashboardSync = null,
}) {
  const [showRequest, setShowRequest] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState({});
  const [detailPosition, setDetailPosition] = useState({});
  const [searchPosition, setSearchPosition] = useState("");
  const [errorText, setErrorText] = useState("");
  const [loading, setIsLoading] = useState(false);

  const { width } = useWindowSize();

  const dataPositions = useMemo(() => {
    return dashboardSync?.data?.map((obj) => {
      // return obj;
      return {
        id:
          (obj?.datas?.length ? obj?.datas[0]?.positionID : obj?.position_name),
        positionName: obj?.position_name,
        quota: obj?.quota,
        fulfilled: obj?.fullfillment,
        vendor: obj?.vendor || obj?.quota - obj?.vacant,
        vacant: obj?.vacant || 0,
        cityLength: obj?.cityLength || 0,
        regions: obj?.city?.map((d) => {
          return {
            id: d?.id || d?.city,
            positionID: d?.positionID || d?.city,
            cityName: d?.city,
            cityCode: d?.City?.code || "-",
            updatedAt: d?.lastUpdated ? new Date(d?.lastUpdated) : "",
            request: d?.quota,
            fulfilled: d?.fulfillment,
            vendor: d?.vendor,
            vacant: d?.vacant,
            qualifications: [],
          };
        }),
      };
    });
  }, [dashboardSync]);

  useEffect(() => {
    if (dataPositions?.length > 0){
      setSelectedPosition(dataPositions?.[0] || {})
    }
  }, [dataPositions]);

  // useEffect(() => {
  //     async function fetchFullfillment() {
  //         try {
  //             setIsLoading(true)
  //             const { response } = await getFullfillment(
  //                 localStorage?.getItem('clientCode'),
  //                 selectedPosition?.positions
  //             );
              
  //             const detail = {
  //                 id: response?.position,
  //                 positionName: response?.position,
  //                 quota: response?.totalQuota,
  //                 fulfilled: response?.totalFulfillment,
  //                 vendor: response?.totalVendor || response?.quota - response?.totalVacant,
  //                 vacant: response?.totalVacant || 0,
  //                 regions: response?.city?.map((d) => {
  //                     return {
  //                         id: d?.id || d?.city,
  //                         positionID: d?.positionID || d?.city,
  //                         cityName: d?.city,
  //                         cityCode: d?.City?.code || '-',
  //                         updatedAt: d?.lastUpdated ? new Date(d?.lastUpdated) : '',
  //                         request: d?.quota,
  //                         fulfilled: d?.fulfillment,
  //                         vendor: d?.vendor,
  //                         vacant: d?.vacant,
  //                         qualifications: [],
  //                     };
  //                 }),
  //             };
  //             setDetailPosition(detail);
  //             setIsLoading(false)
  //             setErrorText('')
  //           } catch (error) {
  //             console.log(error, 'ERROR')
  //             setErrorText(error?.response?.data?.error || "Something went wrong")
  //             setIsLoading(false)
  //         }
  //     }
  //     if (selectedPosition?.positions){
  //       fetchFullfillment();
  //     }
  // }, [selectedPosition]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <img src={Images.CLIENT_HEADER} alt="building" />
        <div className={Styles.headerItems}>
          <h2>
            Dashboard Pemenuhan Tenaga Kerja <span>Outsourcing</span>
          </h2>
          <div className={Styles.hActions}>
            {/* {dataPositions?.length && (
              <button
                disabled
                className={Styles.add}
                onClick={() => setShowRequest(true)}
              >
                <span>Tambah Posisi Baru</span>
                <Icon icon={"add-circle"} size={20} />
              </button>
            )} */}

            <button className={Styles.log} onClick={() => setShowLog(true)}>
              <Icon icon={"clock-back"} size={24} />
            </button>
          </div>
        </div>
      </div>
      <div className={Styles.content}>
        <div className={`${Styles.section} ${Styles.quotaFulfillment}`}>
          <div className={Styles.qFHeader}>
            <h3>Pemenuhan Kuota</h3>
            <div className={Styles.indicators}>
              {[
                {
                  title: "Jumlah Terpenuhi",
                  bgColor: "#1571DE",
                },
                {
                  title: "Vendor",
                  bgColor: "#91BDF1",
                  fontStyle: "italic",
                },
                {
                  title: "Vacant",
                  bgColor: "#EDEDED",
                  fontStyle: "italic",
                },
              ]?.map((x, xI) => (
                <div key={xI} className={Styles.indicator}>
                  <div style={{ backgroundColor: x?.bgColor }} />
                  <span style={{ fontStyle: x?.fontStyle || "normal" }}>
                    {x?.title}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {isLoading ? (
            <Skeleton
              // variant="circular"
              width={"100%"}
              height={50}
              sx={{
                borderRadius: "20px",
              }}
            />
          ) : (
            <Tooltip
              content={
                <div className={Styles.tooltip}>
                  <p>
                    <span>Jumlah Terpenuhi</span>
                    <span>:</span>
                    <span>{`${dashboardSync?.totalFullfillment} (${dashboardSync?.totalQuota == 0 ? 0 :Math.floor(
                      (dashboardSync?.totalFullfillment /
                        dashboardSync?.totalQuota) *
                        100
                    )}%)`}</span>
                  </p>
                  <p>
                    <span>Vendor Lain</span>
                    <span>:</span>
                    <span>{dashboardSync?.totalVendor}</span>
                  </p>
                  <p>
                    <span>Vacant</span>
                    <span>:</span>
                    <span>{dashboardSync?.totalVacant}</span>
                  </p>
                </div>
              }
              direction="top"
              backgroundColor="#fff"
              boxShadow="0px 6px 24px 0px rgba(0, 0, 0, 0.15)"
              tooltipMargin="75px"
              isV2
            >
              <StaticProgressBar
                fulfilledAmount={dashboardSync?.totalFullfillment || 0}
                middleFulfilledText={`${dashboardSync?.totalQuota == 0 ? 0 : Math.floor(
                  (dashboardSync?.totalFullfillment /
                    dashboardSync?.totalQuota) *
                    100
                )}%`}
                // fulfilledText={`${progressBar?.fulfillment || 0} (${Math.floor(+progressBar?.fulfillment / +progressBar?.quota * 100)}%)`}
                candidatesAmount={
                  dashboardSync?.totalFullfillment + dashboardSync?.totalVendor
                }
                candidatesText={dashboardSync?.totalVendor}
                requestAmount={dashboardSync?.totalQuota || 0}
                vacantAmount={dashboardSync?.totalVacant || 0}
                isV3
                borderRadius="0px"
                height={width > 768 ? "32px" : "28px"}
              />
            </Tooltip>
          )}
        </div>
        {errorText && <div className={Styles.errorText}>{errorText}</div>}
        {isLoading || loading ? (
          <div className={Styles.positions}>
            <PositionList
              positions={dataPositions}
              selectedPosition={selectedPosition}
              setSelectedPosition={setSelectedPosition}
              searchPosition={searchPosition}
              setSearchPosition={setSearchPosition}
              isLoading={isLoading || loading}
            />
            <PositionCard key={1} d={{}} sync={sync} isLoading={isLoading || loading} />
          </div>
        ) : dataPositions?.length ? (
          <div className={Styles.positions}>
            <PositionList
              positions={dataPositions}
              selectedPosition={selectedPosition}
              setSelectedPosition={setSelectedPosition}
              searchPosition={searchPosition}
              setSearchPosition={setSearchPosition}
            />
            <PositionCard d={selectedPosition} sync={sync} detailPosition={detailPosition} setDetailPosition={setDetailPosition}/>
            {/* {dataPositions?.map((d, dI) => (
              <PositionCard key={dI} d={d} sync={sync} />
            ))} */}
          </div>
        ) : (
          <div className={Styles.empty}>
            <img src={Images.NO_DATA} alt="" />
            <span>Data Tidak Tersedia</span>
            <p>
              Untuk saat ini kamu belum memiliki order Tenaga Kerja. Ayo lakukan
              request tenaga kerja sekarang.
            </p>
            <button disabled onClick={() => setShowRequest(true)}>
              <span>Tambah Posisi Baru</span>
              <Icon icon={"add-circle"} size={20} />
            </button>
          </div>
        )}
      </div>
      {/* {showRequest && (
        <RequestTKModal
          show={showRequest}
          onClose={() => setShowRequest(false)}
          sync={sync}
          dataPositions={dataPositions}
        />
      )} */}
      {showLog && (
        <ClientDashboardLogModal
          show={showLog}
          onClose={() => setShowLog(false)}
          dataPositions={dataPositions}
        />
      )}
    </div>
  );
}

const PositionCard = ({ d, sync = null, isLoading = false, detailPosition, setDetailPosition = () => {} }) => {
  const [seeQualifications, setSeeQualifications] = useState(null);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onRenewData = () => {
    navigate(`${pathname}?t=${makeRandomString(5)}`);
  };

  const [expandMore, setExpandMore] = useState(false);
  const [addLocation, setAddLocation] = useState(false);
  const [updateVacantQuota, setUpdateVacantQuota] = useState(false);
  const moreRef = useRef();
  const outsideClickMore = UseOutsideClick(moreRef);

  useEffect(() => {
    if (expandMore && outsideClickMore) {
      setExpandMore(false);
    }
  }, [expandMore, outsideClickMore]);

  const { width } = useWindowSize();

  return (
    <div className={`${Styles.section} ${Styles.positionCard}`}>
      <div className={Styles.pHeader}>
        <h3>
          {isLoading ? (
            <Skeleton
              // variant="circular"
              width={"20%"}
              height={30}
              sx={{
                borderRadius: "12px",
              }}
            />
          ) : (
            d?.positionName
          )}
        </h3>
        <div className={Styles.pHActions}>
          {/* <div>
            <button onClick={() => setSeeQualifications(d)}>
              {width > 768 && <span>Lihat Kualifikasi</span>}
              <Icon icon={"eye-visible"} size={20} />
            </button>
          </div> */}
          <div>
            <button onClick={() => setUpdateVacantQuota(true)}>
              <span>Update Kuota & Vacant</span>
            </button>
          </div>
          {/* {isLoading ? null : (
            <div>
              <button onClick={() => setSeeQualifications(d)}>
                {width > 768 && <span>Lihat Kualifikasi</span>}
                <Icon icon={"eye-visible"} size={20} />
              </button>
            </div>
          )} */}

          <div ref={moreRef}>
            {/* <button onClick={() => setExpandMore(!expandMore)}>
              <Icon icon={"three-dots"} size={20} />
            </button> */}
            {expandMore && (
              <div className={Styles.moreOptions}>
                {[
                  {
                    label: (
                      <p>
                        Update Kuota & <span>Vacant</span>
                      </p>
                    ),
                    onClick: () => setUpdateVacantQuota(true),
                  },
                  // {
                  //   label: <p>Tambah Lokasi</p>,
                  //   onClick: () => setAddLocation(true),
                  // }
                ]?.map((o, oI) => (
                  <div
                    key={oI}
                    className={o?.disabled ? Styles.disabled : ""}
                    onClick={() => {
                      if (!o?.disabled) {
                        o?.onClick();
                      }
                      setExpandMore(false);
                    }}
                  >
                    {o?.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={Styles.pIndicators}>
        {[
          {
            title: "Total Kuota",
            value: d?.quota,
            subTitle: "Man Power",
          },
          {
            title: "Vacant",
            titleFontStyle: "italic",
            value: d?.vacant,
            subTitle: "Permintaan",
          },
          {
            title: "Total Pemenuhan",
            extraTitle: (
              <div className={Styles.extra}>
                <span>Permata Indonesia</span>
                <div>
                  <img src={Images.INITIAL_LOGO} alt="" />
                </div>
              </div>
            ),
            value: d?.fulfilled,
            subTitle: "Man Power",
          },
          {
            title: "Persentase Terhadap Pemenuhan",
            value: `${
              d?.quota == 0 ? 0 : Math.floor((d?.fulfilled / d?.quota) * 100)
            }%`,
            subTitle: "Terpenuhi",
          },
        ]?.map((i, iI) => (
          <div key={iI} className={Styles.pIndicator}>
            <div className={Styles.pTitle}>
              <span>{i?.title}</span>
              {!!i?.extraTitle && i?.extraTitle}
            </div>
            <span>
              {isLoading ? (
                <Skeleton
                  // variant="circular"
                  width={50}
                  height={30}
                  sx={{
                    borderRadius: "12px",
                  }}
                />
              ) : (
                i?.value || 0
              )}
            </span>
            <p>{i?.subTitle}</p>
          </div>
        ))}
      </div>
      <div className={`${Styles.pList} ${Styles.customScroll}`}>
        <table>
          <thead>
            {width > 788 ? (
              <tr>
                <th>Kota</th>
                <th>
                  Tanggal <span style={{ fontStyle: "italic" }}>Update</span>
                </th>
                <th>Jumlah Permintaan & Terpenuhi</th>
              </tr>
            ) : (
              <tr>
                <th>
                  <div className={Styles.mobileTHeader}>
                    <span>Kota</span>
                    <span>Tgl Permintaan</span>
                  </div>
                </th>
              </tr>
            )}
          </thead>
          <tbody>
            {isLoading
              ? [...Array(5)]?.map((r, rI) => {
                  return width > 768 ? (
                    <tr
                      key={rI}
                      className={rI === 0 || rI % 2 === 0 ? Styles.odd : ""}
                    >
                      <td className={Styles.bold}>
                        {isLoading ? (
                          <Skeleton
                            // variant="circular"
                            width={"100%"}
                            height={30}
                            sx={{
                              borderRadius: "12px",
                            }}
                          />
                        ) : (
                          r?.cityName
                        )}
                      </td>
                      <td>
                        {isLoading ? (
                          <Skeleton
                            // variant="circular"
                            width={"100%"}
                            height={30}
                            sx={{
                              borderRadius: "12px",
                            }}
                          />
                        ) : r?.updatedAt ? (
                          moment(new Date(r?.updatedAt))?.format("LL")
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {isLoading ? (
                          <Skeleton
                            // variant="circular"
                            width={"100%"}
                            height={30}
                            sx={{
                              borderRadius: "12px",
                            }}
                          />
                        ) : (
                          <div className={Styles.progressWrapper}>
                            <Tooltip
                              content={
                                <div className={Styles.tooltip}>
                                  <p>
                                    <span>Jumlah Terpenuhi</span>
                                    <span>:</span>
                                    <span>{`${r?.fulfilled} (${r?.request == 0 ? 0 :Math.floor(
                                      (r?.fulfilled / r?.request) * 100
                                    )}%)`}</span>
                                  </p>
                                  <p>
                                    <span>Vendor Lain</span>
                                    <span>:</span>
                                    <span>{r?.vendor}</span>
                                  </p>
                                  <p>
                                    <span>Vacant</span>
                                    <span>:</span>
                                    <span>{r?.vacant}</span>
                                  </p>
                                </div>
                              }
                              direction="top"
                              backgroundColor="#fff"
                              boxShadow="0px 6px 24px 0px rgba(0, 0, 0, 0.15)"
                              tooltipMargin="75px"
                              isV2
                            >
                              <StaticProgressBar
                                fulfilledAmount={r?.fulfilled}
                                // fulfilledText={`${r?.fulfilled} (${Math.floor(r?.fulfilled / r?.request * 100)}%)`}
                                candidatesAmount={r?.vendor}
                                candidatesText={r?.vendor}
                                requestAmount={r?.request}
                                vacantAmount={r?.vacant}
                                isV3
                                borderRadius="0px"
                              />
                            </Tooltip>
                          </div>
                        )}
                      </td>
                    </tr>
                  ) : (
                    <tr
                      key={rI}
                      className={rI === 0 || rI % 2 === 0 ? Styles.odd : ""}
                    >
                      <td>
                        <div className={Styles.div}>
                          <div>
                            <span className={Styles.bold}>
                              {isLoading ? (
                                <Skeleton
                                  // variant="circular"
                                  width={50}
                                  height={30}
                                  sx={{
                                    borderRadius: "12px",
                                  }}
                                />
                              ) : (
                                r?.cityName
                              )}
                            </span>
                            <span>
                              {isLoading ? (
                                <Skeleton
                                  // variant="circular"
                                  width={50}
                                  height={30}
                                  sx={{
                                    borderRadius: "12px",
                                  }}
                                />
                              ) : (
                                moment(new Date(r?.updatedAt))?.format("LL")
                              )}
                            </span>
                          </div>
                          {isLoading ? (
                            <Skeleton
                              // variant="circular"
                              width={"100%"}
                              height={30}
                              sx={{
                                borderRadius: "12px",
                              }}
                            />
                          ) : (
                            <StaticProgressBar
                              fulfilledAmount={r?.fulfilled}
                              fulfilledText={`${r?.fulfilled} (${r?.request == 0 ? 0 : Math.floor(
                                (r?.fulfilled / r?.request) * 100
                              )}%)`}
                              candidatesAmount={r?.fulfilled + r?.vendor}
                              candidatesText={r?.vendor}
                              requestAmount={r?.request}
                              vacantAmount={r?.vacant}
                              isV3
                              borderRadius="0px"
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              : d?.regions?.map((r, rI) =>
                  width > 768 ? (
                    <tr
                      key={rI}
                      className={rI === 0 || rI % 2 === 0 ? Styles.odd : ""}
                      style={{cursor: "pointer"}}
                      onClick={() => { setDetailPosition(r) }}
                    >
                      <td className={Styles.bold}>{r?.cityName}</td>
                      <td>
                        {r?.updatedAt
                          ? moment(new Date(r?.updatedAt))?.format("LL")
                          : "-"}
                      </td>
                      <td>
                        <div className={Styles.progressWrapper}>
                          <Tooltip
                            content={
                              <div className={Styles.tooltip}>
                                <p>
                                  <span>Jumlah Terpenuhi</span>
                                  <span>:</span>
                                  <span>{`${r?.fulfilled} (${r?.request == 0 ? 0 : Math.floor(
                                    (r?.fulfilled / r?.request) * 100
                                  )}%)`}</span>
                                </p>
                                <p>
                                  <span>Vendor Lain</span>
                                  <span>:</span>
                                  <span>{r?.vendor}</span>
                                </p>
                                <p>
                                  <span>Vacant</span>
                                  <span>:</span>
                                  <span>{r?.vacant}</span>
                                </p>
                              </div>
                            }
                            direction="top"
                            backgroundColor="#fff"
                            boxShadow="0px 6px 24px 0px rgba(0, 0, 0, 0.15)"
                            tooltipMargin="75px"
                            isV2
                          >
                            <StaticProgressBar
                              fulfilledAmount={r?.fulfilled}
                              // fulfilledText={`${r?.fulfilled} (${Math.floor(r?.fulfilled / r?.request * 100)}%)`}
                              candidatesAmount={r?.vendor}
                              candidatesText={r?.vendor}
                              requestAmount={r?.request}
                              vacantAmount={r?.vacant}
                              isV3
                              borderRadius="0px"
                            />
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr
                      key={rI}
                      className={rI === 0 || rI % 2 === 0 ? Styles.odd : ""}
                      style={{cursor: "pointer"}}
                      onClick={() => { setDetailPosition(r) }}
                    >
                      <td>
                        <div className={Styles.div}>
                          <div>
                            <span className={Styles.bold}>{r?.cityName}</span>
                            <span>
                              {r?.updatedAt ? moment(new Date(r?.updatedAt))?.format("LL") : "-"}
                            </span>
                          </div>
                          <StaticProgressBar
                            fulfilledAmount={r?.fulfilled}
                            fulfilledText={`${r?.fulfilled} (${r?.request == 0 ? 0 :Math.floor(
                              (r?.fulfilled / r?.request) * 100
                            )}%)`}
                            candidatesAmount={r?.fulfilled + r?.vendor}
                            candidatesText={r?.vendor}
                            requestAmount={r?.request}
                            vacantAmount={r?.vacant}
                            isV3
                            borderRadius="0px"
                          />
                        </div>
                      </td>
                    </tr>
                  )
                )}
          </tbody>
        </table>
      </div>
      {/* {seeQualifications && (
        <SeeQualificationsModal
          data={seeQualifications}
          onClose={() => setSeeQualifications(null)}
          sync={sync}
        />
      )} */}
      {/* {addLocation && (
        <AddPositionLocationModal
          show={addLocation}
          positionData={d}
          onClose={() => setAddLocation(false)}
          sync={sync}
        />
      )} */}
      {updateVacantQuota && (
        <UpdateVacantQuotaModal
          show={updateVacantQuota}
          data={d}
          position={d?.positionName}
          onClose={() => setUpdateVacantQuota(false)}
          onRenew={onRenewData}
        />
      )}
      {detailPosition?.cityName && (
        <DetailLocationModal
          vacant={detailPosition?.vacant}
          vendor={detailPosition?.vendor}
          request={detailPosition?.request}
          fulfilled={detailPosition?.fulfilled}
          cityName={detailPosition?.cityName}
          position={d?.positionName}
          clientCode={localStorage?.getItem('clientCode')}
          show={detailPosition?.cityName || false}
          onClose={() => {setDetailPosition({})}}
        />
      )}
    </div>
  );
};

const PositionList = ({
  positions = [],
  selectedPosition,
  setSelectedPosition = () => {},
  searchPosition = "",
  setSearchPosition = () => {},
  isLoading
}) => {
  const positionFilter = useMemo(() => {
    if (searchPosition) {
      return positions?.filter((f) =>
        f?.positionName?.toLowerCase()?.includes(searchPosition?.toLowerCase())
      );
    }
    return positions;
  }, [searchPosition, positions]);

  return (
      <div className={`${Styles.section} ${Styles.positionListContainer}`}>
          <div className={Styles.titleContainer}>
              <div className={Styles.iconCirlce}>
                  <Icon icon="briefcase-outlined" size="16px" />
              </div>
              <span>Daftar Posisi</span>
          </div>
          <div className={Styles.searchWrapper}>
              <SearchBar
                  value={searchPosition}
                  onChange={(e) => setSearchPosition(e?.target?.value)}
                  placeholder={'Cari Posisi'}
              />
          </div>
          <div className={`${Styles.listContent} ${Styles.customScroll}`}>
              {isLoading
                  ? [...Array(5)]?.map((value, index) => (
                        <div
                            className={`${Styles.itemContent}`}
                            key={`positionList_${index}`}>
                            <Skeleton
                                  // variant="circular"
                                  width={'100%'}
                                  height={30}
                                  sx={{
                                      borderRadius: '12px',
                                  }}
                              />
                        </div>
                    ))
                  : positionFilter?.map((value, index) => (
                        <div
                            className={`${Styles.itemContent}  ${
                                value?.positionName === selectedPosition?.positionName ? Styles.selectedItemContent : ''
                            }`}
                            key={`positionList_${index}`}
                            onClick={() => setSelectedPosition(value)}>
                            <div className={Styles.leftItem}>
                                <div className={Styles.staticWidthIcon}>
                                    <Icon icon="bag-job" size="24px" />
                                </div>
                                <span>{value?.positionName || '-'}</span>
                            </div>
                            <div className={Styles.rightItem}>
                                <span>{value?.cityLength || '0'}</span>
                                <div className={Styles.staticWidthIcon}>
                                    <Icon icon="arrow-right" size="24px" />
                                </div>
                            </div>
                        </div>
                    ))}
          </div>
      </div>
  );
};
