import { API } from "@Config/api";

export function getBMList(page = 1, limit = 10, search = 1) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/rbm/bm`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          page,
          limit,
          search,
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// /rbm/clients/position/qualifications?position=DRIVER&clientCode=BM-0014

export function getQualificationFulfillment(position, clientCode) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/rbm/clients/position/qualifications?position=${position}&clientCode=${clientCode}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getSyncTransferBM() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/rbm/transfer-sync`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getFulfillmentDetail(code, employeeId, step) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        localStorage.getItem('role')?.includes('REGION')
          ?
          `/rbm/clients/detail/${code}?bmID=${employeeId}&step=${step}`
          :
          `/bm/dashboard/clients/${code}`,

        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getPositionLogRBM(code, page, limit, position) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/rbm/clients/position/log/${code}?page=${page}&limit=${limit}&position=${position}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// /rbm/clients/position/log/TAK1?page=1&limit=10&position=SORTASI

export function transferOwnerhip(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/rbm/transfer-ownership`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getBMClients(
  id = "",
  page = 1,
  limit = 10,
  search = 1,
  clientType = "OWNER"
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/rbm/clients/${id}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          page,
          limit,
          search,
          clientType,
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getRBMClients(page = 1, limit = 10, search = 1, isRBM = false) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/${isRBM ? "rbm" : "bm/dashboard"}/clients`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
          params: {
            page,
            limit,
            search,
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getBMDashboardSync(
  bmID = "",
  bmForChart = [],
  step = "",
  isRBM = false
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        isRBM ? "/rbm/dashboard-sync" : "/bm/dashboard/sync",
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
          params: {
            bmID,
            // month,
            // year,
            step,
            bmForChart: bmForChart?.length ? bmForChart?.join(",") : "",
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function downloadBM() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/rbm/download-bm`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        responseType: "arraybuffer",
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function downloadClients(isRBM = false) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        isRBM ? `/rbm/download-clients` : `/bm/dashboard/clients/download`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          responseType: "arraybuffer",
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getFLBLogs(schemeID = "", page = 1, limit = 10, type = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/order/log`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          schemeID,
          page,
          limit,
          type,
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
