import { Table, TableContainer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import EmptyHandler from "./Content/EmptyHandler";
import TableData from "./Content/TableData";
import TableHeader from "./Content/TableHeader";
import Styles from "./style.module.scss";
import Pagination from "@Molecule/Pagination";
import ViewDetailModal from "./Content/ViewEmployeeModal";
import UseOutsideClick from "@Hooks/useOutsideClick";
import { getClientCandidates } from "@Services/client/employee";

export default function EmployeeListLayout({
  totalPage,
  setTotalPage,
  fixedSearchTerm,
  defaultCandidatesPackage,
  value,
  setValue,
  pages,
  setPages,
  pageList,
  setPageList,
  selectedPage,
  setSelectedPage,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [data, setData] = useState(defaultCandidatesPackage?.data);

  useEffect(() => {
    setTotalPage(defaultCandidatesPackage?.totalPage);
    // eslint-disable-next-line
  }, [defaultCandidatesPackage]);

  useEffect(() => {
    const query = `limit=${value}&page=${selectedPage}&search=${fixedSearchTerm}`;
    const refetch = async () => {
      const { response } = await getClientCandidates(query);
      setData(response?.employee?.data);
      setTotalPage(response?.employee?.totalPage);
    };

    refetch();
    // eslint-disable-next-line
  }, [fixedSearchTerm, selectedPage, value]);

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (selectedEmployee !== null) {
      if (boxOutsideClick === false) {
        setSelectedEmployee(null);
      }
    }
  }, [boxOutsideClick, selectedEmployee]);

  return (
    <div className={Styles.tableWrapper}>
      <TableContainer className={Styles.tableContainer}>
        <Table className={Styles.table}>
          <TableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            values={data}
          />

          {data?.length > 0 && (
            <TableData
              data={data}
              order={order}
              orderBy={orderBy}
              setSelectedEmployee={setSelectedEmployee}
            />
          )}
        </Table>
      </TableContainer>
      {data?.length === 0 && <EmptyHandler />}
      <div className={Styles.PaginationWrapper}>
        <Pagination
          total={data?.length}
          totalPage={totalPage || 1}
          value={value}
          setValue={setValue}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>

      <div
        className={`${Styles.modalWrapper} ${
          selectedEmployee ? Styles.showModal : Styles.hideModal
        }`}
      >
        <ViewDetailModal
          data={selectedEmployee}
          setData={setSelectedEmployee}
        />
      </div>
      <div
        ref={boxRef}
        className={`${Styles.overlay} ${
          selectedEmployee ? Styles.visible : ""
        }`}
      />
    </div>
  );
}
