// import React from "react";
// import JobSeekersHome from "@Organism/Home/JobSeekers";
import React, { Suspense, useEffect, useRef, useState } from "react";
// import HomeFooter from "../Business/HomeFooter";
import Styles from "./style.module.scss";
import JobSeekersMobileAppAd from "@Molecule/_jobSeekers/MobileAppAd";
import JobSeekersLocation from "@Molecule/_jobSeekers/JobSeekersLocation";
import JobSeekersLinks from "@Molecule/_jobSeekers/JobSeekersLinks";
import JobSeekerListHome from "@Molecule/_jobSeekers/JobSeekerListHome";
import JobSeekersTestimonies from "@Molecule/_jobSeekers/JobSeekersTestimonies";
import HomeFooter from "@Organism/Home/Business/HomeFooter";
import { getListJobSeeker } from "@Services/client/jobSeeker";
import { Await, defer, useLoaderData } from "react-router-dom";
import LoadingAnimation from "@Atom/LoadingAnimation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import JobSeekerHome from "@Organism/Client/JobSeekerHome";
import ModalFilterJobSeeker from "@Molecule/_modal/ModalFilterJobSeeker";

export async function loader() {
  const packageJobSeekerPromise = getListJobSeeker(
    9,
    1,
    "",
    "",
    "",
    "",
    "",
    ""
  );
  return defer({
    packageJobSeeker: packageJobSeekerPromise,
  });
}

export default function JobSeekersHomePage() {
  const { packageJobSeeker } = useLoaderData();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <Suspense
      fallback={
        <div>
          <div className={Styles.main}>
            <div className={Styles.homeLanding}>
              <div className={Styles.autoScroll} ref={targetElement} />
              <JobSeekerHome
                options={null}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            </div>
            <div className={`${Styles.loadingContainer} ${Styles.section}`}>
              <LoadingAnimation />
            </div>
            <div className={`${Styles.mobileAppAdSection} ${Styles.section}`}>
              <JobSeekersMobileAppAd />
            </div>
            <div className={`${Styles.locationSection} ${Styles.section}`}>
              <JobSeekersLocation />
            </div>
            <div className={`${Styles.testimonySection} ${Styles.section}`}>
              <JobSeekersTestimonies />
            </div>
            <div className={Styles.section}>
              <JobSeekersLinks />
            </div>
          </div>
          <div id="Get app">
            <HomeFooter />
          </div>
          {showFilter && (
            <ModalFilterJobSeeker
              handleDone={() => setShowFilter(false)}
              options={null}
              handleClose={() => setShowFilter(false)}
            />
          )}
        </div>
      }
    >
      <Await
        resolve={packageJobSeeker}
        errorElement={
          <div style={{ height: "calc(100vh - 220px)" }}>
            <AsyncErrorBoundary />
          </div>
        }
      >
        {(packageJobSeeker) => (
          <div>
            <div className={Styles.main}>
              <div className={Styles.homeLanding}>
                <div className={Styles.autoScroll} ref={targetElement} />
                <JobSeekerHome
                  options={packageJobSeeker?.response}
                  showFilter={showFilter}
                  setShowFilter={setShowFilter}
                />
              </div>
              <div className={Styles.section}>
                <div className={Styles.jobSeekerListSection}>
                  <JobSeekerListHome
                    initialData={packageJobSeeker?.response}
                    isLoadingData={isLoadingData}
                    setIsLoadingData={setIsLoadingData}
                  />
                </div>
                <div className={Styles.mobileAppAdSection}>
                  <JobSeekersMobileAppAd />
                </div>
                <div className={Styles.locationSection}>
                  <JobSeekersLocation />
                </div>
              </div>
              <div className={Styles.testimonialSection}>
                <JobSeekersTestimonies />
              </div>
              <div className={Styles.section}>
                <div className={Styles.links}>
                  <JobSeekersLinks />
                </div>
              </div>
            </div>
            <div id="Get app">
              <HomeFooter />
            </div>

            {showFilter && (
              <ModalFilterJobSeeker
                handleDone={() => setShowFilter(false)}
                options={packageJobSeeker?.response}
                handleClose={() => setShowFilter(false)}
              />
            )}
          </div>
        )}
      </Await>
    </Suspense>
  );
}
