import ModalWrapper from '@Atom/ModalWrapper';
import { fileBaseUrl } from '@Config/api';
import DetailRemarkModal from '@Molecule/_modal/DetailRemarkModal';
import CustomTable from '@Molecule/CustomTable';
import Images from '@Theme/Images';
import moment from 'moment';
import { useState } from 'react';
import Styles from './style.module.scss';

export default function FirstSection({
  type,
  documents
}) {
  const [showRemark, setShowRemark] = useState(null);


  const columns = [
    {
      name: "docNumber",
      label: "Document Number",
    },
    {
      name: "docType",
      label: "Document Type",
    },
    {
      name: "uploadedAt",
      label: "Upload Date & Time",
      renderData: (row) => (
        <span>
          {moment(row?.uploadedAt)?.format("LLL")?.replaceAll(" pukul", ",")}
        </span>
      ),
    },
    {
      name: "startDate",
      label: "Start Date",
      renderData: (row) => (
        <span>
          {moment(row?.startDate)?.format("LLL")?.replaceAll(" pukul", ",")}
        </span>
      ),
    },
    {
      name: "endDate",
      label: "End Date",
      renderData: (row) => (
        <span>
          {moment(row?.endDate)?.format("LLL")?.replaceAll(" pukul", ",")}
        </span>
      ),
    },
    {
      name: "attachment",
      label: "Attachment",
      renderData: (row) => (
        <span
          className={`${Styles.seeFile} ${!row?.file && Styles.disabled} ${Styles.link}`}
          onClick={() => {
            if (row?.file) {
              window
                .open(
                  row?.file instanceof File
                    ? URL.createObjectURL(row?.file)
                    : fileBaseUrl + row?.file,
                  "_blank"
                )
                .focus();
            }
          }}
        >
          Lihat File
        </span>
      ),
    },
    {
      name: "remark",
      label: "Catatan",
      renderData: (row) => (
        <div
          className={`${Styles.remarkRow} ${!row?.remark && Styles.disabled} ${Styles[row?.remark ? 'link' : '']}`}
          onClick={() => {
            if (row?.remark) {
              setShowRemark(row);
            }
          }}
        >
          {row?.remark ? "Lihat Catatan" : "-"}
        </div>
      ),
    },

  ];

  return (
    <div className={Styles.container}>
      <div className={Styles.type} >

        <div className={Styles.cardHeader}>
          <h4>{type?.title}</h4>
          <span>{type?.label}</span>
        </div>
        <div className={Styles.cardContent}>
          {type?.rules?.map((rule, ir) => {
            return (
              <div key={ir} className={Styles.rule}>
                <img src={Images.CHECK_MARK} alt="check" />
                <p>{rule?.desc}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={Styles.documents}>
        <h4>Legal Document</h4>
        <div>
          <CustomTable

            noPagination
            minHeight="250px"
            data={documents}
            columns={columns}
            oddRowClassName={Styles.odd}
            customEmptyHandler={
              <div className={Styles.empty}>
                <img src={Images.TIME_MANAGEMENT} alt="" />
                <div>
                  <span>Belum Ada Dokumen Ditambahkan</span>
                  <p>
                    Anda belum menambahkan dokumen legal untuk kebutuhan orderan
                    klien.
                  </p>
                </div>
                {/* <button
                  className={Styles.contractButton}
                  onClick={() => setShowAddNewContracts(true)}
                >
                  Contract Update
                </button> */}
              </div>
            }
          />
        </div>
      </div>

      <ModalWrapper show={showRemark} handleClose={() => { }}>
        <DetailRemarkModal
          data={showRemark}
          handleClose={() => setShowRemark(null)}
        />
      </ModalWrapper>
    </div>
  )
}