/* eslint-disable eqeqeq */
import { useMemo } from "react";
import Styles from "./style.module.scss";

export default function StaticProgressBar({
  fulfilledAmount = 0,
  fulfilledText = "",
  candidatesAmount = 0,
  candidatesText = "",
  vacantAmount = "",
  requestAmount = 0,
  isV2 = false,
  isV3 = false,
  borderRadius = "20px",
  height = "20px",
  middleFulfilledText = "",
}) {
  const candidatesBarWidth = useMemo(() => {
    return candidatesAmount
      ? (+candidatesAmount / +requestAmount) * 100 + "%"
      : "0%";
  }, [candidatesAmount, requestAmount]);

  const fulfilledBarWidth = useMemo(() => {
    return fulfilledAmount
      ? (+fulfilledAmount / +requestAmount) * 100 + "%"
      : "0%";
  }, [fulfilledAmount, requestAmount]);

  return (
    <div
      className={`${Styles.container} ${isV2 ? Styles.v2 : ""} ${
        isV3 ? Styles.v3 : ""
      }`}
      style={{ height }}
    >
      <div className={Styles.totalBar}>
        {fulfilledBarWidth !== "0%" && (
          <div
            className={`${Styles.fulfilledBar} ${isV2 ? Styles.v2 : ""} ${
              isV3 ? Styles.v3 : ""
            }`}
            style={{ width: fulfilledBarWidth, borderRadius }}
          >
            <span>{fulfilledText || fulfilledAmount}</span>
            {middleFulfilledText && <p>{middleFulfilledText}</p>}
          </div>
        )}
        {candidatesBarWidth !== "0%" && (
          <div
            className={`${Styles.candidatesBar} ${isV2 ? Styles.v2 : ""} ${
              isV3 ? Styles.v3 : ""
            }`}
            style={{ width: candidatesBarWidth, borderRadius }}
          >
            <span
              className={`${isV2 ? Styles.v2 : ""} ${isV3 ? Styles.v3 : ""}`}
            >
              {candidatesText || candidatesAmount}
            </span>
          </div>
        )}
        {vacantAmount != 0 && <span>{vacantAmount}</span>}
      </div>
      <span>{requestAmount}</span>
    </div>
  );
}
