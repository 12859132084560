const { API } = require("@Config/api");

export function getListJobSeeker(
  limit,
  page,
  placement,
  title,
  contractType,
  experience,
  minSalary,
  maxSalary
) {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("limit", limit);
      formData.append("page", page);
      formData.append("placement", placement || "");
      formData.append("title", title || "");

      if (contractType?.length > 0) {
        contractType?.forEach((contractType) => {
          formData.append("contractType", contractType || "");
        });
      }

      if (experience?.length > 0) {
        experience?.forEach((experience) => {
          formData.append("experience", experience || "");
        });
      }

      formData.append("minSalary", minSalary || "");
      formData.append("maxSalary", maxSalary || "");

      const queryString = new URLSearchParams(formData).toString();

      // console.log(queryString);

      const { data } = await API.get(`/job-post/landing/?${queryString}`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
