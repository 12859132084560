import SelectOptionsToggle from "@Molecule/SelectOptionToggle";
import { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import { makeRandomString } from "@Helpers/makeRandomString";
import Icon from "@Atom/Icon";

export default function TaxesSection(props) {
  const {
    options,
    form,
    setForm,
    setTrigger,
    alertMessage = "",
    contractForm,
  } = props;
  const [PPH21, setPPH21] = useState(form?.taxs?.pph21);
  const [PPH23, setPPH23] = useState(form?.taxs?.pph23);
  const [PPN, setPPN] = useState(form?.taxs?.ppn);

  useEffect(()=> {
    setPPH21(form?.taxs?.pph21)
  }, [form?.taxs?.pph21])
  useEffect(()=> {
    setPPH23(form?.taxs?.pph23)
  }, [form?.taxs?.pph23])
  useEffect(()=> {
    setPPN(form?.taxs?.ppn)
  }, [form?.taxs?.ppn])

  useEffect(() => {
    const newForm = {...form};
    newForm.taxs.pph21 = PPH21;
    newForm.taxs.pph23 = PPH23;
    newForm.taxs.ppn = PPN;
    setForm(newForm);
    setTrigger(makeRandomString(5));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ PPH21, PPH23, PPN, setForm, setTrigger]);

  return (
    <div className={Styles.container}>
      <h3>Potongan Pajak</h3>
      {contractForm?.contract?.toLowerCase() === "jasa pemborongan" ? (
        <div className={Styles.infoPPN}>
          <div>
            <Icon icon={"information-solid"} size={16} color={"#FFAA00"} />
          </div>
          <span>
            PPN: Diambil {PPN?.toLowerCase() || "-"}{" "}
            <span>(Mengikuti jenis kontrak Pemborongan)</span>
          </span>
        </div>
      ) : null}
      <div className={Styles.content}>
        <div className={Styles.selectWrapper}>
          <SelectOptionsToggle
            title="PPH21"
            options={options?.pph21}
            placeholder="Select"
            value={PPH21}
            setValue={setPPH21}
            autoClose={true}
            isError={alertMessage && !PPH21}
            isErrorV2={true}
            errorText="PPH21 belum dipilih"
            required
          />
        </div>
        <div>
          <SelectOptionsToggle
            title="PPH23"
            options={options?.pph23}
            placeholder="Select"
            value={PPH23}
            setValue={setPPH23}
            autoClose={true}
            isError={alertMessage && !PPH23}
            isErrorV2={true}
            errorText="PPH23 belum dipilih"
            required
          />
        </div>
        {contractForm?.contract?.toLowerCase() === "jasa pemborongan" ? null : (
          <div className={Styles.selectWrapper}>
            <SelectOptionsToggle
              title="PPN"
              options={options?.ppn}
              placeholder="Select"
              value={PPN}
              setValue={setPPN}
              autoClose={true}
              isError={alertMessage && !PPN}
              isErrorV2={true}
              errorText="PPN belum dipilih"
              required
            />
          </div>
        )}
      </div>
    </div>
  );
}
