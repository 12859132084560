/* eslint-disable no-unused-vars */
/*eslint-disable no-useless-escape */

import Icon from "@Atom/Icon";
import InputField from "@Molecule/InputField";
import RightDecorativeInputField from "@Molecule/RightDecorativeInputField";
import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

export default function ThirdSubStep(props) {
  const {
    steps,
    handleShowAddQualifications,
    handleShowAddQualificationsMobile,
    locationIndex,
    manPowerIndex,
    form,
    setForm,
    trigger,
    setTrigger,
    onPrevious,
    debounceTrigger,
  } = props;

  const [qualifications, setQualifications] = useState(
    form[locationIndex]?.jobs[manPowerIndex]?.qualifications
  );

  useEffect(() => {
    setQualifications(form[locationIndex]?.jobs[manPowerIndex]?.qualifications);

    // eslint-disable-next-line
  }, [form[locationIndex]?.jobs[manPowerIndex]?.qualifications]);

  // height
  const heightIndex = useMemo(() => {
    const index = qualifications.findIndex((obj) => {
      return obj.name === "Tinggi Badan";
    });

    return index;
  }, [qualifications]);

  const [height, setHeight] = useState(
    qualifications[heightIndex]?.value || ""
  );

  // weight
  const weightIndex = useMemo(() => {
    const index = qualifications.findIndex((obj) => {
      return obj.name === "Berat Badan";
    });

    return index;
  }, [qualifications]);
  const [weight, setWeight] = useState(
    qualifications[weightIndex]?.value || ""
  );

  // maxAge
  const maxAgeIndex = useMemo(() => {
    const index = qualifications.findIndex((obj) => {
      return obj.name === "Usia Maksimal";
    });

    return index;
  }, [qualifications]);

  const [maxAge, setMaxAge] = useState(
    qualifications[maxAgeIndex]?.value || ""
  );

  // minAge
  const minAgeIndex = useMemo(() => {
    const index = qualifications.findIndex((obj) => {
      return obj.name === "Usia Minimum";
    });

    return index;
  }, [qualifications]);
  const [minAge, setMinAge] = useState(
    qualifications[minAgeIndex]?.value || ""
  );

  // lastEducation
  const lastEducationIndex = useMemo(() => {
    const index = qualifications.findIndex((obj) => {
      return obj.name === "Pendidikan Terakhir";
    });

    return index;
  }, [qualifications]);
  const [lastEducation, setLastEducation] = useState(
    qualifications[lastEducationIndex]?.value || ""
  );

  // minExperience
  const minExperienceIndex = useMemo(() => {
    const index = qualifications.findIndex((obj) => {
      return obj.name === "Minimal Pengalaman";
    });

    return index;
  }, [qualifications]);
  const [minExperience, setMinExperience] = useState(
    qualifications[minExperienceIndex]?.value || ""
  );

  // other
  const otherIndex = useMemo(() => {
    const index = qualifications.findIndex((obj) => {
      return obj.name === "Lainnya";
    });

    return index;
  }, [qualifications]);
  const [other, setOther] = useState(qualifications[otherIndex]?.value || "");

  // age
  const ageIndex = useMemo(() => {
    const index = qualifications.findIndex((obj) => {
      return obj.name === "Umur";
    });

    return index;
  }, [qualifications]);

  const [age, setAge] = useState(qualifications[ageIndex]?.value || "");

  useEffect(() => {
    form[locationIndex].jobs[manPowerIndex].qualifications = qualifications;
  }, [form, locationIndex, manPowerIndex, qualifications, setForm]);

  const onDeleteQualification = (type, name) => {
    const data = [...qualifications];
    const filtered = data.filter((el) => el.name !== name);
    setQualifications(filtered);
    setTrigger(Math.random());
  };

  useEffect(() => {
    if (heightIndex !== -1) {
      qualifications[heightIndex].value = height;
    }
    if (weightIndex !== -1) {
      qualifications[weightIndex].value = weight;
    }
    if (maxAgeIndex !== -1) {
      qualifications[maxAgeIndex].value = maxAge;
    }
    if (minAgeIndex !== -1) {
      qualifications[minAgeIndex].value = minAge;
    }
    if (lastEducationIndex !== -1) {
      qualifications[lastEducationIndex].value = lastEducation;
    }
    if (minExperienceIndex !== -1) {
      qualifications[minExperienceIndex].value = minExperience;
    }
    if (otherIndex !== -1) {
      qualifications[otherIndex].value = other;
    }
    if (ageIndex !== -1) {
      qualifications[ageIndex].value = age;
    }
  }, [
    qualifications,
    locationIndex,
    manPowerIndex,
    heightIndex,
    height,
    weightIndex,
    weight,
    maxAgeIndex,
    maxAge,
    minAgeIndex,
    minAge,
    lastEducationIndex,
    lastEducation,
    minExperienceIndex,
    minExperience,
    otherIndex,
    other,
    ageIndex,
    age,
    // trigger
  ]);

  useEffect(() => {}, [
    trigger,
    form,
    locationIndex,
    manPowerIndex,
    heightIndex,
    otherIndex,
  ]);

  const isDone = useMemo(() => {
    let temp = false;
    if (
      heightIndex !== -1 ||
      weightIndex !== -1 ||
      maxAgeIndex !== -1 ||
      minAgeIndex !== -1 ||
      lastEducationIndex !== -1 ||
      minExperienceIndex !== -1 ||
      otherIndex !== -1 ||
      ageIndex !== -1
    ) {
      if (heightIndex !== -1) {
        if (height) {
          temp = true;
        } else {
          return false;
        }
      }
      if (weightIndex !== -1) {
        if (weight) {
          temp = true;
        } else {
          return false;
        }
      }
      if (maxAgeIndex !== -1) {
        if (maxAge) {
          temp = true;
        } else {
          return false;
        }
      }
      if (minAgeIndex !== -1) {
        if (minAge) {
          temp = true;
        } else {
          return false;
        }
      }
      if (lastEducationIndex !== -1) {
        if (lastEducation) {
          temp = true;
        } else {
          return false;
        }
      }
      if (minExperienceIndex !== -1) {
        if (minExperience) {
          temp = true;
        } else {
          return false;
        }
      }
      if (otherIndex !== -1) {
        if (other) {
          temp = true;
        } else {
          return false;
        }
      }
      if (ageIndex !== -1) {
        if (age) {
          temp = true;
        } else {
          return false;
        }
      }
    }

    return temp;
  }, [
    heightIndex,
    height,
    weightIndex,
    weight,
    maxAgeIndex,
    maxAge,
    minAgeIndex,
    minAge,
    lastEducationIndex,
    lastEducation,
    minExperienceIndex,
    minExperience,
    otherIndex,
    other,
    ageIndex,
    age,
  ]);

  useEffect(() => {
    const newSteps = [...steps];
    if (!isDone) {
      newSteps[2].isDone = false;
    } else {
      newSteps[2].isDone = true;
    }
    setTrigger(Math.random());

    // eslint-disable-next-line
  }, [steps, isDone]);

  useEffect(() => {
    debounceTrigger();
  }, [
    qualifications,
    debounceTrigger,
    heightIndex,
    height,
    weightIndex,
    weight,
    maxAgeIndex,
    maxAge,
    minAgeIndex,
    minAge,
    lastEducationIndex,
    lastEducation,
    minExperienceIndex,
    minExperience,
    otherIndex,
    other,
    ageIndex,
    age,
  ]);

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <div className={Styles.topWrapper}>
          <h4>Job Qualification<span className={Styles.asterisk}>&#x2a;</span></h4>
          <button
            className={Styles.desktop}
            onClick={() =>
              handleShowAddQualifications(locationIndex, manPowerIndex)
            }
          >
            <Icon icon="circle-plus" size={18} />
            <span>Qualification</span>
          </button>
          <button
            className={Styles.mobile}
            onClick={() =>
              handleShowAddQualificationsMobile(locationIndex, manPowerIndex)
            }
          >
            <Icon icon="circle-plus" size={18} />
            <span>Qualification</span>
          </button>
        </div>
        <div className={Styles.contentWrapper}>
          {qualifications
            ?.filter((option) => option?.name !== "Lainnya")
            ?.map((each, i) => {
              return (
                <div key={i} className={Styles.each}>
                  {each?.name === "Umur" && (
                    <RightDecorativeInputField
                      deleteOption={true}
                      onDelete={() => onDeleteQualification("age", each?.name)}
                      title={each?.name}
                      placeholder="0"
                      value={age}
                      setValue={setAge}
                      adornment={each?.denom || "Tahun"}
                      type="number"
                    />
                  )}
                  {each?.name === "Tinggi Badan" && (
                    <RightDecorativeInputField
                      deleteOption={true}
                      onDelete={() =>
                        onDeleteQualification("height", each?.name)
                      }
                      title={each?.name}
                      placeholder="0"
                      value={height}
                      setValue={setHeight}
                      adornment={each?.denom || "cm"}
                      type="number"
                    />
                  )}
                  {each?.name === "Berat Badan" && (
                    <RightDecorativeInputField
                      deleteOption={true}
                      onDelete={() =>
                        onDeleteQualification("weight", each?.name)
                      }
                      title={each?.name}
                      placeholder="0"
                      value={weight}
                      setValue={setWeight}
                      adornment={each?.denom || "kg"}
                      type="number"
                    />
                  )}
                  {each?.name === "Usia Maksimal" && (
                    <RightDecorativeInputField
                      deleteOption={true}
                      onDelete={() =>
                        onDeleteQualification("maxAge", each?.name)
                      }
                      title={each?.name}
                      placeholder="0"
                      value={maxAge}
                      setValue={setMaxAge}
                      adornment={each?.denom || "Tahun"}
                      type="number"
                    />
                  )}
                  {each?.name === "Usia Minimum" && (
                    <RightDecorativeInputField
                      deleteOption={true}
                      onDelete={() =>
                        onDeleteQualification("minAge", each?.name)
                      }
                      title={each?.name}
                      placeholder="0"
                      value={minAge}
                      setValue={setMinAge}
                      adornment={each?.denom || "Tahun"}
                      type="number"
                    />
                  )}
                  {each?.name === "Pendidikan Terakhir" && (
                    <InputField
                      deleteOption={true}
                      onDelete={() =>
                        onDeleteQualification("lastEducation", each?.name)
                      }
                      title={each?.name}
                      placeholder="Pendidikan Terakhir"
                      value={lastEducation}
                      onChange={(e) => setLastEducation(e.target.value)}
                    />
                  )}
                  {each?.name === "Minimal Pengalaman" && (
                    <RightDecorativeInputField
                      deleteOption={true}
                      onDelete={() =>
                        onDeleteQualification("minExp", each?.name)
                      }
                      title={each?.name}
                      placeholder="0"
                      value={minExperience}
                      setValue={setMinExperience}
                      adornment={each?.denom || "Tahun"}
                      type="number"
                    />
                  )}
                </div>
              );
            })}
          {qualifications
            ?.filter((option) => option?.name === "Lainnya")
            .map((each, i) => {
              return (
                <div key={i} className={Styles.each}>
                  {each?.name === "Lainnya" && (
                    <InputField
                      deleteOption={true}
                      onDelete={() =>
                        onDeleteQualification("others", each?.name)
                      }
                      type="desc"
                      title={each?.name}
                      placeholder="Deskripsikan"
                      value={other}
                      onChange={(e) =>
                        setOther(
                          e.target.value.replace(
                            /[^0-9/\[\]\\_+,.@!`~#%$*&()={}|:;"'<,>?\-\n a-zA-Z]+/gi,
                            ""
                          )
                        )
                      }
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className={Styles.buttonWrapper}>
        <button onClick={onPrevious}>
          <Icon icon="arrow-left" size={18} />
          <span>Back</span>
        </button>
      </div>
    </div>
  );
}
