import Styles from "./style.module.scss";

export default function TableEmptyHandler({
  title = "Data Kosong",
  desc = "Tidak ada data atau data belum ada",
  isV2 = false,
}) {
  return (
    <div className={`${Styles.container} ${isV2 && Styles.height}`}>
      <span>{title}</span>
      <span>{desc}</span>
    </div>
  );
}
