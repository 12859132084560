import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import { useNavigate } from 'react-router-dom'

export default function BSProfilePopup({
  profilePicture,
  username,
  role
}) {
  const navigate = useNavigate()

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <svg xmlns="http://www.w3.org/2000/svg" width="248" height="107" viewBox="0 0 248 107" fill="none">
          <g clip-path="url(#clip0_2879_10200)">
            <path d="M0 16C0 7.16345 7.16344 0 16 0H232C240.837 0 248 7.16344 248 16V107H0V16Z" fill="url(#paint0_linear_2879_10200)" />
            <rect width="248" height="176" fill="url(#paint1_linear_2879_10200)" />
          </g>
          <defs>
            <linearGradient id="paint0_linear_2879_10200" x1="-5" y1="-4.4529e-06" x2="257.207" y2="102.129" gradientUnits="userSpaceOnUse">
              <stop stop-color="#1571DE" />
              <stop offset="1" stop-color="#1571DE" stopOpacity="0.81" />
            </linearGradient>
            <linearGradient id="paint1_linear_2879_10200" x1="124" y1="0" x2="124" y2="176" gradientUnits="userSpaceOnUse">
              <stop stop-color="#00186C" />
              <stop offset="1" stop-color="white" stopOpacity="0" />
            </linearGradient>
            <clipPath id="clip0_2879_10200">
              <path d="M0 16C0 7.16345 7.16344 0 16 0H232C240.837 0 248 7.16344 248 16V107H0V16Z" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className={Styles.profilePicture}>
        <img src={profilePicture} alt='' />
      </div>
      <h3>{username}</h3>
      <span>{role}</span>
      <button onClick={() => {
        sessionStorage.clear()
        localStorage.clear()
        navigate('/sign-in')
      }}>
        <span>Log Out</span>
        <Icon icon={'logout-outline'} size={20} />
      </button>
    </div>
  )
}