import Styles from "./style.module.scss";

export default function ViewDetailModal({ data, setData }) {
  return (
    <div className={Styles.container}>
      <div className={Styles.topLine} />
      <div className={Styles.header}>
        <h3>Candidate Detail</h3>
      </div>
      <div className={Styles.infoWrapper}>
        <div className={Styles.stackedInfo}>
          <h4>Nama</h4>
          <p>{data?.name}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Email</h4>
          <p>{data?.email || "no email"}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Nomor Handphone</h4>
          <p>{data?.phoneNumber}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Domisili</h4>
          <p>{data?.domicile || '-'}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Alamat</h4>
          <p>{data?.address || '-'}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Pendidikan</h4>
          <p>{data?.lastEducation || '-'}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Pengalaman Terakhir</h4>
          <p>{data?.lastPosition || '-'}</p>
        </div>
      </div>
    </div>
  );
}
