import FilterJobSeekerList from "@Molecule/FilterJobSeekerList";
import Styles from "./styles.module.scss";
import JobSeekersShowcase from "@Molecule/_jobSeekers/JobSeekersShowcase";
import { useEffect, useState } from "react";

export default function TeamporaryHome({ options, showFilter, setShowFilter }) {
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    if (options === null) {
      setIsloading(true);
    } else {
      setIsloading(false);
    }
  }, [options]);
  return (
    <div className={Styles.containerTemporary}>
      <div className={Styles.showcaseWrapper}>
        <JobSeekersShowcase />
      </div>
      <div className={Styles.mobileText}>
        <p>Temukan pekerjaan yang relevan dan mulai bergabung dengan kami</p>
        <p>
          Kami memiliki lebih banyak lowongan pekerjaan untuk anda, mulailah
          temukan dan bangun karir anda bersama kami, karena kami memiliki klien
          korporat yang tersebar di seluruh Indonesia.
        </p>
      </div>
      <div className={Styles.search}>
        <FilterJobSeekerList
          options={options?.cities || []}
          isLoading={isLoading}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      </div>
    </div>
  );
}
