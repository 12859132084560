import Styles from "./style.module.scss";

export default function ViewDetailModal({ data, setData }) {
  let IDR = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });
  return (
    <div className={Styles.container}>
      <div className={Styles.topLine} />
      <div className={Styles.header}>
        <h3>Cost Estimation Detail</h3>
      </div>
      <div className={Styles.infoWrapper}>
        <div className={Styles.stackedInfo}>
          <h4>Man power</h4>
          <p>{data?.name}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Total</h4>
          <p>{data?.amount || 0}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Salary</h4>
          <p>{IDR.format(data?.salary || 0)}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Personal work equipment</h4>
          <p>{IDR.format(data?.personalEquipmentCost || 0)}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Allowance</h4>
          <p>{IDR.format(data?.allowances || 0)}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>BPJS</h4>
          <p>{IDR.format(data?.bpjs || 0)}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Total</h4>
          <p>{IDR.format(data?.totalCosts || 0)}</p>
        </div>
      </div>
    </div>
  );
}
