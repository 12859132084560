import Button from "@Atom/Button";
import Images from "@Theme/Images";
import QRCode from "react-qr-code";
import Styles from "./style.module.scss";

export default function JobSeekersMobileAppAd() {
  return (
    <div className={Styles.container}>
      <div className={Styles.topSection}>
        <div className={Styles.sectionWrapper}>
          <div className={Styles.titleWrapper}>
            <div className={Styles.titleHead}>
              <div className={Styles.line} />
              <p>Aplikasi pencari kerja terbaik</p>
              <div className={Styles.line} />
            </div>
            <div className={Styles.tagLine}>
              <p>
                Cara Baru Menemukan <span>Pekerjaan</span>
              </p>
              <p>Untuk Karirmu</p>
            </div>
          </div>
          <div className={Styles.mobileAppWrapper}>
            <img src={Images.MOBILE_APP_SCREEN_1} alt="phone-1" />
            <img src={Images.MOBILE_APP_SCREEN_2} alt="phone-2" />
            <img src={Images.MOBILE_APP_SCREEN_3} alt="phone-3" />
          </div>
        </div>
      </div>
      <div className={Styles.bottomSection}>
        <div className={Styles.textWrapper}>
          <p>
            Download Aplikasi <span>{"Kerja 365\nLoker"}</span> Sekarang
          </p>
          <p>
            Kami mendukung multi platform seperti Android dan IOS untuk
            memudahkan para pencari kerja
          </p>
        </div>
        <div className={Styles.cardsWrapper}>
          <div className={Styles.cardContainer}>
            <div className={Styles.cardHead}>
              <div className={Styles.cardTitle}>
                <p>Untuk IOS</p>
                <p>IOS 13.0+</p>
              </div>
              <img src={Images.APPLE_WHITE_ICON} alt="apple" />
            </div>
            <div className={Styles.cardBody}>
              <div className={Styles.qrWrapper}>
                <QRCode
                  style={{
                    height: 76,
                    width: 76,
                    maxWidth: "100%",
                  }}
                  value={
                    "https://apps.apple.com/id/app/kerja365-loker/id6469481475"
                  }
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
            <a
              className={Styles.cardAction}
              href="https://apps.apple.com/id/app/kerja365-loker/id6469481475"
              target="_blank"
              rel="noreferrer"
            >
              <Button title={"Download Sekarang"} />
            </a>
          </div>
          <div className={Styles.cardContainer}>
            <div className={Styles.cardHead}>
              <div className={Styles.cardTitle}>
                <p>Untuk Android</p>
                <p>Android 8.0+</p>
              </div>
              <img src={Images.ANDROID_WHITE_ICON} alt="android" />
            </div>
            <div className={Styles.cardBody}>
              <div className={Styles.qrWrapper}>
                <QRCode
                  style={{
                    height: 76,
                    width: 76,
                    maxWidth: "100%",
                  }}
                  value={
                    "https://play.google.com/store/apps/details?id=com.personal365"
                  }
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
            <a
              href="https://play.google.com/store/apps/details?id=com.personal365"
              target="_blank"
              className={Styles.cardAction}
              rel="noreferrer"
            >
              <Button title={"Download Sekarang"} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
