import PerArea from "./PerArea";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";

export default function CostEstimationSection({ data }) {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Cost Estimation</h3>
      </div>
      <div className={Styles.content}>
        <div className={Styles.areas}>
          {data?.costEstimation?.areaCosts?.map((each, i) => (
            <PerArea key={i} data={each} manfee={data?.order?.manfee} ppn={data?.order?.orderComprehensives?.PPN?.toLowerCase()} pph21={data?.order?.orderComprehensives?.PPH21?.toLowerCase()}  />))}
        </div>
        <div className={Styles.TOP}>
          <img src={Images?.PAYDAY} alt="top" />
          <span>Term Of Payment</span>
          <span>
            TOP {data?.order?.termOfPayment}{" "}
            {data?.order?.termOfPayment > 1 ? "Days" : "Day"}{" "}
          </span>
        </div>
      </div>
    </div>
  );
}
