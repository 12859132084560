import CustomTable from '@Molecule/CustomTable'
import { useMemo } from 'react'
import { Grid, InfoCard, Section } from '../Second'
import Styles from './style.module.scss'

export default function ThirdSection({
  additionalForm,
  contactInformation
}) {

  const template = useMemo(() => {
    const columns = [
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'workUnit',
        label: 'Unit Kerja',
      },
      {
        name: 'jobTitle',
        label: 'Position',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'phoneNumber',
        label: 'Phone',
      },
  
  
    ]

    return [
      {
        title: 'Contact Information',
        component: (
          <div className={Styles.contentTable}>
            <CustomTable
              noPagination
              data={contactInformation}
              columns={columns}
              oddRowClassName={Styles.odd}
              minHeight="250px"
            />
          </div>)
      },
      {
        title: 'Flow Invoice',
        component: (
          <Grid
            gridTemplateColumns='1fr'
          >
            <Grid
              gridTemplateColumns='repeat(auto-fit, minmax(250px, 1fr))'
            >
              <InfoCard
                title='Tgl diterima data'
                description={additionalForm?.invoiceFlow?.dateOfReceiptOfDat}
                backgroundColor='#F5F5F5'
              />
              <InfoCard
                title='Tgl pengajuan soft copy invoice'
                description={additionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy}
                backgroundColor='#F5F5F5'
              />
              <InfoCard
                title='Tgl hard copy invoice diterima'
                description={additionalForm?.invoiceFlow?.dateOfFillingOfHardCopy}
                backgroundColor='#F5F5F5'
              />
              <InfoCard
                title='Tgl mulai penggajian'
                description={additionalForm?.invoiceFlow?.payrollDate}
                backgroundColor='#F5F5F5'
              />
            </Grid>
            <Grid
              gridTemplateColumns='1fr 2.05fr 1fr'
              isMobileHalf
            >
              <InfoCard
                title='Tgl diterima data'
                description={additionalForm?.invoiceFlow?.dateOfReceiptOfData}
                backgroundColor='#F5F5F5'
              />
              <InfoCard
                title='Tgl pengajuan soft copy invoice'
                description={additionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy}
                backgroundColor='#F5F5F5'
              />

            </Grid>
          </Grid>
        )
      },
      {
        title: 'Rekening Penerima Invoice',
        component: (
          <Grid
            gridTemplateColumns='repeat(auto-fit, minmax(500px, 1fr))'
          >
            <InfoCard
              title='Perusahaan penerima'
              description={additionalForm?.recipientsAccount?.bankRecipien}
              backgroundColor='#F5F5F5'
            />
            <InfoCard
              title='Bank'
              description={additionalForm?.recipientsAccount?.bankName}
              backgroundColor='#F5F5F5'
            />
            <InfoCard
              title='Nomor rekening'
              description={additionalForm?.recipientsAccount?.bankNumber}
              backgroundColor='#F5F5F5'
            />

          </Grid>
        )
      },
      {
        title: 'Others',
        component: (
          <Grid
            gridTemplateColumns='1fr'
          >
            <InfoCard
              title='Pendapatan dipotong bila'
              description={additionalForm?.others?.deductedAttendance}
              backgroundColor='#F5F5F5'
            />
            <InfoCard
              title='Keterangan lainnya'
              description={additionalForm?.others?.others}
              backgroundColor='#F5F5F5'
            />

          </Grid>
        )
      },

    ]
  }, [additionalForm?.invoiceFlow?.dateOfFillingOfHardCopy, additionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy, additionalForm?.invoiceFlow?.dateOfReceiptOfDat, additionalForm?.invoiceFlow?.dateOfReceiptOfData, additionalForm?.invoiceFlow?.payrollDate, additionalForm?.others?.deductedAttendance, additionalForm?.others?.others, additionalForm?.recipientsAccount?.bankName, additionalForm?.recipientsAccount?.bankNumber, additionalForm?.recipientsAccount?.bankRecipien, contactInformation])

  return (
    <div className={Styles.container}>
      {
        template
          ?.map((t, tI) => (
            <Section
              key={tI}
              title={t?.title}
              component={t?.component}
            />
          ))
      }
    </div>
  )
}