/* eslint-disable no-unused-vars */

import Images from "@Theme/Images";
import Styles from "./style.module.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Icon from "@Atom/Icon";
import { useDropzone } from "react-dropzone";
import useWindowSize from "@Hooks/useWindowSize";
import ModalWrapper from "@Atom/ModalWrapper";
import FileUploadModal from "@Molecule/_modal/FileUpload";

const PDFJS = require("pdfjs-dist/webpack");

export default function SixthStep({
  form,
  setForm,
  isDoneCompleting,
  setIsDoneCompleting,
  handleSend,
  errorField
}) {
  switch (isDoneCompleting) {
    case false:
      return <OnProgress form={form} setForm={setForm} errorField={errorField} />;
    case true:
      return (
        <Completed
          handleSend={handleSend}
          setIsDoneCompleting={setIsDoneCompleting}
        />
      );
    default:
      return <OnProgress form={form} setForm={setForm} errorField={errorField}/>;
  }
}

const Completed = ({ handleSend, setIsDoneCompleting }) => {
  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);
  return (
    <div>
      <div className={Styles.completed}>
        <div className={Styles.autoScroll} ref={targetElement} />
        <div className={Styles.desc}>
          <h3>Silahkan Submit Data Yang Telah Anda Isi</h3>
        </div>
        <img src={Images.COMPLETED} alt="banner" />
        <div className={Styles.desc}>
          <span>Kirim form anda untuk segera kami proses lebih lanjut</span>
        </div>
      </div>
      <div className={Styles.buttonWrapper}>
        <button onClick={() => setIsDoneCompleting(false)}>Kembali</button>
        <button onClick={handleSend}>Kirim Form</button>
      </div>
    </div>
  );
};

const OnProgress = ({ form, setForm, errorField }) => {
  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef();

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <h3 ref={headerRef}>Unggah Dokumen</h3>
      <div className={Styles.content}>
        <div
          style={{ position: "sticky", top: `${headerHeight}px` }}
          className={Styles.left}
        >
          <img
            src={Images?.DOCUMENT_BANNER}
            alt="banner"
            className={Styles.none}
          />
        </div>
        <div className={Styles.right}>
          <Section
            data={form?.mandatory}
            title={"Dokumen Wajib"}
            form={form}
            setForm={setForm}
            identifier={"mandatory"}
            errorField={errorField}
          />
          <Section
            data={form?.nonMandatory}
            title={"Dokumen Tidak Wajib"}
            form={form}
            setForm={setForm}
            identifier={"nonMandatory"}
          />
          <Section
            data={form?.additional}
            title={"Dokumen Tambahan"}
            form={form}
            setForm={setForm}
            identifier={"additional"}
          />
        </div>
      </div>
    </div>
  );
};

const Section = ({ data, title, form, setForm, identifier, errorField }) => {
  return (
    <div className={Styles.section}>
      <h5>{title}</h5>
      <div className={Styles.docs}>
        {data?.map((each, i) => (
          <DocCard
            key={i}
            title={each?.title}
            type={each?.fileType}
            file={each?.file}
            preview={each?.preview}
            name={each?.name}
            form={form}
            setForm={setForm}
            identifier={identifier}
            errorField={errorField}
          />
        ))}
        {identifier === "additional" && (
          <DocCard
            title={"Unggah Dokumen Tambahan"}
            type={""}
            // file={''}
            // preview={each?.preview}
            // name={each?.name}
            form={form}
            setForm={setForm}
            identifier={identifier}
            asButton
          />
        )}
      </div>
    </div>
  );
};

const DocCard = ({
  title,
  type,
  file,
  preview,
  name,
  form,
  setForm,
  identifier,
  asButton = false,
  errorField
}) => {
  const [doc, setDoc] = useState(file || null);
  const [previewTemp, setPreviewTemp] = useState(preview || null);
  const [isUploading, setIsUploading] = useState(false);
  // console.log(file);
  var index = useMemo(() => {
    return form[identifier].findIndex((x) => x?.title === title);
  }, [title, form, identifier]);
  // console.log(file);

  const loadImageFromPDF = useCallback(async (url) => {
    var pdf = await PDFJS.getDocument({ url: url }).promise;
    const canvas = document.createElement("canvas");
    const images = [];
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      images.push(canvas.toDataURL());
    }
    canvas.remove();
    setPreviewTemp(images[0]);
  }, []);

  useEffect(() => {
    if (doc) {
      const objectUrl = URL.createObjectURL(doc);
      if (type === "jpg") {
        setPreviewTemp(objectUrl);
      } else {
        if (doc?.type?.split("/").pop().toLowerCase() === "pdf") {
          loadImageFromPDF(objectUrl);
        } else {
          setPreviewTemp(objectUrl);
        }
      }

      // return () => URL.revokeObjectURL(objectUrl)
    }
  }, [doc, loadImageFromPDF, type]);
  // console.log(file);

  useEffect(() => {
    if (doc) {
      setForm({
        ...form,
        [identifier]: [
          ...form[identifier]?.slice(0, index),
          Object.assign({}, form[identifier][index], {
            file: doc,
            name: doc?.name,
            preview: previewTemp,
          }),
          ...form[identifier]?.slice(index + 1),
        ],
      });
    }

    // eslint-disable-next-line
  }, [doc, index, identifier, previewTemp]);

  const [errorFileReject, setErrorFileReject] = useState({});
  const onDrop = (acceptedFiles, fileRejections) => {
    if (acceptedFiles.length > 0) {
      setDoc(acceptedFiles[0]);
      setErrorFileReject({})
    }

    if (fileRejections?.length){
      fileRejections.forEach(file => {
        file.errors.forEach(err => {
          if (err.code === 'file-too-large') {
            setErrorFileReject({
              message: "File terlalu besar. Ukuran maksimal file adalah 5MB !"
            });
          }
        });
      });
    }
  };

  const onChangeResponsive = (e) => {
    const file = e.target.files[0];
    setDoc(file);
  };

  // const onChange = (e) => {
  //   if (e?.target?.input?.files?.length > 0) {
  //     setDoc(e?.target?.input?.files[0]);
  //   }
  // };

  console.log(doc);
  const { width } = useWindowSize();

  const template = useMemo(() => {
    switch (type) {
      case "pdf":
        return {
          placeholder: "Drag & drop file here (Pdf/Jpg/Png)",
          mobilePlaceholder: "Select file (Pdf/Jpg/Png)",
          icon: "pdf-type",
          acceptedFileType: {
            "application/pdf": [".pdf"],
            "image/jpeg": [".jpeg", ".png"],
          },
        };
      case "jpg":
        return {
          placeholder: "Drag & drop file here (Jpg/Png)",
          mobilePlaceholder: "Select file (Jpg/Png)",
          icon: "jpg-type",
          acceptedFileType: {
            "image/jpeg": [".jpeg", ".png"],
          },
        };
      default:
        return {
          placeholder: "Drag & drop file here",
          mobilePlaceholder: "Select file",
          icon: "upload",
          acceptedFileType: {
            "application/pdf": [".pdf"],
            "image/jpeg": [".jpeg", ".png"],
          },
        };
    }
  }, [type]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: template?.acceptedFileType,
    multiple: false,
    disabled: asButton,
    maxSize: 1048576 * 5
  });

  const inputFileRef = useRef();

  console.log(preview, "apa ini", name);

  const [showUpload, setShowUpload] = useState(false);

  return (
    <>
      {width <= 768 ? (
        <div
          className={`${Styles.card} ${errorField && !preview ? Styles.cardError : ""}`}
          onClick={() =>
            !asButton ? inputFileRef?.current?.click() : () => {}
          }
        >
          {title === "Foto Selfie" ? (
            <input
              accept="image/*"
              type="file"
              ref={inputFileRef}
              name="file"
              capture="camera"
              onChange={onChangeResponsive}
              hidden
            />
          ) : title === "CV" ? (
            <input
              type="file"
              ref={inputFileRef}
              name="file"
              onChange={onChangeResponsive}
              hidden
              accept=".pdf, .png, .jpeg, .jpg"
            />
          ) : (
            <input
              type="file"
              ref={inputFileRef}
              name="file"
              onChange={onChangeResponsive}
              hidden
              accept=".png, .jpeg, .jpg"
            />
          )}

          <div className={Styles.left}>
            {preview ? (
              <img src={preview} alt="preview" />
            ) : (
              <Icon icon={template?.icon} size={26} />
            )}
          </div>
          <div className={Styles.middle}>
            <p>{title}</p>
            <span className={Styles.desktop}>
              {name || template?.placeholder}
            </span>
            <span className={Styles.mobile}>
              {name || template?.mobilePlaceholder}
            </span>
          </div>
          <div className={Styles.right}>
            {preview || name ? (
              <Icon icon={"check-circle"} size={46} className={Styles.icon} />
            ) : (
              <>
                {title === "Foto Selfie" ? (
                  <button>
                    <Icon
                      icon={"camera"}
                      color={"#000"}
                      className={Styles.iconCamera}
                    />
                    Ambil Foto
                  </button>
                ) : (
                  <button
                    onClick={
                      asButton
                        ? () => {
                            setShowUpload(true);
                          }
                        : () => {}
                    }
                  >
                    Browse
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div {...getRootProps()} className={`${Styles.card} ${errorField && !preview ? Styles.cardError : ""}`}>
          <input
            {...getInputProps()}
            type="file"
            // ref={inputFileRef}
            name="file"
            // onChange={onChange}
            hidden
          />

          <div className={Styles.left}>
            {preview ? (
              <img src={preview} alt="preview" />
            ) : (
              <Icon icon={template?.icon} size={26} />
            )}
          </div>
          <div className={Styles.middle}>
            <p>{title}</p>
            <span className={Styles.desktop}>
              {name || template?.placeholder}
            </span>
            <span className={Styles.mobile}>
              {name || template?.mobilePlaceholder}
            </span>
            
          </div>
          <div className={Styles.right}>
            {preview || name ? (
              <Icon icon={"check-circle"} size={46} className={Styles.icon} />
            ) : (
              <>
                <button
                  onClick={
                    asButton
                      ? () => {
                          setShowUpload(true);
                        }
                      : () => {}
                  }
                >
                  Browse
                </button>
              </>
            )}
          </div>
        </div>
      )}
      {
        errorField && !preview && <span className={Styles.errorText}>{title || "File"}{" belum diisi"}</span>
      }
      {errorFileReject?.message ? (
        <span className={Styles.errorText}>*{errorFileReject?.message}</span>
      ) : null}

      {showUpload && (
        <FileUploadModal
          // type={width > 768 ? 'desktop' : 'mobile'}
          title={"Upload Dokumen"}
          setShow={setShowUpload}
          files={[]}
          setFiles={(newFiles) => {
            setForm((prev) => {
              return {
                ...prev,
                additional: [
                  ...prev?.additional,
                  ...newFiles?.map((obj) => {
                    return {
                      file: obj,
                      title: obj?.name,
                    };
                  }),
                ],
              };
            });
          }}
          isMultiple
        />
      )}
    </>
  );
};
