import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import { fileBaseUrl } from "@Config/api";
import { capitalizedFirstLetter } from "@Helpers/capitalizedFirstLetter";
import { makeRandomString } from "@Helpers/makeRandomString";
import UseOutsideClick from "@Hooks/useOutsideClick";
import useQuery from "@Hooks/useQuery";
import Pagination from "@Molecule/Pagination";
import DoneModal from "@Molecule/_modal/DoneModal";
import ResultModal from "@Molecule/_modal/ResultModal";
import CandidateDetailModal from "@Molecule/_recruitment/CandidateDetailModal";
import CandidateInformationModal from "@Molecule/_recruitment/CandidateInformationModal";
import { getCandidates, resendInvitation } from "@Services/officer/recruitment";
import Images from "@Theme/Images";
import styled from "@emotion/styled";
import {
  Avatar,
  InputBase,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import debounce from "lodash.debounce";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Button from "./button";
import Styles from "./style.module.scss";
import { statusOptions } from "@Molecule/_modal/ManPowerRequestDetailModal";

const OutlinedCustomInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    width: "100%",
    border: "1px solid #1571DE",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1571DE",
    // textAlign: 'center',
    paddingLeft: "16px",
    paddingBlock: "8px",

    "&:focus": {
      // border: 'none',
      // color: '#1571DE',
      boxShadow: "none",
    },
  },
});

export default function InvitationTable({
  searchTerm,
  // data,
  locationFilter,
  positionFilter,
  defaultCandidates,
  defaultTotalPage,
  checkedArr,
  setCheckedArr,
  trigger,
  setTrigger,
  invitationSyncData,
  selectedPosition,
  evaluationParameters,
  domicileSearch = "",
  type = "",
  typeSelectedPage,
  setTypeSelectedPage,
  typeLimit,
  setTypeLimit,
  typeTotalPage,
  typeData,
  onClickCandidate = () => {},
  positionIDFilter,
}) {
  const TABLE_HEAD = useMemo(() => {
    switch (type) {
      case "mp-request-details":
        return [
          { id: "name", label: "Nama Kandidat", align: "left" },
          { id: "email", label: "Email", align: "left" },
          { id: "phoneNumber", label: "Nomor Handphone", align: "left" },
          { id: "domicile", label: "Domisili", align: "left" },
          { id: "address", label: "Alamat", align: "left" },
          { id: "approvalLink", label: "Link Approval", align: "left" },
          // { id: "lastExperience", label: "Pengalaman Terakhir", align: "left" },
          { id: "status", label: "Status", align: "center" },
        ];
      default:
        return [
          { id: "name", label: "Nama Kandidat", align: "left" },
          { id: "position", label: "Posisi", align: "left" },
          { id: "portal", label: "Sumber", align: "left" },
          { id: "interviewDate", label: "Waktu Interview", align: "left" },
          { id: "interviewLink", label: "Undangan Interview", align: "left" },
          // { id: "location", label: "Lokasi", align: "left" },
          { id: "email", label: "Email", align: "left" },
          { id: "phoneNumber", label: "Nomor Handphone", align: "left" },
          { id: "domicile", label: "Domisili", align: "left" },
          { id: "address", label: "Alamat", align: "left" },
          { id: "education", label: "Pendidikan", align: "left" },
          // { id: "lastExperience", label: "Pengalaman Terakhir", align: "left" },
          { id: "remark", label: "Catatan Undangan", align: "left" },
          { id: "status", label: "Status", align: "center" },
        ];
    }
  }, [type]);
  const [value, setValue] = useState(5);
  const [data, setData] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const statusList = useMemo(() => {
    return statusOptions
      ?.map((t) => t?.subTypes)
      ?.flat()
      ?.map((sT) => sT?.options)
      ?.flat();
  }, []);

  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalPage, setTotalPage] = useState(defaultTotalPage);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(null);
      }, 5000);
    }
  }, [isSuccess]);

  // eslint-disable-next-line no-unused-vars
  const [expandStatus, setExpandStatus] = useState(false);
  const [status, setStatus] = useState("");
  const [expandResult, setExpandResult] = useState("");
  const [resultData, setResultData] = useState(null);

  const fixedStatus = useMemo(() => {
    if (status === "") {
      // return '&status=null&status=invited&status=accepted'
      return "";
    } else if (status === "Undang") {
      return "&tag=null";
    } else if (status === "Menunggu") {
      return "&tag=invited";
    } else if (status === "Ready") {
      return "&tag=accepted";
    }
  }, [status]);

  const handleSelectStatus = (e) => {
    setStatus(e.target.value);
  };

  const query = useQuery();
  const code = query.get("c");

  const [name, setName] = useState(searchTerm);

  const onChange = useCallback(() => {
    setName(searchTerm);
  }, [searchTerm]);

  const debounceChange = useMemo(() => debounce(onChange, 500), [onChange]);

  useEffect(() => {
    debounceChange();
  }, [debounceChange]);

  console.log(selectedPosition);

  useEffect(() => {
    if (typeData) {
      setData(typeData);
    } else {
      const queryParams = `limit=${value}&page=${selectedPage}${fixedStatus}&positionID=${selectedPosition?.positionID}&status=RO-INVITE&search=${name}&domicile=${domicileSearch}`;
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const { response } = await getCandidates(code, queryParams);
          setData(
            response?.data?.map((obj) => {
              const imgUrl = obj?.CandidateDocuments?.find(
                (obj) => obj?.type?.toLowerCase() === "self picture"
              )?.url;
              return {
                ...obj,
                profilePicture: imgUrl
                  ? fileBaseUrl + imgUrl
                  : Images.AVA_DEFAULT,
              };
            })
          );
          setIsLoading(false);

          // console.log(queryParams, response, "ress");
          setTotalPage(response?.totalPage);
        } catch (err) {
          console.log(err);
          setIsLoading(false);
        }
      };
      if (selectedPosition) {
        fetchData();
      }
    }
  }, [
    code,
    name,
    value,
    selectedPage,
    fixedStatus,
    trigger,
    selectedPosition?.position,
    selectedPosition,
    domicileSearch,
    typeData,
  ]);

  useEffect(() => {
    setSelectedPage(1);
  }, [searchTerm, trigger]);

  const [selectedCandidateDetail, setSelectedCandidateDetail] = useState(null);

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const viewType = useMemo(() => {
    if (windowSize[0] > 768) {
      return "desktop";
    } else if (windowSize[0] <= 768) {
      return "mobile";
    }
  }, [windowSize]);

  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [showCandidateInfo, setShowCandidateInfo] = useState(false);
  const [showCandidateInfoMobile, setShowCandidateInfoMobile] = useState(false);

  const [defaultExpandId, setDefaultExpandId] = useState(1);

  const handleViewInfo = (data) => {
    setDefaultExpandId(2);

    if (viewType === "desktop") {
      setSelectedCandidate(data);

      setShowCandidateInfo(true);
    } else {
      setSelectedCandidate(data);

      setShowCandidateInfoMobile(true);
    }
  };
  const handleCloseInfo = () => {
    setDefaultExpandId(1);
    if (showCandidateInfo) {
      setShowCandidateInfo(false);
    } else {
      setShowCandidateInfoMobile(false);
    }
    setSelectedCandidate(null);
  };

  const handleInvite = (selected) => {
    setDefaultExpandId(3);

    if (viewType === "desktop") {
      setSelectedCandidate(selected);

      setShowCandidateInfo(true);
    } else {
      setSelectedCandidate(selected);

      setShowCandidateInfoMobile(true);
    }
  };

  const handleResend = async (data) => {
    try {
      await resendInvitation(data?.CandidateInvitations?.id);
      setTrigger(makeRandomString(5));
      setIsSuccess(true);
    } catch (err) {
      console.log(err?.response?.data?.error, "error on resendInvitation");
    }
  };

  useEffect(() => {
    if (selectedCandidateDetail !== null) {
      if (boxOutsideClick === false) {
        setSelectedCandidateDetail(null);
      }
    }
    if (showCandidateInfoMobile) {
      if (boxOutsideClick === false) {
        setShowCandidateInfoMobile(false);
      }
    }
  }, [boxOutsideClick, selectedCandidateDetail, showCandidateInfoMobile]);

  const [resultMode, setResultMode] = useState("");

  const resultOptions = ["Lolos", "Tidak Lolos"];

  const resultRef = useRef();
  const backupRef = useRef();

  const outsideResultClick = UseOutsideClick(resultRef);

  useEffect(() => {
    if (expandResult && outsideResultClick) {
      setExpandResult("");
    }
  }, [expandResult, outsideResultClick]);

  const handleOpenInterviewInvitation = (link) => {
    window.open(link);
  };

  return (
    <div
      ref={expandResult ? backupRef : resultRef}
      className={Styles.tableWrapper}
    >
      <TableContainer
        className={`${Styles.tableContainer} ${
          data?.length ? Styles.grow : ""
        }`}
      >
        <Table className={Styles.table}>
          <TableHead className={Styles.tableHead}>
            <TableRow className={Styles.tableHeadRow}>
              {TABLE_HEAD?.map((eachH, iH) => (
                <TableCell
                  key={"headcCell" + iH}
                  align={eachH?.align}
                  padding="none"
                  className={`${Styles.tableHeadCell} ${
                    eachH?.id === "status" ? Styles.status : ""
                  } ${iH !== 0 ? Styles.hideOnMobile : ""}`}
                >
                  <div>
                    <>
                      <span>{eachH?.label}</span>
                    </>
                  </div>
                  {expandStatus && eachH?.id === "status" && (
                    <div className={Styles.statusOption}>option</div>
                  )}
                </TableCell>
              ))}
              {/* <TableCell className={` ${Styles.showOnMobile}`}>
                <div />
              </TableCell> */}
              <TableCell className={` ${Styles.showOnMobile}`}>
                <Select
                  style={{ width: "100%" }}
                  input={<OutlinedCustomInput />}
                  displayEmpty={true}
                  renderValue={(value) =>
                    value?.length
                      ? Array.isArray(value)
                        ? value.join(", ")
                        : value
                      : "Semua"
                  }
                  IconComponent={(props) => (
                    <Icon
                      {...props}
                      icon={"arrow-down"}
                      size={18}
                      color="#1571DE"
                    />
                  )}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        borderRadius: "8px",
                        "& .MuiMenuItem-root": {
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontWeight: 400,
                          color: "#404040",
                          // borderBottom: '1px solid #c2c2c2',
                          paddingInline: "12px",
                          paddingBlock: "0px",
                        },
                      },
                    },
                  }}
                  value={status}
                  onChange={handleSelectStatus}
                >
                  <MenuItem value={""}>
                    <em>Semua</em>
                  </MenuItem>
                  <MenuItem value={"Undang"}>Undang</MenuItem>
                  <MenuItem value={"Menunggu"}>Menunggu</MenuItem>
                  <MenuItem value={"Ready"}>Ready</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={Styles.tableBody}>
            {data?.map((eachD, iD) => {
              return type === "mp-request-details" ? (
                <TableRow key={`tableRow` + iD} className={Styles.tableRow}>
                  <TableCell
                    className={`${Styles.stickyLeft} ${Styles.tableCell}`}
                  >
                    <div>
                      <button
                        className={Styles.stackedProfile}
                        onClick={() => onClickCandidate(eachD)}
                      >
                        {eachD?.profilePicture ? (
                          <Avatar
                            alt={eachD?.name}
                            src={eachD?.profilePicture}
                            sx={{ width: 36, height: 36 }}
                          />
                        ) : (
                          <Avatar sx={{ width: 36, height: 36 }}>
                            {eachD?.name[0]}
                          </Avatar>
                        )}
                        <span>{eachD?.name?.toUpperCase()}</span>
                      </button>
                    </div>
                  </TableCell>

                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.email ? eachD?.email : "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.phoneNumber ? eachD?.phoneNumber : "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.domicile ? eachD?.domicile?.toUpperCase() : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.address ? eachD?.address?.toUpperCase() : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    {eachD?.contractApprovalLink ? (
                      <a
                        className={Styles.link}
                        href={eachD?.contractApprovalLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lihat Detail
                      </a>
                    ) : (
                      <span>-</span>
                    )}
                  </TableCell>

                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile} ${Styles.stickyRight} ${Styles.status}`}
                    align="center"
                    style={{
                      zIndex: eachD?.id === expandResult ? 1 : 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {/* <span>{statusList?.find(obj => obj?.value === eachD?.status)?.name}</span> */}
                    <span>{eachD?.status?.replaceAll("-", " ")}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.showOnMobile}`}
                  >
                    <button onClick={() => setSelectedCandidateDetail(eachD)}>
                      Detail
                    </button>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={`tableRow` + iD} className={Styles.tableRow}>
                  <TableCell
                    className={`${Styles.stickyLeft} ${Styles.tableCell}`}
                  >
                    <div>
                      {/* <Checkbox
                   onClick={() => handleCheck(eachD?.id)}
                   checked={isChecked || false}
                   sx={{ margin: 0, padding: 0 }}
                   icon={<Icon icon="unchecked-checkbox" size={23} />}
                   checkedIcon={<Icon icon="checked-checkbox" size={23} />}
                 /> */}
                      {/* <Check */}
                      <button
                        className={Styles.stackedProfile}
                        onClick={() => handleViewInfo(eachD)}
                      >
                        {eachD?.profilePicture ? (
                          <Avatar
                            alt={eachD?.name}
                            src={eachD?.profilePicture}
                            sx={{ width: 36, height: 36 }}
                          />
                        ) : (
                          <Avatar sx={{ width: 36, height: 36 }}>
                            {eachD?.name[0]}
                          </Avatar>
                        )}
                        <span>{eachD?.name?.toUpperCase()}</span>
                      </button>
                    </div>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.ExpectedPosition?.name?.toUpperCase()}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.portal?.toUpperCase()}</span>
                  </TableCell>

                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.CandidateInvitations?.date
                        ? moment(
                            new Date(eachD?.CandidateInvitations?.date)
                          ).format("Do MMMM YYYY")
                        : "-"}
                      {`, `}
                      {eachD?.CandidateInvitations?.time || "-"}
                    </span>
                  </TableCell>

                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    {eachD?.interviewLink ? (
                      <div
                        className={`${Styles.linkInterview} ${
                          eachD?.status.toLowerCase().includes("hold") &&
                          Styles.inactive
                        }`}
                        onClick={() => {
                          if (!eachD?.status.toLowerCase().includes("hold")) {
                            handleOpenInterviewInvitation(eachD?.interviewLink);
                          }
                        }}
                      >
                        Lihat Detail
                      </div>
                    ) : (
                      <div className={Styles.nullLink}>Belum Tersedia</div>
                    )}
                  </TableCell>

                  {/* <TableCell
               className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
             >
               <span>{eachD?.ExpectedPosition?.City?.name}</span>
             </TableCell> */}
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.email ? eachD?.email : "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.phoneNumber ? eachD?.phoneNumber : "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.domicile ? eachD?.domicile?.toUpperCase() : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.address ? eachD?.address?.toUpperCase() : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.lastEducation
                        ? eachD?.lastEducation?.toUpperCase()
                        : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.CandidateInvitations?.remark
                        ? eachD?.CandidateInvitations?.remark?.toUpperCase()
                        : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile} ${Styles.stickyRight} ${Styles.status}`}
                    align="center"
                    style={{
                      zIndex: eachD?.id === expandResult ? 1 : 0,
                    }}
                  >
                    {/* {eachD?.tag === null && (
                 <button
                   className={Styles.invite}
                   onClick={() => handleInvite(eachD)}
                 >
                   Undang
                 </button>
               )}
               {eachD?.tag?.toLowerCase() === "invited" && (
                 <button className={Styles.invited}>Undang</button>
               )} */}
                    {
                      (!eachD?.tag ||
                        eachD?.tag?.toLowerCase() === "invited") &&
                        // <div>
                        (!eachD?.status?.toLowerCase()?.includes("hold") &&
                        !eachD?.status
                          ?.toLowerCase()
                          ?.includes("contracted") ? (
                          <Button
                            recruiterId={eachD?.recruiterID}
                            tag={eachD?.tag}
                            onClick={(e) =>
                              eachD?.tag && eachD?.recruiterID
                                ? handleResend(eachD)
                                : // resendInvitation(e, eachD?.id)
                                  handleInvite(eachD)
                            }
                            updatedAt={eachD?.updatedAt}
                            isROInterview
                          />
                        ) : (
                          <span className={Styles.holdText}>
                            {capitalizedFirstLetter(
                              eachD?.status?.split("-")[
                                eachD?.status?.split("-")?.length - 1
                              ]
                            )}
                          </span>
                        ))
                      // </div>
                    }
                    {eachD?.tag?.toLowerCase() === "accepted" &&
                      !eachD?.status?.toLowerCase()?.includes("hold") &&
                      !eachD?.status?.toLowerCase()?.includes("contracted") &&
                      !eachD?.CandidateInvitations?.remark && (
                        <button
                          onClick={() => {
                            if (expandResult !== eachD?.id) {
                              setExpandResult(eachD?.id);
                              setResultData(eachD);
                            } else {
                              setExpandResult("");
                              setResultData(null);
                            }
                          }}
                          className={Styles.result}
                        >
                          <span>Result</span>
                          <Icon
                            icon={
                              expandResult === eachD?.id
                                ? "arrow-up"
                                : "arrow-down"
                            }
                            size={20}
                          />
                        </button>
                      )}
                    {eachD?.tag?.toLowerCase() === "accepted" &&
                      !eachD?.status?.toLowerCase()?.includes("hold") &&
                      !eachD?.status?.toLowerCase()?.includes("contracted") &&
                      eachD?.CandidateInvitations?.remark && (
                        <button
                          className={Styles.invite}
                          onClick={() => handleInvite(eachD)}
                        >
                          Reschedule
                        </button>
                      )}
                    {eachD?.tag?.toLowerCase() === "declined" &&
                      (!eachD?.status?.toLowerCase()?.includes("hold") &&
                      !eachD?.status?.toLowerCase()?.includes("contracted") ? (
                        // !eachD?.CandidateInvitations?.remark &&
                        <button className={Styles.rejected}>Tidak Hadir</button>
                      ) : (
                        <span className={Styles.holdText}>
                          {capitalizedFirstLetter(
                            eachD?.status?.split("-")[
                              eachD?.status?.split("-")?.length - 1
                            ]
                          )}
                        </span>
                      ))}
                    {eachD?.id === expandResult && (
                      <div ref={resultRef} className={Styles.resultPopup}>
                        {resultOptions?.map((obj, i) => (
                          <div
                            key={i}
                            onClick={() => {
                              setResultMode(
                                obj === "Lolos" ? "accept" : "reject"
                              );
                            }}
                          >
                            {obj}

                            {i === 0 && <div className={Styles.divider} />}
                          </div>
                        ))}
                      </div>
                    )}
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.showOnMobile}`}
                  >
                    <button onClick={() => setSelectedCandidateDetail(eachD)}>
                      Detail
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {!data?.length && (
        <div className={Styles.emptyWrapper}>
          <TableEmptyHandler
            title={isLoading ? "Tunggu sebentar" : "Data Kosong"}
            desc={
              isLoading
                ? "Data sedang dimuat"
                : "Tidak ada data atau data belum ada"
            }
          />
        </div>
      )}

      <div className={Styles.paginationWrapper}>
        <Pagination
          total={data?.length}
          totalPage={typeTotalPage ? typeTotalPage : totalPage}
          value={typeLimit ? typeLimit : value}
          setValue={setTypeLimit ? setTypeLimit : setValue}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={typeSelectedPage ? typeSelectedPage : selectedPage}
          setSelectedPage={
            setTypeSelectedPage ? setTypeSelectedPage : setSelectedPage
          }
        />
      </div>
      {showCandidateInfo && selectedCandidate && (
        <CandidateInformationModal
          defaultExpandId={defaultExpandId || 3}
          data={selectedCandidate}
          handleClose={handleCloseInfo}
          // inviteOption={!selectedCandidate?.status}
          inviteOption={
            !selectedCandidate?.tag ||
            (selectedCandidate?.tag?.toLowerCase() === "accepted" &&
              selectedCandidate?.CandidateInvitations?.remark)
          }
          type={"desktop"}
          invitationSyncData={invitationSyncData}
          setTrigger={setTrigger}
          setIsSuccess={setIsSuccess}
        />
      )}

      <div
        className={`${Styles.modalWrapper} ${
          selectedCandidateDetail ? Styles.showModal : Styles.hideModal
        }`}
      >
        <CandidateDetailModal
          data={selectedCandidateDetail}
          setData={setSelectedCandidateDetail}
          inviteData={{ ...selectedCandidateDetail }}
          inviteOption={
            !selectedCandidate?.tag ||
            (selectedCandidate?.tag?.toLowerCase() === "accepted" &&
              selectedCandidate?.CandidateInvitations?.remark)
          }
          // inviteOption
          handleInvite={handleInvite}
          handleResend={handleResend}
          setTrigger={setTrigger}
          evaluationParameters={evaluationParameters}
          type="ro"
        />
      </div>
      <div
        className={`${Styles.modalWrapper} ${
          showCandidateInfoMobile && selectedCandidate
            ? Styles.showModal
            : Styles.hideModal
        }`}
      >
        {showCandidateInfoMobile && selectedCandidate && (
          <CandidateInformationModal
            defaultExpandId={defaultExpandId}
            data={selectedCandidate}
            handleClose={handleCloseInfo}
            // inviteOption={!selectedCandidate?.status}
            inviteOption={
              !selectedCandidate?.tag ||
              (selectedCandidate?.tag?.toLowerCase() === "accepted" &&
                selectedCandidate?.CandidateInvitations?.remark)
            }
            type={"mobile"}
            invitationSyncData={invitationSyncData}
            setTrigger={setTrigger}
            setIsSuccess={setIsSuccess}
          />
        )}
      </div>
      <div
        ref={boxRef}
        className={`${Styles.overlay} ${
          selectedCandidateDetail || showCandidateInfoMobile
            ? Styles.visible
            : ""
        }`}
      />
      {isSuccess && (
        <DoneModal
          handleDone={() => setIsSuccess(false)}
          title={"Sent!"}
          description={"Invitation has been sent"}
        />
      )}
      <ModalWrapper show={resultMode ? true : false}>
        {resultMode && (
          <ResultModal
            mode={resultMode}
            data={resultData}
            onClose={() => setResultMode("")}
            setTrigger={setTrigger}
            defaultScores={evaluationParameters}
          />
        )}
      </ModalWrapper>
      {}
    </div>
  );
}
