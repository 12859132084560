import UseOutsideClick from "@Hooks/useOutsideClick";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SelectModal from "./SelectModal";
import Summary from "./Summary";
import ViewDetailModal from "./ViewDetailModal";
import Styles from "./styles.module.scss";

const TABLE_HEAD = [
  { id: "title", label: "Man power", align: "left", disablePadding: true },
  { id: "demand", label: "Total", align: "center", disablePadding: false },
  { id: "salary", label: "Salary", align: "right", disablePadding: false },
  {
    id: "personalEquipments",
    numeric: true,
    label: "Personal Work Equipment",
    align: "right",
    disablePadding: false,
  },
  {
    id: "allowance",
    numeric: true,
    label: "Allowance",
    align: "right",
    disablePadding: false,
  },
  {
    id: "bpjs",
    numeric: true,
    label: "BPJS",
    align: "right",
    disablePadding: false,
  },
  {
    id: "totalCost",
    numeric: true,
    label: "Total Cost",
    align: "right",
    disablePadding: true,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, firstColMinWidth } = props;

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#fff",
        },
      }}
    >
      <TableRow>
        {TABLE_HEAD.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.align ? headCell?.align : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={
              index === 0
                ? `${Styles.stickyLeft} ${Styles.tableHeadCell}`
                : index === TABLE_HEAD.length - 1
                ? `${Styles.stickyRight} ${Styles.HideOnMobile} ${Styles.tableHeadCell}`
                : `${Styles.HideOnMobile} ${Styles.tableHeadCell}`
            }
            sx={{ minWidth: index === 0 ? firstColMinWidth : "" }}
          >
            <span className={Styles.HeadcellLabel}>{headCell.label}</span>
          </TableCell>
        ))}
        <TableCell
          key={Math.random()}
          padding={"normal"}
          className={`${Styles.showOnMobile}`}
          align="right"
        >
          <span>Information</span>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function DataTable({
  locationName,
  each,
  form,
  getPersonalEquipmentsNominal,
  getAllowancesNominal,
  getCleanAllowance,
  getBpjsNominal,
  IDR,
  areaWorkEquipmentsNominal,
  manfee,
  handleClickSee,
  manfeeMode,
  manfeePerArea,
  manfeePercentage,
  getSubTotalPerArea,
  umkArr,
  setUmkArr,
  index,
  getTotalCostPerArea,
  costList,
  setCostList,
  generalCost,
  setGeneralCost,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");

  const umk = useMemo(() => {
    return each?.umk;
  }, [each]);

  const subTotalPerArea = getSubTotalPerArea(each, umk);

  const endTotalPerArea = getTotalCostPerArea(each, umk);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const tableMinWidth = useMemo(() => {
    let width;

    if (windowSize[0] < 768) {
      width = "100%";
    } else {
      width = "1000px";
    }
    return width;
  }, [windowSize]);

  const firstColMinWidth = useMemo(() => {
    let width;

    if (windowSize[0] < 768) {
      width = "100%";
    } else {
      width = "200px";
    }
    return width;
  }, [windowSize]);

  return (
    <div className={Styles.each}>
      <h4>{locationName}</h4>
      <div className={Styles.tableWrapper}>
        <TableContainer className={Styles.tableContainer}>
          <Table className={Styles.table} sx={{ minWidth: tableMinWidth }}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              values={form}
              firstColMinWidth={firstColMinWidth}
            />

            <TableBody>
              {stableSort(each?.jobs, getComparator(order, orderBy))?.map(
                (eachD, iD) => {
                  const personalEquipments = getPersonalEquipmentsNominal(
                    eachD?.personalEquipments
                  );
                  const allowances = getAllowancesNominal(eachD?.allowances);
                  const cleanAllowance = getCleanAllowance(eachD?.allowances);
                  const bpjsNominal = getBpjsNominal(
                    eachD?.salary,
                    umk,
                    cleanAllowance
                  );
                  const totalCost =
                    (Number(eachD?.salary) +
                      Number(personalEquipments) +
                      Number(cleanAllowance) +
                      Number(bpjsNominal)) *
                    eachD?.demand;
                  return (
                    <TableRow
                      key={iD}
                      className={`${Styles.tableRow} ${
                        iD === each?.jobs?.length - 1 && Styles.lastRow
                      }`}
                    >
                      <TableCell
                        padding="none"
                        className={`${Styles.tableCell} ${Styles.stickyLeft}`}
                      >
                        {eachD?.title}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${Styles.tableCell} ${Styles.HideOnMobile}`}
                      >
                        {eachD?.demand}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${Styles.tableCell} ${Styles.HideOnMobile}`}
                      >
                        {IDR.format(eachD?.salary)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${Styles.tableCell} ${Styles.HideOnMobile}`}
                      >
                        {IDR.format(personalEquipments)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${Styles.tableCell} ${Styles.HideOnMobile}`}
                      >
                        {IDR.format(allowances)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${Styles.tableCell} ${Styles.HideOnMobile}`}
                      >
                        {IDR.format(bpjsNominal)}
                      </TableCell>
                      <TableCell
                        align="right"
                        padding="none"
                        className={`${Styles.tableCell} ${Styles.HideOnMobile} ${Styles.stickyRight}`}
                      >
                        {IDR.format(totalCost)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${Styles.tableCell} ${Styles.showOnMobile}`}
                      >
                        <button
                          onClick={() =>
                            handleClickSee(
                              eachD,
                              personalEquipments,
                              allowances,
                              bpjsNominal,
                              totalCost,
                              areaWorkEquipmentsNominal,
                              manfee,
                              cleanAllowance,
                              eachD?.salary
                            )
                          }
                        >
                          See More
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={Styles.summary}>
        <div className={Styles.boxSum}>
          <span>Sub Total</span>
          <span>{IDR.format(subTotalPerArea)}</span>
        </div>
        <div className={Styles.boxSum}>
          <span>Area Work Equipment</span>
          <span>{IDR.format(areaWorkEquipmentsNominal)}</span>
        </div>
        <div className={Styles.boxSum}>
          <span>
            Management Fee {manfeeMode === "%" && `(${manfeePercentage}%)`}
          </span>
          <span>{IDR.format(manfeePerArea)}</span>
        </div>
        <div className={`${Styles.boxSum} ${Styles.last}`}>
          <span>Total Biaya</span>
          <span>{IDR.format(endTotalPerArea)}</span>
        </div>
      </div>
    </div>
  );
}

export default function PaymentSection({
  fixedManfee,
  fixedBpjsCut,
  manfee,
  form,
  workSchemeForm,
  cities,
  ppn
}) {
  const [manfeeMode, setManfeeMode] = useState("Rp");
  const [manfeePercentage, setManfeePercentage] = useState(
    Number(manfee.split("%")[0]) || 0
  );

  const [selectedManPower, setSelectedManPower] = useState(null);
  const [selectedAreaWorkEquipments, setSelectedAreaWorkEquipments] =
    useState(null);
  const [
    selectedPersonalWorkEquipmentNominal,
    setSelectedPersonalWorkEquipmenNominal,
  ] = useState(null);
  const [selectedAllowanceNominal, setSelectedAllowanceNominal] =
    useState(null);
  const [selectedBpjsNominal, setSelectedBpjsNominal] = useState(null);
  const [selectedTotalCost, setSelectedTotalCost] = useState(null);
  const [selectedManfee, setSelectedManfee] = useState(null);
  const [selectedCleanAllowance, setSelectedCleanAllowance] = useState(null);
  const [selectedSalary, setSelectedSalary] = useState(null);

  const [umkArr, setUmkArr] = useState(Array(form?.length).fill(""));
  const [costList, setCostList] = useState(Array(form?.length).fill(""));

  const handleClickSee = (
    data,
    pEquipments,
    allowance,
    bpjs,
    totalCost,
    aWEquipment,
    manFee,
    cleanAllowance,
    salary
  ) => {
    setSelectedManPower(data);
    setSelectedPersonalWorkEquipmenNominal(pEquipments);
    setSelectedAllowanceNominal(allowance);
    setSelectedBpjsNominal(bpjs);
    setSelectedTotalCost(totalCost);
    setSelectedAreaWorkEquipments(aWEquipment);
    setSelectedManfee(manFee);
    setSelectedCleanAllowance(cleanAllowance);
    setSelectedSalary(salary);
  };

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  const showThr = useMemo(() => {
    if (workSchemeForm?.others?.thr?.toLowerCase()?.includes("bulanan")) {
      return true;
    }
    return false;
  }, [workSchemeForm]);

  const showUak = useMemo(() => {
    if (workSchemeForm?.others?.uak?.toLowerCase()?.includes("bulanan")) {
      return true;
    }
    return false;
  }, [workSchemeForm]);

  useEffect(() => {
    if (selectedManPower !== null) {
      if (boxOutsideClick === false) {
        setSelectedManPower(null);
      }
    }
  }, [boxOutsideClick, selectedManPower]);

  const bpjsPercentage = useMemo(() => {
    let percentage = 0;

    if (
      workSchemeForm?.bpjs?.jkk &&
      workSchemeForm?.bpjs?.jkk?.includes("perusahaan")
    ) {
      const jkkPercentage = workSchemeForm?.bpjs?.jkk
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedJkkPercentage = jkkPercentage.replace(",", ".");
      percentage += Number(fixedJkkPercentage);
    }

    if (
      workSchemeForm?.bpjs?.jkm &&
      workSchemeForm?.bpjs?.jkm?.includes("perusahaan")
    ) {
      const jkmPercentage = workSchemeForm?.bpjs?.jkm
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedJkmPercentage = jkmPercentage.replace(",", ".");
      percentage += Number(fixedJkmPercentage);
    }

    if (
      workSchemeForm?.bpjs?.jht &&
      workSchemeForm?.bpjs?.jht?.includes("perusahaan")
    ) {
      const jhtPercentage = workSchemeForm?.bpjs?.jht
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedJhtPercentage = jhtPercentage.replace(",", ".");
      percentage += Number(fixedJhtPercentage);
    }

    if (
      workSchemeForm?.bpjs?.jp &&
      workSchemeForm?.bpjs?.jp?.includes("perusahaan")
    ) {
      const jpPercentage = workSchemeForm?.bpjs?.jp
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedJpPercentage = jpPercentage.replace(",", ".");
      percentage += Number(fixedJpPercentage);
    }

    if (
      workSchemeForm?.bpjs?.health &&
      workSchemeForm?.bpjs?.health?.includes("perusahaan")
    ) {
      const healthPercentage = workSchemeForm?.bpjs?.health
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedHealthPercentage = healthPercentage.replace(",", ".");
      percentage += Number(fixedHealthPercentage);
    }

    return percentage;
  }, [workSchemeForm]);

  const getLocationName = (locationCode) => {
    return cities?.find((c) => c?.code === locationCode)?.name;
  };

  const getTotalSalaryPerArea = (perLocationArr) => {
    let total = 0;
    const jobsArr = perLocationArr?.jobs;
    for (let i = 0; i < jobsArr?.length; i++) {
      const salary = jobsArr[i]?.salary;
      const demand = jobsArr[i]?.demand;
      total += Number(salary) * Number(demand);
    }

    return total;
  };

  const getTotalUmkPerArea = (perLocationArr, umk) => {
    let total = 0;
    const jobsArr = perLocationArr?.jobs;
    for (let i = 0; i < jobsArr?.length; i++) {
      const demand = jobsArr[i]?.demand;
      total += Number(umk) * Number(demand);
    }

    return total;
  };

  const getBpjsNominal = useCallback(
    (salary, umk, cleanAllowance) => {
      let nominal = 0;

      if (fixedBpjsCut?.includes("UMK")) {
        nominal = Math.ceil((bpjsPercentage * umk) / 100);
      } else if (fixedBpjsCut?.includes("+")) {
        nominal = Math.ceil(
          (bpjsPercentage * (Number(salary) + Number(cleanAllowance))) / 100
        );
      } else {
        nominal = Math.ceil((bpjsPercentage * salary) / 100);
      }

      return Math.ceil(nominal);
    },
    [bpjsPercentage, fixedBpjsCut]
  );

  const getPersonalEquipmentsNominal = (equipmentsArr) => {
    let total = 0;

    for (let i = 0; i < equipmentsArr?.length; i++) {
      total += Number(equipmentsArr[i]?.value);
    }

    return total;
  };

  const getPersonalEquipmentsNominalPerArea = useCallback((perLocationArr) => {
    let total = 0;
    const jobsArr = perLocationArr?.jobs;
    for (let i = 0; i < jobsArr?.length; i++) {
      const equipmentsArr = jobsArr[i]?.personalEquipments;
      const demand = jobsArr[i]?.demand;
      total +=
        Number(getPersonalEquipmentsNominal(equipmentsArr)) * Number(demand);
    }

    return total;
  }, []);

  const getAllowancesNominal = useCallback(
    (arr) => {
      let total = 0;

      for (let i = 0; i < arr?.length; i++) {
        const name = arr[i]?.name;
        const value = arr[i]?.value;

        if (!showThr && !showUak) {
          if (
            !name?.toLowerCase()?.includes("thr") &&
            !name?.toLowerCase()?.includes("uak")
          ) {
            total += Number(value);
          }
        } else if (!showThr) {
          if (!name?.toLowerCase()?.includes("thr")) {
            total += Number(value);
          }
        } else if (!showUak) {
          if (!name?.toLowerCase()?.includes("uak")) {
            total += Number(value);
          }
        } else {
          total += Number(arr[i]?.value);
        }
      }

      return total;
    },
    [showThr, showUak]
  );
  const getAllowancesNominalWithoutTHR = (arr) => {
    let total = 0;

    for (let i = 0; i < arr?.length; i++) {
      if (arr[i]?.name !== "THR" && arr[i]?.name !== "UAK") {
        total += Number(arr[i]?.value);
      }
    }

    return total;
  };

  // eslint-disable-next-line
  const getAllowancesNominalPerArea = useCallback(
    (perLocationArr) => {
      let total = 0;
      const jobsArr = perLocationArr?.jobs;
      for (let i = 0; i < jobsArr?.length; i++) {
        const allowanceArr = jobsArr[i]?.allowances;
        const demand = jobsArr[i]?.demand;
        total += Number(getAllowancesNominal(allowanceArr)) * Number(demand);
      }

      return total;
    },
    [getAllowancesNominal]
  );

  const getAllowancesNominalPerAreaWithoutTHR = useCallback(
    (perLocationArr) => {
      let total = 0;
      const jobsArr = perLocationArr?.jobs;
      for (let i = 0; i < jobsArr?.length; i++) {
        const allowanceArr = jobsArr[i]?.allowances;
        const demand = jobsArr[i]?.demand;
        total +=
          Number(getAllowancesNominalWithoutTHR(allowanceArr)) * Number(demand);
      }

      return total;
    },
    []
  );

  const getTotalBpjsNominalPerArea = useCallback(
    (perLocationArr, umk) => {
      const totalSalary = getTotalSalaryPerArea(perLocationArr);
      const totalUmk = getTotalUmkPerArea(perLocationArr, umk);
      const cleanAllowances =
        getAllowancesNominalPerAreaWithoutTHR(perLocationArr);

      return Number(getBpjsNominal(totalSalary, totalUmk, cleanAllowances));
    },
    [getAllowancesNominalPerAreaWithoutTHR, getBpjsNominal]
  );

  const getSubTotalPerArea = useCallback(
    (perLocationArr, umk) => {
      let subTotal = 0;

      const totalSalary = getTotalSalaryPerArea(perLocationArr);
      const totalPersonalWorkEquipment =
        getPersonalEquipmentsNominalPerArea(perLocationArr);
      const totalCleanAllowance =
        getAllowancesNominalPerAreaWithoutTHR(perLocationArr);
      const totalBpjs = getTotalBpjsNominalPerArea(perLocationArr, umk);

      subTotal +=
        Number(totalSalary) +
        Number(totalPersonalWorkEquipment) +
        Number(totalCleanAllowance) +
        Number(totalBpjs);

      return subTotal;
    },
    [
      getAllowancesNominalPerAreaWithoutTHR,
      getPersonalEquipmentsNominalPerArea,
      getTotalBpjsNominalPerArea,
    ]
  );

  const getEachManfeeGetter = (salary, pEquipments, bpjs, allowances) => {
    let manfee = 0;

    if (fixedManfee?.includes("BPJS")) {
      manfee += Math.ceil(Number(bpjs));
    }
    if (fixedManfee?.includes("Gaji")) {
      manfee += Math.ceil(Number(salary));
    }
    if (fixedManfee?.includes("Equipment")) {
      manfee += Math.ceil(Number(pEquipments));
    }
    if (fixedManfee?.includes("Tunjangan")) {
      manfee += Math.ceil(Number(allowances));
    }

    return manfee;
  };

  const getManfeeGetter = useCallback(
    (perLocationArr, umk) => {
      let manfee = 0;

      const totalSalary = getTotalSalaryPerArea(perLocationArr);
      const totalPersonalWorkEquipment =
        getPersonalEquipmentsNominalPerArea(perLocationArr);
      const totalBpjs = Math.ceil(
        getTotalBpjsNominalPerArea(perLocationArr, umk)
      );
      const totalAllowance =
        getAllowancesNominalPerAreaWithoutTHR(perLocationArr);

      if (fixedManfee?.includes("BPJS")) {
        manfee += Math.ceil(Number(totalBpjs));
      }
      if (fixedManfee?.includes("Gaji")) {
        manfee += Math.ceil(Number(totalSalary));
      }
      if (fixedManfee?.includes("Equipment")) {
        manfee += Math.ceil(Number(totalPersonalWorkEquipment));
      }
      if (fixedManfee?.includes("Tunjangan")) {
        manfee += Math.ceil(Number(totalAllowance));
      }

      return manfee;
    },
    [
      fixedManfee,
      getAllowancesNominalPerAreaWithoutTHR,
      getPersonalEquipmentsNominalPerArea,
      getTotalBpjsNominalPerArea,
    ]
  );

  const getAreaWorkEquipmentsNominal = (perLocationArr) => {
    let total = 0;

    const areaWorkEquipmentsArr = perLocationArr?.areaWorkEquipments;

    for (let i = 0; i < areaWorkEquipmentsArr?.length; i++) {
      total += Number(areaWorkEquipmentsArr[i]?.value);
    }

    return total;
  };

  const getTotalCostPerArea = useCallback(
    (perLocationArr, umk) => {
      let total = 0;
      const subTotal = getSubTotalPerArea(perLocationArr, umk);
      const areaWorkEquipmentsNominal =
        getAreaWorkEquipmentsNominal(perLocationArr);
      const manfeePerArea =
        manfeeMode === "Rp"
          ? manfee
          : Math.ceil(
              (manfeePercentage * getManfeeGetter(perLocationArr, umk)) / 100
            );

      total +=
        Number(subTotal) +
        Number(areaWorkEquipmentsNominal) +
        Number(manfeePerArea);

      return total;
    },
    [getManfeeGetter, getSubTotalPerArea, manfee, manfeeMode, manfeePercentage]
  );

  const getTotalManfeePerArea = useCallback(
    (perLocationArr, umk) => {
      let total = 0;
      const manfeePerArea =
        manfeeMode === "Rp"
          ? manfee
          : Math.ceil(
              (manfeePercentage * getManfeeGetter(perLocationArr, umk)) / 100
            );

      total += Number(manfeePerArea);

      return total;
    },
    [getManfeeGetter, manfee, manfeeMode, manfeePercentage]
  );

  // const [generalCost, setGeneralCost] = useState(0)

  const [showSelect, setShowSelect] = useState(false);

  useEffect(() => {
    if (showSelect !== false) {
      if (boxOutsideClick === false) {
        setShowSelect(false);
      }
    }
  }, [boxOutsideClick, showSelect]);

  let IDR = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  const isNoData = useMemo(() => {
    if (form?.length === 0) {
      return true;
    }
    return false;
  }, [form]);

  useEffect(() => {
    if (manfee.includes("%")) {
      setManfeeMode("%");
    } else {
      setManfeeMode("Rp");
    }
  }, [manfee]);

  useEffect(() => {
    if (manfeePercentage > 100) {
      setManfeePercentage(0);
    }
  }, [manfeePercentage]);

  const generalCost = useMemo(() => {
    let cost = 0;
    for (let i = 0; i < form.length; i++) {
      const perArea = getTotalCostPerArea(form[i], form[i]?.umk);
      cost += Math.ceil(Number(perArea));
    }

    return cost;
  }, [form, getTotalCostPerArea]);

  const TotalManfeeCost = useMemo(() => {
    let cost = 0;
    for (let i = 0; i < form.length; i++) {
      const perArea = getTotalManfeePerArea(form[i], form[i]?.umk);
      cost += Math.ceil(Number(perArea));
    }

    return cost;
  }, [form, getTotalManfeePerArea]);

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <div className={Styles.content}>
        {form?.map((each, i) => {
          const locationName = getLocationName(each?.location);
          const areaWorkEquipmentsNominal = getAreaWorkEquipmentsNominal(each);
          const manfeePerArea =
            manfeeMode === "Rp"
              ? manfee
              : Math.ceil(
                  (manfeePercentage * getManfeeGetter(each, each?.umk)) / 100
                );

          const endTotalPerArea = getTotalCostPerArea(each, umkArr[i]);
          return (
            <DataTable
              key={i}
              index={i}
              locationName={locationName}
              each={each}
              form={form}
              getPersonalEquipmentsNominal={getPersonalEquipmentsNominal}
              getAllowancesNominal={getAllowancesNominal}
              getCleanAllowance={getAllowancesNominalWithoutTHR}
              getBpjsNominal={getBpjsNominal}
              IDR={IDR}
              areaWorkEquipmentsNominal={areaWorkEquipmentsNominal}
              manfee={manfee}
              handleClickSee={handleClickSee}
              manfeeMode={manfeeMode}
              manfeePerArea={manfeePerArea}
              manfeePercentage={manfeePercentage}
              endTotalPerArea={endTotalPerArea}
              getSubTotalPerArea={getSubTotalPerArea}
              umkArr={umkArr}
              setUmkArr={setUmkArr}
              getTotalCostPerArea={getTotalCostPerArea}
              costList={costList}
              setCostList={setCostList}
              generalCost={generalCost}
              getEachManfeeGetter={getEachManfeeGetter}
            />
          );
        })}

        {isNoData && <div className={Styles.noData}>No Data Yet</div>}
      </div>
      <div className={Styles.summarySection}>
        <Summary generalCost={generalCost} TotalManfeeCost={TotalManfeeCost} ppn={ppn}/>
      </div>
      <div
        className={`${Styles.modalWrapper} ${
          selectedManPower ? Styles.onShow : Styles.onHide
        }`}
      >
        <ViewDetailModal
          data={selectedManPower}
          setData={setSelectedManPower}
          personalEquipments={selectedPersonalWorkEquipmentNominal}
          allowance={selectedAllowanceNominal}
          bpjs={selectedBpjsNominal}
          totalCost={selectedTotalCost}
          manfee={selectedManfee}
          equipments={selectedAreaWorkEquipments}
          manfeeMode={manfeeMode}
          manfeePercentage={manfeePercentage}
          cleanAllowance={selectedCleanAllowance}
          salary={selectedSalary}
          getEachManfeeGetter={getEachManfeeGetter}
        />
      </div>
      <div
        className={`${Styles.modalWrapper} ${
          showSelect ? Styles.onShow : Styles.onHide
        }`}
      >
        <SelectModal setShow={setShowSelect} setManfeeMode={setManfeeMode} />
      </div>
      <div
        ref={boxRef}
        className={`${Styles.overlay} ${
          selectedManPower || showSelect ? Styles.visible : ""
        }`}
      />
    </div>
  );
}
