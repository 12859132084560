import Icon from "@Atom/Icon";
import { NavLink } from "react-router-dom";
import Styles from "../navbar.module.scss";
import { useState } from "react";

export default function RONavbar({ active, activeInfo, onHover, width }) {
  const [expandRC, setExpandRC] = useState(false);
  return (
    <div className={Styles.navWrapper}>
      <NavLink
        to={`/`}
        className={({ isActive, isPending }) =>
          isActive && active === "/"
            ? Styles.navActive
            : isPending
            ? "pending"
            : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          {active !== "/" ? (
            <div className={Styles.iconDash}>
              <Icon icon="dashboard-outline" color="#757575" size={20} />
            </div>
          ) : (
            <div className={Styles.iconDash}>
              <Icon icon="dashboard-outline" color="#1571DE" size={20} />
            </div>
          )}
          {width <= 768 && <span>Halaman Utama</span>}
          {width > 768 && onHover && <span>Halaman Utama</span>}
        </button>
      </NavLink>
      <NavLink
        to={`/job-posting`}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          {activeInfo[1] === "job-posting" ? (
            <div className={Styles.iconDash}>
              <Icon icon="report-outline" color="#1571DE" size={20} />
            </div>
          ) : (
            <div className={Styles.iconDash}>
              <Icon icon="report-outline" color="#757575" size={20} />
            </div>
          )}
          {width <= 768 && <span>Job Posting</span>}
          {width > 768 && onHover && <span>Job Posting</span>}
        </button>
      </NavLink>
      <NavLink
        to={`/rekrutmen`}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          {activeInfo[1] === "rekrutmen" ? (
            <div className={Styles.iconDash}>
              <Icon icon="recruitment-outline" color="#1571DE" size={20} />
            </div>
          ) : (
            <div className={Styles.iconDash}>
              <Icon icon="recruitment-outline" color="#757575" size={20} />
            </div>
          )}
          {width <= 768 && <span>Rekrutmen</span>}
          {width > 768 && onHover && <span>Rekrutmen</span>}
        </button>
      </NavLink>

      {/* RECRUITER CANDIDATE */}
      <button
        onClick={() => setExpandRC(!expandRC)}
        className={`${Styles.navButton} ${
          activeInfo[1]?.includes("daftar-kandidat") ? Styles.navActive : ""
        }`}
      >
        <div className={Styles.iconDash}>
          <Icon
            icon="global-community"
            size={20}
            color={
              activeInfo[1]?.includes("daftar-kandidat") ? "#1571DE" : "#757575"
            }
          />
        </div>
        {((width > 768 && onHover) || width <= 768) && (
          <span>Daftar Kandidat</span>
        )}
        {((width > 768 && onHover) || width <= 768) && (
          <div className={Styles.iconExpand}>
            <Icon
              icon={expandRC ? "arrow-up" : "arrow-down"}
              size={20}
              color={
                activeInfo[1]?.includes("daftar-kandidat")
                  ? "#1571DE"
                  : "#757575"
              }
            />
          </div>
        )}
      </button>

      {/* RECRUITER CANDIDATE / PUBLIC */}
      {((width > 768 && onHover) || width <= 768) && expandRC && (
        <div className={Styles.subMenuWrapper}>
          <NavLink
            to={`/daftar-kandidat-public`}
            className={({ isActive, isPending }) =>
              isActive ? Styles.navActive : isPending ? "pending" : ""
            }
          >
            <div className={Styles.subNav}>
              <button className={`${Styles.navButton}`}>
                {activeInfo[1] === "daftar-kandidat-public" ? (
                  <div className={Styles.iconDash}>
                    <Icon icon="clarity-employee" color="#1571DE" size={20} />
                  </div>
                ) : (
                  <div className={Styles.iconDash}>
                    <Icon icon="clarity-employee" color="#757575" size={20} />
                  </div>
                )}
                {((width > 768 && onHover) || width <= 768) && (
                  <span>Public</span>
                )}
              </button>
            </div>
          </NavLink>
        </div>
      )}

      {/* RECRUITER CANDIDATE / PRIVATE */}
      {((width > 768 && onHover) || width <= 768) && expandRC && (
        <div className={Styles.subMenuWrapper}>
          <NavLink
            to={`/daftar-kandidat-private`}
            className={({ isActive, isPending }) =>
              isActive ? Styles.navActive : isPending ? "pending" : ""
            }
          >
            <div className={Styles.subNav}>
              <button className={`${Styles.navButton}`}>
                {activeInfo[1] === "daftar-kandidat-private" ? (
                  <div className={Styles.iconDash}>
                    <Icon icon="private-people" color="#1571DE" size={20} />
                  </div>
                ) : (
                  <div className={Styles.iconDash}>
                    <Icon icon="private-people" color="#757575" size={20} />
                  </div>
                )}
                {((width > 768 && onHover) || width <= 768) && (
                  <span>Private</span>
                )}
              </button>
            </div>
          </NavLink>
        </div>
      )}

      {/* <NavLink
        to={`/jadwal`}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          {activeInfo[1] === "jadwal" ? (
            <Icon icon="calendar-v2" color="#1571DE" size={20} />
          ) : (
            <Icon icon="calendar-v2" color="#757575" size={20} />
          )}
          <span>Jadwal</span>
        </button>
      </NavLink> */}
      <NavLink
        to={`/klien`}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          {activeInfo[1] === "klien" ? (
            <div className={Styles.iconDash}>
              <Icon icon="company-outline" color="#1571DE" size={20} />
            </div>
          ) : (
            <div className={Styles.iconDash}>
              <Icon icon="company-outline" color="#757575" size={20} />
            </div>
          )}
          {width > 768 && onHover && <span>Klien</span>}
          {width <= 768 && <span>Klien</span>}
        </button>
      </NavLink>
      {/* <NavLink
        to={`/pipeline-list`}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          {activeInfo[1] === "pipeline-list" ? (
            <div className={Styles.iconDash}>
              <Icon icon="user-group" color="#1571DE" size={20} />
            </div>
          ) : (
            <div className={Styles.iconDash}>
              <Icon icon="user-group" color="#757575" size={20} />
            </div>
          )}
          {width > 768 && onHover && <span>Pipeline</span>}
          {width <= 768 && <span>Pipeline</span>}
        </button>
      </NavLink> */}
      <NavLink
        to={`/chat`}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          {activeInfo[1] === "chat" ? (
            <div className={Styles.iconDash}>
              <Icon icon="chat" color="#1571DE" size={20} />
            </div>
          ) : (
            <div className={Styles.iconDash}>
              <Icon icon="chat" color="#757575" size={20} />
            </div>
          )}
          {width > 768 && onHover && <span>Chat</span>}
          {width <= 768 && <span>Chat</span>}
        </button>
      </NavLink>
      <NavLink
        to={`/tips-&-trick`}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          {activeInfo[1] === "tips-&-trick" ? (
            <div className={Styles.iconDash}>
              <Icon icon="bulb" color="#1571DE" size={20} />
            </div>
          ) : (
            <div className={Styles.iconDash}>
              <Icon icon="bulb" color="#757575" size={20} />
            </div>
          )}
          {width > 768 && onHover && <span>Tips & Trick</span>}
          {width <= 768 && <span>Tips & Trick</span>}
        </button>
      </NavLink>
      {/* <NavLink
        to={`/tugas`}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          {activeInfo[1] === "tugas" ? (
            <Icon icon="task-outline" color="#1571DE" size={20} />
          ) : (
            <Icon icon="task-outline" color="#757575" size={20} />
          )}
          <span>Tugas</span>
        </button>
      </NavLink> */}
      {/* <NavLink
        to={`/laporan`}
        className={({ isActive, isPending }) =>
          isActive ? Styles.navActive : isPending ? "pending" : ""
        }
      >
        <button className={`${Styles.navButton}`}>
          {activeInfo[1] === "laporan" ? (
            <Icon icon="report-outline" color="#1571DE" size={20} />
          ) : (
            <Icon icon="report-outline" color="#757575" size={20} />
          )}
          <span>Laporan</span>
        </button>
      </NavLink> */}
    </div>
  );
}
