import { InputTitle } from "@Molecule/InputField";
import Styles from "./inputNumberField.module.scss";
import InputNumber from "@Atom/InputNumber";

const InputNumberField = ({
  title,
  min,
  max,
  value,
  setValue,
  required,
  placeholder = '',
  isError = false,
  isErrorV2 = false,
  errorText = '',
  ...props }) => {
  return (
    <div className={Styles.container}>
      {/* <div className={Styles.titleWrapper}>{title}{required && <span className={Styles.asterisk}>&#x2a;</span>}</div> */}
      <InputTitle title={title} required={required} />
      <InputNumber
        placeholder={placeholder}
        min={min}
        max={max}
        value={value}
        setValue={setValue}
        isError={isError}
        isErrorV2={isErrorV2}
        errorText={errorText}
      />
    </div>
  );
};

export default InputNumberField;
