import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useCallback, useEffect, useState } from "react";
// import JobSeakerDetail from "@Organism/Client/JobSeakerDetail";
import POSTER from "@Assets/Images/870-smd-nita-31 okt.png";
import POSTER_SECOND from "@Assets/Images/poster-example.png";
import POSTER_THIRD from "@Assets/Images/BENEFIT_IMAGE_1.png";
import JobSeekerCard from "@Molecule/JobSeakerCard";
import JobSeekerList from "@Molecule/JobSeekerList";
import { Link } from "react-router-dom";
import { getListJobSeeker } from "@Services/client/jobSeeker";

// eslint-disable-next-line no-unused-vars
const response = {
  data: [
    {
      id: 1,
      title: "SPG Food",
      address: "Jakarta",
      img: POSTER,
    },
    {
      id: 2,
      title: "Sales Executive Handphone",
      address: "Semarang",
      img: POSTER_SECOND,
    },
    {
      id: 3,
      title: "Finance Staff",
      address: "Semarang",
      img: POSTER_THIRD,
    },
    {
      id: 4,
      title: "Finance Staff",
      address: "Semarang",
      img: POSTER,
    },
    {
      id: 5,
      title: "Finance Staff",
      address: "Semarang",
      img: POSTER,
    },
    {
      id: 6,
      title: "Finance Staff",
      address: "Semarang",
      img: POSTER,
    },
    {
      id: 7,
      title: "Finance Staff",
      address: "Semarang",
      img: POSTER,
    },
    {
      id: 8,
      title: "Finance Staff",
      address: "Semarang",
      img: POSTER,
    },
  ],
};

export default function JobSeekerListHome({
  initialData,
  isLoadingData,
  setIsLoadingData,
}) {
  const sessionSelectedViewJobSeaker = sessionStorage.getItem(
    "idButtonViewJobListHome"
  );

  const [selectedButtonView, setSelectedButtonView] = useState("Grid");
  const setActiveButtonView = (idButton) => {
    setSelectedButtonView(idButton);
    sessionStorage.setItem("idButtonViewJobListHome", idButton);
  };

  useEffect(() => {
    if (sessionSelectedViewJobSeaker) {
      setSelectedButtonView(sessionSelectedViewJobSeaker);
    } else {
      setSelectedButtonView("Grid");
    }
  }, [sessionSelectedViewJobSeaker]);

  // open modal detail
  const [showDetail, setShowDetail] = useState("");
  const [dataList, setDataList] = useState(initialData || null);

  const refetchData = useCallback(async () => {
    try {
      setIsLoadingData(true);
      const { response } = await getListJobSeeker(
        9,
        1,
        "",
        "",
        "",
        "",
        "",
        ""
        // debouncedSearchTerm
      );

      setDataList(response);
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log(err);
    }
  }, [setIsLoadingData]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  return (
    <div className={Styles.body}>
      <div className={Styles.topList}>
        <div className={Styles.titleListBox}>
          <span className={Styles.h1}>Daftar Pekerjaan</span>
          <span className={Styles.p}>
            Pilih pekerjaan yang sesuai untuk karirmu
          </span>
        </div>
        <div className={Styles.filterViewBox}>
          <div
            className={`${Styles.filter} ${
              selectedButtonView === "Grid" && Styles.activeButton
            }`}
            onClick={() => setActiveButtonView("Grid")}
          >
            <Icon
              icon={"view-by-grid"}
              size={20}
              color={selectedButtonView === "Grid" ? "#1571de" : "black"}
            />
            <span
              className={`${Styles.text} ${
                selectedButtonView === "Grid" && Styles.activeText
              }`}
            >
              Grid
            </span>
          </div>
          <div
            className={`${Styles.filter} ${
              selectedButtonView === "List" && Styles.activeButton
            }`}
            onClick={() => setActiveButtonView("List")}
          >
            <Icon
              icon={"view-by-list"}
              size={20}
              color={selectedButtonView === "List" ? "#1571de" : "black"}
            />
            <span
              className={`${Styles.text} ${
                selectedButtonView === "List" && Styles.activeText
              }`}
            >
              List
            </span>
          </div>
        </div>
      </div>

      {selectedButtonView === "Grid" && (
        <JobSeekerCard data={dataList} isLoadingData={isLoadingData} />
      )}
      {selectedButtonView === "List" && (
        <JobSeekerList
          data={dataList}
          showDetail={showDetail}
          setShowDetail={setShowDetail}
          isLoadingData={isLoadingData}
        />
      )}

      <div className={Styles.buttonBox}>
        <Link to={"/job-seeker"} className={Styles.textButton}>
          Lihat Pekerjaan Lainnya
        </Link>
      </div>
    </div>
  );
}
