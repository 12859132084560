import React, { useEffect, useState } from "react";
import Styles from "./inputNumber.module.scss";
import Icon from "@Atom/Icon";
import ErrorBubble from "@Atom/ErrorBubble";

const InputNumber = ({
  min = 0,
  max = 10000,
  value,
  setValue,
  placeholder = 0,
  isError = false,
  errorText = "",
  isErrorV2,
  ...props
}) => {
  let [currentValue, setCurrentValue] = useState(value || null);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (currentValue === null && value) {
      setCurrentValue(+value)
    }
  }, [currentValue, value])

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if(value != currentValue){
      setValue(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  return (
    <div>
      <div className={Styles.container}>
        <input
          className={`${Styles.input} ${isError ? Styles.error : ""}`}
          value={currentValue || ""}
          placeholder={placeholder}
          onChange={(e) => {
            const re = /^[0-9\b]+$/;
            if (e?.target?.value === "") {
              setCurrentValue("");
            } else {
              if ( re.test(e.target.value)) {
                setCurrentValue(
                  +e.target.value <= max && +e.target.value >= min
                    ? +e.target.value
                    : +e.target.value > max
                      ? max
                      : +e.target.value < min
                        ? min
                        : null
                );
              }
            }
          }}
        />
        <div className={Styles.arrows}>
          <div
            onClick={() =>
              setCurrentValue(
                currentValue < max ? (currentValue += 1) : currentValue
              )
            }
          >
            <Icon size={12} icon="arrow-up" />
          </div>
          <div
            onClick={() =>
              setCurrentValue(
                currentValue > min ? (currentValue -= 1) : currentValue
              )
            }
          >
            <Icon size={12} icon="arrow-down" />
          </div>
        </div>
      </div>
      {isError &&
        (!isErrorV2 ? (
          <span className={Styles.errorText}>{errorText}</span>
        ) : (
          <ErrorBubble errorText={errorText} />
        ))}
    </div>
  );
};

export default InputNumber;
