import LoadingAnimation from "@Atom/LoadingAnimation";
import JobSeakerDetail from "@Organism/Client/JobSeakerDetail";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getListJobSeeker } from "@Services/client/jobSeeker";
import { Suspense } from "react";
import Styles from "./styles.module.scss";
// import { getListDividerUtilityClass } from "@mui/joy";
import { Await, defer, useLoaderData } from "react-router-dom";
export async function loader() {
  const packageJobSeekerPromise = getListJobSeeker(
    16,
    1,
    "",
    "",
    "",
    "",
    "",
    ""
  );
  return defer({
    packageJobSeeker: packageJobSeekerPromise,
  });
}

export default function JobSeakerDetailPage() {
  const { packageJobSeeker } = useLoaderData();

  return (
    <Suspense
      fallback={
        <div className={Styles.loadingContainer}>
          <LoadingAnimation />
        </div>
      }
    >
      <Await
        resolve={packageJobSeeker}
        errorElement={
          <div style={{ height: "calc(100vh - 220px)" }}>
            <AsyncErrorBoundary />
          </div>
        }
      >
        {(packageJobSeeker) => (
          <JobSeakerDetail initialData={packageJobSeeker?.response} />
        )}
      </Await>
    </Suspense>
  );
}
