import React, { useState, useRef } from "react";
import Styles from "./style.module.scss";
import PROFILEPICT from "../../../../../assets/Images/ava-default.png";
import Icon from "@Atom/Icon";
import InputField from "@Molecule/InputField";
import { fileBaseUrl } from "@Config/api";
import { patchClientProfileAsync } from "../../../../../async";

const EditProfile = ({ setEditProfile, data, ...props }) => {
  // const accessToken = sessionStorage.getItem('accessToken');
  // const clientId = sessionStorage.getItem('id');
  const accessToken = localStorage.getItem('accessToken');
  const clientId = localStorage.getItem('id');
  const [value, setValue] = useState({
    name: data?.name,
    jobTitle: data?.jobTitle,
    email: data?.email,
    phoneNumber: data?.phoneNumber?.split('62')[1],
    workUnit: data?.workUnit,
    file: (data?.profilePicture && data?.profilePicture !== 'null')
      ? (data?.profilePicture?.includes('http')
        ? data?.profilePicture?.replace('https://myworkspace', 'https://api1.myworkspace')
        : fileBaseUrl + data?.profilePicture)
      : '',
  })

  const [disabled, setDisabled] = useState(false);

  const imageRef = useRef();

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files?.[0];
      setValue({
        ...value,
        file: img,
      })
    }
  }

  const handleChange = (e) => {
    setValue((prev) => ({ ...prev, [e.name]: e.value }));
  };

  const SubmitRequestEdit = async () => {
    const formData = new FormData();
    formData.append("name", value?.name)
    formData.append("jobTitle", value?.jobTitle)
    formData.append("email", value?.email)
    formData.append("phoneNumber", `62${value?.phoneNumber}`)
    formData.append("workUnit", value?.workUnit)
    formData.append("file", value?.file)

    try {
      setDisabled(true);
      const res = await patchClientProfileAsync(
        accessToken,
        formData,
        clientId,
      );
      if (res) {
        setDisabled(false);
        setEditProfile(false);
        localStorage.setItem('role', res?.data?.response?.jobTitle);
        localStorage.setItem('username', res?.data?.response?.name);
        localStorage.setItem('profilePicture', res?.data?.response?.profilePicture);
        // sessionStorage.setItem('role', res?.data?.response?.jobTitle);
        // sessionStorage.setItem('username', res?.data?.response?.name);
        // sessionStorage.setItem('profilePicture', res?.data?.response?.profilePicture);
      }
    } catch (error) {
      console.log(error, 'error saat edit profile');
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.profilePictureWrapper}>
        <input type="file" name="myImage" ref={imageRef} onChange={onImageChange} hidden />
        <div className={Styles.profilePicture} onClick={() => imageRef.current.click()}>
          <img src={!value?.file ? PROFILEPICT : typeof value?.file === 'string' ? value?.file : URL.createObjectURL(value?.file)} height={75} width={75} alt="profilePict" style={{ borderRadius: 37.5, objectFit: 'cover' }} />
          <div className={Styles.cameraWrapper}>
            <Icon icon="camera" size={20} className={Styles.icon} />
          </div>
        </div>
      </div>
      {/* <input type="file"/> */}
      <div className={Styles.form}>
        <div className={Styles.formTitle}>Personal Information</div>
        <InputField title="Nama" value={value?.name} onChange={(e) => handleChange({ name: "name", value: e.target.value })} />
        <InputField title="Posisi" value={value?.jobTitle} onChange={(e) => handleChange({ name: "jobTitle", value: e.target.value })} />
        <InputField title="Email" value={value?.email} onChange={(e) => handleChange({ name: "email", value: e.target.value })} />
        <InputField title="No HP" value={value?.phoneNumber} onChange={(e) => handleChange({ name: "phoneNumber", value: e.target.value })} type="phone" />
        <InputField title="Unit Kerja" value={value?.workUnit} onChange={(e) => handleChange({ name: "workUnit", value: e.target.value })} />
      </div>
      {/* <div className={Styles.form}>
        <div className={Styles.formTitle}>Access</div>
        <InputField title="Kata Sandi" value="ilovebandung" type="password" />
      </div> */}
      <div className={Styles.footer}>
        <div className={Styles.buttonsWrapper}>
          <div className={Styles.cancelButton} onClick={() => setEditProfile(false)}>
            <div className={Styles.cancelText}>Cancel</div>
          </div>
          <div className={Styles.saveButton} style={{ backgroundColor: disabled ? '#91bdf1' : null }} onClick={!disabled ? SubmitRequestEdit : null}>
            <div className={Styles.saveText}>Save</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
