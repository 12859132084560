import { useEffect, useRef, useState } from "react";
import AddLocationModal from "./AddLocationModal";
import DefaultLocation from "./Default";
import WithLocation from "./WithLocation";
import Styles from "./style.module.scss";
import UseOutsideClick from "@Hooks/useOutsideClick";

export default function LocationSection(props) {
  const {
    form,
    setForm,
    cities,
    qualifications,
    options,
    trigger,
    setTrigger,
    debounceTrigger,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [showAddMobile, setShowAddMobile] = useState(false);
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (showAddMobile !== false) {
      if (boxOutsideClick === false) {
        setShowAddMobile(false);
      }
    }
  }, [boxOutsideClick, showAddMobile]);

  useEffect(() => {}, [trigger]);

  return (
    <div className={Styles.container}>
      {form.length === 0 ? (
        <DefaultLocation
          debounceTrigger={debounceTrigger}
          trigger={trigger}
          setTrigger={setTrigger}
          setShowAdd={setShowAdd}
          setShowAddMobile={setShowAddMobile}
        />
      ) : (
        <WithLocation
          debounceTrigger={debounceTrigger}
          trigger={trigger}
          setTrigger={setTrigger}
          options={options}
          cities={cities}
          qualifications={qualifications}
          form={form}
          setForm={setForm}
          setShowAddLocationMobile={setShowAddMobile}
          setShowAddLocation={setShowAdd}
        />
      )}
      {showAdd && (
        <AddLocationModal
          trigger={trigger}
          setTrigger={setTrigger}
          cities={cities}
          setShowAdd={setShowAdd}
          form={form}
          setForm={setForm}
        />
      )}
      <div
        className={`${Styles.modalWrapper} ${
          showAddMobile ? Styles.showModal : Styles.hideModal
        }`}
      >
        <AddLocationModal
          trigger={trigger}
          setTrigger={setTrigger}
          type="mobile"
          cities={cities}
          setShowAdd={setShowAddMobile}
          form={form}
          setForm={setForm}
        />
      </div>
      <div
        ref={boxRef}
        className={`${Styles.overlay} ${showAddMobile ? Styles.visible : ""}`}
      />
    </div>
  );
}
