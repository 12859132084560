import Icon from '@Atom/Icon'
import Styles from './style.module.scss'

export default function ErrorBubble({
  errorText = '',
  marginTop = '8px',
  marginBottom = '0px',
  noTip = false
}) {

  return (
    <div className={`${Styles.container} ${noTip ? Styles.noTip : ''}`} style={{ marginTop, marginBottom }}>
      <Icon icon={'alert-solid'} size={16} color={'#D42701'} />
      <span>{errorText}</span>
    </div>
  )
}