import moment from "moment";
import Styles from "./style.module.scss";
import { useMemo } from "react";

export default function InformationDataSection({ data }) {
  const payroll = useMemo(() => {
    return data?.order?.Client?.contacts?.find(
      (el) => el?.workUnit?.toLowerCase() === "payroll"
    );
  }, [data]);
  const invoice = useMemo(() => {
    return data?.order?.Client?.contacts?.find(
      (el) => el?.workUnit?.toLowerCase() === "invoice"
    );
  }, [data]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Information Data</h3>
      </div>
      <div className={Styles.content}>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Periode Kerjasama</h4>
          </div>
          <div className={`${Styles.col} ${Styles.miniPadding}`}>
            <div className={Styles.stacked}>
              <span>Tanggal mulai</span>
              <span>
                {data?.order?.Client?.contracts[0]?.startDate
                  ? moment(
                      new Date(data?.order?.Client?.contracts[0]?.startDate)
                    ).format("LL")
                  : "-"}
              </span>
            </div>
            <div className={Styles.stacked}>
              <span>Tanggal berakhir</span>
              <span>
                {data?.order?.Client?.contracts[0]?.endDate
                  ? moment(
                      new Date(data?.order?.Client?.contracts[0]?.endDate)
                    ).format("LL")
                  : "-"}
              </span>
            </div>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Tipe Kontrak</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>{data?.order?.contractType}</span>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Kontak Person Klien</h4>
          </div>
          <div className={`${Styles.col} ${Styles.miniPadding}`}>
            <div className={Styles.colSection}>
              <h5>Payroll</h5>
              <div className={Styles.stacked}>
                <span>Nama</span>
                <span>{payroll?.name || "-"}</span>
              </div>
              <div className={Styles.stacked}>
                <span>Telp/HP</span>
                <span>
                  {payroll?.phoneNumber ? "+" + payroll?.phoneNumber : "-"}
                </span>
              </div>
              <div className={Styles.stacked}>
                <span>Email</span>
                <span>{payroll?.email || "-"}</span>
              </div>
            </div>
            <div className={Styles.colSection}>
              <h5>Invoice</h5>
              <div className={Styles.stacked}>
                <span>Nama</span>
                <span>{invoice?.name || "-"}</span>
              </div>
              <div className={Styles.stacked}>
                <span>Telp/HP</span>
                <span>
                  {invoice?.phoneNumber ? "+" + invoice?.phoneNumber : "-"}
                </span>
              </div>
              <div className={Styles.stacked}>
                <span>Email</span>
                <span>{invoice?.email || "-"}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>NPWP Klien</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>{data?.clientFrom?.taxNumber}</span>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Flow Invoice</h4>
          </div>
          <div
            className={`${Styles.col} ${Styles.miniPadding} ${Styles.goingDown}`}
          >
            <div className={Styles.top}>
              <div className={Styles.colSection}>
                <div className={Styles.stacked}>
                  <span>UP di invoice</span>
                  <span>{data?.order?.orderInvoiceFlows?.UP || "-"}</span>
                </div>
                <div className={Styles.stacked}>
                  <span>Tanggal pengajuan soft copy invoice</span>
                  <span>
                    {data?.order?.orderInvoiceFlows
                      ?.TanggalPengajuanSoftCopyInvoice || "-"}
                  </span>
                </div>
                <div className={Styles.stacked}>
                  <span>Tanggal mulai penggajian</span>
                  <span>
                    {data?.order?.orderInvoiceFlows?.TanggalMulaiPenggajian ||
                      "-"}
                  </span>
                </div>
              </div>
              <div className={Styles.colSection}>
                <div className={Styles.stacked}>
                  <span>Tanggal diterima invoice</span>
                  <span>
                    {data?.order?.orderInvoiceFlows?.TanggalDiterimaData || "-"}
                  </span>
                </div>
                <div className={Styles.stacked}>
                  <span>Tanggal hard copy diterima</span>
                  <span>
                    {data?.order?.orderInvoiceFlows
                      ?.TanggalHardCopyInvoiceDiterima || "-"}
                  </span>
                </div>
                <div className={Styles.stacked}>
                  <span>Tanggal cut off absensi</span>
                  <span>
                    {data?.order?.orderInvoiceFlows?.TanggalCutOffAbsensi ||
                      "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className={Styles.stacked}>
              <span>Dokumen pendukung invoice</span>
              <span>
                {data?.order?.orderInvoiceFlows?.DokumenPendukungInvoice &&
                data?.order?.orderInvoiceFlows?.DokumenPendukungInvoice !==
                  "null"
                  ? data?.order?.orderInvoiceFlows?.DokumenPendukungInvoice
                  : "-"}
              </span>
            </div>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Rekening Penerima Invoice</h4>
          </div>
          <div className={`${Styles.col} ${Styles.miniPadding}`}>
            <div className={Styles.colSection}>
              <div className={Styles.stacked}>
                <span>Perusahaan penerima</span>
                <span>{data?.order?.bankRecipient || "-"}</span>
              </div>
              <div className={Styles.col}>
                <div className={Styles.stacked}>
                  <span>Bank</span>
                  <span>{data?.order?.bankName || "-"}</span>
                </div>
                <div className={Styles.stacked}>
                  <span>Nomor rekening</span>
                  <span>{data?.order?.bankNumber || "-"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Absen Dipotong Bila</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>
              {data?.order?.orderComprehensives?.KetentuanPotongAbsen || "-"}
            </span>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Keterangan Lainnya</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>
              {data?.order?.orderComprehensives?.KeteranganLainnya || "-"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
