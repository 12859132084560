/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import Images from "@Theme/Images";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./styles.module.scss";
// import ListModeRecruitment from "./ListModeRecruitment";
import Modal from "@Atom/Modal";
import ModalWrapper from "@Atom/ModalWrapper";
import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import DoneModal from "@Molecule/_modal/DoneModal";
import InterviewInvitationModal from "@Molecule/_modal/InterviewInvitationModal";
import RecruiterDoneModal from "@Molecule/_modal/Recruiter/DoneModal";
import SuccessApprovalModal from "@Molecule/_modal/SuccessApprovalModal";
import RecruiterApprovalModal from "@Organism/Recruiter/RecruiterCandidateList/ApprovalModal";
import TransferModal from "@Organism/Recruiter/RecruiterCandidateList/TransferModal";
import {
  candidateTransferType,
  getAllCandidateSync,
  getInvitationSync,
  getRecruitmentFilter,
} from "@Services/officer/recruitment";
import { useNavigate } from "react-router-dom";
import DetailCandidateRecruitment from "./DetailCandidateRekrutment";
import EvaluationFormModal from "./EvaluationForm";
import FilterModal from "./FilterModal";
import InvitationModal from "./InvitationModal";
import ListModeRecruitment from "./ListModeRecruitment";
import OfferingFormPage from "./OfferingForm";
import RevertModal from "./RevertModal";
import SendRecommendationModal from "./SendRecommendation";
import SendRecomendationPerCandidate from "./SendRecommendation/SendRecommendPerCandidate";
import ShowDetailTable from "./ShowDetailTable";

export default function RecruitmentList({ initialData }) {
  const [searchName, setSearchName] = useState("");
  const [selectedButton, setSelectedButton] = useState("Rekrutmen");
  const [selectedButtonStepRecruitment, setSelectedButtonStepRecruitment] =
    useState("Undang Interview");
  const [selectedButtonStepContract, setSelectedButtonStepContract] =
    useState("Verifikasi Pertama");
  const [selectedButtonTypeContract, setSelectedButtonTypeContract] =
    useState("Reguler");
  const [value, setValue] = useState(5);
  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showDetailTable, setShowDetailTable] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [valueSearchCompany, setValueSearchCompany] = useState("");
  const [valueSearchLocation, setValueSearchLocation] = useState("");
  const [valueSearchPosition, setValueSearchPosition] = useState("");
  const [dataAllRecruitment, setDataAllRecruitment] = useState(
    initialData || null
  );
  const [valueName, setValueName] = useState(
    window.sessionStorage.getItem("valueCandidateRecruitment") || ""
  );
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [dataCandidateFilter, setDataCandidateFilter] = useState([]);
  const [selectedDataCompany, setSelectedDataCompany] = useState(null);
  const [selectedDataLocation, setSelectedDataLocation] = useState(null);
  const [showDetailCandidate, setShowDetailCandidate] = useState(null);
  const [showInterviewRO, setInterviewRO] = useState(null);
  const [showStepBack, setShowStepBack] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [dataOptions, setDataOptions] = useState(null);
  const [contacts, setContacts] = useState([]);
  const debouncedSearchNameFilter = useDebounce(
    showFilter ? valueName || "" : searchName || "",
    300
  );
  const [valueIdCandiate, setValueIdCandidate] = useState(null);
  const [searchIdCandidate, setSearchIdCandidate] = useState(null);
  const [dataFilter, setDataFilter] = useState(null);
  const [showSendRecommended, setShowSendRecommended] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showPreviewPerCandidate, setShowPreviewPerCandidate] = useState(false);
  const [showModalDone, setShowModalDone] = useState(false);
  const [doneText, setDoneText] = useState("");
  const [showSnackDone, setShowSnackDone] = useState(null);
  const [successModal, setSuccessModal] = useState(null);
  const [successBackStep, setSuccessBackStep] = useState(false);
  const [successResend, setSuccessResend] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const query = useQuery();
  const clientCode = query.get("c");
  const positionID = query.get("p");
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedButton) {
      setSelectedButton(selectedButton);
    } else {
      setSelectedButton("Rekrutmen");
    }
  }, [selectedButton]);

  useEffect(() => {
    if (selectedButtonStepRecruitment) {
      setSelectedButtonStepRecruitment(selectedButtonStepRecruitment);
    } else {
      setSelectedButtonStepRecruitment("Undang Interview");
    }
  }, [selectedButtonStepRecruitment]);

  useEffect(() => {
    if (selectedButtonStepContract) {
      setSelectedButtonStepContract(selectedButtonStepContract);
    } else {
      setSelectedButtonStepContract("Verifikasi Pertama");
    }
  }, [selectedButtonStepContract]);

  useEffect(() => {
    if (selectedButtonTypeContract) {
      setSelectedButtonTypeContract(selectedButtonTypeContract);
    } else {
      setSelectedButtonTypeContract("Reguler");
    }
  }, [selectedButtonTypeContract]);

  // console.log(positionID);

  const refetchData = useCallback(async () => {
    try {
      setIsFetchingData(true);
      const previousPage = selectedPage;
      const { response } = await getAllCandidateSync(
        selectedPage,
        value,
        selectedButton,
        selectedButton === "Rekrutmen"
          ? selectedButtonStepRecruitment
          : selectedButtonStepContract,
        selectedDataCompany?.code || "",
        selectedDataLocation?.code || "",
        positionID
          ? encodeURIComponent(positionID)
          : valueSearchPosition
          ? encodeURIComponent(valueSearchPosition)
          : "",
        selectedButton === "Rekrutmen" ? "" : selectedButtonTypeContract,
        valueIdCandiate?.id || "",
        ""
        // positionID ? positionID : ""
      );
      setDataAllRecruitment(response);
      setIsFetchingData(false);
      if (response?.totalPage === 1 || response?.totalPage === 0) {
        setSelectedPage(1);
      } else {
        const validPage = Math.max(
          1,
          Math.min(previousPage, response?.totalPage)
        );
        setSelectedPage(validPage);
      }
    } catch (err) {
      setIsFetchingData(false);
      console.log(err);
    }
  }, [
    selectedPage,
    value,
    selectedButton,
    selectedButtonStepRecruitment,
    selectedButtonStepContract,
    selectedButtonTypeContract,
    valueIdCandiate?.id,
    selectedDataCompany?.code,
    selectedDataLocation?.code,
    valueSearchPosition,
    positionID,
  ]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  const dataStep = useMemo(() => {
    if (selectedButton === "Rekrutmen") {
      return [
        {
          id: 1,
          name: "Undang Interview",
          code: "Undang Interview",
        },
        {
          id: 2,
          name: "Penilaian Interview",
          code: "Penilaian Interview",
        },
        {
          id: 3,
          name: "Undang Interview Klien",
          code: "Undang Interview Klien",
        },
        {
          id: 4,
          name: "Kirim Rekomendasi",
          code: "Kirim Rekomendasi",
        },
        {
          id: 5,
          name: "Kirim Offering",
          code: "Kirim Offering",
        },
      ];
    } else {
      return [
        {
          id: 1,
          name: "Verifikasi Pertama",
          code: "Verifikasi Pertama",
        },
        {
          id: 2,
          name: "Generate Kontrak",
          code: "Generate Kontrak",
        },
        {
          id: 3,
          name: "TTD Kontrak",
          code: "TTD Kontrak",
        },
        {
          id: 4,
          name: "Verifikasi Kedua",
          code: "Verifikasi Kedua",
        },
        {
          id: 5,
          name: "Joined",
          code: "Joined",
        },
      ];
    }
  }, [selectedButton]);

  const datatemp = useMemo(() => {
    if (selectedButton === "Rekrutmen") {
      if (selectedButtonStepRecruitment === "Undang Interview") {
        return {
          totalCandidates: dataAllRecruitment?.totalData || 0,
          data: dataAllRecruitment?.data?.map((item, idx) => {
            return {
              id: idx,
              candidateId: item?.id,
              name: item?.name,
              position: item?.ExpectedPosition?.name,
              positionID: item?.ExpectedPosition?.id,
              contractType: item?.ExpectedPosition?.contractType,
              lastPosition: item?.lastPosition,
              Status: item?.status
                ? item?.status?.includes("HOLD")
                  ? "Hold"
                  : item?.status?.includes("CONTRACTED")
                  ? "Contracted"
                  : item?.status === "RO-INVITE" && item?.tag === null
                  ? "Belum Diundang"
                  : item?.status === "RO-INVITE" && item?.tag === "Invited"
                  ? "Diundang"
                  : item?.status === "RO-INVITE" && item?.tag === "Declined"
                  ? "Menolak Undangan"
                  : item?.status === "RO-INVITE" && item?.tag === "Reschedule"
                  ? "Req. Reschedule"
                  : item?.status
                : null,

              sumber: item?.portal,
              company: item?.ExpectedPosition?.OrderScheme?.Client?.name,
              clientCode: item?.ExpectedPosition?.OrderScheme?.Client?.code,
              placement: item?.ExpectedPosition?.City?.name,
              jobPostLink: item?.candidateLink?.jobPosting,
              linkInterviewRO: item?.candidateLink?.interviewRO,
              email: item?.email,
              phoneNumber: item?.phoneNumber,
              domicili: item?.domicile,
              address: item?.address,
              CandidateDocuments: item?.CandidateDocuments,
              personId: item?.personId,
              remark: item?.remark,
            };
          }),
        };
      } else if (selectedButtonStepRecruitment === "Penilaian Interview") {
        return {
          totalCandidates: dataAllRecruitment?.totalData || 0,
          data: dataAllRecruitment?.data?.map((item, idx) => {
            return {
              candidateId: item?.id,
              id: idx,
              name: item?.name,
              position: item?.ExpectedPosition?.name,
              positionID: item?.ExpectedPosition?.id,
              contractType: item?.ExpectedPosition?.contractType,
              lastPosition: item?.lastPosition,
              Status: item?.status
                ? item?.status?.includes("HOLD")
                  ? "Hold"
                  : item?.status?.includes("CONTRACTED")
                  ? "Contracted"
                  : item?.status === "RO-INVITE" && item?.tag === "Accepted"
                  ? "Butuh Penilaian"
                  : item?.status === "RO-DISQUALIFIED" &&
                    item?.tag === "Declined"
                  ? "Tidak Lolos"
                  : item?.status === "RO-INVITE" && item?.tag === "Absent"
                  ? "Tidak Hadir"
                  : item?.status
                : null,
              sumber: item?.portal,
              company: item?.ExpectedPosition?.OrderScheme?.Client?.name,
              clientCode: item?.ExpectedPosition?.OrderScheme?.Client?.code,
              placement: item?.ExpectedPosition?.City?.name,
              jobPostLink: item?.candidateLink?.jobPosting,
              linkInterviewRO: item?.candidateLink?.interviewRO,
              email: item?.email,
              phoneNumber: item?.phoneNumber,
              domicili: item?.domicile,
              address: item?.address,
              CandidateDocuments: item?.CandidateDocuments,
              personId: item?.personId,
              remark: item?.remark,
            };
          }),
        };
      } else if (selectedButtonStepRecruitment === "Undang Interview Klien") {
        return {
          totalCandidates: dataAllRecruitment?.totalData || 0,
          data: dataAllRecruitment?.data?.map((item, idx) => {
            return {
              candidateId: item?.id,
              id: idx,
              name: item?.name,
              position: item?.ExpectedPosition?.name,
              positionID: item?.ExpectedPosition?.id,
              contractType: item?.ExpectedPosition?.contractType,
              lastPosition: item?.lastPosition,
              Status: item?.status
                ? item?.status?.includes("HOLD")
                  ? "Hold"
                  : item?.status?.includes("CONTRACTED")
                  ? "Contracted"
                  : item?.status === "CLIENT-INVITE" && item?.tag === null
                  ? "Belum Diundang"
                  : item?.status === "CLIENT-INVITE" && item?.tag === "Declined"
                  ? "Menolak Undangan"
                  : item?.status === "CLIENT-INVITE" && item?.tag === "Invited"
                  ? "Diundang"
                  : item?.status
                : null,
              sumber: item?.portal,
              company: item?.ExpectedPosition?.OrderScheme?.Client?.name,
              clientCode: item?.ExpectedPosition?.OrderScheme?.Client?.code,
              placement: item?.ExpectedPosition?.City?.name,
              jobPostLink: item?.candidateLink?.jobPosting,
              linkInterviewRO: item?.candidateLink?.interviewRO,
              linkInterviewClient: item?.candidateLink?.interviewClient,
              email: item?.email,
              phoneNumber: item?.phoneNumber,
              domicili: item?.domicile,
              address: item?.address,
              CandidateDocuments: item?.CandidateDocuments,
              personId: item?.personId,
              remark: item?.remark,
            };
          }),
        };
      } else if (selectedButtonStepRecruitment === "Kirim Rekomendasi") {
        return {
          totalCandidates: dataAllRecruitment?.totalData || 0,
          data: dataAllRecruitment?.data?.map((item, idx) => {
            return {
              candidateId: item?.id,
              id: idx,
              name: item?.name,
              position: item?.ExpectedPosition?.name,
              positionID: item?.ExpectedPosition?.id,
              contractType: item?.ExpectedPosition?.contractType,
              lastPosition: item?.lastPosition,
              Status: item?.status
                ? item?.status?.includes("HOLD")
                  ? "Hold"
                  : item?.status?.includes("CONTRACTED")
                  ? "Contracted"
                  : item?.status === "CLIENT-INVITE" && item?.tag === "Accepted"
                  ? "Belum Dikirim"
                  : item?.status === "CLIENT-INVITE" &&
                    item?.tag === "Recommended"
                  ? "Dikirim"
                  : item?.status === "CLIENT-DISQUALIFIED" &&
                    item?.tag === "Declined"
                  ? "Tidak Lolos"
                  : item?.status
                : null,
              sumber: item?.portal,
              company: item?.ExpectedPosition?.OrderScheme?.Client?.name,
              clientCode: item?.ExpectedPosition?.OrderScheme?.Client?.code,
              placement: item?.ExpectedPosition?.City?.name,
              jobPostLink: item?.candidateLink?.jobPosting,
              linkInterviewRO: item?.candidateLink?.interviewRO,
              linkInterviewClient: item?.candidateLink?.interviewClient,
              email: item?.email,
              phoneNumber: item?.phoneNumber,
              domicili: item?.domicile,
              address: item?.address,
              CandidateDocuments: item?.CandidateDocuments,
              personId: item?.personId,
              remark: item?.remark,
            };
          }),
        };
      } else if (selectedButtonStepRecruitment === "Kirim Offering") {
        return {
          totalCandidates: dataAllRecruitment?.totalData || 0,
          data: dataAllRecruitment?.data?.map((item, idx) => {
            return {
              candidateId: item?.id,
              id: idx,
              name: item?.name,
              position: item?.ExpectedPosition?.name,
              positionID: item?.ExpectedPosition?.id,
              contractType: item?.ExpectedPosition?.contractType,
              lastPosition: item?.lastPosition,
              Status: item?.status
                ? item?.status?.includes("HOLD")
                  ? "Hold"
                  : item?.status?.includes("CONTRACTED")
                  ? "Contracted"
                  : item?.status === "OFFER-INVITE" && item?.tag === null
                  ? "Belum Dikirim"
                  : item?.status === "OFFER-INVITE" && item?.tag === "Declined"
                  ? "Penawaran Ditolak"
                  : item?.status === "OFFER-INVITE" && item?.tag === "Invited"
                  ? "Dikirim"
                  : item?.status
                : null,
              sumber: item?.portal,
              company: item?.ExpectedPosition?.OrderScheme?.Client?.name,
              clientCode: item?.ExpectedPosition?.OrderScheme?.Client?.code,
              placement: item?.ExpectedPosition?.City?.name,
              jobPostLink: item?.candidateLink?.jobPosting,
              linkInterviewRO: item?.candidateLink?.offering,
              isInterviewClient: item?.candidateLink?.interviewClient,
              email: item?.email,
              phoneNumber: item?.phoneNumber,
              domicili: item?.domicile,
              address: item?.address,
              CandidateDocuments: item?.CandidateDocuments,
              personId: item?.personId,
              remark: item?.remark,
            };
          }),
        };
      } else {
        return {
          data: [],
        };
      }
    } else {
      if (selectedButtonStepContract === "Verifikasi Pertama") {
        return {
          totalCandidates: dataAllRecruitment?.totalData || 0,
          data: dataAllRecruitment?.data?.map((item, idx) => {
            return {
              candidateId: item?.id,
              id: idx,
              name: item?.name,
              position: item?.ExpectedPosition?.name,
              positionID: item?.ExpectedPosition?.id,
              contractType: item?.ExpectedPosition?.contractType,
              lastPosition: item?.lastPosition,
              Status: item?.status
                ? item?.status?.includes("HOLD")
                  ? "Hold"
                  : item?.status?.includes("CONTRACTED")
                  ? "Contracted"
                  : item?.status === "BS-REGISTERED" &&
                    item?.stage === "REGISTER"
                  ? "Menunggu Verifikasi"
                  : item?.status === "BS-REGISTERED" &&
                    item?.tag === "Declined" &&
                    item?.stage === "FIRST-VERIFICATION"
                  ? "Perbaikan Data"
                  : item?.status
                : null,
              sumber: item?.portal,
              company: item?.ExpectedPosition?.OrderScheme?.Client?.name,
              clientCode: item?.ExpectedPosition?.OrderScheme?.Client?.code,
              placement: item?.ExpectedPosition?.City?.name,
              jobPostLink: item?.candidateLink?.jobPosting,
              linkInterviewRO: item?.candidateLink?.offering,
              email: item?.email,
              phoneNumber: item?.phoneNumber,
              domicili: item?.domicile,
              address: item?.address,
              CandidateDocuments: item?.CandidateDocuments,
              personId: item?.personId,
              remark: item?.remark,
            };
          }),
        };
      } else if (selectedButtonStepContract === "Generate Kontrak") {
        return {
          totalCandidates: dataAllRecruitment?.totalData || 0,
          data: dataAllRecruitment?.data?.map((item, idx) => {
            return {
              candidateId: item?.id,
              id: idx,
              name: item?.name,
              position: item?.ExpectedPosition?.name,
              positionID: item?.ExpectedPosition?.id,
              contractType: item?.ExpectedPosition?.contractType,
              lastPosition: item?.lastPosition,
              Status: item?.status
                ? item?.status?.includes("HOLD")
                  ? "Hold"
                  : item?.status?.includes("CONTRACTED")
                  ? "Contracted"
                  : item?.status === "BS-PRE-CONTRACT" &&
                    item?.stage === "GENERATE-CONTRACT" &&
                    item?.tag !== "Declined"
                  ? "Belum Generate"
                  : item?.status === "BS-PRE-CONTRACT" &&
                    item?.tag === "Declined" &&
                    item?.stage === "GENERATE-CONTRACT"
                  ? "Perbaikan Kontrak"
                  : item?.status === "BS-PRE-CONTRACT" &&
                    item?.stage === "APPROVE-CONTRACT"
                  ? "Menunggu Approval BM"
                  : item?.status
                : null,
              sumber: item?.portal,
              company: item?.ExpectedPosition?.OrderScheme?.Client?.name,
              clientCode: item?.ExpectedPosition?.OrderScheme?.Client?.code,
              placement: item?.ExpectedPosition?.City?.name,
              jobPostLink: item?.candidateLink?.jobPosting,
              linkInterviewRO: item?.candidateLink?.offering,
              email: item?.email,
              phoneNumber: item?.phoneNumber,
              domicili: item?.domicile,
              address: item?.address,
              CandidateDocuments: item?.CandidateDocuments,
              personId: item?.personId,
              remark: item?.remark,
            };
          }),
        };
      } else if (selectedButtonStepContract === "TTD Kontrak") {
        return {
          totalCandidates: dataAllRecruitment?.totalData || 0,
          data: dataAllRecruitment?.data?.map((item, idx) => {
            return {
              candidateId: item?.id,
              id: idx,
              name: item?.name,
              position: item?.ExpectedPosition?.name,
              lastPosition: item?.lastPosition,
              positionID: item?.ExpectedPosition?.id,
              contractType: item?.ExpectedPosition?.contractType,
              sumber: item?.portal,
              company: item?.ExpectedPosition?.OrderScheme?.Client?.name,
              clientCode: item?.ExpectedPosition?.OrderScheme?.Client?.code,
              placement: item?.ExpectedPosition?.City?.name,
              jobPostLink: item?.candidateLink?.jobPosting,
              linkInterviewRO: item?.candidateLink?.offering,
              linkContract: item?.candidateLink?.contract,
              email: item?.email,
              phoneNumber: item?.phoneNumber,
              domicili: item?.domicile,
              address: item?.address,
              CandidateDocuments: item?.CandidateDocuments,
              personId: item?.personId,
              remark: item?.remark,
            };
          }),
        };
      } else if (selectedButtonStepContract === "Verifikasi Kedua") {
        return {
          totalCandidates: dataAllRecruitment?.totalData || 0,
          data: dataAllRecruitment?.data?.map((item, idx) => {
            return {
              candidateId: item?.id,
              id: idx,
              name: item?.name,
              position: item?.ExpectedPosition?.name,
              positionID: item?.ExpectedPosition?.id,
              contractType: item?.ExpectedPosition?.contractType,
              lastPosition: item?.lastPosition,
              Status: item?.status
                ? item?.status?.includes("HOLD")
                  ? "Hold"
                  : item?.status?.includes("CONTRACTED")
                  ? "Contracted"
                  : item?.status === "BS-PRE-CONTRACT" &&
                    item?.stage === "SIGN-CONTRACT" &&
                    item?.tag === "Accepted"
                  ? "Menunggu Verifikasi"
                  : item?.status === "BS-PRE-CONTRACT" &&
                    item?.tag === "Declined" &&
                    item?.stage === "SECOND-VERIFICATION"
                  ? "Perbaikan Data"
                  : item?.status === "BS-CONTRACT" && item?.stage === "DONE"
                  ? "Gagal Inject"
                  : item?.status
                : null,
              sumber: item?.portal,
              company: item?.ExpectedPosition?.OrderScheme?.Client?.name,
              clientCode: item?.ExpectedPosition?.OrderScheme?.Client?.code,
              placement: item?.ExpectedPosition?.City?.name,
              jobPostLink: item?.candidateLink?.jobPosting,
              linkInterviewRO: item?.candidateLink?.offering,
              linkContract: item?.candidateLink?.contract,
              email: item?.email,
              phoneNumber: item?.phoneNumber,
              domicili: item?.domicile,
              address: item?.address,
              CandidateDocuments: item?.CandidateDocuments,
              personId: item?.personId,
              remark: item?.remark,
            };
          }),
        };
      } else {
        return {
          totalCandidates: dataAllRecruitment?.totalData || 0,
          data: dataAllRecruitment?.data?.map((item, idx) => {
            return {
              id: idx,
              name: item?.name,
              position: item?.ExpectedPosition?.name,
              positionID: item?.ExpectedPosition?.id,
              contractType: item?.ExpectedPosition?.contractType,
              Status: null,
              sumber: item?.portal,
              company: item?.ExpectedPosition?.OrderScheme?.Client?.name,
              lastPosition: item?.lastPosition,
              clientCode: item?.ExpectedPosition?.OrderScheme?.Client?.code,
              placement: item?.ExpectedPosition?.City?.name,
              jobPostLink: item?.candidateLink?.jobPosting,
              linkInterviewRO: item?.candidateLink?.offering,
              linkContract: item?.candidateLink?.contract,
              email: item?.email,
              phoneNumber: item?.phoneNumber,
              domicili: item?.domicile,
              address: item?.address,
              CandidateDocuments: item?.CandidateDocuments,
              personId: item?.personId,
              remark: item?.remark,
            };
          }),
        };
      }
    }
  }, [
    selectedButtonStepRecruitment,
    selectedButton,
    selectedButtonStepContract,
    dataAllRecruitment?.data,
    dataAllRecruitment?.totalData,
  ]);

  const refetchDataFilter = useCallback(async () => {
    try {
      setIsLoadingFilter(true);
      const { response } = await getRecruitmentFilter(
        debouncedSearchNameFilter || ""
      );
      setDataCandidateFilter(response?.candidates);
      setDataFilter(response);
      setIsLoadingFilter(false);
    } catch (err) {
      setIsLoadingFilter(false);
      console.log(err);
    }
  }, [debouncedSearchNameFilter]);

  useEffect(() => {
    refetchDataFilter();
  }, [refetchDataFilter]);

  const onClickOption = (status, stage, tag, jobType, obj, name) => {
    setSearchIdCandidate(obj);
    setValueName(name);

    if (
      status === "RO-INVITE" ||
      status === "RO-QUALIFIED" ||
      status === "RO-DISQUALIFIED" ||
      status === "OFFER-INVITE" ||
      status === "OFFER-DISQUALIFIED" ||
      status === "OFFER-QUALIFIED" ||
      status === "CLIENT-DISQUALIFIED" ||
      status === "CLIENT-QUALIFIED" ||
      status === "CLIENT-INVITE" ||
      status?.includes("RO-INVITE-HOLD") ||
      status?.includes("RO-QUALIFIED-HOLD") ||
      status?.includes("RO-DISQUALIFIED-HOLD") ||
      status?.includes("OFFER-INVITE-HOLD") ||
      status?.includes("OFFER-DISQUALIFIED-HOLD") ||
      status?.includes("OFFER-QUALIFIED-HOLD") ||
      status?.includes("CLIENT-DISQUALIFIED-HOLD") ||
      status?.includes("CLIENT-QUALIFIED-HOLD") ||
      status?.includes("CLIENT-INVITE-HOLD") ||
      status?.includes("RO-INVITE-CONTRACTED") ||
      status?.includes("RO-QUALIFIED-CONTRACTED") ||
      status?.includes("RO-DISQUALIFIED-CONTRACTED") ||
      status?.includes("OFFER-INVITE-CONTRACTED") ||
      status?.includes("OFFER-DISQUALIFIED-CONTRACTED") ||
      status?.includes("OFFER-QUALIFIED-CONTRACTED") ||
      status?.includes("CLIENT-DISQUALIFIED-CONTRACTED") ||
      status?.includes("CLIENT-QUALIFIED-CONTRACTED") ||
      status?.includes("CLIENT-INVITE-CONTRACTED")
    ) {
      setSelectedButton("Rekrutmen");
      // setSelectedButton("Rekrutmen");
      if (
        (status === "RO-INVITE" && tag === null) ||
        (status === "RO-INVITE" && tag === "Invited") ||
        (status === "RO-INVITE" && tag === "Declined") ||
        (status?.includes("RO-INVITE-HOLD") && tag === "Invited") ||
        (status?.includes("RO-INVITE-HOLD") && tag === "Declined") ||
        (status?.includes("RO-INVITE-HOLD") && tag === null) ||
        (status?.includes("RO-INVITE-CONTRACTED") && tag === null) ||
        (status?.includes("RO-INVITE-CONTRACTED") && tag === "Invited") ||
        (status?.includes("RO-INVITE-CONTRACTED") && tag === "Declined")
      ) {
        setSelectedButtonStepRecruitment("Undang Interview");
      } else if (
        (status === "RO-INVITE" && tag === "Accepted") ||
        (status === "RO-DISQUALIFIED" && tag === "Declined") ||
        (status?.includes("RO-INVITE-HOLD") && tag === "Accepted") ||
        (status?.includes("RO-INVITE-CONTRACTED") && tag === "Accepted") ||
        (status?.includes("RO-DISQUALIFIED-HOLD") && tag === "Declined") ||
        (status?.includes("RO-DISQUALIFIED-CONTRACTED") && tag === "Declined")
      ) {
        setSelectedButtonStepRecruitment("Penilaian Interview");
      } else if (
        (status === "OFFER-INVITE" && tag === null) ||
        (status === "OFFER-INVITE" && tag === "Declined") ||
        (status === "OFFER-INVITE" && tag === "Invited") ||
        (status?.includes("OFFER-INVITE-CONTRACTED") && tag === null) ||
        (status?.includes("OFFER-INVITE-CONTRACTED") && tag === "Declined") ||
        (status?.includes("OFFER-INVITE-CONTRACTED") && tag === "Invited") ||
        (status?.includes("OFFER-INVITE-HOLD") && tag === null) ||
        (status?.includes("OFFER-INVITE-HOLD") && tag === "Declined") ||
        (status?.includes("OFFER-INVITE-HOLD") && tag === "Invited")
      ) {
        setSelectedButtonStepRecruitment("Kirim Offering");
      } else if (
        (status === "CLIENT-INVITE" && tag === null) ||
        (status === "CLIENT-INVITE" && tag === "Declined") ||
        (status === "CLIENT-INVITE" && tag === "Invited") ||
        (status?.includes("CLIENT-INVITE-HOLD") && tag === null) ||
        (status?.includes("CLIENT-INVITE-HOLD") && tag === "Declined") ||
        (status?.includes("CLIENT-INVITE-HOLD") && tag === "Invited") ||
        (status?.includes("CLIENT-INVITE-CONTRACTED") && tag === null) ||
        (status?.includes("CLIENT-INVITE-CONTRACTED") && tag === "Declined") ||
        (status?.includes("CLIENT-INVITE-CONTRACTED") && tag === "Invited")
      ) {
        setSelectedButtonStepRecruitment("Undang Interview Klien");
      } else if (
        (status === "CLIENT-INVITE" && tag === "Accepted") ||
        (status === "CLIENT-INVITE" && tag === "Recommended") ||
        (status === "CLIENT-DISQUALIFIED" && tag === "Declined") ||
        (status?.includes("CLIENT-INVITE-HOLD") && tag === "Accepted") ||
        (status?.includes("CLIENT-INVITE-HOLD") && tag === "Recommended") ||
        (status?.includes("CLIENT-DISQUALIFIED-HOLD") && tag === "Declined") ||
        (status?.includes("CLIENT-INVITE-CONTRACTED") && tag === "Accepted") ||
        (status?.includes("CLIENT-INVITE-CONTRACTED") &&
          tag === "Recommended") ||
        (status?.includes("CLIENT-DISQUALIFIED-CONTRACTED") &&
          tag === "Declined")
      ) {
        setSelectedButtonStepRecruitment("Kirim Rekomendasi");
      }
    } else if (
      status === "BS-REGISTERED" ||
      status === "BS-PRE-CONTRACT" ||
      status === "BS-CONTRACT" ||
      status?.includes("BS-REGISTERED-CONTRACTED") ||
      status?.includes("BS-PRE-CONTRACT-CONTRACTED") ||
      status?.includes("BS-CONTRACT-CONTRACTED") ||
      status?.includes("BS-REGISTERED-HOLD") ||
      status?.includes("BS-PRE-CONTRACT-HOLD") ||
      status?.includes("BS-CONTRACT-HOLD")
    ) {
      setSelectedButton("Kontrak");
      if (jobType === "Job Post Peralihan") {
        setSelectedButtonTypeContract("Peralihan");
      } else {
        setSelectedButtonTypeContract("Reguler");
      }

      if (
        (status === "BS-REGISTERED" && stage === "REGISTER") ||
        (status === "BS-REGISTERED" &&
          tag === "Declined" &&
          stage === "FIRST-VERIFICATION") ||
        (status?.includes("BS-REGISTERED-HOLD") && stage === "REGISTER") ||
        (status?.includes("BS-REGISTERED-HOLD") &&
          tag === "Declined" &&
          stage === "FIRST-VERIFICATION") ||
        (status?.includes("BS-REGISTERED-CONTRACTED") &&
          stage === "REGISTER") ||
        (status?.includes("BS-REGISTERED-CONTRACTED") &&
          tag === "Declined" &&
          stage === "FIRST-VERIFICATION")
      ) {
        setSelectedButtonStepContract("Verifikasi Pertama");
      } else if (
        (status === "BS-PRE-CONTRACT" &&
          stage === "GENERATE-CONTRACT" &&
          tag !== "Declined") ||
        (status === "BS-PRE-CONTRACT" &&
          tag === "Declined" &&
          stage === "GENERATE-CONTRACT") ||
        (status === "BS-PRE-CONTRACT" && stage === "APPROVE-CONTRACT") ||
        (status?.includes("BS-PRE-CONTRACT-HOLD") &&
          stage === "GENERATE-CONTRACT") ||
        (status?.includes("BS-PRE-CONTRACT-HOLD") &&
          tag === "Declined" &&
          stage === "GENERATE-CONTRACT") ||
        (status?.includes("BS-PRE-CONTRACT-HOLD") &&
          stage === "APPROVE-CONTRACT") ||
        (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
          stage === "GENERATE-CONTRACT") ||
        (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
          tag === "Declined" &&
          stage === "GENERATE-CONTRACT") ||
        (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
          stage === "APPROVE-CONTRACT")
      ) {
        setSelectedButtonStepContract("Generate Kontrak");
      } else if (
        (status === "BS-PRE-CONTRACT" &&
          stage === "SIGN-CONTRACT" &&
          tag === "Invited") ||
        (status?.includes("BS-PRE-CONTRACT-HOLD") &&
          stage === "SIGN-CONTRACT" &&
          tag === "Invited") ||
        (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
          stage === "SIGN-CONTRACT" &&
          tag === "Invited")
      ) {
        setSelectedButtonStepContract("TTD Kontrak");
      } else if (
        (status === "BS-PRE-CONTRACT" &&
          stage === "SIGN-CONTRACT" &&
          tag === "Accepted") ||
        (status === "BS-PRE-CONTRACT" &&
          tag === "Declined" &&
          stage === "SECOND-VERIFICATION") ||
        (status === "BS-CONTRACT" && stage === "DONE") ||
        (status?.includes("BS-PRE-CONTRACT-HOLD") &&
          stage === "SIGN-CONTRACT" &&
          tag === "Accepted") ||
        (status?.includes("BS-PRE-CONTRACT-HOLD") &&
          tag === "Declined" &&
          stage === "SECOND-VERIFICATION") ||
        (status?.includes("BS-CONTRACT-HOLD") && stage === "DONE") ||
        (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
          stage === "SIGN-CONTRACT" &&
          tag === "Accepted") ||
        (status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
          tag === "Declined" &&
          stage === "SECOND-VERIFICATION") ||
        (status?.includes("BS-CONTRACT-CONTRACTED") && stage === "DONE")
      ) {
        setSelectedButtonStepContract("Verifikasi Kedua");
      } else if (stage === "IOS-SUCCESS") {
        setSelectedButtonStepContract("Joined");
      }
    }
  };

  useEffect(() => {
    if (valueIdCandiate) {
      setSearchIdCandidate(valueIdCandiate);
    } else {
      setSearchIdCandidate(null);
    }
  }, [valueIdCandiate]);

  const refetchDataOptionsInterview = useCallback(async () => {
    try {
      setIsLoadingData(true);
      const { response } = await getInvitationSync(
        showInterviewRO?.data?.clientCode
      );
      setDataOptions(response);
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log(err);
    }
  }, [showInterviewRO?.data?.clientCode]);

  useEffect(() => {
    if (showInterviewRO) {
      refetchDataOptionsInterview();
    }
  }, [showInterviewRO, refetchDataOptionsInterview]);

  useEffect(() => {
    if (dataOptions) {
      setContacts(dataOptions?.clientContacts);
    } else {
      setContacts([]);
    }
  }, [dataOptions, dataOptions?.clientContacts]);

  const handleSubmitPublicOrPrivate = async () => {
    try {
      console.log("submitted");
      await candidateTransferType(
        showSnackDone?.id,
        showSnackDone?.type,
        encodeURIComponent(showSnackDone?.ExpectedPosition?.name),
        {}
      );
      refetchData();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || "something went wrong";
      // setTextError(errorMessage)
      console.log(errorMessage);
    }
  };

  useEffect(() => {
    if (successBackStep === false) {
      setTimeout(() => {
        setShowStepBack(null);
      }, 3000);
    }
  }, [successBackStep]);

  const findOneCompany = useMemo(() => {
    if (clientCode) {
      return (
        dataFilter?.clients?.find((item) => item?.code === clientCode) || null
      );
    } else {
      return null;
    }
  }, [clientCode, dataFilter?.clients]);

  useEffect(() => {
    if (clientCode) {
      setSelectedDataCompany(findOneCompany);
      setValueSearchCompany(findOneCompany?.name);
    } else {
      setSelectedDataCompany(null);
      setValueSearchCompany("");
    }
  }, [clientCode, findOneCompany]);

  if (showInterviewRO?.name === "KIRIM OFFERING") {
    return (
      <OfferingFormPage
        showInterviewRO={showInterviewRO}
        handleClose={() => {
          setInterviewRO(null);
          refetchData();
        }}
      />
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.topHeader}>
          <div className={Styles.top}>
            <div className={Styles.buttonBox}>
              {selectedButton === "Kontrak" ? (
                <div className={Styles.buttonType}>
                  <div
                    className={`${
                      selectedButtonTypeContract === "Reguler" && Styles.active
                    }`}
                    onClick={() => {
                      if (!valueIdCandiate) {
                        setSelectedPage(1);
                        setSelectedButtonTypeContract("Reguler");
                      }
                    }}
                  >
                    Reguler
                  </div>
                  <div
                    className={`${
                      selectedButtonTypeContract === "Peralihan" &&
                      Styles.active
                    }`}
                    onClick={() => {
                      if (!valueIdCandiate) {
                        setSelectedPage(1);
                        setSelectedButtonTypeContract("Peralihan");
                      }
                    }}
                  >
                    Peralihan
                  </div>
                </div>
              ) : null}
              <div
                className={Styles.buttonRefresh}
                onClick={() => {
                  refetchData();
                  navigate(`/rekrutmen`);
                }}
              >
                <span>Muat Ulang</span>
                <Icon icon={"refresh"} size={16} color={"#1571DE"} />
              </div>
            </div>

            <div className={Styles.filterBox}>
              <div
                className={Styles.buttonFilter}
                onClick={() => setShowFilter(true)}
              >
                <span>Filter</span>
                <Icon icon={"adjustment"} size={16} color={"#FFFFFF"} />
              </div>

              <div className={Styles.searchWrapper}>
                <SearchBar
                  placeholder={"Cari Nama Kandidat"}
                  value={searchName}
                  onChange={setSearchName}
                  isRecruitment={true}
                  options={dataCandidateFilter || []}
                  isLoadingFilter={isLoadingFilter}
                  onClickOption={onClickOption}
                  setValueIdCandidate={setValueIdCandidate}
                  valueIdCandidate={valueIdCandiate}
                />
              </div>
            </div>
          </div>

          {valueSearchCompany ||
          (valueSearchLocation && valueSearchLocation !== "-") ||
          (valueSearchPosition && valueSearchPosition !== "-") ? (
            <div className={Styles.listFilterBox}>
              {valueSearchCompany !== "-" && valueSearchCompany ? (
                <div>
                  <div>
                    <span>Perusahaan :</span>
                    <span>{valueSearchCompany || "-"}</span>
                  </div>
                  {/* <Icon
                    icon={"cross"}
                    size={16}
                    color={"#1571DE"}
                    className={Styles.iconDelete}
                    onClick={() => {
                      setValueSearchCompany("");
                      setSelectedDataCompany(null);
                    }}
                  /> */}
                </div>
              ) : null}

              {valueSearchLocation !== "-" && valueSearchLocation ? (
                <div>
                  <div>
                    <span>Lokasi kerja :</span>
                    <span>{valueSearchLocation || "-"}</span>
                  </div>
                  {/* <Icon
                    icon={"cross"}
                    size={16}
                    color={"#1571DE"}
                    className={Styles.iconDelete}
                    onClick={() => {
                      setValueSearchLocation("");
                      setSelectedDataLocation(null);
                    }}
                  /> */}
                </div>
              ) : null}

              {valueSearchPosition ? (
                <div>
                  <div>
                    <span>Posisi :</span>
                    <span>{valueSearchPosition || "-"}</span>
                  </div>
                  {/* <Icon
                    icon={"cross"}
                    size={16}
                    color={"#1571DE"}
                    className={Styles.iconDelete}
                    onClick={() => {
                      setValueSearchPosition("");
                    }}
                  /> */}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className={Styles.bottomHeader}>
          <div>
            <span
              onClick={() => {
                if (!valueIdCandiate) {
                  setSelectedPage(1);
                  setSelectedButton("Rekrutmen");
                }
              }}
              className={`${selectedButton === "Rekrutmen" && Styles.active}`}
            >
              Proses Rekrutmen
            </span>
            <span
              onClick={() => {
                if (!valueIdCandiate) {
                  setSelectedPage(1);
                  setSelectedButton("Kontrak");
                }
              }}
              className={`${selectedButton === "Kontrak" && Styles.active}`}
            >
              Proses Kontrak
            </span>
          </div>
        </div>

        <div className={Styles.lastHeader}>
          <div className={`${Styles.stepperBox}`}>
            {dataStep?.map((el, index) => {
              return (
                <div
                  className={`${Styles.step}`}
                  key={index}
                  onClick={() => {
                    if (selectedButton === "Rekrutmen" && !valueIdCandiate) {
                      setSelectedPage(1);
                      setSelectedButtonStepRecruitment(el?.code);
                    } else if (
                      selectedButton === "Kontrak" &&
                      !valueIdCandiate
                    ) {
                      setSelectedPage(1);
                      setSelectedButtonStepContract(el?.code);
                    }
                  }}
                >
                  {index === 0 ? (
                    <img
                      src={
                        selectedButtonStepContract === el?.code ||
                        selectedButtonStepRecruitment === el?.code
                          ? Images.STEPPER_ACTIVE_1
                          : Images.STEPPER_1
                      }
                      alt=""
                    />
                  ) : index === dataStep?.length - 1 ? (
                    <img
                      src={
                        selectedButtonStepContract === el?.code ||
                        selectedButtonStepRecruitment === el?.code
                          ? Images.STEPPER_ACTIVE_3
                          : Images.STEPPER_3
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      src={
                        selectedButtonStepContract === el?.code ||
                        selectedButtonStepRecruitment === el?.code
                          ? Images.STEPPER_ACTIVE_2
                          : Images.STEPPER_2
                      }
                      alt=""
                    />
                  )}
                  <div className={`${index === 0 && Styles.leftPlace}`}>
                    <div>{el?.id}</div>
                    <span>{el?.name}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={Styles.tableContainer}>
        {selectedButtonStepRecruitment === "Kirim Rekomendasi" &&
        selectedButton === "Rekrutmen" ? (
          <div className={Styles.tableHeader}>
            <div onClick={() => setInterviewRO({ name: "KIRIM-REKOMENDASI" })}>
              Kirim Kandidat ke Klien
            </div>
          </div>
        ) : null}

        {/* {selectedButtonStepContract === "Joined" &&
        selectedButton === "Kontrak" ? (
          <div className={Styles.downloadReportBox}>
            <div className={Styles.left}>
              <div>
                <InputDate
                  title={"Tanggal Lahir"}
                  placeholder={"Pilih tanggal"}
                  iconColor={"#1571DE"}
                  // value={form?.birthDate}
                  value={startDate}
                  setValue={setStartDate}
                  required
                />
              </div>

              <span>-</span>

              <div>
                <InputDate
                  title={"Tanggal Lahir"}
                  placeholder={"Pilih tanggal"}
                  iconColor={"#1571DE"}
                  // value={form?.birthDate}
                  value={endDate}
                  setValue={setEndDate}
                  required
                  // onChange={(e) => handleChange(e, 'birthDate')}
                  // disabled={disableInput}
                  // isError={errorField && !birthDateTemp}
                  // errorText={"*Tanggal lahir belum diisi"}
                />
              </div>
            </div>

            <div className={Styles.right}>
              <div>
                <span>Download Report</span>
                <Icon icon={"download"} size={16} color={"#FFFFFF"} />
              </div>
            </div>
          </div>
        ) : null} */}

        <div className={Styles.main}>
          <ListModeRecruitment
            data={datatemp || []}
            setShowModalDetail={setShowDetailTable}
            value={value}
            setValue={setValue}
            pages={pages}
            setPages={setPages}
            pageList={pageList}
            setPageList={setPageList}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            totalPage={totalPage}
            setTotalPage={setTotalPage}
            selectedButtonStepRecruitment={selectedButtonStepRecruitment}
            isLoading={isFetchingData}
            isDisabled={selectedButton === "Kontrak"}
            selectedButton={selectedButton}
            selectedButtonStepContract={selectedButtonStepContract}
            setShowDetailCandidate={setShowDetailCandidate}
            setInterviewRO={setInterviewRO}
            setShowStepBack={setShowStepBack}
            showNotif={showNotif}
            setShowNotif={setShowNotif}
          />
        </div>
      </div>

      {showDetailTable && (
        <ModalWrapper
          show={showDetailTable}
          handleClose={() => setShowDetailTable(null)}
        >
          <ShowDetailTable
            data={showDetailTable}
            selectedButton={selectedButton}
            selectedButtonStepContract={selectedButtonStepContract}
            selectedButtonStepRecruitment={selectedButtonStepRecruitment}
            setShowNotif={setShowNotif}
          />
        </ModalWrapper>
      )}

      {showFilter && (
        <FilterModal
          handleDone={() => setShowFilter(false)}
          valueSearchCompany={valueSearchCompany}
          setValueSearchCompany={setValueSearchCompany}
          valueSearchLocation={valueSearchLocation}
          setValueSearchLocation={setValueSearchLocation}
          valueSearchPosition={valueSearchPosition}
          setValueSearchPosition={setValueSearchPosition}
          searchName={valueName}
          setSearchName={setValueName}
          showFilter={showFilter}
          valueIdCandiate={valueIdCandiate}
          setValueIdCandidate={setValueIdCandidate}
          valueName={searchName}
          setValueName={setSearchName}
          setSelectedButton={setSelectedButton}
          setSelectedButtonStepContract={setSelectedButtonStepContract}
          setSelectedButtonStepRecruitment={setSelectedButtonStepRecruitment}
          setSelectedButtonTypeContract={setSelectedButtonTypeContract}
          searchIdCandidate={searchIdCandidate}
          setSearchIdCandidate={setSearchIdCandidate}
          dataFilter={dataFilter}
          setSelectedDataCompany={setSelectedDataCompany}
          selectedDataCompany={selectedDataCompany}
          setSelectedDataLocation={setSelectedDataLocation}
          selectedDataLocation={selectedDataLocation}
          navigate={navigate}
        />
      )}

      {showDetailCandidate && (
        <ModalWrapper show={showDetailCandidate} handleClose={() => {}}>
          <DetailCandidateRecruitment
            handleClose={() => {
              setShowDetailCandidate(null);
            }}
            showDetailCandidate={showDetailCandidate}
          />
        </ModalWrapper>
      )}

      {showInterviewRO?.name === "RO" && (
        <ModalWrapper show={showInterviewRO} handleClose={() => {}}>
          <InvitationModal
            handleClose={() => {
              setInterviewRO(null);
            }}
            showInterviewRO={showInterviewRO}
            refetchDataCandidate={refetchData}
            setSelectedPage={setSelectedPage}
            dataOptions={dataOptions}
            setSuccessModal={setSuccessModal}
          />
        </ModalWrapper>
      )}

      {showInterviewRO?.name === "CLIENT" && (
        <ModalWrapper show={showInterviewRO} handleClose={() => {}}>
          <InterviewInvitationModal
            data={showInterviewRO?.data}
            handleClose={() => {
              setInterviewRO(null);
            }}
            invitationSyncData={dataOptions}
            contacts={contacts}
            setContacts={setContacts}
            isRecruitment={true}
            refetchData={refetchData}
            setSelectedPage={setSelectedPage}
            refetchDataOptionsInterview={refetchDataOptionsInterview}
            setSuccessModal={setSuccessModal}
          />
        </ModalWrapper>
      )}

      {showInterviewRO?.name === "KIRIM-REKOMENDASI" && (
        <Modal
          handleDone={() => {}}
          show={showInterviewRO}
          isPreview={showPreview === true}
        >
          <SendRecommendationModal
            showInterviewRO={showInterviewRO}
            handleDone={() => setInterviewRO(null)}
            setShowSendRecommended={setShowSendRecommended}
            showSendRecommended={showSendRecommended}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            refetchDataAllCandidate={refetchData}
          />
        </Modal>
      )}

      {showInterviewRO?.name === "KIRIM REKOMENDASI PER CANDIDATE" && (
        <Modal
          handleDone={() => {}}
          show={showInterviewRO}
          isPreview={showPreviewPerCandidate === true}
        >
          <SendRecomendationPerCandidate
            showInterviewRO={showInterviewRO}
            // handleDone={() => setInterviewRO(null)}
            setInterviewRO={setInterviewRO}
            setShowPreviewPerCandidate={setShowPreviewPerCandidate}
            showPreviewPerCandidate={showPreviewPerCandidate}
            refetchDataAllCandidate={refetchData}
          />
        </Modal>
      )}

      {showInterviewRO?.name === "TransferToJobPosting" ||
      showInterviewRO?.name === "TransferToAnotherRecruiter" ||
      showInterviewRO?.name === "AddLocationAddToJobPosting" ||
      showInterviewRO?.name === "AddLocationTransferToJobPosting" ||
      showInterviewRO?.name === "AddLocationTransferToAnotherRecruiter" ? (
        <ModalWrapper show={showInterviewRO} handleClose={() => {}}>
          <TransferModal
            mode={""}
            type={showInterviewRO?.name}
            data={{
              id: showInterviewRO?.data?.candidateId,
              name: showInterviewRO?.data?.name,
              lastPosition: showInterviewRO?.data?.position,
            }}
            setData={() => {}}
            setShowModalTransfer={setInterviewRO}
            setShowModalDone={setShowModalDone}
            setDoneText={setDoneText}
            setDoneTitle={() => {}}
            setTrigger={() => {}}
            isRecruitment={true}
            refetchDataAllCandidate={refetchData}
            setType={(row) => {
              setInterviewRO((el) => ({
                ...el,
                name: row,
              }));
            }}
          />
        </ModalWrapper>
      ) : null}

      {showInterviewRO?.name === "LOLOS" ||
      showInterviewRO?.name === "TIDAK LOLOS" ? (
        <ModalWrapper show={showInterviewRO} handleClose={() => {}}>
          <EvaluationFormModal
            data={showInterviewRO}
            handleClose={() => setInterviewRO(null)}
            setSelectedButtonStepRecruitment={setSelectedButtonStepRecruitment}
            refetchDataAllCandidate={refetchData}
            isReject={showInterviewRO?.name === "TIDAK LOLOS"}
          />
        </ModalWrapper>
      ) : null}

      {showModalDone && (
        <RecruiterDoneModal
          handleDone={() => {
            setShowModalDone(false);
          }}
          title={"Berhasil Mengalihkan Kandidat"}
          description={doneText}
          showButton={true}
          titleButton={"Tutup"}
          show={showModalDone}
        />
      )}

      {showInterviewRO?.name === "Public" ||
      showInterviewRO?.name === "Private" ? (
        <RecruiterApprovalModal
          handleDone={() => {
            setShowSnackDone({
              id: showInterviewRO?.data?.candidateId,
              name: showInterviewRO?.data?.name,
              ExpectedPosition: {
                name: showInterviewRO?.data?.position,
              },
              type: showInterviewRO?.name,
            });
            setInterviewRO(null);
          }}
          onCancel={() => {
            setInterviewRO(null);
          }}
          show={showInterviewRO}
          data={{
            id: showInterviewRO?.data?.candidateId,
            name: showInterviewRO?.data?.name,
            ExpectedPosition: {
              name: showInterviewRO?.data?.position,
            },
          }}
          type={showInterviewRO?.name}
        />
      ) : null}

      {showSnackDone && (
        <SuccessApprovalModal
          message={`Kandidat ${showSnackDone.name} berhasil dialihkan ke daftar kandidat ${showSnackDone?.type}`}
          handleDone={() => {
            setInterviewRO(null);
            setShowSnackDone(null);
            handleSubmitPublicOrPrivate();
          }}
          onBack={() => {
            setInterviewRO(null);
            setShowSnackDone(null);
          }}
          cancelType={"button"}
        />
      )}

      {successModal && (
        <DoneModal
          title={"Terkirim!"}
          description={
            successModal?.name === "RO"
              ? "Berhasil Mengirim Undangan ke Kandidat"
              : successModal?.name === "CLIENT"
              ? "Berhasil Mengirim Undangan ke Kandidat"
              : "Berhasil Mengalihkan Kandidat"
          }
          show={successModal}
          doneButton={true}
          doneTitle={"Ok"}
          handleDone={() => {
            setSuccessModal(null);
            // handleClose();
          }}
        />
      )}

      {showStepBack?.status === "Revert" && (
        <ModalWrapper show={showStepBack} handleClose={() => {}}>
          <RevertModal
            data={showStepBack}
            handleClose={() => setShowStepBack(null)}
            setSuccessBackStep={setSuccessBackStep}
            setShowStepBack={setShowStepBack}
            refetchDataCandidate={refetchData}
          />
        </ModalWrapper>
      )}

      {showStepBack?.status === "Absent" && (
        <ModalWrapper show={showStepBack} handleClose={() => {}}>
          <RevertModal
            data={showStepBack}
            handleClose={() => setShowStepBack(null)}
            setSuccessBackStep={setSuccessBackStep}
            setShowStepBack={setShowStepBack}
            refetchDataCandidate={refetchData}
            isAbsent={true}
          />
        </ModalWrapper>
      )}

      {showStepBack?.status === "Kirim Ulang Undangan" && (
        <ModalWrapper show={showStepBack} handleClose={() => {}}>
          <RevertModal
            data={showStepBack}
            handleClose={() => setShowStepBack(null)}
            setSuccessBackStep={setSuccessResend}
            setShowStepBack={() => {}}
            refetchDataCandidate={refetchData}
            isResend={true}
          />
        </ModalWrapper>
      )}

      <div
        className={`${Styles.notification} ${
          successBackStep ? Styles.show : ""
        }`}
      >
        <Icon icon={"check-circle-2"} size={20} color={"#fff"} />
        {showStepBack?.status === "DONE-ABSENT"
          ? `Kandidat ${showStepBack?.data?.name} berhasil ditandai tidak hadir proses interview RO`
          : `Kandidat ${showStepBack?.data?.name} berhasil dipindahkan ke step
        ${showStepBack?.step}: ${showStepBack?.name}`}
      </div>

      <div
        className={`${Styles.notification} ${successResend ? Styles.show : ""}`}
      >
        <Icon icon={"check-circle-2"} size={20} color={"#fff"} />
        Link berhasil dikirim
      </div>

      <div className={`${Styles.notification} ${showNotif ? Styles.show : ""}`}>
        <Icon icon={"check-circle-2"} size={20} color={"#fff"} />
        Data berhasil disalin
      </div>
    </div>
  );
}
