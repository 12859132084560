import PerArea from "./PerArea";
import Styles from "./style.module.scss";

export default function ManPowerSection({ data, qualificationList }) {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Man Power</h3>
      </div>
      <div className={Styles.content}>
        <div className={Styles.areas}>
          {data?.order?.manPower?.map((each, i) => (
            <PerArea
              key={i}
              data={each}
              qualificationList={qualificationList}
            />
          ))}
        </div>
        <div className={Styles.remark}>
          <h4>Remark</h4>
          <div className={Styles.box}>
            <p>{data?.order?.remark || "-"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
