import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";

export default function Checkbox({
  isChecked,
  setIsChecked,
  type,
  setSelectedCandidates,
  data,
  isError,
}) {
  if (type === "recommendad") {
    return (
      <button
        onClick={() => {
          setIsChecked(!isChecked);
          if (isChecked === true) {
            setSelectedCandidates([]);
          } else {
            setSelectedCandidates(data);
          }
        }}
        className={`${Styles.containerRecommended} ${
          isChecked ? Styles.checked : ""
        }`}
      >
        {isChecked && <Icon icon="check" size={15} />}
      </button>
    );
  }
  if (type === "job-post") {
    return (
      <button
        onClick={() => setIsChecked(!isChecked)}
        className={`${Styles.containerJobPost} ${
          isChecked ? Styles.checked : ""
        } ${isError && Styles.error}`}
      >
        {isChecked && <Icon icon="check" size={15} />}
      </button>
    );
  }
  return (
    <button
      onClick={() => setIsChecked(!isChecked)}
      className={`${Styles.container} ${isChecked ? Styles.checked : ""}`}
    >
      {isChecked && <Icon icon="check" size={15} />}
    </button>
  );
}
