import React from "react";
import Styles from "./style.module.scss";
import { Skeleton as SkeletonMUI } from "@mui/material";

const Skeleton = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <SkeletonMUI className={Styles.profilePicture} variant="circular" />
        <div className={Styles.headerContent}>
          <SkeletonMUI className={Styles.title}  />
          <SkeletonMUI className={Styles.information}  />
        </div>
      </div>
      <div className={Styles.body}>
        <SkeletonMUI className={Styles.title}/>
        <div className={Styles.content}>
          <SkeletonMUI className={Styles.contentLeft}/>
          <SkeletonMUI className={Styles.contentRight}/>
        </div>
        <div className={Styles.content}>
          <SkeletonMUI className={Styles.contentLeft}/>
          <SkeletonMUI className={Styles.contentRight}/>
        </div>
        <div className={Styles.content}>
          <SkeletonMUI className={Styles.contentLeft}/>
          <SkeletonMUI className={Styles.contentRight}/>
        </div>
        <div className={Styles.content}>
          <SkeletonMUI className={Styles.contentLeft}/>
          <SkeletonMUI className={Styles.contentRight}/>
        </div>
      </div>
      <div className={Styles.body}>
        <SkeletonMUI className={Styles.title}/>
        <div className={Styles.content}>
          <SkeletonMUI className={Styles.contentLeft}/>
          <SkeletonMUI className={Styles.contentRight}/>
        </div>
      </div>
    </div>
  )
};

export default Skeleton;