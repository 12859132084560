/* eslint-disable eqeqeq */
import { useEffect, useRef, useState } from 'react';
import Styles from './style.module.scss'
import Icon from '@Atom/Icon';
import useWindowSize from '@Hooks/useWindowSize';
import Images from '@Theme/Images';
import { makeRandomString } from '@Helpers/makeRandomString';
import { Card, RightColumn } from '../Second';
import InputField from '@Molecule/InputField';
import DropdownField from '@Molecule/DropdownField';
import InputYearField from '@Molecule/InputYearField';

export default function ThirdStep({
  educationData,
  setEducationData = () => { },
  stepperHeight = '',
  errorKeys = []
}) {

  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef()

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  const handleChangeEducationData = (id, name, value) => {
    const newData = educationData?.map(obj => {
      if (obj?.id === id) {
        return {
          ...obj,
          [name]: value
        }
      }
      return obj
    })
    setEducationData(newData)
  }

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  const { width } = useWindowSize()

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <h3 ref={headerRef} style={{
        position: width > 768 ? 'sticky' : 'absolute',
        top: 0
        // top: `calc(${stepperHeight}px)`,
      }}>
        Data Pendidikan
      </h3>
      <div className={Styles.content}>
        <div className={Styles.left}>
          <LogoCard
            img={Images.EDUCATION_BANNER}
            addTitle={'Data Pendidikan'}
            onAdd={() => {
              const newEducationData = [...educationData, {
                id: makeRandomString(5),
                educationType: '',
                yearFrom: '',
                yearTo: '',
                institutionName: '',
                facultyName: '',
                city: '',
                certificateNumber: '',
                gpa: ''
              }]
              setEducationData(newEducationData)
            }}
            headerHeight={headerHeight}
            stepperHeight={stepperHeight}
          />
        </div>
        <RightColumn className={Styles.right}>
          {!educationData?.length
            ?
            <EmptyPlaceholder
              title={'Belum ada Data Pendidikan yang Ditambahkan'}
              desc={'Tambahkan data pendidikan anda, klik pada tombol tambah data pendidikan'}
              img={Images.EDUCATION_BANNER}
            />
            :
            educationData?.map((ed, eI) => (
              <Card key={eI} className={Styles.card}>
                {
                  width <= 768
                  &&
                  <span>{`Data Pendidikan ${eI + 1}`}</span>
                }
                <DropdownField
                  title={'Jenis Pendidikan'}
                  value={ed?.educationType}
                  setValue={(newVal) => handleChangeEducationData(ed?.id, 'educationType', newVal)}
                  placeholder={'Pilih jenis pendidikan'}
                  options={[
                    'FORMAL',
                    'NON FORMAL'
                  ]
                    ?.map(obj => {
                      return {
                        name: obj
                      }
                    })
                  }
                  autoClose
                  required
                  isError={errorKeys?.find(obj => obj?.split('-')[0] === 'educationType' && obj?.split('-')[1] == eI)}
                  errorText='Jenis Pendidikan belum diisi'
                />
                <div className={Styles.grid}>
                  <InputYearField
                    title={'Dari Tahun'}
                    value={ed?.yearFrom}
                    setValue={(newVal) => handleChangeEducationData(ed?.id, 'yearFrom', newVal)}
                    placeholder={"Pilih tahun"}
                    iconColor={"#1571DE"}
                    disabled={!ed?.educationType}
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'yearFrom' && obj?.split('-')[1] == eI) && !ed?.yearFrom}
                    errorText='Tahun Mulai belum diisi'
                  />
                  <InputYearField
                    title={'Sampai Tahun'}
                    value={ed?.yearTo}
                    setValue={(newVal) => handleChangeEducationData(ed?.id, 'yearTo', newVal)}
                    placeholder={"Pilih tahun"}
                    iconColor={"#1571DE"}
                    disabled={!ed?.educationType}
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'yearTo' && obj?.split('-')[1] == eI) && !ed?.yearTo}
                    errorText='Tahun Selesai belum diisi'
                  />
                  <InputField
                    title={'Nama Penyelenggara'}
                    value={ed?.institutionName?.toUpperCase()}
                    onChange={(e) => handleChangeEducationData(ed?.id, 'institutionName', e?.target?.value)}
                    placeholder={'Penyelenggara'}
                    disabled={!ed?.educationType}
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'institutionName' && obj?.split('-')[1] == eI) && !ed?.institutionName}
                    errorText='Nama Penyelenggara belum diisi'
                  />
                  <InputField
                    title={ed?.educationType === 'NON FORMAL' ? 'Nama Kursus' : 'Fakultas/Jurusan'}
                    value={ed?.facultyName?.toUpperCase()}
                    onChange={(e) => handleChangeEducationData(ed?.id, 'facultyName', e?.target?.value)}
                    placeholder={ed?.educationType === 'NON FORMAL' ? 'Judul kursus yang diikuti' : 'Fakultas/jurusan'}
                    disabled={!ed?.educationType}
                    // required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'facultyName' && obj?.split('-')[1] == eI) && !ed?.facultyName}
                    errorText={`${ed?.educationType === 'NON FORMAL' ? 'Nama Kursus' : 'Fakultas/Jurusan'} belum diisi`}
                  />
                  {
                    ed?.educationType === 'FORMAL'
                    &&
                    <InputField
                      title={'IPK/NEM'}
                      value={ed?.gpa}
                      onChange={(e) => handleChangeEducationData(ed?.id, 'gpa', e?.target?.value)}
                      placeholder={'0'}
                      disabled={!ed?.educationType}
                      // required
                      isError={errorKeys?.find(obj => obj?.split('-')[0] === 'gpa' && obj?.split('-')[1] == eI) && !ed?.gpa}
                      errorText='IPK/NEM belum diisi'  
                    />
                  }

                  <InputField
                    title={'Kota'}
                    value={ed?.city?.toUpperCase()}
                    onChange={(e) => handleChangeEducationData(ed?.id, 'city', e?.target?.value)}
                    placeholder={'Tulis nama kota'}
                    disabled={!ed?.educationType}
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'city' && obj?.split('-')[1] == eI) && !ed?.city}
                    errorText='Kota belum diisi'  
                  />
                  <InputField
                    title={ed?.educationType === 'NON FORMAL' ? 'Kode Sertifikat' : 'Nomor Ijazah'}
                    value={ed?.certificateNumber}
                    onChange={(e) => handleChangeEducationData(ed?.id, 'certificateNumber', e?.target?.value)}
                    placeholder={ed?.educationType === 'NON FORMAL' ? 'Kode sertifikat' : '0'}
                    disabled={!ed?.educationType}
                    // required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'certificateNumber' && obj?.split('-')[1] == eI) && !ed?.certificateNumber}
                    errorText={`${ed?.educationType === 'NON FORMAL' ? 'Kode Sertifikat' : 'Nomor Ijazah'} belum diisi`} 
                  />

                </div>
                <DeleteButton
                  onDelete={() => setEducationData(prev => prev?.filter(obj => obj?.id !== ed?.id))}
                />
              </Card>
            ))
          }
        </RightColumn>
      </div>

    </div>
  )
}

export const LogoCard = ({
  img = '',
  addTitle,
  onAdd,
  headerHeight,
  stepperHeight,
  noAddButton = false
}) => {
  return (
    <div className={Styles.logoCard} style={{
      //  top: `calc(${headerHeight + stepperHeight}px)`,
      top: `calc(${headerHeight}px)`,
    }}>
      <img src={img} alt='' />
      {!noAddButton
        &&
        <div className={Styles.buttonWrapper}>
          <button onClick={onAdd}>
            <Icon icon={'add-circle'} size={24} />
            <span>{addTitle}</span>
          </button>

        </div>

      }
    </div>)
}

export const EmptyPlaceholder = ({
  title,
  desc,
  img
}) => {
  const { width } = useWindowSize()

  return (
    <div className={Styles.emptyPlaceholder}>
      {width <= 768
        &&
        <img src={img} alt='' />
      }
      <span>{title}</span>
      <p>{desc}</p>
    </div>
  )
}

export const DeleteButton = ({
  onDelete
}) => {

  return (
    <button onClick={onDelete} className={Styles.deleteButton}>
      <Icon icon={'delete'} size={20} />
      <span>Hapus</span>
    </button>
  )
}