/* eslint-disable eqeqeq */
import ModalWrapper from '@Atom/ModalWrapper'
import Rotation from '@Atom/Rotation'
import { updateVacantQuota } from '@Services/client/dashboard'
import { useEffect, useMemo, useState } from 'react'
import RequestSuccessModal from '../RequestSuccessModal'
import Styles from './style.module.scss'

export default function UpdateVacantQuotaModal({
  data,
  position,
  show,
  onClose = () => { },
  onRenew = () => { }
}) {

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [regionsData, setRegionsData] = useState(data?.regions)
  const [isSuccess, setIsSuccess] = useState(false)


  useEffect(() => {
    setRegionsData(data?.regions)
  }, [data?.regions])

  const handleChangeRegionsData = (id, name, value) => {
    setRegionsData(regionsData?.map(obj => {
      if (obj?.id === id) {
        return {
          ...obj,
          [name]: value
        }
      } return obj
    }))
  }

  const changedRegionsData = useMemo(() => {
    return regionsData?.filter(obj => {
      return !data?.regions?.some(d => {
        return obj?.request == d?.request && obj?.vacant == d?.vacant
      }
      )
    }
    )
  }, [data?.regions, regionsData])

  const disableSubmit = useMemo(() => {
    return isSubmitting
  }, [isSubmitting])


  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      if (changedRegionsData?.length) {
        const form = new URLSearchParams()
        for (let i = 0; i < changedRegionsData?.length; i++) {
          // form?.append(`manPowers[${i}][id]`, changedRegionsData[i]?.id)
          form?.append(`manPowers[${i}][id]`, (Number.isFinite(+changedRegionsData[i]?.id) ? changedRegionsData[i]?.id : null))
          form?.append(`manPowers[${i}][quota]`, changedRegionsData[i]?.request)
          form?.append(`manPowers[${i}][vacant]`, changedRegionsData[i]?.vacant)
          form?.append(`manPowers[${i}][city]`, changedRegionsData[i]?.cityName)
          form?.append(`manPowers[${i}][position]`, position)
        }
        await updateVacantQuota(form)
      }
      setIsSubmitting(false)
      setIsSuccess(true)
    } catch (err) {
      console.log(err)
    }
  }

  if (isSuccess) {
    return (
      <RequestSuccessModal
        show={isSuccess}
        title='Update Berhasil'
        description='Update vacant/Kuota telah berhasil di simpan.'
        closeButtonText='Tutup'
        onClose={() => {
          setIsSuccess(false)
          onRenew()
          onClose()
        }}
      />
    )

  }


  return (
    <ModalWrapper show={show} handleClose={!isSuccess ? onClose : () => {
      setIsSuccess(false)
      onRenew()
      onClose()
    }}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.hLeft}>
            <h3>Update <span>Vacant</span> & Kuota</h3>
            <span>{data?.positionName}</span>
          </div>
          <div className={Styles.hRight}>
            <div>
              <span>Total Kuota</span>
              <p>{data?.quota || 0}</p>
            </div>
            <div>
              <span>Total Vacant</span>
              <p>{data?.vacant || 0}</p>
            </div>
          </div>
        </div>
        <div className={Styles.content}>
          <div className={Styles.cHeader}>
            <div>Kota</div>
            <div>Kuota</div>
            <div>Vacant</div>
          </div>
          <div className={Styles.cBody}>
            {regionsData?.map((r, rI) => (
              <div key={rI} className={Styles.cBRow}>
                <div>{r?.cityName}</div>
                <input
                  type='number'
                  value={r?.request}
                  onChange={(e) => handleChangeRegionsData(r?.id, 'request', e?.target?.value)}
                />
                <input
                  type='number'
                  value={r?.vacant}
                  onChange={(e) => handleChangeRegionsData(r?.id, 'vacant', e?.target?.value)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={Styles.actions}>
          <button onClick={onClose}>
            Batal
          </button>
          <button disabled={disableSubmit} onClick={handleSubmit}>
            {
              isSubmitting
                ?
                <Rotation width='20px' type='white' />
                :
                <span>Simpan</span>
            }
          </button>
        </div>
      </div>
    </ModalWrapper>
  )
}