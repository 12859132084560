// import { de } from 'date-fns/locale'
import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/api";
import Styles from "./style.module.scss";

// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import ModalWrapper from "@Atom/ModalWrapper";
import JobPostingLinkModal from "@Molecule/_modal/JobPostingLinkModal";
import { rupiahFormat } from "@Helpers/formatRupiah";
import { sendLinkJobPost } from "@Services/officer/jobPosting";
import Spinner from "@Atom/Spinner";
import useWindowSize from "@Hooks/useWindowSize";

export default function JobSeekerCard({ data, isLoadingData }) {
  const [showModalLink, setShowModalLink] = useState(null);
  const maxLength = 20;
  const maxLengthAddress = 40;

  const startIndex = 0;
  const endIndex = 1;

  const { width } = useWindowSize();

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  useEffect(() => {
    if (showModalLink) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [showModalLink]);

  function changeFormatPlacement(str) {
    return str.replaceAll("::", ", ");
  }

  const [isLoadingCopyLink, setIsLodingCopyLink] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e, location, id) => {
    e.preventDefault();
    try {
      setIsLodingCopyLink(id);
      const response = await sendLinkJobPost({
        jobPostingID: id,
        city: location || "",
        type: "Normal",
        page: "Landing",
        recruiterID: "",
      });

      if (response?.code === 200) {
        if (width > 768) {
          window.open(response?.response);
        } else {
          window.location.href = response?.response;
        }

        setIsLodingCopyLink(null);
        setError(null);
      }
    } catch (error) {
      console.log(error);
      setIsLodingCopyLink(null);
      setError({
        error: error?.response?.data?.error,
        id: id,
      });
    }
  };

  return (
    <>
      {data?.data?.length === 0 && (
        <div className={Styles.emptyData}>
          <div className={Styles.boxEmptyText}>
            <span className={Styles.warn}>Data Kosong</span>
            <span className={Styles.descWarn}>
              Tidak ada data atau data belum ada
            </span>
          </div>
        </div>
      )}
      {data?.data?.length > 0 && (
        <>
          {isLoadingData ? (
            <div className={Styles.emptyData}>
              <div className={Styles.boxEmptyText}>
                <span className={Styles.warn}>Loading...</span>
                <span className={Styles.descWarn}>
                  Mohon tunggu data sedang dimuat
                </span>
              </div>
            </div>
          ) : (
            <div className={Styles.bodyJobSeakerCard}>
              {data?.data?.map((el) => {
                return (
                  <div className={Styles.card} key={el?._id}>
                    <div className={Styles.imagesBox}>
                      <img
                        src={
                          fileBaseUrl +
                          el?.medias?.find((el) => el?.type === "Poster")?.uri
                        }
                        alt=""
                      />
                      {/* <LazyLoadImage
                        src={
                          fileBaseUrl +
                          el?.medias?.find((el) => el?.type === "Poster")?.uri
                        }
                        // PlaceholderSrc={PlaceholderImage}
                        effect="blur"
                      /> */}
                    </div>
                    <div className={Styles.titleJobs}>
                      <div className={Styles.titleBox}>
                        <span className={Styles.job}>Pekerjaan</span>
                        <Tooltip title={`${el?.title}`}>
                          <span className={Styles.title}>
                            {el?.title
                              ? el?.title.length > maxLength
                                ? el?.title.substring(0, maxLength) + "..."
                                : el?.title
                              : "-"}
                          </span>
                        </Tooltip>
                      </div>
                      <div className={Styles.addressBox}>
                        <Icon
                          icon={"send-outline"}
                          size={16}
                          color={"#1571DE"}
                        />
                        {Array.isArray(el?.placement) ? (
                          <Tooltip
                            title={
                              <span>
                                {toTitleCase(
                                  changeFormatPlacement(
                                    el?.placement
                                      ?.map((item) => item.city)
                                      .join(", ")
                                  )
                                ) || "-"}
                              </span>
                            }
                          >
                            <div className={Styles.placement}>
                              <span>
                                {el?.placement?.slice(startIndex, endIndex)
                                  ?.length > 0
                                  ? el?.placement
                                      ?.slice(startIndex, endIndex)
                                      .map((item) => item.city)
                                      .join(", ")?.length > maxLengthAddress
                                    ? toTitleCase(
                                        changeFormatPlacement(
                                          el?.placement
                                            ?.slice(startIndex, endIndex)
                                            .map((item) => item.city)
                                            .join(", ")
                                        )
                                      )?.substring(0, maxLengthAddress) + "..."
                                    : toTitleCase(
                                        changeFormatPlacement(
                                          el?.placement
                                            ?.slice(startIndex, endIndex)
                                            .map((item) => item.city)
                                            .join(", ")
                                        )
                                      )
                                  : "-"}
                              </span>

                              {el?.placement?.length > 1 ? (
                                <span className={Styles.slice}>
                                  +{el?.placement?.slice(endIndex)?.length}
                                </span>
                              ) : null}
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              <span>
                                {toTitleCase(
                                  changeFormatPlacement(el?.placement)
                                )}
                              </span>
                            }
                          >
                            <span className={Styles.addressText}>
                              {" "}
                              {el?.placement
                                ? el?.placement.length > maxLengthAddress
                                  ? toTitleCase(
                                      changeFormatPlacement(el?.placement)
                                    ).substring(0, maxLengthAddress) + "..."
                                  : toTitleCase(
                                      changeFormatPlacement(el?.placement)
                                    )
                                : "-"}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                      <div className={Styles.salaryBox}>
                        <Icon icon={"money"} color={"#27BB83"} size={16} />

                        <span>
                          {el?.salaryRange?.length &&
                          el?.salaryRange?.length == 2
                            ? `${rupiahFormat(
                                el?.salaryRange[0]
                              )} - ${rupiahFormat(el?.salaryRange[1])}`
                            : el?.salaryRange?.length &&
                              el?.salaryRange?.length == 1
                            ? `${rupiahFormat(el?.salaryRange[0])}`
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div className={Styles.buttonBox}>
                      {/* <span className={Styles.error}>*test</span> */}
                      {error?.id === el?._id && (
                        <span className={Styles.error}>
                          *{error?.error || "-"}
                        </span>
                      )}
                      <div
                        onClick={() => {
                          if (isLoadingCopyLink === el?._id) {
                            return;
                          } else {
                            if (el?.placement?.length > 1) {
                              setShowModalLink({
                                isShow: true,
                                name: "Normal",
                                data: el,
                              });
                            } else {
                              // Create a synthetic event to simulate a form submission
                              const syntheticEvent = new Event("submit", {
                                bubbles: true,
                                cancelable: true,
                              });

                              // Call handleSubmit with the synthetic event and the city location
                              handleSubmit(
                                syntheticEvent,
                                el?.placement[0]?.city,
                                el?._id
                              );
                            }
                          }
                        }}
                        // target="_blank"
                        // rel="noopener noreferrer"
                        className={Styles.buttonSection}
                      >
                        {isLoadingCopyLink === el?._id ? (
                          <Spinner />
                        ) : (
                          "Lamar Sekarang"
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}

      {showModalLink && (
        <ModalWrapper
          show={showModalLink}
          handleClose={() => setShowModalLink(null)}
          isLandingPage
        >
          <JobPostingLinkModal
            data={showModalLink}
            handleClose={() => setShowModalLink(null)}
          />
        </ModalWrapper>
      )}
    </>
  );
}
