import LoadingAnimation from "@Atom/LoadingAnimation";
import ClientOrderDetailsContent from "@Organism/Client/Orders/OrderDetails/Content";
import ClientOrderDetailsNavigator from "@Organism/Client/Orders/OrderDetails/Navigator";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { clientOrderSync } from "@Services/client/order";
import { getSchemeDetail } from "@Services/manager/client";
import { Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./style.module.scss";

export async function loader({ params }) {
  const id = params.id;
  const schemeDetailPromise = getSchemeDetail(id);
  const orderSyncResult = clientOrderSync();

  return defer({
    packageSchemeDetail: schemeDetailPromise,
    packageOrderSync: orderSyncResult,
  });
}

export default function ClientOrderDetails() {
  const { packageSchemeDetail, packageOrderSync } = useLoaderData();

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Scheme Details</title>
      </Helmet>
      <div className={Styles.navigatorWrapper}>
        <ClientOrderDetailsNavigator />
      </div>

      <div className={Styles.contentWrapper}>
        <Suspense
          fallback={
            <div className={Styles.loadingWrapper}>
              <LoadingAnimation />
            </div>
          }
        >
          <Await
            resolve={packageSchemeDetail}
            errorElement={
              <div style={{ height: "calc(100vh - 220px)" }}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageSchemeDetail) => (
              <Suspense
                fallback={
                  <div className={Styles.loadingWrapper}>
                    <LoadingAnimation />
                  </div>
                }
              >
                <Await resolve={packageOrderSync}>
                  {(packageOrderSync) => (
                    <ClientOrderDetailsContent
                      data={packageSchemeDetail?.response}
                      qualifications={
                        packageOrderSync?.response?.qualifications
                      }
                    />
                  )}
                </Await>
              </Suspense>
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}
