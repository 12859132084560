import moment from "moment";
import CostEstimationSection from "./CostEstimation";
import InformationDataSection from "./InformationData";
import ManPowerSection from "./ManPower";
import TaxAndBPJSSection from "./TaxAndBPJS";
import Styles from "./style.module.scss";
import ContactSection from "./Contacts";

export default function ClientOrderDetailsContent({ data, qualifications }) {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.stackedInfo}>
          <span>Tanggal:</span>
          <span>{moment(new Date(data?.dateProject))?.format("LL")}</span>
        </div>
        <div className={Styles.stackedInfo}>
          <span>Projek:</span>
          <div className={Styles.address}>
            <span>{data?.order?.Client?.name}</span>
            <span>{data?.order?.Client?.address}</span>
          </div>
        </div>
        <div className={Styles.stackedInfo}>
          <span>Model Kerjasama:</span>
          <span className={Styles.bold}>{data?.cooperationModels}</span>
        </div>
      </div>

      <div className={Styles.content}>
        <InformationDataSection data={data} />
        <TaxAndBPJSSection data={data} />
        <ManPowerSection data={data} qualificationList={qualifications} />
        <CostEstimationSection data={data} />
        <ContactSection data={data} />
      </div>
    </div>
  );
}
