/*eslint-disable no-useless-escape */

import Textarea from "@Atom/Textarea";
import Styles from "./style.module.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";

export default function RemarkSection(props) {
  const { remark, setRemark } = props;

  const [temp, setTemp] = useState(remark);

  const onChange = (e) => {
    setTemp(
      e.target.value.replace(
        /[^0-9/\[\]\\_+,.@!`~#%$*&()={}|:;"'<,>?\-\n a-zA-Z]+/gi,
        "-"
      )
    );
  };

  const reRemark = useCallback(() => {
    setRemark(temp);
  }, [temp, setRemark]);

  const debounceRemark = useMemo(() => debounce(reRemark, 500), [reRemark]);

  useEffect(() => {
    debounceRemark();
  }, [temp, debounceRemark]);

  return (
    <div className={Styles.container}>
      <h3>Remark</h3>
      <div className={Styles.content}>
        <div className={Styles.wrapper}>
          <Textarea
            placeholder={"Description"}
            value={temp}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}
