/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import AutocompleteV2 from "@Atom/AutocompleteV2";
import Styles from "./styles.module.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import JobSeekerCard from "@Molecule/JobSeakerCard";
// import POSTER from "@Assets/Images/870-smd-nita-31 okt.png";
// import POSTER_SECOND from "@Assets/Images/poster-example.png";
// import POSTER_THIRD from "@Assets/Images/BENEFIT_IMAGE_1.png";
import JobSeekerList from "@Molecule/JobSeekerList";
import Pagination from "@Atom/PaginationJobSeeker";
import HomeFooter from "@Organism/Home/Business/HomeFooter";
import { getListJobSeeker } from "@Services/client/jobSeeker";
import FilterJobSeekerList from "@Molecule/FilterJobSeekerList";
import { useDebounce } from "@Hooks/useDebounce";
import { useLocation, useNavigate } from "react-router-dom";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import NonLinearSlider from "@Atom/RangePriceSlider";
import InputSearchJob from "@Atom/InputSearchJob";
import useQuery from "@Hooks/useQuery";
import ModalFilterJobSeeker from "@Molecule/_modal/ModalFilterJobSeeker";
import AutocompleteV3 from "@Atom/AuroCompleteV3";
// import BACKGROUND_HEADER from "@Assets/Images/background-job-seaker.png";

const TipeKontrak = [
  { id: 1, name: "PKWT", code: "pkwt" },
  { id: 2, name: "PKM", code: "pkm" },
];

const experiences = [
  {
    id: 1,
    name: "Fresh Graduate",
    code: "Fresh Graduate",
  },
  {
    id: 2,
    name: "> 1 tahun",
    code: "> 1 tahun",
  },
  {
    id: 3,
    name: "> 3 tahun",
    code: "> 3 tahun",
  },
  {
    id: 4,
    name: "> 4 tahun",
    code: "> 4 tahun",
  },
];

export default function JobSeakerDetail({ initialData }) {
  const sessionSelectedViewJobSeaker = sessionStorage.getItem(
    "idButtonViewJobPosting"
  );
  const location = useLocation();
  const searchTerm = location?.state || "";

  const [showFilter, setShowFilter] = useState(false);

  const navigate = useNavigate();
  const query = useQuery();

  const [minValue, setMinValue] = useState(100000);
  const [maxValue, setMaxValue] = useState(25000000);

  function formatNumber(num) {
    if (num >= 1e12) {
      return (num / 1e12).toFixed(2).replace(/\.?0+$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(2).replace(/\.?0+$/, "") + "M";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(2).replace(/\.?0+$/, "") + "jt";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(2).replace(/\.?0+$/, "") + "rb";
    } else {
      return num.toString();
    }
  }

  let minPrice = formatNumber(minValue);
  let maxPrice = formatNumber(maxValue);

  const templatePrice = `${minPrice} - ${maxPrice}`;

  const [selectedItems, setSelectedItems] = useState([]);

  const handleChecked = (item) => {
    let isSelected = false;
    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i] === item?.name) {
        isSelected = true;
        break;
      }
    }
    return isSelected;
  };
  const handleCheckboxChange = (item) => {
    let isSelected = handleChecked(item);
    if (isSelected) {
      setSelectedItems(
        selectedItems.filter((selected) => selected !== item.name)
      );
    } else {
      setSelectedItems([...selectedItems, item.name]);
    }
  };

  // console.log(selectedItems);

  const [selectedItemsExperiences, setSelectedItemsExperiences] = useState([]);

  const handleChekkedExperinces = (items) => {
    let isSelect = false;
    for (let i = 0; i < selectedItemsExperiences.length; i++) {
      if (selectedItemsExperiences[i] === items?.name) {
        isSelect = true;
        break;
      }
    }
    return isSelect;
  };

  const handleCheckboxChangeExperinces = (item) => {
    let isSelected = handleChekkedExperinces(item);
    if (isSelected) {
      setSelectedItemsExperiences(
        selectedItemsExperiences.filter((selected) => selected !== item?.name)
      );
      // setIsActive(false);
    } else {
      setSelectedItemsExperiences([...selectedItemsExperiences, item?.name]);
      // setIsActive(false);
    }
  };

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  const [selectedButtonView, setSelectedButtonView] = useState("Grid");
  const setActiveButtonView = (idButton) => {
    setSelectedButtonView(idButton);
    sessionStorage.setItem("idButtonViewJobPosting", idButton);
  };

  useEffect(() => {
    if (sessionSelectedViewJobSeaker) {
      setSelectedButtonView(sessionSelectedViewJobSeaker);
    } else {
      setSelectedButtonView("Grid");
    }
  }, [sessionSelectedViewJobSeaker]);

  // open modal detail
  const [showDetail, setShowDetail] = useState("");

  // pagination
  const [valuePage, setValuePage] = useState(18);
  const [pages, setPages] = useState(0);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  // const [searchTerm, setSearchTerm] = useState("");

  const [dataList, setDataList] = useState(initialData || null);

  const [isLoadingData, setIsLoadingData] = useState(false);

  const [valueSearchTermTitle, setValueSearchTermTitle] = useState("");
  const [valueSearchTermPlace, setValueSearchTermPlace] = useState("");
  // const [selectedPlaces, setSelectedPlaces] = useState([]);

  function handleSearch() {
    navigate(`/job-seeker`, {
      state: { valueSearchTermTitle, valueSearchTermPlace },
      // replace: true,
    });
    // setValuePlacement("");
    // setValueTitle("");
  }

  useEffect(() => {
    if (
      searchTerm?.valueTitle ||
      searchTerm?.valuePlacement ||
      searchTerm?.valueTypeContract?.length > 0 ||
      searchTerm?.valueExperiences?.length > 0 ||
      searchTerm?.minValue ||
      searchTerm?.maxValue
    ) {
      setSelectedItemsExperiences(searchTerm?.valueExperiences || []);
      setSelectedItems(searchTerm?.valueTypeContract || []);
      setMinValue(searchTerm?.minValue || 100000);
      setMaxValue(searchTerm?.maxValue || 25000000);
      setValueSearchTermPlace(searchTerm?.valuePlacement || "");
      setValueSearchTermTitle(searchTerm?.valueTitle || "");
    }
  }, [
    searchTerm?.maxValue,
    searchTerm?.minValue,
    searchTerm?.valueExperiences,
    searchTerm?.valuePlacement,
    searchTerm?.valueTitle,
    searchTerm?.valueTypeContract,
  ]);

  const handleReset = () => {
    setValueSearchTermTitle("");
    navigate(`/job-seeker`, {
      state: { valueSearchTermPlace },
      // replace: true,
    });
    // setValuePlacement("");
    // setValueTitle("");
  };

  const handleResetPlace = () => {
    setValueSearchTermPlace("");
    navigate(`/job-seeker`, {
      state: { valueSearchTermTitle },
      // replace: true,
    });
    // setValuePlacement("");
    // setValueTitle("");
  };
  const debouncedSearch = useDebounce(
    searchTerm?.valueTitle || searchTerm?.valueSearchTermTitle || "",
    300
  );
  const debouncedPlacement = useDebounce(
    searchTerm?.valuePlacement || searchTerm?.valueSearchTermPlace || "",
    300
  );
  const debouncedTypeContract = useDebounce(
    selectedItems || searchTerm?.valueTypeContract || "",
    300
  );
  const debouncedExperiences = useDebounce(
    selectedItemsExperiences || searchTerm?.valueExperiences || "",
    300
  );
  const debouncedminPrice = useDebounce(
    minValue || searchTerm?.minValue || "",
    300
  );
  const debouncedmaxPrice = useDebounce(
    maxValue || searchTerm?.maxValue || "",
    300
  );

  // console.log(maxValue);

  // console.log(selectedItems);

  const refetchData = useCallback(async () => {
    try {
      setIsLoadingData(true);
      const { response } = await getListJobSeeker(
        valuePage,
        selectedPage,
        debouncedPlacement,
        debouncedSearch ? encodeURIComponent(debouncedSearch) : "",
        debouncedTypeContract,
        debouncedExperiences,
        debouncedminPrice,
        debouncedmaxPrice
      );
      // console.log(response, "res");

      setDataList(response);
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log(err);
    }
  }, [
    valuePage,
    selectedPage,
    debouncedPlacement,
    debouncedSearch,
    debouncedTypeContract,
    debouncedExperiences,
    debouncedminPrice,
    debouncedmaxPrice,
    encodeURIComponent,
  ]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);
  // console.log(dataList);

  const handleResetLeft = () => {
    setSelectedItems([]);
    setSelectedItemsExperiences([]);
    setMinValue(100000);
    setMaxValue(25000000);
  };
  return (
    <div className={Styles.bodyJobSeaker}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <div className={Styles.headerBody}>
        <div className={Styles.top}>
          <div className={Styles.textHeaderBox}>
            <div className={Styles.text}>
              <span className={Styles.H1}>Daftar Pekerjaan Kami</span>
              <span className={Styles.p}>
                Pilih pekerjaan yang sesuai untuk karirmu
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={Styles.contentJobSeaker}>
        <div className={Styles.topContent}>
          <span className={Styles.textTitle}>Semua Daftar Pekerjaan</span>
          <div className={Styles.filterViewBox}>
            <div
              className={`${Styles.box} ${
                selectedButtonView === "Grid" && Styles.activeButton
              }`}
              onClick={() => setActiveButtonView("Grid")}
            >
              <Icon
                icon={"view-by-grid"}
                size={20}
                color={selectedButtonView === "Grid" ? "#1571de" : "black"}
              />
              <span
                className={`${Styles.text} ${
                  selectedButtonView === "Grid" && Styles.activeText
                }`}
              >
                Grid
              </span>
            </div>
            <div
              className={`${Styles.box} ${
                selectedButtonView === "List" && Styles.activeButton
              }`}
              onClick={() => setActiveButtonView("List")}
            >
              <Icon
                icon={"view-by-list"}
                size={20}
                color={selectedButtonView === "List" ? "#1571de" : "black"}
              />
              <span
                className={`${Styles.text} ${
                  selectedButtonView === "List" && Styles.activeText
                }`}
              >
                List
              </span>
            </div>
          </div>
        </div>
        <div className={Styles.bottomContent}>
          <div className={Styles.filterSection}>
            <div className={Styles.iconFilter} onClick={handleResetLeft}>
              <div className={Styles.boxIcon}>
                <Icon icon={"adjustment"} size={20} color={"#404040"} />
                <span className={Styles.filterSpan}>Filter</span>
              </div>
              <div className={Styles.reset}>Reset</div>
            </div>
            <div className={Styles.filterType}>
              <span>Tipe Kontrak</span>
              <div className={Styles.list}>
                {TipeKontrak?.map((el) => {
                  return (
                    <div className={Styles.check} key={el?.id}>
                      <CheckboxJobPosting
                        type="radio-section"
                        checked={handleChecked(el)}
                        onChange={() => {
                          handleCheckboxChange(el);
                          // setIsActive(false);
                        }}
                      />
                      <span>{el?.name}</span>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={Styles.filterType}>
              <span>Level Pengalaman</span>
              <div className={Styles.list}>
                {experiences?.map((el) => {
                  return (
                    <div className={Styles.check} key={el?.id}>
                      <CheckboxJobPosting
                        type="radio-section"
                        checked={handleChekkedExperinces(el)}
                        onChange={() => {
                          handleCheckboxChangeExperinces(el);
                          // setIsActive(false);
                        }}
                      />
                      <span>{el?.name}</span>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={Styles.filterType}>
              <span>Range Gaji</span>
              <div className={Styles.rangePrice}>Rp{templatePrice}</div>
              <div className={Styles.slider}>
                <NonLinearSlider
                  setMax={setMaxValue}
                  setMin={setMinValue}
                  min={minValue}
                  max={maxValue}
                />
              </div>
            </div>
          </div>
          <div className={Styles.cardSection}>
            <div className={Styles.filterInTop}>
              <div className={Styles.filterStart}>
                <label>Pekerjaan</label>
                <div className={Styles.searchBox}>
                  <InputSearchJob
                    setValue={setValueSearchTermTitle}
                    value={valueSearchTermTitle}
                    type={"job-seeker"}
                    onReset={handleReset}
                  />
                </div>
              </div>

              <div className={Styles.filterStart}>
                <label>Lokasi</label>
                <div className={Styles.searchBox}>
                  <AutocompleteV2
                    type={"job-seeker-detail"}
                    placeholder={"Masukkan lokasi"}
                    options={dataList?.cities || []}
                    value={valueSearchTermPlace}
                    setValue={setValueSearchTermPlace}
                    isLoading={isLoadingData}
                    onReset={handleResetPlace}
                    // setSelectedPlaces={setSelectedPlaces}
                    // selectedPlaces={selectedPlaces}
                  />
                </div>
              </div>

              <div className={Styles.buttonSave} onClick={handleSearch}>
                <span className={Styles.searchText}>Cari Pekerjaan</span>
                <Icon icon={"search"} size={20} color={"#FFFFFF"} />
              </div>
            </div>

            {selectedButtonView === "List" && (
              <div className={Styles.boxCards}>
                <JobSeekerList
                  data={dataList}
                  showDetail={showDetail}
                  setShowDetail={setShowDetail}
                  isLoadingData={isLoadingData}
                />
              </div>
            )}

            {selectedButtonView === "Grid" && (
              <div className={Styles.boxCards}>
                <JobSeekerCard data={dataList} isLoadingData={isLoadingData} />
              </div>
            )}

            <div>
              <Pagination
                // total={10}
                totalPage={dataList?.totalPage}
                // totalData={10}
                value={valuePage}
                setValue={setValuePage}
                pages={pages}
                setPages={setPages}
                pageList={pageList}
                setPageList={setPageList}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.filterResponsive}>
        <div
          className={Styles.bodyFilter}
          onClick={() => {
            setShowFilter(true);
          }}
        >
          <Icon icon={"adjustment"} size={20} color={"#404040"} />
          <span className={Styles.filterSpan}>Filter</span>
        </div>
      </div>

      {showFilter && (
        <ModalFilterJobSeeker
          handleDone={() => setShowFilter(false)}
          options={dataList}
          handleClose={() => setShowFilter(false)}
          selectedItems={selectedItems}
          selectedItemsExperiences={selectedItemsExperiences}
          minValueTerm={minValue}
          maxValueTerm={maxValue}
          valueSearchTermTitle={valueSearchTermTitle}
          valueSearchTermPlace={valueSearchTermPlace}
        />
      )}
      <HomeFooter />
    </div>
  );
}
