import Images from "@Theme/Images";
import Styles from "./styles.module.scss";

export default function DesktopMode() {
  return (
    <div className={Styles.desktopContainer}>
      <div className={Styles.Header}>
        <img src={Images.KERJA365_LIGHT} alt="" />
        <div className={Styles.Logo}>
          <div>
            <img src={Images.PERMATA_LOGO} alt="permata-logo" />
          </div>
          <div>
            <span>Powered by</span>
            <p>Permata Indo Sejahtera</p>
          </div>
        </div>
      </div>
      <div className={Styles.content}>
        <span>Pengisian Data Hanya Dapat Dilakukan Melalui Ponsel Anda</span>
        <div>
          <img src={Images.RAFIKI_MOBILE} alt="" />
        </div>
        <span>
          Harap akses halaman ini melalui perangkat ponsel pintar anda.
          Dikarenakan pengisian surat offering saat ini hanya dapat dilakukan
          melalui browser di perangkat ponsel anda.
        </span>
      </div>
    </div>
  );
}
