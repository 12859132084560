import Icon from "@Atom/Icon";
import UseOutsideClick from "@Hooks/useOutsideClick";
import { useEffect, useRef, useState } from "react";
import { getClientProfileAsync } from "../../../../async";
import EditProfile from "./EditProfile";
import Profile from "./Profile";
import Skeleton from "./Skeleton";
import Styles from "./style.module.scss";

export default function ClientProfileModal({ handleDone }) {
  const boxRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const boxOutsideClick = UseOutsideClick(boxRef);
  const [editPofile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const accessToken = localStorage.getItem("accessToken");
  const clientId = localStorage.getItem("id");
  const isClient = localStorage.getItem("isClient") === "true" ? true : false;

  // const accessToken = sessionStorage.getItem('accessToken');
  // const clientId = sessionStorage.getItem("id");
  // const isClient = sessionStorage.getItem("isClient") === 'true' ? true : false;

  useEffect(() => {
    if (isClient) {
      (async () => {
        try {
          const { data: res } = await getClientProfileAsync(
            accessToken,
            clientId
          );

          if (res.response) {
            setLoading(false);
            setData(res.response);
          }
        } catch (error) {
          console.log(error, "error saat get client profile");
        }
      })();
    }
  }, [accessToken, clientId, editPofile, isClient]);

  // useEffect(() => {
  //   if (boxOutsideClick) {
  //     handleDone();
  //   }
  // }, [boxOutsideClick, handleDone]);

  return (
    // <div className={Styles.background}>
    <div className={Styles.container} ref={boxRef}>
      <div className={Styles.topLine} />
      <div className={Styles.header}>
        <div className={Styles.profileText}>My Profile</div>
        <div className={Styles.closeButton} onClick={handleDone}>
          <Icon className={Styles.closeIcon} icon="cross" size={24} />
        </div>
      </div>
      {editPofile ? (
        <EditProfile data={data} setEditProfile={setEditProfile} />
      ) : loading ? (
        <Skeleton />
      ) : (
        <Profile data={data} setEditProfile={setEditProfile} />
      )}
    </div>
    // </div>
  );
}
