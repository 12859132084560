import { Slider } from "@mui/material";
// import Typography from "@material-ui/core/Typography";
// import Slider from "@material-ui/core/Slider";
const prices = [
  {
    string: "100K",
    label: "100K",
    value: 0,
    scaledValue: 100000,
  },
  {
    string: "500K",
    label: "",
    value: 20,
    scaledValue: 500000,
  },
  {
    string: "1jt",
    label: "",
    value: 40,
    scaledValue: 1000000,
  },

  {
    string: "5jt",
    label: "",
    value: 60,
    scaledValue: 5000000,
  },
  {
    string: "10jt",
    label: "",
    value: 80,
    scaledValue: 10000000,
  },
  {
    string: "15jt",
    label: "",
    value: 100,
    scaledValue: 15000000,
  },
  {
    string: "20jt",
    label: "",
    value: 120,
    scaledValue: 20000000,
  },
  {
    string: "25jt",
    label: "25jt",
    value: 140,
    scaledValue: 25000000,
  },
];

const descale = (scaledValue) => {
  const priceIndex = prices.findIndex(
    (price) => price.scaledValue >= scaledValue
  );
  const price = prices[priceIndex];
  if (price.scaledValue === scaledValue) {
    return price.value;
  }
  if (priceIndex === 0) {
    return 0;
  }
  const m =
    (price.scaledValue - prices[priceIndex - 1].scaledValue) /
    (price.value - prices[priceIndex - 1].value || 1);
  const dX = scaledValue - prices[priceIndex - 1].scaledValue;
  return dX / m + prices[priceIndex - 1].value;
};

const scale = (value) => {
  const priceIndex = prices.findIndex((price) => price.value >= value);
  const price = prices[priceIndex];
  if (price.value === value) {
    return price.scaledValue;
  }
  const m =
    (price.scaledValue - prices[priceIndex - 1].scaledValue) /
    (price.value - prices[priceIndex - 1].value || 1);
  const dX = value - prices[priceIndex - 1].value;
  return m * dX + prices[priceIndex - 1].scaledValue;
};

export default function NonLinearSlider({ min, max, setMax, setMin }) {
  //   const [min, setMin] = useState(100000);
  //   const [max, setMax] = useState(25000000);
  //   const [value, setValue] = useState([1, 25]);

  return (
    <div>
      {/* {min} */}
      {/* {max} */}
      <Slider
        onChange={(e, value) => {
          setMin(scale(value[0]));
          setMax(scale(value[1]));
        }}
        value={[descale(min), descale(max) || prices.slice(-1)[0].value]}
        scale={scale}
        marks={prices}
        defaultValue={[prices[0].value, prices[prices.length - 1].value]}
        min={0}
        max={140}
      />
      {/* <Typography>Values: {JSON.stringify(scaleValues(value))}</Typography> */}
    </div>
  );
}
