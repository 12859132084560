import { API } from "@Config/api";

export function getFLBList(verified = "no", page, limit, search = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/hr-access/${verified === "no" ? "unverified" : "verified"}-flb`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
          params: { page, limit, search },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getSyncFlbList(type) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/hr-access/flb/sync?type=${type}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getDownloadReportFLBList(type, startDate, endDate) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/hr-access/flb/report?type=${type}&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// /hr-access/flb/report?type=Active&startDate=2024-08-05&endDate=2024-08-10

export function getFLBListVerif(
  limit,
  page,
  type,
  clientName,
  flbNumber,
  docNumber
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/hr-access/flb?limit=${limit}&page=${page}&type=${type}&clientName=${clientName}&flbNumber=${flbNumber}&docNumber=${docNumber}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

//
export function getFLBDetails(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/hr-access/flb/${id}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function verifyFLB(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/hr-access/verify-flb/${id}`,
        {},
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
