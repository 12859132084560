/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars*/

import { fileToBase64 } from "@Helpers/convertFileToBase64";
import { makeRandomString } from "@Helpers/makeRandomString";
import { addOrder, saveOrderAsDraft } from "@Services/manager/client";
import debounce from "lodash.debounce";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import FifthStep from "./Fifth";
import FirstStep from "./First";
import FourthRevamp from "./FourthRevamp";
import NewSixth from "./NewSixth";
import SecondStep from "./Second";
import FinishedModal from "./Seventh/FinishedModal";
import ThirdStep from "./Third";
import Styles from "./style.module.scss";
import { base64ToFile } from "@Helpers/base64ToFile";
import SendFLBModal from "@Molecule/_modal/SendFLBModal";
import { useNavigate } from "react-router-dom";
import DeploymentStep from "./Deployment";
import Icon from "@Atom/Icon";
import PositionStep from "./Position";

const bankOptions = [
  {
    name: "BCA",
    number: "1783050505",
  },
  {
    name: "BNI",
    number: "0195900064",
  },
  // {
  //   name: "BNI CONTIGENCY",
  //   number: "8000177898",
  // },
  {
    name: "BRI",
    number: "037601000660300",
  },
  {
    name: "BTPN",
    number: "4843000059",
  },
  {
    name: "CIMB NIAGA",
    number: "800007679100",
  },
  {
    name: "DANAMON",
    number: "000071968697",
  },
  {
    name: "BANK DBS IND",
    number: "3320129862",
  },
  {
    name: "DKI",
    number: "41208000408",
  },
  {
    name: "MANDIRI",
    number: "1280004585938",
  },
  {
    name: "MANDIRI MCM",
    number: "0700006667153",
  },
  {
    name: "MAYBANK",
    number: "2026165210",
  },
  {
    name: "MEGA",
    number: "010940011002145",
  },
  {
    name: "MNC BANK",
    number: "100010000103026",
  },
  {
    name: "NOBU BANK",
    number: "17830003001",
  },
  {
    name: "PERMATA",
    number: "701583183",
  },
  {
    name: "SAMPOERNA",
    number: "1021555106",
  },
  {
    name: "SINARMAS",
    number: "0045791556",
  },
  {
    name: "OCBC NISP",
    number: "417800003234",
  },
];

export default function AddOrderLayout(props) {
  const {
    currentStep,
    setCurrentStep,
    setContractType,
    clientCode,
    clientName,
    options,
    qualifications,
    cities,
    users,
    banks,
    maxStep,
    setMaxStep,
    steps,
    setSteps,
    alertMessage = "",
    setAlertMessage = () => { },
    clientContacts = [],
    contractType,
    previousButtonText = '',
    nextButtonText = '',
    headerHeight
  } = props;

  const [trigger, setTrigger] = useState(null);
  const navigate = useNavigate()

  const defaultForm = JSON.parse(sessionStorage.getItem("addOrderForm"));
  const defaultContractForm = JSON.parse(
    sessionStorage.getItem("contractForm")
  )

  const [withBPJS, setWithBPJS] = useState(sessionStorage.getItem("addWithBPJS") === 'NO' ? false : true)


  const defaultContractType = useMemo(() => {
    if (defaultForm) {
      return defaultForm?.contractType;
    }
    if (defaultContractForm) {
      return defaultContractForm?.contract;
    }
  }, [defaultForm, defaultContractForm]);

  const [contractForm, setContractForm] = useState({
    contract: defaultContractForm?.contract
      ? defaultContractForm?.contract
      : null,
  });

  const defaultContracts =
    JSON.parse(sessionStorage.getItem("contracts")) || [];

  const defaultContracts2 = [...defaultContracts]

  for (let i = 0; i < defaultContracts2.length; i++) {
    const str = defaultContracts2[i]["str"]
    if (str) {
      defaultContracts2[i]["file"] = base64ToFile(str, 'file')
    }

  }

  // const [contracts, setContracts] = useState(defaultContracts?.length ? defaultContracts : [
  //   // {
  //   //   id: 1,
  //   //   docType: 'PKS',
  //   //   docNumber: '1987UHF972GF198H',
  //   //   startDate: moment(new Date())?.format('LL'),
  //   //   endDate: moment(new Date())?.format('LL'),
  //   //   file: null,
  //   //   uploadedAt: moment(new Date())?.format('LL'),
  //   //   status: ''
  //   // }
  // ])

  const [contracts, setContracts] = useState(defaultContracts2);

  useEffect(() => {
    setContractForm((prev) => {
      return {
        ...prev,
        contract: defaultContractType,
      };
    });

    setContractType(defaultContractType);
  }, [defaultContractType, setContractType]);

  const firstStepDoneIndicator = useMemo(() => {
    if (contractForm?.contract) {
      return true;
    }
    return false;
  }, [contractForm]);

  const contractTypeOptions = useMemo(() => {
    let arr = [];
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (
        option?.type === "contractTypeClient" &&
        option?.value === "Jasa Pemborongan"
      ) {
        arr.push({
          type: "PEMBORONGAN",
          title: option?.value,
          label: "PPN Dari Total Biaya",
          rules: [
            {
              id: 11,
              desc: "Status Karyawan PKWT atau Mitra",
            },
            {
              id: 12,
              desc: "PPh 21 dihitung dari penghasilan per bulan",
            },
            {
              id: 13,
              desc: "BPJS Kesehatan dihitung dari bantuan operasional (minimum UMP)",
            },
            {
              id: 14,
              desc: "BPJS Ketenagakerjaan JKK, JKM, JHT, atau JKK & JKM Dihitung dari bantuan operasional",
            },
            {
              id: 15,
              desc: "Manajemen Fee dihitung dari selisih nilai kontrak dengan biaya yang dikeluarkan",
            },
          ],
        });
      } else if (
        option?.type === "contractTypeClient" &&
        option?.value === "Penyedia Jasa Tenaga Kerja"
      ) {
        arr.push({
          type: "PJTK",
          title: option?.value,
          label: "PPN dari Manfee & PPN Fasilitas dari Total",
          rules: [
            {
              id: 21,
              desc: "Status Karyawan PKWT",
            },
            {
              id: 22,
              desc: "PPH 21 dihitung dari penghasilan yang disetahunkan",
            },
            {
              id: 23,
              desc: "BPJS Kesehatan dihitung dari Gapok + Tunjangan tetap (minimum UMP)",
            },
            {
              id: 24,
              desc: "BPJS Ketenagakerjaan JKK, JKM, JHT, JP Dihitung dari Gapok + Tunjangan tetap",
            },
            {
              id: 25,
              desc: "Manajemen Fee dihitung dari prosentase atau nominal yang disepakati",
            },
          ],
        });
      }
    }
    return arr;
  }, [options]);

  const defaultFiles = JSON.parse(sessionStorage.getItem("orderFiles"));

  const [files, setFiles] = useState([]);

  const fixedFiles = useMemo(() => {
    const arr = [];

    for (let i = 0; i < files.length; i++) {
      arr.push(files[i]);
    }
    return arr;
  }, [files]);

  const defaultWorkSchemeForm = JSON.parse(
    sessionStorage.getItem("workSchemeForm")
  );

  const [workSchemeForm, setWorkSchemeForm] = useState({
    tkoContract: defaultWorkSchemeForm?.tkoContract
      ? defaultWorkSchemeForm?.tkoContract
      : null,
    taxs: {
      pph21: defaultWorkSchemeForm?.taxs?.pph21
        ? defaultWorkSchemeForm?.taxs?.pph21
        : null,
      pph23: defaultWorkSchemeForm?.taxs?.pph23
        ? defaultWorkSchemeForm?.taxs?.pph23
        : null,
      ppn: defaultWorkSchemeForm?.taxs?.ppn
        ? defaultWorkSchemeForm?.taxs?.ppn
        : null,
    },
    bpjs: {
      registration: defaultWorkSchemeForm?.bpjs?.registration
        ? defaultWorkSchemeForm?.bpjs?.registration
        : null,
      paymentProof: defaultWorkSchemeForm?.bpjs?.paymentProof
        ? defaultWorkSchemeForm?.bpjs?.paymentProof
        : null,
      jkkOn: defaultWorkSchemeForm?.bpjs?.jkkOn
        ? defaultWorkSchemeForm?.bpjs?.jkkOn
        : false,
      jkk: defaultWorkSchemeForm?.bpjs?.jkk
        ? defaultWorkSchemeForm?.bpjs?.jkk
        : null,
      jkmOn: defaultWorkSchemeForm?.bpjs?.jkmOn
        ? defaultWorkSchemeForm?.bpjs?.jkmOn
        : false,
      jkm: defaultWorkSchemeForm?.bpjs?.jkm
        ? defaultWorkSchemeForm?.bpjs?.jkm
        : null,
      jhtOn: defaultWorkSchemeForm?.bpjs?.jhtOn
        ? defaultWorkSchemeForm?.bpjs?.jhtOn
        : false,
      jht: defaultWorkSchemeForm?.bpjs?.jht
        ? defaultWorkSchemeForm?.bpjs?.jht
        : null,
      healthOn: defaultWorkSchemeForm?.bpjs?.healthOn
        ? defaultWorkSchemeForm?.bpjs?.healthOn
        : false,
      health: defaultWorkSchemeForm?.bpjs?.health
        ? defaultWorkSchemeForm?.bpjs?.health
        : null,
      akhdkOn: defaultWorkSchemeForm?.bpjs?.akhdkOn
        ? defaultWorkSchemeForm?.bpjs?.akhdkOn
        : false,
      akhdk: defaultWorkSchemeForm?.bpjs?.akhdk
        ? defaultWorkSchemeForm?.bpjs?.akhdk
        : null,
      jpOn: defaultWorkSchemeForm?.bpjs?.jpOn
        ? defaultWorkSchemeForm?.bpjs?.jpOn
        : false,
      jp: defaultWorkSchemeForm?.bpjs?.jp
        ? defaultWorkSchemeForm?.bpjs?.jp
        : null,
      cutOffBPJSTKO: defaultWorkSchemeForm?.bpjs?.cutOffBPJSTKO
        ? defaultWorkSchemeForm?.bpjs?.cutOffBPJSTKO
        : null,
      cutOffNominal: defaultWorkSchemeForm?.bpjs?.cutOffNominal
        ? defaultWorkSchemeForm?.bpjs?.cutOffNominal
        : null,
    },
    others: {
      manfee: defaultWorkSchemeForm?.others?.manfee
        ? defaultWorkSchemeForm?.others?.manfee
        : [],
      thr: defaultWorkSchemeForm?.others?.thr
        ? defaultWorkSchemeForm?.others?.thr
        : null,
      uak: defaultWorkSchemeForm?.others?.uak
        ? defaultWorkSchemeForm?.others?.uak
        : null,
      tkoBank: defaultWorkSchemeForm?.others?.tkoBank
        ? defaultWorkSchemeForm?.others?.tkoBank
        : [],
    },
  });

  const [selectedButtonBPJS, setSelectedButtonBPJS] = useState(
    sessionStorage?.getItem("selectedButtonBPJS") || "Tenaga Kerja"
  );
  const [selectedValueBPU, setSelectedValueBPU] = useState(
    sessionStorage?.getItem("selectedValueBPU") || ""
  );
  const sessionSelectedButtonBPU = sessionStorage.getItem("idButtonTypeBPU");

  const setActiveButtonBPJS = (idButton) => {
    setSelectedButtonBPJS(idButton);
    sessionStorage.setItem("idButtonTypeBPU", idButton);
  };

  useEffect(() => {
    if (sessionSelectedButtonBPU) {
      setSelectedButtonBPJS(sessionSelectedButtonBPU);
    } else {
      setSelectedButtonBPJS("Tenaga Kerja");
    }
  }, [sessionSelectedButtonBPU]);

  const secondStepDoneIndicator = useMemo(() => {
    if (workSchemeForm?.taxs?.pph21 === null) {
      return false;
    } else if (workSchemeForm?.taxs?.pph23 === null) {
      return false;
    } else if (workSchemeForm?.taxs?.ppn === null) {
      return false;
    } else if (workSchemeForm?.others?.manfee?.length === 0) {
      return false;
    } else if (workSchemeForm?.others?.thr === null) {
      return false;
    } else if (workSchemeForm?.others?.uak === null) {
      return false;
    } else if (workSchemeForm?.others?.tkoBank?.length === 0) {
      return false;
    } else {
      return true;
    }
    // eslint-disable-next-line
  }, [workSchemeForm, trigger]);

  const fixedWorkSchemeManFee = useMemo(() => {
    let arr = [];

    const manfeeArr = workSchemeForm?.others?.manfee;
    for (let i = 0; i < manfeeArr?.length; i++) {
      const manfee = manfeeArr[i];
      arr.push(manfee?.name);
    }
    return arr.join("::");
  }, [workSchemeForm?.others?.manfee]);

  const fixedTkoBank = useMemo(() => {
    let arr = [];

    const banksArr = workSchemeForm?.others?.tkoBank;
    for (let i = 0; i < banksArr?.length; i++) {
      const bank = banksArr[i];
      arr.push(bank);
    }
    return arr.join("::");
  }, [workSchemeForm?.others?.tkoBank]);

  const fixedBpjsCut = useMemo(() => {
    return workSchemeForm?.bpjs?.cutOffBPJSTKO;
  }, [workSchemeForm?.bpjs?.cutOffBPJSTKO]);

  const workSchemeOptions = useMemo(() => {
    const arr = [];

    const taxs = [];
    const pph21 = [];
    const pph23 = [];
    const ppn = [];

    const bpjs = [];
    const registration = [];
    const paymentProof = [];
    const jkk = [];
    const jkm = [];
    const jht = [];
    const health = [];
    const akdhk = [];
    const jp = [];
    const cutOffBPJSTKO = [];

    const others = [];
    const manfee = [];
    const uak = [];
    const thr = [];
    const tkoBank = [];

    for (let i = 0; i < options?.length; i++) {
      const option = options[i];
      if (option?.type === "PPH21") {
        pph21.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "PPH23") {
        pph23.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "PPN") {
        ppn.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Pendaftaran BPJS") {
        registration.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Bukti Pendaftaran BPJS") {
        paymentProof.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "JKK") {
        jkk.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "JKM") {
        jkm.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "JHT") {
        jht.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "JP") {
        jp.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "KS") {
        health.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "AKDHK") {
        akdhk.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Pengenaan Manfee") {
        manfee.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Penagihan UAK") {
        uak.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Penagihan THR") {
        thr.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "AKDHK") {
        akdhk.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Potongan BPJS TKO") {
        cutOffBPJSTKO.push({
          id: option.id,
          name: option?.value,
        });
      }
    }

    for (let i = 0; i < banks.length; i++) {
      tkoBank.push({
        id: i,
        name: banks[i],
      });
    }

    taxs.push({
      pph21: pph21,
      pph23: pph23,
      ppn: ppn,
    });
    bpjs.push({
      registration: registration,
      paymentProof: paymentProof,
      jkk: jkk,
      jkm: jkm,
      jht: jht,
      health: health,
      akdhk: akdhk,
      jp: jp,
      cutOffBPJSTKO: cutOffBPJSTKO,
    });
    others.push({
      manfee: manfee,
      uak: uak,
      thr: thr,
      tkoBank: tkoBank,
    });

    arr.push({
      taxs: taxs[0],
      bpjs: bpjs[0],
      others: others[0],
    });

    return arr[0];
  }, [options, banks]);

  const defaultAdditionalForm = JSON.parse(
    sessionStorage.getItem("additionalForm")
  );

  useEffect(() => {
    const form = workSchemeForm;
    if (contractType?.type?.toLowerCase() === "pemborongan") {
      // setWorkSchemeForm;
      form.taxs.ppn = "Dari total";
      setWorkSchemeForm(form);
    } else {
      form.taxs.ppn = workSchemeForm?.taxs?.ppn || null;
      setWorkSchemeForm(form);
    }
  }, [contractType, workSchemeForm]);

  const [additionalForm, setAdditionalForm] = useState({
    // contactsw: [
    //   {
    //     "id": 4157,
    //     "name": "alina",
    //     "jobTitle": "hrd",
    //     "email": "alina.hrd@gmail.com",
    //     "phoneNumber": "6281286057037",
    //     "workUnit": "hrd",
    //     "clientCode": "BM-0016",
    //     "password": null,
    //     "profilePicture": null,
    //     "createdAt": "2024-02-12T06:53:06.489Z",
    //     "updatedAt": "2024-02-12T08:06:32.076Z"
    //   },
    //   {
    //     "id": 5158,
    //     "name": "AGNES",
    //     "jobTitle": "CEO",
    //     "email": "agnes@mail.com",
    //     "phoneNumber": "6285718259166",
    //     "workUnit": "test",
    //     "clientCode": "BM-0016",
    //     "password": null,
    //     "profilePicture": null,
    //     "createdAt": "2024-02-15T04:57:57.516Z",
    //     "updatedAt": "2024-02-15T04:57:57.516Z"
    //   },
    //   {
    //     "id": 5161,
    //     "name": "Andi",
    //     "jobTitle": "Developer",
    //     "email": "andi@mail.com",
    //     "phoneNumber": "6281298581781",
    //     "workUnit": "IT",
    //     "clientCode": "BM-0016",
    //     "password": null,
    //     "profilePicture": null,
    //     "createdAt": "2024-02-27T07:19:54.076Z",
    //     "updatedAt": "2024-02-27T07:19:54.076Z"
    //   },
    // ],
    contacts: defaultAdditionalForm?.contacts
      ? defaultAdditionalForm?.contacts
      : [],
    recipientsAccount: {
      bankName: defaultAdditionalForm?.recipientsAccount?.bankName
        ? defaultAdditionalForm?.recipientsAccount?.bankName
        : "OCBC NISP",
      bankNumber: defaultAdditionalForm?.recipientsAccount?.bankNumber
        ? defaultAdditionalForm?.recipientsAccount?.bankNumber
        : "417800003234",
      bankRecipient: "PT Permata Indo Sejahtera",
    },
    invoiceFlow: {
      dateOfReceiptOfData: defaultAdditionalForm?.invoiceFlow
        ?.dateOfReceiptOfData
        ? defaultAdditionalForm?.invoiceFlow?.dateOfReceiptOfData
        : null,
      UP: defaultAdditionalForm?.invoiceFlow?.UP
        ? defaultAdditionalForm?.invoiceFlow?.UP
        : null,
      dateOfFillingOfSoftCopy: defaultAdditionalForm?.invoiceFlow
        ?.dateOfFillingOfSoftCopy
        ? defaultAdditionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy
        : null,
      dateOfFillingOfHardCopy: defaultAdditionalForm?.invoiceFlow
        ?.dateOfFillingOfHardCopy
        ? defaultAdditionalForm?.invoiceFlow?.dateOfFillingOfHardCopy
        : null,
      payrollDate: defaultAdditionalForm?.invoiceFlow?.payrollDate
        ? defaultAdditionalForm?.invoiceFlow?.payrollDate
        : null,
      cutOffAttendance: defaultAdditionalForm?.invoiceFlow?.cutOffAttendance
        ? defaultAdditionalForm?.invoiceFlow?.cutOffAttendance
        : null,
      supportingDocument: defaultAdditionalForm?.invoiceFlow?.supportingDocument
        ? defaultAdditionalForm?.invoiceFlow?.supportingDocument
        : null,
    },
    // cooperationPeriod: {
    //   startDate: defaultAdditionalForm?.cooperationPeriod?.startDate
    //     ? defaultAdditionalForm?.cooperationPeriod?.startDate
    //     : null,
    //   endDate: defaultAdditionalForm?.cooperationPeriod?.endDate
    //     ? defaultAdditionalForm?.cooperationPeriod?.endDate
    //     : null,
    //   documentType: defaultAdditionalForm?.cooperationPeriod?.documentType
    //     ? defaultAdditionalForm?.cooperationPeriod?.documentType
    //     : 'PKS',
    //   documentNumber: defaultAdditionalForm?.cooperationPeriod?.documentNumber
    //     ? defaultAdditionalForm?.cooperationPeriod?.documentNumber
    //     : null,
    // },
    others: {
      deductedAttendance: defaultAdditionalForm?.others?.deductedAttendance
        ? defaultAdditionalForm?.others?.deductedAttendance
        : null,
      others: defaultAdditionalForm?.others?.others
        ? defaultAdditionalForm?.others?.others
        : null,
    },
  });

  const thirdStepDoneIndicator = useMemo(() => {
    if (!additionalForm?.cooperationPeriod?.documentNumber) {
      return false;
    } else if (!additionalForm?.cooperationPeriod?.documentType) {
      return false;
    } else if (!additionalForm?.cooperationPeriod?.startDate) {
      return false;
    } else if (!additionalForm?.cooperationPeriod?.endDate) {
      return false;
    } else if (!additionalForm?.invoiceFlow?.dateOfReceiptOfData) {
      return false;
    } else if (!additionalForm?.invoiceFlow?.UP) {
      return false;
    } else if (!additionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy) {
      return false;
    } else if (!additionalForm?.invoiceFlow?.dateOfFillingOfHardCopy) {
      return false;
    } else if (!additionalForm?.invoiceFlow?.payrollDate) {
      return false;
    } else if (!additionalForm?.invoiceFlow?.cutOffAttendance) {
      return false;
    } else if (!additionalForm?.recipientsAccount?.bankNumber) {
      return false;
    } else {
      return true;
    }
    // eslint-disable-next-line
  }, [trigger, additionalForm]);

  const defaultManPowerForm = JSON.parse(
    sessionStorage.getItem("manPowerFormAdd")
  );

  const [manPowerForm, setManPowerForm] = useState(
    defaultManPowerForm?.length > 0 ? defaultManPowerForm : []
  );

  const defaultDeploymentsData = useMemo(() => {
    return [
      {
        role: "BUSINESS MANAGER",
        required: true,
        delegations: users?.businessManagers?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
      {
        role: "ACCOUNT MANAGER / ACCOUNT OFFICER",
        delegations: users?.accountManagerOrPIC?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
      {
        role: "ADMIN / ADMIN CONTRACT",
        delegations: users?.accountOfficerOrAdmin?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
      {
        role: "RECRUITMENT OFFICER",
        delegations: users?.recruitmentOfficers?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
    ];
  }, [
    users?.accountManagerOrPIC,
    users?.accountOfficerOrAdmin,
    users?.businessManagers,
    users?.recruitmentOfficers,
  ]);

  const defaultManPowerFormArr = JSON.parse(
    sessionStorage.getItem("manPowerFormArrAdd")
  );

  const [manPowerFormArr, setManPowerFormArr] = useState(
    defaultManPowerFormArr?.length ? defaultManPowerFormArr : []
  );

  const defaultDelegationsForm = JSON.parse(
    sessionStorage.getItem("delegationsForm")
  );
  const defaultPositionsForm = JSON.parse(
    sessionStorage.getItem("positionsForm")
  );

  const [delegationsForm, setDelegationsForm] = useState(
    defaultDelegationsForm?.length ? defaultDelegationsForm : []
  );
  const [positionsForm, setPositionsForm] = useState(
    defaultPositionsForm?.length ? defaultPositionsForm : []
  );

  const mappedDelegationsForm = useMemo(() => {
    return delegationsForm?.map((obj) => {
      return {
        code: obj?.cityCode,
        delegations: obj?.deployments
          ?.map((d) =>
            d?.delegations?.map((u) => {
              return {
                ...u,
                position: d?.role,
              };
            })
          )
          ?.flat()
          ?.filter((u) => u?.isPicked),
      };
    });
  }, [delegationsForm]);

  const handleRenewPositionsForm = useCallback(() => {
    const newPositionsForm = positionsForm.slice(0);

    for (let i = 0; i < newPositionsForm?.length; i++) {
      const perPosition = newPositionsForm[i];
      const newCitiesData = delegationsForm?.map((d) => {
        const found = positionsForm
          ?.find(
            (obj) =>
              obj?.positionName?.toUpperCase() ===
              perPosition?.positionName?.toUpperCase()
          )
          ?.citiesData?.find((obj) => obj?.cityCode === d?.cityCode);
        return {
          id: found?.id || makeRandomString(5),
          isApplied: found?.isApplied || false,
          cityCode: found?.cityCode || d?.cityCode,
          cityName: found?.cityName || d?.cityName,
          umk: found?.umk || d?.umk,
          amount: found?.amount || 1,
          salaryValue: found?.salaryValue || d?.umk || 4500000,
          salaryDenom: found?.salaryDenom || "",
          personalEquipments: found?.personalEquipments || [],
          allowances: found?.allowances || [],
          jkk: found?.jkk || "",
          jkm: found?.jkm || "",
          jht: found?.jht || "",
          expectedDate: found?.expectedDate || "",
        };
      });
      perPosition.citiesData = newCitiesData;
    }
    // setPositionsForm(newPositionsForm)
  }, [delegationsForm, positionsForm]);

  useEffect(() => {
    if (
      positionsForm?.length &&
      delegationsForm?.length &&
      positionsForm[0]?.citiesData?.length !== delegationsForm?.length
    ) {
      handleRenewPositionsForm();
    }
  }, [
    delegationsForm?.length,
    handleRenewPositionsForm,
    positionsForm,
    positionsForm?.length,
  ]);

  const fourthStepDoneIndicator = useMemo(() => {
    // let temp;

    // if (manPowerForm?.length === 0) {
    //   return false;
    // } else {
    //   for (let i = 0; i < manPowerForm?.length; i++) {
    //     const perLocation = manPowerForm[i];
    //     if (perLocation?.areaWorkEquipments?.length > 0) {
    //       for (let j = 0; j < perLocation?.areaWorkEquipments?.length; j++) {
    //         if (
    //           perLocation?.areaWorkEquipments[j]?.value &&
    //           perLocation?.areaWorkEquipments[j]?.value !== 0
    //         ) {
    //           temp = true;
    //         } else {
    //           return false;
    //         }
    //       }
    //     }
    //     if (perLocation?.jobs?.length > 0) {
    //       for (let j = 0; j < perLocation?.jobs?.length; j++) {
    //         const perJob = perLocation?.jobs[j];
    //         const filterAllowences = perJob?.allowances?.filter(
    //           (el) => !el?.type
    //         );

    //         if (
    //           !perJob?.title ||
    //           !perJob?.contractType ||
    //           !perJob?.fulfillmentDate
    //         ) {
    //           return false;
    //         }

    //         if (perJob?.qualifications?.length < 1) {
    //           return false;
    //         } else {
    //           for (let k = 0; k < perJob?.qualifications?.length; k++) {
    //             if (
    //               !perJob?.qualifications[k]?.value ||
    //               perJob?.qualifications[k]?.value === 0 ||
    //               perJob?.qualifications[k]?.value === ""
    //             ) {
    //               return false;
    //             } else {
    //               temp = true;
    //             }
    //           }
    //         }

    //         for (let k = 0; k < filterAllowences?.length; k++) {
    //           if (
    //             !filterAllowences[k]?.value ||
    //             filterAllowences[k]?.value === 0 ||
    //             filterAllowences[k]?.value === "0" ||
    //             filterAllowences[k]?.value === ""
    //           ) {
    //             return false;
    //           } else {
    //             temp = true;
    //           }
    //         }

    //         for (let k = 0; k < perJob?.personalEquipments?.length; k++) {
    //           if (
    //             !perJob?.personalEquipments[k]?.value ||
    //             perJob?.personalEquipments[k]?.value === 0 ||
    //             perJob?.personalEquipments[k]?.value === "0" ||
    //             perJob?.personalEquipments[k]?.value === ""
    //           ) {
    //             return false;
    //           } else {
    //             temp = true;
    //           }
    //         }
    //       }
    //     } else {
    //       return false;
    //     }
    //   }
    // }

    // return temp;

    if (
      !manPowerFormArr?.length ||
      manPowerFormArr?.find((f) =>
        f?.deployments
          ?.find((obj) => obj?.role === "BUSINESS MANAGER")
          ?.delegations?.every((obj) => !obj?.isPicked)
      ) ||
      manPowerFormArr?.find((obj) => !obj?.positions?.length) ||
      manPowerFormArr?.find((obj) =>
        obj?.positions?.find(
          (p) =>
            !p?.contractType ||
            !p?.salaryDenom ||
            !p?.salaryValue ||
            p?.salaryValue == 0 ||
            !p?.expectedDate ||
            !p?.qualifications?.length ||
            p?.qualifications?.find((q) => !q?.value)
        )
      )
    ) {
      return false;
    } else {
      return true;
    }

    // eslint-disable-next-line
  }, [manPowerFormArr, trigger]);

  const manPowerOptions = useMemo(() => {
    const arr = [];

    const contractTypeWorker = [];
    const personalWorkEquipment = [];
    const allowance = [];
    const areaWorkEquipment = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];

      if (option?.type === "contractTypeWorker") {
        contractTypeWorker.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Personal Work Equipment") {
        personalWorkEquipment.push({
          name: option?.value,
          value: "",
        });
      } else if (option?.type === "Allowance") {
        allowance.push({
          name: option?.value,
          value: "",
        });
      } else if (option?.type === "Area Work Equipment") {
        areaWorkEquipment.push({
          name: option?.value,
          value: "",
        });
      }
    }

    arr.push({
      contractTypeWorker: contractTypeWorker,
      personalWorkEquipment: personalWorkEquipment,
      allowance: allowance,
      areaWorkEquipment: areaWorkEquipment,
    });

    return arr[0];
  }, [options]);

  const manPowerDoneIndicator = useMemo(() => {
    if (manPowerForm?.length === 0) {
      return false;
    } else {
      for (let i = 0; i < manPowerForm.length; i++) {
        const perLocation = manPowerForm[i];
        if (perLocation?.jobs?.length === 0) {
          return false;
        } else {
        }
      }
    }
  }, [manPowerForm]);

  const qualificationOptions = useMemo(() => {
    const arr = [];
    for (let i = 0; i < qualifications.length; i++) {
      const qualification = qualifications[i];

      arr.push({
        name: qualification?.value,
        denom: qualification.denom,
        value: null,
      });
    }

    return arr;
  }, [qualifications]);

  const defaultManfee = JSON.parse(sessionStorage.getItem("manfee"));
  const defaultTOP = JSON.parse(sessionStorage.getItem("TOP"));
  const defaultRemark = JSON.parse(sessionStorage.getItem("remark"));

  const [manfee, setManFee] = useState(defaultManfee || "");
  const [TOP, setTOP] = useState(defaultTOP || "");
  const [remark, setRemark] = useState(defaultRemark || "");

  const defaultDelegations = JSON.parse(sessionStorage.getItem("delegations"));

  const [delegations, setDelegations] = useState({
    businessManagers: defaultDelegations?.businessManagers || [],
    accountManagers: defaultDelegations?.accountManagers || [],
    admins: defaultDelegations?.admins || [],
    recruiters: defaultDelegations?.recruiters || [],
  });

  const fixedDelegations = useMemo(() => {
    const arr = [];

    const businessManagers = delegations?.businessManagers;
    for (let i = 0; i < businessManagers?.length; i++) {
      const bm = businessManagers[i];
      if (manPowerForm?.find((obj) => obj?.location === bm?.locationId)) {
        arr.push({
          employeeId: bm?.employeeId,
          cityCode: bm?.locationId,
        });
      }
    }

    const accountManagers = delegations?.accountManagers;
    for (let i = 0; i < accountManagers?.length; i++) {
      const am = accountManagers[i];
      if (manPowerForm?.find((obj) => obj?.location === am?.locationId)) {
        arr.push({
          employeeId: am?.employeeId,
          cityCode: am?.locationId,
          isPIC: am?.isPIC === false ? "NO" : "YES",
        });
      }
    }

    const admins = delegations?.admins;
    for (let i = 0; i < admins?.length; i++) {
      const admin = admins[i];
      if (manPowerForm?.find((obj) => obj?.location === admin?.locationId)) {
        arr.push({
          employeeId: admin?.employeeId,
          cityCode: admin?.locationId,
          isPIC: admin?.isPIC === false ? "NO" : "YES",
        });
      }
    }

    const recruiters = delegations?.recruiters;
    for (let i = 0; i < recruiters?.length; i++) {
      const ro = recruiters[i];
      if (manPowerForm?.find((obj) => obj?.location === ro?.locationId)) {
        arr.push({
          employeeId: ro?.employeeId,
          cityCode: ro?.locationId,
          isPIC: ro?.isPIC === false ? "NO" : "YES",
        });
      }
    }

    return arr;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delegations, manPowerForm, trigger]);

  const sixthStepDoneIndicator = useMemo(() => {
    if (fixedDelegations?.length > 0) {
      const cityCodeArr = fixedDelegations?.map((each) => each.cityCode);
      const locationArr = manPowerForm?.map((each) => each.location);
      return locationArr.every((element) => {
        return cityCodeArr.indexOf(element) !== -1;
      });
    }
    return false;
  }, [fixedDelegations, manPowerForm]);

  const form = useMemo(() => {
    return {
      contractType: contractForm?.contract,
      clientCode: clientCode,
      clientName: clientName,
      setManFee: manfee,
      setTOP: TOP,
      remark: remark,
      workSchemes: workSchemeForm,
      additional: additionalForm,
      manPowers: manPowerForm,
      files: files,
      delegations: fixedDelegations,
    };
  }, [
    contractForm?.contract,
    clientCode,
    clientName,
    manfee,
    TOP,
    remark,
    workSchemeForm,
    additionalForm,
    manPowerForm,
    files,
    fixedDelegations,
  ]);

  const maxStepId = useMemo(() => {
    const found = steps.find((el) => !el?.isDone);
    return found?.id;
  }, [steps]);

  const onPrevious = useCallback(() => {
    setAlertMessage("");
    setCurrentStep(+currentStep - 1);
  }, [currentStep, setAlertMessage, setCurrentStep]);

  const handleSubmit = useCallback(
    async (e, type) => {
      if (type === "send") {
        setSubmittingType("send");
        setSubmitting(true);
      } else {
        setSubmittingType("draft");
        setSubmittingDraft(true);
      }
      try {
        e.preventDefault();

        const formData = new FormData();

        formData.append("contractType", form?.contractType);
        formData.append("employeementType", form?.workSchemes?.tkoContract);
        formData.append("clientCode", form?.clientCode);
        formData.append("clientName", form?.clientName);
        formData.append("setManFee", form?.setManFee);
        formData.append("setTOP", form?.setTOP);
        formData.append("remark", form?.remark);

        formData.append(
          "workScheme[taxs][pph21]",
          form?.workSchemes?.taxs?.pph21
        );
        formData.append(
          "workScheme[taxs][pph23]",
          form?.workSchemes?.taxs?.pph23
        );
        formData.append("workScheme[taxs][ppn]", form?.workSchemes?.taxs?.ppn);
        formData.append(
          "workScheme[bpjs][registration]",
          form?.workSchemes?.bpjs?.registration
        );
        formData.append(
          "workScheme[bpjs][paymentProof]",
          form?.workSchemes?.bpjs?.paymentProof
        );
        formData.append("workScheme[bpjs][bpuType]", withBPJS ? selectedValueBPU : null);
        formData.append("workScheme[bpjs][jkk]", withBPJS ? form?.workSchemes?.bpjs?.jkk : null);
        formData.append("workScheme[bpjs][jkm]", withBPJS ? form?.workSchemes?.bpjs?.jkm : null);
        formData.append("workScheme[bpjs][jht]", withBPJS ? form?.workSchemes?.bpjs?.jht : null);
        formData.append(
          "workScheme[bpjs][akhdk]",
          withBPJS ? form?.workSchemes?.bpjs?.akhdk : null
        );
        formData.append("workScheme[bpjs][jp]", withBPJS ? form?.workSchemes?.bpjs?.jp : null);
        formData.append(
          "workScheme[bpjs][health]",
          withBPJS ? form?.workSchemes?.bpjs?.health : null
        );
        formData.append(
          "workScheme[bpjs][cutOffBPJSTKO]",
          withBPJS ? (form?.workSchemes?.bpjs?.cutOffBPJSTKO === "Manual"
            ? form?.workSchemes?.bpjs?.cutOffNominal
            : form?.workSchemes?.bpjs?.cutOffBPJSTKO) : null
        );
        formData.append("workScheme[others][manfee]", fixedWorkSchemeManFee);
        formData.append(
          "workScheme[others][thr]",
          form?.workSchemes?.others?.thr
        );
        formData.append(
          "workScheme[others][uak]",
          form?.workSchemes?.others?.uak
        );
        formData.append("workScheme[others][tkoBank]", fixedTkoBank);

        formData.append(
          "additional[recipientsAccount][bankName]",
          form?.additional?.recipientsAccount?.bankName
        );
        formData.append(
          "additional[recipientsAccount][bankNumber]",
          form?.additional?.recipientsAccount?.bankNumber
        );
        formData.append(
          "additional[recipientsAccount][bankRecipient]",
          form?.additional?.recipientsAccount?.bankRecipient
        );
        for (let i = 0; i < form?.additional?.contacts.length; i++) {
          formData?.append(
            `additional[contactInformation][${i}][contactID]`,
            form?.additional?.contacts[i]?.id
          );
        }
        formData.append(
          "additional[invoiceFlow][dateOfReceiptOfData]",
          form?.additional?.invoiceFlow?.dateOfReceiptOfData
        );
        formData.append(
          "additional[invoiceFlow][UP]",
          form?.additional?.invoiceFlow?.UP
        );
        formData.append(
          "additional[invoiceFlow][dateOfFillingOfSoftCopy]",
          form?.additional?.invoiceFlow?.dateOfFillingOfSoftCopy
        );
        formData.append(
          "additional[invoiceFlow][dateOfFillingOfHardCopy]",
          form?.additional?.invoiceFlow?.dateOfFillingOfHardCopy
        );
        formData.append(
          "additional[invoiceFlow][payrollDate]",
          form?.additional?.invoiceFlow?.payrollDate
        );
        formData.append(
          "additional[invoiceFlow][cutOffAttendance]",
          form?.additional?.invoiceFlow?.cutOffAttendance
        );
        formData.append(
          "additional[invoiceFlow][supportingDocument]",
          form?.additional?.invoiceFlow?.supportingDocument || ""
        );
        // formData.append(
        //   "additional[cooperationPeriod][startDate]",
        //   form?.additional?.cooperationPeriod?.startDate
        //     ? formatDate(form?.additional?.cooperationPeriod?.startDate)
        //     : null
        // );
        // formData.append(
        //   "additional[cooperationPeriod][endDate]",
        //   form?.additional?.cooperationPeriod?.endDate
        //     ? formatDate(form?.additional?.cooperationPeriod?.endDate)
        //     : null
        // );
        // formData.append(
        //   "additional[cooperationPeriod][documentNumber]",
        //   form?.additional?.cooperationPeriod?.documentNumber
        // );
        // formData.append(
        //   "additional[cooperationPeriod][docType]",
        //   form?.additional?.cooperationPeriod?.documentType
        // );
        formData.append(
          "additional[others][deductedAttendance]",
          form?.additional?.others?.deductedAttendance || ""
        );
        formData.append(
          "additional[others][others]",
          form?.additional?.others?.others || ""
        );

        for (let i = 0; i < mappedDelegationsForm.length; i++) {
          const dataPerCity = mappedDelegationsForm[i];
          formData?.append(`orderLocations[${i}][code]`, dataPerCity?.code);

          for (let j = 0; j < dataPerCity?.delegations?.length; j++) {
            const perDelegation = dataPerCity?.delegations[j];

            formData?.append(
              `orderLocations[${i}][delegations][${j}][position]`,
              perDelegation?.position
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][employeeId]`,
              perDelegation?.id
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][isPIC]`,
              perDelegation?.isPIC ? "YES" : "NO"
            );
          }
        }

        for (let i = 0; i < positionsForm?.length; i++) {
          const perPositionData = positionsForm[i];

          formData?.append(`jobs[${i}][title]`, perPositionData?.positionName);
          formData?.append(
            `jobs[${i}][dedicatedRO]`,
            perPositionData?.dedicatedROId
          );

          for (let j = 0; j < perPositionData?.qualifications?.length; j++) {
            const perQualification = perPositionData?.qualifications[j];

            formData?.append(
              `jobs[${i}][qualifications][${j}][name]`,
              perQualification?.name
            );
            formData?.append(
              `jobs[${i}][qualifications][${j}][value]`,
              perQualification?.value
            );
          }

          const appliedCitiesData = perPositionData?.citiesData?.filter(
            (obj) => obj?.isApplied
          );

          for (let j = 0; j < appliedCitiesData?.length; j++) {
            const perCityData = appliedCitiesData[j];

            formData?.append(
              `jobs[${i}][locations][${j}][code]`,
              perCityData?.cityCode
            );
            formData?.append(
              `jobs[${i}][locations][${j}][salary]`,
              `${perCityData?.salaryValue}::${perCityData?.salaryDenom}`
            );
            formData?.append(
              `jobs[${i}][locations][${j}][fulfilmentDate]`,
              moment(perCityData?.expectedDate)?.format("YYYY-MM-DD")
            );
            formData?.append(
              `jobs[${i}][locations][${j}][demand]`,
              perCityData?.amount
            );

            for (let k = 0; k < perCityData?.personalEquipments?.length; k++) {
              const perAllowance = perCityData?.personalEquipments[k];
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][name]`,
                perAllowance?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][price]`,
                perAllowance?.value
              );
            }

            for (let k = 0; k < perCityData?.allowances?.length; k++) {
              const perAllowance = perCityData?.allowances[k];
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][name]`,
                perAllowance?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][value]`,
                perAllowance?.value
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][denom]`,
                perAllowance?.denom || "Bulanan"
              );
            }

            if (selectedButtonBPJS === "BPU") {
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${perCityData?.allowances?.length}][name]`,
                "JKK"
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${perCityData?.allowances?.length}][value]`,
                perCityData?.jkk || 0
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${perCityData?.allowances?.length}][type]`,
                "BPU"
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${perCityData?.allowances?.length + 1
                }][name]`,
                "JKM"
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${perCityData?.allowances?.length + 1
                }][value]`,
                perCityData?.jkm || 0
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${perCityData?.allowances?.length + 1
                }][type]`,
                "BPU"
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${perCityData?.allowances?.length + 2
                }][name]`,
                "JHT"
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${perCityData?.allowances?.length + 2
                }][value]`,
                perCityData?.jht || 0
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${perCityData?.allowances?.length + 2
                }][type]`,
                "BPU"
              );
            }
          }
        }

        for (let i = 0; i < contracts.length; i++) {
          formData?.append(
            `cooperationPeriod[${i}][startDate]`,
            moment(contracts[i]?.startDate)?.format("YYYY-MM-DD")
          );
          formData?.append(
            `cooperationPeriod[${i}][endDate]`,
            moment(contracts[i]?.endDate)?.format("YYYY-MM-DD")
          );
          formData?.append(
            `cooperationPeriod[${i}][docType]`,
            contracts[i]?.docType
          );
          formData?.append(
            `cooperationPeriod[${i}][docNumber]`,
            contracts[i]?.docNumber
          );
          formData?.append(
            `cooperationPeriod[${i}][remark]`,
            contracts[i]?.remark || ""
          );
          formData?.append("files", contracts[i]?.file);
        }

        const filesArr = form?.files;

        for (let i = 0; i < filesArr.length; i++) {
          const file = filesArr[i];
          formData?.append("files", file);
        }
        if (type === "send") {
          await addOrder(formData);
          setSubmitting(false);
          setIsSuccess(true);
        } else {
          await saveOrderAsDraft(formData);
          setSubmittingDraft(false);
          setIsSuccessDraft(true)
        }
      } catch (err) {
        setSubmitting(false);
        setSubmittingDraft(false);
        console.log(err.response.data.error);
        setError({
          code: err?.response?.data?.code || "500",
          message: err?.response?.data?.error || "Something went wrong",
        });
      }
    },
    [
      contracts,
      fixedTkoBank,
      fixedWorkSchemeManFee,
      form?.additional?.contacts,
      form?.additional?.invoiceFlow?.UP,
      form?.additional?.invoiceFlow?.cutOffAttendance,
      form?.additional?.invoiceFlow?.dateOfFillingOfHardCopy,
      form?.additional?.invoiceFlow?.dateOfFillingOfSoftCopy,
      form?.additional?.invoiceFlow?.dateOfReceiptOfData,
      form?.additional?.invoiceFlow?.payrollDate,
      form?.additional?.invoiceFlow?.supportingDocument,
      form?.additional?.others?.deductedAttendance,
      form?.additional?.others?.others,
      form?.additional?.recipientsAccount?.bankName,
      form?.additional?.recipientsAccount?.bankNumber,
      form?.additional?.recipientsAccount?.bankRecipient,
      form?.clientCode,
      form?.clientName,
      form?.contractType,
      form?.files,
      form?.remark,
      form?.setManFee,
      form?.setTOP,
      form?.workSchemes?.bpjs?.akhdk,
      form?.workSchemes?.bpjs?.cutOffBPJSTKO,
      form?.workSchemes?.bpjs?.cutOffNominal,
      form?.workSchemes?.bpjs?.health,
      form?.workSchemes?.bpjs?.jht,
      form?.workSchemes?.bpjs?.jkk,
      form?.workSchemes?.bpjs?.jkm,
      form?.workSchemes?.bpjs?.jp,
      form?.workSchemes?.bpjs?.paymentProof,
      form?.workSchemes?.bpjs?.registration,
      form?.workSchemes?.others?.thr,
      form?.workSchemes?.others?.uak,
      form?.workSchemes?.taxs?.pph21,
      form?.workSchemes?.taxs?.pph23,
      form?.workSchemes?.taxs?.ppn,
      form?.workSchemes?.tkoContract,
      mappedDelegationsForm,
      positionsForm,
      selectedButtonBPJS,
      selectedValueBPU,
    ]
  );
  const handleSubmitNew = useCallback(
    async (e, type) => {
      console.log("new");
      if (type === "send") {
        setSubmittingType("send");
        setSubmitting(true);
      } else {
        setSubmittingType("draft");
        setSubmittingDraft(true);
      }
      try {
        e.preventDefault();

        const formData = new FormData();

        formData.append("contractType", form?.contractType);
        formData.append("clientCode", form?.clientCode);
        formData.append("setManFee", form?.setManFee);
        formData.append("setTOP", form?.setTOP);
        formData.append("remark", form?.remark);

        formData.append(
          "workScheme[taxs][pph21]",
          form?.workSchemes?.taxs?.pph21
        );
        formData.append(
          "workScheme[taxs][pph23]",
          form?.workSchemes?.taxs?.pph23
        );
        formData.append("workScheme[taxs][ppn]", form?.workSchemes?.taxs?.ppn);
        formData.append(
          "workScheme[bpjs][registration]",
          form?.workSchemes?.bpjs?.registration
        );
        formData.append(
          "workScheme[bpjs][paymentProof]",
          form?.workSchemes?.bpjs?.paymentProof
        );
        formData.append("workScheme[bpjs][bpuType]", selectedValueBPU);
        formData.append("workScheme[bpjs][jkk]", form?.workSchemes?.bpjs?.jkk);
        formData.append("workScheme[bpjs][jkm]", form?.workSchemes?.bpjs?.jkm);
        formData.append("workScheme[bpjs][jht]", form?.workSchemes?.bpjs?.jht);
        formData.append(
          "workScheme[bpjs][akhdk]",
          form?.workSchemes?.bpjs?.akhdk
        );
        formData.append("workScheme[bpjs][jp]", form?.workSchemes?.bpjs?.jp);
        formData.append(
          "workScheme[bpjs][health]",
          form?.workSchemes?.bpjs?.health
        );
        formData.append(
          "workScheme[bpjs][cutOffBPJSTKO]",
          form?.workSchemes?.bpjs?.cutOffBPJSTKO
        );
        formData.append("workScheme[others][manfee]", fixedWorkSchemeManFee);
        formData.append(
          "workScheme[others][thr]",
          form?.workSchemes?.others?.thr
        );
        formData.append(
          "workScheme[others][uak]",
          form?.workSchemes?.others?.uak
        );
        formData.append("workScheme[others][tkoBank]", fixedTkoBank);

        formData.append(
          "additional[recipientsAccount][bankName]",
          form?.additional?.recipientsAccount?.bankName
        );
        formData.append(
          "additional[recipientsAccount][bankNumber]",
          form?.additional?.recipientsAccount?.bankNumber
        );
        formData.append(
          "additional[recipientsAccount][bankRecipient]",
          form?.additional?.recipientsAccount?.bankRecipient
        );
        formData.append(
          "additional[invoiceFlow][dateOfReceiptOfData]",
          form?.additional?.invoiceFlow?.dateOfReceiptOfData
        );
        formData.append(
          "additional[invoiceFlow][UP]",
          form?.additional?.invoiceFlow?.UP
        );
        formData.append(
          "additional[invoiceFlow][dateOfFillingOfSoftCopy]",
          form?.additional?.invoiceFlow?.dateOfFillingOfSoftCopy
        );
        formData.append(
          "additional[invoiceFlow][dateOfFillingOfHardCopy]",
          form?.additional?.invoiceFlow?.dateOfFillingOfHardCopy
        );
        formData.append(
          "additional[invoiceFlow][payrollDate]",
          form?.additional?.invoiceFlow?.payrollDate
        );
        formData.append(
          "additional[invoiceFlow][cutOffAttendance]",
          form?.additional?.invoiceFlow?.cutOffAttendance
        );
        formData.append(
          "additional[invoiceFlow][supportingDocument]",
          form?.additional?.invoiceFlow?.supportingDocument || ""
        );
        formData.append(
          "additional[cooperationPeriod][startDate]",
          form?.additional?.cooperationPeriod?.startDate
            ? formatDate(form?.additional?.cooperationPeriod?.startDate)
            : null
        );
        formData.append(
          "additional[cooperationPeriod][endDate]",
          form?.additional?.cooperationPeriod?.endDate
            ? formatDate(form?.additional?.cooperationPeriod?.endDate)
            : null
        );
        formData.append(
          "additional[cooperationPeriod][documentNumber]",
          form?.additional?.cooperationPeriod?.documentNumber
        );
        formData.append(
          "additional[cooperationPeriod][docType]",
          form?.additional?.cooperationPeriod?.documentType
        );
        formData.append(
          "additional[others][deductedAttendance]",
          form?.additional?.others?.deductedAttendance || ""
        );
        formData.append(
          "additional[others][others]",
          form?.additional?.others?.others || ""
        );

        const filesArr = form?.files;

        for (let i = 0; i < manPowerFormArr?.length; i++) {
          const perlocation = manPowerFormArr[i];

          const delegationsArr = perlocation?.deployments
            ?.map((obj) => obj?.delegations)
            ?.flat()
            ?.filter((obj) => obj?.isPicked);
          const jobsArr = perlocation?.positions;
          const areaEquipmentsArr = perlocation?.areaEquipments;

          formData.append(`manPowers[${i}][location]`, perlocation?.locationID);

          for (let j = 0; j < delegationsArr.length; j++) {
            formData?.append(
              `manPowers[${i}][delegations][${j}][employeeId]`,
              delegationsArr[j]?.id
            );
            formData?.append(
              `manPowers[${i}][delegations][${j}][isPIC]`,
              delegationsArr[j]?.isPIC ? "YES" : "NO"
            );
          }

          for (let j = 0; j < jobsArr?.length; j++) {
            const allowancesArr = jobsArr[j]?.allowances;
            const allowancesArrFilter = jobsArr[j]?.allowances?.filter(
              (el) => !el?.type
            );
            const qualificationsArr = jobsArr[j]?.qualifications;
            const personalEquipmentsArr = jobsArr[j]?.personalEquipments;

            const job = jobsArr[j];

            formData?.append(`manPowers[${i}][jobs][${j}][title]`, job?.title);
            formData?.append(
              `manPowers[${i}][jobs][${j}][contractType]`,
              job?.contractType
            );
            formData?.append(
              `manPowers[${i}][jobs][${j}][salary]`,
              `${job?.salaryValue}::${job?.salaryDenom}`
            );
            formData?.append(
              `manPowers[${i}][jobs][${j}][fulfilmentDate]`,
              job?.expectedDate ? formatDate(job?.expectedDate) : ""
            );
            formData?.append(
              `manPowers[${i}][jobs][${j}][demand]`,
              job?.amount || 0
            );

            if (selectedValueBPU === "Tagih Ke Perusahaan") {
              for (let k = 0; k < allowancesArr.length; k++) {
                formData?.append(
                  `manPowers[${i}][jobs][${j}][allowances][${k}][type]`,
                  allowancesArr[k]?.type || ""
                );
                formData?.append(
                  `manPowers[${i}][jobs][${j}][allowances][${k}][name]`,
                  allowancesArr[k]?.name
                );
                formData?.append(
                  `manPowers[${i}][jobs][${j}][allowances][${k}][value]`,
                  allowancesArr[k]?.value || 0
                );
              }
            } else {
              for (let k = 0; k < allowancesArrFilter.length; k++) {
                formData?.append(
                  `manPowers[${i}][jobs][${j}][allowances][${k}][name]`,
                  allowancesArr[k]?.name
                );
                formData?.append(
                  `manPowers[${i}][jobs][${j}][allowances][${k}][value]`,
                  allowancesArr[k]?.value || 0
                );
              }
            }

            for (let k = 0; k < qualificationsArr.length; k++) {
              formData?.append(
                `manPowers[${i}][jobs][${j}][qualifications][${k}][name]`,
                qualificationsArr[k]?.name
              );
              formData?.append(
                `manPowers[${i}][jobs][${j}][qualifications][${k}][value]`,
                qualificationsArr[k]?.value
              );
            }

            for (let k = 0; k < personalEquipmentsArr.length; k++) {
              formData?.append(
                `manPowers[${i}][jobs][${j}][personalEquipments][${k}][name]`,
                personalEquipmentsArr[k]?.name
              );
              formData?.append(
                `manPowers[${i}][jobs][${j}][personalEquipments][${k}][price]`,
                personalEquipmentsArr[k]?.value
              );
            }
          }

          for (let j = 0; j < areaEquipmentsArr.length; j++) {
            const equipment = areaEquipmentsArr[j];
            formData?.append(
              `manPowers[${i}][areaWorkEquipments][${j}][name]`,
              equipment?.name
            );
            formData?.append(
              `manPowers[${i}][areaWorkEquipments][${j}][price]`,
              equipment?.value
            );
          }
        }

        for (let i = 0; i < filesArr.length; i++) {
          const file = filesArr[i];
          formData?.append("files", file);
        }
        if (type === "send") {
          await addOrder(formData);
          setSubmitting(false);
          setIsSuccess(true);
        } else {
          await saveOrderAsDraft(formData);
          setSubmittingDraft(false);
          setIsSuccessDraft(true)
        }
      } catch (err) {
        setSubmitting(false);
        setSubmittingDraft(false);
        console.log(err?.response?.data?.error);
        setError({
          code: err?.response?.data?.code,
          message: err?.response?.data?.error,
        });
      }
    },
    [
      fixedTkoBank,
      fixedWorkSchemeManFee,
      form?.additional?.cooperationPeriod?.documentNumber,
      form?.additional?.cooperationPeriod?.documentType,
      form?.additional?.cooperationPeriod?.endDate,
      form?.additional?.cooperationPeriod?.startDate,
      form?.additional?.invoiceFlow?.UP,
      form?.additional?.invoiceFlow?.cutOffAttendance,
      form?.additional?.invoiceFlow?.dateOfFillingOfHardCopy,
      form?.additional?.invoiceFlow?.dateOfFillingOfSoftCopy,
      form?.additional?.invoiceFlow?.dateOfReceiptOfData,
      form?.additional?.invoiceFlow?.payrollDate,
      form?.additional?.invoiceFlow?.supportingDocument,
      form?.additional?.others?.deductedAttendance,
      form?.additional?.others?.others,
      form?.additional?.recipientsAccount?.bankName,
      form?.additional?.recipientsAccount?.bankNumber,
      form?.additional?.recipientsAccount?.bankRecipient,
      form?.clientCode,
      form?.contractType,
      form?.files,
      form?.remark,
      form?.setManFee,
      form?.setTOP,
      form?.workSchemes?.bpjs?.akhdk,
      form?.workSchemes?.bpjs?.cutOffBPJSTKO,
      form?.workSchemes?.bpjs?.health,
      form?.workSchemes?.bpjs?.jht,
      form?.workSchemes?.bpjs?.jkk,
      form?.workSchemes?.bpjs?.jkm,
      form?.workSchemes?.bpjs?.jp,
      form?.workSchemes?.bpjs?.paymentProof,
      form?.workSchemes?.bpjs?.registration,
      form?.workSchemes?.others?.thr,
      form?.workSchemes?.others?.uak,
      form?.workSchemes?.taxs?.pph21,
      form?.workSchemes?.taxs?.pph23,
      form?.workSchemes?.taxs?.ppn,
      manPowerFormArr,
      selectedValueBPU,
    ]
  );

  const onNext = useCallback(
    (isDone) => {
      const newSteps = [...steps];
      // if (isDone) {
      // if (+currentStep !== 5) {
      newSteps[currentStep - 1].isDone = true;
      setSteps(newSteps);
      setCurrentStep(currentStep + 1);
      // } 
      // else {
      //   handleSubmit();
      // }
      // }
    },
    [currentStep, setCurrentStep, setSteps, steps]
  );

  const resetIsDone = useCallback(() => {
    const newSteps = [...steps];
    if (!firstStepDoneIndicator) {
      newSteps[0].isDone = false;
    }
    if (!secondStepDoneIndicator) {
      newSteps[1].isDone = true;
    }
    if (!thirdStepDoneIndicator) {
      newSteps[2].isDone = false;
    }
    if (!fourthStepDoneIndicator) {
      newSteps[3].isDone = false;
    }
    // if (!sixthStepDoneIndicator) {
    //   newSteps[5].isDone = false;
    // }
    setSteps(newSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstStepDoneIndicator,
    secondStepDoneIndicator,
    setSteps,
    thirdStepDoneIndicator,
    fourthStepDoneIndicator,
  ]);

  useEffect(() => {
    resetIsDone();
  }, [resetIsDone]);

  const onChangeStep1 = useCallback(
    (type) => {
      setContractForm((prev) => {
        return {
          ...prev,
          contract: type?.title,
        };
      });

      setContractType(type);
    },
    [setContractType]
  );


  useEffect(() => {
    const found = contractTypeOptions.find(
      (option) => option?.title === contractForm?.contract
    );
    setContractType(found);

    // eslint-disable-next-line
  }, [contractTypeOptions, contractForm]);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccessDraft, setIsSuccessDraft] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error, setError]);
  const [hash, setHash] = useState(null);
  const [submittingType, setSubmittingType] = useState(null);

  const [submitting, setSubmitting] = useState(null);
  const [submittingDraft, setSubmittingDraft] = useState(null);

  const clearStorage = () => {
    sessionStorage.removeItem("contractForm");
    sessionStorage.removeItem("workSchemeForm");
    sessionStorage.removeItem("additionalForm");
    sessionStorage.removeItem("manPowerFormAdd");
    sessionStorage.removeItem("manPowerFormArrAdd");
    sessionStorage.removeItem("remark");
    sessionStorage.removeItem("TOP");
    sessionStorage.removeItem("manfee");
    sessionStorage.removeItem("delegations");
    sessionStorage.removeItem("orderFiles");
    sessionStorage.removeItem("orderStep");
    sessionStorage.removeItem("maxOrderStep");
    sessionStorage.removeItem("steps");
    sessionStorage.removeItem("activeFourthStep");
    sessionStorage.removeItem("selectedButtonBPJS");
    sessionStorage.removeItem("selectedValueBPU");
    sessionStorage.removeItem("delegationsForm");
    sessionStorage.removeItem("positionsForm");
    sessionStorage.removeItem("contracts");
    sessionStorage.removeItem("activeFourthStep");
    sessionStorage.removeItem("addWithBPJS");
  };

  useEffect(() => {
    setMaxStep(maxStepId);
  }, [maxStepId, setMaxStep]);

  const renewTrigger = useCallback(() => {
    setTrigger(Math.random());
  }, []);

  const debounceTrigger = useMemo(
    () => debounce(renewTrigger, 500),
    [renewTrigger]
  );


  const renewDefault = useCallback(() => {
    sessionStorage.setItem("contractForm", JSON.stringify(contractForm));
    sessionStorage.setItem("workSchemeForm", JSON.stringify(workSchemeForm));
    sessionStorage.setItem("additionalForm", JSON.stringify(additionalForm));
    sessionStorage.setItem("manPowerFormAdd", JSON.stringify(manPowerForm));
    sessionStorage.setItem(
      "manPowerFormArrAdd",
      JSON.stringify(manPowerFormArr)
    );
    sessionStorage.setItem("delegationsForm", JSON.stringify(delegationsForm));
    sessionStorage.setItem("positionsForm", JSON.stringify(positionsForm));
    sessionStorage.setItem("remark", JSON.stringify(remark));
    sessionStorage.setItem("TOP", JSON.stringify(TOP));
    sessionStorage.setItem("manfee", JSON.stringify(manfee));
    sessionStorage.setItem("delegations", JSON.stringify(delegations));
    sessionStorage.setItem("maxOrderStep", maxStep);
    sessionStorage.setItem("steps", JSON.stringify(steps));
    sessionStorage.setItem(
      "selectedButtonBPJS",
      JSON.stringify(selectedButtonBPJS)
    );
    sessionStorage.setItem("selectedValueBPU", selectedValueBPU);
    sessionStorage.setItem("addWithBPJS", withBPJS ? 'YES' : 'NO');



    const contractArr = [...contracts]

    for (let i = 0; i < contractArr.length; i++) {
      const file = contractArr[i]["file"]
      if (typeof file === "object") {
        fileToBase64(file, async (base64) => {
          contractArr[i]["str"] = base64
        })
      }

    }

    sessionStorage.setItem("contracts", JSON.stringify(contractArr?.map((obj) => {
      return {
        ...obj,

        type: typeof obj?.file
      }
    })));

    // sessionStorage.setItem('orderFiles', JSON.stringify(fixedFiles))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contractForm,
    workSchemeForm,
    additionalForm,
    manPowerForm,
    manPowerFormArr,
    delegationsForm,
    positionsForm,
    remark,
    TOP,
    manfee,
    delegations,
    maxStep,
    steps,
    trigger,
    selectedButtonBPJS,
    selectedValueBPU,
    contracts,
    withBPJS
  ]);

  const debounceDefault = useMemo(
    () => debounce(renewDefault, 500),
    [renewDefault]
  );

  useEffect(() => {
    debounceDefault();
  }, [debounceDefault, trigger]);

  // useEffect(()=> {
  //   renewTrigger()
  // }, [manPowerFormArr, renewTrigger])


  const alertFound = useMemo(() => {
    switch (currentStep) {
      case 1:
        if (!contractForm?.contract && !contracts?.length) {
          return "Mohon untuk memilih tipe kontrak dan kontrak kerja terlebih dahulu";
        }
        if (!contractForm?.contract) {
          return "Mohon untuk memilih tipe kontrak terlebih dahulu";
        }
        if (!contracts?.length) {
          return "Mohon untuk memasukkan kontrak kerja terlebih dahulu";
        }
        if (contracts?.length > 0) {
          for (let i = 0; i < contracts.length; i++) {
            const contract = contracts[i];
            if (!contract?.file) {
              return `Mohon untuk memasukkan attachment ${contract?.docType} - ${contract?.docNumber} terlebih dahulu`;
            }

            if (!contract?.docNumber) {
              return `Mohon untuk memasukkan document number terlebih dahulu`;
            }

            if (!contract?.docType) {
              return `Mohon untuk memasukkan document type terlebih dahulu`;
            }
          }
        }
        break;
      case 2:
        if (
          !workSchemeForm?.taxs?.pph21 ||
          !workSchemeForm?.taxs?.pph23 ||
          !workSchemeForm?.taxs?.ppn ||
          (withBPJS && (!workSchemeForm?.bpjs?.registration ||
            !workSchemeForm?.bpjs?.paymentProof ||
            !workSchemeForm?.bpjs?.cutOffBPJSTKO ||
            (workSchemeForm?.bpjs?.cutOffBPJSTKO === "Manual" &&
              !workSchemeForm?.bpjs?.cutOffNominal) ||
            (sessionStorage?.getItem("idButtonTypeBPU") === "BPU" &&
              !selectedValueBPU)))
          ||
          !workSchemeForm?.others?.manfee?.length ||
          !workSchemeForm?.others?.thr ||
          !workSchemeForm?.others?.uak ||
          !workSchemeForm?.others?.tkoBank?.length ||
          !workSchemeForm?.tkoContract ||
          (workSchemeForm?.bpjs?.jkkOn && !workSchemeForm?.bpjs?.jkk) ||
          (workSchemeForm?.bpjs?.jkmOn && !workSchemeForm?.bpjs?.jkm) ||
          (workSchemeForm?.bpjs?.jhtOn && !workSchemeForm?.bpjs?.jht) ||
          (workSchemeForm?.bpjs?.healthOn && !workSchemeForm?.bpjs?.health) ||
          (workSchemeForm?.bpjs?.akhdkOn && !workSchemeForm?.bpjs?.akhdk)
        ) {
          return "Mohon untuk melengkapi form terlebih dahulu";
        }

        break;
      case 3:
        if (
          // !additionalForm?.cooperationPeriod?.startDate
          // || !additionalForm?.cooperationPeriod?.endDate
          // || !additionalForm?.cooperationPeriod?.documentType
          // || !additionalForm?.cooperationPeriod?.documentNumber
          !additionalForm?.contacts?.length ||
          !additionalForm?.invoiceFlow?.dateOfReceiptOfData ||
          !additionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy ||
          !additionalForm?.invoiceFlow?.dateOfFillingOfHardCopy ||
          !additionalForm?.invoiceFlow?.UP ||
          !additionalForm?.invoiceFlow?.payrollDate ||
          !additionalForm?.invoiceFlow?.cutOffAttendance
        ) {
          return "Mohon untuk melengkapi form terlebih dahulu";
        }
        break;
      case 4:
        if (!delegationsForm?.length) {
          return "Mohon untuk menambahkan data deployment terlebih dahulu";
        }
        const dArr = delegationsForm?.map(
          (obj) => obj?.deployments[0]?.delegations?.map(u => {
            return {
              ...u,
              cityName: obj?.cityName
            }
          })
        );
        for (let i = 0; i < dArr.length; i++) {
          const d = dArr[i];
          const found = !d?.find((u) => u?.isPicked)
          if (found) {
            return `Harap pilih business manager untuk lokasi ${d[0]?.cityName}`;
          }
        }
        const dArr3 = delegationsForm?.map(
          (obj) => obj?.deployments[3]?.delegations?.map(u => {
            return {
              ...u,
              cityName: obj?.cityName
            }
          })
        );

        for (let i = 0; i < dArr3.length; i++) {
          const d = dArr3[i];
          const found = !d?.find((u) => u?.isPicked)
          if (found) {
            return `Harap pilih recruitment officer untuk lokasi ${d[0]?.cityName}`;
          }
        }

        break;

      case 5:
        if (!positionsForm?.length) {
          return "Mohon untuk menambahkan list position terlebih dahulu";
        }

        const flatPositions = positionsForm
          ?.map((obj) =>
            obj?.citiesData
              ?.filter((f) => f?.isApplied)
              ?.map((c) => {
                return {
                  ...c,
                  positionName: obj?.positionName,
                };
              })
          )
          ?.flat();

        for (let i = 0; i < delegationsForm?.length; i++) {
          if (
            !flatPositions?.find(
              (p) => p?.cityCode === delegationsForm[i].cityCode
            )
          ) {
            return `Belum ada posisi diaktifkan untuk kota ${delegationsForm[i]?.cityName}`;
          }
        }

        for (let i = 0; i < positionsForm.length; i++) {
          if (!positionsForm[i]?.citiesData?.find((c) => c?.isApplied)) {
            return `Posisi ${positionsForm[i]?.positionName} belum diaktifkan ke salah satu kota`;
          }

          const appliedCities = positionsForm[i]?.citiesData?.filter(
            (c) => c?.isApplied
          );

          for (let j = 0; j < appliedCities.length; j++) {
            if (appliedCities[j]?.personalEquipments?.length > 0) {
              for (
                let l = 0;
                l < appliedCities[j]?.personalEquipments?.length;
                l++
              ) {
                const personalEquipment =
                  appliedCities[j]?.personalEquipments[l];
                if (!personalEquipment?.value) {
                  return `Personal Equipment ${personalEquipment?.name} posisi ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`;
                }
              }
            }

            if (appliedCities[j]?.allowances?.length > 0) {
              for (let k = 0; k < appliedCities[j]?.allowances?.length; k++) {
                const allowance = appliedCities[j]?.allowances[k];
                if (!allowance?.value) {
                  return `Allowances ${allowance?.name} posisi ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`;
                }
              }
            }

            if (!appliedCities[j]?.expectedDate) {
              return `Expected fulfillment date ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`;
            }
          }
        }

        const noRODedicatedFound = positionsForm?.find(
          (obj) => !obj?.dedicatedROId
        );
        if (noRODedicatedFound) {
          return `Mohon untuk memilih Recruitment Officer Dedicated untuk posisi ${noRODedicatedFound?.positionName}`;
        }
        const noQualificationFound = positionsForm?.find(
          (obj) => !obj?.qualifications?.length
        );
        if (noQualificationFound) {
          return `Mohon untuk memasukkan kualifikasi untuk posisi ${noQualificationFound?.positionName}`;
        }
        break;
      default:
        return "";
    }
  }, [additionalForm?.contacts?.length, additionalForm?.invoiceFlow?.UP, additionalForm?.invoiceFlow?.cutOffAttendance, additionalForm?.invoiceFlow?.dateOfFillingOfHardCopy, additionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy, additionalForm?.invoiceFlow?.dateOfReceiptOfData, additionalForm?.invoiceFlow?.payrollDate, contractForm?.contract, contracts, currentStep, delegationsForm, positionsForm, selectedValueBPU, withBPJS, workSchemeForm?.bpjs?.akhdk, workSchemeForm?.bpjs?.akhdkOn, workSchemeForm?.bpjs?.cutOffBPJSTKO, workSchemeForm?.bpjs?.cutOffNominal, workSchemeForm?.bpjs?.health, workSchemeForm?.bpjs?.healthOn, workSchemeForm?.bpjs?.jht, workSchemeForm?.bpjs?.jhtOn, workSchemeForm?.bpjs?.jkk, workSchemeForm?.bpjs?.jkkOn, workSchemeForm?.bpjs?.jkm, workSchemeForm?.bpjs?.jkmOn, workSchemeForm?.bpjs?.paymentProof, workSchemeForm?.bpjs?.registration, workSchemeForm?.others?.manfee?.length, workSchemeForm?.others?.thr, workSchemeForm?.others?.tkoBank?.length, workSchemeForm?.others?.uak, workSchemeForm?.taxs?.pph21, workSchemeForm?.taxs?.pph23, workSchemeForm?.taxs?.ppn, workSchemeForm?.tkoContract]);

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  useEffect(() => {
    if (!alertFound) {
      setAlertMessage("");
    }
  }, [alertFound, setAlertMessage]);
  useEffect(() => {
    if (alertMessage && alertMessage !== alertFound) {
      setAlertMessage(alertFound);
    }
  }, [alertFound, alertMessage, setAlertMessage]);

  const handleOnNext = useCallback(() => {
    if (alertFound) {
      setAlertMessage(alertFound);
    } else {
      setAlertMessage("");
      onNext(true);
    }
    scrollingTop();
  }, [alertFound, onNext, setAlertMessage]);

  const selectedContract = useMemo(() => {
    return contractTypeOptions?.find(obj => obj?.title === contractForm?.contract)
  }, [contractForm?.contract, contractTypeOptions])


  const activeStep = useMemo(() => {
    switch (currentStep) {
      case 1:
        return (
          <FirstStep
            debounceTrigger={debounceTrigger}
            types={contractTypeOptions}
            onChange={onChangeStep1}
            form={contractForm}
            // onNext={() => onNext(firstStepDoneIndicator)}
            onNext={handleOnNext}
            contracts={contracts}
            setContracts={setContracts}
            alertMessage={alertMessage}
          />
        );
      case 2:
        return (
          <SecondStep
            setActiveButtonBPJS={setActiveButtonBPJS}
            selectedButtonBPJS={selectedButtonBPJS}
            setSelectedButtonBPJS={setSelectedButtonBPJS}
            selectedValueBPU={selectedValueBPU}
            setSelectedValueBPU={setSelectedValueBPU}
            debounceTrigger={debounceTrigger}
            bankOptions={banks?.map((obj) => {
              return { name: obj };
            })}
            setTrigger={setTrigger}
            isDone={secondStepDoneIndicator}
            options={workSchemeOptions}
            form={workSchemeForm}
            setForm={setWorkSchemeForm}
            // onNext={() => onNext(secondStepDoneIndicator)}
            alertMessage={alertMessage}
            onNext={handleOnNext}
            onPrevious={onPrevious}
            contractType={contractType}
            withBPJS={withBPJS}
            setWithBPJS={setWithBPJS}
            optionalBPJS
          />
        );
      case 3:
        return (
          <ThirdStep
            debounceTrigger={debounceTrigger}
            setTrigger={setTrigger}
            isDone={thirdStepDoneIndicator}
            form={additionalForm}
            setForm={setAdditionalForm}
            files={files}
            setFiles={setFiles}
            alertMessage={alertMessage}
            // onNext={() => onNext(true)}
            onNext={handleOnNext}
            onPrevious={onPrevious}
            clientContacts={clientContacts}
          />
        );
      case 4:
        return (
          <DeploymentStep
            delegationsForm={delegationsForm}
            setDelegationsForm={setDelegationsForm}
            defaultDeployments={defaultDeploymentsData}
            cities={cities}
            headerHeight={headerHeight}

          />
        )
      case 5:
        return (
          <PositionStep
            delegationsForm={delegationsForm}
            positionsForm={positionsForm}
            setPositionsForm={setPositionsForm}
            headerHeight={headerHeight}
            alertMessage={alertMessage}
          />
          // <FourthStep
          //   form={manPowerForm}
          //   setForm={setManPowerForm}
          //   options={optionsFetcher?.fourth()}
          //   cities={syncData?.cities}
          //   remark={remark}
          //   setRemark={setRemark}
          //   trigger={trigger}
          //   setTrigger={setTrigger}
          //   isDone={isDoneChecker?.fourth()}
          //   onPrevious={onPrevious}
          //   onNext={() => onNext(isDoneChecker?.fourth())}
          //   debounceTrigger={debounceTrigger}
          //   selectedValueBPU={selectedValueBPU}
          // />
          // <NewFourth
          //   formArr={manPowerFormArr}
          //   setFormArr={setManPowerFormArr}
          //   isDone={fourthStepDoneIndicator}
          //   cities={cities}
          //   remark={remark}
          //   setRemark={setRemark}
          //   defaultDeployments={defaultDeploymentsData}
          //   contractTypeOptions={options?.filter(obj => obj?.type === "contractTypeWorker")?.map(obj => obj?.value)}
          //   salaryDenomOptions={["Harian", "Mingguan", "Bulanan"]}
          //   personalEquipmentOptions={options?.filter(obj => obj?.type === 'Personal Work Equipment')?.map(obj => obj?.value)}
          //   allowanceOptions={options?.filter(obj => obj?.type === "Allowance")?.map(obj => obj?.value)}
          //   qualificationOptions={qualifications?.map(obj => {
          //     return {
          //       // id: makeRandomString(5),
          //       name: obj?.value,
          //       denom: obj?.denom,
          //       value: ''
          //     }
          //   })}
          //   areaEquipmentOptions={options?.filter(obj => obj?.type === 'Area Work Equipment')?.map(obj => obj?.value)}
          //   onNext={() => onNext(true)}
          //   onPrevious={onPrevious}
          //   selectedValueBPU={selectedValueBPU}
          // />
          // <FourthRevamp
          //   onPrevious={onPrevious}
          //   onNext={handleOnNext}
          //   delegationsForm={delegationsForm}
          //   setDelegationsForm={setDelegationsForm}
          //   positionsForm={positionsForm}
          //   setPositionsForm={setPositionsForm}
          //   remark={remark}
          //   setRemark={setRemark}
          //   defaultDeployments={defaultDeploymentsData}
          //   cities={cities}
          //   alertMessage={alertMessage}
          //   contractTypeOptions={options
          //     ?.filter((obj) => obj?.type === "contractTypeWorker")
          //     ?.map((obj) => obj?.value)}
          //   personalEquipmentOptions={options
          //     ?.filter((obj) => obj?.type === "Personal Work Equipment")
          //     ?.map((obj) => obj?.value)}
          //   allowanceOptions={options
          //     ?.filter((obj) => obj?.type === "Allowance")
          //     ?.map((obj) => obj?.value)}
          //   qualificationOptions={qualifications?.map((obj) => {
          //     return {
          //       // id: makeRandomString(5),
          //       name: obj?.value,
          //       denom: obj?.denom,
          //       value: "",
          //     };
          //   })}
          //   areaEquipmentOptions={options
          //     ?.filter((obj) => obj?.type === "Area Work Equipment")
          //     ?.map((obj) => obj?.value)}
          //   selectedButtonBPJS={selectedButtonBPJS}
          //   selectedValueBPU={selectedValueBPU}
          //   scrollingTop={scrollingTop}
          // />
        );
      case 6:
        return (
          <FifthStep
            debounceTrigger={debounceTrigger}
            manfeeArr={workSchemeForm?.others?.manfee}
            fixedManfee={fixedWorkSchemeManFee}
            fixedBpjsCut={fixedBpjsCut}
            manfee={manfee}
            setManfee={setManFee}
            TOP={TOP}
            setTOP={setTOP}
            // form={manPowerForm}
            form={delegationsForm?.map((obj) => {
              return {
                location: obj?.cityCode,
                cityName: obj?.cityName,
                umk: obj?.umk,
                areaWorkEquipments: [],
                jobs: positionsForm
                  ?.filter((p) =>
                    p?.citiesData?.find(
                      (c) => c?.cityCode === obj?.cityCode && c?.isApplied
                    )
                  )
                  ?.map((pObj) => {
                    return {
                      title: pObj?.positionName,
                      demand: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.amount,
                      contractType: workSchemeForm?.tkoContract,
                      fulfillmentDate: pObj?.citiesData?.find(
                        (c) => c?.cityCode === pObj?.cityCode
                      )?.expectedDate,
                      salary: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.salaryValue,
                      salaryPeriod: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.salaryDenom,
                      personalEquipments: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.personalEquipments,
                      qualifications: pObj?.qualifications,
                      // allowances: pObj?.citiesData?.find(c => c?.cityCode === obj?.cityCode)?.allowances,
                      allowances:
                        selectedValueBPU !== "Tagih Ke Perusahaan"
                          ? pObj?.citiesData?.find(
                            (c) => c?.cityCode === obj?.cityCode
                          )?.allowances
                          : [
                            ...pObj?.citiesData?.find(
                              (c) => c?.cityCode === obj?.cityCode
                            )?.allowances,
                            {
                              name: "JKK",
                              value: pObj?.citiesData?.find(
                                (c) => c?.cityCode === obj?.cityCode
                              )?.jkk,
                              type: "BPU",
                            },
                            {
                              name: "JKM",
                              value: pObj?.citiesData?.find(
                                (c) => c?.cityCode === obj?.cityCode
                              )?.jkm,
                              type: "BPU",
                            },
                            {
                              name: "JHT",
                              value: pObj?.citiesData?.find(
                                (c) => c?.cityCode === obj?.cityCode
                              )?.jht,
                              type: "BPU",
                            },
                          ],
                    };
                  }),
              };
            })}
            workSchemeForm={workSchemeForm}
            setForm={setManPowerForm}
            cities={cities}
            onSubmit={handleSubmit}
            onPrevious={onPrevious}
            selectedValueBPU={selectedValueBPU}
            isSubmitting={submitting}
            isSubmittingDraft={submittingDraft}
            submittingType={submittingType}
            error={error}
            isDone={
              firstStepDoneIndicator &&
              secondStepDoneIndicator &&
              thirdStepDoneIndicator &&
              fourthStepDoneIndicator
            }
            onNext={handleOnNext}

          // isV2
          />
        );

      case 7:
        return (
          <NewSixth
            onSubmit={handleSubmit}
            onPrevious={onPrevious}
            isSubmitting={submitting}
            isSubmittingDraft={submittingDraft}
            submittingType={submittingType}
            contract={selectedContract}
            contracts={contracts}
            workSchemeForm={workSchemeForm}
            employmentType={contractType}
            remark={remark}
            additionalForm={additionalForm}
            contactInformation={clientContacts}

            fixedManfee={fixedWorkSchemeManFee}
            fixedBpjsCut={fixedBpjsCut}
            manfee={manfee}
            TOP={TOP}
            delegationsForm={delegationsForm?.map((obj) => {
              return {
                location: obj?.cityCode,
                cityName: obj?.cityName,
                umk: obj?.umk,
                areaWorkEquipments: [],
                jobs: positionsForm
                  ?.filter((p) =>
                    p?.citiesData?.find(
                      (c) => c?.cityCode === obj?.cityCode && c?.isApplied
                    )
                  )
                  ?.map((pObj) => {
                    return {
                      title: pObj?.positionName,
                      demand: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.amount,
                      contractType: workSchemeForm?.tkoContract,
                      fulfillmentDate: pObj?.citiesData?.find(
                        (c) => c?.cityCode === pObj?.cityCode
                      )?.expectedDate,
                      salary: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.salaryValue,
                      salaryPeriod: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.salaryDenom,
                      personalEquipments: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.personalEquipments,
                      qualifications: pObj?.qualifications,
                      // allowances: pObj?.citiesData?.find(c => c?.cityCode === obj?.cityCode)?.allowances,
                      allowances:
                        selectedValueBPU !== "Tagih Ke Perusahaan"
                          ? pObj?.citiesData?.find(
                            (c) => c?.cityCode === obj?.cityCode
                          )?.allowances
                          : [
                            ...pObj?.citiesData?.find(
                              (c) => c?.cityCode === obj?.cityCode
                            )?.allowances,
                            {
                              name: "JKK",
                              value: pObj?.citiesData?.find(
                                (c) => c?.cityCode === obj?.cityCode
                              )?.jkk,
                              type: "BPU",
                            },
                            {
                              name: "JKM",
                              value: pObj?.citiesData?.find(
                                (c) => c?.cityCode === obj?.cityCode
                              )?.jkm,
                              type: "BPU",
                            },
                            {
                              name: "JHT",
                              value: pObj?.citiesData?.find(
                                (c) => c?.cityCode === obj?.cityCode
                              )?.jht,
                              type: "BPU",
                            },
                          ],
                    };
                  }),
              };
            })}
            positionsForm={positionsForm}
            deploymentsForm={delegationsForm}
            cities={cities}
            selectedValueBPU={selectedValueBPU}
          />
        )

      default:
        return (
          <FirstStep
            debounceTrigger={debounceTrigger}
            types={contractTypeOptions}
            onChange={onChangeStep1}
            form={contractForm}
            // onNext={() => onNext(firstStepDoneIndicator)}
            onNext={handleOnNext}
            alertMessage={alertMessage}
          />
        );
    }
  }, [currentStep, debounceTrigger, contractTypeOptions, onChangeStep1, contractForm, handleOnNext, contracts, alertMessage, selectedButtonBPJS, selectedValueBPU, banks, secondStepDoneIndicator, workSchemeOptions, workSchemeForm, onPrevious, contractType, withBPJS, thirdStepDoneIndicator, additionalForm, files, clientContacts, delegationsForm, positionsForm, remark, defaultDeploymentsData, cities, options, qualifications, fixedWorkSchemeManFee, fixedBpjsCut, manfee, TOP, handleSubmit, submitting, submittingDraft, submittingType, error, firstStepDoneIndicator, fourthStepDoneIndicator, selectedContract]);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      {activeStep}
      {/* {isSuccess && (
        <FinishedModal
          clearStorage={clearStorage}
          setShow={setIsSuccess}
          submittingType={submittingType}
        />
      )} */}
      {(submitting || submittingDraft || isSuccess || isSuccessDraft) && (
        <SendFLBModal
          show={submitting || submittingDraft || isSuccess || isSuccessDraft}
          type={submitting ? 'submit' : (
            submittingDraft ? 'draft' : (
              isSuccess ? 'success' : (
                isSuccessDraft ? 'success-draft' : 'failure'
              )
            )
          )}
          onNext={() => {
            navigate(-1)
          }}
          onClose={() => { }}
        />
      )}

      <div className={Styles.buttonsWrapper}>
        {
          currentStep != 1
          &&
          <button className={Styles.previous} onClick={onPrevious}>
            <Icon icon={'arrow-left-back'} size={20} />
            <span>
              {`Munder ke Step ${+currentStep - 1}: ${previousButtonText}`}
            </span>
          </button>
        }
        {
          currentStep != 7
          &&
          <button className={Styles.next} onClick={handleOnNext}>
            <span>
              {`Lanjut ke Step ${+currentStep + 1}: ${nextButtonText}`}
            </span>
            <Icon icon={'arrow-right-next'} size={20} />
          </button>

        }
      </div>
    </div>
  );
}
