import Images from '@Theme/Images'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon';
import { useEffect, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

const PDFJS = require("pdfjs-dist/webpack");

export default function PreviewImageModal({
  url,
  onClose
}) {



  if (url?.includes("pdf-pdf")) {
    return (
      <PDFType
        url={url}
        onClose={onClose}
      />
    )
  } else {
    return (
      <ImageType
        url={url}
        onClose={onClose}
      />
    )
  }


}

const ImageType = ({ url, onClose }) => {
  let imageAngle = 0;


  const rotateImage = () => {
    if(imageAngle < 360) {
      imageAngle += 90
    } else {
      imageAngle -= 360
    }
    var div = document.getElementById('activeImage'),
      angle = imageAngle;

    if (angle === 90 || angle === 270) {
      div.classList.add(Styles.pushDown);
    }
    else {
      div.classList.remove(Styles.pushDown);
    }

    div.style.webkitTransform = 'rotate(' + angle + 'deg)';
    div.style.mozTransform = 'rotate(' + angle + 'deg)';
    div.style.msTransform = 'rotate(' + angle + 'deg)';
    div.style.oTransform = 'rotate(' + angle + 'deg)';
    div.style.transform = 'rotate(' + angle + 'deg)';
  }
  // rotated = !rotated;
  return (
    <div className={Styles.container}>
      <TransformWrapper>
        <TransformComponent>
          <img className={Styles.img} src={url} alt='' id='activeImage' />
        </TransformComponent>
      </TransformWrapper>
      <div
        className={`${Styles.button} ${Styles.close}`}
        onClick={() =>
          onClose()
        }
      >
        <Icon icon={'cross'} size={30} color={'#fff'} />
      </div>
      <div
        className={`${Styles.button} ${Styles.rotate}`}
        onClick={() =>
          rotateImage()
        }
      >
        <img src={Images.ROTATE} alt="" />
      </div>
    </div>
  )
}


const PDFType = ({ url, onClose }) => {
  const [images, setImages] = useState([]);


  useEffect(() => {
    if (url) {
      const loadDefaultImages = async () => {
        const imagesArr = [];
        if (url === undefined) {
          imagesArr.push(null);
        } else {
          const uri = url;
          var pdf = await PDFJS.getDocument({ url: uri }).promise;
          const canvas = document.createElement("canvas");
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport: viewport })
              .promise;
            imagesArr.push(canvas.toDataURL());
          }
          canvas.remove();
        }

        setImages(imagesArr);
      };
      loadDefaultImages();
    } else {
      setImages([]);
    }
  }, [url]);

  return (
    <div className={Styles.pdfContainer}>
      <div className={Styles.buttonWrapper}>
        <div
          className={`${Styles.button} ${Styles.close}`}
          onClick={() =>
            onClose()
          }
        >
          <Icon icon={'cross'} size={30} color={'#fff'} />
        </div>
      </div>

      <div className={Styles.content}>
        {images?.length ? (
          images?.map((eachI, iI) => <img key={iI} alt="cv" src={eachI} />)
        ) : (
          <div>{url ? "loading file..." : "no cv uploaded"}</div>
        )}
      </div>
    </div>
  )
}