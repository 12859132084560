// import logo from '@Assets/logo.svg';
// import Icon from "@Atom/Icon";
import HeaderComponent from "@Molecule/Header";
import Auth from "@Pages/Auth";
import "@Theme/variables.scss";
import { useEffect, useMemo, useRef } from "react";
import {
  Outlet,
  redirect,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.scss";
import Styles from "./style.module.scss";

export const toolsLoader = (route) => {
  const url = window?.location?.href;
  if (!url?.includes("orders") && !url?.includes("edit")) {
    sessionStorage.removeItem("contractFormEdit");
    sessionStorage.removeItem("contractsEdit");
    sessionStorage.removeItem("workSchemeFormEdit");
    sessionStorage.removeItem("additionalFormEdit");
    sessionStorage.removeItem("manPowerFormAddEdit");
    sessionStorage.removeItem("manPowerFormArrEdit");
    sessionStorage.removeItem("delegationsFormEdit");
    sessionStorage.removeItem("positionsFormEdit");
    sessionStorage.removeItem("remarkEdit");
    sessionStorage.removeItem("delegationsEdit");
    sessionStorage.removeItem("TOPEdit");
    sessionStorage.removeItem("manfeeEdit");
    sessionStorage.removeItem("stepsEdit");
    sessionStorage.removeItem("selectedButtonBPJSEdit");
    sessionStorage.removeItem("selectedValueBPUEdit");
    sessionStorage.removeItem("withBPJSEdit");
    sessionStorage.removeItem("isLoadingFirst");
    sessionStorage.removeItem("isLoadingSecond");
    sessionStorage.removeItem("isLoadingThird");
    sessionStorage.removeItem("isLoadingFourth");
    sessionStorage.removeItem("isLoadingFifth");
    sessionStorage.removeItem("defaultFirst");
    sessionStorage.removeItem("defaultSecond");
    sessionStorage.removeItem("defaultThird");
    sessionStorage.removeItem("defaultFourth");
    sessionStorage.removeItem("defaultFifth");
    sessionStorage.removeItem("orderStepEdit");
  }
  // sessionStorage.setItem('currentMode', 'dashboard')
  // const accessToken = sessionStorage.getItem("accessToken");
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    throw redirect("/sign-in");
  }

  return null;
};

const loader = () => {
  const currentMode = localStorage.getItem("currentMode");
  const name = localStorage.getItem("username");
  const position = localStorage.getItem("role");
  // const currentMode = sessionStorage.getItem("currentMode");
  // const name = sessionStorage.getItem("username");
  // const position = sessionStorage.getItem("role");
  // const accessToken = sessionStorage.getItem("accessToken");
  const accessToken = localStorage.getItem("accessToken");
  if (currentMode === "dashboard") {
    if (!accessToken) {
      throw redirect("/login");
    }
    return null;
  } else if (currentMode === "homepage") {
    const user = {
      name: name,
      position: position,
      accessToken: accessToken,
    };
    return user;
  } else {
    const user = {
      name: name,
      position: position,
      accessToken: accessToken,
    };
    return user;
  }
};

function App() {
  const currentMode = localStorage.getItem("currentMode");
  // const currentMode = sessionStorage.getItem("currentMode");
  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage?.getItem("accessToken") &&
      localStorage.getItem("isClient") === "true"
    ) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const fixedCurrentMode = useMemo(() => {
    if (localStorage.getItem("isClient") !== "false") {
      if (pathname === "/business" || pathname === "/") {
        return "homepage";
      } else if (
        pathname?.includes("job-seeker") ||
        // pathname?.includes("job-seeker/details") ||
        pathname?.includes("login") ||
        pathname?.includes("register") ||
        pathname?.includes("forgot-password")
      ) {
        return "homepage";
      } else {
        return "dashboard";
      }
    } else {
      return currentMode;
    }
  }, [pathname, currentMode]);

  useEffect(() => {
    // sessionStorage.setItem("currentMode", fixedCurrentMode);
    localStorage.setItem("currentMode", fixedCurrentMode);
  }, [fixedCurrentMode]);

  switch (fixedCurrentMode) {
    case "dashboard":
      return <Dashboard />;
    case "homepage":
      return <HomePage />;
    default:
      return <HomePage />;
  }
}

const Dashboard = () => {
  const role = localStorage.getItem("role");
  // const role = sessionStorage.getItem("role");

  return <Auth role={role} />;
};

const HomePage = () => {
  const containerRef = useRef(null);

  const data = useLoaderData();

  const user = {
    name: localStorage.getItem("username"),
    position: localStorage.getItem("role"),
    accessToken: localStorage.getItem("accessToken"),
    // name: sessionStorage.getItem("username"),
    // position: sessionStorage.getItem("role"),
    // accessToken: sessionStorage.getItem("accessToken"),
  };

  useEffect(() => {}, [data]);

  return (
    <div ref={containerRef} className={Styles.homepageContainer}>
      <HeaderComponent user={user} />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export { loader };

export default App;
