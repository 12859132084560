import Images from "@Theme/Images";
import Styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

export default function EmptyHandler() {
  const navigate = useNavigate();

  const handleNavigateRequest = () => {
    navigate("/order/manpower-outsource");
  };
  return (
    <div className={Styles.container}>
      <div className={Styles.logo}>
        <img src={Images.LIST} alt="no-data" />
      </div>
      <h3>Empty List</h3>
      <p>There is still no employee list, start placing orders now</p>
      <button onClick={handleNavigateRequest}>Make Request</button>
    </div>
  );
}
