import React from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";

export default function JobSeekersShowcase() {
  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <div className={Styles.image}>
          <img src={Images.SHOWCASE_IMAGE} alt="showcase" />
        </div>
        <div className={Styles.background}/>
        <div className={Styles.textWrapper}>
          <p>Temukan pekerjaan yang relevan dan mulai bergabung dengan kami</p>
          <p>
            Kami memiliki lebih banyak lowongan pekerjaan untuk anda, mulailah
            temukan dan bangun karir anda bersama kami, karena kami memiliki
            klien korporat yang tersebar di seluruh Indonesia.
          </p>
        </div>
      </div>
    </div>
  );
}
