import Icon from "@Atom/Icon";
import { useRef } from "react";
import Styles from "./inputDate.module.scss";
import ErrorBubble from "@Atom/ErrorBubble";
import moment from "moment";

const InputDate = ({
  value,
  setValue,
  iconColor,
  required,
  disabled,
  placeholder,
  readOnly = false,
  disabledDate,
  isError = false,
  isErrorV2 = false,
  errorText = "",
  preventPastDates = false,
  type,
  min,
  ...props
}) => {
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const dateRef = useRef();

  // useEffect(() => {
  // }, [dateRef])

  function formatDateMonth(date) {
    if (!date) {
      return "seluruh total"; // Jika date tidak ada, kembalikan string "seluruh total"
    }
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;

    return [year, month].join("-");
  }

  if (type === "month") {
    return (
      <div
        className={Styles.container}
        onClick={() =>
          !disabledDate &&
          !disabled &&
          !readOnly &&
          dateRef.current.showPicker()
        }
      >
        <div className={Styles.inputWrapper}>
          <input
            className={`${Styles.input} ${
              (dateRef?.current?.value &&
                dateRef?.current?.value !== "NaN-NaN-NaN") ||
              (value && value !== "NaN-NaN-NaN")
                ? "has-value"
                : "no-value"
            } ${isError ? Styles.error : ""}`}
            // className={Styles.input}
            value={value ? formatDateMonth(value) : ""}
            onChange={(e) => {
              if (e?.target?.value) {
                setValue(new Date(e.target.value));
              } else {
                setValue("");
              }
              // dateRef.className = (dateRef.value ? 'has-value' : '')
            }}
            placeholder={placeholder ? placeholder : "Select date"}
            type="month"
            ref={dateRef}
            disabled={disabled}
            readOnly={readOnly || disabledDate}
          />
          <div className={Styles.iconWrapper}>
            <Icon
              className={Styles.icon}
              color={
                !disabledDate && !disabled && !readOnly
                  ? iconColor || "#616161"
                  : "#9e9e9e"
              }
              icon="calendar-v2"
              size={20}
            />
          </div>
        </div>
        {isError && !disabled && !readOnly && (
          <span className={Styles.errorText}>{errorText}</span>
        )}
      </div>
    );
  }

  return (
    <div
      className={Styles.container}
      onClick={() =>
        !disabledDate && !disabled && !readOnly && dateRef.current.showPicker()
      }
    >
      <div className={Styles.inputWrapper}>
        <input
          className={`${Styles.input} ${
            (dateRef?.current?.value &&
              dateRef?.current?.value !== "NaN-NaN-NaN") ||
            (value && value !== "NaN-NaN-NaN")
              ? "has-value"
              : "no-value"
          } ${isError ? Styles.error : ""}`}
          // className={Styles.input}
          value={value ? formatDate(value) : ""}
          onChange={(e) => {
            if (e?.target?.value) {
              setValue(new Date(e.target.value));
            } else {
              setValue("");
            }
            // dateRef.className = (dateRef.value ? 'has-value' : '')
          }}
          placeholder={placeholder ? placeholder : "Select date"}
          type="date"
          ref={dateRef}
          disabled={disabled}
          readOnly={readOnly || disabledDate}
          min={
            preventPastDates
              ? moment(new Date())?.format("YYYY-MM-DD")
              : min
              ? moment(new Date(min))?.format("YYYY-MM-DD")
              : ""
          }
        />
        <div className={Styles.iconWrapper}>
          <Icon
            className={Styles.icon}
            color={
              !disabledDate && !disabled && !readOnly
                ? iconColor || "#616161"
                : "#9e9e9e"
            }
            icon="calendar-v2"
            size={20}
          />
        </div>
      </div>
      {isError &&
        !disabled &&
        !readOnly &&
        (!isErrorV2 ? (
          <span className={Styles.errorText}>{errorText}</span>
        ) : (
          <ErrorBubble errorText={errorText} />
        ))}
    </div>
  );
};

export default InputDate;
